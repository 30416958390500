import * as React from "react";
import "./ajoutAdmin.css";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";

import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addAdminAsync,
  isAddingAdmins,
} from "../../redux/slices/adminSlices/adminSlice";
import { useSelector, useDispatch } from "react-redux";
toast.configure();

const AjoutAdmin = () => {
  // Redux
  const dispatch = useDispatch();
  const isAddingRedux = useSelector(isAddingAdmins);

  const [state, setState] = React.useReducer(
    (state, newState) => ({ ...state, newState }),
    {
      domaines: [],
      fields: {},
    }
  );

  const addCoachHandleChange = (e) => {
    let fields = state.fields;
    fields[e.target.name] = e.target.value;
    setState({
      fields,
    });
  };

  const validateForm = () => {
    let fields = state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["first_name"]) {
      formIsValid = false;
      setState({
        fn_empty: true,
      });
    }

    if (typeof fields["first_name"] !== "undefined") {
      if (
        !fields["first_name"].match(
          /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
        )
      ) {
        formIsValid = false;
        setState({
          fn_empty: true,
        });
      }
    }

    if (!fields["last_name"]) {
      formIsValid = false;
      setState({
        ln_empty: true,
      });
    }

    if (typeof fields["last_name"] !== "undefined") {
      if (
        !fields["last_name"].match(
          /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
        )
      ) {
        formIsValid = false;
        setState({
          ln_empty: true,
        });
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      setState({
        email_empty: true,
      });
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        setState({
          email_empty: true,
        });
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      setState({
        phone_empty: true,
      });
    }

    setState({
      errors: errors,
    });
    return formIsValid;
  };

  const ajoutAdmin = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let fields = {};
      fields["first_name"] = "";
      fields["last_name"] = "";
      fields["email"] = "";
      fields["phone"] = "";
      // fields["coach_domaine_id"] = "";
      fields["status"] = "admin";
      setState({ fields: fields });
      const dataCoach = state.fields;
      dispatch(addAdminAsync(dataCoach));
    }
  };

  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-xl-2 col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/liste-admins" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="users"
                    />
                    Admins
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon className="icon-breadcrumb-custom" icon="user" />
                  Ajout Admin
                </li>
              </ol>
            </nav>

            <form>
              <div className="row edit-coach-container">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Prénom : </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={addCoachHandleChange}
                      name="first_name"
                      required
                    />
                  </div>
                </div>
                {state.fn_empty && (
                  <div className="errorMsg">{state.errors.first_name}</div>
                )}
                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Nom : </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={addCoachHandleChange}
                      name="last_name"
                      required
                    />
                  </div>
                </div>
                {state.ln_empty && (
                  <div className="errorMsg">{state.errors.last_name}</div>
                )}
                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Email : </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={addCoachHandleChange}
                      name="email"
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Téléphone : </label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={addCoachHandleChange}
                      name="phone"
                      required
                    />
                  </div>
                </div>
                {/*
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Domaine :</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="coach_domaine_id"
                        onChange={addCoachHandleChange}
                        required
                      >
                        <option> -- Choisir un domaine -- </option>
                        {state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
              </div>

              {/* <button
                  type="button"
                  className="btn-theme-danger"
                >
                  <i class="fas fa-times-circle"></i> &nbsp; Quitter
                </button> */}

              {isAddingRedux ? (
                <button
                  type="button"
                  className="col-md-4 offset-md-6 btn-theme"
                >
                  Ajout en cours &nbsp;
                  <i className="fas fa-spinner fa-spin"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="col-md-4 offset-md-6 btn-theme"
                  onClick={(e) => ajoutAdmin(e)}
                >
                  Ajouter
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjoutAdmin;
