import React, { useEffect, useReducer } from "react";
import "./listeAdmins.css";
import FeatherIcon from "feather-icons-react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
import SkeletonAllBakeliste from "../tableMyAllbakelistes/skeletonAllBakeliste";

import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from "rsuite";
import { NavLink } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import * as Sentry from "@sentry/browser";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { archiveAdminsAsync, getAdminsAsync, showListAdmins } from "../../redux/slices/parametreSlices/parametreSlice";
toast.configure();
const ListeAdminsRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      admin: [],
      isLoaded: true,
    }
  );
  const adminData = useSelector(showListAdmins)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAdminsAsync())
   // getListAdmins();
  }, []);

  const archivedAdmin = (id) => {

    dispatch(archiveAdminsAsync(id))
    // axios
    //   .post(state.api + "archive-user/" + id)
    //   .then((res) => {
    //     // console.log("archiver", res)
    //     if (res.data.success === true) {
    //       toast.success(
    //         "admin archivé avec success",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //       getListAdmins();
    //     } else {
    //       toast.error(
    //         "erreur sur l'archivage",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const getListAdmins = () => {
    axios
      .get(state.api + "list-admins")
      .then((res) => {
        if (res.data.success === true) {
          //  console.log('domaines actifs', res.data.data)
          setState({
            admin: res.data.data,
            isLoaded: false,
          });
        } else {
          setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        setState({
          loading: false,
        });
        console.log(error.message);
      });
  };
  const handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    console.log("This is my custom function for ExportCSVButton click event");
    onClick();
  };
  const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        style={{ "background-color": "#469789", margin: "1rem" }}
        btnText="Export CSV"
      />
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <span>
        &nbsp;&nbsp;
        <Tooltip title="Archivez admin">
          <FeatherIcon
            icon="archive"
            id="delete_group"
            style={{
              color: "red",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            onClick={() => {
              archivedAdmin(row.id);
            }}
            className="ajouter"
            cursor="pointer"
          />
        </Tooltip>
      </span>
    );
  };
  const options = {
    exportCSVBtn: createCustomExportCSVButton,
  };
  return (
    <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            {/* <button className="btn-add-with-icon-plus-circle ml-md-auto" onClick={addAdmin}>
          Ajouter
          <Icon className="icon-add ml-2" icon="plus-circle" />
        </button> */}

            <div className="col-md-2 btn-add-with-icon-plus-circle ml-auto mb-2">
              <NavLink className="ml-md-auto right-elements" to="/ajout-admin">
                Ajouter
                <Icon className="ml-2" icon="plus-circle" />
              </NavLink>
            </div>
            {adminData.length > 0 ? (
              <BootstrapTable
                data={adminData}
                striped
                version="4"
                exportCSV
                csvFileName="Liste des coachs.csv"
                bordered={false}
                bodyStyle={{
                  fontSize: 12,
                  color: "rgba(0, 0, 0, 0.7)",
                  fontFamily: "inherit",
                  fontWeight: 400,
                }}
                tdStyle={{ background: "red" }}
                tableHeaderclassName="header-table-groupe-view"
                wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                className="table-responsive table-responsive-sm"
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID{" "}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="first_name"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Prénom
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="last_name"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Nom 
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Email
                  </span>
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={actionFormatter}
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Actions
                  </span>
                </TableHeaderColumn>
              </BootstrapTable>
            ) : (
              <p className="pComment">Il n'y a pas encore d'admin actifs.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeAdminsRefact;
