import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "rsuite";
import IconStat1 from "../../img/icons/touch.png";
import "./navVerticalAdmin.css";

export default class navVerticalAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-nav-vertical d-lg-block d-none">
        <div
          className="nav flex-column nav-pills"
          id="v-pills-tabCustom"
          role="tablist"
          aria-orientation="vertical"
        >
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/dashboard-admin"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="layout" />
            Dashboard
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/bakelistes_new"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Bakelistes New
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/bakelistes-db"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Bakelistes
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-coachs"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Coachs
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-coach-assistants"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Coachs Assistants
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-admins"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="user-plus" />
            Admins
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/stagiaires"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="user-plus" />
            Stagiaires
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/evenements"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="shield" />
            Evenements
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/users-admin"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Utilisateurs
          </NavLink>{" "}
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-prospect"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="user-plus" />
            Prospects
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-bakelistes-recrutables"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="user-plus" />
            Recrutables
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-des-bakelistes-en-formation"
          >
            <FeatherIcon
              className="icon-side-navbar-custom"
              icon="user-check"
            />
            Suivi
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/parametres-admin"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="settings" />
            Parametres
          </NavLink>
        </div>

        {/*
        <div
          className="nav flex-column nav-pills"
          id="v-pills-tabCustom"
          role="tablist"
          aria-orientation="vertical"
        >
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/dashboard-admin"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="layout" />
            Dashboard
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/controle-admin"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="shield" />
            Contrôle
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/formation-admin"
          >
            <Icon className="icon-side-navbar-custom" icon="bank" />
            Formation
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/bakelistes-admin"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="user" />
            Bakelistes
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/suivi-bakeliste-version-deux"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Suivi_bakeliste
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/users-admin"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="users" />
            Utilisateurs
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-prospect"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="user-plus" />
            Prospects
          </NavLink>

          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-prospect parametres-admin liste-des-bakelistes-en-formation"
          >
            <FeatherIcon className="icon-side-navbar-custom" icon="settings" />
            Parametres
          </NavLink>
          <NavLink
            className="nav-link nav-link-side-navbar-custom"
            to="/liste-des-bakelistes-en-formation"
          >
            <FeatherIcon
              className="icon-side-navbar-custom"
              icon="user-check"
            />
            Suivi
          </NavLink>
        </div>
      */}
      </div>
    );
  }
}

// {/* <NavLink className="nav-link nav-link-side-navbar-custom" to="/livrables-admin">
//                       <FeatherIcon className="icon-side-navbar-custom" icon="clipboard" />
//                       Livrables
//                     </NavLink> */}
//           {/* <NavLink className="nav-link nav-link-side-navbar-custom" to="/articles-admin">
//                       <FeatherIcon className="icon-side-navbar-custom" icon="copy" />
//                       Articles
//                     </NavLink> */}
//           {/* <NavLink className="nav-link nav-link-side-navbar-custom" to="/annonces-admin">
//                       <FeatherIcon className="icon-side-navbar-custom" icon="sidebar" />
//                       Annonces
//                     </NavLink> */}
//           {/* <NavLink className="nav-link nav-link-side-navbar-custom" to="/pointage-admin">
//                       <img className="icon-side-navbar-custom" src={IconStat1} alt="" />
//                       Pointage
//                     </NavLink> */}
