import FeatherIcon from "feather-icons-react";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getAllNotifications,
  getAllUnreadNotifications,
  getNotificationsAsync,
  markAllAsRead,
} from "../../redux/slices/notifications/notificationSlice";
import { CustomToggle } from "../customToggle/CustomToggle";
import frenchFormatDate from "../frenchFormatDate";
import "./notifications.css";

const Notifications = () => {
  // HOC
  const dispatch = useDispatch();
  const notifications = useSelector(getAllNotifications);
  const unreadNotifications = useSelector(getAllUnreadNotifications);

  // user_id
  const user_id = sessionStorage.getItem("user_id");

  const [isToggled, setIsToggled] = React.useState(false);

  React.useEffect(() => {
    dispatch(getNotificationsAsync(user_id));
  }, []);

  const markAsRead = () => {
    dispatch(markAllAsRead(user_id));
  };

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsToggled(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="notification" ref={wrapperRef}>
      <div className={`notBtn ${isToggled && "toggled"}`}>
        <div
          className={`number ${unreadNotifications?.length === 0 && "empty"}`}
        ></div>
        <i
          className="fas fa-bell"
          onClick={() => {
            setIsToggled(!isToggled);
            unreadNotifications?.length >= 1 && markAsRead();
          }}
        ></i>
        <div className="notifBox">
          <div className="header py-2 d-flex align-items-center justify-content-between">
            <h3 className="pl-3">Notifications</h3>
            {unreadNotifications?.length >= 1 && (
              <Dropdown drop="down-centered" style={{ cursor: "pointer" }}>
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu size="sm" title="">
                  <Link
                    //   onClick={() =>
                    //     is_syllabus_assigned?.length >= 1
                    //       ? showWarning()
                    //       : archiverSyllabusRedux()
                    //   }
                    style={{ textDecoration: "none" }}
                  >
                    <Dropdown.Item>
                      <p className="info-content-syllabus-item">
                        <FeatherIcon
                          className="icon-content-syllabus-item"
                          icon="check"
                          style={{ width: "20px" }}
                        />
                        Tout marquer comme lu
                      </p>
                    </Dropdown.Item>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <div className="display">
            <div className="nothing">
              {/* <i className="fas fa-child stick"></i> */}
              <div className="cent">Accune notifications pour le moment</div>
            </div>
            <div className="cont">
              {notifications?.map((notif) => (
                <div key={notif?.id} className={`sec`}>
                  {/* <div className="profCont">
                    <img
                      className="profile"
                      src="https://c1.staticflickr.com/5/4007/4626436851_5629a97f30_b.jpg"
                      alt=""
                    />
                  </div> */}
                  <div>
                    <h5 className="font-weight-bold text-black">
                      {notif?.data?.header}
                    </h5>
                    <p className="txt1">{notif?.data?.body}</p>
                  </div>
                  <div className="txt1 sub d-flex align-items-center justify-content-end">
                    {frenchFormatDate.frenchFormatDate(notif?.created_at)}
                    {/* <span className="font-italic cursor">Marquer comme lu</span> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
