import React, {Component} from 'react';
import './notificationPage.css';
import HeaderDashboardCoach from '../headerDashboardCoach/index';
import NavVerticalCoach from '../navVerticalCoach/index';
import AddComment from '../notifications/AddComment';
import TaskSend from '../notifications/TaskSend';
import TaskUpdate from '../notifications/TaskUpdate';
import SyllabusAdd from '../notifications/SyllabusAdd';
import SyllabusUpdate from '../notifications/SyllabusUpdate';
import DeletedBakeliste from '../notifications/DeletedBakeliste';
import SyllabusAssign from '../notifications/SyllabusAssign';
import SyllabusTaskCreate from '../notifications/SyllabusTaskCreate';
import SyllabusTaskUpdate from '../notifications/SyllabusTaskUpdate';
import SyllabusTaskArchive from '../notifications/SyllabusTaskArchive';

export default class notificationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
      return  <div className="">
                <HeaderDashboardCoach />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalCoach />
                    </div>
                    <div className="col-lg-10 pt-3 p-b-200">
                      <div className="row pl-2 pr-2">
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <AddComment/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <TaskSend/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <TaskUpdate/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <SyllabusAdd/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <SyllabusUpdate/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <DeletedBakeliste/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <SyllabusAssign/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <SyllabusTaskCreate/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <SyllabusTaskUpdate/>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mb-2 pl-1 pr-1">
                          <SyllabusTaskArchive/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ notificationPage }) => ({ ...notificationPage }),
//     dispatch => bindActionCreators({ ...notificationPageActions }, dispatch)
//   )( notificationPage );