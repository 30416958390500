import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/plugin/weekOfYear";
import React from "react";
import Chart from "react-apexcharts";
import { Col, Container, Dropdown, Row, Tab, Tabs } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  AllCoachAssistantFeedback, 
  coachAssistantFeedback,
  dailyIdentifier,
  getAllCoachCommentStatsAsync,
  getCoachAssistantAsync,
  getCoachsCommentStatsAsync,
  monthlyIdentifier,
  setCommentIdentifier,
  showListCoachsAssistant,
  weeklyIdentifier,
} from "../../redux/slices/coachAssistantSlice/coachAssistantSlice";
import {
  getAllCoachsAsync,
  getArchivedCoachsAsync,
  showArchivedCoachs,
  showListCoachs,
} from "../../redux/slices/coachsSlices/coachsSlice";
import "./coachAdmin.css";
var weekOfYear = require("dayjs/plugin/weekOfYear");
var toObject = require("dayjs/plugin/toObject");

// Components here

const Dashboard = () => {
  // Days js Config
  dayjs.locale("fr");
  dayjs.extend(weekOfYear);
  dayjs.extend(toObject);

  const dispatch = useDispatch();

  //   const coachAssistantRedux = useSelector(showListCoachsAssistant);
  const coachAssistantFeedbackStats = useSelector(coachAssistantFeedback);
  const AllCoachAssistantFeedbackStats = useSelector(AllCoachAssistantFeedback);
  const commentIdentifier = useSelector(setCommentIdentifier);

  React.useEffect(() => {
    // coachAssistantRedux.length === 0 && dispatch(getCoachAssistantAsync());
    dispatch(getAllCoachCommentStatsAsync());
  }, []);

  // Initial States
  const [selectedTime, setSelectedTime] = React.useState("day");

  React.useEffect(() => {
    //   For the comments
    const fd = new FormData();
    fd.append("commentTime", selectedTime);

    if (selectedTime) {
      dispatch(getCoachsCommentStatsAsync(fd, handleCommentIdentifier));
    }
  }, [selectedTime]);

  const handleCommentIdentifier = () => {
    return selectedTime === "day"
      ? dispatch(dailyIdentifier())
      : selectedTime === "week"
      ? dispatch(weeklyIdentifier())
      : selectedTime === "month"
      ? dispatch(monthlyIdentifier())
      : null;
  };

  // COMMENTS CHARTS CONFIG
  const globalColors = ["#009688", "#F44336"];
  var globalSeries = [
    {
      name: "Commentaires",
      data: AllCoachAssistantFeedbackStats.map((item) =>
        item?.comments === undefined ? 0 : item?.comments
      ),
    },
    {
      name: "Validations",
      data: AllCoachAssistantFeedbackStats.map((item) =>
        item?.validations === undefined ? 0 : item?.validations
      ),
    },
  ];

  var globalOptions = {
    chart: {
      type: "bar",
      //   height: 430,
    },
    colors: globalColors,
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    yaxis: {
      categories: AllCoachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
    },
    xaxis: {
      categories: AllCoachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      display: true,
      text: "Vue d'ensemble des commentaires et validations des coachs",
      align: "center",
    },
    noData: {
      text: "Chargement...",
    },
  };

  // Weekly data

  const weeklyColors = ["#FF9800", "#009688"];

  var weeklySeries = [
    {
      name: "Commentaires",
      data: coachAssistantFeedbackStats.map((item) =>
        item?.comments === undefined ? 0 : item?.comments
      ),
    },
    {
      name: "Validations",
      data: coachAssistantFeedbackStats.map((item) =>
        item?.validations === undefined ? 0 : item?.validations
      ),
    },
  ];

  var weeklyOptions = {
    chart: {
      type: "bar",
      height: 430,
    },
    colors: weeklyColors,
    plotOptions: {
      bar: {
        horizontal: true,
        // distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    yaxis: {
      categories: coachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
    },
    xaxis: {
      categories: coachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      display: true,
      text: "Statistiques hebdomadaire des commentaires et validations des coachs",
      align: "center",
    },
    noData: {
      text: "Chargement...",
    },
  };

  // Monhly data

  const monthlyColors = ["#F44336", "#FF9800"];

  var monthlySeries = [
    {
      name: "Commentaires",
      data: coachAssistantFeedbackStats.map((item) =>
        item?.comments === undefined ? 0 : item?.comments
      ),
      goals: [
        {
          name: "Expected",
          value: 52,
          strokeColor: "#775DD0",
        },
      ],
    },
    {
      name: "Validations",
      data: coachAssistantFeedbackStats.map((item) =>
        item?.validations === undefined ? 0 : item?.validations
      ),
    },
  ];

  var monthlyOptions = {
    chart: {
      type: "bar",
      height: 430,
    },

    colors: monthlyColors,
    plotOptions: {
      bar: {
        horizontal: true,
        // distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    yaxis: {
      categories: coachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
    },
    xaxis: {
      categories: coachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      display: true,
      text: "Statistiques mensuel des commentaires et validations des coachs",
      align: "center",
    },
    noData: {
      text: "Chargement...",
    },
  };

  return (
    <div className="mt-3">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg left-pane">
            <div className="row align-items-center justify-content-between p-3 customSelectSection">
              <select
                name="period"
                id="period"
                onChange={(e) => setSelectedTime(e.target.value)}
              >
                {/* <option>Periode</option> */}
                <option value="day">Aujourd'hui</option>
                <option value="week">Semaine</option>
                <option value="month">Mois</option>
              </select>
            </div>
            <>
              {commentIdentifier === "global" ||
                (commentIdentifier === "day" && (
                  <Chart
                    options={globalOptions}
                    series={globalSeries}
                    type="bar"
                  />
                ))}
              {/* {commentIdentifier === "day" && (
                <Chart options={dailyOptions} series={dailySeries} type="bar" />
              )} */}
              {commentIdentifier === "week" && (
                <Chart
                  options={weeklyOptions}
                  series={weeklySeries}
                  type="bar"
                />
              )}
              {commentIdentifier === "month" && (
                <Chart
                  options={monthlyOptions}
                  series={monthlySeries}
                  type="bar"
                />
              )}
            </>
          </div>

          {/* <div className="col-lg-6 right-pane">
            <div className="row">
              <div className="col-lg-6 statsCard">
                <div className="statsCard_title d-flex align-items-center">
                  <FiUsers size={20} className="mx-1 greenIcon" />
                  <h6>Coachs actifs</h6>
                </div>

                <div className="statsCard_body">
                  <span>100</span>
                </div>
              </div>
              <div className="col-lg-6 statsCard">
                <div className="statsCard_title d-flex align-items-center">
                  <FaTasks size={20} className="mx-1 greenIcon" />
                  <h6>Total validations tâches</h6>
                </div>

                <div className="statsCard_body">
                  <span>100</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 statsCard">
                <div className="statsCard_title d-flex align-items-center">
                  <AiOutlineComment size={20} className="mx-1 greenIcon" />
                  <h6>Commentaires</h6>
                </div>

                <div className="statsCard_body">
                  <span>100</span>
                </div>
              </div>
              <div className="col-lg-6 statsCard">
                <div className="statsCard_title d-flex align-items-center">
                  <AiOutlineFundProjectionScreen
                    size={20}
                    className="mx-1 greenIcon"
                  />
                  <h6>Total syllabus</h6>
                </div>

                <div className="statsCard_body">
                  <span>100</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

// BootstrapTable component
const BootstrapTableComponent = (props) => {
  const { data, nameFormatter, actionFormatter } = props;

  return (
    <BootstrapTable
      data={data}
      striped
      version="4"
      exportCSV
      csvFileName="Liste des coachs assistants.csv"
      bordered={false}
    >
      <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
        {" "}
        ID{" "}
      </TableHeaderColumn>

      <TableHeaderColumn
        // dataField={nameFormatter}
        width="150"
        thStyle={{
          border: "none",
          //   fontWeight: "400",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "22px",
          color: "#069A77",
        }}
        tdStyle={{
          border: "none",
          padding: "1rem",
          "font-size": "14px",
          "font-family": "Montserrat",
        }}
        dataFormat={nameFormatter}
      >
        Prénom et nom
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="email"
        width="150"
        thStyle={{
          border: "none",
          //   fontWeight: "400",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "22px",
          color: "#069A77",
        }}
        tdStyle={{
          border: "none",
          padding: "1rem",
          "font-size": "14px",
          "font-family": "Montserrat",
        }}
      >
        Email
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="phone"
        width="150"
        thStyle={{
          border: "none",
          //   fontWeight: "400",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "22px",
          color: "#069A77",
        }}
        tdStyle={{
          border: "none",
          padding: "1rem",
          "font-size": "14px",
          "font-family": "Montserrat",
        }}
      >
        Téléphone
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="first_name"
        width="150"
        thStyle={{
          border: "none",
          //   fontWeight: "400",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "22px",
          color: "#069A77",
        }}
        tdStyle={{
          border: "none",
          padding: "1rem",
          "font-size": "14px",
          "font-family": "Montserrat",
        }}
      >
        Nombre Bakelistes
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="domaine"
        width="150"
        // thStyle={{ border: "none", fontWeight: "400" }}
        thStyle={{
          border: "none",
          //   fontWeight: "400",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "22px",
          color: "#069A77",
        }}
        tdStyle={{
          border: "none",
          padding: "1rem",
          "font-size": "14px",
          "font-family": "Montserrat",
        }}
      >
        Domaine
      </TableHeaderColumn>

      <TableHeaderColumn
        // dataField="first_name"
        width="150"
        // thStyle={{ border: "none", fontWeight: "400" }}
        thStyle={{
          border: "none",
          //   fontWeight: "400",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "22px",
          color: "#069A77",
        }}
        tdStyle={{
          border: "none",
          padding: "1rem",
          "font-size": "14px",
          "font-family": "Montserrat",
        }}
        dataFormat={actionFormatter}
      >
        Actions
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

const Coachs = () => {
  const dispatch = useDispatch();

  const coachsRedux = useSelector(showListCoachs);

  React.useEffect(() => {
    coachsRedux.length === 0 && dispatch(getAllCoachsAsync());
  }, []);

  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  const actionFormatter = (cell, row) => { 
    return (
      <span className="text-center">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="position-z">
            <Dropdown.Item href="#/action-1">Modifier</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Archiver</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>{" "}
      </span>
    );
  };

  return (
    <div className="mt-3">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12 tableSection">
            <BootstrapTableComponent
              data={coachsRedux}
              actionFormatter={actionFormatter}
              nameFormatter={nameFormatter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CoachsAssistant = () => {
  const dispatch = useDispatch();

  const coachAssistantredux = useSelector(showListCoachsAssistant);

  React.useEffect(() => {
    coachAssistantredux.length === 0 && dispatch(getCoachAssistantAsync());
  }, []);

  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  const actionFormatter = (cell, row) => {
    return (
      <span className="text-center">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="position-z">
            <Dropdown.Item href="#/action-1">Modifier</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Archiver</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>{" "}
      </span>
    );
  };
  return (
    <div className="mt-3">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12 tableSection">
            <BootstrapTableComponent
              data={coachAssistantredux}
              actionFormatter={actionFormatter}
              nameFormatter={nameFormatter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Archives = () => {
  const dispatch = useDispatch();

  const archivedCoachsRedux = useSelector(showArchivedCoachs);

  React.useEffect(() => {
    archivedCoachsRedux.length === 0 && dispatch(getArchivedCoachsAsync());
  }, []);

  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  const actionFormatter = (cell, row) => {
    return (
      <span className="text-center">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="position-z">
            <Dropdown.Item href="#/action-2">Desarchiver</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>{" "}
      </span>
    );
  };

  return (
    <div className="mt-3">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12 tableSection">
            <BootstrapTableComponent
              data={archivedCoachsRedux}
              actionFormatter={actionFormatter}
              nameFormatter={nameFormatter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CoachsAdminNew = () => {
  return (
    <div className="component-coachs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link to="/dashboard-admin" className="lien_nav">
                    Dashboard
                  </Link>
                </li>
                <li
                  className="lien_nav_current breadcrumb-item"
                  aria-current="page"
                >
                  Dashboard coachs
                </li>
              </ol>
            </nav>

            {/* All other components */}
            <Container fluid>
              <Row>
                <Col>
                  <Tabs defaultActiveKey="Dashboard">
                    <Tab eventKey="Dashboard" title="Dashboard">
                      {/* dashboard component */}
                      <Dashboard /> 
                    </Tab>

                    <Tab eventKey="Coachs" title="Coachs">
                      {/* Coachs component */}
                      <Coachs />
                    </Tab>

                    <Tab eventKey="Coachs assistant" title="Coachs assistant">
                      {/* Coachs assistant component */}
                      <CoachsAssistant />
                    </Tab>

                    <Tab eventKey="Archives" title="Archives">
                      {/* Archives component */}
                      <Archives />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachsAdminNew;
