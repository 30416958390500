import React, { Component } from "react";
import "./listeSousGroupesAdmin.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";

import MultiSelect from "@khanacademy/react-multi-select";
import { Icon, Modal } from "rsuite";
import SweetAlert from "react-bootstrap-sweetalert";

import API from "../../variablesGlobales";

export default class listeSousGroupesAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      coach_id: window.sessionStorage.getItem("user_id"),
      loading: false,
      //syllabus_id:this.props.match.params.id,
      groupes: [],
      datas: [],
     
      sous_groupes: [],
      groupeInfos: [],
      sgroupe: {},
      sgroupeInfos: [],
      liste: [],
      syllabus: [],
      noSavedData: [],
      selectOptions: [],
      selected: [],
      bakelistes: [],
      ajoutEnCours: false,
      ajoutSuccessAlert: false,
      isAjoutError: false,
      fields: {},
      onlySelect: false,
      groupySelect: true,
      showAlertSuccess: false,
      showAlertError: false,
      showAlertDeleteSuccess: false,
      showAlertDeleteError: false,
      name: "",
      lien_whatsapp: "",
      size: "md",
      showEditModal: false,
      editSousGroupeInProgress: false,
      voirListe: false,
      voirSyllabus: false,
      able: true,
      abled: true,
    };
    this.ajoutBakeliste = this.ajoutBakeliste.bind(this);
    this.close = this.close.bind(this);
  }
  componentDidMount() {
    this.getSousGroupes();
    this.getBakelistes();

    this.isLoading = setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  onSubmitEditSousGroupe = (e) => {
    e.preventDefault();
    this.setState({
      editSousGroupeInProgress: true,
    });
    var fd = new FormData();
    fd.append("created_by", this.state.coach_id);
    fd.append("groupe_id", this.state.groupeId);
    fd.append("name", this.state.name);
    fd.append("lien_whatsapp", this.state.lien_whatsapp);
    axios
      .put(this.state.api + "sous__groupes/" + this.state.sgId, fd)
      .then((response) => {
        this.setState({
          editSousGroupeNInProgress: true,
        });
        if (response.data.success) {
          this.setState({
            editSousGroupeInProgress: false,
            showEditModal: false,
          });
          this.close();
          this.getSousGroupes();
        } else {
          this.setState({
            editSousGroupeInProgress: false,
            showEditModal: false,
          });
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          editSousGroupeNInProgress: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  // ajouter un bakeliste dans un sous-groupe
  ajoutBakeliste() {
    this.setState({
      ajoutEnCours: true,
      onlySelect: false,
      groupySelect: true,
    });
    let data = this.state.fields;
    if (this.state.onlySelect) {
      data["sous_groupe_id"] = this.state.sgroupeInfos.id;
      data["is_only"] = 1;
      data["is_groupy"] = 0;
      data["bakeliste_ids"] = this.state.fields.bakeliste_ids;
    } else if (this.state.groupySelect) {
      data["sous_groupe_id"] = this.state.sgroupeInfos.id;
      data["is_groupy"] = 1;
      data["is_only"] = 0;
      data["bakeliste_ids"] = this.state.selected;
    }
    axios
      .post(this.state.api + "sous_groupes/add-bakeliste", data)
      .then((res) => {
        if (res.data.success) {
          $("#date_groupe").val("");
          this.setState({
            ajoutEnCours: false,
            showAlertSuccess: true,
            fields: {},
          });
          // window.location = "/sous-groupe/" + this.props.id;
        } else {
          this.setState({
            showAlertError: true,
          });
        }
        $(".close-modal-bakeliste-title").click();
      })
      .catch((error) => {
        this.setState({
          ajoutEnCours: false,
          isAjoutError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  }

  getSousGroupes = () => {
    this.showLoader();
    axios
      .get(this.state.api + "sous__groupes")
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.data != undefined) {
          this.setState({
            // groupes: res.data.data,
            sous_groupes: res.data.data,
          });
          // console.log("response", res.data.data);
        }
        this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        this.hideLoader();
      });
  };

  actionsFormatterSousGroup(cell, row) {
    return (
      <span>
        <FeatherIcon
          icon="archive"
          onClick={() => {
            this.deleteSousGroup(row);
          }}
          id="delete_group"
          style={{
            color: "red",
            height: "21px",
            width: "22px",
            cursor: "pointer",
          }}
        />
        &nbsp;&nbsp;
        <FeatherIcon
          icon="plus-circle"
          onClick={() => {
            this.onGetSousGroupeInfos(row);
          }}
          data-toggle="modal"
          data-target="#ajouterBakelisteModale"
          className="ajouter"
          cursor="pointer"
        />
        &nbsp;&nbsp;
        <span className="voir">{row.sous_groupes_bakelistes.length}</span>
        <FeatherIcon
          icon="users"
          onClick={() => {
            this.getBakelisteBySousGroupes(row.id);
            this.onGetSousGroupeInfos(row);
          }}
          cursor="pointer"
          className="voir"
          data-toggle="modal"
          data-target="#voirListeModale"
        />
        &nbsp;&nbsp;
        <FeatherIcon
          icon="edit"
          onClick={() => {
            this.onGetSousGroupeInfos(row);
            this.editSousGroupeModal();
          }}
          cursor="pointer"
          className="edit"
        />
      </span>
    );
  }

  editSousGroupeModal = (id) => {
    // this.onGetGroupeById(id);
    this.setState({
      showEditModal: true,
    });
  };
  close = () => {
    this.setState({
      showEditModal: false,
    });
  };
  deleteSousGroup = (sgroup) => {
    axios
      .get(this.state.api + "sous__groupe/supprimer/" + sgroup.id)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            showAlertDeleteSuccess: true,
          });
          this.getSousGroupes();
        } else {
          this.setState({
            showAlertDeleteError: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  onGetSousGroupeInfos(sgroup) {
    this.setState({
      sgroupeInfos: sgroup,
      sgId: sgroup.id,
      name: sgroup.name,
      lien_whatsapp: sgroup.lien_whatsapp,
      groupeId: sgroup.groupe_id,
    });
    // console.log(this.state.sgroupeInfos)
  }

  getBakelisteBySousGroupes = (id) => {
    const _this = this;
    // this.showLoader();
    axios
      .get(this.state.api + "sous__groupes/" + id)
      .then((response) => {
        this.setState({
          loading: false,
        });
        if (response.data.success) {
          this.setState({
            liste: response.data.liste[0].reverse(),
            syllabus: response.data.syllabus[0].reverse(),
          });
        }
        _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        _this.hideLoader();
      });
  };

  // liste des bakelistes
  getBakelistes() {
    const _this = this;
    this.showLoader();
    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.coach_id)
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          this.setState({
            bakelistes: data,
          });
        }
        data.forEach((item) => {
          let infos = {
            label:
              item.first_name +
              " " +
              item.last_name +
              " - " +
              item.domaine_name,
            value: item.id,
          };
          this.state.selectOptions.push(infos);
        });
        _this.hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        _this.hideLoader();
      });
  }

  showSyllabus = () => {
    this.setState({
      voirListe: false,
      voirSyllabus: true,
      abled: false,
      able: true,
    });
  };

  showListe = () => {
    this.setState({
      voirListe: true,
      voirSyllabus: false,
      able: false,
      abled: true,
    });
  };

  voirModal = (cell, row) => {
    return (
      <>
        <button>
          <Link
            to={{
              pathname: "/information-sous-groupe",
              state: { id: row.id, name: row.groupe_name, donnees: row },
            }}
          >
            {row.name}{" "}
          </Link>
        </button>
       
      </>
    );
  };

  render() {
    const options = {
      clearSearchBtn: this.createCustomClearButton,
    };
    return ( 
    <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
    <BootstrapTable
      data={this.state.sous_groupes}
      striped
      version="4"
      bordered={false}
      exportCSV
      csvFileName="Liste des sous-groupes.csv"

      bodyStyle={{
        fontSize: 12,
        color: "rgba(0, 0, 0, 0.7)",
        fontFamily: "inherit",
        fontWeight: 400,
      }}
      tdStyle={{ background: "red" }}
      tableHeaderclassName="header-table-groupe-view"
      wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
      className="table-responsive table-responsive-sm"
    >
      <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
        {" "}
        ID{" "}
      </TableHeaderColumn>
      <TableHeaderColumn
        // filter={{type:"TextFilter", delay: 1000}}
        dataField="name"
        width="250"
        thStyle={{ border: "none", fontWeight: "400" }}
        tdStyle={{
          border: "none",
          padding: "1rem",
          "font-size": "14px",
          "font-family": "Montserrat",
        }}
      >
        <span className="color-nom-prenom-utilisateurs headerStyle">
          Sous groupes
        </span>
      </TableHeaderColumn>
      <TableHeaderColumn
        className="titre-tab"
        dataField="groupe_name"
        thStyle={{ border: "none", fontWeight: "400" }}
        tdStyle={{ border: "none", padding: "1rem" }}
      >
        <span className="color-nom-prenom-utilisateurs headerStyle">

        Groupes
        </span>
      </TableHeaderColumn>
      <TableHeaderColumn
        className="titre-tab"
        dataField="description"
        thStyle={{ border: "none", fontWeight: "400" }}
        tdStyle={{ border: "none", padding: "1rem" }}
      >
        <span className="color-nom-prenom-utilisateurs headerStyle">

        Description
        </span>
      </TableHeaderColumn>

      <TableHeaderColumn className="titre-tab" dataField='action' 
                  dataFormat={this.actionsFormatterSousGroup.bind(this)}
       thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1rem' }}>
         
         <span className="color-nom-prenom-utilisateurs headerStyle">

         Actions
</span>
         </TableHeaderColumn>
    </BootstrapTable>
    <div>
      <hr />
    </div>
 
     
              {/* <div className="row m-b-30 m-t-20">
                <div className="col dis-flex m-b-15"></div>
              </div>

              <BootstrapTable
                data={this.state.sous_groupes}
                striped={true}
                hover={true}
                condensed={true}
                multiColumnSort={2}
                options={options}
                pagination
                search={true}
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                 
                  dataFormat={this.voirModal}
                >
                  Sous-Groupes
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="groupe_name"
                >
                  Groupe
                </TableHeaderColumn>
                <TableHeaderColumn dataField="description">
                  Description
                </TableHeaderColumn>
                <TableHeaderColumn dataField="lien_whatsapp"> 
                  lien groupe whatsapp
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  dataFormat={this.actionsFormatterSousGroup.bind(this)}
                  dataSort={true}
                >
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
               */}
              <SweetAlert
                show={this.state.showAlertSuccess}
                onConfirm={() => this.setState({ showAlertSuccess: false })}
                success
                title="Ajout effectué avec succes!!!"
                style={{
                  color: "#069A77",
                  fontSize: "12px",
                  fontFamily: "Work Sans, sans-serif",
                  paddingTop: 60,
                  paddingBottom: 40,
                }}
                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
              />

              <SweetAlert
                show={this.state.showAlertError}
                onConfirm={() => this.setState({ showAlertError: false })}
                error
                title="Erreur sur l'ajout, veuillez reessayer!!!"
                style={{
                  color: "#d9534f",
                  fontSize: "12px",
                  fontFamily: "Work Sans, sans-serif",
                  paddingTop: 60,
                  paddingBottom: 40,
                }}
                confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
              />
              <div
                className="modal fade"
                id="ajouterBakelisteModale"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="addLivraisonBakelisteTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content modal-content-bakeliste">
                    <h5
                      className="modal-bakeliste-title m-b-50 marge"
                      id="addLivraisonBakelisteTitle"
                    >
                      Ajouter des bakelistes dans {this.state.sgroupeInfos.name}
                    </h5>
                    <button
                      type="button"
                      className="close-modal-bakeliste-title"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <FeatherIcon icon="x" aria-hidden="true" />
                    </button>
                    <form encType="multipart/form-data" id="livrable_form">
                      <div className="form-group col-md-12 marge">
                        <label>Choisissez un ou des Bakelistes</label>
                        <MultiSelect
                          overrideStrings={{
                            selectSomeItems: "Choisissez les bakelistes",
                            allItemsAreSelected:
                              "Tous vos bakelistes on été choisis",
                            selectAll: "Sélectionnez tout",
                            search: "Rechercher",
                          }}
                          options={this.state.selectOptions}
                          selected={this.state.selected}
                          onSelectedChanged={(selected) =>
                            this.setState({ selected })
                          }
                        />
                      </div>

                      {this.state.ajoutEnCours ? (
                        <button
                          type="submit"
                          className="btn-theme text-up  marge m-t-30 w-full p-t-15 p-b-15"
                          disabled
                        >
                          Ajout en cours &nbsp;
                          <FeatherIcon icon="loader" aria-hidden="true" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn-theme text-up"
                          onClick={() => this.ajoutBakeliste()}
                        >
                          Ajouter
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>

              <Modal
                className="modal-responsive-md"
                size={this.state.size}
                show={this.state.showEditModal}
                onHide={this.close}
              >
                <form
                  encType="multipart/form-data"
                  onSubmit={(e) => this.onSubmitEditSousGroupe(e)}
                >
                  <Modal.Header>
                    <Modal.Title className="modal-contact-us">
                      Modifier le sous-groupe {this.state.sgroupeInfos.name}
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="max-height-modal">
                    <div className="form-group as_input_container">
                      <input
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({
                            name: e.target.value,
                          })
                        }
                        name="name"
                        className="form-control input_item"
                        id="name"
                      />
                      <br />
                      <input
                        value={this.state.lien_whatsapp}
                        onChange={(e) =>
                          this.setState({
                            lien_whatsapp: e.target.value,
                          })
                        }
                        name="lien_whatsapp"
                        placeholder="Lien groupe whatsapp"
                        className="form-control input_item"
                        id="lien_whatsapp"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="row dis-flex justify-content-end">
                      <div className="col-md-5 col-sm-6">
                        {this.state.editSousGroupeInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            Modification en cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            MODIFIER
                          </button>
                        )}
                      </div>
                    </div>
                  </Modal.Footer>
                </form>
              </Modal>


              <div
                className="modal fade"
                id="voirListeModale"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="addLivraisonBakelisteTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content modal-content-bakeliste">
                    <h5
                      className="modal-bakeliste-title m-b-50"
                      id="addLivraisonBakelisteTitle"
                    >
                      Liste des bakelistes dans {this.state.sgroupeInfos.name}
                    </h5>
                    <button
                      type="button"
                      className="close-modal-bakeliste-title"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <FeatherIcon icon="x" aria-hidden="true" />
                    </button>

                    <div className="modal-body">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Prenom</th>
                            <th scope="col">Nom</th>
                            <th scope="col">Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.liste.map((item, index) => (
                            <tr key={index}>
                              <td>{item.first_name}</td>
                              <td>{item.last_name}</td>
                              <td>{item.email}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <SweetAlert
                      show={this.state.showAlertDeleteSuccess}
                      onConfirm={() =>
                        this.setState({ showAlertDeleteSuccess: false })
                      }
                      success
                      title="Sous-groupe archivé avec succes!!!"
                      style={{
                        color: "#069A77",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />

                    <SweetAlert
                      show={this.state.showAlertDeleteError}
                      onConfirm={() =>
                        this.setState({ showAlertDeleteError: false })
                      }
                      error
                      title="Erreur sur la suppression, veuillez reessayer!!!"
                      style={{
                        color: "#d9534f",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />
                  </div>
                </div>
              </div>


              </div>

            
    );
  }
}
// export default connect(
//     ({ listeSousGroupesCoach }) => ({ ...listeSousGroupesCoach }),
//     dispatch => bindActionCreators({ ...listeSousGroupesCoachActions }, dispatch)
//   )( listeSousGroupesCoach );
