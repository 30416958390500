import FeatherIcon from "feather-icons-react";
import React, { useState } from "react";

import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import { Icon, Modal } from "rsuite";
import { toast } from "react-toastify";

import axios from "axios";
import API from "../../variablesGlobales";

import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { useSelector } from "react-redux";

import { Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
// import { Icon } from "rsuite";
import {
  archiveAdmin,
  getAdminsAsync,
  getUsersExceptAdminsAsync,
  showListAdmins,
  showListUsersExceptAdmins,
} from "../../redux/slices/adminSlices/adminSlice";
import {
  getCoachAssistantAsync,
  showListCoachsAssistant,
  ajouterEntite,
} from "../../redux/slices/coachAssistantSlice/coachAssistantSlice";
import {
  archiveCoach,
  getAllCoachsAsync,
  showListCoachs,
} from "../../redux/slices/coachsSlices/coachsSlice";
import {
  getParticipantsAsync,
  getVisiteursAsync,
  showListParticipants,
  showListVisiteurs,
} from "../../redux/slices/utilisateursSlices/utilisateurSlice";
import { history } from "../history";
import { API_VERSION } from "@sentry/hub/dist/hub";

// ListeAdmins component
const ListeAdmins = () => {
  const dispatch = useDispatch();

  const adminReduxData = useSelector(showListAdmins);

  const usersExceptAdminReduxData = useSelector(showListUsersExceptAdmins);

  React.useEffect(() => {
    adminReduxData?.length === 0 && dispatch(getAdminsAsync());
  }, []);

  React.useEffect(() => {
    usersExceptAdminReduxData?.length === 0 &&
      dispatch(getUsersExceptAdminsAsync());
  }, []);

  const archiveAdminRedux = (id) => {
    dispatch(archiveAdmin(id));
  };

  const actionFormatter = (cell, row) => {
    return (
      <span>
        &nbsp;&nbsp;
        <Tooltip title="Archivez admin">
          <FeatherIcon
            icon="archive"
            id="delete_group"
            style={{
              color: "red",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            onClick={() => {
              archiveAdminRedux(row.id);
            }}
            className="ajouter"
            cursor="pointer"
          />
        </Tooltip>
      </span>
    );
  };

  return (
    <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="col-md-2 btn-add-with-icon-plus-circle ml-auto mb-2">
              <NavLink className="ml-md-auto right-elements" to="/ajout-admin">
                Ajouter
                <Icon className="ml-2" icon="plus-circle" />
              </NavLink>
            </div>
            {adminReduxData.length > 0 ? (
              <BootstrapTable
                data={adminReduxData}
                striped
                version="4"
                exportCSV
                csvFileName="Liste des coachs.csv"
                bordered={false}
                bodyStyle={{
                  fontSize: 12,
                  color: "rgba(0, 0, 0, 0.7)",
                  fontFamily: "inherit",
                  fontWeight: 400,
                }}
                tdStyle={{ background: "red" }}
                tableHeaderclassName="header-table-groupe-view"
                wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                className="table-responsive table-responsive-sm"
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID{" "}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="first_name"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Prénom
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="last_name"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Nom
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Email
                  </span>
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={actionFormatter}
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Actions
                  </span>
                </TableHeaderColumn>
              </BootstrapTable>
            ) : (
              <p className="pComment">Il n'y a pas encore d'admin actifs.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// ListeCoachs component
const ListeCoachs = () => {
  const dispatch = useDispatch();
  const coachReduxData = useSelector(showListCoachs);

  React.useEffect(() => {
    coachReduxData.length === 0 && dispatch(getAllCoachsAsync());
  });

  const addCoach = () => {
    history.push("ajout-coach");
  };

  const archiveCoachRedux = (id) => {
    dispatch(archiveCoach(id));
  };

  const nameFormatter = (cell, row) => {
    return (
      <span>
        <button className="btn-show" title="Voir les détails">
          {/* <Tooltip title="Voir les détails"> */}
          <Link
            to={{
              pathname: "/profil-coach",
              state: { id: row?.id, data: row },
            }}
          >
            {row?.first_name} {row?.last_name}{" "}
          </Link>
          {/* </Tooltip> */}
        </button>
      </span>
    );
  };

  const actionformater = (cell, row) => {
    return (
      <span>
        <FeatherIcon
          icon="archive"
          id="delete_group"
          style={{
            color: "red",
            height: "21px",
            width: "22px",
            cursor: "pointer",
          }}
          onClick={() => archiveCoachRedux(row.id)}
        />
        &nbsp;&nbsp;
        <Link
          to={{
            pathname: "/edit-coach",
            state: { id: row.id, data: row },
          }}
        >
          <FeatherIcon
            title="modifier les détails"
            icon="edit-2"
            style={{
              color: "#469789",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            className="ajouter"
            cursor="pointer"
          />
        </Link>
        &nbsp;&nbsp;
      </span>
    );
  };

  return (
    <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            <button
              className="btn-add-with-icon-plus-circle ml-md-auto"
              onClick={addCoach}
            >
              Ajouter
              <Icon className="icon-add ml-2" icon="plus-circle" />
            </button>
            {coachReduxData?.length > 0 ? (
              <BootstrapTable
                data={coachReduxData}
                striped
                version="4"
                exportCSV
                csvFileName="Liste des coachs.csv"
                bordered={false}
                bodyStyle={{
                  fontSize: 12,
                  color: "rgba(0, 0, 0, 0.7)",
                  fontFamily: "inherit",
                  fontWeight: 400,
                }}
                tdStyle={{ background: "red" }}
                tableHeaderclassName="header-table-groupe-view"
                wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                className="table-responsive table-responsive-sm"
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID{" "}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="first_name"
                  dataFormat={nameFormatter}
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Prénoms
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="domaine"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Domaine
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Email
                  </span>
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataFormat={actionformater}
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Actions
                  </span>
                </TableHeaderColumn>
              </BootstrapTable>
            ) : (
              <p className="pComment">Il n'y a pas encore de coachs actifs.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// ListeCoachAssistants component
const ListeCoachAssistants = () => {
  const dispatch = useDispatch();

  const coachAssistantredux = useSelector(showListCoachsAssistant);

  React.useEffect(() => {
    coachAssistantredux.length === 0 && dispatch(getCoachAssistantAsync());
  }, []);

  const addCoachAssistant = () => {
    history.push("/ajout-coach-assistant");
  };

  const archivedCoach = (id) => {
    dispatch(archiveCoach(id));
  };

  const actionformater = (cell, row) => {
    return (
      <span>
        <FeatherIcon
          icon="archive"
          id="delete_group"
          style={{
            color: "red",
            height: "21px",
            width: "22px",
            cursor: "pointer",
          }}
          onClick={() => archivedCoach(row.id)}
        />
        &nbsp;&nbsp;
        <Link
          to={{
            pathname: "/edit-coach",
            state: { id: row.id, data: row },
          }}
        >
          <FeatherIcon
            title="modifier les détails"
            icon="edit-2"
            style={{
              color: "#469789",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            className="ajouter"
            cursor="pointer"
          />
        </Link>
        &nbsp;&nbsp;
      </span>
    );
  };

  return (
    <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            <button
              className="btn-add-with-icon-plus-circle ml-md-auto"
              onClick={addCoachAssistant}
            >
              Ajouter
              <Icon className="icon-add ml-2" icon="plus-circle" />
            </button>
            {coachAssistantredux.length > 0 ? (
              <BootstrapTable
                data={coachAssistantredux}
                striped
                version="4"
                exportCSV
                csvFileName="Liste des coachs assistants.csv"
                bordered={false}
                bodyStyle={{
                  fontSize: 12,
                  color: "rgba(0, 0, 0, 0.7)",
                  fontFamily: "inherit",
                  fontWeight: 400,
                }}
                tdStyle={{ background: "red" }}
                tableHeaderclassName="header-table-groupe-view"
                wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                className="table-responsive table-responsive-sm"
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID{" "}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="first_name"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Prénoms
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="last_name"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Noms
                  </span>
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="email"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Email
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={actionformater}
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Actions
                  </span>
                </TableHeaderColumn>
              </BootstrapTable>
            ) : (
              <p className="pComment">
                Il n'y a pas encore de coachs assistants actifs.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// MyParticipants component
const MyParticipants = () => {
  const dispatch = useDispatch();
  const participants = useSelector(showListParticipants);

  React.useEffect(() => {
    participants?.length === 0 && dispatch(getParticipantsAsync());
  }, []);

  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <div className="component-table-my-participants">
      {participants?.length > 0 ? (
        <BootstrapTable
          striped
          version="4"
          exportCSV
          csvFileName="Liste participants.csv"
          data={participants}
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm pdt_table "
        >
          <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
            {" "}
            ID{" "}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="first_name"
            dataFormat={nameFormatter}
            width="150"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">
              Nom complet
            </span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="email"
            width="150"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">
              Email
            </span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="phone"
            width="150"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">
              Téléphone
            </span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="sesssion"
            width="150"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">
              Rencontres
            </span>
          </TableHeaderColumn>
          <TableHeaderColumn
            // dataFormat={this.actionformater}
            width="150"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">
              Actions
            </span>
          </TableHeaderColumn>
        </BootstrapTable>
      ) : (
        <p className="pComment">
          Il n'y a pas encore de participants disponibles.
        </p>
      )}
    </div>
  );
};

// MyBfTerminer component
const MyBfTerminer = () => {
  const options = {
    exportCSVBtn: this.CustomExportCsvBtn,
  };

  return (
    <div className="component-table-my-bf-terminer">
      <BootstrapTable
        pagination
        exportCSV
        options={options}
        csvFileName="utilisateurs.csv"
        //   data={gestionTacheForCoach}
        striped
        version="4"
        bordered={false}
        bodyStyle={{
          fontSize: 12,
          color: "rgba(0, 0, 0, 0.7)",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        tdStyle={{ background: "red" }}
        tableHeaderclassName="header-table-groupe-view"
        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
        className="table-responsive table-responsive-sm"
      >
        <TableHeaderColumn
          isKey
          filter={{ type: "TextFilter", delay: 1000 }}
          dataField="fullname"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="email"
          width="200"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Email</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="telephone"
          width="100"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Telephone</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="dateFin"
          width="200"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Date de fin</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="ecole"
          width="100"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Ecole</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="action"
          // dataFormat={uploadFormatter}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            display: "flex",
            justifyContent: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs"> Actions</span>
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

// TableMyVisiteurs component
const TableMyVisiteurs = () => {
  const dispatch = useDispatch();
  const visiteursRedux = useSelector(showListVisiteurs);

  React.useEffect(() => {
    visiteursRedux?.length === 0 && dispatch(getVisiteursAsync());
  }, []);

  return (
    <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            <BootstrapTable
              data={visiteursRedux}
              striped
              version="4"
              csvFileName="Liste des visiteurs.csv"
              exportCSV
              bordered={false}
              bodyStyle={{
                fontSize: 12,
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "inherit",
                fontWeight: 400,
              }}
              tdStyle={{ background: "red" }}
              tableHeaderclassName="header-table-groupe-view"
              wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
              className="table-responsive table-responsive-sm"
            >
              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                {" "}
                ID{" "}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="first_name"
                width="150"
                thStyle={{ border: "none", fontWeight: "400" }}
                tdStyle={{
                  border: "none",
                  padding: "1rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs headerStyle">
                  Noms complets
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="email"
                width="150"
                thStyle={{ border: "none", fontWeight: "400" }}
                tdStyle={{
                  border: "none",
                  padding: "1rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs headerStyle">
                  Email
                </span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="phone"
                width="150"
                thStyle={{ border: "none", fontWeight: "400" }}
                tdStyle={{
                  border: "none",
                  padding: "1rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs headerStyle">
                  Telephone
                </span>
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="action"
                //   dataFormat={uploadFormatter}
                width="150"
                thStyle={{ border: "none", fontWeight: "400" }}
                tdStyle={{
                  border: "none",
                  padding: "1rem",
                  "font-size": "14px",
                  "font-family": "Montserrat",
                }}
              >
                <span className="color-nom-prenom-utilisateurs headerStyle">
                  Actions
                </span>
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    </div>
  );
};

// MyUserManager component
const MyUserManager = () => {
  const dispatch = useDispatch();

  const adminReduxData = useSelector(showListAdmins);

  React.useEffect(() => {
    adminReduxData?.length === 0 && dispatch(getAdminsAsync());
  }, []);

  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  const Actions = () => {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon
            className="icon-btn-icon-table"
            icon="refresh-cw"
            size={18}
          />
        </button>
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="send" size={18} />
        </button>
      </div>
    );
  };

  const uploadFormatter = (cell, row) => {
    return <Actions active={cell} />;
  };

  const customExportCsvBtn = (onClick) => {
    return (
      <ExportCSVButton
        style={{
          background: "#FF9800",
          border: "none",
          height: "42px",
          width: "150px",
          left: "20px",
        }}
        btnText="Export to csv"
      />
    );
  };

  const options = {
    exportCSVBtn: customExportCsvBtn,
  };

  return (
    <div className="component-table-my-user-manager">
      <BootstrapTable
        pagination
        exportCSV
        options={options}
        csvFileName="utilisateurs.csv"
        data={adminReduxData}
        striped
        version="4"
        bordered={false}
        bodyStyle={{
          fontSize: 12,
          color: "rgba(0, 0, 0, 0.7)",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        tdStyle={{ background: "red" }}
        tableHeaderclassName="header-table-groupe-view"
        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
        className="table-responsive table-responsive-sm"
      >
        <TableHeaderColumn
          filter={{ type: "TextFilter", delay: 1000 }}
          dataField="first_name"
          dataFormat={nameFormatter}
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          isKey={true}
          dataField="email"
          width="200"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Email</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="phone"
          width="200"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Telephone</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="status"
          width="150"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Statut</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="action"
          dataFormat={uploadFormatter}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            display: "flex",
            justifyContent: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs"> Actions</span>
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

// All users except admin
const AllUsers = () => {
  const dispatch = useDispatch();

  const usersExceptAdminsReduxData = useSelector(showListUsersExceptAdmins);
  const [show, setShow] = useState(false);
  const [userLastname, setUserLastname] = useState("");
  const [userFirstname, setUserFirstname] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [userEntite, setUserEntite] = useState("");
  const [userId, setUserId] = useState("");
  // const [api, setapi] = useState(API);
  const close = () => {
    setShow(false);
  };

  const open = async () => {
    await setShow(true);
  };

  const getUserInfos = (user) => {
    setUserId(user.id);
    setUserFirstname(user.first_name);
    setUserStatus(user.status);
    setUserLastname(user.last_name);
    setUserEntite(user.entite);
  };

  React.useEffect(() => {
    usersExceptAdminsReduxData?.length === 0 &&
      dispatch(getUsersExceptAdminsAsync());
  }, []);

  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  const ActionsUsers = () => {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon
            className="icon-btn-icon-table"
            icon="refresh-cw"
            size={18}
          />
        </button>
      </div>
    );
  };

  const Actions = () => {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon
            className="icon-btn-icon-table"
            icon="refresh-cw"
            size={18}
          />
        </button>
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="send" size={18} />
        </button>
      </div>
    );
  };

  const uploadFormatter = (cell, row) => {
    return <Actions active={cell} />;
  };

  const actionFormatterUsers = (cell, row) => {
    return (
      <div className="text-left">
        <span className="text-center text-success">
          <Tooltip title="ajouter entité">
            <FeatherIcon
              icon="home"
              style={{
                color: "#469789",
                height: "21px",
                width: "22px",
                cursor: "pointer",
              }}
              onClick={() => {
                getUserInfos(row);
                open();
              }}
            />
          </Tooltip>
          &nbsp;&nbsp;
        </span>
      </div>
    );
  };
  const customExportCsvBtn = (onClick) => {
    return (
      <ExportCSVButton
        style={{
          background: "#FF9800",
          border: "none",
          height: "42px",
          width: "150px",
          left: "20px",
        }}
        btnText="Export to csv"
      />
    );
  };

  const options = {
    exportCSVBtn: customExportCsvBtn,
  };
  {
    /* Editer les infos du coach */
  }
  const addEntite = (e) => {
    e.preventDefault();
    // console.log("entité",userEntite);
    var fd = new FormData();
    fd.append("entite", userEntite);
    fd.append("user_id", userId);
    fd.append("status", userStatus);
    axios
      .post(API.API + "add-entite-to-user", fd)
      .then((response) => {
        // console.log(response)
        if (response.data.success === true) {
          close();
          toast.success(
            "User modifier avec succes",
            { position: toast.POSITION.TOP_RIGHT },
            { autoClose: 1000 }
          );
          dispatch(getUsersExceptAdminsAsync());
        } else {
          toast.error(
            "erreur sur la modification",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const addEntite = (id) => {
  //   var fd = new FormData();
  //   fd.append("entite", userEntite);
  //   fd.append("user_id", userId);
  //   fd.append("status", userStatus);
  //   dispatch(ajouterEntite(fd));
  // };

  return (
    <div className="component-table-my-user-manager">
      <BootstrapTable
        
        data={usersExceptAdminsReduxData}
        striped={true}
        hover={true}
        search
        exportCSV
        csvFileName="listes-all-utilisateurs-except-admin.csv"
        searchPlaceholder="Rechercher bakeliste..."
        multiColumnSearch
        multiColumnSort={2}
        pagination
        bordered={true}
        options={options}
      >
        <TableHeaderColumn
          dataField="first_name"
                 filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
                 tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                 width="160"
        >
          <span className="color-nom-prenom-utilisateurs">Prénoms</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="last_name"
                 filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
                 tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                 width="150"
        >
          <span className="color-nom-prenom-utilisateurs">Noms</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          isKey={true}
          dataField="email"
                 filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
                 tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                 width="200"
        >
          <span className="color-nom-prenom-utilisateurs">Email</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="status"
                 filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
                 tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                 width="150"
        >
          <span className="color-nom-prenom-utilisateurs">Statut</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="action"
          dataFormat={actionFormatterUsers}
                 filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
                 tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                 width="90"
        >
          <span className="color-nom-prenom-utilisateurs"> Actions</span>
        </TableHeaderColumn>
      </BootstrapTable>
      <Modal className="modal-responsive-sm p-b-50" onHide={close} show={show}>
        <h5 className="modal-bakeliste-title m-b-50">
          Éditer les details de &nbsp; {userFirstname} &nbsp; {userLastname}
        </h5>
        <button className="close-modal-coach-title" onClick={close}>
          <FeatherIcon icon="x" aria-hidden="true" />
        </button>
        <div className="modal-body">
          <form className="form1" onSubmit={(e) => addEntite(e)}>
            <div className="row edit-coach-container">
              <div className="form-group ">
                <label class="label-form">Entité: {userEntite}</label>
                <select
                  className="form-control ab1 si-form-input "
                  id="entite"
                  name="entite"
                  value={userEntite}
                  onChange={(e) => setUserEntite(e.target.value)}
                  required
                >
                  <option> *Entité -- choisir une entité -- </option>
                  <option value="Volkeno"> Volkeno </option>
                  <option value="Bakeli"> Bakeli </option>
                  <option value="DefarSci"> DefarSci </option>
                  <option value="Craft-It"> Craft-It </option>

                </select>
              </div>
              <div className="form-group ">
                <label class="label-form">status: {userStatus}</label>
                <select
                  className="form-control ab1 si-form-input "
                  id="status"
                  name="status"
                  value={userStatus}
                  onChange={(e) => setUserStatus(e.target.value)}
                  required
                >
                  <option> -- Modifier Status -- </option>
                  <option value="bakeliste"> Bakeliste </option>
                  <option value="employe"> Employé </option>

                </select>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row">
                <div className="col-md-5">
                  <button
                    type="button"
                    className="btn btn-theme-danger"
                    onClick={close}
                  >
                    Quitter
                  </button>
                </div>
                <div className="col-md-7">
                  <button type="submit" className="btn btn-theme">
                    Modifier
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

const NavTabsUtilisateursRefac = () => {
  return (
    <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon
                    className="icon-breadcrumb-custom"
                    icon="users"
                  />
                  Utilisateurs
                </li>
              </ol>
            </nav>

            <Container fluid>
              <Row>
                <Col>
                  <Tabs defaultActiveKey="Admins" id="controlled-tab-example">
                    <Tab eventKey="Admins" title="Admins">
                      <ListeAdmins />
                    </Tab>
                    <Tab eventKey="Coachs" title="Coachs">
                      <ListeCoachs />
                    </Tab>
                    <Tab eventKey="Coach-Assistants" title="Coach-Assistants">
                      <ListeCoachAssistants />
                    </Tab>
                    <Tab eventKey="Participants" title="Participants">
                      <MyParticipants />
                    </Tab>
                    {/* <Tab eventKey="BF.Terminés" title="BF.Terminés">
                      <MyBfTerminer />
                    </Tab> */}
                    <Tab eventKey="Visiteurs" title="Visiteurs">
                      <TableMyVisiteurs />
                    </Tab>
                    <Tab eventKey="User Manager" title="User Manager">
                      <MyUserManager />
                    </Tab>
                    <Tab eventKey="All users" title="All users">
                      <AllUsers />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavTabsUtilisateursRefac;
