import React, { useEffect, useReducer } from "react";
import "./listeComptesBloques.css";
import axios from "axios";
import API from "../../variablesGlobales";
import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";

import { Tooltip } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
const ListeCompteBloquesRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      listebloques: [],
      api: API.API,
    }
  );

  useEffect(() => {
    getListesComptesBloques();
  }, []);
  const getListesComptesBloques = () => {
    axios
      .get(state.api + "blocked-user-list")
      .then((res) => {
        // console.log(res, "okkk");
        if (res.data.success === true) {
          setState({
            listebloques: res.data.data,
            // loading: true,
          });
        } else {
          setState({
            loading: false,
            isEmptyData: true,
          });
        }
        // setState({ isLoaded: false });
        // $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  const nameFormatter = (cell, row) => {
    return (
      <span>
        {/* <button> */}
        <Tooltip title="Voir la fiche">
          <Link
            className="linkName"
            to={{
              pathname: "/profil-utilisateur",
              state: { id: row.id, data: row },
            }}
          >
            {row.first_name} {row.last_name}{" "}
          </Link>
        </Tooltip>
        {/* </button> */}
      </span>
    );
  };
//   const options = {
//     exportCSVBtn: CustomExportCsvBtn,
//   };
  return (
    <div className="component-liste-bakelistes-pause">
      {" "}
      <BootstrapTable
        pagination
        exportCSV
        // options={options}
        csvFileName="liste-comptes-bloques.csv"
        data={state.listebloques}
        striped
        version="4"
        bordered={false}
        bodyStyle={{
          fontSize: 12,
          color: "rgba(0, 0, 0, 0.7)",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        tdStyle={{ background: "red" }}
        tableHeaderclassName="header-table-groupe-view"
        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
        className="table-responsive table-responsive-sm"
      >
        <TableHeaderColumn
          filter={{ type: "TextFilter", delay: 1000 }}
          dataField="first_name"
          dataFormat={nameFormatter}
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          isKey={true}
          dataField="email"
          width="200"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Email</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="phone"
          width="200"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Telephone</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="status"
          width="150"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Statut</span>
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

export default ListeCompteBloquesRefact;
