import React, {Component} from 'react';
import './navVerticalCoach.css';
import {NavLink} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

export default class navVerticalCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="container-nav-vertical no-view-tablette">
                <div className="nav flex-column nav-pills" id="v-pills-tabCustom" role="tablist" aria-orientation="vertical">
                  <NavLink className="nav-link nav-link-side-navbar-custom" to="/dashboard-coach">
                    <FeatherIcon className="icon-side-navbar-custom" icon="layout" />
                    Dashboard
                  </NavLink>
                  <NavLink className="nav-link nav-link-side-navbar-custom" to="/tache-coach">
                    <FeatherIcon className="icon-side-navbar-custom" icon="grid" />
                    Tâches
                  </NavLink>
                  <NavLink className="nav-link nav-link-side-navbar-custom" to="/livraison-coach">
                    <FeatherIcon className="icon-side-navbar-custom" icon="folder" />
                    Livraison
                  </NavLink>
                  <NavLink className="nav-link nav-link-side-navbar-custom" to="/groupe-coach">
                    <FeatherIcon className="icon-side-navbar-custom" icon="users" />
                    Groupe
                  </NavLink>
                  <NavLink className="nav-link nav-link-side-navbar-custom" to="/syllabus-coach">
                    <FeatherIcon className="icon-side-navbar-custom" icon="book" />
                    Syllabus
                  </NavLink>
                  <NavLink className="nav-link nav-link-side-navbar-custom bg-warning text-dark" to="/notification-exemples">
                    <FeatherIcon className="icon-side-navbar-custom" icon="bell" />
                    Notifications (Exemples)
                  </NavLink>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ navVerticalCoach }) => ({ ...navVerticalCoach }),
//     dispatch => bindActionCreators({ ...navVerticalCoachActions }, dispatch)
//   )( navVerticalCoach );