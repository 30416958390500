import React, {Component} from 'react';
import './ajoutCoachAssistantView.css';
  import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
  import NavVerticalAdmin from '../navVerticalAdmin/index';
  import ParametresAdmin from '../parametresAdmin/index';
  import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
  import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/bakelisteMissingItemForAdmin';
  import ProgramReunionCoach from '../programReunionCoach/programReunionCoach';
  import SavedReunionListItem from '../savedReunionListItem/savedReunionListItem';
  import NavTabsParametres from '../navTabsParametres/navTabsParametres';
import AjoutCoachAssistant from '../ajoutCoachAssistant/index';
  
  export default class ajoutCoachAssistantView extends Component {
      constructor(props) {
          super(props);
          this.state = {};
      }
      render() {
        return  <div className="body-theme">
                  <HeaderDashboardAdmin />
                  <div className="container-fluid fixing-after-header">
                    <div className="row">
                      <div className="col-xl-2 col-lg-2 p-0">
                        <NavVerticalAdmin />
                      </div>
                      <div className="col-lg-10 pt-3">
                      <AjoutCoachAssistant />
                      </div>
                    </div>
                </div>
             </div>;
      }
    }