import React, {Component} from 'react';
import './ajoutCoach.css';
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";

import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as ajoutCoachActions from "../../store/ajoutCoach/actions";
export default class ajoutCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {
          ajoutCoachInProgresse: false,
          domaines : [],
          api: API.API,
          fields: {},

        };
      }
      componentDidMount = () => {
        
        this.getAllDomaines();
       
      };
  
      addCoachHandleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        //console.log("handle change", e.target.value)
        this.setState({
          fields,
        });
        //console.log("handle change", this.state.fields);
      }
      getAllDomaines = () => {
        axios
          .get(this.state.api + "domaines")
          .then((res) => {
            const domains = res.data;
            this.setState({
              domaines: domains.data,
            });
            // console.log(this.state.domaines)
          })
          .catch((error) => {
            //console.log(error.message);
            if (process.env.NODE_ENV !== "production") {
              return;
            }
            // Sentry.captureException(error);
          });
      };
      validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["first_name"]) {
          formIsValid = false;
          this.setState({
            fn_empty: true,
          });
        }
    
        if (typeof fields["first_name"] !== "undefined") {
          if (
            !fields["first_name"].match(
              /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
            )
          ) {
            formIsValid = false;
            this.setState({
              fn_empty: true,
            });
          }
        }
    
        if (!fields["last_name"]) {
          formIsValid = false;
          this.setState({
            ln_empty: true,
          });
             }
    
        if (typeof fields["last_name"] !== "undefined") {
          if (
            !fields["last_name"].match(
              /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
            )
          ) {
            formIsValid = false;
            this.setState({
              ln_empty: true,
            });
           }
        }
    
        if (!fields["email"]) {
          formIsValid = false;
          this.setState({
            email_empty: true,
          });
          }
    
        if (typeof fields["email"] !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          );
          if (!pattern.test(fields["email"])) {
            formIsValid = false;
            this.setState({
              email_empty: true,
            });
              }
        }
    
        if (!fields["phone"]) {
          formIsValid = false;
          this.setState({
            phone_empty: true,
          });
          }
    
        if (!fields["coach_domaine_id"]) {
          formIsValid = false;
          this.setState({
            df_empty: true,
          });
             }
    
        
        this.setState({
          errors: errors,
        });
        return formIsValid;
      }
    ajoutCoach = (e) => {
      e.preventDefault();
  // console.log("hello Debut")
      if (this.validateForm()) {
        this.setState({
          ajoutCoachInProgresse: true,
        });
        let fields = {};
        fields["first_name"] = "";
        fields["last_name"] = "";
        fields["email"] = "";
        fields["phone"] = "";
        fields["coach_domaine_id"] = "";
        fields["status"] = "coach";
        this.setState({ fields: fields });
        const dataCoach = this.state.fields;
        // console.log(dataCoach);
        axios.post(this.state.api + "add-coach", dataCoach)
        .then((res) => {
          // console.log(res);
          if (res.status === 201 && res.statusText === "Created") {
            
            this.setState({
              ajoutCoachInProgresse: false,
              // AjoutCoachInfoSuccess: true,
            });
            toast.success(
              "Coach ajouté avec success",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 500 }
            );
            window.location = '/liste-coachs';
            

          } else {
            this.setState({
              ajoutCoachInProgresse: false,
              // AjoutCoachInfoSuccess:true
            });
            toast.error(
              "Erreur sur l'ajout de coach!!!",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 1000 }
            );
          }
        });
      }
    }

    render() {
      return  <div className="body-theme">
                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-xl-2 col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                     
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/liste-coachs" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="users"
                      />
                      Coachs
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="user"
                    />
                    Ajout Coach
                  </li>
                </ol>
              </nav>

        <form>
                <div className="row edit-coach-container">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Prénom : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.addCoachHandleChange}
                        name="first_name"
                      />
                    </div>
                  </div>
                  {this.state.fn_empty && (
                    <div className="errorMsg">
                      {this.state.errors.first_name}
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Nom : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.addCoachHandleChange}
                        name="last_name"
                        required
                      />
                    </div>
                  </div>
                  {this.state.ln_empty && (
                    <div className="errorMsg">
                      {this.state.errors.last_name}
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Email : </label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={this.addCoachHandleChange}
                        name="email"
                        required
                      />
                    </div>
                  </div>
                
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Téléphone : </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={this.addCoachHandleChange}
                        name="phone"
                        required
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Domaine :</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="coach_domaine_id"
                        onChange={this.addCoachHandleChange}
                        required
                      >
                        <option> -- Choisir un domaine -- </option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
               
             
                </div>
             
             
                {/* <button
                  type="button"
                  className="btn-theme-danger"
                >
                  <i class="fas fa-times-circle"></i> &nbsp; Quitter
                </button> */}

                {!this.state.ajoutCoachInProgresse && (
                  <button
                    type="button"
                    className="col-md-4 offset-md-6 btn-theme"
                    onClick={(e) => this.ajoutCoach(e)}
                  >
                     Ajouter
                  </button>
                )}
                {this.state.ajoutCoachInProgresse && (
                  <button
                    type="button"
                    className=" col-md-4 btn-theme-orange"
                  >
                    Ajout en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
         </form>
       
                    </div>
                  </div>
              </div>
           </div>;
    }
  }
    
// export default connect(
//     ({ ajoutCoach }) => ({ ...ajoutCoach }),
//     dispatch => bindActionCreators({ ...ajoutCoachActions }, dispatch)
//   )( ajoutCoach );