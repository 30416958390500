import React from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import DetailsBakelistePresentiel from "./DetailsBakelistePresentiel";
function DetailsBakelistePresentielView() {

    const location = useLocation()
    const {data} = location.state
  return (
    <div className="body-theme bakelisteDash">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <div className="p-b-200 vh-100">
              <div className="col-lg-10 mt-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard-admin" className="lien_nav_current">
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link
                        to="/all-bakelistes-presentiel"
                        className="lien_nav_current"
                      >
                        Bakelistes en presentiels
                      </Link>
                    </li>
                    <li className="breadcrumb-item lien_nav_current">Details  {data.first_name} {data.last_name}</li>
                  </ol>
                </nav>
              </div>
              <div className="row mx-2">
                <DetailsBakelistePresentiel />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsBakelistePresentielView;
