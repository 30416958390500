import React, { Component } from "react";
import "./formationAdmin.css";
// import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
// import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
// import ProgramReunionCoach from '../programReunionCoach/index';
// import SavedReunionListItem from '../savedReunionListItem/index';
import Tools from "../tools/index";
import { Link } from "react-router-dom";
import AddFormation from "../addFormation/index";

export default class formationAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="row m-b-15">
                <div className="col m-b-15">
                  <h6 className="h-theme ff-inter fw-600 px-3 pt-3">Formation</h6>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-10 col-lg-10 col-md-8">
                    {/* <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
    

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav mr-auto">
                            <li class="nav-item active">
                                <a class="nav-link px-0" href="">Outils<span class="sr-only">(current)</span></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link ml-5" href="domaine-admin">Domaine</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link ml-5" href="technologie-admin">Technologie</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link ml-5" href="syllabus-admin">Syllabus</a>
                            </li>
                            </ul>

                        </div>
                        </nav> */}
                  </div>
                  <div className="col dis-flex m-b-15">
                    <AddFormation />
                  </div>
                </div>
              </div>

              <div className="card-dashboard m-b-15 p-b-60">
                <div className="row m-b-30 m-t-20">
                  <div className="col-xl-9 col-lg-8 col-md-9 col-sm-8 col-8 m-b-15 dis-flex itm-center">
                    <h5 className="h-theme ff-inter fw-500">
                      Listes des outils
                    </h5>
                  </div>
                </div>

                <Tools />
              </div>
            </div>

            {/* <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </div>
    );
  }
}
