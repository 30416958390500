import React, { Component } from "react";
import "./listeFillieresAdmin.css";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { AutoComplete, Icon, InputGroup } from "rsuite";
import SyllabusFiliéreProgrammation from "../syllabusFiliéreProgrammation/index";
import SyllabusFiliéreDisign from "../syllabusFiliéreDisign/index";
import SyllabusFiliéreGestiondeprojet from "../syllabusFiliéreGestiondeprojet";
import SyllabusFiliéreMarketingDigital from "../syllabusFiliéreMarketingDigital";

import AddFiliére from "../addFiliére/index";
import { Link } from "react-router-dom";

const styles = {
  marginBottom: 30,
};

const data = [
  "Developpement web",
  "Android developpeur",
  "UX/UI Design",
  "Gestion de projet",
  "Infographie",
];

export default class listeFillieresAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              {/* <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav">
                      Dashboard /{" "}
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav_current"
                  >
                    <Link to="/parametres-admin" className="lien_nav">
                      {" "}
                      Parametre /{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/listeDomainesAdmin" className="lien_nav">
                      &nbsp; &nbsp; Liste des Fillières{" "}
                    </Link>
                  </li>
                </ol>
              </nav> */}
              {/* <div className="row m-b-15">
                <div className="col m-b-15">
                  <h6 className="h-theme ff-inter fw-600 px-3">
                    {" "}
                    Gestion des Fillières
                  </h6>
                </div>
              </div> */}
            

            {/* <div className="row">
              <div className="row m-b-15">
                <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
                  <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul class="navbar-nav mr-auto">
                      <li class="nav-item">
                        <a class="nav-link ml-4 lfb" href="parametres-admin">
                          Liste de formation de Bakeli
                          <span class="sr-only">(current)</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link ml-4" href="listeDomainesAdmin">
                          Liste des domaines
                        </a>
                      </li>
                      <li class="nav-item active">
                        <a class="nav-link ml-4" href="listeFillieresAdmin">
                          Liste des filières
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link ml-4" href="creneauxHorairesAdmin">
                          Créneaux horaire
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link ml-4" href="riAdmin">
                          RI
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div> */}
          
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5">
              <InputGroup inside style={styles}>
                <InputGroup.Addon>
                  <Icon icon="" />
                </InputGroup.Addon>
                <AutoComplete
                  data={data}
                  placeholder="Chercher un Domaine"
                  className="input-syllabus-search"
                />
              </InputGroup>
            </div>
            <div className="col dis-flex m-b-15">
              <AddFiliére />
            </div>
          </div>

          <div className="row px-lg-0 px-md-2">
            <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
              <SyllabusFiliéreProgrammation />
            </div>
            <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
              <SyllabusFiliéreMarketingDigital />
            </div>
            <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
              <SyllabusFiliéreGestiondeprojet />
            </div>
            <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
              <SyllabusFiliéreDisign />
            </div>
          </div>
        
      </div>

        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    );
  }
}
