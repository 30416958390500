import React, {Component} from 'react';
import './tableMyUserManager.css';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import API from "../../variablesGlobales";
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyUserManagerActions from "../../store/tableMyUserManager/actions";



class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="refresh-cw" size={18} />
        </button>
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="send" size={18} />
        </button>
        
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}



export default class tableMyUserManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          admins: [],
        };
    }

    componentDidMount() {
      this.getListUserManager();
    }

    getListUserManager = () => {
      axios
        .get(this.state.api + "list-admins")
        .then((res) => {
          if (res.data.success === true) {
        //  console.log('admins', res.data.data)
            this.setState({
              admins: res.data.data,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              isEmptyData: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error.message);
        });
    };

        //recuperation du nom et prenom dans un meme colonnne
        nameFormatter(cell, row) {
          return `${row.first_name} ${row.last_name}`
      }

    CustomExportCsvBtn = (onClick) =>{
      return(
        <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
      );
    }
    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      };
      return (
              <div className="component-table-my-user-manager">
                <BootstrapTable 
                  pagination
                  exportCSV
                  options={options}
                  csvFileName="utilisateurs.csv"
                  data={this.state.admins} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                    
                  <TableHeaderColumn  
                    filter={{type:"TextFilter", delay: 1000}} 
                    dataField='first_name'  
                    dataFormat={this.nameFormatter} 
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Nom Complet</span> 
                    </TableHeaderColumn>
                 
                     
                     <TableHeaderColumn 
                      isKey={true}
                      dataField='email' width="200" 
                      filter={{type:"TextFilter", delay: 1000}}                    
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Email</span>
                     </TableHeaderColumn>
                     <TableHeaderColumn 
                      dataField='phone' width="200" 
                      filter={{type:"TextFilter", delay: 1000}}                    
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Telephone</span>
                     </TableHeaderColumn>

                    

                     <TableHeaderColumn 
                      dataField='status' width="150"  
                      filter={{type:"TextFilter", delay: 1000}}                   
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Statut</span>
                     </TableHeaderColumn>

                  <TableHeaderColumn dataField='action' 
                  dataFormat={uploadFormatter}                  
                  thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                  tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs"> Actions</span>
                    </TableHeaderColumn>
                </BootstrapTable>
                </div>
        );
    }
  }
// export default connect(
//     ({ tableMyUserManager }) => ({ ...tableMyUserManager }),
//     dispatch => bindActionCreators({ ...tableMyUserManagerActions }, dispatch)
//   )( tableMyUserManager );