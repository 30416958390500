import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../variablesGlobales";
import MultiStepForm from "./multiStep/MultiStepForm";
// import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import BakelisteInfosApercu from "./bakelisteInfosApercu/BakelisteInfosApercu";
import { onFID } from "web-vitals";

const AjouterBakelisteRefac = () => {
  const days = ["lundi", "mardi", "mercredi", "jeudi", "vendredi"];
  const [page, setPage] = useState(0);
  const [domaines, setDomaines] = useState([]);
  const [coachs, setCoachs] = useState([]);
  const [apercu, setApercu] = useState(false);
  const [coach_Assistant,setCoach_Assistants] = useState([])
  const [bakelisteInfos, setBakelisteInfos] = useState({
    civility: "",
    marital_status: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    telephone: "",
    date_naissance: "",
    address: "",
    ecole: "",
    //informations sur la formation
    type_formation: "",
    is_pro: 0,
    is_etudiant: 0,
    is_etudiant_a_tester: 0,
    is_week: 0,
    is_weekend: 0,
    nbre_jours: 0,
    horaire: null,
    jour_1: "",
    jour_2: "",
    jour_3: "",
    jour_4: "",
    jour_5: "",
    jour_6: "",
    jour_fd: "",
    domaines_id: null,
    is_formation_payante: null,
    paiement_inscription: null,
    is_stagede_formation: null,
    lieu_formation: "",
    bakeliste_level: "",
    training_duration: "",
    // !autres infos
    debut_formation: "",
    fin_formation: "",
    objectifs: "",
    commentaire: "",
    demi_boursier: 0,
    is_isa_contract: 0,
    coach_id: null,
    coach_assistant_id: null
  });

  // onFID(console.log);

  useEffect(() => {
    const getDomaines = async () => {
      try {
        const {
          data: { data },
        } = await axios(API + "domaines");
        setDomaines(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getCoachs = async () => {
      try {
        const {
          data: { data },
        } = await axios(API + "liste-des-coachs");
        setCoachs(data);
      } catch (error) {
        console.log(error);
      }
    };
    const getListCoachAssistant = async() => {
     try {
      const {
        data : {data}
      } = await axios(API + "liste-des-coachs-assistants");
      setCoach_Assistants(data)
     } catch (error) {
      console.log(error)
     }
    };
    getDomaines();
    getCoachs();
    getListCoachAssistant()
  }, []);
  return (
    <section
      className="mx-auto"
      style={{
        maxWidth: "1300px",
        minHeight: "70vh",
        padding: "2rem 0 0 0",
        margin: "0rem 0 10rem 0",
      }}
    >
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb cd-breadcrumb custom-separator custom-icons">
          <li className="breadcrumb-item">
            <Link to="/dashboard-admin" className="lien_nav_current">
              Dashboard
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/bakelistes_new" className="lien_nav_current">
              Dashboard Bakeliste
            </Link>
          </li>
          <li className="breadcrumb-item active lien_nav" aria-current="page">
            {/* <FeatherIcon /> */}
            Ajouter bakeliste
          </li>
        </ol>
      </nav>

      {!apercu ? (
        <MultiStepForm
          apercu={apercu}
          setApercu={setApercu}
          days={days}
          domaines={domaines}
          coachs={coachs}
          page={page}
          setPage={setPage}
          bakelisteInfos={bakelisteInfos}
          setBakelisteInfos={setBakelisteInfos}
          coachAssistants={coach_Assistant}
        />
      ) : (
        <BakelisteInfosApercu
          route="add-bakeliste"
          requestMethod="post"
          domaines={domaines}
          bakelisteInfos={bakelisteInfos}
          setApercu={setApercu}
          setPage={setPage}
        />
      )}
    </section>
  );
};

export default AjouterBakelisteRefac;
