import React, {Component} from 'react';
import './tableMyTechnologies.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';


var gestionTacheForCoach = [{
  fullname: "Reactjs",
  action: "oui"
},{
  fullname: "HTLM5",
  action: "oui "
},{
  fullname: "CSS3",
  action: "oui "
},{
  fullname: "Android",
  action: "non "
},{
  fullname: "WordPress",
  action: "oui "
},{
  fullname: "Windev",
  action: "non "
}];

class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col">
        <p className="badge todo-badge">To do</p>

        {/* !!!!important!!!! */}
        {/* <p className="badge doing-badge">Doing</p> */}
        {/* <p className="badge done-badge">Done</p> */}
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return (
    <Status active={ cell } />
  );
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="trash" size={16} />
        </button>
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}

export default class tableMyTechnologies extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
                <BootstrapTable 
                  data={gestionTacheForCoach} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                   <TableHeaderColumn 
                    
                    isKey filter={{type:"TextFilter", delay: 1000}} 
                    dataField='fullname' 
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Technologies</span> 
                    </TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='action' dataFormat={uploadFormatter} thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1rem' }}>Actions</TableHeaderColumn>
                </BootstrapTable>
              </div>;
    }
  }
