import React, { Component, useState } from "react";
import "./createEvent.css";
import { Link } from "react-router-dom";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import FeatherIcon from "feather-icons-react";
import TofCreerEvent from "../../img/tofCreerEvent.png";
import ProfilCreer from "../../img/profilCreer.png";
import axios from "axios";
import API from "../../variablesGlobales";
// import { Modal } from 'antd';
import { toast } from "react-toastify";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as createEventActions from "../../store/createEvent/actions";
export default class createEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_meetup: false,
      is_atelier_pro: false,
      loading: false,
      api: API.API,
      is_meetup: 0,
      is_atelier_pro: 0,
      is_payant: 0,
      prix_participation: 0,
      visible: false,
      rencontre_file: "",
      file: null,
      fileDeux: null,
      rencontre_slug: "Bakeli",
    };
    this.handleChange = this.handleChange.bind(this);
    this.change = this.change.bind(this);
  }

  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }
  change(event) {
    this.setState({
      fileDeux: URL.createObjectURL(event.target.files[0]),
    });
  }

  addEvents(e) {
    e.preventDefault();
    // console.log("gooooo");
    // console.log("hello");

    // console.log(parseInt(this.state.is_payant));
    // console.log(this.state.is_payant);
    this.setState({
      loading: true,
    });

    var fd = new FormData();
    fd.append("rencontre_name", this.state.rencontre_name);
    fd.append("rencontre_type", this.state.rencontre_type);
    fd.append("rencontre_start_date", this.state.rencontre_start_date);
    fd.append("rencontre_start_time", this.state.rencontre_start_time);
    fd.append("rencontre_end_time", this.state.rencontre_end_time);
    fd.append("rencontre_slug", this.state.rencontre_slug);
    // fd.append("is_payant", parseInt(this.state.is_payant));
    fd.append("is_payant", this.state.is_payant);
    // if (this.state.is_payant) {
    //   fd.append("prix_participation", 0);
    // }
    fd.append("rencontre_end_date", this.state.rencontre_end_date);
    fd.append("prix_participation", this.state.prix_participation);
    fd.append("rencontre_description", this.state.rencontre_description);
    fd.append("rencontre_file", this.state.rencontre_file);
    if (this.state.is_meetup) {
      fd.append("is_meetup", 1);
      fd.append("is_atelier_pro", 0);
    } else if (this.is_atelier_pro) {
      fd.append("is_meetup", 0);
      fd.append("is_atelier_pro", 1);
    }
    axios
      .post(this.state.api + "add-rencontre", fd)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            loading: false,
          });
          toast.success(
            "évenement ajouté avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 3000 }
          );
          window.location = "/evenements";
        } else {
          // toast.error(
          //   "erreur sur l ajout d evenement",
          //   { position: toast.POSITION.BOTTOM_LEFT },
          //   { autoClose: 3000 }
          // );
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          loading: false,
        });
      });
  }

  show = () => {
    this.setState({
      is_payant: 1,
    });
  };
  isMeetup = () => {
    this.setState({
      is_meetup: 1,
      is_atelier_pro: 0,
    });
  };
  isAtelierPro = () => {
    this.setState({
      is_meetup: 0,
      is_atelier_pro: 1,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    // console.log(e);
    this.setState({
      visible: true,
    });
  };

  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      visible: true,
    });
  };

  handleSubmit(e) {
    e.preventDefault();
    let activeP = this.state.active === "edit" ? "profile" : "edit";
    this.setState({
      active: activeP,
    });
  }

  handlefileChange = (e) => {
    // console.log(e.target.files[0]);
    if (e.target.name === "rencontre_file") {
      if (this.fileValidation("rencontre_file")) {
        this.setState({
          rencontre_file: e.target.files[0],
        });
        // fields["rencontre_file"] = e.target.files[0];
      } else {
        this.setState({
          isInvalidFileExtension: true,
        });
        setTimeout(() => {
          this.setState({
            isInvalidFileExtension: false,
          });
        }, 6000);
      }
    }
    // console.log(this.state.rencontre_file);
  };

  fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { active } = this.state;
    return (
      <div className="component-create-event">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      Dashboard{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/evenements" className="lien_nav">
                      evenements{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    créer evenements{" "}
                  </li>
                </ol>
              </nav>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <h6 className="h-theme ff-inter fw-600 px-3">
                    {" "}
                    {/* <FeatherIcon icon="arrow-left" />
                    Créer Evenements */}
                  </h6>
                </div>
              </div>

              <div className="container-fluid">
                <div className="card mb-3">
                  <div className="card-body">
                    {/* <div className="reglage-petit-icon-edit-img">
                      <input
                        type="file"
                        className="dep-petit-input-profile-event"
                        name="rencntre_file"
                        placeholder="choisir une image"
                        onChange={this.change}
                      />
                      {
                        active === "edit" && (
                          <img
                            src={TofCreerEvent}
                            className="img-profil-event"
                            onSubmit={(e) => this.handleSubmit(e)}
                            alt=""
                          />
                        )
                        // : (
                        //   <img
                        //     src={this.state.fileDeux}
                        //     className="img-profil-event"
                        //     onSubmit={(e) => this.handleSubmit(e)}
                        //     alt=""
                        //   />
                        // )
                      }

                       <div>
                        <i className="fas fa-pen icon-petit-posit d-flex justify-content-around p-2"></i>
                      </div> 
                    </div>
                   */}
                    <form
                      onSubmit={(e) => this.addEvents(e)}
                      encType="multipart/form-data"
                    >
                      <div className="reglement-affichage-et-clique">
                        <input
                          type="file"
                          onChange={(e) => this.handlefileChange(e)}
                          name="rencontre_file"
                          placeholder="Pièce jointe"
                          className="form-control input_item"
                          id="rencontre_file"
                        />
                      </div>
                      <div className="row mb-3 pt-3">
                        <label
                          htmlfor="inputEmail3"
                          className="col-form-label pb-3 pl-3"
                        >
                          Nom de l'événement
                        </label>
                        <div className="col-sm-12">
                          <input
                            onChange={(e) =>
                              this.setState({ rencontre_name: e.target.value })
                            }
                            type="text"
                            name="rencontre_name"
                            className="form-control taille-des-input-event"
                            id="inputEmail3"
                          />
                          {/* <span className="dep-crt-caractere offset-11">0/75</span> */}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlfor="inputPassword3"
                          className="col-form-label pb-3 pl-3"
                        >
                          Type de la rencontre
                        </label>
                        <div className="col-sm-12">
                          <select
                            onChange={(e) =>
                              this.setState({ rencontre_type: e.target.value })
                            }
                            className="form-control form-select taille-des-input-event"
                            name="rencontre_type"
                          >
                            <option selected> -- choisir une option-- </option>
                            <option value="evenement">Evenement</option>
                            <option value="workshop">Workshop</option>
                            <option value="webinaire">Webinaire</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-12 pt-2 form-group assignation-type-choice">
                        <div className="item-label col-form-label">
                          Choisir un type pour la rencontre
                        </div>
                        <input
                          type="radio"
                          name="status"
                          value="is_meetup"
                          className="item1"
                          onClick={this.isMeetup}
                        />{" "}
                        <span className="radioItem col-form-label">
                          Meet up
                        </span>{" "}
                        <br />
                        <input
                          type="radio"
                          name="status"
                          value="is_atelier_pro"
                          className="item2"
                          onClick={this.isAtelierPro}
                        />{" "}
                        <span className="radioItem col-form-label">
                          Atelier pro
                        </span>
                      </div> */}

                      <label
                        htmlfor="inputPassword3"
                        className="col-form-label"
                      >
                        Rencontre payant
                      </label>
                      <div className="form-check-inline ali">
                        <input
                          type="radio"
                          id="huey"
                          name="is_payant"
                          value="1"
                          onChange={(e) =>
                            this.setState({ is_payant: e.target.value })
                          }
                        />
                        <label className="col-md-3 taille-option-non-oui">
                          Oui
                        </label>
                        <input
                          onChange={(e) =>
                            this.setState({ is_payant: e.target.value })
                          }
                          type="radio"
                          id="dewey"
                          name="is_payant"
                          value="0"
                        />
                        <label className="col-md-3 taille-option-non-oui">
                          Non
                        </label>
                      </div>

                      {this.state.is_payant == 1 && (
                        <div className="row mb-3 pt-3">
                          <label
                            htmlfor="inputEmail3"
                            className="col-form-label pb-3 pl-3"
                          >
                            Ajouter un prix pour l'accès
                          </label>
                          <div className="col-sm-12">
                            <input
                              onChange={(e) =>
                                this.setState({
                                  prix_participation: e.target.value,
                                })
                              }
                              type="text"
                              name="prix_participation"
                              className="form-control taille-des-input-event"
                              id="inputEmail3"
                            />
                          </div>
                        </div>
                      )}

                      <div className="row mb-3">
                        <div className="col-lg-6 col-xl-6 col-md-6">
                          <div className="row">
                            <div className="col-lg-6 col-xl-6 col-md-6">
                              <label className="col-form-label pb-3">
                                Date de début
                              </label>
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    rencontre_start_date: e.target.value,
                                  })
                                }
                                type="date"
                                id="start"
                                name="rencontre_start_date"
                                className="supp-taille-input"
                              />
                            </div>
                            <div className="col-lg-6 col-xl-6 col-md-6">
                              <label className="col-form-label pb-3">
                                Heure de début
                              </label>
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    rencontre_start_time: e.target.value,
                                  })
                                }
                                type="time"
                                id="appt"
                                name="rencontre_start_time"
                                className="supp-taille-input"
                                placeholder="09:00"
                                min="09:00"
                                max="18:00"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-6">
                          <div className="row">
                            <div className="col-lg-6 col-xl-6 col-md-6">
                              <label className="col-form-label pb-3">
                                Date de fin
                              </label>
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    rencontre_end_date: e.target.value,
                                  })
                                }
                                type="date"
                                id="start"
                                name="rencontre_end_date"
                                className="supp-taille-input"
                                placeholder="2021-01-05"
                                // min="2021-01-05"
                                // max="2021-06-01"
                              />
                            </div>
                            <div className="col-lg-6 col-xl-6 col-md-6">
                              <label className="col-form-label pb-3">
                                Heure de fin
                              </label>
                              <input
                                onChange={(e) =>
                                  this.setState({
                                    rencontre_end_time: e.target.value,
                                  })
                                }
                                type="time"
                                id="appt"
                                name="rencontre_end_time"
                                className="supp-taille-input"
                                placeholder="17:00"
                                min="09:00"
                                max="18:00"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3 pt-3">
                        <label
                          htmlfor="inputEmail3"
                          className="col-form-label pb-3 pl-3"
                        >
                          Description
                        </label>
                        <br />
                        <div className="col-sm-12">
                          <div className="la-couleur-p mb-2">
                            Ajouter des détails tels que des sujets, programmes
                            ...
                          </div>
                          <textarea
                            type="text"
                            rows="1"
                            cols="5"
                            name="rencontre_description"
                            className="form-control taille-des-input-event"
                            id="inputEmail3"
                            onChange={(e) =>
                              this.setState({
                                rencontre_description: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                      </div>

                      <div>
                        {!this.state.loading ? (
                          <button className="col-md-4 btn btn-theme">
                            <i className="fas fa-plus-circle"></i> &nbsp; Créer
                          </button>
                        ) : (
                          <button className="col-md-4 offset-2 btn btn-theme loading-btn">
                            Création en cours &nbsp;
                            <i className="fas fa-spin fa-spinner"></i>
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              {/* <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ createEvent }) => ({ ...createEvent }),
//     dispatch => bindActionCreators({ ...createEventActions }, dispatch)
//   )( createEvent );
