import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";
import { Provider } from "react-redux";
// import Connexion from "./components/connexion/index";
// import DashboardBakelisteView from "./components/dashboardBakelisteView/index";
// import TacheBakelisteView from "./components/tacheBakelisteView/index";
// import LivrableBakelisteView from "./components/livrableBakelisteView";
// import GroupeBakelisteView from "./components/groupeBakelisteView/index";
// import DashboardCoachView from "./components/dashboardCoachView/index";
// import TacheCoachView from "./components/tacheCoachView/index";
// import LivrableCoachView from "./components/livrableCoachView";
// import GroupeCoachView from "./components/groupeCoachView/index";
// import SyllabusCoachView from "./components/syllabusCoachView/index";
// import SyllabusDetailsCoachView from "./components/syllabusDetailsCoachView/index";
// import SyllabusBakelisteView from "./components/syllabusBakelisteView/index";
// import NotificationExemples from "./components/notificationPage/index";
// import AccountBakelisteView from "./components/accountBakelisteView/index";
// import AccountCoachView from "./components/accountCoachView/index";
// import SyllabusDetailsBakelisteView from "./components/syllabusDetailsBakelisteView/index";

// import DashboardAdminView from "./components/dashboardAdminView/index";
// import ControleAdminView from './components/controleAdminView/index';
// import FormationAdminView from './components/formationAdminView/index';
// import BakelistesAdminView from './components/bakelistesAdminView/index';
// import UsersAdminView from './components/usersAdminView/index';
// import LivrablesAdminView from './components/livrablesAdminView/index';
// import ArticlesAdminView from './components/articlesAdminView/index';
// import AnnoncesAdminView from './components/annoncesAdminView/index';
// import PointageAdminView from './components/pointageAdminView/index';
// import ParametresAdminView from './components/parametresAdminView/index';
// import ListeDomainesAdminView from './components/listeDomainesAdminView/index';
// import ListeFillieresAdminView from './components/listeFillieresAdminView/index';
// import CreneauxHorairesAdminView from './components/creneauxHorairesAdminView/index';
// import RiAdminView from './components/riAdminView/index';
// import ReportingAdminView from './components/reportingAdminView/index';
// import IntermediaireAdminView from './components/intermediaireAdminView/index';
// import DiplomeAdminView from './components/diplomeAdminView/index';
// import DomaineAdminView from './components/domaineAdminView/index';
// import TechnologieAdminView from './components/technologieAdminView/index';
// import SyllabusAdminView from './components/syllabusAdminView/index';
// import CoachAdminView from './components/coachAdminView/index';
// import TableMyAllbakelistesView from './components/tableMyAllbakelistesView/index';
// import TableMyParticipantsView from './components/tableMyParticipantsView/index';
// import TableMyBfTerminerView from "./components/tableMyBfTerminerView/index";
// import TableMyVisiteursView from "./components/tableMyVisiteursView/index";
// import MyUserManagerView from "./components/myUserManagerView/index";
// import ListeBakelisteThiesView from "./components/listeBakelisteThiesView/index";
// import ListeBakelisteDakarView from "./components/listeBakelisteDakarView/index";
// import ListeBakelisteLabeView from "./components/listeBakelisteLabeView/index";
// import ListeAllPointageView from "./components/listeAllPointageView/index";
// import AllListeAbsencesView from "./components/allListeAbsencesView/index";
// import AllListeRetardView from "./components/allListeRetardView/index";
// import RessetPassword from './components/ressetPassword/index';

// import EventView from "./components/eventView/index";
// import StatistiqueAdminView from  "./components/statistiqueAdminView/index";
// import CreateEventView from "./components/createEventView/index";
// import ListeEventView from "./components/listeEventView/index";
// import ProposEventView from "./components/proposEventView/index";
// import ProfilAdminView from "./components/profilAdminView/index";
// import OrganiserReunionView from "./components/organiserReunionView/index";
// import ProfilVisiteurView from "./components/profilVisiteurView/index";
// import passwordReset from "./components/passwordReset/index";
// import ProfilBakelisteView from "./components/profilBakelisteView/index";
// import NavTabsProfilBakelisteView from "./components/navTabsProfilBakelisteView/index";
// import VisiteursAdminView from "./components/visiteursAdminView/index";
// import DetailsBakelisteView from "./components/detailsBakelisteView/index";

// document.addEventListener("DOMContentLoaded", function () {
//   ReactDOM.render(
//     <Router>
//       <div>
//         <Route exact path="/" component={Connexion} />
//         <Route path="/dashboard-bakeliste" component={DashboardBakelisteView} />
//         <Route path="/tache-bakeliste" component={TacheBakelisteView} />
//         <Route path="/livraison-bakeliste" component={LivrableBakelisteView} />
//         <Route path="/groupe-bakeliste" component={GroupeBakelisteView} />
//         <Route path="/syllabus-bakeliste" component={SyllabusBakelisteView} />
//         <Route path="/reinitialiser-mot-de-passe" component={RessetPassword} />
//         <Route path="/modifier-mot-de-passe" component={passwordReset} />

//         <Route
//           path="/syllabus-details-bakeliste/:id"
//           component={(props) => (
//             <SyllabusDetailsBakelisteView id={props.match.params.id} />
//           )}
//         />
//         <Route path="/compte-bakeliste" component={AccountBakelisteView} />

//         <Route path="/dashboard-coach" component={DashboardCoachView} />
//         <Route path="/tache-coach" component={TacheCoachView} />
//         <Route path="/livraison-coach" component={LivrableCoachView} />
//         <Route path="/groupe-coach" component={GroupeCoachView} />
//         <Route path="/syllabus-coach" component={SyllabusCoachView} />
//         <Route
//           path="/syllabus-details-coach"
//           component={SyllabusDetailsCoachView}
//         />
//         <Route path="/notification-exemples" component={NotificationExemples} />
//         <Route path="/compte-coach" component={AccountCoachView} />

//         <Route path="/dashboard-admin" component={DashboardAdminView} />
//         <Route path="/statistique-admin" component={StatistiqueAdminView} />
//         <Route path="/controle-admin" component={ControleAdminView} />
//         <Route path="/formation-admin" component={FormationAdminView} />
//         <Route path="/bakelistes-admin" component={BakelistesAdminView} />
//         <Route path="/users-admin" component={UsersAdminView} />
//         <Route path="/livrables-admin" component={LivrablesAdminView} />
//         <Route path="/articles-admin" component={ArticlesAdminView} />
//         <Route path="/annonces-admin" component={AnnoncesAdminView} />
//         <Route path="/pointage-admin" component={PointageAdminView} />
//         <Route path="/parametres-admin" component={ParametresAdminView} />
//         <Route path="/listeDomainesAdmin" component={ListeDomainesAdminView} />
//         <Route path="/listeFillieresAdmin" component={ListeFillieresAdminView} />
//         <Route path="/creneauxHorairesAdmin" component={CreneauxHorairesAdminView} />
//         <Route path="/riAdmin" component={RiAdminView} />
//         <Route path="/intermediaire-admin" component={IntermediaireAdminView} />
//         <Route path="/controle_admin/reporting-admin" component={ReportingAdminView} />
//         <Route path="/diplome-admin" component={DiplomeAdminView} />
//         <Route path="/domaine-admin" component={DomaineAdminView} />
//         <Route path="/technologie-admin" component={TechnologieAdminView} />
//         <Route path="/syllabus-admin" component={SyllabusAdminView} />
//         <Route path="/utilisateurs-coach-admin" component={CoachAdminView} />
//         <Route path="/all-bakelistes-admin" component={TableMyAllbakelistesView} />
//         <Route path="/participants-admin" component={TableMyParticipantsView} />
//         <Route path="/bf-bakelistes-admin" component={TableMyBfTerminerView} />
//         <Route path="/visiteurs-admin" component={TableMyVisiteursView} />
//         <Route path="/user-menager-admin" component={MyUserManagerView} />
//         <Route path="/bakelistes-admin-dakar" component={ListeBakelisteDakarView} />
//         <Route path="/bakelistes-admin-thies" component={ListeBakelisteThiesView} />
//         <Route path="/bakelistes-admin-labe" component={ListeBakelisteLabeView} />
//         <Route path="/listeAllPointage" component={ListeAllPointageView} />
//         <Route path="/liste-absences" component={AllListeAbsencesView} />
//         <Route path="/liste-retards" component={AllListeRetardView} />

//         <Route path="/evenements" component={EventView} />
//         <Route path="/create-event" component={CreateEventView} />
//         <Route path="/liste-des-evenements" component={ListeEventView} />
//         <Route path="/a-propos-des-evenements" component={ProposEventView} />
//         <Route path="/profil-admin" component={ProfilAdminView} />
//         <Route path="/organiser-reunion" component={OrganiserReunionView} />
//         <Route path="/profil-visiteur" component={ProfilVisiteurView} />
//         <Route path="/profil-bakeliste" component={DetailsBakelisteView} />

//         <Route path="/profil-du-bakeliste" component={ProfilBakelisteView} />
//         <Route path="/liste-des-bakelistes-en-formation" component={NavTabsProfilBakelisteView}/>
//         <Route path="/visiteurs-folder" component={VisiteursAdminView} />

//       </div>
//     </Router>,
//     document.getElementById("root")
//   );
// });
// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

document.addEventListener("DOMContentLoaded", function () {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,

    document.getElementById("root")
  );
});
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
