import React, {Component} from 'react';
import './listeSousGroupe.css';
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from 'rsuite';
import ListeSousGroupesAdmin from "../../components/listeSousGroupesAdmin/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeSousGroupeActions from "../../store/listeSousGroupe/actions";
export default class listeSousGroupe extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  addSousGroupe = () => {
    window.location="/ajout-sous-groupe"
  }
  render() {
    return <div className="p-b-200">
      <div className="row">
        <div className="col-lg-9">
    
      <div className="row m-b-15">
        <div className="col m-b-15">
          <h6 className="h-theme ff-inter fw-600 px-3"> Gestion des sous Groupes</h6>
        </div>
      </div>
     
             <div className="row">
             <div className="col-md-10">
</div>

               <div className="col-md-2">
                    <button className="btn-add-with-icon-plus-circle offset" onClick={this.addSousGroupe}>
                Ajouter
                <Icon className="icon-add ml-2" icon="plus-circle" /> 
              </button>
           
         </div>
</div>
          <div className="row px-lg-0 px-md-2"> 
            <ListeSousGroupesAdmin  />
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          {/* <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div> */}
        </div>
      </div>
    </div>;
  }
}
