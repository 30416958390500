import React, { Component } from "react";
import "./statisticsAdmin.css";

import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

export default class statisticsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row m-t-60 m-b-30">
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard ">
            <Link to="/users-admin" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {" "}
                  {this.props.users}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Total
                </p>
                <div className="eclipse1">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="users"
                  />
                  {/* <img
                    className="img-item-statistics-dashboard styleicon"
                    src={IconStat3}
                    alt=""
                  /> */}
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard ">
            <Link to="/bakelistes-db" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.bakelistes}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Bakelistes
                </p>
                <div className="eclipse1">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="user-check"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 pr-md pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <Link to="/users-admin" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.visiteurs}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  visiteurs
                </p>
                <div className="eclipse1">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="user-minus"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <Link to="/users-admin" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.coaches}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Coachs
                </p>
                <div className="eclipse1">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="user-plus"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 pr-md pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <Link to="/users-admin" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.coach_ass}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Coach-assistants
                </p>
                <div className="eclipse1">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="user"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <Link to="/users-admin" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.admins}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Admins
                </p>
                <div className="eclipse1">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="user-check"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
