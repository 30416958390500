import React, { Component } from 'react';
import './listeSousDomainesAdmin.css';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';

import AddSousDomaines from '../../components/addSousDomaines/index';
import { Link } from 'react-router-dom';
import API from "../../variablesGlobales";
// import TableMyDomaine from "../../components/tableMyDomaine/index"
import axios from 'axios';
import $ from "jquery"; 
import TableSousDomaines from '../tableSousDomaines/index';
const styles = {
  marginBottom: 30
};

const data = [
  'Developpement web',
  'Android developpeur',
  'UX/UI Design', 
  'Gestion de projet',
  'Infographie',
];

export default class listeSousDomainesAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allSousDomaines : [],
      api: API.API
    };
  }
  componentDidMount = () => {
    
    this.getListAllSousDomaines();
   
  };

  // getAllSousDomaines = () => {
  //   axios
  //     .get(this.state.api + "sous_domaines")
  //     .then((res) => {
  //       const domains = res.data;
  //       this.setState({
  //         sousDomaines: domains.data,
  //       });
  //     })
  //     .catch((error) => {
  //       if (process.env.NODE_ENV !== "production") {
  //         return;
  //       }
  //     });
  // };


  getListAllSousDomaines = () => {
    axios
      .get(this.state.api + "sous_domaines")
      .then((res) => {
        // console.log(res, "sous")
        if (res.data.success === true) {

          this.setState({
            allSousDomaines: res.data.data,
            // domaine_name: res.data.data.domaine.name,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  render() {
    return <div className="p-b-200">
      <div className="row">
        <div className="col-lg-9">
    
      <div className="row m-b-15">
        <div className="col m-b-15">
          <h6 className="h-theme ff-inter fw-600 px-3"> Gestion des Domaines</h6>
        </div>
      </div>
      <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5">
                    </div>
                    <div className="col dis-flex m-b-15">
              <AddSousDomaines getAllSousDomaines={this.getListAllSousDomaines}/> 
            </div>
          </div>

          <div className="row px-lg-0 px-md-2"> 
            <TableSousDomaines  />
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
