import React from "react";
import HeaderDashboardAdmin from "../headerDashboardAdmin";
import NavVerticalAdmin from "../navVerticalAdmin";
import AllBakelisteAbondonnes from "./AllBakelisteAbondonnes";
import AllBakelisteBloques from "./AllBakelisteBloques";
import AllBakelisteEnStages from "./AllBakelisteEnStages";
const AllBakelisteBloquesView = () => {
  return (
    <div className="component-bakeliste-suivi-d-b-view">
      {" "}
      <div className="component-card-suivi-bakeliste-view body-theme">
        <HeaderDashboardAdmin />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalAdmin />
            </div>
            <div className="col-lg-10 pt-3">
              <AllBakelisteBloques />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBakelisteBloquesView;
