import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { API } from "../../../variablesGlobales";

const initialState = {
  isLoading: false,
  allCoachsAssistant: [],
  fetchingCommentStats: false,
  coachsCommentStats: [],
  allCoachsCommentStats: [],
  allCoachsTaskValidationStats: [],
  identifier: "global",
};

const API_URL = API;

export const coachAssistantSlice = createSlice({
  name: "listCoachAssistant",
  initialState,

  reducers: {
    isFetchingCoachAssistant: (state) => {
      state.isLoading = true;
    },

    getCoachAssistant: (state, action) => {
      state.allCoachsAssistant = action.payload;
    },

    isFetchingDoneCoachAssistant: (state) => {
      state.isLoading = false;
    },

    isFetchingCommentStats: (state) => {
      state.fetchingCommentStats = true;
    },

    getCoachsCommentStats: (state, action) => {
      state.coachsCommentStats = action.payload;
    },

    isFetchingCommentStatsDone: (state) => {
      state.fetchingCommentStats = false;
    },

    getAllCoachCommentStats: (state, action) => {
      state.allCoachsCommentStats = action.payload;
    },

    getAllCoachsTaskValidationStats: (state, action) => {
      state.allCoachsTaskValidationStats = action.payload;
    },

    dailyIdentifier: (state) => {
      state.identifier = "day";
    },
    weeklyIdentifier: (state) => {
      state.identifier = "week";
    },
    monthlyIdentifier: (state) => {
      state.identifier = "month";
    },
  },
});

export const getCoachAssistantAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}liste-des-coachs-assistants`);
    if (res.data.success) {
      dispatch(isFetchingDoneCoachAssistant());
      dispatch(getCoachAssistant(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const archiveCoach = (id) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}archive-user/${id}`);
    if (res.data.success) {
      toast.success(
        "coach archivé avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getCoachAssistantAsync());
    } else {
      toast.error(
        "erreur sur l'archivage",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};

// get coachs comments stats
export const getCoachsCommentStatsAsync =
  (fd, handleIdentify) => async (dispatch) => {
    handleIdentify();
    try {
      const res = await axios.post(`${API_URL}coachs-comment-stats`, fd);
      if (res.data.success) {
        dispatch(getCoachsCommentStats(res.data.data));
        dispatch(isFetchingCommentStatsDone());
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getAllCoachCommentStatsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}coachs-all-comment-stats`);
    if (res.data.success) {
      dispatch(getAllCoachCommentStats(res.data.data));
      // dispatch(isFetchingCommentStatsDone());
    }
  } catch (error) {
    console.log(error);
  }
};

export const {
  isFetchingDoneCoachAssistant,
  getCoachAssistant,
  isFetchingCoachAssistant,
  isFetchingCommentStats,
  getCoachsCommentStats,
  isFetchingCommentStatsDone,
  getAllCoachCommentStats,
  getAllCoachsTaskValidationStats,
  dailyIdentifier,
  weeklyIdentifier,
  monthlyIdentifier,
} = coachAssistantSlice.actions;
export const isLoadingCoachAssistant = (state) =>
  state.listCoachAssistant?.isLoading;
export const showListCoachsAssistant = (state) =>
  state.listCoachAssistant.allCoachsAssistant;
export const coachAssistantFeedback = (state) =>
  state.listCoachAssistant.coachsCommentStats;
export const AllCoachAssistantFeedback = (state) =>
  state.listCoachAssistant.allCoachsCommentStats;
export const setCommentIdentifier = (state) =>
  state.listCoachAssistant.identifier;
export default coachAssistantSlice.reducer;
