import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  eventDetails,
  getEventDetailsAsync,
  joinEvent,
  joiningEvent,
} from "../../redux/slices/eventsSlices/eventSlice";
import { base_api_url } from "../../variablesGlobales";
import { frenchShortFormatDate } from "../frenchFormatDate";
import "./eventParticipate.css";

const EventParticipate = () => {
  // dispatch
  const dispatch = useDispatch();
  const eventDetailsData = useSelector(eventDetails);
  const isJoining = useSelector(joiningEvent);

  let { id } = useParams();

  React.useEffect(() => {
    dispatch(getEventDetailsAsync(id));
  }, []);

  const formatDate = frenchShortFormatDate;

  // SETTING UP THE FORM VALIDATION SCHEMA
  const formSchema = Yup.object().shape({
    first_name: Yup.string().required("Veuillez ajouter votre prénom"),
    last_name: Yup.string().required("Veuillez ajouter votre nom"),
    email: Yup.string().required("L'email est requis"),
    phone: Yup.string().required("Veuillez ajouter votre numéro de téléphone"),
    address: Yup.string().required("Veuillez ajouter votre addresse"),
    domaine: Yup.string().required("Cette question est obligatoire."),
    rencontre_online: Yup.string().required("Cette question est obligatoire."),
    rencontre_place: Yup.string().required("Cette question est obligatoire."),
    rencontre_user_tools: Yup.string().required(
      "Cette question est obligatoire."
    ),
    rencontre_user_disponibility: Yup.string().required(
      "Cette question est obligatoire."
    ),
    rencontre_user_questions: Yup.string(),
    // rencontres_id: Yup.string().required("Ce champ est obligatoire"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const { register, handleSubmit, setError, clearErrors, formState } =
    useForm(formOptions);
  const { errors } = formState;

  // initialState
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [telephone, setTelephone] = React.useState("");
  const [addresse, setAddresse] = React.useState("");

  // rencontre questions
  const [rencontreOnline, setRencontreOnline] = React.useState("");
  const [rencontrePlace, setRencontrePlace] = React.useState("");
  const [rencontreDomaine, setRencontreDomaine] = React.useState("");
  const [rencontreUserTools, setRencontreUserTools] = React.useState("");
  const [rencontreUserDisponibility, setRencontreUserDisponibility] =
    React.useState("");
  const [rencontreUserQuestions, setRencontreUserQuestions] =
    React.useState("");

  // Clear form
  const resetFormState = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setTelephone("");
    setAddresse("");
    setRencontreOnline("");
    setRencontrePlace("");
    setRencontreDomaine("");
    setRencontreUserTools("");
    setRencontreUserDisponibility("");
    setRencontreUserQuestions("");
  };

  // join event
  const joinEventForm = () => {
    // e.preventDefault();

    //   Form data
    const fd = new FormData();
    fd.append("first_name", firstName);
    fd.append("last_name", lastName);
    fd.append("email", email);
    fd.append("phone", telephone);
    fd.append("address", addresse);
    fd.append("domaine", rencontreDomaine);
    fd.append("rencontre_online", rencontreOnline);
    fd.append("rencontre_place", rencontrePlace);
    fd.append("rencontre_user_tools", rencontreUserTools);
    fd.append("rencontre_user_disponibility", rencontreUserDisponibility);
    fd.append("rencontre_user_questions", rencontreUserQuestions);
    fd.append("rencontres_id", eventDetailsData?.id);

    dispatch(joinEvent(fd, resetFormState));
  };

  return (
    <div className="eventParticipation__container row">
      {/* {console.log("state", eventDetailsData)} */}
      <div className="eventParticipation__eventBanner col-md-6">
        <img
          src={`${base_api_url}/uploads/rencontre_files/${eventDetailsData?.rencontre_file}`}
          alt=""
        />
      </div>
      <div className="col-md-6 eventParticipation__formSection">
        <div className="formSection__eventInfos">
          <h4>{eventDetailsData?.rencontre_name}</h4>

          <p>{eventDetailsData?.rencontre_description}</p>

          <p>
            Date de l'évenement : &nbsp;{" "}
            {formatDate(eventDetailsData?.rencontre_start_date)} &nbsp; au
            &nbsp; {formatDate(eventDetailsData?.rencontre_end_date)}
          </p>

          <p>
            Lieu de l'évenement : &nbsp; {eventDetailsData?.rencontre_place}
          </p>
        </div>

        {/* Form section */}
        <form
          //   onSubmit={(e) => joinEventForm(e)}
          onSubmit={handleSubmit(joinEventForm)}
        >
          <div className="formSection__eventForm">
            {/*  */}
            <div
              className={`${
                errors.first_name ? "is-invalid" : "eventForm__userDetails"
              }`}
            >
              <input
                type="text"
                placeholder="Prénom"
                // onChange={(e) => setFirstName(e.target.value)}
                {...register("first_name", {
                  onChange: (e) => setFirstName(e.target.value),
                  value: firstName,
                })}
              />
              {errors.first_name && (
                <p style={{ color: "#ec0303" }}>{errors.first_name.message}</p>
              )}
            </div>

            <div
              className={`${
                errors.last_name ? "is-invalid" : "eventForm__userDetails"
              }`}
            >
              <input
                type="text"
                placeholder="Nom"
                // onChange={(e) => setLastName(e.target.value)}
                {...register("last_name", {
                  onChange: (e) => setLastName(e.target.value),
                  value: lastName,
                })}
              />
              {errors.last_name && (
                <p style={{ color: "#ec0303" }}>{errors.last_name.message}</p>
              )}
            </div>

            <div
              className={`${
                errors.email ? "is-invalid" : "eventForm__userDetails"
              }`}
            >
              <input
                type="email"
                placeholder="Email"
                // onChange={(e) => setEmail(e.target.value)}
                {...register("email", {
                  onChange: (e) => setEmail(e.target.value),
                  value: email,
                })}
              />
              {errors.email && (
                <p style={{ color: "#ec0303" }}>{errors.email.message}</p>
              )}
            </div>
  
            <div
              className={`${
                errors.phone ? "is-invalid" : "eventForm__userDetails"
              }`}
            >
              <input
                type="tel"
                placeholder="Téléphone"
                // onChange={(e) => setTelephone(e.target.value)}
                {...register("phone", {
                  onChange: (e) => setTelephone(e.target.value),
                  value: telephone,
                })}
              />
              {errors.phone && (
                <p style={{ color: "#ec0303" }}>{errors.phone.message}</p>
              )}
            </div>

            <div
              className={`${
                errors.address ? "is-invalid" : "eventForm__userDetails"
              }`}
            >
              <input
                type="text"
                placeholder="Addresse"
                // onChange={(e) => setAddresse(e.target.value)}
                {...register("address", {
                  onChange: (e) => setAddresse(e.target.value),
                  value: addresse,
                })}
              />
              {errors.address && (
                <p style={{ color: "#ec0303" }}>{errors.address.message}</p>
              )}
            </div>

            {/*  */}
            <div className="d-flex flex-column mt-3 mb-2 eventForm_radioQuestionSection">
              <label className="" htmlFor="">
                Voulez-vous former en ligne ou en présentiel?
                <span className="requiredElement">*</span>
              </label>
              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="online"
                  name="rencontre_online"
                  value="En ligne"
                  //   onChange={(e) => setRencontreOnline(e.target.value)}
                  {...register("rencontre_online", {
                    onChange: (e) => setRencontreOnline(e.target.value),
                    value: rencontreOnline,
                  })}
                />
                <label htmlFor="online" className="mx-2">
                  En ligne
                </label>
              </span>
              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="presentiel"
                  name="rencontre_online"
                  value="En presentiel"
                  //   onChange={(e) => setRencontreOnline(e.target.value)}
                  {...register("rencontre_online", {
                    onChange: (e) => setRencontreOnline(e.target.value),
                    value: rencontreOnline,
                  })}
                />
                <label htmlFor="presentiel" className="mx-2">
                  En présentiel
                </label>
              </span>
              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="presentiel_enLigne"
                  name="rencontre_online"
                  value="En ligne et en présentiel"
                  //   onChange={(e) => setRencontreOnline(e.target.value)}
                  {...register("rencontre_online", {
                    onChange: (e) => setRencontreOnline(e.target.value),
                    value: rencontreOnline,
                  })}
                />
                <label htmlFor="presentiel_enLigne" className="mx-2">
                  En ligne et en présentiel
                </label>
              </span>
              {errors.rencontre_online && (
                <p role="alert" style={{ color: "#ec0303" }}>
                  {errors.rencontre_online?.message}
                </p>
              )}
            </div>

            {/*  */}
            <div className="d-flex flex-column mb-2 eventForm_radioQuestionSection">
              <label className="" htmlFor="">
                Dans quelle ville voulez-vous participer au JéemaCoder?
                <span className="requiredElement">*</span>
              </label>
              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="place_dakar"
                  name="rencontre_place"
                  value="Dakar"
                  //   onChange={(e) => setRencontrePlace(e.target.value)}
                  {...register("rencontre_place", {
                    onChange: (e) => setRencontrePlace(e.target.value),
                    value: rencontrePlace,
                  })}
                />
                <label htmlFor="place_dakar" className="mx-2">
                  Dakar
                </label>
              </span>

              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="place_thies"
                  name="rencontre_place"
                  value="Thiès"
                  //   onChange={(e) => setRencontrePlace(e.target.value)}
                  {...register("rencontre_place", {
                    onChange: (e) => setRencontrePlace(e.target.value),
                    value: rencontrePlace,
                  })}
                />
                <label htmlFor="place_thies" className="mx-2">
                  Thiès
                </label>
              </span>

              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="place_mbour"
                  name="rencontre_place"
                  value="Mbour"
                  //   onChange={(e) => setRencontrePlace(e.target.value)}
                  {...register("rencontre_place", {
                    onChange: (e) => setRencontrePlace(e.target.value),
                    value: rencontrePlace,
                  })}
                />
                <label htmlFor="place_mbour" className="mx-2">
                  Mbour
                </label>
              </span>
              {errors.rencontre_place && (
                <p role="alert" style={{ color: "#ec0303" }}>
                  {errors.rencontre_place?.message}
                </p>
              )}
            </div>

            {/*  */}
            <div className="d-flex flex-column mb-2 eventForm_radioQuestionSection">
              <label className="" htmlFor="">
                Quelle formation voulez-vous suivre ?
                <span className="requiredElement">*</span>
              </label>
              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Programmation"
                  name="domaine"
                  value="Programmation"
                  //   onChange={(e) => setRencontreDomaine(e.target.value)}
                  {...register("domaine", {
                    onChange: (e) => setRencontreDomaine(e.target.value),
                    value: rencontreDomaine,
                  })}
                />
                <label htmlFor="Programmation" className="mx-2">
                  Programmation
                </label>
              </span>

              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Marketing digital"
                  name="domaine"
                  value="Marketing digital"
                  //   onChange={(e) => setRencontreDomaine(e.target.value)}
                  {...register("domaine", {
                    onChange: (e) => setRencontreDomaine(e.target.value),
                    value: rencontreDomaine,
                  })}
                />
                <label htmlFor="Marketing digital" className="mx-2">
                  Marketing digital
                </label>
              </span>

              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Design"
                  name="domaine"
                  value="Design"
                  //   onChange={(e) => setRencontreDomaine(e.target.value)}
                  {...register("domaine", {
                    onChange: (e) => setRencontreDomaine(e.target.value),
                    value: rencontreDomaine,
                  })}
                />
                <label htmlFor="Design" className="mx-2">
                  Design
                </label>
              </span>

              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Gestion de projet"
                  name="domaine"
                  value="Gestion de projet"
                  //   onChange={(e) => setRencontreDomaine(e.target.value)}
                  {...register("domaine", {
                    onChange: (e) => setRencontreDomaine(e.target.value),
                    value: rencontreDomaine,
                  })}
                />
                <label htmlFor="Gestion de projet" className="mx-2">
                  Gestion de projet
                </label>
              </span>
              {errors.domaine && (
                <p role="alert" style={{ color: "#ec0303" }}>
                  {errors.domaine?.message}
                </p>
              )}
            </div>

            {/*  */}
            <div className="d-flex flex-column mb-2 eventForm_radioQuestionSection">
              <label className="" htmlFor="">
                Qu'avez-vous à votre disposition? (Merci de prévoir de quoi
                noter)<span className="requiredElement">*</span>
              </label>
              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Ordinateur"
                  name="rencontre_user_tools"
                  value="Ordinateur"
                  //   onChange={(e) => setRencontreUserTools(e.target.value)}
                  {...register("rencontre_user_tools", {
                    onChange: (e) => setRencontreUserTools(e.target.value),
                    value: rencontreUserTools,
                  })}
                />
                <label htmlFor="Ordinateur" className="mx-2">
                  Ordinateur
                </label>
              </span>

              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Smartphone"
                  name="rencontre_user_tools"
                  value="Smartphone"
                  //   onChange={(e) => setRencontreUserTools(e.target.value)}
                  {...register("rencontre_user_tools", {
                    onChange: (e) => setRencontreUserTools(e.target.value),
                    value: rencontreUserTools,
                  })}
                />
                <label htmlFor="Smartphone" className="mx-2">
                  Smartphone
                </label>
              </span>

              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Tablette"
                  name="rencontre_user_tools"
                  value="Tablette"
                  //   onChange={(e) => setRencontreUserTools(e.target.value)}
                  {...register("rencontre_user_tools", {
                    onChange: (e) => setRencontreUserTools(e.target.value),
                    value: rencontreUserTools,
                  })}
                />
                <label htmlFor="Tablette" className="mx-2">
                  Tablette
                </label>
              </span>
              {errors.rencontre_user_tools && (
                <p role="alert" style={{ color: "#ec0303" }}>
                  {errors.rencontre_user_tools?.message}
                </p>
              )}
            </div>

            {/*  */}
            <div className="d-flex flex-column mb-2 eventForm_radioQuestionSection">
              <label className="" htmlFor="">
                Seriez-vous disponible du 18 au 21 Octobre 2022 de 8h30 à 18h?
                <span className="requiredElement">*</span>
              </label>
              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Oui"
                  name="rencontre_user_disponibility"
                  value="Oui"
                  //   onChange={(e) =>
                  //     setRencontreUserDisponibility(e.target.value)
                  //   }
                  {...register("rencontre_user_disponibility", {
                    onChange: (e) =>
                      setRencontreUserDisponibility(e.target.value),
                    value: rencontreUserDisponibility,
                  })}
                />
                <label htmlFor="Oui" className="mx-2">
                  Oui
                </label>
              </span>

              <span className="d-flex radioQuestionSection_span">
                <input
                  type="radio"
                  id="Non"
                  name="rencontre_user_disponibility"
                  value="Non"
                  //   onChange={(e) =>
                  //     setRencontreUserDisponibility(e.target.value)
                  //   }
                  {...register("rencontre_user_disponibility", {
                    onChange: (e) =>
                      setRencontreUserDisponibility(e.target.value),
                    value: rencontreUserDisponibility,
                  })}
                />
                <label htmlFor="Non" className="mx-2">
                  Non
                </label>
              </span>
              {errors.rencontre_user_disponibility && (
                <p role="alert" style={{ color: "#ec0303" }}>
                  {errors.rencontre_user_disponibility?.message}
                </p>
              )}
            </div>

            <div className="d-flex flex-column mb-2 eventForm_radioQuestionSection">
              <label className="" htmlFor="rencontre_user_questions">
                Avez-vous des questions?
              </label>
              <div className="eventForm__userDetails">
                <input
                  type="text"
                  placeholder="Votre réponse"
                  name="rencontre_user_questions"
                  id="rencontre_user_questions"
                  onChange={(e) => setRencontreUserQuestions(e.target.value)}
                  {...register("rencontre_user_questions", {
                    onChange: (e) => setRencontreUserQuestions(e.target.value),
                    value: rencontreUserQuestions,
                  })}
                />
              </div>
            </div>
          </div>

          <div className="formSubmitSection mt-3">
            <button type="submit" disabled={isJoining ? true : false}>
              {isJoining ? "Envoi..." : "Envoyer"}
            </button>

            <span onClick={resetFormState}>Effacer le formulaire</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EventParticipate;
