import React, {Component} from 'react';
import './tableMyBfTerminer.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyBfTerminerActions from "../../store/tableMyBfTerminer/actions";

var gestionTacheForCoach = [{
  fullname: "Abdou ba",
  email: "gueyeazou201629@gmail.com",
  telephone:"770000000",
  dateFin: "27/12/2020",
  ecole: "Bakeli",
  action: "oui"
},{
  fullname: "Abdou ba",
  email: "gueyeazou201629@gmail.com",
  telephone:"770000000",
  dateFin: "27/12/2020",
  ecole: "Bakeli",
  action: "oui"
},{
  fullname: "Abdou ba",
  email: "gueyeazou201629@gmail.com",
  telephone:"770000000",
  dateFin: "27/12/2020",
  ecole: "Bakeli",
  action: "oui"
},{
  fullname: "Abdou ba",
  email: "gueyeazou201629@gmail.com",
  telephone:"770000000",
  dateFin: "27/12/2020",
  ecole: "Bakeli",
  action: "oui"
},
];

class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col">
        <p className="badge todo-badge">To do</p>

        {/* !!!!important!!!! */}
        {/* <p className="badge doing-badge">Doing</p> */}
        {/* <p className="badge done-badge">Done</p> */}
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return (
    <Status active={ cell } />
  );
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="refresh-cw" size={18} />
        </button>
        
        
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}



export default class tableMyBfTerminer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    CustomExportCsvBtn = (onClick) =>{
      return(
        <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
      );
    }
    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      };
      return (
              <div className="component-table-my-bf-terminer">
                
                <BootstrapTable 
                  pagination
                  exportCSV
                  options={options}
                  csvFileName="utilisateurs.csv"
                  data={gestionTacheForCoach} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                    
                  <TableHeaderColumn 
                    
                    isKey filter={{type:"TextFilter", delay: 1000}} 
                    dataField='fullname' 
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Nom Complet</span> 
                    </TableHeaderColumn>
                 
                     
                     <TableHeaderColumn 
                      dataField='email' width="200" 
                      filter={{type:"TextFilter", delay: 1000}}                    
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Email</span>
                     </TableHeaderColumn>
                     <TableHeaderColumn 
                      dataField='telephone' width="100" 
                      filter={{type:"TextFilter", delay: 1000}}                    
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Telephone</span>
                     </TableHeaderColumn>

                     <TableHeaderColumn 
                      dataField='dateFin' width="200" 
                      filter={{type:"TextFilter", delay: 1000}}
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Date de fin</span>
                     </TableHeaderColumn>

                     <TableHeaderColumn 
                      dataField='ecole' width="100"  
                      filter={{type:"TextFilter", delay: 1000}}                   
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Ecole</span>
                     </TableHeaderColumn>

                  <TableHeaderColumn dataField='action' 
                  dataFormat={uploadFormatter}                  
                  thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                  tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs"> Actions</span>
                    </TableHeaderColumn>
                </BootstrapTable>
             
                </div>
        );
    }
  }
// export default connect(
//     ({ tableMyBfTerminer }) => ({ ...tableMyBfTerminer }),
//     dispatch => bindActionCreators({ ...tableMyBfTerminerActions }, dispatch)
//   )( tableMyBfTerminer );