import axios from "axios";
import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Appel from "../../img/appel.jpeg";
import ImageDossier from "../../img/folder_guess.png";
import API from "../../variablesGlobales";
import ListeProspectSkeleton from "./listeProspectSkeleton";
import "./listProspects.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listProspectsActions from "../../store/listProspects/actions";
export default class listProspects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      prospects: [],
      calledStatementInProgress: false,
      successCalledAlert: false,
      errorCalledAlert: false,
      changeInProgress: false,
      isCalled: false,
      isLoaded: true,
    };
  }

  componentDidMount() {
    this.getProspect();
  }

  voirDetail = (id) => {
    // window.location="/dossier-de-candidature-prospect"
    window.location = `/dossier-de-candidature-prospect?id=${id}`;
  };
  getProspect = () => {
    axios
      .get(this.state.api + "prospects")
      .then((response) => {
        this.setState({ isLoaded: false, prospects: response.data.data });
      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
  transformer = (id) => {
    window.location = `/convertir-prospect-a-bakeliste?id=${id}`;
  };

  onCalledStatement = (id) => {
    this.setState({
      calledStatementInProgress: true,
    });
    axios
      .post(this.state.api + "contacted-prospect/" + id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            calledStatementInProgress: false,
            successCalledAlert: true,
          });
          this.getProspect();
          // this.handleShowAndHideAlert('successCalledAlert')
        } else {
          this.setState({
            calledStatementInProgress: false,
            errorCalledAlert: true,
          });
          // this.handleShowAndHideAlert('errorCalledAlert');
        }
      })
      .catch((error) => {
        this.setState({
          calledStatementInProgress: false,
          errorCalledAlert: true,
        });
        // this.handleShowAndHideAlert('errorCalledAlert');
        console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };

  // handleSwitchStatut = (label) => {
  //   setTimeout(() => {
  //     if (label === "phone") {
  //       this.setState({
  //         empty_pm_phone: false,
  //       });
  //     }
  //     if (label === "isSuccessPointMe") {
  //       this.setState({
  //         isSuccessPointMe: false,
  //       });
  //     }
  //     if (label === "isErrorPointAlert") {
  //       this.setState({
  //         isErrorPointAlert: false,
  //       });
  //     }
  //     /*  if(label === 'isSuccessPointDepartureAlert'){
  //       this.setState({
  //         isSuccessPointDepartureAlert: false
  //       })
  //     }
  //     if(label === 'isErrorPointDepartureAlert'){
  //       this.setState({
  //         isErrorPointDepartureAlert: false
  //       })
  //     } */
  //     if (label === "phone") {
  //       this.setState({
  //         empty_pm_phone: false,
  //       });
  //     }
  //     if (label === "phone_syntax_error") {
  //       this.setState({
  //         phone_syntax_error: false,
  //       });
  //     }
  //   }, 5000);
  // };
  // confirm = (e) => {
  //   e.preventDefault();
  //     this.setState({
  //       calledStatementInProgress: true,
  //       isCalled: true,
  //     });
  // }
  render() {
    return (
      <div className="component-my-participants">
        {/* {this.state.isLoaded && <ListeProspectSkeleton />} */}
        <div className="container-fluid px-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
              <li className="breadcrumb-item">
                <Link to="/dashboard-admin" className="lien_nav_current">
                  <FeatherIcon
                    className="icon-breadcrumb-custom"
                    icon="layout"
                  />
                  Dashboard
                </Link>
              </li>
              <li
                className="breadcrumb-item active lien_nav"
                aria-current="page"
              >
                <FeatherIcon
                  className="icon-breadcrumb-custom"
                  icon="user-plus"
                />
                Propects
              </li>
            </ol>
          </nav>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <h6 className="h-theme ff-inter fw-600 px-3">
                {/* Dossiesr de candidature */}
              </h6>
            </div>
            <div className="row">
              {this.state.prospects.map((item, index) => (
                <>
                  {item.is_called == 0 ? (
                    <div key={index} className=" col-md-4 mb-3">
                      <img
                        className="place-folder"
                        src={ImageDossier}
                        alt=""
                        onClick={() => this.voirDetail(item.id)}
                      />
                      <span
                        onClick={(e) =>
                          window.confirm(
                            "Êtes-vous sûr de vouloir déclarer avoir appelé ce prospect ?"
                          ) && this.onCalledStatement(item.id)
                        }
                      >
                        <FeatherIcon
                          className="icon"
                          icon="phone-outgoing"
                          type="button"
                          aria-hidden="true"
                        />
                      </span>
                      <div className="texte_centrer">
                        {item.firstName} &nbsp; {item.lastName}
                        <br />
                        {item.email}
                      </div>
                    </div>
                  ) : (
                    <div key={index} className=" col-md-4 mb-3">
                      <img
                        className="place-folder"
                        src={Appel}
                        alt=""
                        onClick={() => this.voirDetail(item.id)}
                      />
                      <span>
                        <FeatherIcon
                          className="icon"
                          icon="phone-off"
                          type="button"
                          aria-hidden="true"
                        />
                      </span>
                      <span onClick={() => this.transformer(item.id)}>
                        <FeatherIcon
                          className="iconAppel"
                          icon="check-square"
                          type="button"
                          aria-hidden="true"
                        />
                      </span>
                      <div className="texte_centrer">
                        {item.firstName} &nbsp; {item.lastName}
                        <br />
                        {item.email}
                      </div>
                    </div>
                  )}
                  {/* <SweetAlert
                  show={this.state.pointDeparture}
                  onConfirm={() =>
                    this.confirm()
                  }
                  warning
                  title="Confirmez cette action!!!"
                  style={{
                    color: "rgb(240, 173, 78)",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                /> */}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listProspects }) => ({ ...listProspects }),
//     dispatch => bindActionCreators({ ...listProspectsActions }, dispatch)
//   )( listProspects );
