import React, { Component } from "react";
import "./listeBakelistesAbandonnes.css";
import axios from "axios";
import API from "../../variablesGlobales";
import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { Tooltip } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";


// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeBakelistesPauseActions from "../../store/listeBakelistesPause/actions";
export default class listeBakelistesAbandonnes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bakelistesabandon: [],
      api: API.API,
    };
  }
  componentDidMount() {
    this.getListesBakelistesAbandonne();
  }

  getListesBakelistesAbandonne = () => {
    axios
      .get(this.state.api + "bakelistes-training-abandon-list")
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            bakelistesabandon: res.data.data,
            loading: true,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
        }
        // this.setState({ isLoaded: false });
        // $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  // nameFormatter(cell, row) {
  //   return `${row.first_name} ${row.last_name}`;
  // }
  nameFormatter = (cell, row) => {
    return (
      <span>
        {/* <button> */}
        <Tooltip title="Voir la fiche">
          <Link className="linkName"
            to={{
              pathname: "/profil-utilisateur",
              state: { id: row.id, data: row, },
            }}
          >
            {row.first_name} {row.last_name}{" "}
          </Link>
          </Tooltip>
        {/* </button> */}

      </span>
    );
  };

  render() {
    const options = {
      exportCSVBtn: this.CustomExportCsvBtn,
    };
    return (
      <div className="component-liste-bakelistes-pause">
        {" "}
        <BootstrapTable
          pagination
          exportCSV
          options={options}
          csvFileName="liste-bakelistes-abandonnes.csv"
          data={this.state.bakelistesabandon}
          striped
          version="4"
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn
            filter={{ type: "TextFilter", delay: 1000 }}
            dataField="first_name"
            dataFormat={this.nameFormatter} 
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            isKey={true}
            dataField="email"
            width="200"
            filter={{ type: "TextFilter", delay: 1000 }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Email</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="phone"
            width="200"
            filter={{ type: "TextFilter", delay: 1000 }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Telephone</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="status"
            width="150"
            filter={{ type: "TextFilter", delay: 1000 }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Statut</span>
          </TableHeaderColumn>

          {/* <TableHeaderColumn
            dataField="action"
            dataFormat={uploadFormatter}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              display: "flex",
              justifyContent: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs"> Actions</span>
          </TableHeaderColumn> */}
        </BootstrapTable>
      </div>
    );
  }
}
// export default connect(
//     ({ listeBakelistesPause }) => ({ ...listeBakelistesPause }),
//     dispatch => bindActionCreators({ ...listeBakelistesPauseActions }, dispatch)
//   )( listeBakelistesPause );
