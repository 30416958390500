import React, {Component} from 'react';
import './bakelisteLateItemForAdmin.css';
import IconGroup from '../../img/icons/people-icon.png';
import ModalBakelisteDetails from '../modalBakelisteDetails/index';

class Status extends React.Component {
  render() {
    return (
      <ModalBakelisteDetails/>
    );
  }
}

function changeStatus(cell, row) {
  return (
    <Status active={ cell } />
  );
}
export default class bakelisteLateItemForAdmin extends Component {
    constructor(props) {
        super(props); 
        this.state = {};
    }
    render() {
      return <div className="card-dashboard m-b-15 w-full dis-flex flex-col">
      <p className="h-card-dashboard-bakeliste"><img className="icon-h-card-dashboard-bakeliste" src={IconGroup} alt="" /> Retards </p>
        <br />
        <br />
                <div className="row late-missing">
                <div className="card-dashboard m-b-15 late">
                <p className='chiffre-item-statistics-dashboard nbr-late'>12</p>
                <p className="designation-item-statistics-dashboard">Dakar</p>
                </div>
                <div className="card-dashboard m-b-15 late">
                <p className='chiffre-item-statistics-dashboard nbr-late'>90</p>
                <p className="designation-item-statistics-dashboard">Thiès</p>
                </div>
                </div>
    </div>


      // <div className="card-dashboard m-b-15 w-full crd-late">
      //   <p className="h-card-dashboard-bakeliste"><img className="icon-h-card-dashboard-bakeliste" src={IconGroup} alt="" /> Retards </p>
      //   <br />
      //   <br />
      //           <div className="row late-missing">
      //           <div className="card-dashboard m-b-15 late">
      //           <p className='chiffre-item-statistics-dashboard nbr-late'>12</p>
      //           <p className="designation-item-statistics-dashboard">Dakar</p>
      //           </div>
      //           <div className="card-dashboard m-b-15 late">
      //           <p className='chiffre-item-statistics-dashboard nbr-late'>90</p>
      //           <p className="designation-item-statistics-dashboard">Thiès</p>
      //           </div>
      //           </div>
      //         </div>;
    
  }
  } 
