import React, { Component } from "react";
import ProposEvent from "../proposEvent/index";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import "./proposEventView.css";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as proposEventViewActions from "../../store/proposEventView/actions";
export default class proposEventView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_rencontre: this.props?.location?.state.id,
      nbreParticipants: this.props?.location?.state.nbreParticipants,
    };
  }
  render() {
    return (
      <div className="component-propos-event-view body-theme">
        <HeaderDashboardAdmin />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-xl-2 col-lg-2 p-0">
              <NavVerticalAdmin />
            </div>
            <div className="col-xl-10 col-lg-2 pt-3">
              <ProposEvent
                id={this.state.id_rencontre}
                nbreParticipants={this.state.nbreParticipants}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ proposEventView }) => ({ ...proposEventView }),
//     dispatch => bindActionCreators({ ...proposEventViewActions }, dispatch)
//   )( proposEventView );
