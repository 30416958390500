import React, {Component} from 'react';
import './createDomaineView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import CreateDomaine from '../createDomaine/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as createDomaineViewActions from "../../store/createDomaineView/actions";
export default class createDomaineView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-create-domaine-view body-theme">
        <HeaderDashboardAdmin />
            <div className="container-fluid fixing-after-header">
              <div className="row">
                <div className="col-lg-2 p-0">
                  <NavVerticalAdmin />
                </div>
                <div className="col-lg-10 pt-3">
                  <CreateDomaine />
                </div>
              </div>
            </div>
        </div>
      );
    }
  }
// export default connect(
//     ({ createDomaineView }) => ({ ...createDomaineView }),
//     dispatch => bindActionCreators({ ...createDomaineViewActions }, dispatch)
//   )( createDomaineView );