import React, {Component} from 'react';
import './createEventView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import CreateEvent from '../createEvent/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as createEventViewActions from "../../store/createEventView/actions";
export default class createEventView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
          <div className="component-create-event-view body-theme">
            <HeaderDashboardAdmin />
            <div className="container-fluid fixing-after-header">
              <div className="row">
                <div className="col-lg-2 p-0">
                  <NavVerticalAdmin />
                </div>
                <div className="col-lg-10 pt-3">
                  <CreateEvent />
                </div>
              </div>
            </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ createEventView }) => ({ ...createEventView }),
//     dispatch => bindActionCreators({ ...createEventViewActions }, dispatch)
//   )( createEventView );