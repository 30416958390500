import React, {Component} from 'react';
import './dossierCandidature.css';
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
// import Ellipse from "../../img/Ellipse.png";
import Wagaan from "../../img/wagaan_head.png";
import $ from "jquery";
import FeatherIcon from 'feather-icons-react';
import { Modal, Icon, Uploader } from 'rsuite';
import Ellipse from "../../img/Ellipse.png";
import "./dossierCandidature.css";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
import axios from "axios";
import API from "../../variablesGlobales";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
// import { bindActionCreators } from "redux";
// import * as dossierCandidatureActions from "../../store/dossierCandidature/actions";
export default class dossierCandidature extends Component {
  constructor(props) {
    super(props);
    this.handleAdminCommentChange = this.handleAdminCommentChange.bind(this);
    this.handleAddAdminComment = this.handleAddAdminComment.bind(this);
    this.state = {      
      userName:"",
      adminID: window.sessionStorage.getItem("user_id"),
      prospects: [],
      loading: true,
      fields: {},
      domaine: [],
      api: API.API,
      commenterFullname:"",
      domaineId: this.props.domaineId,
      prospect_id: this.props.prospect_id,
      commentOnProspectID: 1,
      showBloc: false,
      successCalledAlert: false,
      prospectStatus: "",
      prospectItem: [],
      adminComments: [],
      addCommentInProgress: false,
      isAddCommentRequestError: false,
      acFields: {},
      addCommentByAdminSuccess: false,
      commentsIsLoading: false,
      successAlert: false,
      // formatDate: frenchShortFormatDate.frenchShortFormatDate,

    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  componentDidMount() {
    this.getProspectList();
    this.getCommentOnProspect();
    this.getDomaineById();
  }



  getDomaineById = () => {
  axios
    .get(this.state.api + "domaines/" + this.props.domaineId)
    .then((response) => {
      this.setState({
        domaine: response.data.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
      if (process.env.NODE_ENV !== "production") {
        return;
      }
    });
};

getProspectList = () => {
  axios
    .get(this.state.api + "prospects")
    .then((res) => {
      let prospects = res.data.data;
      this.setState({
        prospects: prospects,
        loading: false,
      });
      //console.log(res.data.data)
    })
    .catch((error) => {
      console.log(error.message);
      this.setState({
        loading: false,
      });
      if (process.env.NODE_ENV !== "production") {
        return;
      }
     // Sentry.captureException(error);
    });
};


getProspectId = (prospect) => {
  this.getCommentOnProspect(prospect.id);
  this.setState({
    commentOnProspectID: prospect.id,
  });
};

getCommentOnProspect = () => {
  this.setState({
  
  });
  axios
    .get(this.state.api + "admin-do-comment-prospects/")
    .then((response) => {
      if (response.data.success === true) {
        // console.log("success");
        if (response.data.data !== undefined) {
          this.setState({
            adminComments: response.data.data,
            commentsIsLoading: false,
          });
        }
      } else {
        // console.log("empty");
        // console.log("Commentaire non trouvé");
        this.setState({
          commentsIsLoading: false,
        });
      }
    })
    .catch((error) => {
      this.setState({
        isGetCommentsRequestError: true,
        commentsIsLoading: true,
      });
      console.log(error);
      if (process.env.NODE_ENV !== "production") {
        return;
      }
     // Sentry.captureException(error);
    });
};

showComment = (e) => {
  e.preventDefault()
  if (this.state.showBloc) {
    this.setState({
      showBloc: false,
    });
  } else {
    this.setState({
      showBloc: true,
    });
  }
  this.getCommentOnProspect(this.state.commentOnProspectID);
};

handleAdminCommentChange = (e) => {
  let fields = this.state.acFields;
  fields["admin_id"] = this.state.adminID;
  fields["prospect_id"] = this.props.prospect_id;

  fields[e.target.name] = e.target.value;

  this.setState({
    acFields: fields,
  });

  // console.log(fields);
};


handleAddAdminComment = (e) => {
  e.preventDefault();
  this.setState({
    addCommentInProgress: true,
  });

 /* const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })*/
  
 

  let addData = this.state.acFields;
  axios
    .post(this.state.api + "admin-do-comment-prospects", addData)
    .then((response) => {
      this.setState({
        addCommentInProgress: false,
      });
      // console.log(response.data);
      if (response.data.success === true) {
        this.getProspectList();
        $(".close").click();
        $(".comment_input").val("");
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Commentaire ajouté',
          showConfirmButton: false,
          timer: 1500
        })
        this.setState({
          fields: {},
          addCommentByAdminSuccess: true,
          successAlert: true,
        });
        
      } else {
        this.setState({
          addCommentInProgress: false,
        });
        console.log("error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Echec !',
          
        })
      }
    })
    .catch((error) => {
      this.setState({
        addCommentInProgress: false,
        isAddCommentRequestError: true,
      });
      console.log(error);
      if (process.env.NODE_ENV !== "production") {
        return;
      }
      //Sentry.captureException(error);
    });
};

    render() {
      
    // console.log('test', this.state.adminComments);
    //   console.log(this.props.domaine_id, this.props.prospect_id);
      this.state.userName =this.props.firstName + " " + this.props.lastName;

      return (
        <div className="p-b-200">
          <div className="row">
            <div className="col-lg-9">
              <div className="row m-b-15">
                <div className="col m-b-15">
                  <h6 className="h-theme ff-inter fw-600">
                    Dossier de candidature
                  </h6>{" "}
                </div>
                <div className="col dis-flex m-b-15">

                                    
                <div className="ml-md-auto btn-comment">
              <button className="btn-add-with-icon-plus-circle" onClick={this.open}>
                Commentaire
                <Icon className="icon-add ml-2" icon="send" />
              </button>

              <Modal className="modal-responsive-sm p-b-50" show={this.state.show} onHide={this.close}>
              <button className="close-modal-bakeliste-title" onClick={this.close}>
                    <FeatherIcon icon="x" aria-hidden="true" />
                  </button>
                <form style={{overflow:'scroll', height:'500px', padding: '30px'}}>
                  <h5 className="modal-bakeliste-title m-b-50">Poster un commentaire</h5>
                  <div className="comments-prospects-container">
                  <div className="toggle-btn-container">
                    <button
                      className="btn-theme w-full fs-13 voir"
                      onClick={(e)=>this.showComment(e)}
                      onHide={this.open}
                    >
                      Voir les commentaires
                    </button>
                    
                  </div>
                  {this.state.showBloc ? (
                    <div className="comment-items" style={{overflow:'scroll', height:'400px'}}>
                      {this.state.commentsIsLoading ? (
                        <div className="loader-container">
                          <div id="loader2"></div>
                          <i>chargement en cours ...</i>
                        </div>
                      ) : this.state.adminComments.length !== 0 ? (
                        this.state.adminComments.map((comment, index) => (
                          <div key={index} className="comment-item">
                            <div className="comment-title">
                              Commentaire {comment.commenterFullname} {" "}
                              {comment.frenchDateFormat}
                            </div>
                            <div className="comment-content-container">
                              <p> {comment.comment_content} </p>
                            </div>
                            <hr />
                          </div>
                        ))
                      ) : (
                        <div className="alert alert-warning empty-comment-alert">
                          Aucun commentaire sur ce bakeliste.
                        </div>
                      )}

                    </div>
                  ) : (
                    ""
                  )}
                </div>
                  <button className="close-modal-bakeliste-title" onClick={this.close}>
                    
                  </button>
                  <Modal.Body className="max-height-modal pb-0">
                    <div className="form-group form-group-add-syllabus">
                      <textarea name="comment_content" type="text" className="form-control input-add-syllabus" id="nomSyllabus" placeholder="commentaire" 
                    onChange={this.handleAdminCommentChange}  />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="m-t-40 dis-flex justify-content-center">
                      <div className="col-sm-5">
                      {!this.state.addCommentInProgress ? (
                    <button
                      type="button"
                      className="btn-theme w-full fs-13"
                      onClick={(e) => this.handleAddAdminComment(e)}
                    > Ajouter un commentaire
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn button addBtn btn_for_wait"
                    >
                      ajout en cours &nbsp;
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                  )}
                      </div>
                    </div>
                    {this.state.successCalledAlert === true && (
          <div className="alert alert-success text-center upsuccess-alert">
            <i class="fas fa-check-double"></i> &nbsp;&nbsp; Appel déclaré avec
            succès.
          </div>
        )}
                  </Modal.Footer>
                  
                </form>
              </Modal>
            </div>
                  
                </div>
              </div> 
            
  
            
              <div className=" m-b-15 p-b-60">
                <div className="component-dossier-demande-Candidature">
                  <div className="card my-1 mt-2 dossier-demande-candidature">
                    <div className="card-body">
                      <div class="card btom-card-dossier-demande-candidature">
                        <div class="card-body images-aita-card-center">
                          <img src={Wagaan} />
                          
                          <h2>{this.state.userName}</h2>
                          {this.props.ispro === false ? (
                          <span>Etudiant</span>

                          ):(
                          <span>Professionnel</span>

                          )}
                        </div>
                      </div>
  
                      <div class="card btom-card-dossier-demande-candidature">
                        <div class="card-body">
                          <span className="experience-demande-candidature">
                            Informations personnelles
                          </span>
                          <table class="table table-borderless">
                            <thead>
                              <tr>
                                {/* <td scope="col">Date de naissance</td> */}
                                {/* <td scope="col">Date de naissance</td> */}
                                <td scope="col">Telephone</td>
                                <td scope="col">Adresse mail</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {/* <th>{this.state.formatDate(this.props.date)}
                                </th> */}
                                <th>{this.props.phone}</th>
                                <th>{this.props.email}</th>
                              </tr>
                              <tr>
                                <td>Adresse</td>
                                <td>WhatsApp</td>
                                <td >Domaine choisie</td>
                                {/* <td>N° carte d'identité</td> */}
                              </tr>
                              <tr>
                                <th>{this.props.adresse}</th>
                                <th>{this.props.phone}</th>
                                <th>{this.props.study_choice}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
 
                      <div class="card btom-card-dossier-demande-candidature">
                        <div class="card-body">
                          <span className="experience-demande-candidature">
                            Motivations
                          </span>
                          <p className="text-experience-demande-candidature">
                            {/* Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book */}
                            {this.props.motivation}
                          </p>
                        </div>
                      </div>
                      <div class="card btom-card-dossier-demande-candidature">
                        <div class="card-body">
                          <span className="experience-demande-candidature">
                            Formations
                          </span>
                          <br />
                          <table class="table table-borderless">
                            <thead>
                              <tr>
                                <td scope="col">Année</td>
                                <td scope="col">Diplôme obtenu</td>
                                <td scope="col">Ecole</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th> {this.state.domaine.name}</th>
                                <th>{this.props.type_formation} </th>
                              </tr>
                             
                            </tbody>
                          </table>

                       
                        </div>
                      </div>
                      
                      <div class="card btom-card-dossier-demande-candidature">
                        <div class="card-body">
                        </div>
                      </div>
                      <div className="row">
                        {/*<div className="col-md-6">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            id="annuller-buton-demande"
                          >
                            Supprimer
                          </button>
                        </div>
                        <div className="col-md-6">
                          <Link to="/demande-candidature">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-primary btn-lg"
                              id="annuller-buton-demande"
                            >
                              Modifier
                            </button>
                          </Link>
                        </div> */}
                      </div>

                    </div>
                  </div>



                </div>
              </div>
              </div>
              {/*<div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
                      </div>*/}
            
          </div>
        </div>
      );
    }
  }
