import "animate.css";
import { motion } from "framer-motion/dist/framer-motion";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import next from "./next.svg";
import prev from "./prev.svg";

const FormationBakeli = ({
  page,
  setPage,
  bakelisteInfos,
  setBakelisteInfos,
  domaines,
  x,
  setX,
  days,
}) => {
  const [typePresence, setTypePresence] = useState({});
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  // ! ********* gerer le type de formation
  const handleTypeFormation = (e) => {
    setBakelisteInfos({
      ...bakelisteInfos,
      type_formation: e.target.value,
      jour_fd: "",
    });
    if (e.target.value === "diplomante") {
      setBakelisteInfos({
        ...bakelisteInfos,
        type_formation: e.target.value,
        is_pro: 0,
        is_etudiant: 0,
        is_etudiant_a_tester: 0,
        jour_1: "lundi",
        jour_2: "mardi",
        jour_3: "mercredi",
        jour_4: "jeudi",
        jour_5: "vendredi",
        jour_fd: "lundi au vendredi",
        nbre_jours: 5,
        is_week: 1,
        is_weekend: 0,
      });
    }
  };

  // ! ********** gerer le status professionnel
  const handleStatus = (e) => {
    const status = e.target.id;
    setValue("horaire", "");
    setValue("jour_1", "");
    setValue("jour_2", "");
    setValue("jour_3", "");
    setValue("jour_4", "");
    setValue("jour_5", "");
    setValue("jour_6", "");
    setBakelisteInfos({
      ...bakelisteInfos,
      horaire: null,
      jour_1: "",
      jour_2: "",
      jour_3: "",
      jour_4: "",
      jour_5: "",
      jour_6: "",
      jour_fd: "",
    });

    if (status === "professionnel") {
      setBakelisteInfos({
        ...bakelisteInfos,
        is_pro: 1,
        is_etudiant: 0,
        is_etudiant_a_tester: 0,
        is_week: 0,
        is_weekend: 0,
        nbre_jours: 0,
        horaire: null,
        jour_1: "",
        jour_2: "",
        jour_3: "",
        jour_4: "",
        jour_5: "",
        jour_6: "",
        jour_fd: "",
      });
    }
    if (status === "etudiant") {
      setBakelisteInfos({
        ...bakelisteInfos,
        is_pro: 0,
        is_etudiant: 1,
        is_etudiant_a_tester: 0,
        is_week: 0,
        is_weekend: 0,
        nbre_jours: 0,
        horaire: null,
        jour_1: "",
        jour_2: "",
        jour_3: "",
        jour_4: "",
        jour_5: "",
        jour_6: "",
        jour_fd: "",
      });
      setTypePresence("");
    }
    if (status === "etudiant_a_tester") {
      setBakelisteInfos({
        ...bakelisteInfos,
        is_pro: 0,
        is_etudiant: 1,
        is_etudiant_a_tester: 1,
        is_week: 0,
        is_weekend: 0,
        nbre_jours: null,
        horaire: null,
        jour_1: "",
        jour_2: "",
        jour_3: "",
        jour_4: "",
        jour_5: "",
        jour_6: "",
        jour_fd: "",
      });
      setTypePresence("");
    }
  };

  // ! *********** type de presence
  const handleTypeDePresence = (e) => {
    const jourDeFormation = e.target.value;
    // ! ----- professionnel
    if (bakelisteInfos.is_pro) {
      if (jourDeFormation === "deux_jours") {
        setValue("horaire", "");
        setValue("jour_1", "");
        setValue("jour_2", "");
        setValue("jour_3", "");
        setValue("jour_4", "");
        setValue("jour_5", "");
        setValue("jour_6", "");
        setBakelisteInfos({
          ...bakelisteInfos,
          nbre_jours: 2,
          horaire: "17h30 - 19h30",
          is_weekend: 0,
          is_week: 1,
          jour_1: "",
          jour_2: "",
          jour_3: "",
          jour_4: "",
          jour_5: "",
          jour_6: "",
        });
      }
      // ! infos fixent
      if (jourDeFormation === "weekend") {
        setValue("horaire", "");
        setValue("jour_1", "");
        setValue("jour_2", "");
        setValue("jour_3", "");
        setValue("jour_4", "");
        setValue("jour_5", "");
        setValue("jour_6", "");
        setBakelisteInfos({
          ...bakelisteInfos,
          nbre_jours: 1,
          horaire: "9h - 13h",
          is_weekend: 1,
          is_week: 0,
          jour_1: "samedi",
          jour_2: "",
          jour_3: "",
          jour_4: "",
          jour_5: "",
          jour_6: "",
        });
      }
    }
    // ! --------- etudiant
    if (bakelisteInfos.is_etudiant || bakelisteInfos.is_etudiant_a_tester) {
      if (jourDeFormation === "trois_jours") {
        setValue("horaire", "");
        setValue("jour_1", "");
        setValue("jour_2", "");
        setValue("jour_3", "");
        setValue("jour_4", "");
        setValue("jour_5", "");
        setValue("jour_6", "");
        setBakelisteInfos({
          ...bakelisteInfos,
          nbre_jours: 3,
          horaire: "",
          is_weekend: 0,
          is_week: 1,
          jour_1: "",
          jour_2: "",
          jour_3: "",
          jour_4: "",
          jour_5: "",
          jour_6: "",
        });
      }
      // ! infos fixent
      if (jourDeFormation === "weekend") {
        setValue("horaire", "");
        setValue("jour_1", "");
        setValue("jour_2", "");
        setValue("jour_3", "");
        setValue("jour_4", "");
        setValue("jour_5", "");
        setValue("jour_6", "");
        setBakelisteInfos({
          ...bakelisteInfos,
          nbre_jours: 254,
          horaire: "9h - 17h",
          is_weekend: 1,
          is_week: 0,
          jour_1: "samedi",
          jour_2: "dimanche",
          jour_3: "",
          jour_4: "",
          jour_5: "",
          jour_6: "",
        });
      }
      if (jourDeFormation === "duLundiAuVendredi") {
        setValue("horaire", "");
        setValue("jour_1", "");
        setValue("jour_2", "");
        setValue("jour_3", "");
        setValue("jour_4", "");
        setValue("jour_5", "");
        setValue("jour_6", "");
        setBakelisteInfos({
          ...bakelisteInfos,
          nbre_jours: 5,
          horaire: "",
          is_weekend: 0,
          is_week: 1,
          jour_1: "lundi",
          jour_2: "mardi",
          jour_3: "mercredi",
          jour_4: "jeudi",
          jour_5: "vendredi",
        });
      }
    }
    setTypePresence(jourDeFormation);
  };

  // ! ********* next page
  const nextPage = (e) => {
    e.preventDefault();
    setX(10);
    const { horaire } = getValues();

    if (!bakelisteInfos.type_formation) {
      setError("type_formation");
      setTimeout(() => {
        clearErrors("type_formation");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.domaines_id) {
      setError("domaines_id");
      setTimeout(() => {
        clearErrors("domaines_id");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.is_formation_payante) {
      setError("is_formation_payante");
      setTimeout(() => {
        clearErrors("is_formation_payante");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.paiement_inscription) {
      setError("paiement_inscription");
      setTimeout(() => {
        clearErrors("paiement_inscription");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.is_stagede_formation) {
      setError("is_stagede_formation");
      setTimeout(() => {
        clearErrors("is_stagede_formation");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.lieu_formation) {
      setError("lieu_formation");
      setTimeout(() => {
        clearErrors("lieu_formation");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.bakeliste_level) {
      setError("bakeliste_level");
      setTimeout(() => {
        clearErrors("bakeliste_level");
      }, 1500);
      return;
    }

    if (!bakelisteInfos.training_duration) {
      setError("training_duration");
      setTimeout(() => {
        clearErrors("training_duration");
      }, 1500);
      return;
    }

    if (bakelisteInfos.is_etudiant || bakelisteInfos.is_etudiant_a_tester) {
      if (bakelisteInfos.nbre_jours === 5) {
        setBakelisteInfos({
          ...bakelisteInfos,
          horaire,
        });
      }
    }
    setPage(page + 1);
  };
  // ! prev page
  const prevPage = () => {
    setPage(page - 1);
    const { horaire } = getValues();

    if (bakelisteInfos.is_etudiant) {
      if (bakelisteInfos.nbre_jours === 5) {
        setBakelisteInfos({
          ...bakelisteInfos,
          horaire,
        });
      }
    }
    setX(-10);
  };

  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 1 }}
      animate={{ x: 0 }}
      className="py-5 multi-form"
    >
      <h2>Formation à Bakeli</h2>
      {/* select ******************** */}
      <div className="multi-form--label">
        <label style={{ fontWeight: "bold" }} htmlFor="courte">
          <input
            name="formation"
            type="radio"
            id="courte"
            value="courte"
            defaultChecked={
              bakelisteInfos.type_formation === "courte" ? "checked" : null
            }
            onChange={handleTypeFormation}
          />{" "}
          Formation courte
        </label>
        <label style={{ fontWeight: "bold" }} htmlFor="diplomante">
          <input
            name="formation"
            type="radio"
            id="diplomante"
            value="diplomante"
            defaultChecked={
              bakelisteInfos.type_formation === "diplomante" ? "checked" : null
            }
            onChange={handleTypeFormation}
          />{" "}
          Formation diplômante
        </label>
        {errors.type_formation && (
          <span style={{ color: "red" }} role="alert">
            Type de formation obligatoire
          </span>
        )}
      </div>

      {/* *************** Status professionnel *********** */}
      {bakelisteInfos.type_formation === "courte" && (
        <div className="animate__animated animate__fadeIn">
          <h4 className="mt-1 mb-1 h4-multi-step">Status professionnel</h4>
          <div className="form-status">
            <label style={{ color: "#666666" }} htmlFor="professionnel">
              <input
                name="status-professionnel"
                type="radio"
                id="professionnel"
                value="professionnel"
                defaultChecked={bakelisteInfos.is_pro ? "checked" : null}
                onChange={handleStatus}
              />{" "}
              Professionnel
            </label>
            <label
              style={{ color: "#666666" }}
              className="mx-sm-4"
              htmlFor="etudiant"
            >
              <input
                name="status-professionnel"
                type="radio"
                id="etudiant"
                value="etudiant"
                defaultChecked={bakelisteInfos.is_etudiant ? "checked" : null}
                onChange={handleStatus}
              />{" "}
              Etudiant
            </label>
            <label style={{ color: "#666666" }} htmlFor="etudiant_a_tester">
              <input
                name="status-professionnel"
                type="radio"
                id="etudiant_a_tester"
                value="etudiant_a_teste"
                defaultChecked={
                  bakelisteInfos.is_etudiant_a_tester ? "checked" : null
                }
                onChange={handleStatus}
              />{" "}
              Etudiant à tester
            </label>
            {errors.type_formation && (
              <span style={{ color: "red" }} role="alert">
                Formation obligatoire
              </span>
            )}
          </div>
        </div>
      )}

      {/* ********************* type de presence ***************************** */}

      {bakelisteInfos.type_formation === "courte" &&
        (bakelisteInfos.is_pro === 1 ? (
          <div className="animate__animated animate__fadeIn">
            <h4 className="mt-1 mb-1 h4-multi-step">Type de présence</h4>
            <div className="form-status">
              <label style={{ color: "#666666" }} htmlFor="deux_jours">
                <input
                  name="jourdeformation"
                  type="radio"
                  id="deux_jours"
                  value="deux_jours"
                  defaultChecked={
                    bakelisteInfos.is_pro && bakelisteInfos.nbre_jours === 2
                      ? "checked"
                      : null
                  }
                  onChange={handleTypeDePresence}
                />{" "}
                Semaine (2 jours de 17h 30 - 19h 30)
              </label>
              <label
                style={{ color: "#666666" }}
                className="mx-sm-4"
                htmlFor="weekend"
              >
                <input
                  name="jourdeformation"
                  type="radio"
                  id="weekend"
                  value="weekend"
                  defaultChecked={
                    bakelisteInfos.is_pro && bakelisteInfos.is_weekend
                      ? "checked"
                      : null
                  }
                  onChange={handleTypeDePresence}
                />{" "}
                Weekend (samedi 9h - 13h)
              </label>
              {errors.type_formation && (
                <span style={{ color: "red" }} role="alert">
                  Type de présence obligatoire
                </span>
              )}
            </div>
          </div>
        ) : // ********************* etudiant ou etudiant a tester ******************************
        bakelisteInfos.is_etudiant === 1 ||
          bakelisteInfos.is_etudiant_a_tester === 1 ? (
          <div className="animate__animated animate__fadeIn form-status">
            <h4 className="mt-1 mb-1 h4-multi-step">Type de présence</h4>
            <label style={{ color: "#666666" }} htmlFor="trois_jours">
              <input
                name="jourdeformation"
                type="radio"
                id="trois_jours"
                value="trois_jours"
                defaultChecked={
                  (bakelisteInfos.is_etudiant === 1 ||
                    bakelisteInfos.is_etudiant_a_tester === 1) &&
                  bakelisteInfos.nbre_jours === 3
                    ? "checked"
                    : null
                }
                onChange={handleTypeDePresence}
              />{" "}
              Semaine (3 jours)
            </label>
            <label
              style={{ color: "#666666" }}
              className="mx-sm-4"
              htmlFor="weekend"
            >
              <input
                name="jourdeformation"
                type="radio"
                id="weekend"
                value="weekend"
                defaultChecked={
                  (bakelisteInfos.is_etudiant === 1 ||
                    bakelisteInfos.is_etudiant_a_tester === 1) &&
                  bakelisteInfos.is_weekend
                    ? "checked"
                    : null
                }
                onChange={handleTypeDePresence}
              />{" "}
              Weekend (samedi et dimanche 9h - 17h)
            </label>
            <label
              style={{ color: "#666666" }}
              className="mx-sm-4"
              htmlFor="duLundiAuVendredi"
            >
              <input
                name="jourdeformation"
                type="radio"
                id="duLundiAuVendredi"
                value="duLundiAuVendredi"
                defaultChecked={
                  (bakelisteInfos.is_etudiant === 1 ||
                    bakelisteInfos.is_etudiant_a_tester === 1) &&
                  bakelisteInfos.nbre_jours === 5
                    ? "checked"
                    : null
                }
                onChange={handleTypeDePresence}
              />{" "}
              Semaine (du lundi au vendredi)
            </label>
            {errors.type_formation && (
              <span style={{ color: "red" }} role="alert">
                Type de présence obligatoire
              </span>
            )}
          </div>
        ) : null)}

      {/* +++++++++++++++++++++ formation admin deux jours +++++++++++++++++++++++ */}
      {bakelisteInfos.type_formation === "courte" &&
        bakelisteInfos.is_pro === 1 &&
        bakelisteInfos.nbre_jours === 2 && (
          <div className=" row-form">
            <div>
              <select
                defaultValue={bakelisteInfos.jour_1}
                onChange={(e) =>
                  setBakelisteInfos({
                    ...bakelisteInfos,
                    jour_1: e.target.value,
                  })
                }
                className="p-2 animate__animated animate__fadeIn"
              >
                <option value=""> -- Jour 1 --</option>
                {days
                  .filter((day) => day !== bakelisteInfos.jour_2)
                  .map((day, index) => (
                    <option key={index} value={day}>
                      {day}
                    </option>
                  ))}
              </select>
              {errors.horaire && <span role="alert">Jour obligatoire</span>}
            </div>
            <div>
              <select
                defaultValue={bakelisteInfos.jour_2}
                onChange={(e) =>
                  setBakelisteInfos({
                    ...bakelisteInfos,
                    jour_2: e.target.value,
                  })
                }
                className="p-2 animate__animated animate__fadeIn"
              >
                <option value=""> -- Jour 2 --</option>
                {days
                  .filter((day) => day !== bakelisteInfos.jour_1)
                  .map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
              </select>
              {errors.horaire && <span role="alert">Jour obligatoire</span>}
            </div>
          </div>
        )}

      {/* +++++++++++++++++++++ formation etudiant 3 jours +++++++++++++++++++++++ */}
      {bakelisteInfos.type_formation === "courte" &&
        (bakelisteInfos.is_etudiant === 1 ||
          bakelisteInfos.is_etudiant_a_tester === 1) &&
        bakelisteInfos.nbre_jours === 3 && (
          <>
            <div className="row-form">
              <div>
                <select
                  defaultValue={bakelisteInfos.horaire}
                  onChange={(e) =>
                    setBakelisteInfos({
                      ...bakelisteInfos,
                      horaire: e.target.value,
                    })
                  }
                  className="p-2 animate__animated animate__fadeIn"
                >
                  <option value=""> -- Horaire --</option>
                  <option value="8h - 17h">8h - 17h</option>
                  <option value="8h - 12h30">8h - 12h30</option>
                  <option value="12h30 - 17h">12h30 - 17h</option>
                </select>
                {errors.horaire && (
                  <span role="alert">Horaire obligatoire</span>
                )}
              </div>
              <div>
                <select
                  defaultValue={bakelisteInfos.jour_1}
                  onChange={(e) =>
                    setBakelisteInfos({
                      ...bakelisteInfos,
                      jour_1: e.target.value,
                    })
                  }
                  className="p-2 animate__animated animate__fadeIn"
                >
                  <option value=""> -- Jour 1 --</option>
                  {days
                    .filter((day) => day !== bakelisteInfos.jour_2)
                    .filter((day) => day !== bakelisteInfos.jour_3)
                    .map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                </select>
                {errors.horaire && <span role="alert">Jour obligatoire</span>}
              </div>
            </div>
            <div className="row-form">
              <div>
                <select
                  defaultValue={bakelisteInfos.jour_2}
                  onChange={(e) =>
                    setBakelisteInfos({
                      ...bakelisteInfos,
                      jour_2: e.target.value,
                    })
                  }
                  className="p-2"
                >
                  <option value=""> -- Jour 2 -- </option>
                  {days
                    .filter((day) => day !== bakelisteInfos.jour_1)
                    .filter((day) => day !== bakelisteInfos.jour_3)
                    .map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                </select>
                {errors.horaire && <span role="alert">Jour obligatoire</span>}
              </div>
              <div>
                <select
                  className="animate__animated animate__fadeIn p-2"
                  defaultValue={bakelisteInfos.jour_3}
                  onChange={(e) =>
                    setBakelisteInfos({
                      ...bakelisteInfos,
                      jour_3: e.target.value,
                    })
                  }
                >
                  <option value=""> -- Jour 3 --</option>
                  {days
                    .filter((day) => day !== bakelisteInfos.jour_1)
                    .filter((day) => day !== bakelisteInfos.jour_2)
                    .map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                </select>
                {errors.horaire && <span role="alert">Jour obligatoire</span>}
              </div>
            </div>
          </>
        )}

      {/* +++++++++++++++++++++ formation etudiant 5 jours +++++++++++++++++++++++ */}
      {bakelisteInfos.type_formation === "courte" &&
        (bakelisteInfos.is_etudiant === 1 ||
          bakelisteInfos.is_etudiant_a_tester === 1) &&
        bakelisteInfos.nbre_jours === 5 && (
          <>
            <div className="row-form">
              <div>
                <select
                  defaultValue={bakelisteInfos.horaire}
                  {...register("horaire", { required: true })}
                  className="p-2 animate__animated animate__fadeIn"
                >
                  <option value=""> -- Horaire --</option>
                  <option value="8h - 17h">8h - 17h</option>
                  <option value="8h - 12h30">8h - 12h30</option>
                  <option value="12h30 - 17h">12h30 - 17h</option>
                </select>
                {errors.horaire && <span role="alert">Jour obligatoire</span>}
              </div>
            </div>
          </>
        )}

      {bakelisteInfos.type_formation === "diplomante" && (
        <div className="row-form ">
          <select
            defaultValue={bakelisteInfos.horaire}
            onChange={(e) =>
              setBakelisteInfos({ ...bakelisteInfos, horaire: e.target.value })
            }
            className="p-2 animate__animated animate__fadeIn"
          >
            <option value=""> -- Horaire --</option>
            <option value="8h - 17h">8h - 17h</option>
            <option value="8h - 12h30">8h - 12h30</option>
            <option value="12h30 - 17h">12h30 - 17h</option>
          </select>
          {errors.horaire && <span role="alert">Horaire obligatoire</span>}
        </div>
      )}
      <div className="row-form">
        <div>
          <select
            defaultValue={bakelisteInfos.domaines_id}
            className="p-2"
            onChange={(e) => {
              setBakelisteInfos({
                ...bakelisteInfos,
                domaines_id: Number(e.target.value),
              });
            }}
          >
            <option value=""> -- choisissez un domaine --</option>
            {domaines.map((domaine) => (
              <option
                name={domaine?.name}
                key={domaine?.id}
                value={domaine?.id}
              >
                {domaine.name}
              </option>
            ))}
          </select>
          {errors.domaines_id && <span role="alert">Domaine obligatoire</span>}
        </div>
        <div>
          <select
            defaultValue={bakelisteInfos.is_formation_payante}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                is_formation_payante: e.target.value,
              })
            }
          >
            <option value=""> -- Formation payante --</option>
            <option value={1}>Oui</option>
            <option value={0}>Non</option>
          </select>
          {errors.is_formation_payante && (
            <span role="alert">Formation payante obligatoire</span>
          )}
        </div>
      </div>
      {/* select ******************** */}
      <div className="row-form">
        <div>
          <select
            defaultValue={bakelisteInfos.paiement_inscription}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                paiement_inscription: e.target.value,
              })
            }
          >
            <option value=""> -- Paiement inscription --</option>
            <option value={1}>Oui</option>
            <option value={0}>Non</option>
          </select>
          {errors.paiement_inscription && (
            <span role="alert">Paiement inscription obligatoire</span>
          )}
        </div>
        <div>
          <select
            defaultValue={bakelisteInfos.is_stagede_formation}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                is_stagede_formation: e.target.value,
              })
            }
          >
            <option value=""> -- Stage de formation --</option>
            <option value={1}>Oui</option>
            <option value={0}>Non</option>
          </select>
          {errors.is_stagede_formation && (
            <span role="alert">Stage de formation obligatoire</span>
          )}
        </div>
        <div>
          <select
            defaultValue={bakelisteInfos.lieu_formation}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                lieu_formation: e.target.value,
              })
            }
          >
            <option value=""> -- Choisir un lieu --</option>
            <option value="Grand Yoff 1">Grand Yoff 1</option>
            <option value="Grand Yoff 2">Grand Yoff 2</option>
            <option value="Thiés">Thiès</option>
            <option value="Mbour">Mbour</option>
            <option value="Labé">Labé (Guinée)</option>
            <option value="Conakry">Conakry(Guinée)</option>
          </select>
          {errors.lieu_formation && (
            <span role="alert">Lieu de formation obligatoire</span>
          )}
        </div>
      </div>
      {/* select ******************** */}
      <div className="row-form">
        <div>
          <select
            defaultValue={bakelisteInfos.bakeliste_level}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                bakeliste_level: e.target.value,
              })
            }
          >
            <option value=""> -- Choisir le niveau --</option>
            <option value="débutant">Débutant</option>
            <option value="intermédiaire">Intermédiaire</option>
            <option value="avancé">Avancé</option>
          </select>
          {errors.bakeliste_level && (
            <span role="alert">Niveau du Bakeliste obligatoire</span>
          )}
        </div>

        <div>
          <select
            defaultValue={bakelisteInfos.training_duration}
            className="p-2"
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                training_duration: e.target.value,
              })
            }
          >
            <option value=""> -- Choisir une durée --</option>
            <option value="3">3 mois</option>
            <option value="6">6 mois</option>
            <option value="12">12 mois</option>
            <option value="24">24 mois</option>
          </select>
          {errors.training_duration && (
            <span role="alert">Durée de formation obligatoire obligatoire</span>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <button className="btn-switch btn-prev" onClick={prevPage}>
          <img src={prev} alt="Prev" className="mr-3" /> prec
        </button>
        <button className="btn-switch btn-next" onClick={nextPage}>
          suivant <img className="ml-3" src={next} alt="Next" />
        </button>
      </div>
    </motion.div>
  );
};

export default FormationBakeli;
