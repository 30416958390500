import FeatherIcon from "feather-icons-react";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Avatar, Dropdown, Icon } from "rsuite";
import Logo from "../../img/logo.png";
import API from "../../variablesGlobales";
import "./headerDashboardAdmin.css";

import axios from "axios";

import SearchIcon from "@material-ui/icons/Search";
import Notifications from "../newNotificationSystem/Notifications";

export default class headerDashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      token: window.sessionStorage.getItem("token"),
      userStatus: window.sessionStorage.getItem("user_status"),
      userID: window.sessionStorage.getItem("user_id"),
      userName: window.sessionStorage.getItem("userFullName"),
      email: sessionStorage.getItem("email"),
    };
  }

  doLogout = () => {
    window.sessionStorage.removeItem("token");
    axios.get(this.state.api + "user-logout").then((response) => {
      const result = response.data;
      //  console.log(result);
      if (result.success === true) {
        window.location = "/";
      }
    });
  };

  render() {
    return (
      <div>
        <nav className=" navbar-expand-lg navbar-light bg-white box-shadow fixed-top px-lg-5 py-md-3 height-header">
          <div className="d-flex justify-content-between align-items-cente p-3">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <NavLink className=" mr-lg-5 mr-0" to="/">
              <img className="logo-header" src={Logo} alt="" />
            </NavLink>

            <p className="d-none d-lg-block welcome-header m-0 mt-3">
              Bienvenue sur Bakeli SI{" "}
            </p>

            <Avatar className="avatar-rs-custom no-display-desktop">RS</Avatar>

            <div className="ml-auto no-view-tablette">
              <div className="avatar-group d-flex align-items-center">
                <Notifications />
                <Avatar className="avatar-rs-custom">
                  {this.state.userName
                    .split(" ")
                    .map((n, i, a) =>
                      i === 0 || i + 1 === a.length ? n[0] : null
                    )
                    .join("")}
                </Avatar>
                <Dropdown
                  className="name-avatar-custom"
                  title={this.state.userName}
                  placement="bottomEnd"
                  trigger="hover"
                >
                  <NavLink
                    className="dropdown-item-header-dashboard"
                    to="/compte-bakeliste"
                  >
                    Mon compte
                  </NavLink>

                  <Dropdown.Item onClick={this.doLogout}>
                    Déconnexion
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="collapse" id="navbarSupportedContent">
            <div className="d-block d-lg-none">
              <div className="container-nav-vertical">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tabCustom"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/dashboard-admin"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="layout"
                    />
                    Dashboard
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/bakelistes-db"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="users"
                    />
                    Bakelistes
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-coachs"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="users"
                    />
                    Coachs
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-coach-assistants"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="users"
                    />
                    Coachs Assistants
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-admins"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="user-plus"
                    />
                    Admins
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/stagiaires"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="user-plus"
                    />
                    Stagiaires
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/evenements"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="shield"
                    />
                    Evenements
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/users-admin"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="users"
                    />
                    Utilisateurs
                  </NavLink>{" "}
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-prospect"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="user-plus"
                    />
                    Prospects
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-bakelistes-recrutables"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="user-plus"
                    />
                    Recrutables
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/liste-des-bakelistes-en-formation"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="user-check"
                    />
                    Suivi
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/parametres-admin"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="settings"
                    />
                    Parametres
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
