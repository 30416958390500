import React, { Component } from "react";
import "./listeEvent.css";
import { Link } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
import PresentationEvent from "../../img/presentationEvent.png";
import ProgrammationEvent from "../../img/programmationEvent.png";
import Calendrier from "../../img/calendrier.png";
import Monde from "../../img/monde.png";
import ImageAbd from "../../img/imageAbd.png";
import KakutanaEvent from "../../img/kakutanaEvent.png";
import MeetingEvent from "../../img/meetingEvent.png";
import Workshop from "../../img/workshop.png";
import Investir from "../../img/investir.png";
import BonnePratique from "../../img/bonnePratique.png";
import More_vert from '../../img/more_vert.png';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeEventActions from "../../store/listeEvent/actions";
export default class listeEvent extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
    
              <div className="container-fluid bg-white">
                <div className="row">

                  <div className="d-flex m-b-10 p-4">
                    <div>
                       <img src={Monde} /> 
                   </div>
                  </div>
                  <div className="col-lg-7">
                  <div className="carte-jeemacoder-dateLine-event">
                    <div className="pl-2 mb-4 p-t-2">
                        <p>
                          <img src={Calendrier} alt=""/>
                          <span className="formate-txt-dateLine pl-3">
                            {this.props.date}
                          </span>
                          <br />
                          <span className="formate-txt-dateLine pl-5">
                            {this.props.name}
                          </span>
                        </p>
                         {/* <div className="row">
                         <div className="dropdown offset-9 haut-btn-mod">
                            <img src={More_vert} className="btn dropdown-toggle ml-5"
                             data-toggle="dropdown" alt="..." />
                                <div 
                                  className="dropdown-menu dropdown-menu-right change-position" 
                                  aria-labelledby="dropdownMenuButton">
                                  <a className="dropdown-item mb-3" href="#">Modifier</a>
                                  <a className="dropdown-item mb-3" href="#">Supprimer</a>
                                </div>
                              </div>
                           </div> */}
                      </div>
                      <div className="pl-2">
                        <p>
                          <FeatherIcon
                            icon="map-pin"
                            className="couleur-icon-map"
                          />
                          <span className="formate-txt-dateLine pl-3">
                            HLM GY prés du Terminus villa N°241
                          </span>
                        </p>
                        
                      </div>
                  </div>
                 
              </div>
            </div>
          </div>
      //   </div>

        
      // </div>
    );
  }
}
// export default connect(
//     ({ listeEvent }) => ({ ...listeEvent }),
//     dispatch => bindActionCreators({ ...listeEventActions }, dispatch)
//   )( listeEvent );
