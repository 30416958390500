import React, {Component} from 'react';
import './tableMyAllbakelistesView.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
// import AllBakelisteAdmin from '../allBakelisteAdmin/index';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyAllbakelistesViewActions from "../../store/tableMyAllbakelistesView/actions";
export default class tableMyAllbakelistesView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
            <div className="component-table-my-allbakelistes-view body-theme">

                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      {/* <AllBakelisteAdmin /> */}
                    </div>
                  </div>
                </div>
              </div>
        );
    }
  }
// export default connect(
//     ({ tableMyAllbakelistesView }) => ({ ...tableMyAllbakelistesView }),
//     dispatch => bindActionCreators({ ...tableMyAllbakelistesViewActions }, dispatch)
//   )( tableMyAllbakelistesView );