import React, {Component} from 'react';
import './listeGroupesAdmin.css';
import { Link } from "react-router-dom";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { Icon } from "rsuite";
import { NavLink } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import API from "../../variablesGlobales";
import IconGroup from "../../img/icons/people.png";
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "rsuite";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeGroupesAdminActions from "../../store/listeGroupesAdmin/actions";
export default class listeGroupesAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      groupes: [],
      loading: false,
      showAlertError: false,
      showAlertSuccess: false,
      userID: window.sessionStorage.getItem("user_id"),
      list: [],
      group_name: "",
      lien_whatsapp: "",
      id: "",
      showEditModal: false,
      fields: {},
      size: "md",
      value: " ",
      groupe: {},
      editGroupeInProgress: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  componentDidMount() {
    this.getGroupes();
    // this.isLoading = setTimeout(() => {
    //   this.setState({ loading: false });
    // }, 2000);
  }

  open(item) {
    // $(`#editGroupModal${item.id}`)
    this.setState({
      group_name: item.group_name,
      show: true,
    });
  }
  // hideLoader = () => {
  //   this.setState({ loading: false });
  // };

  // showLoader = () => {
  //   this.setState({ loading: true });
  // };

  

  onSubmitEditDetailsGroupe = (e) => {
    e.preventDefault();
    this.setState({
      editGroupeInProgress: true,
    });
    var fd = new FormData();
    fd.append("group_name", this.state.group_name);
    fd.append("lien_whatsapp", this.state.lien_whatsapp);

    // data["group_name"] = this.state.group_name;
    axios
      .put(this.state.api + "groupes/" + this.state.id, fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        this.setState({
          editGroupeNameInProgress: true,
        });
        if (response.data.success) {
          this.setState({
            showAlertSuccess: true,
            editGroupeInProgress: false,

            showEditModal: false,
          });
          this.close();
          this.getGroupes();
        } else {
          this.setState({
            showAlertError: true,
            editGroupeInProgress: false,

            showEditModal: false,
          });
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          editGroupeNameInProgress: false,
          showAlertError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getGroupes = () => {
    // const _this = this;
    // this.showLoader();
    axios
      .get(this.state.api + "groupes")
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.success) {
          this.setState({
            groupes: res.data.data.reverse(),
            name: res.data.data.group_name,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
       // _this.hideLoader();
      });
  };

  editGroup = (e, id, group_name) => {
    e.preventDefault();
    this.setState({
      addGroupContentInProgress: true,
    });
    var data = this.state.fields;
    axios
      .put(this.state.api + "groupes" + id, data)
      .then((response) => {
        this.setState({
          editGroupContentInProgress: true,
        });
        if (response.data.success) {
          this.setState({
            showAlertSuccess: true,
          });
        } else {
          // console.log(response);
          this.setState({
            showAlertError: true,
          });
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          editGroupContentInProgress: false,
          showAlertError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  deleteGroup = (id) => {
    axios
      .delete(this.state.api + "groupes/" + id)
      .then((res) => {
        if (res.data.success) {
          this.getGroupes();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  editGroupeModal = (id) => {
    this.onGetGroupeById(id);
    this.setState({
      showEditModal: true,
    });
  };

  onGetGroupeById = (id) => {
    const _this = this;
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;
    axios
      .get(baseApiUrl + "groupes/" + id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            groupe: response.data.data,
            group_name: response.data.data.group_name,
            id: response.data.data.id,
          });
      
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };

  close = () => {
    this.setState({
      showEditModal: false,
    });
  };
 
  render() {
    // const { loading } = this.state;

    return (
      <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
      
            <div className="row">
             
             
              {this.state.groupes.map((item, index) => (
                <div
                  className="col-md-6 col-sm-4 pr-sm-2 mb-3 dis-flex"
                  key={index}
                >
                  <div className="item-statistics-dashboard">
                    <Link
                      to={{
                        pathname: "/sous-groupe",
                        state: { id: item.id },
                      }}
                    >
                      <p className="groupe-titre-on-coach-dashboard">
                        {item.group_name}
                      </p>

                      <p className="designation-item-statistics-dashboard"> </p>
                      <img
                        className="img-item-statistics-dashboard1"
                        src={IconGroup}
                        alt=""
                      />
                    </Link>
                    
                    {item.sous_groupes !== undefined && (
                      <span>{item.sous_groupes.length} sous-groupes</span>
                    )} <br/>
                    <a 
                     // className="lien-whatsapp"
                      href={item.lien_whatsapp}
                      target="blank"
                    >
                      {item.lien_whatsapp}
                    </a>
                    <div className="row">
                    <div className="col-md-6">

                      <span onClick={() => this.editGroupeModal(item.id)}>
                        <FeatherIcon
                          icon="edit"
                          className="btn-modifier "
                          type="button"
                          aria-hidden="true"
                        />
                      </span>
                      </div>
                    <div className="col-md-6">


                      <FeatherIcon
                        icon="archive"
                        className="btn-archiver-groupe "
                        type="button"
                        onClick={() => this.deleteGroup(item.id)}
                        aria-hidden="true"
                      />
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id={`editGroupModal${item.id}`}
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="editDetailsSyllabusModalLabel"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {/* Alert Livrable envoye avec succes */}
                          <SweetAlert
                            show={this.state.showAlertSuccess}
                            onConfirm={() =>
                              this.setState({ showAlertSuccess: false })
                            }
                            success
                            title="têche modifié avec succes!!!"
                            style={{
                              color: "#069A77",
                              fontSize: "12px",
                              fontFamily: "Work Sans, sans-serif",
                              paddingTop: 60,
                              paddingBottom: 40,
                            }}
                            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                          />
                          <SweetAlert
                            show={this.state.showAlertError}
                            onConfirm={() =>
                              this.setState({ showAlertError: false })
                            }
                            error
                            title="Erreur sur modification du groupe, veuillez reessayer!!!"
                            style={{
                              color: "#d9534f",
                              fontSize: "12px",
                              fontFamily: "Work Sans, sans-serif",
                              paddingTop: 60,
                              paddingBottom: 40,
                            }}
                            confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                          />
                          <Modal
                            className="modal-responsive-md"
                            size={this.state.size}
                            show={this.state.showEditModal}
                            onHide={this.close}
                          >
                            <form
                              encType="multipart/form-data"
                              onSubmit={(e) =>
                                this.onSubmitEditDetailsGroupe(e)
                              }
                            >
                              <Modal.Header>
                                <Modal.Title className="modal-contact-us">
                                  Modifier Groupe{" "}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="max-height-modal">
                                <div className="form-group as_input_container">
                                  <input
                                    value={this.state.group_name}
                                    onChange={(e) =>
                                      this.setState({
                                        group_name: e.target.value,
                                      })
                                    }
                                    name="group_name"
                                    placeholder="Nom du groupe"
                                    className="form-control input_item"
                                    id="group_name"
                                  />
                                  <br/>
                                   <input
                                    value={this.state.lien_whatsapp}
                                    onChange={(e) =>
                                      this.setState({
                                        lien_whatsapp: e.target.value,
                                      })
                                    }
                                    name="lien_whatsapp"
                                    placeholder="Lien groupe whatsapp"
                                    className="form-control input_item"
                                    id="lien_whatsapp"
                                  />
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className="row dis-flex justify-content-end">
                                  <div className="col-md-5 col-sm-6">
                                    {this.state.editGroupeInProgress ? (
                                      <button
                                        type="submit"
                                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                        disabled
                                      >
                                        Modification en cours &nbsp;
                                        <FeatherIcon
                                          icon="loader"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                      >
                                        MODIFIER
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </Modal.Footer>
                            </form>
                          
                          
                          </Modal>
                          ENd ALert Error
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          
      </div>
    );
  }
}
// export default connect(
//     ({ listeGroupesAdmin }) => ({ ...listeGroupesAdmin }),
//     dispatch => bindActionCreators({ ...listeGroupesAdminActions }, dispatch)
//   )( listeGroupesAdmin );