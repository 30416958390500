import React, {Component} from 'react';
import './tableBakelisteNivLabe.css';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import API from "../../variablesGlobales";
import Swal from 'sweetalert2';
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableBakelisteNivLabeActions from "../../store/tableBakelisteNivLabe/actions";

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <i className="fas fa-pen icon-btn-icon-table"> </i>
        </button>
        
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={18} />
        </button>
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}

export default class tableBakelisteNivLabe extends Component {
    constructor(props) {
        super(props);
        this.state = {
          admin: '',
          coach: '',
          bakeliste: [],
          domaines:[],
          bakelisteID: 0,
          api: API.API,
          BakelisteFullname: "",
          BakelisteEmail: "",
          nbreBakeliste: 0,
          isEmptyData: false,
          getBakelisteRequestError: false,
          completedFormationRequestError: false,
          BakelisteDomaine: "",
          stageFormationValue: false,
          firstNameValue: "",
          lastNameValue: "",
          typeStage:"",
          loading: true,
        };
    }

    componentDidMount() {
      this.getListeAllBakeliste();
      this.getBakelisteLabe();
    }

    handleShowAndHideAlert(label) {
      setTimeout(() => {
        
          if (label === 'successCompletedFormation') {
              this.setState({
                  successCompletedFormationAlert: false
              })
          }
          if (label === 'errorCompletedFormation') {
              this.setState({
                  completedFormationRequestError: false
              })
          }
        
          
      }, 5000);
    }

    getBakelisteLabe = () =>{
      axios.get(this.state.api + 'bakelistes-by-coach/' + this.state.coach_id)
          .then((response) => {
              if (response.data.data !== undefined) {
                  const nbreBakeliste = response.data.count;
                  // console.log('nombre bakeliste', response.data.count);
                  this.setState({
                      nbreBakeliste: nbreBakeliste
                  });
              } else {
                  this.setState({
                      nbreBakeliste: 0
                  })
              }
          })
          .catch(error => {
              if (process.env.NODE_ENV !== 'production') {
                  return;
              }
             // Sentry.captureException(error);
          })
    }

    handleCompletedFormation = (bakeliste) => {
      this.setState({
          completedInProgress: true
      });
    
      let bakelisteID = bakeliste.id;
      let data = {};
    
      axios.post(this.state.api + 'completed-bakelistes-formation/' + bakelisteID, data)
          .then(response => {
              this.setState({
                  completedInProgress: false
              });
     
              if (response.data.success === true) {
                  // console.log('okay')
                //  this.getAllbakeliste();
                  this.getBakelisteLabe();
                  this.setState({
                      successCompletedFormationAlert: true
                  })
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Formation arrêtée avec succèe',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.handleShowAndHideAlert('successCompletedFormation')
              } else {
                  // console.log(response);
                  this.setState({
                      completedFormationRequestError: true,
                  })
                  this.handleShowAndHideAlert('errorCompletedFormation')
              }
          })
          .catch(error => {
              console.log(error)
              this.setState({
                  completedFormationRequestError: true,
                  completedInProgress: false
              })
              this.handleShowAndHideAlert('errorCompletedFormation')
              if (process.env.NODE_ENV !== 'production') {
                  return;
              }
            //  Sentry.captureException(error);
          })
    }

    getListeAllBakeliste = () => {
      axios
        .get(this.state.api + 'liste-tous-les-bakelistes')
        .then((res) => {
          if (res.data.success === true) {
            // console.log('All Bakelistes', res.data.data)
    
            this.setState({
              bakeliste: res.data.data,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              isEmptyData: true,
            });
            //console.log(res);
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            getBakelisteRequestError: true,
          });
          console.log(error.message);
        });
    };

    
    BakelisteNiveau = (Column, row) => {
      return(
        <div className="la-taille-div">
          {row.statut === "stage" ?
            (
            <span className="stage-color">Formation</span>
            )
                :
            (
            <span className="stage-color"> Payante</span>
            )
          }
        </div>
      )
}
BakelisteNiveauFormation = (Column, row) => {
  return(
    <div className="la-taille-div">
      {row.statut === "Formation" ?
        (
        <span className="admin-color">courte</span>
        )
            :
        (
        <span className="coach-color"> Diplômée</span>
        )
      }
    </div>
  )
}
CoachNiveau = (Column, row) => {
  return(
    <div className="">
      {row.coach === "Makhtar" ?
        (
        <span className="">Makhtar</span>
        )
            :
        (
        <button className="btn-icon-user-plus">  
          <FeatherIcon className="icon-btn-icon-user-plus" icon="user-plus" size={18} />
      </button>
        )
      }
    </div>
  )
}
CustomExportCsvBtn = (onClick) =>{
  return(
    <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
  );
}

    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      }; 
      return (
        <div className="component-table-bakeliste-niv-labe">
          <BootstrapTable 
                  pagination
                  data={this.state.bakeliste} 
                  options={options}
                  exportCSV
                  csvFileName="utilisateurs.csv"
                  // data={gestionTacheForCoach} 
                  
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                    <TableHeaderColumn dataField="id" isKey={true} hidden={true}>{" "} ID </TableHeaderColumn>
                    
                  <TableHeaderColumn 
                    
                    
                    dataField='bakelisteFullname' 
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Nom Complet</span> 
                    
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                    dataField='domaine' width="100"                     
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Domaine</span>
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                      dataField='type_formation' width="100" 
                      dataFormat={this.BakelisteNiveauFormation}
                      
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Type de Formation</span>
                     </TableHeaderColumn>
                     <TableHeaderColumn 
                      dataField='duree' width="200" 
                      dataFormat={this.BakelisteNiveau}
                      
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Type de Stage</span>
                     </TableHeaderColumn>
                     <TableHeaderColumn 
                      dataField='coachFullname' width="100" 
                      dataFormat={this.CoachNiveau}
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Coach</span>
                     </TableHeaderColumn>
                  <TableHeaderColumn 
                  dataField='' 
                  dataFormat={uploadFormatter} 
                  
                  thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                  tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs"> Actions</span>
                    </TableHeaderColumn>
                </BootstrapTable>
          </div>
        );
    }
  }
// export default connect(
//     ({ tableBakelisteNivLabe }) => ({ ...tableBakelisteNivLabe }),
//     dispatch => bindActionCreators({ ...tableBakelisteNivLabeActions }, dispatch)
//   )( tableBakelisteNivLabe );