import React, { useEffect, useReducer } from "react";
import "./recrutablesView.css";
// import { connect } from "react-redux";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import Recrutables from "../../components/recrutables/index";
import API from "../../variablesGlobales";

import axios from "axios";
import * as Sentry from "@sentry/react";
import RecrutablesRefact from "../recrutables/RecrutablesRefact";
const RecrutableViewRefact = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      Recrutables: [],
      api: API.API,
    }
  );
  useEffect(() => {
    getRecrutables();
  }, []);
  const getRecrutables = () => {
    axios
      .get(state.api + "liste-bakelistes-recrutables")
      .then((response) => {
        if (response.data.data !== undefined) {
          setState({
            Recrutables: response.data.data,
          });
          // console.log(state.Recrutables, 'recru')
        } else {
          setState({
            Recrutables: 0,
          });
        }
        // _hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        // _hideLoader();
      });
  };
  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <RecrutablesRefact Recrutables={state.Recrutables} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecrutableViewRefact;
