import React, { useReducer } from "react";
import "./profilBakeliste.css";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Coach from "../../img/coach.png";
const ProfilBakelisteRefact = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      domaineFilter: "",
    }
  );

  const handleChange = (e) => {
    setState({
      domaineFilter: e.target.value
    })
    props.onChange(e.target.value)
  }
  return  <div className="component-profil-bakeliste">
  <div className="container-fluid px-0">
    <div className="row">
      <div className="col-lg-12">

          <div className="container">
           <div className="row">
              <div className="pl-5 pb-4 pt-3">
                  <div className="search-wrapper">
                      <span className="fa fa-search"></span>
                          <input type="search" name="" 
                          placeholder="recherche"
                          id="filter" 
                          value={state.domaineFilter} 
                          onChange={handleChange} />
                      </div>    
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-4">
                      <div className="text-center card-box">
                          <div className="member-card pt-2 pb-2">
                              <div className="thumb-lg member-thumb mx-auto">
                              <img src={Coach} className="rounded-circle img-thumbnail" alt="profile-image" /></div>
                              <div className="">
                                  <h5>Assane Gueye</h5>
                                  <p className="text-muted">Marketer</p>
                              </div>
                              <ul className="social-links list-inline">
                                  <FeatherIcon icon="facebook" className="list-inline-item" />
                                  <FeatherIcon icon="twitter" className="list-inline-item" />
                                  <FeatherIcon icon="instagram" className="list-inline-item" />
                              </ul>
                              <Link to="/profil-visiteur"><button type="button" 
                              className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                              <div className="mt-4">
                                  <div className="row">
                                      <div className="col-4">
                                          <div className="mt-3">
                                          
                                              <p className="mb-0 text-muted">Livrables</p>
                                              <p>121</p>
                                          </div>
                                      </div>
                                      <div className="col-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Pointage</p>
                                              <p>Absent</p>
                                          </div>
                                      </div>
                                      <div className="col-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Avis du Coach</p>
                                              <p> il vient souvent en retard</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/* <!-- end col --> */}
                  <div className="col-lg-4">
                      <div className="text-center card-box">
                          <div className="member-card pt-2 pb-2">
                              <div className="thumb-lg member-thumb mx-auto">
                              <img src={Coach} className="rounded-circle img-thumbnail" alt="profile-image" /></div>
                              <div className="">
                                  <h5>Aita Mbaye</h5>
                                  <p className="text-muted">Programmer</p>
                              </div>
                              <ul className="social-links list-inline">
                                  <FeatherIcon icon="facebook" className="list-inline-item" />
                                  <FeatherIcon icon="twitter" className="list-inline-item" />
                                  <FeatherIcon icon="instagram" className="list-inline-item" />
                              </ul>
                              <Link to="/profil-visiteur"><button type="button" 
                              className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                              <div className="mt-4">
                                  <div className="row">
                                      <div className="col-4">
                                          <div className="mt-3">
                                          
                                              <p className="mb-0 text-muted">Livrables</p>
                                              <p>121</p>
                                          </div>
                                      </div>
                                      <div className="col-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Pointage</p>
                                              <p>Absent</p>
                                          </div>
                                      </div>
                                      <div className="col-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Avis du Coach</p>
                                              <p> il vient souvent en retard</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/* <!-- end col --> */}
                  <div className="col-lg-4">
                      <div className="text-center card-box">
                          <div className="member-card pt-2 pb-2">
                              <div className="thumb-lg member-thumb mx-auto">
                              <img src={Coach} 
                              className="rounded-circle img-thumbnail" alt="profile-image" /></div>
                              <div className="">
                                  <h5>Kalika</h5>
                                  <p className="text-muted">Webdesigner</p>
                              </div>
                              <ul className="social-links list-inline">
                                  <FeatherIcon icon="facebook" className="list-inline-item" />
                                  <FeatherIcon icon="twitter" className="list-inline-item" />
                                  <FeatherIcon icon="instagram" className="list-inline-item" />
                              </ul>
                              <Link to="/profil-visiteur"><button type="button" 
                              className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                              <div className="mt-4">
                                  <div className="row">
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                          
                                              <p className="mb-0 text-muted">Livrables</p>
                                              <p>121</p>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Pointage</p>
                                              <p>Absent</p>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Avis du Coach</p>
                                              <p> il vient souvent en retard</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/* <!-- end col --> */}
              </div>
              {/* <!-- end row --> */}
              <div className="row">
                  <div className="col-lg-4">
                      <div className="text-center card-box">
                          <div className="member-card pt-2 pb-2">
                              <div className="thumb-lg member-thumb mx-auto">
                              <img src={Coach} className="rounded-circle img-thumbnail" alt="profile-image" /></div>
                              <div className="">
                                  <h5>Assane Gueye</h5>
                                  <p className="text-muted">Marketer</p>
                              </div>
                              <ul className="social-links list-inline">
                                  <FeatherIcon icon="facebook" className="list-inline-item" />
                                  <FeatherIcon icon="twitter" className="list-inline-item" />
                                  <FeatherIcon icon="instagram" className="list-inline-item" />
                              </ul>
                              <Link to="/profil-visiteur"><button type="button" 
                              className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                              <div className="mt-4">
                                  <div className="row">
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                          
                                              <p className="mb-0 text-muted">Livrables</p>
                                              <p>121</p>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Pointage</p>
                                              <p>Absent</p>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Avis du Coach</p>
                                              <p> il vient souvent en retard</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/* <!-- end col --> */}
                  <div className="col-lg-4">
                      <div className="text-center card-box">
                          <div className="member-card pt-2 pb-2">
                              <div className="thumb-lg member-thumb mx-auto">
                              <img src={Coach} className="rounded-circle img-thumbnail" alt="profile-image" /></div>
                              <div className="">
                                  <h5>Aita Mbaye</h5>
                                  <p className="text-muted">Programmer</p>
                              </div>
                              <ul className="social-links list-inline">
                                  <FeatherIcon icon="facebook" className="list-inline-item" />
                                  <FeatherIcon icon="twitter" className="list-inline-item" />
                                  <FeatherIcon icon="instagram" className="list-inline-item" />
                              </ul>
                              <Link to="/profil-visiteur"><button type="button" 
                              className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                              <div className="mt-4">
                                  <div className="row">
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                          
                                              <p className="mb-0 text-muted">Livrables</p>
                                              <p>121</p>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Pointage</p>
                                              <p>Absent</p>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Avis du Coach</p>
                                              <p> il vient souvent en retard</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/* <!-- end col --> */}
                  <div className="col-lg-4">
                      <div className="text-center card-box">
                          <div className="member-card pt-2 pb-2">
                              <div className="thumb-lg member-thumb mx-auto">
                              <img src={Coach} 
                              className="rounded-circle img-thumbnail" alt="profile-image" /></div>
                              <div className="">
                                  <h5>Kalika</h5>
                                  <p className="text-muted">Webdesigner</p>
                              </div>
                              <ul className="social-links list-inline">
                                  <FeatherIcon icon="facebook" className="list-inline-item" />
                                  <FeatherIcon icon="twitter" className="list-inline-item" />
                                  <FeatherIcon icon="instagram" className="list-inline-item" />
                              </ul>
                              <Link to="/profil-visiteur"><button type="button" 
                              className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                              <div className="mt-4">
                                  <div className="row">
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                          
                                              <p className="mb-0 text-muted">Livrables</p>
                                              <p>121</p>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Pointage</p>
                                              <p>Absent</p>
                                          </div>
                                      </div>
                                      <div className="col-lg-4 col-md-4">
                                          <div className="mt-3">
                                              
                                              <p className="mb-0 text-muted">Avis du Coach</p>
                                              <p> il vient souvent en retard</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  {/* <!-- end col --> */}
              </div>
              {/* <!-- end row --> */}

          </div>
          

        </div>
      </div>
    </div>
  </div>;
};

export default ProfilBakelisteRefact;
