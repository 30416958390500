import React, {Component} from 'react';
import './syllabusDetailsAdmin.css';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import SyllabusDetails from '../syllabusDetails/index';
import axios from 'axios';
import API from "../../variablesGlobales";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";


export default class syllabusDetailsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syllabus_id: this.props.id,
      api: API.API,
      userID: window.sessionStorage.getItem("user_id"),
      syllabusDatas: [],
      syllabusInfos: [],
      syllabusID: 0,
      detail_syllabus_id: 0,
      isPageLoading: false,
      taches: [],
      details: [],
      nbrtache: 0,
      // loading: false,
    };
  }

  componentDidMount = () => {
    this.onGetSyllabusDetails();
  };
  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  onGetSyllabusDetails = () => {
    //const _this = this;
    this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "all-details-syllabus/" + this.state.syllabus_id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            isPageLoading: false,
            syllabusDatas: response.data.data,
            nombreTaches: response.data.data.details.length,
            nombre: response.data.data.assignations.length,
          });
        }
        //_this.hideLoader();
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // _this.hideLoader();
      });
  };

  render() {
    return  <div className="p-b-200">
              <div className="row">
       
                <div className="col-lg-9 mb-md-0 mb-5">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                    <li className="breadcrumb-item"><Link to="/dashboard-admin" className="lien_nav_current"><FeatherIcon className="icon-breadcrumb-custom" icon="layout"/>Dashboard</Link></li>
                    <li className="breadcrumb-item"><Link to="/parametres-admin" className="lien_nav_current"><FeatherIcon className="icon-breadcrumb-custom" icon="settings"/>Paramètre</Link></li>
                    <li className="breadcrumb-item active" aria-current="page" className="lien_nav"><FeatherIcon className="icon-side-breadcrumb-custom" icon="users"/>Détail syllabus</li>
                  </ol>
                </nav>
                  <SyllabusDetails 
                  name={this.state.syllabusDatas.syllabus_name}
                  niveau={this.state.syllabusDatas.syllabus_level}
                  description={this.state.syllabusDatas.syllabus_description}
                  id={this.state.syllabus_id}
                  nbreTaches={this.state.nombreTaches}
                  Taches={this.state.syllabusDatas.taches}
                  nbreBakeliste={this.state.nombre}/>
                </div>
                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                  <div className="row">
                    {/* <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <BakelisteGroupItemForCoach />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                      <ProgramReunionCoach />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <SavedReunionListItem />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>;
  }
}