import React, { useEffect, useReducer } from "react";
import "./ajoutCoach.css";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";

import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { history } from "../history";
import { useDispatch } from "react-redux";
import { addCoachAsync } from "../../redux/slices/coachsSlices/coachsSlice";
toast.configure();
const AjoutCoachRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ajoutCoachInProgresse: false,
      domaines: [],
      api: API.API,
      fields: {},
    }
  );

  const dispatch = useDispatch();
  useEffect(() => {
    getAllDomaines();
  }, []);
  const addCoachHandleChange = (e) => {
    let fields = state.fields;
    fields[e.target.name] = e.target.value;
    //console.log("handle change", e.target.value)
    setState({
      fields,
    });
    //console.log("handle change", state.fields);
  };
  const getAllDomaines = () => {
    axios
      .get(state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        setState({
          domaines: domains.data,
        });
        // console.log(state.domaines)
      })
      .catch((error) => {
        //console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };

  const ajoutCoach = (e) => {
    e.preventDefault();
    // console.log("hello Debut")

    setState({
      ajoutCoachInProgresse: true,
    });
    let fields = {};
    fields["first_name"] = "";
    fields["last_name"] = "";
    fields["email"] = "";
    fields["phone"] = "";
    fields["coach_domaine_id"] = "";
    fields["status"] = "coach";
    setState({ fields: fields });
    const dataCoach = state.fields;
    // console.log(dataCoach);2
    dispatch(addCoachAsync(dataCoach));

    setTimeout(() => {
      setState({
        ajoutCoachInProgresse: false,
        // AjoutCoachInfoSuccess: true,
      });
    }, 1500);
    // axios.post(state.api + "add-coach", dataCoach).then((res) => {
    //   // console.log(res);
    //   if (res.status === 201 && res.statusText === "Created") {
    //     setState({
    //       ajoutCoachInProgresse: false,
    //       // AjoutCoachInfoSuccess: true,
    //     });
    //     toast.success(
    //       "Coach ajouté avec success",
    //       { position: toast.POSITION.BOTTOM_LEFT },
    //       { autoClose: 500 }
    //     );
    //     history.push("/liste-coachs");
    //   } else {
    //     setState({
    //       ajoutCoachInProgresse: false,
    //       // AjoutCoachInfoSuccess:true
    //     });
    //     toast.error(
    //       "Erreur sur l'ajout de coach!!!",
    //       { position: toast.POSITION.BOTTOM_LEFT },
    //       { autoClose: 1000 }
    //     );
    //   }
    // });
  };
  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-xl-2 col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/liste-coachs" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="users"
                    />
                    Coachs
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon className="icon-breadcrumb-custom" icon="user" />
                  Ajout Coach
                </li>
              </ol>
            </nav>

            <form>
              <div className="row edit-coach-container">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Prénom : </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={addCoachHandleChange}
                      name="first_name"
                    />
                  </div>
                </div>
                {state.fn_empty && (
                  <div className="errorMsg">{state.errors.first_name}</div>
                )}
                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Nom : </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={addCoachHandleChange}
                      name="last_name"
                      required
                    />
                  </div>
                </div>
                {state.ln_empty && (
                  <div className="errorMsg">{state.errors.last_name}</div>
                )}
                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Email : </label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={addCoachHandleChange}
                      name="email"
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label>Téléphone : </label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={addCoachHandleChange}
                      name="phone"
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Domaine :</label>
                    <select
                      className="form-control"
                      id="inputbakeli_domaine_id"
                      name="coach_domaine_id"
                      onChange={addCoachHandleChange}
                      required
                    >
                      <option> -- Choisir un domaine -- </option>
                      {state.domaines.map((x, y) => (
                        <option value={x.id} key={y}>
                          {x.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* <button
    type="button"
    className="btn-theme-danger"
  >
    <i class="fas fa-times-circle"></i> &nbsp; Quitter
  </button> */}

              {!state.ajoutCoachInProgresse && (
                <button
                  type="button"
                  className="col-md-4 offset-md-6 btn-theme"
                  onClick={(e) => ajoutCoach(e)}
                >
                  Ajouter
                </button>
              )}
              {state.ajoutCoachInProgresse && (
                <button type="button" className=" col-md-4 btn-theme-orange">
                  Ajout en cours &nbsp;
                  <i className="fas fa-spinner fa-spin"></i>
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjoutCoachRefact;
