import React, {Component} from 'react';
import DetailsCoach from '../detailsCoach/index';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import './detailsCoachView.css';
import API from "../../variablesGlobales";
import qs from "qs";
import axios from "axios";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as profilVisiteurViewActions from "../../store/profilVisiteurView/actions";
export default class detailsCoachView extends Component {
    constructor(props) {
      super(props); 
      let user_id = this.props.location?.state?.id;
      let coach_data = this.props.location?.state?.data;

      if (!user_id && this.props.location.search) {
        const obj = qs.parse(this.props.location.search.replace("?", ""));
        user_id = parseInt(obj.id);
      } 
      
      this.state = {
        user_id: user_id, 
        api: API.API, 
        userID: window.sessionStorage.getItem("user_id"),
        coach: coach_data,
      };
      // console.log(this.state.coach, "coach")
    }
// componentDidMount = () =>{
//   this.getDetailsBakeliste();
// }
 
// getDetailsBakeliste = () => {
//     axios
//       .get(this.state.api + "bakeliste-by-id/" + this.state.user_id)
//       .then((response) => {
//         this.setState({
//           isPageLoading: false,
//         });
//         if (response.data.success) {
//           this.setState({
//            user: response.data.data
//           });
//           // console.log(this.state.user, "details")
//         }
//       })
//       .catch((error) => {
//         this.setState({
//           isPageLoading: false,
//         });
//         if (process.env.NODE_ENV !== "production") {
//           return;
//         }
//       });
//   };
    render() {
      const data = this.state.coach
      return (
          <div className="component-profil-visiteur-view body-theme">
              <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <DetailsCoach 
                      name={data.coachFullname}
                      email={data.email}
                      phone={data.phone}
                      address={data.address}
                      nbr_bakeliste={data.nbreBakeliste}
                      motivation={data.objectifs}
                      formation_suivie={data.formation_suivie}
                      ecole={data.ecole}
                      domaine={data.domaine}
                      />
                    </div>
                  </div>
                </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ profilVisiteurView }) => ({ ...profilVisiteurView }),
//     dispatch => bindActionCreators({ ...profilVisiteurViewActions }, dispatch)
//   )( profilVisiteurView );