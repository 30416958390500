import React, { Component } from "react";
import "./event.css";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import EventSkeleton from './eventSkeleton'; 
import { Modal } from "rsuite";
import frenchShortFormatDate from "../frenchFormatDate";

import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Jeemacoder from "../../img/jeemacoder.png";
import ImageJeemaCoder from "../../img/imageJeemaCoder.png";
import Kakutana from "../../img/kakutana.png";
import Journee from "../../img/journee.png";
import Formation from "../../img/formation.png";
// import data from '../dataTesting.json';
import IconPartage from "../../img/iconPartage.png";
import Calendrier from "../../img/calendrier.png";
import Carbon_event from "../../img/carbon_event.png";
import More_vert from "../../img/more_vert.png";
import Transfer from "../../img/transfer.png";
import Personne from "../../img/personne.png";
import Messenger from "../../img/messenger.png";
import Flag from "../../img/flag.png";
import Journal from "../../img/journal.png";
import axios from "axios";
import API from "../../variablesGlobales";
import $ from "jquery";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as eventActions from "../../store/event/actions";

export default class event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      comings: [],
      recents: [],
      show: false,
      isLoaded: true,
      formatDate: frenchShortFormatDate.frenchShortFormatDate,

    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  componentDidMount() {
    this.getComingRencontres();
    this.getRecentRencontres();
  }

  getComingRencontres = () => {
    axios
      .get(this.state.api + "get-coming-rencontres")
      .then((res) => {
        // console.log(res.data);
        this.setState({
          comings: res.data.data,
        });
        
        this.state.comings.map((coming, index) => {
          this.setState({
            loading: true,
          });
        });
        this.setState({ isLoaded: false });
        $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getRecentRencontres = () => {
    axios
      .get(this.state.api + "get-recent-rencontres")
      .then((res) => {
        // console.log(res.data);
        this.setState({
          recents: res.data.data,
        });
        this.state.recents.map((recent, index) => {
          this.setState({
            loading: true,
          });
        });
        this.setState({ isLoaded: false });
        $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  showEventParticipants = (participants, e) => {
    // console.log(participants, e);
    e.preventDefault();
    return (
      <Link
        to={{
          pathname: "/liste-des-participants-evenements",
          state: { participants: participants }, // your data array of objects
        }}
      ></Link>
    );
  };
  render() {
    const comings = this.state;
    const recents = this.state;

    return (
      <div className="component-event">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <Link to="/dashboard-admin" className="lien_nav">
                      Dashboard{" "}
                    </Link>
                  </li>
                  <li
                    className="lien_nav_current breadcrumb-item"
                    aria-current="page"
                  >
                    evenements
                  </li>
                </ol>
              </nav>

              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 pt-4 pl-4 ml-auto">
                  <Link to="/create-event" className="link">
                    <div className="creation-event-btn">
                      <div className="dep-event">
                        <span className="event-creer ml-3">
                          Creer un evenement
                          <img
                            src={Carbon_event}
                            className="color-icon-img ml-4"
                            alt="..."
                          />
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>

             
              </div>

              <br />
              {this.state.isLoaded && <EventSkeleton />}
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <h6 className="h-theme ff-inter fw-600 px-3 mb-3">
                    Evenements a venir
                  </h6>
                </div>
              </div> 
           

              <div className="row m-b-30">
                {this.state.comings.map((coming, index) => (
                  <div className="col-md-9 px-xl-3 px-lg-2 m-b-10 pl-md-0 pr-md-3">
                    <div className="border-photo-jeemacoder" id="">
                      <div className="card ml-3 mr-3">
                        {/* <img src={ImageJeemaCoder} class="card-img-top" alt="..." /> */}
                        <div class="card-body">
                          <div className="row">
                            <div className="col-sm-10 col-lg-10 col-xl-10 col-md-10">
                              Du {""}
                              <span className="like-txt">
                                {this.state.formatDate(coming.rencontre_start_date)}
                              </span>{" "}
                              au{" "}
                              <span className="like-txt">
                                {" "}
                                {this.state.formatDate(coming.rencontre_end_date)}
                              </span>
                              <p className="fot-txt-rendre">
                                {coming.rencontre_name}
                              </p>
                              <br />
                              <span className="like-txt">
                                {coming.rencontre_description}
                              </span>
                              <p className="like-txt mt-2">
                              
                              </p>
                            </div>
                            <div className="col-sm-2 col-lg-2 col-xl-2 col-md-2">
                              <div class="dropdown">
                                <img
                                  src={More_vert}
                                  className="btn dropdown-toggle ml-5"
                                  data-toggle="dropdown"
                                  alt="..."
                                />
                            
                                <div
                                  className="dropdown-menu dropdown-menu-right change-position"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <a className="dropdown-item mb-3" href="#">
                                    Nombre d'inscrit
                                  </a>
                                  <a className="dropdown-item mb-3" href="#">
                                    Nombre de participants
                                  </a>
                                  <a className="dropdown-item mb-3" href="#">
                                    Les intervenants
                                  </a>
                                  <a className="dropdown-item mb-3" href="#">
                                    Representants au Sénégal
                                  </a>
                                  <a className="dropdown-item mb-3" href="#">
                                    A l'international
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-2 footer-information-card">
                          <div className="col-lg-4 col-xl-4 col-md-4">
                            <Link
                              to={{
                                pathname: "/liste-des-participants-evenements",
                                state: { participants: coming.participant }, // your data array of objects
                              }}
                            >
                              <p className="d-flex">
                                <span className="form-bg-rond-part">
                                  <FeatherIcon
                                    icon="users"
                                    className="feather feather-globe ml-1"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-1 pt-2 for-t-cont">
                                  {coming.nbreParticipant} participants{" "}
                                </span>
                              </p>
                            </Link>
                          </div>

                          <div className="col-lg-4 col-xl-4 col-md-4 ">
                            <Link
                              to={{
                                pathname: "/edit-event",
                                state: {
                                  id: coming.id,
                                },
                              }}
                            >
                              <p className="d-flex">
                                <span className="form-bg-rond-reseau">
                                  <FeatherIcon
                                    icon="edit"
                                    className="feather feather-globe ml-1"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3 pt-2 for-t-cont">
                                  Modifier
                                </span>
                              </p>
                            </Link>
                          </div>

                          <div className="col-lg-4 col-xl-4 col-md-4 ">
                            <Link
                              to={{
                                pathname: "/edit-event",
                                state: {
                                  id: coming.id,
                                },
                              }}
                            >
                              <p className="d-flex">
                                <span className="form-bg-rond-archive">
                                  <FeatherIcon
                                    icon="delete"
                                    className="feather feather-globe ml-1"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-3 pt-2 for-t-cont">
                                  Archiver
                                </span>
                              </p>
                            </Link>
                          </div>
                   
                        </div>
                      </div>
                    </div>

                    
                    <div className="row ml-2 pt-4 mr-3">
                      <div className="col-sm-9 col-lg-9 col-xl-9 col-md-9">
                        <Link
                          to={{
                            pathname: "/a-propos-des-evenements",
                            state: {
                              id: coming.id,
                              nbreParticipants: coming.nbreParticipant,
                            },
                          }}
                          className="link"
                        >
                          <div className="design-but-propEvent">
                            <p className="pt-2">
                              <span className="txt-propos-event">
                                {" "}
                                A propos de l’évènement{" "}
                              </span>
                            </p>
                          </div>
                        </Link>
                      </div>

                    
                    </div>
                  </div>
                ))}
               
              </div>

              <div className="mr-3">
                <span className="autres-events pl-5">
                  {" "}
                  Autres Evenements de Bakeli{" "}
                </span>
                <div className="row d-flex m-b-30 m-t-20">

                {this.state.recents.map((coming) => (
                    // <div className="col-xl-4  col-lg-4  col-md-4 col-sm-4 m-b-15">
                    //   <img src={Journee} alt="..." />

                    //   <span className="comportement-txt-event d-flex">
                    //     {coming.rencontre_name}
                    //   </span>
                    // </div>
                    <div className="col-xl-6  col-lg-6  col-md-6 col-sm-6 m-b-15 border-photo-jeemacoder" id="">
                      <div className="card">
                        {/* <img src={ImageJeemaCoder} class="card-img-top" alt="..." /> */}
                        <div class="card-body">
                          <div className="row">
                            <div className="col-sm-10 col-lg-10 col-xl-10 col-md-10">
                              Du {""}
                              <span className="like-txt">
                                {this.state.formatDate(coming.rencontre_start_date)}
                              </span>{" "}
                              au{" "}
                              <span className="like-txt">
                                {" "}
                                {this.state.formatDate(coming.rencontre_end_date)}
                              </span>
                              <p className="fot-txt-rendre">
                                {coming.rencontre_name}
                              </p>
                              <br />
                              <span className="like-txt">
                                {coming.rencontre_description}
                              </span>
                              <p className="like-txt mt-2">
                              
                              </p>
                            </div>
                          
                          </div>
                        </div>
{/*                         
                        <div className="mb-2 footer-information-card">

                    <div className="row justify-content-end position-action-container-syllabus-item-back">
                      <div className="col-xl-4 col-lg-5 col-md-5  pl-md-0 col-sm-6">
                       
                      <Link
                              to={{
                                pathname: "/liste-des-participants-evenements",
                                state: { participants: coming.participant }, // your data array of objects
                              }}
                            >
                              <p className="d-flex">

                                <span className="form-bg-rond-part">
                                  <FeatherIcon
                                    icon="users"
                                    className="feather feather-globe ml-1"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-1 pt-2 for-t-cont">
                                  {coming.nbreParticipant} participants{" "}
                                </span>
                          </p>
                        </Link>
                      </div>
                    
                    </div>
                  </div> */}



                        <div className="row mb-2 footer-information-card justify-content-end ">
                          <div className="col-lg-4 col-xl-4 col-md-4 pl-md-0">
                            <Link
                              to={{
                                pathname: "/liste-des-participants-evenements",
                                state: { participants: coming.participant }, // your data array of objects
                              }}
                            >
                              <p className="d-flex">
                                <span className="form-bg-rond-participant">
                                  <FeatherIcon
                                    icon="users"
                                    className="feather feather-globe ml-1"
                                    aria-hidden="true"
                                  />
                                </span>
                                <span className="ml-1 pt-2 for-t-cont">
                                  {coming.nbreParticipant} participants{" "}
                                </span>
                              </p>
                            </Link>
                          </div>

                        </div>
                      
                      
                      </div>
                    </div>
                ))}
                  </div>

              </div>
          
            </div>

            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ event }) => ({ ...event }),
//     dispatch => bindActionCreators({ ...eventActions }, dispatch)
//   )( event );
