import React, {Component} from 'react';
import './articlesAdmin.css';
import { BrowserRouter as Router, Route } from "react-router-dom";

import Ellipse from '../../img/Ellipse.png';
import sendeal from '../../img/sendeal.png';
import Group from '../../img/Group.png';
import logokaloma from '../../img/logokaloma.png';
import smarthome from '../../img/smarthome.png';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { Link } from 'react-router-dom';
import CommentIcon from '@material-ui/icons/Comment';
import SendIcon from '@material-ui/icons/Send';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { MdHome, MdSettings } from "react-icons/md";
// import { Router } from "@reach/router";

import Home from "../dashboardAdmin";
import Dashboard from "../controleAdmin";


const Separator = ({ children, ...props }) => (
  <span style={{ color: "blue", size: "10px" }} {...props}>
    {children}
  </span>
);

const options = {
  icons: {
    Home: MdHome,
    Dashboard: MdSettings,
    
  },
  items: [
    { to: "/", label: "Home" },
    { to: "/dashboard-admin", label: "Dashboard" }
    
  ]
};
export default class articlesAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }
    render() {
      return  (<div className="p-b-200">
      <div className="container page-article">
        <div className="row">
        <div className="col-lg-9">
            <div className="container">
            <Breadcrumb className="breadcrumb-custom-icons" separator={<Separator>/</Separator>}>
          {options.items.map(({ to, label }) => {
        const Icon = options.icons[label];
        return (
          <div key={to} className="some-custom-classname">
            {Icon && <Icon />}
            <Link to={to}>{label}</Link>
          </div>
        );
      })}
          </Breadcrumb>
          <Router>
      <Home path="/" />
      <Dashboard path="/dashboard-admin" />
      
    </Router>
      
            </div>
            <div className="container-fluid px-5">
              <div className="row">
                <h3 className="article p-0 m-0">Articles</h3>
              </div>
            </div>
            <div>
              <div className="card my-1 mt-2 content">
                <div className="card-body">
                  <div className="bordure">
                    <h5 className="card-title"><img src={Ellipse} alt="Logo" className="imgg" /> <span className="nom mt-4">Absa Fall</span> <span className="heure">8h</span></h5>
                    <div className="px-4">
                      <a id="lien" href="/" className="py-4">https://www.youtube.com/watch?v=Q6wrVkRqgVkdf</a>
                      <p className="card-text texte-articles py-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla integer viverra aliquet enim enim sit auctor est. Vel tincidunt etiam aliquam et dui commodo neque, vitae nulla. Sociis convallis nam mauris sit ornare tempus nisi, velit. Pretium quis urna, pulvinar cursus sit at.
                      Nunc et euismod egestas fames eget eget nibh porttitor quis. Non ac adipiscing sit velit ut egestas vitae rhoncus tortor. Sit pharetra arcu suspendisse nullam malesuada massa nisl. Vestibulum, amet, quis massa hendrerit purus cursus pulvinar. Odio commodo, ipsum massa sapien egestas non. Tempor, semper morbi pulvinar morbi purus. Sit nulla cursus vitae in dignissim id sed magna rutrum. Quis sed mauris lorem duis a aliquam.
                      Justo, sapien, volutpat molestie nisi, pharetra risus, dolor mattis nulla. Facilisis pellentesque sit imperdiet.</p>
                    </div>
                    <img src={sendeal} alt="sendeal" className="cv my-4 mx-2"></img>
                    <img src={sendeal} alt="sendeal" className="cv my-4 mx-2"></img>
                    </div>
                    <div className="row">
                      <div className="col-xl-8 col-lg-8">
                        <div className="color-texte pl-4">
                          <span>52</span>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2">
                        <div className="color-texte">
                          <span>13 commentaires</span>
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2">
                        <div className="color-texte">
                          <span>2 partages</span>
                        </div>
                      </div>
                    </div>
                    <div className="row my-3 d-flex border-ligne">
                      <div className="col-xl-4 col-lg-4 col-md-4">
                        <div className="row">
                          <a className="nav-link" href="/"><i className="fas fa-thumbs-up color-icon-share"></i></a>
                          <a className="nav-link" href="/"><i className="fas fa-thumbs-down color-icon-share"></i></a>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4">
                        <a className="nav-link" href="/"><i className="fas fa-comment-alt-lines color-icon-share"></i></a>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 justify-content-end">
                        <div className="dropdown d-flex justify-content-end" id="dropdownn">
                          <button className="btn d-flex color-icon-share" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-share-alt"></i>
                          </button>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="/"><MailOutlineIcon />Envoyer un message privé </a>
                            <a className="dropdown-item" href="/">Partager sur le fil d’actualité</a>
                            <a className="dropdown-item" href="/"><MailOutlineIcon />Partager sur ses réseaux </a>
                            <a className="dropdown-item" href="/"><InsertLinkIcon/>Copier le lien </a>
                            <a className="dropdown-item" href="/">Enregistrer l’article</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="texte-comment pb-3 px-2">
                        <span>Voir les commentaires précendents</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex">
                        <div className="image-comment d-flex px-2">
                          <img src={Ellipse} alt="Logo" className="img-comment" />
                        </div>
                        <div className="blok-comment d-flex pb-3">
                          <span>Sokhna Ngoné Diop</span>
                          <div>
                            <span>Excellent!!</span>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div className="row d-flex pl-5 py-2">
                        <div className="col-xl-1 col-lg-1 col-md-1">
                          <span className="texte-gris">J’aime</span>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1 px-0">
                          <span className="texte-gris">Répondre</span>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1">
                          <span className="texte-gris">2h</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 d-flex">
                          <div class="input-group py-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                <CommentIcon className="color-icon-share mx-3" />
                                <input type="text" class="form-control input-border texte-gris" placeholder="Ajouter un commentaire" aria-label="Username" aria-describedby="basic-addon1" />
                                <SendIcon className="color-icon-share" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid pb-3">
                    <div className="row">
                      <div className="card my-1 mt-2 content">
                        <div className="card-body">
                          <div className="bordure">
                            <h5 className="card-title"><img src={Ellipse} alt="Logo" className="imgg" /> <span className="nom mt-4">Absa Fall</span> <span className="heure">8h</span></h5>
                            <div className="px-4">
                              <a id="lien" href="/" className="py-4">https://www.youtube.com/watch?v=Q6wrVkRqgVkdf</a>
                              <p className="card-text texte-articles py-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla integer viverra aliquet enim enim sit auctor est. Vel tincidunt etiam aliquam et dui commodo neque, vitae nulla. Sociis convallis nam mauris sit ornare tempus nisi, velit. Pretium quis urna, pulvinar cursus sit at.
                              Nunc et euismod egestas fames eget eget nibh porttitor quis. Non ac adipiscing sit velit ut egestas vitae rhoncus tortor. Sit pharetra arcu suspendisse nullam malesuada massa nisl. Vestibulum, amet, quis massa hendrerit purus cursus pulvinar. Odio commodo, ipsum massa sapien egestas non. Tempor, semper morbi pulvinar morbi purus. Sit nulla cursus vitae in dignissim id sed magna rutrum. Quis sed mauris lorem duis a aliquam.
                              Justo, sapien, volutpat molestie nisi, pharetra risus, dolor mattis nulla. Facilisis pellentesque sit imperdiet.</p>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <img src={Group} alt="Group" className="cv1 my-4 mx-2"></img>
                              </div>
                              <div className="col-4">
                                <img src={logokaloma} alt="logokaloma" className="cv2 my-4 mx-2"></img>
                              </div>
                              <div className="col-4">
                                <img src={smarthome} alt="smarthome" className="cv3 my-4 mx-2"></img>
                              </div>
                           </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-8 col-lg-8">
                              <div className="color-texte pl-4">
                                <span>52</span>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2">
                              <div className="color-texte">
                                <span>13 commentaires</span>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2">
                              <div className="color-texte">
                                <span>2 partages</span>
                              </div>
                            </div>
                          </div>
                          <div className="row my-3 d-flex border-ligne">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                              <div className="row">
                                <a className="nav-link" href="/"><i className="fas fa-thumbs-up color-icon-share"></i></a>
                                <a className="nav-link" href="/"><i className="fas fa-thumbs-down color-icon-share"></i></a>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                              <a className="nav-link" href="/"><i className="fas fa-comment-alt-lines color-icon-share"></i></a>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 justify-content-end">
                              <div className="dropdown d-flex justify-content-end" id="dropdownn">
                                <button className="btn d-flex color-icon-share" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="fas fa-share-alt"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a className="dropdown-item" href="/"><MailOutlineIcon />Envoyer un message privé </a>
                                  <a className="dropdown-item" href="/">Partager sur le fil d’actualité</a>
                                  <a className="dropdown-item" href="/"><MailOutlineIcon />Partager sur ses réseaux </a>
                                  <a className="dropdown-item" href="/"><InsertLinkIcon/>Copier le lien </a>
                                  <a className="dropdown-item" href="/">Enregistrer l’article</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="texte-comment pb-3 px-2">
                              <span>Voir les commentaires précendents</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="d-flex">
                              <div className="image-comment d-flex px-2">
                                <img src={Ellipse} alt="Logo" className="img-comment" />
                              </div>
                              <div className="blok-comment d-flex pb-3">
                                <span>Sokhna Ngoné Diop</span>
                                <div>
                                  <span>Excellent!!</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex pl-5 py-2">
                            <div className="col-xl-1 col-lg-1 col-md-1">
                              <span className="texte-gris">J’aime</span>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-1 px-0">
                              <span className="texte-gris">Répondre</span>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-1">
                              <span className="texte-gris">2h</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <div class="input-group py-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                    <CommentIcon className="color-icon-share mx-3" />
                                    <input type="text" class="form-control input-border texte-gris" placeholder="Ajouter un commentaire" aria-label="Username" aria-describedby="basic-addon1" />
                                    <SendIcon className="color-icon-share" />
                                  </span>
                               </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid pb-3">
                    <div className="row">
                      <div className="card my-1 mt-2 content">
                        <div className="card-body">
                          <div className="bordure">
                            <h5 className="card-title"><img src={Ellipse} alt="Logo" className="imgg" /> <span className="nom mt-4">Absa Fall</span> <span className="heure">8h</span></h5>
                            <div className="px-4">
                              <a id="lien" href="/" className="py-4">https://www.youtube.com/watch?v=Q6wrVkRqgVkdf</a>
                              <p className="card-text texte-articles py-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla integer viverra aliquet enim enim sit auctor est. Vel tincidunt etiam aliquam et dui commodo neque, vitae nulla. Sociis convallis nam mauris sit ornare tempus nisi, velit. Pretium quis urna, pulvinar cursus sit at.
                              Nunc et euismod egestas fames eget eget nibh porttitor quis. Non ac adipiscing sit velit ut egestas vitae rhoncus tortor. Sit pharetra arcu suspendisse nullam malesuada massa nisl. Vestibulum, amet, quis massa hendrerit purus cursus pulvinar. Odio commodo, ipsum massa sapien egestas non. Tempor, semper morbi pulvinar morbi purus. Sit nulla cursus vitae in dignissim id sed magna rutrum. Quis sed mauris lorem duis a aliquam.
                              Justo, sapien, volutpat molestie nisi, pharetra risus, dolor mattis nulla. Facilisis pellentesque sit imperdiet.</p>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <img src={Group} alt="Group" className="cv1 my-4 mx-2"></img>
                              </div>
                              <div className="col-4">
                                <img src={logokaloma} alt="logokaloma" className="cv2 my-4 mx-2"></img>
                              </div>
                              <div className="col-4">
                                <img src={smarthome} alt="smarthome" className="cv3 my-4 mx-2"></img>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-8 col-lg-8">
                              <div className="color-texte pl-4">
                                <span>52</span>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2">
                              <div className="color-texte">
                                <span>13 commentaires</span>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2">
                              <div className="color-texte">
                                <span>2 partages</span>
                              </div>
                            </div>
                          </div>
                          <div className="row my-3 d-flex border-ligne">
                            <div className="col-xl-4 col-lg-4 col-md-4">
                              <div className="row">
                                <a className="nav-link" href="/"><i className="fas fa-thumbs-up color-icon-share"></i></a>
                                <a className="nav-link" href="/"><i className="fas fa-thumbs-down color-icon-share"></i></a>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4">
                              <a className="nav-link" href="/"><i className="fas fa-comment-alt-lines color-icon-share"></i></a>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 justify-content-end">
                              <div className="dropdown d-flex justify-content-end" id="dropdownn">
                                <button className="btn d-flex color-icon-share" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="fas fa-share-alt"></i>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a className="dropdown-item" href="/"><MailOutlineIcon />Envoyer un message privé </a>
                                  <a className="dropdown-item" href="/">Partager sur le fil d’actualité</a>
                                  <a className="dropdown-item" href="/"><MailOutlineIcon />Partager sur ses réseaux </a>
                                  <a className="dropdown-item" href="/"><InsertLinkIcon/>Copier le lien </a>
                                  <a className="dropdown-item" href="/">Enregistrer l’article</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="texte-comment pb-3 px-2">
                              <span>Voir les commentaires précendents</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="d-flex">
                              <div className="image-comment d-flex px-2">
                                <img src={Ellipse} alt="Logo" className="img-comment" />
                              </div>
                              <div className="blok-comment d-flex pb-3">
                                <span>Sokhna Ngoné Diop</span>
                                <div>
                                  <span>Excellent!!</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex pl-5 py-2">
                            <div className="col-xl-1 col-lg-1 col-md-1">
                              <span className="texte-gris">J’aime</span>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-1 px-0">
                              <span className="texte-gris">Répondre</span>
                            </div>
                            <div className="col-xl-1 col-lg-1 col-md-1">
                              <span className="texte-gris">2h</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <div class="input-group py-3">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon1">
                                    <CommentIcon className="color-icon-share mx-3" />
                                    <input type="text" class="form-control input-border texte-gris" placeholder="Ajouter un commentaire" aria-label="Username" aria-describedby="basic-addon1" />
                                    <SendIcon className="color-icon-share" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
          
                



     );
    }
  }
