import React, {Component} from 'react';
import './tableMyCoachs.css';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import API from "../../variablesGlobales";
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';
import SkeletonUsers from './skeletonUsers';
import $ from "jquery";

// class Status extends React.Component {
//   render() {
//     return (
//       <div className="dis-flex justify-content-center itm-center flex-col">
//         <p className="badge todo-badge">To do</p>

//         {/* !!!!important!!!! */}
//         {/* <p className="badge doing-badge">Doing</p> */}
//         {/* <p className="badge done-badge">Done</p> */}
//       </div>
//     );
//   }
// }

// function changeStatus(cell, row) {
//   return (
//     <Status active={ cell } />
//   );
// }

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="user" size={18} />
        </button>
        
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="send" size={18} />
        </button>
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}

export default class tableMyCoachs extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          loading: true,
          first_name: "",
          last_name:"",
          email:"",
          status: "",
          users: [],
          admin: '',
          coach: '',
          isLoaded: true,
        };
    }
    componentDidMount() {
      this.getListAllUserAdmin();
    }

    getListAllUserAdmin = () => {    
      axios
        .get(this.state.api + "all-users")
        .then((res) => {
          if (res.data.success === true) {
        //  console.log('users', res.data.data)
            this.setState({
              users: res.data.data,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              isEmptyData: true,
            });
          }
          this.setState({ isLoaded: false });
          $("#start-hidden").removeClass("start-hidden");
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error.message);
        });
    };

    Utilisateurs = (Column, row) => {
          return(
            <div className="la-taille-div">
              {row.status === "admin" ?
                (
                <span className="admin-color">Admin</span>
                )
                    :
                (
                <span className="coach-color">Coach</span>
                )
              }
            </div>
          )
    }
     nameFormatter(cell, row) {
      return `${row.first_name} ${row.last_name}`
  }
  
    CustomExportCsvBtn = (onClick) =>{
      return(
        <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
      );
    }
    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      };
      return  <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
            {this.state.isLoaded && <SkeletonUsers />}
               
                <BootstrapTable 
                  pagination
                  options={options}
                  exportCSV
                  csvFileName="utilisateurs.csv"
                  data={this.state.users}
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                    
                  <TableHeaderColumn 
                    
                    isKey filter={{type:"TextFilter", delay: 1000}} 
                    dataField='first_name'
                    dataFormat={this.nameFormatter} 
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Prénom & Nom</span> 
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                    dataField='email' width="200" 
                    filter={{type:"TextFilter", delay: 1000}}
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Email</span>
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                      dataField='status' width="200" 
                      dataFormat={this.Utilisateurs}
                      filter={{type:"TextFilter", delay: 1000}}
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Statut</span>
                     </TableHeaderColumn>
                  <TableHeaderColumn dataField='action' 
                  dataFormat={uploadFormatter} 
                  
                  thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                  tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs"> Actions</span>
                    </TableHeaderColumn>
                </BootstrapTable>
              </div>;
    }
  }
