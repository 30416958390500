import React, {Component} from 'react';
import './tableMyParticipants.css';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import API from "../../variablesGlobales";
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyParticipantsActions from "../../store/tableMyParticipants/actions";





class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="send" size={18} />
        </button>
        
        
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}




export default class tableMyParticipants extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          participants: [],
          domaines: [],
          fields: {},
          upFields: {},
          participantsID: 0,
          participantFullname: "",
          participantEmail: "",
          participantDomaine: "",
          participantEcole: "",
          participantPhone: "",
          participantDebutFormation: "",
          participantFinFormation: "",
          emailValue: "",
          phoneValue: "",
          boursierValue: false,
          demiBoursierValue: false,
          adresseValue: "",
          ecoleValue: "",
          stageFormationValue: false,
          isISAContractValue: false,
          lieuFormationValue: "",
          horaireValue: "",
          paiementInscriptionValue: false,
          debutFormationValue: "",
          finFormationValue: "",
          firstNameValue: "",
          lastNameValue: "",
          loading: true,
        };
    }


    
    componentDidMount() {
      this.getListAllParticipants();
    }

    getListAllParticipants = () => {
      axios
        .get(this.state.api + "participants")
        .then((res) => {
          if (res.data.success === true) {
        //  console.log('participants', res.data.data)
            this.setState({
              participants: res.data.data,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error.message);
        });
    };


    CustomExportCsvBtn = (onClick) =>{
      return(
        <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
      );
    }
    //recuperation du nom et prenom dans un meme colonnne
    nameFormatter(cell, row) {
      return `${row.first_name} ${row.last_name}`
  }

  
    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      };
      return (
            <div className="component-table-my-participants">

            {this.state.participants.length > 0 ? (

                <BootstrapTable
                  striped 
                  version='4'  
                  exportCSV

                  csvFileName="Liste participants.csv"
                  data={this.state.participants} 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm pdt_table "
                >
                  <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                    {" "}
                    ID{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='first_name'  
                    dataFormat={this.nameFormatter} 
                   width="150"
                   thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                   tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                     <span className="color-nom-prenom-utilisateurs headerStyle">Nom complet</span>
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='email'
                    width="150"
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs headerStyle">Email</span>
                  </TableHeaderColumn>
                
                  <TableHeaderColumn
                   dataField='phone'
                   width="150"
                   thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                   tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                     <span className="color-nom-prenom-utilisateurs headerStyle">Téléphone</span>
                  </TableHeaderColumn>
                  <TableHeaderColumn
                   dataField='sesssion'

                   width="150"
                   thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                   tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                     <span className="color-nom-prenom-utilisateurs headerStyle">Rencontres</span>
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    // dataFormat={this.actionformater}
                    width="150"
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs headerStyle">Actions</span>
                  </TableHeaderColumn>
                  
                </BootstrapTable>
               ) : (
                <p className="pComment">
                  Il n'y a pas encore de participants disponibles.
                </p>
              )}
              </div>
        );
    }
  }
// export default connect(
//     ({ tableMyParticipants }) => ({ ...tableMyParticipants }),
//     dispatch => bindActionCreators({ ...tableMyParticipantsActions }, dispatch)
//   )( tableMyParticipants );