import React, { Component } from "react";
import "./statisticsPosition.css";
import { Link, NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

export default class statisticsPosition extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row m-t-60 m-b-30">
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard ">
            <Link to="/bakelistes-db" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.GY1 + this.props.GY2}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Dakar
                </p>
                <div className="eclipse2">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="home"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard ">
            <Link to="/bakelistes-db" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {" "}
                  {this.props.Thies}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Thies
                </p>
                <div className="eclipse2">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="map-pin"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <Link to="/bakelistes-db" className="desactive-decoration">
              <p className="chiffre-item-statistics-dashboard nbr">15</p>
              <p className="designation-item-statistics-dashboard txt">Labé</p>
              <div className="eclipse2">
                <FeatherIcon
                  className="img-item-statistics-dashboard styleicon"
                  icon="map"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
