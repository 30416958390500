import React, { Component } from "react";
import "./controleAdmin.css";
// import { Link, NavLink } from "react-router-dom";
// import FeatherIcon from "feather-icons-react";
// import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
// import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
// import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
// import ProgramReunionCoach from "../programReunionCoach/index";
// import SavedReunionListItem from "../savedReunionListItem/index";
import TableMyTasks from "../tableMyTasks/index";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export default class controleAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">

              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 pt-3 pb-3">
                  <h6 className="h-theme ff-inter fw-600 px-3">
                    Listes de contrôles
                  </h6>
                </div>
              </div>

              {/* <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                  <a class="nav-link" href="controle-admin"><span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link ml-5" href="pointage-admin"></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link ml-5" href="controle_admin/reporting-admin"></a>
                </li>
              </ul>
            </div>
          </nav> */}

              <br />

              <div className="card-dashboard p-4 ml-3 mr-3">
                <div className="row m-b-30 m-t-20">
                  <TableMyTasks />
                  <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
