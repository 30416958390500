import axios from "axios";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { API } from "../../variablesGlobales";
import BakelisteInfosApercu from "../ajouterBakeliste/bakelisteInfosApercu/BakelisteInfosApercu";
import MultiStepForm from "../ajouterBakeliste/multiStep/MultiStepForm";

const ConvertirProspectRefac = ({ prospectId }) => {
  // initial states
  const days = ["lundi", "mardi", "mercredi", "jeudi", "vendredi"];
  const [page, setPage] = React.useState(0);
  const [domaines, setDomaines] = React.useState([]);
  const [coachs, setCoachs] = React.useState([]);
  const [apercu, setApercu] = React.useState(false);
  const [prospectInfos, setProspectInfos] = React.useState([]);

  const [bakelisteInfos, setBakelisteInfos] = React.useState({
    civility: "",
    marital_status: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    telephone: "",
    date_naissance: "",
    address: "",
    ecole: "",
    //informations sur la formation
    type_formation: "",
    is_pro: 0,
    is_etudiant: 0,
    is_etudiant_a_tester: 0,
    is_week: 0,
    is_weekend: 0,
    nbre_jours: 0,
    horaire: null,
    jour_1: "",
    jour_2: "",
    jour_3: "",
    jour_4: "",
    jour_5: "",
    jour_6: "",
    jour_fd: "",
    domaines_id: null,
    is_formation_payante: null,
    paiement_inscription: null,
    is_stagede_formation: null,
    lieu_formation: "",
    bakeliste_level: "",
    training_duration: "",
    // !autres infos
    debut_formation: "",
    fin_formation: "",
    objectifs: "",
    commentaire: "",
    demi_boursier: 0,
    is_isa_contract: 0,
    coach_id: null,
  });

  // Get initial data we need
  React.useEffect(() => {
    const getDomaines = async () => {
      try {
        const {
          data: { data },
        } = await axios(API + "domaines");
        setDomaines(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getCoachs = async () => {
      try {
        const {
          data: { data },
        } = await axios(API + "liste-des-coachs");
        setCoachs(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getProspectsDetails = async () => {
      try {
        const res = await axios.get(`${API}prospects/${prospectId}`);
        if (res.data.success) {
          setBakelisteInfos({
            ...bakelisteInfos,
            first_name: res.data?.data?.firstName,
            last_name: res.data?.data?.lastName,
            phone: res?.data?.data?.phone,
            email: res?.data?.data?.email,
            objectifs: res?.data?.data?.objectifs,
          });
          //   setProspectInfos(res?.data?.data);
        }
      } catch (error) {}
    };

    getDomaines();
    getCoachs();
    getProspectsDetails();
  }, []);

  return (
    <section
      className="mx-auto"
      style={{
        maxWidth: "1300px",
        minHeight: "70vh",
        padding: "2rem 0 0 0",
        margin: "0rem 0 10rem 0",
      }}
    >
      {/* Breadcrumb */}
      <div className="container-fluid px-0">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav">
                Dashboard{" "}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/liste-prospect" className="lien_nav">
                Prospects /{" "}
              </Link>
            </li>
            <li
              className="breadcrumb-item lien_nav_current active"
              aria-current="page"
            >
              {" "}
              conversion{" "}
            </li>
          </ol>
        </nav>
      </div>

      {/* content */}

      {!apercu ? (
        <MultiStepForm
          apercu={apercu}
          setApercu={setApercu}
          days={days}
          domaines={domaines}
          coachs={coachs}
          page={page}
          setPage={setPage}
          bakelisteInfos={bakelisteInfos}
          setBakelisteInfos={setBakelisteInfos}
        />
      ) : (
        <BakelisteInfosApercu
          route={`validate-bakeliste-self-subscribe/${prospectId}`}
          requestMethod="put"
          domaines={domaines}
          bakelisteInfos={bakelisteInfos}
          setApercu={setApercu}
          setPage={setPage}
        />
      )}
    </section>
  );
};

export default ConvertirProspectRefac;
