import React, { Component } from "react";
import "./syllabusDomaine.css";
import ImageProgrammation from "../../img/Domaine(1).png";
import { NavLink, Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ShareTo from "../shareTo/index";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import ImageMarketing from "../../img/Domaine (2).png";
import ImageGestion from "../../img/Domaine(3).png";
import ImageDesign from "../../img/Domaine (4).png";
import ImageBureautique from "../../img/Domaine(5).png";
import ImageAnglais from "../../img/Domaine(6).png";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import { Modal } from "rsuite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export default class syllabusDomaine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      size: "md",
      syllabus: {},
      api : API.API,
      doamaine: {},
      domaine_name: this.props.name,
      description: this.props.description,
      status: this.props.status,
      fields: {},
      domaineID: this.props.id,
    };
  }

  // componentDidMount = () => {
    
  //   this.onGetDomaine();
   
  // };
  editDomaineModal = () => {
    this.onGetDomaine(this.state.domaineID);
    this.setState({
      showEditModal: true,
    });  
  };

  onGetDomaine = (id) => {
    this.setState({
      isPageLoading: true,
    });
    axios
      .get(this.state.api + "domaines/" + id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        // console.log(response, "res")
        if (response.data.success) {
          this.setState({
            doamaine: response.data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };
  close = () => {
    this.setState({
      showEditModal: false,
    });
  };

  onSubmitEditDetailsDomaine = (e) => {
    e.preventDefault();
    this.setState({
      editDomaineInProgress: true,
    });
    // var data = this.state.syllabus; 
    var fd = new FormData();
    fd.append("name", this.state.domaine_name);
    fd.append("status", this.state.status);
    fd.append("description", this.state.description);

    axios
      .put(this.state.api + "domaines/" + this.state.domaineID ,fd,{
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        // console.log("start")
        // console.log("response", response)
        this.setState({
          editDomaineInProgress: true,
        });
        if (response.data.success) {
          this.setState({
            showEditModal: false,
          editDomaineInProgress: false,

          });
          this.props.getAllDomaines();
          toast.success(
            "Domaine modifiée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          this.setState({
            showEditModal: false,
          });
          toast.error(
            "erreur sur la modification",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 3000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          editSyllabusContentInProgress: false,
        });
        toast.error(
          "erreur sur la modification",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 3000 }
        );
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  render() {
    let id = this.props.syllabus_id;
    // console.log(id, "idddd")
    const nameD = this.props.name;
    return (
      <div className="container-syllabus-item">
        <div className="syllabus-item">
        
         {this.props.name === "Design" && (
            <img
              src={ImageDesign}
              alt="Avatar"
              className="image-syllabus-domaine"
              style={{ width: "100%" }}
            />
          )}{" "}
          { this.props.name === "Hackaton" | this.props.name === "Intelligence artificielle" | this.props.name === "Programmation" | this.props.name === "Développement web"| this.props.name === "Systèmes embarqués-Iot" | this.props.name === "Data science"  | this.props.name === "Développement mobile" | this.props.name === "Big Data" && (
            <img
              src={ImageProgrammation}
              alt="Avatar"
              className="image-syllabus-domaine"
              style={{ width: "100%" }}
            />
          )}{" "}
          
          {this.props.name === "Marketing Digital" | this.props.name === "Comptabilité-Gestion" | this.props.name === "Business" | this.props.name === "Business Intelligence" && (
            <img
              src={ImageMarketing}
              alt="Avatar"
              className="image-syllabus-domaine"
              style={{ width: "100%" }}
            />
          )}{" "}
          {this.props.name === "Gestion de projects" && (
            <img
              src={ImageGestion}
              alt="Avatar"
              className="image-syllabus-domaine"
            />
          )} {" "}
           {this.props.name === "Anglais" && (
            <img
              src={ImageAnglais}
              alt="Avatar"
              className="image-syllabus-domaine"
            />
          )} 
           {this.props.name === "Bureautique" && (
            <img
              src={ImageBureautique}
              className="image-syllabus-domaine"
            />
          )} {" "}
          <div className="content-syllabus-item">
            <p className="titre-content-syllabus-item">{this.props.name}</p>
          </div>
        </div>
        <div className="container-syllabus-item-back">
          {/* <NavLink className="link-details-container-syllabus-item-back" to={'/syllabus-details-bakeliste/'+this.props.syllabus_id} > */}

          {/* <Link
            className="link-details-container-syllabus-item-back"
            to={{
              pathname: "/details-syllabus",
              state: { id: id },
            }}
          > */}
            <div className="height-container-syllabus-item-back">
              <p className="titre-container-syllabus-item-back">Description</p>
              <p className="content-container-syllabus-item-back">
                {this.props.description}
              </p>
            </div>
          {/* </Link> */}

          <div className="">
            <div className="row justify-content-end position-action-container-syllabus-item-back">
              <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
            
                       
                       {/* <Link className="link-details-container-syllabus-item-back" 
                         to={{
                           pathname: "/details-syllabus",
                           state: { id: id },
                         }}>
                         <FeatherIcon className="icon-details-container-syllabus-item-back" icon="archive" />
                         Archiver
                       </Link> */}
                     </div>
                     <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
                       
                     <span className="modifier" onClick={() => this.editDomaineModal()}>
                         <FeatherIcon className="icon-details-container-syllabus-item-back" icon="edit-3" />
                         Modifier
                       </span>
                     </div>
                     <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
                       
                       {/* <Link className="link-details-container-syllabus-item-back" 
                         to={{
                          //  pathname: "/details-syllabus",
                           state: { id: id },
                         }}> */}
                         <FeatherIcon className="icon-details-container-syllabus-item-back" icon="eye" />
                         Details
                       {/* </Link> */}
              </div>

              <Modal
                className="modal-responsive-md"
                size={this.state.size}
                show={this.state.showEditModal}
                onHide={this.close}
              >
                <form onSubmit={(e) => this.onSubmitEditDetailsDomaine(e)}>
                  <Modal.Header>
                    <Modal.Title className="modal-contact-us">
                      Modifier Domaine{" "}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="max-height-modal">
                    <div className="form-group as_input_container">
                    <label>Nom du domaine</label>
                      <input
                        value={this.state.domaine_name}
                        onChange={(e) =>
                          this.setState({ domaine_name: e.target.value })
                        }
                        name="syllabus_name"
                        placeholder="Nom du syllabus"
                        className="form-control input_item"
                        id="name"
                      />
                    </div>
                    <div className="form-group as_input_container">
                      <label>Description du domaine</label>
                      <textarea
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        name="description"
                        placeholder="Nom du syllabus"
                        className="form-control input_item"
                        id="description"
                      />
                    </div>
                    <div className="form-group as_input_container">
                           <select
                            name="status"
                            className="form-control input_item"
                            id="syllabus_level"
                             value={this.state.fields["domaine_id"]}
                             onChange={(e) =>
                              this.setState({ status: e.target.value })
                            }
                          > 
                              <option  value="actif"> Actif  </option>
                              <option  value="non-actif"> Non-actif  </option>
                          </select>
                    </div>
                    
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="row dis-flex justify-content-end">
                      <div className="col-md-5 col-sm-6">
                        {this.state.editDomaineInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            Modification en cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            MODIFIER
                          </button>
                        )}
                      </div>
                    </div>
                  </Modal.Footer>
                </form>
              </Modal>
           
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ syllabusDomaine }) => ({ ...syllabusDomaine }),
//     dispatch => bindActionCreators({ ...syllabusDomaineActions }, dispatch)
//   )( syllabusDomaine );
