import FeatherIcon from "feather-icons-react";
import React, { useEffect, useReducer, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  archiverBakelisteAsync,
  changerStatutBakeliste,
  changerStatutStaigiareToBakeliste,
  getBakelisteTerminesAsync,
  showListBakelistTermines,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import TableBootstrap from "./TableBootstrap";

const AllBakelisteTermines = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      recruteur_domaine: "",
      recruteur_raison: "",
      bakToStagiaireInProgress: false,
      bakeliste_id: "",
    }
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const bakelisteData = useSelector(showListBakelistTermines);

  useEffect(() => {
    dispatch(getBakelisteTerminesAsync());
  }, []);
  const actionFormatter = (cell, row) => {
    return (
      <span className="text-center">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="position-z">
            <Dropdown.Item href="#/action-2">
              <span
                onClick={() => archiverBakeliste(row.id)}
                style={{ textDecoration: "none" }}
              >
                Archiver
              </span>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              <span
                onClick={() => reintegreFormation(row.id)}
                style={{ textDecoration: "none" }}
              >
                Réintéger
              </span>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-3">
              <span
                onClick={() => {
                  handleShow();
                  setState({ bakeliste_id: row.id });
                }}
              >
                Placer en stage
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>{" "}
      </span>
    );
  };

  const archiverBakeliste = (id) => {
    dispatch(archiverBakelisteAsync(id));
  };

  const reintegreFormation = (id) => {
    var fd = new FormData();
    fd.append("bakeliste_id", id);
    dispatch(changerStatutStaigiareToBakeliste(fd));
  };

  const changeStatus = (e) => {
    e.preventDefault();

    setState({
      bakToStagiaireInProgress: true,
    });
    var fd = new FormData();
    fd.append("bakeliste_id", state.bakeliste_id);
    fd.append("recruteur_raison", state.recruteur_raison);
    fd.append("recruteur_domaine", state.recruter_domaine);

    dispatch(changerStatutBakeliste(fd));

    setTimeout(() => {
      setState({
        bakToStagiaireInProgress: false,
        // showEditModal: false,
      });
      handleClose();
    }, 2000);
    // axios
    //   .post(state.api + "bakeliste-to-stagiaire", fd)
    //   .then((response) => {
    //     // console.log(response);
    //     if (response.data.success === true) {
    //       setState({
    //         bakToSta: response.data.data,
    //         bakToStagiaireInProgress: false,
    //         showEditModal: false,
    //       });
    //       props.getListeAllBakelistes();
    //       toast.success(
    //         "Status changé avec success",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     } else {
    //       toast.error(
    //         "Erreur sur le changement du status",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error.message);
    //   });
  };

  // Bootstrap table name formatter
  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/bakelistes_new" className="lien_nav_current">
                Dashboard bakeliste
              </Link>
            </li>
            <li>
              <Link to="#" className="lien_nav_current">
                Bakelistes terminés
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row mt-2">
        <div className="col-md-11">
          <TableBootstrap
            userData={bakelisteData}
            csvName="Liste bakeliste formation terminés"
            shouldExportCsv
            shouldFormatName
            shouldPaginate={bakelisteData?.length > 10}
            shouldSearch
            shouldShowPhoneNumber
            shouldShowCoach
            shouldFormatAction
            nameFormatter={nameFormatter}
            actionFormatter={actionFormatter}
          />
          {/* <BootstrapTable
            data={bakelisteData}
            striped={true}
            hover={true}
            search
            exportCSV
            csvFileName="listes-bakelistes-terminés.csv"
            searchPlaceholder="Rechercher bakeliste..."
            multiColumnSearch
            multiColumnSort={2}
            pagination
            bordered={true}
          >
            <TableHeaderColumn
              isKey
              dataField="bakelisteFullname"
              filter={{
                type: "TextFilter",
                delay: 1000,
                placeholder: "Recherche  ",
              }}
              tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
              width="200"
            >
              <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="email"
              filter={{
                type: "TextFilter",
                delay: 1000,
                placeholder: "Recherche  ",
              }}
              tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
              width="200"
            >
              <span className="color-nom-prenom-utilisateurs">Email</span>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="phone"
              filter={{
                type: "TextFilter",
                delay: 1000,
                placeholder: "Recherche  ",
              }}
              tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
              width="200"
            >
              <span className="color-nom-prenom-utilisateurs">Numéro</span>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="coachFullname"
              filter={{
                type: "TextFilter",
                delay: 1000,
                placeholder: "Recherche  ",
              }}
              tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
              width="200"
            >
              <span className="color-nom-prenom-utilisateurs">Coach</span>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="action"
              dataFormat={actionFormatter}
              filter={{
                type: "TextFilter",
                delay: 1000,
                placeholder: "Recherche  ",
              }}
              tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
              width="200"
            >
              <span className="color-nom-prenom-utilisateurs"> Actions</span>
            </TableHeaderColumn>
          </BootstrapTable> */}
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <form onSubmit={(e) => changeStatus(e)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="h-theme ">Changer bakeliste en stagiaire</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group as_input_container">
              <label>Domaine</label>
              <input
                // value={state.recruteur_raison}
                onChange={(e) =>
                  setState({ recruteur_domaine: e.target.value })
                }
                name="recruteur_domaine"
                placeholder="Domaine du recruteur"
                className="form-control input_item"
                id="name"
              />
            </div>
            <div>
              <label>Raison social</label>
              <input
                // value={state.recruteur_raison}
                onChange={(e) => setState({ recruteur_raison: e.target.value })}
                name="recruteur_raison"
                placeholder="Domaine du recruteur"
                className="form-control input_item"
                id="name"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row justify-content-end text-center">
              <div className="">
                {state.bakToStagiaireInProgress ? (
                  <button
                    type="submit"
                    className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    En cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button type="submit" className="btn-theme text-center px-5">
                    Valider
                  </button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AllBakelisteTermines;
