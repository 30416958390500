import React, { Component } from "react";
import "./listProspects.css";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import FeatherIcon from "feather-icons-react";
import ImageDossier from "../../img/folder_guess.png";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listProspectsActions from "../../store/listProspects/actions";
export default class listProspects extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="component-my-participants">
        <div className="container-fluid px-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
              <li className="breadcrumb-item">
                <Link to="/dashboard-admin" className="lien_nav_current">
                  <FeatherIcon
                    className="icon-breadcrumb-custom"
                    icon="layout"
                  />
                  Dashboard
                </Link>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                className="lien_nav"
              >
                <FeatherIcon
                  className="icon-breadcrumb-custom"
                  icon="user-plus"
                />
                Propects
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <h6 className="h-theme ff-inter fw-600 px-3">
                {/* Dossiesr de candidature */}
              </h6>
            </div>
            <div className="row">
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
              <div className=" col-md-4 mb-3">
                <Skeleton width="80%" height="30vh" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listProspects }) => ({ ...listProspects }),
//     dispatch => bindActionCreators({ ...listProspectsActions }, dispatch)
//   )( listProspects );
