import React, { Component } from "react";
import { Link } from "react-router-dom";

import frenchShortFormatDate from "../frenchFormatDate";
import "./ficheBakelisteAdmin.css";
import FeatherIcon from "feather-icons-react";
import qs from "qs";
//import axios from "axios";
import API from "../../variablesGlobales";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import axios from "axios";
import * as Sentry from "@sentry/react";
import $ from "jquery";
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';

export default class ficheBakelisteAdmin extends Component {
  constructor(props) {
    super(props);
    let bakeliste_id = this.props.location?.state?.id;
    let bakelisteDonnees = this.props.location?.state?.data;
    if (!bakeliste_id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      bakeliste_id = parseInt(obj.id);
    }

    this.state = {
      bakeliste_id: bakeliste_id,
      api: API.API,
      loading: true,
      bakelisteDonnees: bakelisteDonnees,
      formatDate: frenchShortFormatDate.frenchShortFormatDate,
      selectOptions: [],
      selectOptions1: [],
      selected: [],
      showAlertError: false,
      coach_id: window.sessionStorage.getItem("user_id"),
      selectedBakeliste: [],
      sous_groupes: [],
      ajoutEnCours: false,
      ajoutSuccessAlert: false,
      isAjoutError: false,
      fields: {},
    };
    // console.log(this.state.bakelisteDonnees, "dommmmm")
  }


  render() {
    let bakeliste = this.state.bakelisteDonnees;
    return (
      <div className="body-theme">
         <HeaderDashboardAdmin />
          <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
            </div>
                <div className="col-lg-10 pt-3">
                  <div className="p-b-200">
                    <div className="row">
                      <div className="col-lg-9">
{/*                         
                        <nav aria-label="breadcrumb fixed">
                          <ol className="breadcrumb fixed">
                            <li className="breadcrumb-item ">
                              <Link to="/dashboard-coach">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item " aria-current="page">
                              <Link to="/coach-bakelistes">Bakelistes</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Fiche de {bakeliste.first_name}{" "}
                              {bakeliste.last_name}
                            </li>
                          </ol>
                        </nav>
                         */}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/suivi-bakeliste-version-deux">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="user"
                      />
                       Suivi Bakeliste
                    </Link>
                  </li>      
                  <li
                    className="breadcrumb-item active lien_nav"
                    aria-current="page"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="file"
                    />
                    fiche bakeliste
                  </li>
                </ol>
              </nav>
              <div className="liste-bak pb-4">
                <span className="liste-bak-all ml-3">
                  Fiche de {bakeliste.first_name} {bakeliste.last_name}
                </span>
              </div>
                        <div className="card-dashboard m-b-15 p-b-60 mto-3">
                          <div className="row mt-5 mb-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-account">
                                  Nom Complet:
                                </label>
                                <label className="form-control input-account">
                                  {" "}
                                  {bakeliste.first_name} {bakeliste.last_name}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">Email:</label>
                                <label className="form-control input-account">
                                  {bakeliste.email}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Adresse:
                                </label>
                                <label className="form-control input-account">
                                  {bakeliste.address}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Domaine:
                                </label>
                                <label className="form-control input-account">
                                  {bakeliste.formation_suivie}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Type de formation:
                                </label>
                                <br />
                                {bakeliste.type_formation === "courte" ? (
                                  <label className="form-control input-account">
                                    Formation Courte
                                  </label>
                                ) : (
                                  <label className="form-control input-account">
                                    Formation Longue
                                  </label>
                                )}
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Formation Payante:
                                </label>
                                <br />
                                {bakeliste.is_formation_payante === 0 ? (
                                  <span className="form-control input-account">
                                    Oui
                                  </span>
                                ) : (
                                  <span className="form-control input-account">
                                    Non
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Objectifs:
                                </label>
                                <textarea className="form-control input-account taille">
                                  {bakeliste.objectifs}
                                </textarea>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-account">
                                  Telephone:
                                </label>
                                <label className="form-control input-account">
                                  {bakeliste.phone}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Horaire:
                                </label>
                                <label className="form-control input-account"></label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Date début formation
                                </label>
                                <label className="form-control input-account">
                                  {this.state.formatDate(
                                    bakeliste.debut_formation
                                  )}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Date de fin
                                </label>
                                <label className="form-control input-account">
                                  {this.state.formatDate(
                                    bakeliste.fin_formation
                                  )}
                                </label>
                              </div>
                              <div className="form-group">
                              
                              </div>
                              

                              <div className="form-group">
                                <label className="label-account">Bio:</label>
                                <textarea className="form-control input-account">
                                  {bakeliste.bio}
                                </textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                       
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ ficheBakeliste }) => ({ ...ficheBakeliste }),
//     dispatch => bindActionCreators({ ...ficheBakelisteActions }, dispatch)
//   )( ficheBakeliste );
