import React, {Component} from 'react';
import './editerStagiaire.css';
import API from "../../variablesGlobales";
import qs from "qs";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as editerStagiareActions from "../../store/editerStagiare/actions";
export default class editerStagiaire extends Component {
  constructor(props) {
    super(props); 
    let user_id = this.props.location?.state?.id;
    let data = this.props.location?.state?.data;
    if (!user_id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      user_id = parseInt(obj.id);
    } 
    this.state = {  
      user_id: user_id, 
      api: API.API, 
      data: data,
      userID: window.sessionStorage.getItem("user_id"),
      user:[],
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      phone: data.phone,
      email: data.email,
      ecole: data.ecole,
      domaine: data.domaine,
      domaine_id: data.coach_domaine_id,
      formation_suivie:data.formation_suivie,
      domaines: [],
      id: data.id,
      status: data.status,
      recruteur_raison: data.recruiter_raison_sociale,
      recruteur_domaine:data.recruiter_domaine_pro,
      objectifs: data.bio,
    };
    // console.log(this.state.data)
  }

   
  editDetailsStagiaire = (e) => {
    // console.log("ddddddddd",this.state.bakeliste)
    e.preventDefault();
    this.setState({
      editStagiaireInProgress: true,
    });
    var fd = new FormData();
    fd.append("recruteur_raison", this.state.recruteur_raison);
    fd.append("recruteur_domaine", this.state.recruteur_domaine);
    fd.append("bakeliste_id" , this.state.id)
    axios
      .post(this.state.api + "edit-stagiaire" , fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((result) => {
      //  console.log(result)
        if (result.data.success) {
          toast.success(
            "Détails stagiaire modiifié avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          this.setState({
            editStagiaireInProgress: false,
          });
        
          window.location = "/stagiaires";
        }else{
          toast.error(
            "Erreur sur la modification",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      });
  };
    render() {

      return (
        <div className="component-edit-bakeliste-suivi-view body-theme">
          <HeaderDashboardAdmin />
                  <div className="container-fluid fixing-after-header">
                    <div className="row">
                      <div className="col-lg-2 p-0">
                        <NavVerticalAdmin />
                      </div>
                      <div className="col-lg-10 pt-3">


                      <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/stagiaires" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="users"
                      />
                      Stagiaires
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-side-breadcrumb-custom"
                      icon="edit"
                    />
                    Editer détails statgiaire
                  </li>
                </ol>
              </nav>
              <div className="row">
                <div className="col-md-6">
                    
                <div className="card-dashboard m-b-15 p-b-60 mto-3">
                <h4 className="h-theme titreCoach">
                  Fiche de  {this.state.first_name} {this.state.last_name}
                </h4>
                          <div className="row mt-5 mb-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-account">
                                  Nom Complet:
                                </label>
                                <label className="form-control input-account">
                                  {" "}
                                  {this.state.first_name} {this.state.last_name}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">Email:</label>
                                <label className="form-control input-account">
                                  {this.state.email}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Adresse:
                                </label>
                                <label className="form-control input-account">
                                  {this.state.address}
                                </label>
                              </div>
                              
                             </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="label-account">
                                  Telephone:
                                </label>
                                <label className="form-control input-account">
                                  {this.state.phone}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Domaine:
                                </label>
                                <label className="form-control input-account">
                                  {this.state.formation_suivie}
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="label-account">
                                  Objectifs:
                                </label>
                                <textarea className="form-control input-account taille">
                                  {this.state.objectifs}
                                </textarea>
                              </div>
                           
                            </div>
                          </div>
                        </div>
                        


                </div>
                <div className="col-md-6">
                  
           <form className="form1" onSubmit={(e) => this.editDetailsStagiaire(e)}>
              <div>
                  <h4 className="h-theme titreCoach">
                  Modifier détails de stage 
                </h4>
               
                
              </div>
              <div className="form-row row pdt_table">
              <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Domaine du recruteur:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="recruteur_domaine"
                      value={this.state.recruteur_domaine}
                      onChange={(e) =>
                        this.setState({ recruteur_domaine: e.target.value })
                      }
                    />

                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Raison sociale:</label>
                    <input
                      type="last_name"
                      className="form-control ab1"
                      name="recruteur_raison"
                      value={this.state.recruteur_raison}
                      onChange={(e) =>
                        this.setState({ recruteur_raison: e.target.value })
                      }
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
               
                 
                 </div>

                <div >
                  {this.state.editStagiaireInProgress ? (
                    <button
                      type="submit"
                      className="col-md-6 btn-theme text-up m-t-30 w-full p-t-15 p-b-15 offset-md-6"
                      disabled
                    >
                      Modification en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="col-md-5 btn-theme offset-md-6 text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      MODIFIER
                    </button>
                  )}
                </div>
              
            </form>
          
                 
                </div>
              </div>
                 </div>
                    </div> 
                  </div> 
          </div>
        );
     }
  }
// export default connect(
//     ({ editerStagiare }) => ({ ...editerStagiare }),
//     dispatch => bindActionCreators({ ...editerStagiareActions }, dispatch)
//   )( editerStagiare );