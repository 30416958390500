import React, { Component } from "react";
import "./cardUtilisateurSkeleton.css";
import Skeleton from "react-loading-skeleton";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cardUtilisateurSkeletonActions from "../../store/cardUtilisateurSkeleton/actions";
export default class cardUtilisateurSkeleton extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-card-utilisateur-skeleton">
        <div className="row">
          <div className="col-md-4 mb-2">
            <div className="card-item first-card-info">
              <div className="image-cercle">
                <Skeleton width={`100%`} height={`100%`} />
              </div>
              <div className="pt-2 pl-3">
                <p className="fat-ma">
                  <span className="d-flex">
                    {" "}
                    <Skeleton width={`40%`} height={`40%`} />
                  </span>
                </p>
                <p>
                  <Skeleton width={`40%`} height={`40%`} />
                </p>
              </div>

              <div className="row">
                <div className="col-md-10 col-lg-10">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="row m-2">
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-4 mb-2">
            <div className="card-item first-card-info">
              <div className="image-cercle">
                <Skeleton width={`100%`} height={`100%`} />
              </div>
              <div className="pt-2 pl-3">
                <p className="fat-ma">
                  <span className="d-flex">
                    {" "}
                    <Skeleton width={`40%`} height={`40%`} />
                  </span>
                </p>
                <p>
                  <Skeleton width={`40%`} height={`40%`} />
                </p>
              </div>

              <div className="row">
                <div className="col-md-10 col-lg-10">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="row m-2">
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-4 mb-2">
            <div className="card-item first-card-info">
              <div className="image-cercle">
                <Skeleton width={`100%`} height={`100%`} />
              </div>
              <div className="pt-2 pl-3">
                <p className="fat-ma">
                  <span className="d-flex">
                    {" "}
                    <Skeleton width={`40%`} height={`40%`} />
                  </span>
                </p>
                <p>
                  <Skeleton width={`40%`} height={`40%`} />
                </p>
              </div>

              <div className="row">
                <div className="col-md-10 col-lg-10">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="row m-2">
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-4 mb-2">
            <div className="card-item first-card-info">
              <div className="image-cercle">
                <Skeleton width={`100%`} height={`100%`} />
              </div>
              <div className="pt-2 pl-3">
                <p className="fat-ma">
                  <span className="d-flex">
                    {" "}
                    <Skeleton width={`40%`} height={`40%`} />
                  </span>
                </p>
                <p>
                  <Skeleton width={`40%`} height={`40%`} />
                </p>
              </div>

              <div className="row">
                <div className="col-md-10 col-lg-10">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="row m-2">
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>

          <div className="col-md-4 mb-2">
            <div className="card-item first-card-info">
              <div className="image-cercle">
                <Skeleton width={`100%`} height={`100%`} />
              </div>
              <div className="pt-2 pl-3">
                <p className="fat-ma">
                  <span className="d-flex">
                    {" "}
                    <Skeleton width={`40%`} height={`40%`} />
                  </span>
                </p>
                <p>
                  <Skeleton width={`40%`} height={`40%`} />
                </p>
              </div>

              <div className="row">
                <div className="col-md-10 col-lg-10">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="row m-2">
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-4 mb-2">
            <div className="card-item first-card-info">
              <div className="image-cercle">
                <Skeleton width={`100%`} height={`100%`} />
              </div>
              <div className="pt-2 pl-3">
                <p className="fat-ma">
                  <span className="d-flex">
                    {" "}
                    <Skeleton width={`40%`} height={`40%`} />
                  </span>
                </p>
                <p>
                  <Skeleton width={`40%`} height={`40%`} />
                </p>
              </div>

              <div className="row">
                <div className="col-md-10 col-lg-10">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-6 col-lg-6 mb-3">
                  <Skeleton width={`80%`} height={`60%`} />
                </div>
                <div className="col-md-12 col-lg-12">
                  <div className="row m-2">
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div class="icon-wrapper">
                        <i className="custom-icon-skel">
                          <Skeleton width={`40%`} height={`40%`} />
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ cardUtilisateurSkeleton }) => ({ ...cardUtilisateurSkeleton }),
//     dispatch => bindActionCreators({ ...cardUtilisateurSkeletonActions }, dispatch)
//   )( cardUtilisateurSkeleton );
