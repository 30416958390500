import React, { Component } from "react";
import "./headerDashboardCoach.css";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import Logo from "../../img/logo.png";
import { Avatar, Dropdown } from "rsuite";
// import Pointage from '../pointage/index';

export default class headerDashboardCoach extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-white box-shadow fixed-top px-lg-5 py-md-3 height-header">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <NavLink className="navbar-brand mr-lg-5 mr-0" to="/">
            <img className="logo-header" src={Logo} alt="" />
          </NavLink>

          <Avatar className="avatar-rs-custom no-display-desktop">RS</Avatar>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="no-view-tablette dis-flex itm-center w-full">
              <div className="pl-xl-5 pl-lg-4 dis-flex pt-lg-0 pt-5 pb-lg-0 pb-5">
                <p className="welcome-header">Bienvenue sur Bakeli SI </p>
                <p className="annee-header">2020</p>
              </div>

              {/* <div className="m-auto">
                        <button className="btn-theme-light" type="button" data-toggle="modal" data-target="#btnPointage">
                          <FeatherIcon className="icon-pointage" icon="check-circle" />
                          Pointage
                        </button>
                      </div> */}

              <div className="ml-auto no-view-tablette">
                <div className="avatar-group dis-flex itm-center">
                  <Avatar className="avatar-rs-custom">RS</Avatar>
                  <Dropdown
                    className="name-avatar-custom"
                    title="Abdou seck"
                    placement="bottomEnd"
                    trigger="hover"
                  >
                    <NavLink
                      className="dropdown-item-header-dashboard"
                      to="/compte-coach"
                    >
                      Mon compte
                    </NavLink>
                    <NavLink className="dropdown-item-header-dashboard" to="/">
                      Déconnexion
                    </NavLink>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="no-display-desktop">
              <div className="container-nav-vertical">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tabCustom"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/dashboard-coach"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="layout"
                    />
                    Dashboard
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/tache-coach"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="grid"
                    />
                    Tâches
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/livraison-coach"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="folder"
                    />
                    Livraison
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/groupe-coach"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="users"
                    />
                    Groupe
                  </NavLink>
                  <NavLink
                    className="nav-link nav-link-side-navbar-custom"
                    to="/syllabus-coach"
                  >
                    <FeatherIcon
                      className="icon-side-navbar-custom"
                      icon="book"
                    />
                    Syllabus
                  </NavLink>
                </div>

                {/* <button className="btn-theme-light mt-4 ml-4 mb-5" type="button" data-toggle="modal" data-target="#btnPointage">
                          <FeatherIcon className="icon-pointage" icon="check-circle" />
                          Pointage
                        </button> */}
                      </div>
                    </div>
                  </div>
                  
                </nav>
                
                {/* <div className="modal fade" id="btnPointage" tabindex="-1" role="dialog" aria-labelledby="btnPointageTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content modal-pointage">
                      <Pointage />
                    </div>
                  </div>
                </div> */}
      </div>
    );
  }
}
// export default connect(
//     ({ headerDashboardCoach }) => ({ ...headerDashboardCoach }),
//     dispatch => bindActionCreators({ ...headerDashboardCoachActions }, dispatch)
//   )( headerDashboardCoach );
