import React, { Component } from 'react';
import './passwordReset.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import API from '../../variablesGlobales';
import Logo from "../../img/logo.png";
import FeatherIcon from "feather-icons-react";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";

export default class passwordReset extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.resetUserPassword = this.resetUserPassword.bind(this);
    this.state = {
      id: 0,
      api: API.API,
      isPending: false,
      fields: {},
      successPasswordReset: false,
      showForm: true
    };
  }

  componentDidMount() {
    
    const { match: { params } } = this.props;
    this.setState({
      id: params.id
    })
    window.sessionStorage.setItem('reset_userID', params.id);
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields
    })
    // console.log(fields)
  }

  resetUserPassword = (e) => {
    e.preventDefault();
    this.setState({
      isPending: true
    })
    let reset_userID = window.sessionStorage.getItem('reset_userID');
    let passwordData = this.state.fields;

    axios.post(this.state.api + 'update-user-password/' + reset_userID, passwordData)
      .then(response => {
        this.setState({
          isPending: false
        });

        if (response.data.success === true) {
          this.setState({
            successPasswordReset: true,
            showForm: false
          });
        } else {
          console.log('Erreur lors de la modification')
        }
      })
  }


  render() {
    return (
      <Amplitude>
        <div className="container-fluid">
          <LogOnMount eventType="visite page d'accueil" />
          <div className="row">
            <div className="col-md-6 banner-connexion-page dis-flex justify-content-center itm-center py-5">
              <div className="col-xl-9 col-lg-10 col-md-11 col-sm-8 text-center">
                <h1 className="titre-banner-connexion">Bakeli SI</h1>
                <p className="slogan-banner-connexion">
                  Training the best tech talent in Africa.
                </p>
              </div>
            </div>
            <div className="col-md-6 bg-white dis-flex justify-content-center itm-center py-md-0 py-5">
              <div className="col-xl-7 col-lg-9 col-md-11 col-sm-8 dis-flex flex-col">
                <img className="logo-connexion m-l-r-auto" src={Logo} alt="" />
                <p className="h-connexion-form">MODIFIER VOTRE MOT DE PASSE</p>
                  {this.state.showForm ?
                    <div className="reset-form-container">
                      <form className="positionForm" onSubmit={this.resetUserPassword}>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control input-form-connexion"
                            id="password" placeholder="Mot de passe"
                            name="password" onChange={this.handleChange} required />
                        </div>
                        <div className="form-group">
                          {!this.state.isPending &&
                            <button className='btn-connexion'>
                              <i class="fas fa-edit"></i> &nbsp;
                              Valider
                          </button>
                          }
                          {this.state.isPending &&
                            <button className="btn-connexion">
                              Modification en cours &nbsp;
                          <i class="fas fa-spinner fa-spin"></i>
                            </button>
                          }

                        </div>

                      </form>
                    </div> :

                    <div className="success-reset-form-container">
                      <div className="alert alert-success success-message">
                        <p>Bravo ! Votre mot de passe a été bien réinitialisé. Vous pouvez vous connecter dorénavant
                        avec votre email et votre nouveau mot de passe.</p>
                        <hr />
                        <Link to="/" className="connexion_link">
                          <button className="btn btn-primary btn-lg volkeno-green-bcolor connexionBtn-link">Connectez-vous</button>
                        </Link>
                      </div>
                    </div>
                    }
              </div>
            </div> 
          </div>
        </div>
      </Amplitude>
    );
  }
}
