import React, { Component } from "react";
import "./editBakeliste.css";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import TableDebutant from "../tableDebutant/index";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import amplitude from "amplitude-js";
import * as Sentry from "@sentry/react";
import { Icon } from "rsuite";
// import { toast } from "react-toastify";

export default class editBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      domaines: [],
      bakeliste: [],
      domaineName: [],
      bakeliste_domaine_id: "",
      editBakelisteInProgress: false,
      bakeliste_domaine_id: this.props.domaine_id,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      domaine: this.props.domaine,
      address: this.props.adresse,
      email: this.props.email,
      phone: this.props.phone,
      debut_formation: this.props.debut_formation,
      fin_formation: this.props.fin_formation,
      ecole: this.props.ecole,
      lieu_formation: this.props.lieu_formation,
      // paiement_inscription:this.props.paiement_inscription,
      horaire: "9h-17h",
      // boursier:0,
      // demi_boursier:0,
      is_isa_contract: 0,
      is_stagede_formation: 0,
      // horaire: "",
    };
  }

  getDomaine() {
    axios.get(this.state.api + "domaines").then((res) => {
      const domains = res.data;
      this.setState({
        domaines: domains.data,
      });
    });
  }
  handleChange = (e) => {
    this.setState(() => ({
      bakeliste: { [e.target.name]: e.target.value },
    }));
  };
  getDomaineById() {
    axios
      .get(this.state.api + "domaines/" + this.props.domaine_id)
      .then((res) => {
        this.setState({
          domaineName: res.data.data.name,
        });
        // console.log("ee",this.state.domaineName)
      });
  }
  getHoraireById() {
    axios
      .get(this.state.api + "programmation_horaires/" + this.props.id)
      .then((res) => {
        this.setState({
          horaire: res.data.data.horaire,
        });
        //  console.log("ee",this.state.horaire)
      });
  }
  
  componentDidMount() {
    this.getDomaine();
    this.getDetailsBakelistes();
    this.getDomaineById();
    // this.getHoraireById();
  }

  getDetailsBakelistes = () => {
    axios
      .get(this.state.api + "get-profile/" + this.props.id)
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Details Bakeliste", res.data.data);
          this.setState({
            bakeliste: res.data.data,
            bakeliste_domaine_id: res.data.data.bakeliste_domaine_id,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          getBakelisteRequestError: true,
        });
        console.log(error.message);
      });
  };

  editBakeliste = (e) => {
    // console.log("ddddddddd",this.state.bakeliste)
    e.preventDefault();
    this.setState({
      editBakelisteInProgress: true,
    });
    var fd = new FormData();
    fd.append("first_name", this.state.first_name);
    fd.append("boursier", 0);
    fd.append("demi_boursier", 0);
    fd.append("is_formation_payante", 1);
    fd.append("admin_id", 0);
    fd.append("horaire", this.state.horaire);
    fd.append("is_isa_contract", this.state.is_isa_contract);
    fd.append("is_stagede_formation", this.state.is_stagede_formation);
    fd.append("paiement_inscription", 1);
    fd.append("bakeliste_domaine_id", this.state.bakeliste_domaine_id);
    fd.append("last_name", this.state.last_name);
    fd.append("ecole", this.state.ecole);
    fd.append("phone", this.state.phone);
    fd.append("address", this.state.address);
    fd.append("debut_formation", this.state.debut_formation);
    fd.append("fin_formation", this.state.fin_formation);
    fd.append("lieu_formation", this.state.lieu_formation);
    fd.append("domaine", this.state.domaine);
    fd.append("email", this.state.email); 

    axios
      .put(this.state.api + "update-bakeliste-infos/" + this.props.id, fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((result) => {
        // console.log("editer", result);
        this.setState({
          editBakelisteInProgress: false,
        });
        // console.log(result);
        if (result.data.success) {
          window.location = "/bakelistes-db";
        }
      });
  };
annuler = (e) => {
  window.location="/bakelistes-db"
}
  onChange = (e) => {
    this.setState({ bakeliste_domaine_id: e.value });
  };
  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav">
                  Dashboard{" "}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/bakelistes-db" className="lien_nav">
                    Base de données bakelistes /{" "}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  className="lien_nav_current"
                >
                  {" "}
                  Modification données bakeliste
                </li>
              </ol>
            </nav>
            <form className="form1" onSubmit={(e) => this.editBakeliste(e)}>
              <div>
                <h4 className="h-theme titreCoach">
                  Modification des details d'un bakeliste 
                </h4>
              </div>
              <div className="form-row row">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Prénom:</label>
                    <input
                      type="text" 
                      className="form-control ab1"
                      name="first_name"
                      value={this.state.first_name}
                      onChange={(e) =>
                        this.setState({ first_name: e.target.value })
                      }
                    />

                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Nom de famille:</label>
                    <input
                      type="last_name"
                      className="form-control ab1"
                      name="last_name"
                      value={this.state.last_name}
                      onChange={(e) =>
                        this.setState({ last_name: e.target.value })
                      }
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Email:</label>
                    <input
                      type="Email"
                      className="form-control ab1"
                      name="email"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Téléphone:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="phone"
                      value={this.state.phone}
                      onChange={(e) => this.setState({ phone: e.target.value })}
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Adresse:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="address"
                      value={this.state.address}
                      onChange={(e) =>
                        this.setState({ address: e.target.value })
                      }
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">École:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="ecole"
                      value={this.state.ecole}
                      onChange={(e) => this.setState({ ecole: e.target.value })}
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Lieu de formation:</label>
                    <select
                      className="form-control ab1"
                      id="inputlieu_formation"
                      name="lieu_formation"
                      value={this.state.lieu_formation}
                      onChange={(e) =>
                        this.setState({ lieu_formation: e.target.value })
                      }
                    >
                      <option> --Choisir un lieu-- </option>
                      <option value="Sicap Foire">Sicap Foire</option>
                      <option value="Grand Yoff 1">Grand Yoff 1</option>
                      <option value="Grand Yoff 2">Grand Yoff 2</option>
                      <option value="Thiés">Thiés</option>
                      <option value="Labé">Labé (Guinée)</option>
                    </select>
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                                  
                  {/* <div className="col-md-6">
                    <div className="form-group ">
                      <label>Horaire:</label>
                      <select
                        className="form-control ab1"
                        name="horaire"
                        value={this.state.horaire}
                        // onChange={this.handleChange}
                        onChange={(e) =>
                          this.setState({ horaire: e.target.value })
                        }
                      >
                        <option> --Choisir une option-- </option>
                        <option value="8h30-12h30">8h30' - 12h30'</option>
                        <option value="13h-17h">13h-17</option>
                        
                      </select>
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div> */}

                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Domaine de formation:</label>
                    <select
                      className="form-control ab1"
                      id="bakeliste_domaine_id"
                      name="bakeliste_domaine_id"
                      value={this.state.bakeliste_domaine_id}
                      onChange={(e) =>
                        this.setState({ bakeliste_domaine_id: e.target.value })
                      }
                    >
                      <option> --choisir une domaine </option>
                      {this.state.domaines.map((x, y) => (
                        <option value={x.id} key={y}>
                          {x.name}
                        </option>
                      ))}
                    </select>
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Date de début:</label>
                    <input
                      type="date"
                      className="form-control ab1"
                      name="debut_formation"
                      value={this.state.debut_formation}
                      onChange={(e) =>
                        this.setState({ debut_formation: e.target.value })
                      }
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Date de fin:</label>
                    <input
                      type="date"
                      className="form-control ab1"
                      // id="inputfin_formation"
                      name="fin_formation"
                      value={this.state.fin_formation}
                      //  onChange={this.handleChange}
                      onChange={(e) =>
                        this.setState({ fin_formation: e.target.value })
                      }
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="row" >
                <div className="col-md-6">
                  {this.state.editBakelisteInProgress ? (
                    <button
                      type="submit"
                      className="col-md-5 btn-theme text-up m-t-30 w-full p-t-15 p-b-15 offset-md-6"
                      disabled
                    >
                      Modification en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="col-md-5 btn-theme offset-md-6 text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      MODIFIER
                    </button>
                  )}
                </div>
                <div className="col-md-6">
                  <button
                       onClick={(e) => this.annuler(e)}
                      className="col-md-5 btn-warning text-up m-t-30 w-full p-t-15 p-b-15 offset-md-1"
                    >
                      ANNULER
                    </button>
                </div>
              </div>
             
              {/* {!this.state.editBakelisteInProgress && (
                        <button
                          
                          className=" col-md-4 btn btn-theme"
                          onClick={(e) => this.editBakeliste(e)}
                        >
                          <i className="fas fa-edit"></i>&nbsp; Modifier
                        </button>
                      )}
                      {this.state.updateInProgress && (
                        <button
                          type="button"
                          className="col-md-4 btn btn-theme"
                        >
                          Modification en cours &nbsp;
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      )}     */}
            </form>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteLateItemForAdmin />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteMissingItemForAdmin />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
