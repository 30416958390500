import React, {Component} from 'react';
import './itemFilActualite.css';
import AvatarFilActualite from '../../img/icons/fil-actualite.png';
import {NavLink} from 'react-router-dom';
import IconComment from '../../img/icons/comment.png';
import IconSendComment from '../../img/icons/send1.png';
import { Input, InputGroup } from 'rsuite';
import FeatherIcon from 'feather-icons-react';
import GalleryLivraison from '../galleryLivraison/index'


export default class itemFilActualite extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }
    render() {
      return  <div className="card-fil-actualite">
                <div className="row mb-15">
                  <div className="col-sm-9 dis-flex itm-center mb-sm-0 mb-2">
                    <img className="avatar-fil-actualite" src={AvatarFilActualite} alt="" />
                    <p className="name-fil-actualite">Ngoné Diop</p>
                    <p className="heure-fil-actualite">8h</p>
                  </div>
                  <div className="col-sm-3 dis-flex itm-center">
                    <p className="h-theme ff-work fs-12 fw-500 ml-sm-auto ml-5">Tâches N°1</p>
                  </div>
                </div>

                <div className="px-md-5 py-md-4 py-3 px-3">
                  <NavLink className="lien-utile-bakeliste-tache-card" to="">
                    https://www.figma.com/file
                  </NavLink>

                  <div>
                    <GalleryLivraison />
                  </div>
                </div>
                <form>
                  <div className="row m-t-25 m-b-5">
                    <div className="px-lg-2 col-sm-2 col-3 px-md-3 px-sm-2 dis-flex itm-center justify-content-center">
                      <button className="btn-like-fil-actualite-card mr-xl-3 mr-lg-2 pr-sm-3 px-lg-0">
                        <FeatherIcon className="icon-btn-like-fil-actualite-card" icon="thumbs-up" />
                      </button>
                    </div>
                    <div className="col-sm-10 col-9 pl-0 pr-2 pr-sm-2 pr-md-3  items">
                      <InputGroup inside>
                        <InputGroup.Addon>
                          <img className="icon-comment-fil-actualite" src={IconComment} alt="" />
                        </InputGroup.Addon>
                        <Input placeholder="Ajouter un commentaire" className="input-comment-fil-actualite" />
                        <button className="btn-send-comment-fil-actualite">
                          <img className="icon-btn-send-comment-fil-actualite" src={IconSendComment} alt="" />
                        </button>
                      </InputGroup>
                    </div>
                  </div>
                </form>
                
              </div>;
    }
  }
// export default connect(
//     ({ itemFilActualite }) => ({ ...itemFilActualite }),
//     dispatch => bindActionCreators({ ...itemFilActualiteActions }, dispatch)
//   )( itemFilActualite );