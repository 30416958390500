import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getCandidatureByIdAsync,
  prospectByIdItem,
} from "../../redux/slices/prospectSlices/prospectSlice";
import DossierCandidatureRefac from "../dossierCandidature/DossierCandidatureRefac";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";

const DossierCandidatureViewRefac = () => {
  const location = useLocation();

  const { id } = useParams();

  const dispatch = useDispatch();
  const prospectCandidature = useSelector(prospectByIdItem);

  React.useEffect(() => {
    dispatch(getCandidatureByIdAsync(id));
  }, []);

  return (
    <div className="component-table-my-participants-view body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            {/* <DossierCandidature
              prospect_id={this.state.dossier.id}
              motivation={this.state.dossier.motivations}
              phone={this.state.dossier.phone}
              domaineId={this.state.dossier.study_choice}
              type_formation={this.state.dossier.type_formation}
              duree_formation={this.state.dossier.duree_formation}
              adresse={this.state.dossier.ville_residence}
              firstName={this.state.dossier.firstName}
              lastName={this.state.dossier.lastName}
              level={this.state.dossier.level}
              email={this.state.dossier.email}
              ispro={this.state.dossier.ispro}
              study_choice={this.state.dossier.study_choice}
            /> */}
            <DossierCandidatureRefac dataProps={prospectCandidature} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DossierCandidatureViewRefac;
