import React, {Component} from 'react';
import DetailsUsers from '../detailsUsers/index';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import './detailsUsersView.css';
import API from "../../variablesGlobales";
import qs from "qs";
import axios from "axios";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as profilVisiteurViewActions from "../../store/profilVisiteurView/actions";
export default class detailsUsersView extends Component {
    constructor(props) {
      super(props); 
      let user_id = this.props.location?.state?.id;
      if (!user_id && this.props.location.search) {
        const obj = qs.parse(this.props.location.search.replace("?", ""));
        user_id = parseInt(obj.id);
      } 
      this.state = {
        user_id: user_id, 
        api: API.API, 
        userID: window.sessionStorage.getItem("user_id"),
        user:[]
      };
    }
componentDidMount = () =>{
  this.getDetailsBakeliste();
}
 
getDetailsBakeliste = () => {
    axios
      .get(this.state.api + "bakeliste-by-id/" + this.state.user_id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
           user: response.data.data
          });
          // console.log(this.state.user, "details")
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
    render() {
      return (
          <div className="component-profil-visiteur-view body-theme">
              <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <DetailsUsers 
                      name={this.state.user.user_fullname}
                      email={this.state.user.email}
                      phone={this.state.user.phone}
                      address={this.state.user.address}
                      formation_suivie={this.state.user.formation_suivie}
                      ecole={this.state.user.ecole}
                      debut_formation={this.state.user.debut_formation}
                      fin_formation={this.state.user.fin_formation}
                      is_online={this.state.user.is_online}
                      lieu_formation={this.state.user.lieu_formation}
                      motivation={this.state.user.bio}
                      domaine_id={this.state.user.bakeliste_domaine_id}
                      />
                    </div>
                  </div>
                </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ profilVisiteurView }) => ({ ...profilVisiteurView }),
//     dispatch => bindActionCreators({ ...profilVisiteurViewActions }, dispatch)
//   )( profilVisiteurView );