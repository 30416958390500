import React, {Component} from 'react';
import './tableDakar.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';


var gestionTacheForCoach = [{
  number: "1",
  fullname: "Abdou ba",
  tel: "779130085",
  mail: "kalika@gmail.com",
  coach: "Makhtar Mbaye",
  action: "oui"
}, {
  number: "2",
  fullname: "Moumadjad ahmed abdou",
  tel: "779130085",
  mail: "kalika@gmail.com",
  coach: "",
  action: "oui "
},{
  number: "3",
  fullname: "Mounir ismael moegne daho",
  tel: "779130085",
  mail: "kalika@gmail.com",
  coach: "",
  action: "oui "
},{
  number: "4",
  fullname: "malaoupé mbaba",
  tel: "779130085",
  mail: "kalika@gmail.com",
  coach: "",
  action: "non "
},{
  number: "5",
  fullname: "mwegne hamadi omar",
  tel: "779130085",
  mail: "kalika@gmail.com",
  coach: "",
  action: "oui "
},{
  number: "6",
  fullname: "Mouayad ahmed abdou",
  tel: "779130085",
  mail: "kalika@gmail.com",
  coach: "",
  action: "non "
}];

class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col">
        <p className="badge todo-badge">To do</p>
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return (
    <Status active={ cell } />
  );
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={16} />
        </button>
 
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="edit-2" size={16} />
            </button>
                   <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="trash" size={16} />
        </button>
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}

export default class tableDakar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
  }
  CoachNiveau = (Column, row) => {
  return(
    <div className="">
      {row.coach === "Makhtar" ?
        (
        <span className="">Makhtar</span>
        )
            :
        (
        <button className="btn-icon-user-plus">  
          <FeatherIcon className="icon-btn-icon-user-plus" icon="user-plus" size={18} />
      </button>
        )
      }
    </div>
  )
}
    render() {
      return  <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
                <BootstrapTable 
                  data={gestionTacheForCoach} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                  <TableHeaderColumn className="titre-tab" dataField='number' thStyle={{ 'border': 'none', 'fontWeight': '' }} tdStyle={{ 'border': 'none', 'padding': 'none' }}>N°</TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" isKey dataField='fullname' thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none', 'padding': '1rem' }}>Nom Complet </TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='tel' thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none', 'padding': '1rem' }}>N° Téléphone</TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='mail' thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none', 'padding': '1rem' }}>Adresse mail</TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='coach' dataFormat={this.CoachNiveau} thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none', 'padding': '1rem' }}>Coach</TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='action' dataFormat={uploadFormatter} thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1rem' }}>Actions</TableHeaderColumn>
                </BootstrapTable>
              </div>;
    }
  }
