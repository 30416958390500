import React, { useEffect, useReducer } from "react";
import "./listeBakelistesArchives.css";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import API from "../../variablesGlobales";
import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";

import { Tooltip } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBakelisteArchivesAsync, showListBakeArchives } from "../../redux/slices/bakelistesSlices/bakelistesSlice";
const ListeBakelistesArichivesRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      bakelistesarchives: [],
      api: API.API,
    }
  );
const dispatch = useDispatch()
const bakelisteArchivesData = useSelector(showListBakeArchives)
  useEffect(() => {
    dispatch(getBakelisteArchivesAsync())
    getListesBakelistesArchives();
  }, []);

  const getListesBakelistesArchives = () => {
    axios
      .get(state.api + "liste-des-bakelistes-archives")
      .then((res) => {
        // console.log('heloooooooooooooooooo', res)
        if (res.status === 201) {
          // console.log("archivés", res);
          setState({
            bakelistesarchives: res.data.data,
            loading: true,
          });
        } else {
          setState({
            loading: false,
            isEmptyData: true,
          });
        }
        // setState({ isLoaded: false });
        // $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  const nameFormatter = (cell, row) => {
    return (
      <span>
        {/* <button> */}
        <Tooltip title="Voir la fiche">
          <Link
            className="linkName"
            to={{
              pathname: "/profil-utilisateur",
              state: { id: row.id, data: row },
            }}
          >
            {row.first_name} {row.last_name}{" "}
          </Link>
        </Tooltip>
        {/* </button> */}
      </span>
    );
  };
  return (
    <div className="component-liste-bakelistes-pause">
      {" "}
      <BootstrapTable
        pagination
        exportCSV
        // options={options}
        csvFileName="liste-bakelistes-abandonnes.csv"
        data={bakelisteArchivesData}
        striped
        version="4"
        bordered={false}
        bodyStyle={{
          fontSize: 12,
          color: "rgba(0, 0, 0, 0.7)",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        tdStyle={{ background: "red" }}
        tableHeaderclassName="header-table-groupe-view"
        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
        className="table-responsive table-responsive-sm"
      >
        <TableHeaderColumn
          filter={{ type: "TextFilter", delay: 1000 }}
          dataField="first_name"
          dataFormat={nameFormatter}
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          isKey={true}
          dataField="email"
          width="200"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Email</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="phone"
          width="200"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Telephone</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="status"
          width="150"
          filter={{ type: "TextFilter", delay: 1000 }}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            textAlign: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Statut</span>
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  );
};

export default ListeBakelistesArichivesRefact;
