import React, {Component} from 'react';
import './cvBakelistesView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import CvBakelistes from '../cvBakelistes/index';
import qs from "qs";
import { CancelPresentationOutlined } from '@material-ui/icons';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cvBakelistesViewActions from "../../store/cvBakelistesView/actions";
export default class cvBakelistesView extends Component {
    constructor(props) {
        super(props);
        let id = this.props.location?.state?.id;
        // let data = this.props.location?.state.data;
          if (!id && this.props.location.search) {
          const obj = qs.parse(this.props.location.search.replace("?", ""));
          id = parseInt(obj.id);
        }
        this.state = {
          id:id
        };
    }
    render(){
      // console.log("id cv", this.state.id)
      return  <div className="body-theme">
    <HeaderDashboardAdmin />
    <div className="container-fluid fixing-after-header">
      <div className="row">
        <div className="col-lg-2 p-0">
          <NavVerticalAdmin />
        </div>
        <div className="col-lg-10 pt-3">
          <CvBakelistes bakeliste_id={this.state.id}/>
        </div>
      </div> 
    </div>

    
  </div>;
}
  }
// export default connect(
//     ({ cvBakelistesView }) => ({ ...cvBakelistesView }),
//     dispatch => bindActionCreators({ ...cvBakelistesViewActions }, dispatch)
//   )( cvBakelistesView );