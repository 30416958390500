import React, {Component} from 'react';
import './navTabsProfilBakelisteView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import NavTabsProfilBakeliste from '../navTabsProfilBakeliste/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navTabsProfilBakelisteViewActions from "../../store/navTabsProfilBakelisteView/actions";
export default class navTabsProfilBakelisteView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
          <div className="component-nav-tabs-profil-bakeliste-view body-theme">
             <HeaderDashboardAdmin />
              <div className="container-fluid fixing-after-header">
                <div className="row">
                  <div className="col-lg-2 p-0">
                    <NavVerticalAdmin />
                  </div>
                  <div className="col-lg-10 pt-3">
                    <NavTabsProfilBakeliste />
                  </div>
                </div>
              </div>
            </div>
        );
    }
  }
// export default connect(
//     ({ navTabsProfilBakelisteView }) => ({ ...navTabsProfilBakelisteView }),
//     dispatch => bindActionCreators({ ...navTabsProfilBakelisteViewActions }, dispatch)
//   )( navTabsProfilBakelisteView );