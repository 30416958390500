import React from "react";
import "./listeGroupe.css";
import { Icon} from "rsuite";


import ListeGroupesAdmin from "../listeGroupesAdmin/index";

import { history } from "../history";
import ListeGroupesAdminRefact from "../listeGroupesAdmin/ListeGroupesAdminRefact";
const ListeGroupeRefact = () => {
  const addGroupe = () => {
    history.push("/ajout-groupe");
  };
  return (
    <div className="p-b-200">
      <div className="row">
        <div className="col-lg-9">
          <div className="row m-b-15">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600 px-3">
                {" "}
                Gestion des Groupes
              </h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10"></div>

            <div className="col-md-2">
              <button
                className="btn-add-with-icon-plus-circle offset"
                onClick={addGroupe}
              >
                Ajouter
                <Icon className="icon-add ml-2" icon="plus-circle" />
              </button>
            </div>
          </div>
          <div className="row px-lg-0 px-md-2">
            <ListeGroupesAdminRefact />
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
        </div>
      </div>
    </div>
  );
};

export default ListeGroupeRefact;
