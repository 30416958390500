import React, {Component} from 'react';
import './tableBakelisteNiveau.css';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import API from "../../variablesGlobales";
import Swal from 'sweetalert2';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableBakelisteNiveauActions from "../../store/tableBakelisteNiveau/actions";


class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <i className="fas fa-pen icon-btn-icon-table"> </i>
        </button>
        
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={18} />
        </button>
      </div>
    );
  }
}

// function uploadFormatter(cell, row) {
//   return (
//     <Actions active={ cell } />
//   );
// }


export default class tableBakelisteNiveau extends Component {
    constructor(props) {
        super(props);
        this.state = {
          admin: '',
          coach: '',
          bakeliste: [],
          domaines:[],
          bakelisteID: 0,
          api: API.API,
          BakelisteFullname: "",
          BakelisteEmail: "",
          nbreBakeliste: 0,
          isEmptyData: false,
          completedInProgress: false,
          getBakelisteRequestError: false,
          completedFormationRequestError: false,
          BakelisteDomaine: "",
          stageFormationValue: false,
          firstNameValue: "",
          lastNameValue: "",
          typeStage:"",
          loading: true,
        };
    }
    

    componentDidMount() {
      this.getListeAllBakeliste();
      this.getNombreBakeliste();
    }

    handleShowAndHideAlert(label) {
      setTimeout(() => {
        
          if (label === 'successCompletedFormation') {
              this.setState({
                  successCompletedFormationAlert: false
              })
          }
          if (label === 'errorCompletedFormation') {
              this.setState({
                  completedFormationRequestError: false
              })
          }
        
          
      }, 5000);
    }

    getNombreBakeliste = () =>{
      axios.get(this.state.api + 'bakelistes-by-coach/' + this.state.coach_id)
          .then((response) => {
              if (response.data.data !== undefined) {
                  const nbreBakeliste = response.data.count;
                  // console.log('nombre bakeliste', response.data.count);
                  this.setState({
                      nbreBakeliste: nbreBakeliste
                  });
              } else {
                  this.setState({
                      nbreBakeliste: 0
                  })
              }
          })
          .catch(error => {
              if (process.env.NODE_ENV !== 'production') {
                  return;
              }
             // Sentry.captureException(error);
          })
    }

    handleCompletedFormation = (bakeliste) => {
      this.setState({
          completedInProgress: true
      });
    
      let bakelisteID = bakeliste.id;
      let data = {};
    
      axios.post(this.state.api + 'completed-bakelistes-formation/' + bakelisteID, data)
          .then(response => {
              this.setState({
                  completedInProgress: false
              });
    
              if (response.data.success === true) {
                  // console.log('okay')
                //  this.getAllbakeliste();
                  this.getNombreBakeliste();
                  this.setState({
                      successCompletedFormationAlert: true
                  })
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Formation arrêtée avec succèe',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.handleShowAndHideAlert('successCompletedFormation')
              } else {
                  // console.log(response);
                  this.setState({
                      completedFormationRequestError: true,
                  })
                  this.handleShowAndHideAlert('errorCompletedFormation')
              }
          })
          .catch(error => {
              console.log(error)
              this.setState({
                  completedFormationRequestError: true,
                  completedInProgress: false
              })
              this.handleShowAndHideAlert('errorCompletedFormation')
              if (process.env.NODE_ENV !== 'production') {
                  return;
              }
            //  Sentry.captureException(error);
          })
    }

    getListeAllBakeliste = () => {
      axios
        .get(this.state.api + 'liste-tous-les-bakelistes')
        .then((res) => {
          if (res.data.success === true) {
            // console.log('All Bakelistes', res.data.data)
    
            this.setState({
              bakeliste: res.data.data,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              isEmptyData: true,
            });
            //console.log(res);
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            getBakelisteRequestError: true,
          });
          console.log(error.message);
        });
    };

    
    BakelisteNiveau = (Column, row) => {
      return(
        <div className="la-taille-div">
          {row.statut === "stage" ?
            (
            <span className="stage-color">Formation</span>
            )
                :
            (
            <span className="stage-color"> Payante</span>
            )
          }
        </div>
      )
}
BakelisteNiveauFormation = (Column, row) => {
  return(
    <div className="la-taille-div">
      {row.type_formation === "type_formation" ?
        (
        <span className="admin-color">Diplomé</span>
        )
            :
        (
        <span className="coach-color">courte</span>
        
        )
      }
    </div>
  )
}
CoachNiveau = (Column, row) => {
  return(
    <div className="">
      {row.coach === "Makhtar" ?
        (
        <span className="">Makhtar</span>
        )
            :
        (
        <button className="btn-icon-user-plus">  
          <FeatherIcon className="icon-btn-icon-user-plus" icon="user-plus" size={16} />
      </button>
        )
      }
    </div>
  )
}
CustomExportCsvBtn = (onClick) =>{
  return(
    <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
  );
}

actionsFormatter = (cell, row) => {
  // console.log(row.id)
  let id = row.id
  
  return (
    
    <span>

      <Link
          to={{
            pathname: "/profil-bakeliste",
            state: { id: row.id, data: row },
          }}
        >
          <Tooltip title="Voir détails">

            <VisibilityIcon  className="btn-icon-table"
          cursor="pointer" />
          </Tooltip>
        </Link> 
        <Link 
          to={{
            pathname: "/edit-bakeliste",
            state: { id: row.id, data: row},
          }}
        >
          <EditIcon className="btn-icon-table" />
        </Link>     
        <button type="button" className="btn btn-danger terminer-formation" 
                id="completed" title='términer la formation'
                    onClick={e => window.confirm("Êtes-vous sûr de vouloir arrêter la formation de ce bakeliste ?") &&
                        this.handleCompletedFormation(row)}
                >
                    {this.state.completedInProgress ?
                        <i className="fas fa-spin fa-spinner"></i> :
                        <i className="fas fa-minus"></i>
                    }

                    

          </button>     
      <div
          className="modal fade"
          id={`showInfosModal${row.id}`}
          role="dialog"
          aria-labelledby="showInfosModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <h4 className="modal-title" >
                  Details sur {row.bakelisteFullname}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close" 
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              
              </div>
              <div className="modal-body">
                <div className="bakelisteInfos-container">
                  <div className="fullname_container">
                    <span className="item_label">Prénom et Nom : </span>{" "}
                    <span className="label_content">
                      {row.bakelisteFullname}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="email_container">
                    <span className="item_label">Email : </span>{" "}
                    <span className="label_content">
                      {row.email}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="phone_container">
                    <span className="item_label">Ecole : </span>{" "}
                    <span className="label_content">
                      {row.ecole}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="phone_container">
                    <span className="item_label">Téléphone : </span>{" "}
                    <span className="label_content">
                      {row.phone}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="domaine_container">
                    <span className="item_label">Domaine de compétence : </span>{" "}
                    <span className="label_content">
                      {row.domaine}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="durée_container">
                    <span className="item_label">Durée de la formation : </span>{" "}
                    du{" "}
                    <span className="label_content">
                      {row.startFrenchDateFormat}
                    </span>{" "}
                    au{" "}
                    <span className="label_content">
                      {row.endFrenchDateFormat}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="durée_container">
                    <span className="item_label">Coach : </span>{" "}
                    <span className="label_content">
                      {row.coachFullname}
                    </span>
                  </div>{" "}
                  <hr />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
        
     </span>
  );
}
render() {
  const options = {
    exportCSVBtn : this.CustomExportCsvBtn,
  };      return (
              <div className="component-table-bakeliste-niveau">
                <BootstrapTable 
                  pagination
                  data={this.state.bakeliste} 
                  options={options}
                  exportCSV
                  csvFileName="utilisateurs.csv"
                  // data={gestionTacheForCoach} 
                  
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                    <TableHeaderColumn dataField="id" isKey={true} hidden={true}>{" "} ID </TableHeaderColumn>
                    
                  <TableHeaderColumn 
                    
                    
                    dataField='bakelisteFullname' 
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '12px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Nom Complet</span> 
                    
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                    dataField='domaine' width="100"                     
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1.2rem','font-size': '12px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Domaine</span>
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                      dataField='type_formation' width="100" 
                      dataFormat={this.BakelisteNiveauFormation}
                      
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Formation</span>
                     </TableHeaderColumn>
                     <TableHeaderColumn 
                      dataField='duree' width="100" 
                      dataFormat={this.BakelisteNiveau}
                      bakeliste_level
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Stage</span>
                     </TableHeaderColumn>
                     <TableHeaderColumn 
                      dataField='bakeliste_level' width="100" 
                     
                      
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '12px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Niveau</span>
                     </TableHeaderColumn>

                     <TableHeaderColumn 
                      dataField='coachFullname' width="70" 
                      dataFormat={this.CoachNiveau}
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Coach</span>
                     </TableHeaderColumn>
                  <TableHeaderColumn 
                  dataField='' 
                  width="150"
                  dataFormat={this.actionsFormatter} 
                  
                  thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                  tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '12px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs"> Actions</span>
                    </TableHeaderColumn>
                </BootstrapTable>

                </div>
        );
    }
  }
// export default connect(
//     ({ tableBakelisteNiveau }) => ({ ...tableBakelisteNiveau }),
//     dispatch => bindActionCreators({ ...tableBakelisteNiveauActions }, dispatch)
//   )( tableBakelisteNiveau );