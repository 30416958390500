import { toast } from "react-toastify";
toast.configure();

const successMsg = (message) => {
    toast.success(
        message,
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 500 }
      );
}


export default successMsg