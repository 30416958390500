import React, {Component} from 'react';
import './galleryLivraison.css';
// import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';

const IMAGES =
  [
  //{
  //   src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
  //   thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
  //   width: "100%",
  //   thumbnailHeight: 500,
  //   caption: "After Rain (Jeshu John - designerspics.com)"
  // },
  
 // {
  //  src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
  //  thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
  //  thumbnailHeight: 212
  //},
 // {
  //  src: "https://c6.staticflickr.com/9/8893/28897116141_641b88e342_b.jpg",
  //  thumbnail: "https://c6.staticflickr.com/9/8893/28897116141_641b88e342_n.jpg",
  //  thumbnailHeight: 215,
  //  tags: [{value: "People", title: "People"}],
   // caption: "Untitled (moveast.me)"
  //},
 // {
  //  src: "https://c1.staticflickr.com/9/8056/28354485944_148d6a5fc1_b.jpg",
  //  thumbnail: "https://c1.staticflickr.com/9/8056/28354485944_148d6a5fc1_n.jpg",
  //  thumbnailHeight: 320,
  //  caption: "A photo by 贝莉儿 NG. (unsplash.com)"
  //},
 // {
  //  src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
   // thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
  //  thumbnailHeight: 212,
  //  tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
  //  caption: "Boats (Jeshu John - designerspics.com)"
  //},

  {
    src: "https://c7.staticflickr.com/9/8824/28868764222_19f3b30773_b.jpg",
    thumbnail: "https://c7.staticflickr.com/9/8824/28868764222_19f3b30773_n.jpg",
    thumbnailHeight: 320,
    caption: "A photo by Matthew Wiebe. (unsplash.com)"
  }]

export default class galleryLivraison extends Component {
  constructor(props){
    super(props);

    this.state = {
      
    };

  }

  render() {
    return  <div>
              <Gallery images={IMAGES}/>
            </div>;
  }
}

