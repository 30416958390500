import React, {Component} from 'react';
import './tableMyParticipantsView.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
// import MyParticipants from '../myParticipants/index';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyParticipantsViewActions from "../../store/tableMyParticipantsView/actions";
export default class tableMyParticipantsView extends Component {
    // constructor(props) {
    //     super(props); 
    //     this.state = {};
    // }
    render() {
      return (
              <div className="component-table-my-participants-view body-theme">
                  <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      {/* <MyParticipants /> */}
                    </div>
                  </div>
                </div>
              </div>
       );
    }
  }
// export default connect(
//     ({ tableMyParticipantsView }) => ({ ...tableMyParticipantsView }),
//     dispatch => bindActionCreators({ ...tableMyParticipantsViewActions }, dispatch)
//   )( tableMyParticipantsView );