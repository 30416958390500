import React, {Component} from 'react';
import './addDomaine.css';
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from 'rsuite';
import FeatherIcon from 'feather-icons-react';
import data from '../dataTesting.json';
import API from "../../variablesGlobales";
import axios from 'axios';
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export default class addDomaine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      api: API.API,
      loading: false,
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  

addDomaines = (e) => {
  e.preventDefault();
  // console.log('ddddddd');  
  this.setState({
    loading: true,
  });
  var fd = new FormData();
    fd.append("description", this.state.description);
    fd.append("name", this.state.name);
    fd.append("status", this.state.status);
axios
.post(this.state.api + "domaines", fd ,{
  Headers: { "Content-Type": "multipart/formdata" },
})
.then((res) => {
  // console.log('domaie', res);

  if (res.data.success) {
    this.setState({
      loading: false,
    });
    $(".close-modal-bakeliste-title").click();
    this.props.getAllDomaines();
    toast.success(
      "Domaine ajouté avec success",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
    // window.location = "/domaine-admin";
  } else {
    toast.error(
      "erreur sur l ajout",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 3000 }
    );
  }
})
.catch((error) => {
  // console.log(error.message);
  this.setState({
    loading: false,
  });
});
};
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  render() {
    return  <div className="modal-container ml-md-auto">
              <button className="btn-add-with-icon-plus-circle" onClick={this.open}>
                Ajouter
                <Icon className="icon-add ml-2" icon="plus-circle" />
              </button>
              <Modal className="modal-responsive-sm p-b-50" show={this.state.show} onHide={this.close}>
              <h5 className="modal-bakeliste-title m-b-50">Créer un nouveau domaine</h5>
              <button className="close-modal-bakeliste-title" onClick={this.close}>
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              <form 
                onSubmit={(e) => this.addDomaines(e)}
                id="domaine_form"
                className="mt-5"
                >
                <div className="form-row row">    
                  <div className="col-md-6">
                    <div className="form-group ">
                      <input
                        type="name"
                        className="form-control ab1 si-form-input"
                        id="inputName"
                        placeholder="Name"
                        name="name"                       
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        required
                      />               
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group"> 
                      <select
                        name="status"
                        className="form-control ab1 si-form-input"
                        id="statusInput"
                        onChange={(e) =>
                          this.setState({ status: e.target.value })
                        }
                        required
                      >
                        <option>
                          {" "}
                          *Choisir un statut --{" "}
                        </option>
                        <option value="Actif"> Actifs </option>
                        <option value="Non-actif"> Non-actif </option>
                      </select>
                     
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <textarea
                        className="form-control ab1 si-form-input"
                        id="inputDescription"
                        placeholder="description"
                        name="description"
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
               </div>
               <div className="col-md-6  offset-6 submit-btn-container">
                    <div className="form-group ">
                      {!this.state.loading ? (
                        <button className="btn btn-theme">
                          <i className="fas fa-plus-circle"></i> &nbsp; Ajouter
                        </button>
                      ) : (
                        <button className="btn btn-theme loading-btn">
                          Envoie en cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                  </form>
              </Modal>
            </div>;
  }
}
// export default connect(
//     ({ addSyllabus }) => ({ ...addSyllabus }),
//     dispatch => bindActionCreators({ ...addSyllabusActions }, dispatch)
//   )( addSyllabus );