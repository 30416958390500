import React, { Component } from "react";
import "./dashboardAdmin.css";
import FeatherIcon from "feather-icons-react";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import AddChiffres from "../addChiffres/index";
import StatisticsAdmin from "../statisticsAdmin/index";
import StatisticsPosition from "../statisticsPosition/index";
import StatisticsProgress from "../statisticsProgress/index";
import StatisticsEvents from "../statisticsEvents/index";
import TableDebutant from "../tableDebutant/index";
import { Link } from "react-router-dom";
import API from "../../variablesGlobales";
import { Icon } from "rsuite";
import Skeleton from "react-loading-skeleton";

export default class dashboardAdminSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-9">
            <nav>
              <ol className="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
              </ol>
            </nav>

            <div className="row mb-3 right-elements">
              <div className="ml-auto mb-3">
                <button className="btn-add-with-icon-plus-circle">
                  <Skeleton width={`30vh`} height={`8vh`} />
                </button>
              </div>
              <div className="ml-auto">
                <Skeleton width={`20vh`} height={`7vh`} />
              </div>
            </div>
            <h6 className="titre_utilisateurs m-t-10">Utilisateurs</h6>
            <div className="row mt-5">
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
            </div>
            <h6 className="titre_lieux m-t-10">Lieux</h6>
            <div className="row mt-5">
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
            </div>
            <h6 className="titre_diplomes m-t-10">Diplômes</h6>
            <div className="row mt-5">
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
            </div>
            <h6 className="titre_evenements m-t-10">Evenements</h6>
            <div className="row mt-5">
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="item-statistics-dashboard ">
                  <Skeleton width={`100%`} height={`20vh`} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <h6 className="h-theme ff-inter fw-600 px-3 fs-20">
                  Listes des Bakelistes par niveau
                </h6>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <button
                  className="btn-add-with-icon-plus-circle btn-vert-add"
                  onClick={this.ajouterBakeliste}
                >
                  Ajouter un bakeliste
                  <Icon className="icon-add-chiffres ml-2" icon="plus-circle" />
                </button>
              </div>
            </div>

            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                  <h5 className="h-theme ff-inter fw-500">Debutant</h5>
                </div>
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>
              <TableDebutant />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
