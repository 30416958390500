import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  archiveAdmin,
  getAdminsAsync,
  isFetchingAdmins,
  isLoadingAdmins,
  showListAdmins,
} from "../../redux/slices/adminSlices/adminSlice";
import { Tooltip } from "@material-ui/core";
import FeatherIcon from "feather-icons-react";
import { Link, NavLink } from "react-router-dom";
import { Icon } from "rsuite";

import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import $ from "jquery";
import SkeletonAllBakeliste from "../tableMyAllbakelistes/skeletonAllBakeliste";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const TableListeAdmin = () => {
  // Redux
  const dispatch = useDispatch();

  const isFetchingReduxData = useSelector(isLoadingAdmins);
  const adminReduxData = useSelector(showListAdmins);

  React.useEffect(() => {
    adminReduxData?.length === 0 &&
      dispatch(getAdminsAsync()) &&
      dispatch(isFetchingAdmins());
  }, []);

  const archiveAdminRedux = (id) => {
    dispatch(archiveAdmin(id));
  };

  const actionFormatter = (cell, row) => {
    return (
      <span>
        &nbsp;&nbsp;
        <Tooltip title="Archivez admin">
          <FeatherIcon
            icon="archive"
            id="delete_group"
            style={{
              color: "red",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            onClick={() => {
              archiveAdminRedux(row.id);
            }}
            className="ajouter"
            cursor="pointer"
          />
        </Tooltip>
      </span>
    );
  };

  // const options = {
  //   exportCSVBtn: CustomExportCsvBtn,
  // };

  return (
    <div className="p-b-200">
      <div className="container-fluid px-0">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav">
                Dashboard /{" "}
              </Link>
            </li>
            <li
              className="breadcrumb-item active lien_nav_curren"
              aria-current="page"
            >
              Admins
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row m-b-15">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                Listes des Admins
              </h6>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 ml-auto vla">
              <NavLink className="nav-link" to="/liste-admins-archives">
                <button className="btn btn-theme-orange right-elements ">
                  Voir liste admins archivés
                </button>
              </NavLink>
            </div>
          </div>
          <div className="card-dashboard m-b-15 p-b-60">
            {isFetchingReduxData && <SkeletonAllBakeliste />}
            <div className="row m-b-30 m-t-20">
              <div className="col-md-2 ml-auto mb-2">
                <NavLink
                  className="nav-link btn-theme right-elements"
                  to="/ajout-admin"
                >
                  Ajouter
                  <Icon className="ml-2" icon="plus-circle" />
                </NavLink>
              </div>
              <BootstrapTable
                data={adminReduxData}
                striped={true}
                hover={true}
                //   options={options}
                search
                searchPlaceholder="Rechercher coach..."
                multiColumnSearch
                exportCSV
                csvFileName="listes-coachs.csv"
                multiColumnSort={2}
                pagination
                bordered={true}
                bodyStyle={{
                  fontSize: 12,
                  color: "rgba(0, 0, 0, 0.7)",
                  fontFamily: "inherit",
                  fontWeight: 400,
                }}
                tdStyle={{ background: "red" }}
                tableHeaderclassName="header-table-groupe-view"
                wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                className="table-responsive table-responsive-sm"
              >
                <TableHeaderColumn
                  className="titre-tab"
                  isKey
                  filter={{
                    type: "TextFilter",
                    delay: 1000,
                    placeholder: "Recherche  ",
                  }}
                  dataField="first_name"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                  //   width="250"
                >
                  <span className="color-nom-prenom-utilisateurs">Prénom</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  className="titre-tab"
                  filter={{
                    type: "TextFilter",
                    delay: 1000,
                    placeholder: "Recherche  ",
                  }}
                  dataField="last_name"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                  //   width="250"
                >
                  <span className="color-nom-prenom-utilisateurs">Nom</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  className="titre-tab"
                  filter={{
                    type: "TextFilter",
                    delay: 1000,
                    placeholder: "Recherche  ",
                  }}
                  thStyle={{
                    border: "none",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                  tdStyle={{
                    border: "none",
                    textAlign: "center",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                  //   width="250"
                >
                  <span className="color-nom-prenom-utilisateurs">Email</span>
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="phone"
                  className="titre-tab"
                  filter={{
                    type: "TextFilter",
                    delay: 1000,
                    placeholder: "Recherche  ",
                  }}
                  thStyle={{
                    border: "none",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                  tdStyle={{
                    border: "none",
                    textAlign: "center",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs">Numéro</span>
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="action"
                  dataFormat={actionFormatter}
                  className="titre-tab"
                  thStyle={{
                    border: "none",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                  tdStyle={{
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                    padding: "1.2rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs">
                    {" "}
                    Actions
                  </span>
                </TableHeaderColumn>
              </BootstrapTable>

              <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableListeAdmin;
