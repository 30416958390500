import React, { Component } from "react";
import "./dashboardAdmin.css";
import FeatherIcon from "feather-icons-react";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import AddChiffres from "../addChiffres/index";
import StatisticsAdmin from "../statisticsAdmin/index";
import StatisticsPosition from "../statisticsPosition/index";
import StatisticsProgress from "../statisticsProgress/index";
import StatisticsEvents from "../statisticsEvents/index";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import TableDebutant from "../tableDebutant/index";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import amplitude from "amplitude-js";
import * as Sentry from "@sentry/react";
import { Icon } from "rsuite";
import DashboardAdminSkeleton from "./dashboardAdminSkeleton";
import $ from "jquery";

export default class dashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      nbreBakeliste: 0,
      nbreBakelisteBoursier: 0,
      nbreBakelistePayant: 0,
      nbrebakelistesThies: 0,
      nbrebakelistesTamba: 0,
      nbreBakelisteISA: 0,
      nbreBakelisteStageDF: 0,
      allUser: 0,
      allBakeliste: 0,
      allVisiteur: 0,
      allParticipants: 0,
      allEvents: 0,
      isLoaded: true,
      allAdmin: 0,
      allCoach: 0,
      allCoachAss: 0,
    };
  }
  componentDidMount() {
    this.getNombreBakeliste();
    this.getCountUserByStatus();
  }
  ajouterBakeliste() {
    window.location = "/ajouter-bakeliste";
  }
  getNombreBakeliste() {
    axios
      .get(this.state.api + "nbre-bakeliste")
      .then((response) => {
        const nbreBakeliste = response.data.data1;
        const nbreBakelisteBoursier = response.data.data2;
        const nbreBakelistePayant = response.data.data3;
        const nbrebakelistesVF = response.data.data4;
        const nbrebakelistesGY1 = response.data.data5;
        const nbrebakelistesGY2 = response.data.data6;
        const nbrebakelistesThies = response.data.data7;
        const nbrebakelistesTamba = response.data.data8;
        const nbreBakelisteISA = response.data.data9;
        const nbreBakelisteStageDF = response.data.data10;
        const nbreBakelisteDemiBoursier = response.data.data11;
        const nbreBakelisteFormationDiplomante = response.data.data12;
        const nbreBakelisteFormationCourte = response.data.data13;

        //console.log("nombre bakeliste", response);
        this.setState({
          nbreBakeliste: nbreBakeliste,
          nbreBakelisteBoursier: nbreBakelisteBoursier,
          nbreBakelistePayant: nbreBakelistePayant,
          nbrebakelistesVF: nbrebakelistesVF,
          nbrebakelistesGY2: nbrebakelistesGY2,
          nbrebakelistesGY1: nbrebakelistesGY1,
          nbrebakelistesThies: nbrebakelistesThies,
          nbrebakelistesTamba: nbrebakelistesTamba,
          nbreBakelisteISA: nbreBakelisteISA,
          nbreBakelisteStageDF: nbreBakelisteStageDF,
          nbreBakelisteDemiBoursier: nbreBakelisteDemiBoursier,
          nbreBakelisteFormationDiplomante: nbreBakelisteFormationDiplomante,
          nbreBakelisteFormationCourte: nbreBakelisteFormationCourte,
        });
        // setTimeout(
        //   function () {
        this.setState({ isLoaded: false });
        $("#start-hidden").removeClass("start-hidden");
        //   }.bind(this),
        //   5000
        // );
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCountUserByStatus() {
    axios
      .get(this.state.api + "count-users-by-status")
      .then((response) => {
        // console.log("tetttt", response);
        this.setState({
          allUser: response.data.allUser,
          allBakeliste: response.data.nbreBakeliste,
          allVisiteur: response.data.nbreVisiteur,
          allParticipants: response.data.nbreparticipants,
          allEvents: response.data.allEvents,
          allCoach: response.data.nbreCoach,
          allCoachAss: response.data.nbreCoachAss,
          allAdmin: response.data.nbreAdmin,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="p-b-200">
        {this.state.isLoaded && <DashboardAdminSkeleton />}

        <div className="row start-hidden" id="start-hidden">
          <div className="col-lg-9">
            <nav>
              <ol className="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
              </ol>
            </nav>

            <div className="row mb-3 right-elements">
              <div className="ml-auto mb-3">
                <button className="btn-add-with-icon-plus-circle btn-vert-add ">
                  <Link
                    to="/ajouter-bakeliste"
                    className="lien_nav lienstatistique"
                  >
                    Nouveau Bakeliste{" "}
                    <Icon
                      className="icon-add-chiffres ml-2"
                      icon="plus-circle"
                    />
                  </Link>
                </button>{" "}
              </div>
              <div className="ml-auto">
                <Link
                  to="/statistique-admin"
                  className="lien_nav lienstatistique"
                >
                  Statistique
                  <Icon className="icon-statistique ml-2" icon="signal" />
                </Link>
              </div>
            </div>
            <h6 className="titre_utilisateurs m-t-10">Utilisateurs</h6>
            <StatisticsAdmin
              bakelistes={this.state.allBakeliste}
              users={this.state.allUser}
              visiteurs={this.state.allVisiteur}
              participants={this.state.allParticipants}
              coaches={this.state.allCoach}
              coach_ass={this.state.allCoachAss}
              admins={this.state.allAdmin}
            />
            <h6 className="titre_lieux m-t-10">Lieux</h6>
            <StatisticsPosition
              Thies={this.state.nbrebakelistesThies}
              s
              GY1={this.state.nbrebakelistesGY1}
              GY2={this.state.nbrebakelistesGY2}
            />
            <h6 className="titre_diplomes m-t-10">Diplômes</h6>
            <StatisticsProgress
              Diplomante={this.state.nbreBakelisteFormationDiplomante}
              Courte={this.state.nbreBakelisteFormationCourte}
            />
            <h6 className="titre_evenements m-t-10">Evenements</h6>
            <StatisticsEvents
              participants={this.state.allParticipants}
              allEvents={this.state.allEvents}
            />
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <h6 className="h-theme ff-inter fw-600 px-3 fs-20">
                  Listes des Bakelistes
                </h6>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <button
                  className="btn-add-with-icon-plus-circle btn-vert-add"
                  onClick={this.ajouterBakeliste}
                >
                  Ajouter un bakeliste
                  <Icon className="icon-add-chiffres ml-2" icon="plus-circle" />
                </button>
              </div>
            </div>
            {/* 
              <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                      <a class="nav-link" href="dashboard-admin">
                        Débutant<span class="sr-only">(current)</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link ml-5" href="intermediaire-admin">
                        Intermédiaire
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link ml-5" href="diplome-admin">
                        Diplômé
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              */}
            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                  <h5 className="h-theme ff-inter fw-500">Debutant</h5>
                </div>
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>
              <TableDebutant />
            </div>
          </div>

          {/*<div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
 
          <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

                  <BakelisteLateItemForAdmin />
                </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

                  <BakelisteMissingItemForAdmin />
                </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

                  <ProgramReunionCoach />
                </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

                  <SavedReunionListItem />
                </div>
          </div>

        </div> */}
        </div>
      </div>
      // <div className="p-b-200">
      //   <div className="container-fluid px-0">
      //     <div className="row">
      //       <div className="col-lg-9">
      //         <nav aria-label="breadcrumb">
      //           <ol className="breadcrumb">
      //             <li className="breadcrumb-item">
      //               <Link to="" className="lien_nav">
      //                 Home /{" "}
      //               </Link>
      //             </li>
      //             <li
      //               className="breadcrumb-item active"
      //               aria-current="page"
      //               className="lien_nav_current"
      //             >
      //               {" "}
      //               Dashboard
      //             </li>
      //           </ol>
      //         </nav>
      //         <div className="row m-b-15">
      //           <div className="col-xl-6">
      //             <h6 className="h-theme ff-inter fw-600 m-t-5">Chiffres</h6>
      //           </div>
      //           <div className="col-xl-6 dis-flex">
      //             <AddChiffres />
      //           </div>
      //         </div>
      //         <div className="row m-b-15">
      //           <div className="col-xl-6">
      //             <h6 className="text m-t-10">Utilisateurs</h6>
      //           </div>
      //           <div className="col-xl-6">
      //             <Link
      //               to="/statistique-admin"
      //               className="lien_nav lienstatistique"
      //             >
      //               Statistique
      //               <Icon className="icon-statistique ml-2" icon="signal" />
      //             </Link>
      //           </div>
      //         </div>
      //         <StatisticsAdmin
      //           Bakeliste={this.state.nbreBakeliste}
      //           Stagiaires={this.state.nbreBakelisteStageDF}
      //         />
      //         <h6 className="text1">Lieux</h6>
      //         <StatisticsPosition
      //           Thies={this.state.nbrebakelistesThies}
      //           GY1={this.state.nbrebakelistesGY1}
      //           GY2={this.state.nbrebakelistesGY2}
      //         />
      //         <h6 className="text2">Diplôme</h6>
      //         <StatisticsProgress
      //           Diplomante={this.state.nbreBakelisteFormationDiplomante}
      //           Courte={this.state.nbreBakelisteFormationCourte}
      //         />
      //         <h6 className="text3">Evenement</h6>
      //         <StatisticsEvents />
      //         <div className="row">
      //           <div className="col-xl-6 col-lg-6 col-md-6">
      //             <h6 className="h-theme ff-inter fw-600 px-3">
      //               Listes des Bakelistes par niveau
      //             </h6>
      //           </div>
      //           <button
      //             className="btn-add-with-icon-plus-circle"
      //             onClick={this.ajouterBakeliste}
      //           >
      //             Ajouter un bakeliste
      //             <Icon className="icon-add ml-2" icon="plus-circle" />
      //           </button>
      //         </div>

      //         <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
      //           <div
      //             class="collapse navbar-collapse"
      //             id="navbarSupportedContent"
      //           >
      //             <ul class="navbar-nav mr-auto">
      //               <li class="nav-item active">
      //                 <a class="nav-link" href="dashboard-admin">
      //                   Débutant<span class="sr-only">(current)</span>
      //                 </a>
      //               </li>
      //               <li class="nav-item">
      //                 <a class="nav-link ml-5" href="intermediaire-admin">
      //                   Intermédiaire
      //                 </a>
      //               </li>
      //               <li class="nav-item">
      //                 <a class="nav-link ml-5" href="diplome-admin">
      //                   Diplômé
      //                 </a>
      //               </li>
      //             </ul>
      //           </div>
      //         </nav>
      //         <div className="card-dashboard m-b-15 p-b-60">
      //           <div className="row m-b-30 m-t-20">
      //             <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
      //               <h5 className="h-theme ff-inter fw-500">Debutant</h5>
      //             </div>
      //             <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
      //           </div>
      //           <TableDebutant />
      //         </div>
      //       </div>
      //       <div className="col-xl-3 col-lg-3 col-md-3 ml-lg-auto pl-xl-3 pl-lg-0">
      //         <div className="row">
      //           <div className="col-xl-12 col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
      //             <BakelisteLateItemForAdmin />
      //           </div>
      //           <div className="col-xl-12 col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
      //             <BakelisteMissingItemForAdmin />
      //           </div>
      //           <div className="col-xl-12 col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
      //             <ProgramReunionCoach />
      //           </div>
      //           <div className="col-xl-12 col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
      //             <SavedReunionListItem />
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
