import React, { Component } from "react";
import "./dossierCandidatureView.css";
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import ListProspects from '../listProspects/index';
import API from "../../variablesGlobales";
import axios from "axios";
import DossierCandidature from "../dossierCandidature";
import qs from "qs";
// import * as dossierCandidatureViewActions from "../../store/dossierCandidatureView/actions";
export default class dossierCandidatureView extends Component {
  constructor(props) {
    super(props);
    // const id= this.props.location.state.id;
    let id = this.props.location?.state?.id;
    if (!id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      id = parseInt(obj.id);
    } 
    this.state = {
      dossier: [],
      id: id,
      api: API.API,

    };
}

componentDidMount() {
  this.getCandidaturById();

}



getCandidaturById = () => {
axios
  .get(this.state.api + "prospects/" + this.state.id)
  .then((response) => {
    this.setState({
      dossier: response.data.data,
    });
    // console.log("dddrrfff", this.state.dossier)
  })
  .catch((error) => {
    console.log(error.message);
    if (process.env.NODE_ENV !== "production") {
      return;
    }
  });
};
  render() {
    return (
    <div className="component-table-my-participants-view body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <DossierCandidature 
              prospect_id={this.state.dossier.id}
               motivation={this.state.dossier.motivations}
               phone={this.state.dossier.phone}
               domaineId={this.state.dossier.study_choice}
               type_formation={this.state.dossier.type_formation}
               duree_formation={this.state.dossier.duree_formation}
               adresse={this.state.dossier.ville_residence}
               firstName={this.state.dossier.firstName}
               lastName={this.state.dossier.lastName}
               level={this.state.dossier.level}
               email={this.state.dossier.email}
               ispro={this.state.dossier.ispro}
               study_choice={this.state.dossier.study_choice}

            />
          </div>
        </div>
      </div>
    </div>);
  }
}
