import React, { useReducer } from "react";
import "./syllabusItem.css";
import ImageSyllabus from "../../img/syllabus.jpg";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

const SyllabusItemRefact = (props) => {

    const [state,setState] = useReducer(
        (state,newState)=>({...state,...newState}),{
            state : {}
        }
    )
    let id = props.syllabus_id;

  return (
    <div className="container-syllabus-item">
      <div className="syllabus-item">
        {/* !!!!!!!!Important!!!!!!!!!!! */}
        {/* etiquette pour le débutant  */}

        <p /* className="etiquette-syllabus-item " */
          className={
            "etiquette-syllabus-item " +
            (props.niveau === "débutant"
              ? " bg-debutant"
              : props.niveau === "intermédiaire"
              ? "bg-intermediaire"
              : props.niveau === "avancé"
              ? "bg-avance"
              : "")
          }
        >
          {props.niveau}
        </p>
        {/* etiquette pour le confirmé  */}
        {/* <p className="etiquette-syllabus-item confirme">confirmé</p> */}
        <img
          src={ImageSyllabus}
          alt="Avatar"
          className="image-syllabus-item"
          style={{ width: "100%" }}
        />
        <div className="content-syllabus-item">
          <p className="titre-content-syllabus-item">{props.name}</p>
          <div className="row">
            <div className="col-xl-4 col-lg-5 pr-lg-0 col-6 pr-0">
              <p className="info-content-syllabus-item">
                <FeatherIcon
                  className="icon-content-syllabus-item"
                  icon="list"
                />{" "}
                {props.nbreTache} tâches
              </p>
            </div>
            {/* <div className="col-xl-4 pl-xl-0 col-6 pr-0">
            <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/> 3 semaines</p>
          </div> */}
            <div className="col-xl-4 pl-xl-0 col-6 pr-0">
              <p className="info-content-syllabus-item">
                <FeatherIcon
                  className="icon-content-syllabus-item"
                  icon="user"
                />{" "}
                {props.nbreBakeliste} bakelistes
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-syllabus-item-back">
        {/* <NavLink className="link-details-container-syllabus-item-back" to={'/syllabus-details-bakeliste/'+props.syllabus_id} > */}

        <Link
          className="link-details-container-syllabus-item-back"
          to={{
            pathname: "/details-syllabus",
            state: { id: id },
          }}
        >
          <div className="height-container-syllabus-item-back">
            <p className="titre-container-syllabus-item-back">Description</p>
            <p className="content-container-syllabus-item-back">
              {props.description}
            </p>

            <p className="titre-container-syllabus-item-back mt-xl-5 mt-lg-2 mt-md-2 mt-2">
              Outils et Technologies
            </p>
            <p className="content-container-syllabus-item-back">
              {props.outils.map((item, index) => (
                <span>{item.name}&nbsp;- </span>
              ))}
            </p>
            <p className="content-container-syllabus-item-back">
              {props.technologies.map((item, index) => (
                <span key={index}>{item.name}&nbsp;</span>
              ))}
            </p>
          </div>
        </Link>

        <div className="">
          <div className="row justify-content-end position-action-container-syllabus-item-back">
            <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
              <Link
                className="link-details-container-syllabus-item-back"
                to={{
                  pathname: "/details-syllabus",
                  state: { id: id },
                }}
              >
                <FeatherIcon
                  className="icon-details-container-syllabus-item-back"
                  icon="eye"
                />
                Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyllabusItemRefact;
