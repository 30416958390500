import React, {Component} from 'react';
import './profilAdminView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import ProfilAdmin from '../profilAdmin/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as profilAdminViewActions from "../../store/profilAdminView/actions";
export default class profilAdminView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
          <div className="component-profil-admin-view body-theme">
            <HeaderDashboardAdmin />
              <div className="container-fluid fixing-after-header">
                <div className="row">
                  <div className="col-lg-2 p-0">
                    <NavVerticalAdmin />
                  </div>
                  <div className="col-lg-10 pt-3">
                    <ProfilAdmin />
                  </div>
                </div>
              </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ profilAdminView }) => ({ ...profilAdminView }),
//     dispatch => bindActionCreators({ ...profilAdminViewActions }, dispatch)
//   )( profilAdminView );