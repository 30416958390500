import React, {Component} from 'react';
import './organiserReunion.css';
import {Link} from 'react-router-dom';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import FeatherIcon from 'feather-icons-react';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as organiserReunionActions from "../../store/organiserReunion/actions";
export default class organiserReunion extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
          <div className="component-organiser-reunion">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-lg-9">
                  <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to="/dashboard-admin" className="lien_nav">Dashboard / </Link></li>
                    <li className="breadcrumb-item" aria-current="page" className="lien_nav_current">Organiser une réunion</li>
                  </ol>
                  </nav>
                      <div className="container-fluid bg-white colorate-reunion-bg pt-2 mb-5">                       
                          <span className="comportement-sujet ml-4">Sujet</span>
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                            <input type="text" className="form-control form-inpt-bas mt-4" id="formGroupExampleInput" />
                          </div>
                        </div>

                        <div className="row mb-3">
                              <label for="inputPassword3" className="col-sm-3 col-form-label">Début:</label>
                                <div className="col-lg-6 col-sm-4"> 
                                
                                <select className="form-control form-select taille-des-input">
                                  <option selected>ven: Jan 24, 2020</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                                <div className="col-lg-2 col-sm-3"> 
                                  <select className="form-control form-select taille-des-input">
                                    <option selected>10.00</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                               
                            </div>

                            <div className="row mb-3">
                              <label for="inputPassword3" className="col-sm-3 col-form-label">Durée:</label>
                                <div className="col-lg-3 col-sm-4"> 
                                
                                <select className="form-control form-select taille-des-input">
                                  <option selected>1heure</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                                <div className="col-lg-3 col-sm-3"> 
                                  <select className="form-control form-select taille-des-input">
                                    <option selected>45 minutes</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                               
                            </div>
                            <div className="row mb-15">
                              <div className="col-sm-6">
                                <p className="ml-4"><input type="checkbox" className="tail-btn-check"/><span className="ml-5 form-txt-reunion">Réunion périodique</span></p>
                              </div>
                              <div className="col-sm-3 offset-3">
                                <select className="form-control enlevement-bordure-select-btn">
                                    <option selected>Fuseau horaire</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                              </div>
                              
                            </div>
                            <hr />
                            <div className="mt-2">
                              <span className="form-txt-id-reunion mt-5">ID de réunion</span>
                              
                              <div className="row">
                                <div className="col-sm-6">
                                <p className="mt-4"><input type="radio" className="tail-btn-check"/><span className="form-txt-reunion ml-4">Créé(e) automatiquement</span></p>
                                </div>
                                <div className="col-sm-6">
                                <p className="mt-4"><input type="radio" className="tail-btn-check"/><span className="form-txt-reunion ml-4">ID de réunion personnelle</span></p>

                                </div>
                              </div>
                            </div>

                            <div className="mt-5">
                              <span className="form-txt-id-reunion">Mot de passe</span>
                              <div className="row">
                                <div className="col-sm-6">
                                <p className="mt-4"><input type="checkbox" className="tail-btn-check" checked/><span className="ml-5 form-txt-reunion">Exiger un mot de passe de la réunion</span></p>
                                </div>
                                <div className="col-sm-6">
                                  <input type="time" step="" className="mt-3 form-inpt-timer" placeholder="00:00:00"/>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="mt-4">
                              <span className="form-txt-id-reunion">Vidéo</span>
                              <div className="row mt-4">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    
                                      <p className="form-txt-reunion" >Animateur : <input type="radio" className="tail-btn-check"/><span className="ml-2">Activé</span><span className="ml-2"><input type="radio" className="tail-btn-check"/></span><span className="ml-2">Désactivé</span></p>                                                                    
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">                                  
                                  <p className="form-txt-reunion" >Animateur : <input type="radio" className="tail-btn-check"/><span className="ml-2">Activé</span><span className="ml-2"><input type="radio"  className="tail-btn-check"/></span><span className="ml-2">Désactivé</span></p>                                                                    
                                    </div>                                   
                                     
                                  </div>
                              </div>
                              <hr className="mt-5"/>
                            <div className="mt-4 mb-5">
                            <span className="form-txt-id-reunion">Audio</span>

                              <div className="row">
                                  <div className="col-sm-12 col-lg-12 col-md-12 col-xl-12">
                                    <p className="mt-4">
                                      <input type="radio" className="tail-btn-check"/>
                                      <span className="form-txt-reunion ml-4">Téléphone</span>
                                      <input type="radio" className="tail-btn-check ml-5"/>
                                      <span className="form-txt-reunion ml-4">Audio de l’ordinateur</span>
                                      <input type="radio" className="tail-btn-check ml-5"/>
                                      <span className="form-txt-reunion ml-4">Téléphone et audio de l’ordinateur</span>
                                      </p>
                                    <button className="mt-5 offset-8 form-txt-modif-bas">modifier</button>
                                  </div>  
                              </div>
                            </div>

                            <hr className="mt-5"/>
                            <div className="mt-4 mb-5">
                            <span className="form-txt-id-reunion">Calendrier</span>

                              <div className="row">
                                  <div className="col-sm-12 col-lg-12 col-md-12 col-xl-12">
                                    <p className="mt-4">
                                      <input type="radio" className="tail-btn-check"/>
                                      <span className="form-txt-reunion ml-5">Outlook</span>
                                      <input type="radio" className="tail-btn-check ml-5"/>
                                      <span className="form-txt-reunion ml-5">Google Agenda</span>
                                      <input type="radio" className="tail-btn-check ml-5"/>
                                      <span className="form-txt-reunion ml-5">Autres calendriers</span>
                                      </p>
                                    
                                  </div>  
                              </div>
                            </div>
                            <hr className="mt-5"/>
                            <div className="row mb-15">
                              
                              <div className="col-sm-3">
                                <select className="form-control form-txt-id-reunion enlevement-bordure-select-btn">
                                    <option selected>Options avancées</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                              </div>
                              
                            </div>

                            <div className="row pb-5">
                              <div className="col-sm-12 col-lg-12 col-md-12 col-xl-12">
                                <div className="d-flex offset-6">
                                  <button className="form-btn-prog">PROGRAMMER</button>
                                  <button className="form-btn-annuler ml-3">ANNULER</button>
                                </div>
                                </div>
                            </div>
                      </div>
                </div>


                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                <div className="row">
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteLateItemForAdmin />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteMissingItemForAdmin />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                    <ProgramReunionCoach />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <SavedReunionListItem />
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ organiserReunion }) => ({ ...organiserReunion }),
//     dispatch => bindActionCreators({ ...organiserReunionActions }, dispatch)
//   )( organiserReunion );