import React from 'react'
import "./bakelisteSuiviDBView.css";
import HeaderDashboardAdmin from "../headerDashboardAdmin";
import NavVerticalAdmin from "../navVerticalAdmin";
import BakelisteSuiviDBRefact from '../bakelisteSuiviDB/BakelisteSuiviDBRefact';


const BakelisteSuivziDBViewRefact = () => {
  return (
    <div className="component-bakeliste-suivi-d-b-view">
        {" "}
        <div className="component-card-suivi-bakeliste-view body-theme">
          <HeaderDashboardAdmin />
          <div className="container-fluid fixing-after-header">
            <div className="row">
              <div className="col-lg-2 p-0">
                <NavVerticalAdmin />
              </div>
              <div className="col-lg-10 pt-3">
                <BakelisteSuiviDBRefact />
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default BakelisteSuivziDBViewRefact