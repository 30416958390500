import React from "react";
import InfoItem from "./InfoItem";

const ApercuFormation = ({ bakelisteInfos, domaines }) => {
  return (
    <div>
      <h4>Formation à Bakeli</h4>
      <div className="container-fluid">
        {bakelisteInfos.type_formation === "diplomante" && (
          <>
            <div className="row apercu--alone border-bottom py-2">
              <div className="col-6">
                <span>
                  Type de formation :{" "}
                  {bakelisteInfos.type_formation ? (
                    bakelisteInfos.type_formation
                  ) : (
                    <span className="required--info">
                      Information indispensable
                    </span>
                  )}
                </span>
              </div>
            </div>
            <InfoItem
              label1="Horaire"
              label2="Jours formations"
              data1={bakelisteInfos.horaire}
              data2={bakelisteInfos.jour_fd}
            />
          </>
        )}
        {/* ******************************** professionnel ************************* */}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_pro) === 1 &&
          Number(bakelisteInfos.nbre_jours) === 2 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Professionnel"
                data2="Semaine (2 jours )"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
                data1={bakelisteInfos.horaire}
                data2={`${bakelisteInfos.jour_1} - ${bakelisteInfos.jour_2}`}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_pro) === 1 &&
          Number(bakelisteInfos.is_weekend) === 1 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Professionnel"
                data2="Weekend"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
                data1={bakelisteInfos.horaire}
                data2={`${bakelisteInfos.jour_1}`}
              />
            </>
          )}
        {/* ********************************** etudiant ******************************* */}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) !== 1 &&
          Number(bakelisteInfos.nbre_jours) === 3 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant"
                data2="Semaine (3 jours)"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
                data1={bakelisteInfos.horaire}
                data2={`${bakelisteInfos.jour_1} - ${bakelisteInfos.jour_2} - ${bakelisteInfos.jour_3}`}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) !== 1 &&
          Number(bakelisteInfos.is_weekend) === 1 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant"
                data2="Weekend"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
                data1={bakelisteInfos.horaire}
                data2={`${bakelisteInfos.jour_1} - ${bakelisteInfos.jour_2}`}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) !== 1 &&
          Number(bakelisteInfos.nbre_jours) === 5 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant"
                data2="Semaine (lundi au vendredi)"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
                data1={bakelisteInfos.horaire}
                data2={`${bakelisteInfos.jour_1} - ${bakelisteInfos.jour_2} - ${bakelisteInfos.jour_3} - ${bakelisteInfos.jour_4} - ${bakelisteInfos.jour_5} `}
              />
            </>
          )}
        {/* ********************************** etudiant a tester ******************************* */}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) === 1 &&
          Number(bakelisteInfos.nbre_jours) === 3 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant à tester"
                data2="Semaine (3 jours)"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
                data1={bakelisteInfos.horaire}
                data2={`${bakelisteInfos.jour_1} - ${bakelisteInfos.jour_2} - ${bakelisteInfos.jour_3}`}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) === 1 &&
          Number(bakelisteInfos.is_weekend) === 1 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant à tester"
                data2="Weekend"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
                data1={bakelisteInfos.horaire}
                data2={`${bakelisteInfos.jour_1} - ${bakelisteInfos.jour_2}`}
              />
            </>
          )}
        {bakelisteInfos.type_formation === "courte" &&
          Number(bakelisteInfos.is_etudiant) === 1 &&
          Number(bakelisteInfos.is_etudiant_a_tester) === 1 &&
          Number(bakelisteInfos.nbre_jours) === 5 && (
            <>
              <div className="row border-bottom py-2">
                <div className="col-6">
                  <span>
                    Type de formation :{" "}
                    {bakelisteInfos.type_formation ? (
                      bakelisteInfos.type_formation
                    ) : (
                      <span className="required--info">
                        Information indispensable
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <InfoItem
                label1="Status professionnel"
                label2="Type de présence"
                data1="Etudiant à tester"
                data2="Semaine (lundi au vendredi)"
              />
              <InfoItem
                label1="Horaire"
                label2="Jours"
                data1={bakelisteInfos.horaire}
                data2={`${bakelisteInfos.jour_1} - ${bakelisteInfos.jour_2} - ${bakelisteInfos.jour_3} - ${bakelisteInfos.jour_4} - ${bakelisteInfos.jour_5} `}
              />
            </>
          )}
        <InfoItem
          label1="Domaine choisi"
          label2="Formation payante"
          data1={
            domaines.length !== 0
              ? domaines.find(
                  (domaine) =>
                    domaine.id === Number(bakelisteInfos.domaines_id)
                )?.name
              : "No domaine"
          }
          data2={
            Number(bakelisteInfos.is_formation_payante) === 1 ? "Oui" : "Non"
          }
        />

        <InfoItem
          label1="Paiement inscription"
          label2="Stage de formation"
          data1={
            Number(bakelisteInfos.paiement_inscription) === 1 ? "Oui" : "Non"
          }
          data2={
            Number(bakelisteInfos.is_stagede_formation) === 1 ? "Oui" : "Non"
          }
        />

        <InfoItem
          label1="Lieu de formation"
          label2="Niveau du bakeliste"
          data1={bakelisteInfos.lieu_formation}
          data2={bakelisteInfos.bakeliste_level}
        />

        <div className="row border-bottom py-2">
          <div className="col-6">
            <span>
              Durée formation :{" "}
              {bakelisteInfos.training_duration ? (
                bakelisteInfos.training_duration + " mois"
              ) : (
                <span className="required--info">
                  Information indispensable
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApercuFormation;
