import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { history } from "../../../components/history";
import { API } from "../../../variablesGlobales";

const initialState = {
  isLoading: false,
  allSyllabus: [],
  allDomaineActifs: [],
  allSousDomaines: [],
  allAdmins: [],
  allGroupes: [],
  allSousGroupes: [],
};

const API_URL = API;

export const parametreSlice = createSlice({
  name: "listSyllabus",
  initialState,

  reducers: {
    // isFetchingCoachAssistant: (state) => {
    //   state.isLoading = true;
    // },

    getSyllabus: (state, action) => {
      state.allSyllabus = action.payload;
    },
    getAllDomaines: (state, action) => {
      state.allDomaineActifs = action.payload;
    },
    getAllSousDomaines: (state, action) => {
      state.allSousDomaines = action.payload;
    },
    getAllAdmins: (state, action) => {
      state.allAdmins = action.payload;
    },
    getAllGroupes: (state, action) => {
      state.allGroupes = action.payload;
    },
    getAllSousGroupes: (state, action) => {
      state.allSousGroupes = action.payload;
    },
    // isFetchingDoneCoachAssistant: (state) => {
    //   state.isLoading = false;
    // },
  },
});

export const getSyllabusAsync = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}syllabus-by-coach/${id}`);
    if (res.data.success) {
      // dispatch(isFetchingDoneCoachAssistant());
      dispatch(getSyllabus(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const addSyllabusAsync = (id, data) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}syllabus/create"`, data);
    if (res.data.success) {
      toast.success(
        "Syllabus ajouté avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getSyllabusAsync(id));
    } else {
      toast.error(
        "erreur sur l'ajout !!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getDomainesAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}domaines`);
    if (res.data.success) {
      // dispatch(isFetchingDoneCoachAssistant());
      dispatch(getAllDomaines(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const addDomaineAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}domaines`, data);
    if (response.data.success) {
      toast.success(
        "Domaine ajouté avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getDomainesAsync());
    } else {
      toast.error(
        "Problème sur l'ajouté!!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};

export const updateDomaineAsync = (domaine_id, data) => async (dispatch) => {
  try {
    const response = await axios.put(`${API_URL}domaines/${domaine_id}`, data);
    if (response.data.success) {
      toast.success(
        "Domaine modifié avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getDomainesAsync());
    } else {
      toast.error(
        "Problème sur la modification!!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};

export const getSousDomainesAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}sous_domaines`);
    if (res.data.success) {
      // dispatch(isFetchingDoneCoachAssistant());
      dispatch(getAllSousDomaines(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const archiveSousDomaineAsync = (sousDomaineId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${API_URL}archive_sous_domaine/${sousDomaineId}`
    );
    if (res.data.success) {
      toast.success(
        "sous domaine archivé avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getSousDomainesAsync());
    } else {
      toast.error(
        "erreur sur l'archivage",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateSousDomaineAsync =
  (sousDomaineId, data) => async (dispatch) => {
    try {
      const res = await axios.put(
        `${API_URL}sous_domaines/${sousDomaineId}`,
        data
      );
      if (res.data.success) {
        toast.success(
          "sous domaine modifié avec success",
          { position: toast.POSITION.TOP_RIGHT },
          { autoClose: 1000 }
        );
        dispatch(getSousDomainesAsync());
      } else {
        toast.error(
          "erreur sur la modification!!",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 1000 }
        );
      }
    } catch (err) {
      throw new Error(err);
    }
  };
export const addSousDomaineAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}sous_domaines`, data);
    if (response.data.success) {
      toast.success(
        "sous domaine ajouté avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getSousDomainesAsync());
    } else {
      toast.success(
        "Erreur sous l'ajout!!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};

export const getAdminsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}list-admins`);
    if (res.data.success) {
      // dispatch(isFetchingDoneCoachAssistant());
      dispatch(getAllAdmins(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const archiveAdminsAsync = (admin_id) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}archive-user/${admin_id}`);
    if (res.data.success) {
      toast.success(
        "Admin archivé avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getAdminsAsync());
    } else {
      toast.error(
        "Erreur sur ml'archivage!!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getGroupesAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}groupes`);
    if (res.data.success) {
      // dispatch(isFetchingDoneCoachAssistant());
      dispatch(getAllGroupes(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateGroupeAsync = (groupe_id, data) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}groupes/${groupe_id}`, data);
    if (res.data.success) {
      toast.success(
        "Détails du groupe  modifiés  avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getGroupesAsync());
    } else {
      toast.error(
        "Erreur sur la modification!!",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const addGroupeAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}groupes`, data);
    if (response.data.success) {
      toast.success(
        "Groupe ajouté avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getGroupesAsync());
      history.push("/parametres-admin");
    } else {
      toast.success(
        "Erreur sous l'ajout!!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};
export const getSousGroupesAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}sous__groupes`);
    if (res.data.success) {
      // dispatch(isFetchingDoneCoachAssistant());
      dispatch(getAllSousGroupes(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const archiveSousGroupesAsync = (sousGroupe_id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${API_URL}sous__groupe/supprimer/${sousGroupe_id}`
    );
    if (res.data.success) {
      toast.success(
        "Sous groupe archivé avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getSousGroupesAsync());
    } else {
      toast.error(
        "Erreur sur l'archivage!!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const updateSousGroupeAsync = (sGroupe_id, data) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_URL}sous__groupes/${sGroupe_id}`, data);
    if (res.data.success) {
      toast.success(
        "Détails du sous groupe  modifiés  avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getSousGroupesAsync());
    } else {
      toast.error(
        "Erreur sur la modification!!",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};
export const addSougroupeAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}sous__groupes`, data);
    if (response.data.success) {
      toast.success(
        "Sous groupe  ajouté  avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      history.push("/parametres-admin")
    }else{
      toast.error(
        "Erreur sur l'ajout!!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};
export const {
  getSyllabus,
  getAllDomaines,
  getAllSousDomaines,
  getAllAdmins,
  getAllGroupes,
  getAllSousGroupes,
} = parametreSlice.actions;
export const isLoadingCoachAssistant = (state) =>
  state.listCoachAssistant?.isLoading;
export const showListSyllabus = (state) => state.listSyllabus.allSyllabus;
export const showListDomaines = (state) => state.listSyllabus.allDomaineActifs;
export const showListSousDomaines = (state) =>
  state.listSyllabus.allSousDomaines;
export const showListAdmins = (state) => state.listSyllabus.allAdmins;
export const showListGroupes = (state) => state.listSyllabus.allGroupes;
export const showListSousGroupes = (state) => state.listSyllabus.allSousGroupes;

export default parametreSlice.reducer;
