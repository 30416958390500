import React, { Component } from "react";
import "./tableDebutant.css";
import FeatherIcon from "feather-icons-react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import { Tooltip } from "@material-ui/core";

import { Modal } from "rsuite";
import $ from "jquery";

import StopIcon from "@material-ui/icons/Stop";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col">
        <p className="badge todo-badge">To do</p>
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return <Status active={cell} />;
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={16} />
        </button>

        <button className="btn-icon-table">
          <FeatherIcon
            className="icon-btn-icon-table"
            icon="edit-2"
            size={16}
          />
        </button>
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="trash" size={16} />
        </button>
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return <Actions active={cell} />;
}

export default class tableDebutant extends Component {
  constructor(props) {
    let token = window.sessionStorage.getItem("token");
    let userStatus = window.sessionStorage.getItem("user_status");

    if (!token) {
      window.history.back();
    }
    if (userStatus === "bakeliste" || userStatus === "coach") {
      window.history.back();
    }
    super(props); 
    this.state = {
      user_id: window.sessionStorage.getItem("user_id"),
      bakeliste: [],
      domaines: [],
      fields: {},
      upFields: {},
      api: API.API,
      id: "",
      email: "",
      phone: "",
      bakelisteID: 0,
      bakelisteFullname: "",
      bakelisteEmail: "",
      bakelisteDomaine: "",
      bakelisteEcole: "",
      bakelistePhone: "",
      BakelisteDebutFormation: "",
      bakelisteFinFormation: "",
      bakelistecoachFullname: "",
      boursierValue: false,
      demiBoursierValue: false,
      adresseValue: "",
      ecoleValue: "",
      stageFormationValue: false,
      isISAContractValue: false,
      lieuFormationValue: "",
      horaireValue: "",
      paiementInscriptionValue: false,
      debutFormationValue: "",
      finFormationValue: "",
      firstNameValue: "",
      lastNameValue: "",
      loading: true,
      getBakelisteRequestError: false,
      isEmptyData: false,
      updateInProgress: false,
      successAlert: false,
      updateBakelisteRequestError: false,
      successCompletedFormation: false,
      completedFormationRequestError: false,
      completedInProgress: false,
      nbreBakeliste: 0,
      isGetCommentsRequestError: false,
      CoachComments: [],
      showBloc: false,
      pop: false,
      coachs: [],
      showModal: false,
      size: "md",
      assignationEncours: false,
    };
    // this.getBakelisteInfos = this.getBakelisteInfos.bind(this);
    // this.updateBakelisteInfos = this.updateBakelisteInfos.bind(this);
  }

  componentDidMount() {
    this.getListAllBakelistes();
    // this.getBakelisteInfos();
    this.getNombreBakeliste();
    this.getCoachCommentToBakeliste();
    this.getAllActivesCoach();
  }

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "successCompletedFormation") {
        this.setState({
          successCompletedFormationAlert: false,
        });
      }
      if (label === "errorCompletedFormation") {
        this.setState({
          completedFormationRequestError: false,
        });
      }
    }, 5000);
  }

  getNombreBakeliste() {
    axios
      .get(this.state.api + "bakelistes-by-coach/" + this.state.coach_id)
      .then((response) => {
        if (response.data.data !== undefined) {
          const nbreBakeliste = response.data.count;
          // console.log('nombre bakeliste', response.data.count);
          this.setState({
            nbreBakeliste: nbreBakeliste,
          });
        } else {
          this.setState({
            nbreBakeliste: 0,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  }

  handleCompletedFormation = () => {
    // console.log("ddddddd");

    this.setState({
      completedInProgress: true,
    });
    // const bakelisteID = this.bakeliste.id;

    let data = {};
    // const id = this.row.id;
    // const id: row.id, data: row
    // var fd = new FormData();
    // fd.append("bakelisteFullname", this.state.bakelisteFullname);
    // fd.append("address", this.state.address);
    // fd.append("bakeliste_level", this.state.bakeliste_level);
    // fd.append("commentaire", this.state.commentaire);
    // fd.append("email", this.state.email);
    // fd.append("ecole", this.state.ecole);
    // fd.append("first_name", this.state.first_name)

    axios
      .post(this.state.api + "completed-bakelistes-formation/" + data)
      .then((response) => {
        this.setState({
          completedInProgress: false,
        });

        if (response.data.success === true) {
          // console.log("okay");
          this.getListAllBakelistes();
          this.getNombreBakeliste();
          this.setState({
            successCompletedFormationAlert: true,
          });
          this.Popups("successCompletedFormation");
          this.handleShowAndHideAlert("successCompletedFormation");
        } else {
          // console.log(response);
          this.setState({
            completedFormationRequestError: true,
          });
          this.handleShowAndHideAlert("errorCompletedFormation");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          completedFormationRequestError: true,
          completedInProgress: false,
        });
        this.handleShowAndHideAlert("errorCompletedFormation");
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        //  Sentry.captureException(error);
      });
  };

  showComment = () => {
    if (this.state.showBloc) {
      this.setState({
        showBloc: false,
      });
    } else {
      this.setState({
        showBloc: true,
      });
    }
    this.getCoachCommentToBakeliste();
  };
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  onCoachAssignation = (e) => {
    this.setState({
      assignationEncours: true,
    });
    console.log(this.state.selectedBakelist.id, this.state.fields.coachSelect);
    const id_bakelist = this.state.selectedBakelist.id;

    const dataForAssign = {
      bakeliste_id: id_bakelist,
      coach_id: this.state.fields.coachSelect,
    };
    axios
      .post(this.state.api + "assigner-un-coach", dataForAssign)
      .then((res) => {
        // console.log(res);
        if (res.data.success) {
          // this.getBakelisteEnFormation();
          //e.target.reset();

          this.setState({
            assignationEncours: false,
            showModal: false,
          });
          $("#assignation-form")[0].reset();
          this.getListAllBakelistes();
          toast.success(
            "Assignation effectué avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          //window.location.reload();
          // this.handleShowAndHideAlert('assignationSuccessAlert')
        } else {
          console.log(res);
          this.setState({
            assignationEncours: false,
            // assignationErrorAlert: true
          });

          // this.handleShowAndHideAlert('assignationErrorAlert')
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          assignationEncours: false,
          showModal: false,
        });
        toast.error(
          "erreur sur l'assignation",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 3000 }
        );
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  getCoachCommentToBakeliste = () => {
    let bakelisteID = window.sessionStorage.getItem("commentBakelisteId");

    axios
      .get(this.state.api + "coach-comments-by-bakeliste/" + bakelisteID)
      .then((response) => {
        if (response.data.success === true) {
          // console.log('success')
          if (response.data.data !== undefined) {
            this.setState({
              CoachComments: response.data.data,
            });
          }
        } else {
          // console.log('empty')
          console.log("Commentaire non trouvé");
        }
      })
      .catch((error) => {
        this.setState({
          isGetCommentsRequestError: true,
        });
        console.log(error);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };

  // getBakelisteInfos = (bakeliste) => {
  //   this.setState(
  //     {
  //       bakelisteID: bakeliste.id,
  //       BakelisteFullname: bakeliste.bakelisteFullname,
  //       BakelisteEmail: bakeliste.email,
  //       BakelisteDomaine: bakeliste.domaine,
  //       BakelisteEcole: bakeliste.ecole,
  //       BakelistePhone: bakeliste.phone,
  //       BakelistecoachFullname: bakeliste.coachFullname,
  //       BakelisteDebutFormation: bakeliste.startFrenchDateFormat,
  //       BakelisteFinFormation: bakeliste.endFrenchDateFormat,
  //     },
  //     () => {
  //       //console.log(this.state.BakelistecoachFullname)
  //     }
  //   );
  //   this.setState(
  //     {
  //       bakelisteIDForUpdate: bakeliste.id,
  //       bakelisteFullname: bakeliste.bakelisteFullname,
  //       emailValue: bakeliste.email,
  //       phoneValue: bakeliste.phone,
  //       boursierValue: bakeliste.boursier,
  //       demiBoursierValue: bakeliste.demi_boursier,
  //       adresseValue: bakeliste.address,
  //       ecoleValue: bakeliste.ecole,
  //       domaineFormationValue: bakeliste.bakeliste_domaine_id,
  //       stageFormationValue: bakeliste.is_stagede_formation,
  //       formationPayanteValue: bakeliste.is_formation_payante,
  //       isISAContractValue: bakeliste.is_isa_contract,
  //       lieuFormationValue: bakeliste.lieu_formation,
  //       nbreJoursValue: bakeliste.nbreJours,
  //       horaireValue: bakeliste.horaire,
  //       paiementInscriptionValue: bakeliste.paiement_inscription,
  //       debutFormationValue: bakeliste.debut,
  //       finFormationValue: bakeliste.fin,
  //       firstNameValue: bakeliste.first_name,
  //       lastNameValue: bakeliste.last_name,
  //       updateBakeliste: bakeliste,
  //     },
  //     () => {
  //      // console.log(this.state.updateBakeliste);
  //     }
  //   );
  // };

  Popups = (pop) => {
    pop = true;
    Swal.fire({
      title: "Voulez-vous arreter la formation de ce Bakeliste?",
      showDenyButton: true,
      confirmButtonText: `confirmer` && this.handleCompletedFormation(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("formation arreté avec succes!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("changement non effectué", "", "info");
      }
    });
  };

  actionsFormatter = (cell, row) => {
    // console.log(row.id)
    let id = row.id;

    return (
      <span>
        {row.coach_id === null ? (
          <Tooltip title="Assigner à un coach">
            <FeatherIcon
              icon="user-plus"
              className="btn-icon-table"
              cursor="pointer"
              onClick={(e) => this.getSelectedBakelist(row, e)}
            />
          </Tooltip>
        ) : (
          <Tooltip title="A déja un coach">
            <FeatherIcon
              icon="user-check"
              className="btn-icon-table"
              cursor="pointer"
            />
          </Tooltip>
        )}

        {/* <Link
          to={{
            pathname: "/profil-bakeliste",
            state: { id: row.id, data: row },
          }}
        >
          <Tooltip title="Voir détails">

            <VisibilityIcon  className="btn-icon-table"
          cursor="pointer" />
          </Tooltip>
        </Link>  */}
        {/* <Link 
          to={{
            pathname: "/edit-bakeliste",
            state: { id: row.id, data: row},
          }}
        >
          <EditIcon className="btn-icon-table" />
        </Link>      */}
        {/* <button type="button" 
          className="btn btn-danger terminer-formation" 
          id="completed" 
          title='términer la formation'
          onClick={this.handleCompletedFormation}
          >
          {this.state.completedInProgress ?
              <i className="fas fa-spin fa-spinner"></i> : 
              <i className="fas fa-minus"></i>
              
          }
        </button>      */}
        {/* <button className="btn-icon-table"  
        data-placement="top"
        title="Voir les infos du coach"
        data-toggle="modal"
        data-target={`#showInfosModal${row.id}`}>
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={16} />
        </button> */}
        {/*
        <button className="btn-icon-table"
        data-toggle="modal"
        // data-target="#editBakelisteModal"
        data-target={`#editBakelisteModal${row.id}`}
        onClick={(e) => this.getBakelisteInfos(row, e)} >
          
          <FeatherIcon className="icon-btn-icon-table" icon="edit-2" size={16} />
            </button>
                   <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="trash" size={16} />
        </button>
      */}
        {/* Modal Afficher les infos du Bakeliste */}
        <div
          className="modal fade"
          id={`showInfosModal${row.id}`}
          role="dialog"
          aria-labelledby="showInfosModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Details sur {row.bakelisteFullname}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="bakelisteInfos-container">
                  <div className="fullname_container">
                    <span className="item_label">Prénom et Nom : </span>{" "}
                    <span className="label_content">
                      {row.bakelisteFullname}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="email_container">
                    <span className="item_label">Email : </span>{" "}
                    <span className="label_content">{row.email}</span>
                  </div>{" "}
                  <hr />
                  <div className="phone_container">
                    <span className="item_label">Ecole : </span>{" "}
                    <span className="label_content">{row.ecole}</span>
                  </div>{" "}
                  <hr />
                  <div className="phone_container">
                    <span className="item_label">Téléphone : </span>{" "}
                    <span className="label_content">{row.phone}</span>
                  </div>{" "}
                  <hr />
                  <div className="domaine_container">
                    <span className="item_label">Domaine de compétence : </span>{" "}
                    <span className="label_content">{row.domaine}</span>
                  </div>{" "}
                  <hr />
                  <div className="durée_container">
                    <span className="item_label">Durée de la formation : </span>{" "}
                    du{" "}
                    <span className="label_content">
                      {row.startFrenchDateFormat}
                    </span>{" "}
                    au{" "}
                    <span className="label_content">
                      {row.endFrenchDateFormat}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="durée_container">
                    <span className="item_label">Coach : </span>{" "}
                    <span className="label_content">{row.coachFullname}</span>
                  </div>{" "}
                  <hr />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  };

  getListAllBakelistes = () => {
    axios
      .get(this.state.api + 
        // "bakelistes"
        "bakelistes-in-training"
        )
      .then((res) => {
        if (res.data.success === true) {
          //  console.log('All Bakelistes', res.data.data)

          this.setState({
            bakeliste: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
          //console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          getBakelisteRequestError: true,
        });
        console.log(error.message);
      });
  };
  handleExportCSVButtonClick = (onClick) => {
    //console.log("This is my custom function for ExportCSVButton click event");
    onClick();
  };
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        style={{ backgroundColor: "#f39831" }}
        btnText="Export CSV"
      />
    );
  };

  getSelectedBakelist(x) {
    this.getAllActivesCoach();
    this.setState({
      selectedBakelist: x,
      showModal: true,
    });
    return x.id;
  }
  getAllActivesCoach = () => {
    axios
      .get(this.state.api + "liste-des-coachs")
      .then((res) => {
        const coachsData = res.data;
        const listActiveCoach = [];
        coachsData.data.forEach((item) => {
          if (item.coach_is_actif === 1) {
            listActiveCoach.push(item);
          }
        });
        this.setState({
          coachs: listActiveCoach,
        });
        //console.log("coach list: ", this.state.coachs)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  close = () => {
    this.setState({
      showModal: false,
    });
  };
  render() {
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
    };
    return (
      <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
        <BootstrapTable
          data={this.state.bakeliste}
          striped
          version="4"
          exportCSV
          pagination
          search
          options={options}
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
            {" "}
            ID{" "}
          </TableHeaderColumn>
          <TableHeaderColumn
            className="titre-tab"
            filter={{ type: "TextFilter", delay: 1000 }}
            dataField="bakelisteFullname"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none", padding: "1rem" }}
          >
            Nom Complet{" "}
          </TableHeaderColumn>
          <TableHeaderColumn
            className="titre-tab"
            filter={{ type: "TextFilter", delay: 1000 }}
            dataField="phone"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none", padding: "1rem" }}
          >
            N° Téléphone
          </TableHeaderColumn>
          <TableHeaderColumn
            className="titre-tab"
            filter={{ type: "TextFilter", delay: 1000 }}
            dataField="email"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none", padding: "1rem" }}
          >
            Adresse mail
          </TableHeaderColumn>
          <TableHeaderColumn
            className="titre-tab"
            filter={{ type: "TextFilter", delay: 1000 }}
            dataField="coachFullname"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none", padding: "1rem" }}
          >
            Coach
          </TableHeaderColumn>
          <TableHeaderColumn
            className="titre-tab"
            dataField=""
            dataFormat={this.actionsFormatter}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              display: "flex",
              justifyContent: "center",
              padding: "1rem",
            }}
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>

        <Modal
          className="modal-responsive-md"
          size={this.state.size}
          show={this.state.showModal}
          onHide={this.close}
        >
          <Modal.Header>
            <Modal.Title className="modal-contact-us">
              <h6 className="h-theme ff-inter fw-600 px-3 fs-20">
                {" "}
                Assigner un coach à ce bakeliste{" "}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-height-modal">
            <form id="assignation-form">
              <div className="modal-body">
                <div className="form-group ">
                  <label>Coach:</label>
                  <select
                    className="form-control"
                    id="inputbakeli_domaine_id"
                    name="coachSelect"
                    onChange={(e) => this.handleChange(e)}
                  >
                    <option>--Choisir un coach--</option>
                    {this.state.coachs.map((z, y) => (
                      <option key={y} value={z.id}>
                        {z.first_name} {z.last_name} -{" "}
                        {"[ " + z.nbreBakeliste + " bakeliste(s) ]"}
                      </option>
                    ))}
                  </select>
                </div>

                {this.state.assignationErrorAlert && (
                  <div className="row assignation-error-row">
                    <div className="col-md-12 assignation-error-col">
                      <div className="alert alert-danger assignation-alert-error">
                        Un problème est survenu lors de l'assignation. Veuillez
                        réessayer plus tard.
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                {!this.state.assignationEncours && (
                  <button
                    type="button"
                    id="ass"
                    className="btn-theme"
                    onClick={(e) => this.onCoachAssignation(e)}
                  >
                    Assigner
                  </button>
                )}
                {this.state.assignationEncours && (
                  <button type="button" className="btn btn-theme-orange">
                    Assignation en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
