import React, { Component } from "react";
import "./allCardSuiviBakeliste.css";
import Hom from "../../img/hom.png";
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import { CSVLink } from "react-csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import csvData from "react-csv";
import { Report } from "@material-ui/icons";
import CardSkeleton from "./cardSkeleton";
import FeatherIcon from "feather-icons-react";
import { Tooltip } from "@material-ui/core";
import { Dropdown, Icon, IconButton } from "rsuite";
import $ from "jquery";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default class allCardSuiviBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      api: API.API,
      bakeliste_domaine_id: 0,
      Tache: [],
      bakelistes: [],
      nbrSemaine: 0,
      Assiduite: "",
      Performance: "",
      Autonomie: "",
      Satisfactions: "",
      avisCoach: "",
      loading: true,
      getListeBakelisteRequestError: false,
      isLoaded: true,
      bakToSta: [],
      showAlertSuccess: false,
      showAlertError: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isLoaded: false,
      });
    }, 3000);
  }
  arreterFormation = () => {};
  changeBakelisteToPresentiel = (id) => {
    // console.log(id);
    axios
      .post(this.state.api + "change-bakeliste-to-presentiel/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Format de formation changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du format de formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  changeBakelisteToOnline = (id) => {
    // console.log(id);
    axios
      .post(this.state.api + "change-bakeliste-to-online/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Format de formation changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du format de formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  changeStatus = (id) => {
    var fd = new FormData();
    fd.append("bakeliste_id", id);
    // console.log(id);
    axios
      .post(this.state.api + "bakeliste-to-stagiaire", fd)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.setState({
            bakToSta: response.data.data,
          });
          this.props.getListeAllBakelistes();
          toast.success(
            "Status changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du status",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  arreterFormation = (id) => {
    // console.log(id);
    axios
      .post(this.state.api + "completed-bakelistes-formation/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Formation arrêtée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'arrêt du formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  recrutable = (id) => {
    var fd = new FormData();
    fd.append("bakeliste_nickname", "expert");
    fd.append("bakeliste_id", id);
    fd.append("label", "recruitable");
    axios
      .post(this.state.api + "change-bakeliste-to-recrutable", fd)
      .then((response) => {
        if (response.data.success === true) {
          // this.props.getListeBakelistes();
          toast.success(
            " Ce bakeliste est désormais recrutable",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'action, réessayer!!!!'",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
        // this.setState({
        //   bakrecru: response.data.data,
        // });
      })
      .catch((error) => {});
  };

  render() {
    const data = this.state;
    const loaderSpinner = undefined;
    console.log("donnes", this.props);
    return (
      // <div >
      this.state.isLoaded ? (
        <CardSkeleton />
      ) : (
        <div className="container-card-item">
          <div className="card-item">
            <div className="row">
              <div className="col-md-7">
                <div className="align-inf-bak">
                  <div className="image-cercle">
                    <img src={Hom} alt="" />
                  </div>

                  <div className="ml-2">
                    <p className="fat-ma">
                      <span className="d-flex">
                        {this.props.bakelisteFullname}
                      </span>
                    </p>
                    <p>
                      <span className="pro-mail">{this.props.domaine} /</span>
                      <span className="d-flex mail-color">
                        {this.props.email}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="ml-2">
                  <div className="row">
                    <div className="col-md-4">
                      <Tooltip title="Déclarer comme stagiaire">
                        <i
                          className="fas fa-user-graduate"
                          onClick={(e) =>
                            window.confirm(
                              "Êtes-vous sûr de vouloir changer le status de ce bakeliste en stagiaire ?"
                            ) && this.changeStatus(this.props.id)
                          }
                        >
                          {" "}
                        </i>
                      </Tooltip>
                    </div>
                    <div className="col-md-4">
                      {this.props.is_recruitable === 1 ? (
                        <Tooltip title="Déja recrutable">
                          <i className="fas fa-user-check"> </i>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Déclarer comme recrutable">
                          <i
                            className="fas fa-file-certificate"
                            onClick={() => this.recrutable(this.props.id)}
                          >
                            {" "}
                          </i>
                        </Tooltip>
                      )}
                    </div>
                    <div className="col-md-4">
                      <div class="dropdown col-md-6">
                        <Icon
                          icon="ellipsis-v"
                          className="icon-dropdown-syllabus-item-tache curseurDrop"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          
                        />

                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {this.props.is_completed === 1 ? (
                            <Dropdown.Item >
                              <button className="btn col " disabled>
                                formation en arrêt
                              </button>
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item >
                              <button
                                className="btn "
                                onClick={() =>
                                  this.arreterFormation(this.props.id)
                                }
                              >
                                Arrêter la formation
                              </button>
                            </Dropdown.Item>
                          )}

                          {this.props.is_presentiel === 1 ? (
                            <Dropdown.Item >
                              <button
                                className="btn "
                                onClick={() =>
                                  this.changeBakelisteToOnline(this.props.id)
                                }
                              >
                                Mettre en ligne
                              </button>
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item>
                              <button
                                className="btn "
                                onClick={() =>
                                  this.changeBakelisteToPresentiel(
                                    this.props.id
                                  )
                                }
                              >
                                Mettre en présentiel
                              </button> 
                            </Dropdown.Item>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-lg-4">
                <p>
                  <span className="txt-suivi-coach">Tache : </span>
                  <span className="comp-fonct">{}</span>
                </p>
              </div>
              <div className="col-md-4 col-lg-4 ">
                <p>
                  <span className="txt-suivi-coach">Coach : </span>
                  <span className="comp-fonct">
                    {" "}
                    {this.props.coachFullname}{" "}
                  </span>
                </p>
              </div>
              <div className="col-md-4 col-lg-4">
                <p>
                  <span className="txt-suivi-coach">Nbr Semaine : </span>
                  <span className="comp-fonct"> {""} </span>
                </p>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4 col-lg-4">
                <p>
                  <span className="txt-suivi-coach">Assiduité : </span>
                  <span className="comp-fonct"> {""} </span>
                </p>
              </div>
              <div className="col-md-4 col-lg-4 ">
                <p>
                  <span className="txt-suivi-coach">Performance : </span>
                  <div className="progress-bare-niv-bakeliste">
                    <div className="progress-effet-bare">
                      <span>{""}</span>
                    </div>
                  </div>
                </p>
              </div>
              <div className="col-md-4 col-lg-4">
                <p>
                  <span className="txt-suivi-coach">Autonomie: </span>
                  <div className="progress-bare-niv-bakeliste">
                    <div className="progress-effet-bare">
                      <span>{""}</span>
                    </div>
                  </div>
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4 col-lg-4 ">
                <p>
                  <span className="txt-suivi-coach">Satisfaction: </span>
                  <div className="progress-bare-niv-bakeliste">
                    <div className="progress-effet-bare">
                      <span>{""}</span>
                    </div>
                  </div>
                </p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4 col-lg-4">
                <span className="txt-suivi-coach">Avis du coach</span>
              </div>
              <div className="col-md-8 col-lg-8 ">
                <p className="txt-coach">{this.props.commentaire}</p>
              </div>
            </div>

            <div className="col-md-6">
              <Link
                to={{
                  pathname: "/profil-bakeliste",
                }}
              >
                <button className="btn-v-plus">Voir plus</button>
              </Link>
            </div>
          </div>
        </div>
      )
    );
  }
}
// export default connect(
//     ({ allCardSuiviBakeliste }) => ({ ...allCardSuiviBakeliste }),
//     dispatch => bindActionCreators({ ...allCardSuiviBakelisteActions }, dispatch)
//   )( allCardSuiviBakeliste );
