import React, { Component } from "react";
import "./proposEvent.css";
import { Link } from "react-router-dom";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import FeatherIcon from "feather-icons-react";
import Ecole from "../../img/ecole.png";
import axios from "axios";
import API from "../../variablesGlobales";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as proposEventActions from "../../store/proposEvent/actions";
export default class proposEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      details: [],
    };
  }

  componentDidMount() {
    this.getDetailsEvent();
  }

  getDetailsEvent = () => {
    axios
      .get(this.state.api + "rencontres/" + this.props.id)
      .then((res) => {
        // console.log(res.data.data);
        this.setState({
          details: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="component-propos-event">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav">
                      Dashboard{" "}
                    </Link>
                  </li>
                  <li
                    className="lien_nav_current breadcrumb-item"
                    aria-current="page"
                  >
                    <Link to="/evenements" className="lien_nav">
                      Evenements{" "}
                    </Link>
                  </li>
                  <li
                    className="lien_nav_current breadcrumb-item active"
                    aria-current="page"
                  >
                    A propos de l'evenement
                  </li>
                </ol>
              </nav>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  {/* <h6 className="h-theme ff-inter fw-600 px-3"> 
                      <FeatherIcon icon="arrow-left" />
                      créer un Evenements
                      </h6> */}
                </div>
              </div>
              <div className="container-fluid bg-white colorate-details-bg pt-4">
                <div className="row pt-4">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <span className="ml-4 dtails">
                      {this.state.details.rencontre_name}{" "}
                    </span>
                  </div>

                  <div className="les-details-evenements-clique">
                    <Link
                      to="/liste-des-participants-evenements"
                      className="pas-de-ligne-survol"
                    >
                      <p className="ml-5 pt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#666666"
                          class="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          <path
                            fill-rule="evenodd"
                            d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                          />
                          <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                        </svg>
                        <span className="forme-txt-details-event ml-5">
                          {this.props.nbreParticipants} personnes ont répondu
                        </span>
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <p className="ml-5 pt-4">
                    <FeatherIcon icon="flag" className="coler-icon" />
                    <span className="forme-txt-details-event ml-5">
                      {this.state.details.rencontre_type}
                      {/* Evenments de bakeli   */}
                    </span>
                  </p>
                </div>

                <div className="row">
                  <p className="ml-5 pt-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#666666"
                      class="bi bi-geo-alt-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                    </svg>
                    <span className="forme-txt-details-event ml-5">
                      Adresse HLM GY villa N° 241
                    </span>
                  </p>
                </div>

                <div className="row">
                  <p className="ml-5 pt-4">
                    <FeatherIcon icon="clock" className="coler-icon" />
                    <span className="forme-txt-details-event ml-5">
                      Du {this.state.details.rencontre_start_date} au{" "}
                      {this.state.details.rencontre_end_date}
                    </span>
                  </p>
                </div>

                <div className="row">
                  <p className="ml-5 pt-4">
                    <FeatherIcon icon="star" className="coler-icon" />
                    <span className="forme-txt-details-event ml-5">
                      Prix: {this.state.details.is_payant}
                    </span>
                  </p>
                </div>

                <div className="row">
                  <p className="ml-5 pt-4">
                    <FeatherIcon icon="globe" className="coler-icon" />
                    <span className="forme-txt-details-event ml-5">
                      Public.Tout le monde avec ou sans compte
                    </span>
                  </p>
                </div>

                <div className="row">
                  <p className="forme-txt-details-p ml-5 pt-4 mb-5">
                    {this.state.details.rencontre_description}
                    <br />
                  </p>
                </div>
              </div>

              <div className="container-fluid bg-white colorate-details-bg pt-5 mt-4">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                    <span className="ml-4 dtails">Organisateur </span>
                  </div>
                  <p className="ml-5 pt-4 mb-2">
                    <img src={Ecole} alt="" />
                    <span className="forme-txt-bakeli ml-2">Bakeli </span>{" "}
                    <br />
                    <span className="forme-txt-details-event offset-7">
                      Ecole
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ proposEvent }) => ({ ...proposEvent }),
//     dispatch => bindActionCreators({ ...proposEventActions }, dispatch)
//   )( proposEvent );
