import React, {Component} from 'react';
import './navTabsControleAdmin.css';
import { Link } from 'react-router-dom';
import {Tabs, Tab, Row, Col, Container} from "react-bootstrap";
import PointageAdmin from '../pointageAdmin/index';
import ReportingAdmin from '../reportingAdmin/index';
import FeatherIcon from "feather-icons-react";
import ControleAdmin from '../controleAdmin/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navTabsControleAdminActions from "../../store/navTabsControleAdmin/actions";
export default class navTabsControleAdmin extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-nav-tabs-controle-admin">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
            <nav>
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active current"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="shield"
                    />
                    Contrôle
                  </li>
                </ol>
              </nav>
              <Container>
                <Row>
                  <Col>
                  <Tabs defaultActiveKey="tache" 
                                    id="controlled-tab-example">
                      <Tab eventKey="tache" title="Tache">                     
                        <ControleAdmin />
                      </Tab>
                      <Tab eventKey="pointage" title="Pointage">
                        <PointageAdmin />
                      </Tab>
                      <Tab eventKey="reporting" title="Reporting">
                      <ReportingAdmin />
                      </Tab>
                  </Tabs>
                  </Col>
                </Row>
                </Container>
              </div>

              {/* <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div> */}
            </div>
          </div>
        </div>
      );
    }
  }
// export default connect(
//     ({ navTabsControleAdmin }) => ({ ...navTabsControleAdmin }),
//     dispatch => bindActionCreators({ ...navTabsControleAdminActions }, dispatch)
//   )( navTabsControleAdmin );