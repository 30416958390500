import React, { Component } from "react";
import "./statisticsProgress.css";
import SchoolIcon from "@material-ui/icons/School";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import EventIcon from "@material-ui/icons/Event";
import { Link } from "react-router-dom";
import { Icon } from "rsuite";
import IconStat3 from "../../img/Group_New1.png";

export default class statisticsProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row m-t-60 m-b-30">
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard ">
            <Link to="/formation-diplomante" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.Diplomante}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Diplômante
                </p>
                <div className="eclipse">
                  <Icon
                    className="img-item-statistics-dashboard icon-mortar-board"
                    icon="mortar-board"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard ">
            <Link to="/formations-courtes" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.Courte}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Courte
                </p>
                <div className="eclipse">
                  <Icon
                    className="img-item-statistics-dashboard icon-mortar-board"
                    icon="book2"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* <div className="col-xl-4 col-lg-4 col-md-6 pr-md pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard">
            <Link to="/formations-courtes" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">15</p>
                <p className="designation-item-statistics-dashboard txt">
                  Formé G
                </p>
                <div className="eclipse">
                  <Icon
                    className="img-item-statistics-dashboard icon-mortar-board"
                    icon="folder-open"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div> */}
      </div>
    );
  }
}
