import React, { useEffect, useReducer } from "react";
import "./ajoutGroupe.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { Link } from "react-router-dom";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import HeaderDashboardCoach from "../headerDashboardCoach/index";
import API from "../../variablesGlobales";
import $ from "jquery";
import SweetAlert from "react-bootstrap-sweetalert";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import { useDispatch } from "react-redux";
import { addGroupeAsync, getDomainesAsync, showListDomaines } from "../../redux/slices/parametreSlices/parametreSlice";
import { useSelector } from "react-redux";
const AjoutGroupeRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      showAlertSuccess: false,
      showAlertError: false,
      api: API.API,
      newGroup: {
        group_name: "",
        lien_whatsapp: "",
      },
      isErrorAjout: false,
      group_name: "",
      lien_whatsapp: "",
      domaine_id : "",
      name: "",
      userID: window.sessionStorage.getItem("user_id"),
      addGroupInProgress: false,
    }
  );
  const dispatch = useDispatch();
  const domaineData = useSelector(showListDomaines)

  useEffect(()=>{
    dispatch(getDomainesAsync())
  },[])
  const addGroupe = (e) => {
    e.preventDefault();
    setState({
      addGroupInProgress: true,
    });
    var fd = new FormData();
    fd.append("group_name", state.group_name);
    fd.append("lien_whatsapp", state.lien_whatsapp);
    fd.append("domaine_id",state.domaine_id)
    dispatch(addGroupeAsync(fd));
    // axios
    //   .post(state.api + "groupes", fd, state.newGroup)
    //   .then((response) => {
    //     if (response.data.success) {
    //       $("#group_name").val("");
    //       $("#lien_whatsapp").val("");
    //       setState({
    //         addGroupInProgress: false,
    //         showAlertSuccess: true,
    //         group_name: "",
    //         lien_whatsapp: "",
    //       });
    //       window.location = "/parametres-admin";
    //     } else {
    //       setState({
    //         showAlertError: true,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     if ((error.message = "Request failed with status code 422")) {
    //     }
    //     setState({
    //       assignationEncours: false,
    //       showAlertError: true,
    //     });
    //     setTimeout(() => {
    //       setState({
    //         showAlertError: false,
    //       });
    //     }, 1000);
    //     if (process.env.NODE_ENV !== "production") {
    //       return;
    //     }
    //     Sentry.captureException(error);
    //   });
  };

  const onChange = (event) => {
    setState({
      group_name: event.target.value.replace(/[^\w\s]/gi, ""),
    });
  };
  const onChangeLink = (event) => {
    setState({
      lien_whatsapp: event.target.value.replace(/[^\w\s]/gi, ""),
    });
  };
  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <div className="p-b-200">
              <div className="col-lg-9">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard-admin" className="lien_nav_current">
                        <FeatherIcon
                          className="icon-breadcrumb-custom"
                          icon="layout"
                        />
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/parametres-admin" className="lien_nav_current">
                        <FeatherIcon
                          className="icon-breadcrumb-custom"
                          icon="settings"
                        />
                        Paramètres
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active lien_nav"
                      aria-current="page"
                    >
                      <FeatherIcon
                        className="icon-side-breadcrumb-custom"
                        icon="users"
                      />
                      Ajout Groupe
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-lg-9">
                  <div className="row m-b-15">
                    <div className="col m-b-15">
                      <h6 className="h-theme ff-inter fw-600">
                        Créer un groupe
                      </h6>
                    </div>
                    <div className="col dis-flex m-b-15"></div>
                  </div>
                  <div className="card-dashboard p-md-5 p-sm-5 m-b-20">
                    <SweetAlert
                      show={state.showAlertSuccess}
                      onConfirm={() => setState({ showAlertSuccess: false })}
                      success
                      title="Groupe ajouté avec succes!!!"
                      style={{
                        color: "#069A77",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />
                    <SweetAlert
                      show={state.showAlertError}
                      onConfirm={() => setState({ showAlertError: false })}
                      error
                      title="Erreur sur la création d'un groupe, veuillez reessayer!!!"
                      style={{
                        color: "#d9534f",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />
                    <form id="add_groupe_form" onSubmit={(e) => addGroupe(e)}>
                      <div className="edit-bakeliste-container">
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Nom du groupe</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputName"
                              placeholder="Nom"
                              name="group_name"
                              required
                              value={state.group_name}
                              onChange={onChange}
                            />{" "}
                            {state.remaining_char >= 31 && (
                              <span className="restant_container_sup_30">
                                {" "}
                                {state.remaining_char} caractéres restants
                              </span>
                            )}
                            {state.remaining_char <= 30 &&
                              state.remaining_char > 1 && (
                                <span className="restant_container_inf_30">
                                  {" "}
                                  {state.remaining_char} caractéres restants
                                </span>
                              )}
                            {state.remaining_char <= 1 && (
                              <span className="restant_container_inf_30">
                                {" "}
                                {state.remaining_char} caractére restant
                              </span>
                            )}
                            <br />
                            <label>Lien du groupe whatsapp</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputLink"
                              placeholder="Lien whatsapp"
                              name="lien_whatsapp"
                              value={state.lien_whatsapp}
                              onChange={onChangeLink}
                            />
                            <div className="form-group">
                              <label>Domaine :</label>
                              <select
                                className="form-control"
                                id="inputbakeli_domaine_id"
                                name="coach_domaine_id"
                                onChange={(e)=>setState({domaine_id : e.target.value})}
                                required
                              >
                                <option> -- Choisir un domaine -- </option>
                                {domaineData.map((x, y) => (
                                  <option value={x.id} key={y}>
                                    {x.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {state.addGroupInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            Création en cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            Créer
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                  <div className="row">
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <BakelisteLateItemForAdmin />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <BakelisteMissingItemForAdmin />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                      <ProgramReunionCoach />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <SavedReunionListItem />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjoutGroupeRefact;
