import React, {Component} from 'react';
import './modalBakelisteDetails.css';
import { Modal, Button, ButtonToolbar} from 'rsuite';
import FeatherIcon from 'feather-icons-react';
import AvatarBakeliste from '../../img/mouum.jpg'

export default class modalBakelisteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({
      show: false
    });
  }
  open(size) {
    this.setState({
      size,
      show: true
    });
  }
  render() {
    return  <div className="modal-container">
              <ButtonToolbar>
                <Button className="btn-show-modal-bakeliste-details" size="md" onClick={() => this.open('md')}>
                  Moumadjad Ahmed Abdou
                </Button>
              </ButtonToolbar>
              <Modal className="modal-responsive-md pb-5" size={this.state.size} show={this.state.show} onHide={this.close}>
                <button className="close-modal-bakeliste-title" onClick={this.close}>
                  <FeatherIcon icon="x" aria-hidden="true" />
                </button>
                
                <div className="dis-flex justify-content-center mb-md-5">
                  <div className="col-lg-4 col-md-4 col-sm-5 pl-md-3 mr-md-0 pl-sm-0 position-info-bakeliste-header text-center mt-3">
                    <img className="avatar-bakeliste" src={AvatarBakeliste} alt="" />
                    <p className="nom-bakeliste-details">Ahmed Abdou MOUMADJAD</p>
                    <p className="formation-bakeliste-details">Designer</p>
                  </div>
                  
                </div>
                <div className="col-lg-3 px-lg-0 col-md-3 px-md-0 col-sm-4 pr-sm-0 pl-sm-4 position-action-bakeliste-details mb-5">
                  <button className="btn-action-bakeliste-details">
                    <FeatherIcon className="icon-btn-action-bakeliste-details" icon="corner-up-right" />
                    ASSIGNER UNE TÂCHE
                  </button>
                  <button className="btn-action-bakeliste-details">
                    <FeatherIcon className="icon-btn-action-bakeliste-details" icon="message-circle" />
                    Envoyer un message
                  </button>
                  <button className="delete-btn-action-bakeliste-details" onClick={this.close}>
                    <FeatherIcon className="icon-delete-btn-action-bakeliste-details" icon="x" />
                    Retirer</button>
                </div>

                <div className="mt-5"></div>

                <div className="content-informations-bakeliste-details mt-5">
                  <p className="titre-content-informations-bakeliste-details">Informations personnelles</p>
                  <div className="row">
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Date de naissance:</p>
                      <p className="text-content-informations-bakeliste-details">23/03/2000</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Téléphone:</p>
                      <p className="text-content-informations-bakeliste-details">77 000 00 00 </p>
                    </div>
                    <div className="col-md-4 col-sm-6 pr-md-0 mb-3">
                      <p className="h-content-informations-bakeliste-details">Adresse mail</p>
                      <p className="text-content-informations-bakeliste-details mail-bakeliste">moumadjadahmed.2709@gmail.com </p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Adresse :</p>
                      <p className="text-content-informations-bakeliste-details">Liberté 6</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Whatsapp </p>
                      <p className="text-content-informations-bakeliste-details">77 000 00 00</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">N° carte d'identité * </p>
                      <p className="text-content-informations-bakeliste-details">2769199203456</p>
                    </div>
                  </div>
                </div>

                <div className="content-informations-bakeliste-details">
                  <p className="titre-content-informations-bakeliste-details">Formations</p>
                  <div className="row">
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Statut</p>
                      <p className="text-content-informations-bakeliste-details">Professionnel </p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Diplômes obtenus</p>
                      <p className="text-content-informations-bakeliste-details">Licence</p>
                      <p className="text-content-informations-bakeliste-details fs-13 fw-500">Informatique</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">École</p>
                      <p className="text-content-informations-bakeliste-details">ISM Dakar</p>
                    </div>
                  </div>
                </div>

                <div className="content-informations-bakeliste-details">
                  <p className="titre-content-informations-bakeliste-details">Bakeli</p>
                  <div className="row">
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Date d’inscription:</p>
                      <p className="text-content-informations-bakeliste-details">02/10/2019</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Durée de la formation:</p>
                      <p className="text-content-informations-bakeliste-details">6 mois</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Date de fin</p>
                      <p className="text-content-informations-bakeliste-details">02/04/2020</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Domaine</p>
                      <p className="text-content-informations-bakeliste-details">Design</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="h-content-informations-bakeliste-details">Mode de formation</p>
                      <p className="text-content-informations-bakeliste-details">Présentiel</p>
                    </div>
                  </div>
                </div>

                <div className="content-informations-bakeliste-details">
                  <p className="titre-content-informations-bakeliste-details">Motivations</p>
                  <p className="text-content-informations-bakeliste-details ff-nunito fw-400 fs-13 mb-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sollicitudin iaculis adipiscing nulla libero tristique facilisis lectus. Quam adipiscing nullam pharetra, cras. In dui in amet nisl quis. Mattis odio convallis quis vel sed malesuada ullamcorper. Odio vel, fringilla facilisi neque sapien, at. Sem nec, laoreet arcu viverra nisl. Posuere lacinia ornare magna id.
                  </p>
                </div>

                <div className="content-informations-bakeliste-details">
                  <p className="titre-content-informations-bakeliste-details">Expérience</p>
                  <div className="row">
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="text-content-informations-bakeliste-details">UI designer</p>
                      <p className="text-content-informations-bakeliste-details fs-13">Volkeno</p>
                      <p className="h-content-informations-bakeliste-details">2018</p>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-3">
                      <p className="text-content-informations-bakeliste-details">UI designer</p>
                      <p className="text-content-informations-bakeliste-details fs-13">Volkeno</p>
                      <p className="h-content-informations-bakeliste-details">2018</p>
                    </div>
                  </div>
                </div>
                
              </Modal>
            </div>;
  }
}
// export default connect(
//     ({ modalBakelisteDetails }) => ({ ...modalBakelisteDetails }),
//     dispatch => bindActionCreators({ ...modalBakelisteDetailsActions }, dispatch)
//   )( modalBakelisteDetails );