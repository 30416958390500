import dayjs from "dayjs";
import "dayjs/locale/fr";

var relativeTime = require("dayjs/plugin/relativeTime");

export const timeFromX = (dateX, dateY) => {

    dayjs.locale("fr")

  dayjs.extend(relativeTime);

  let a = dayjs(dateX);

  return dayjs(dateY).from(a, true);
};

// module.exports = {
//     timeFromX : timeFromX,
// }
