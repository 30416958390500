import React from "react";
import "./listeFillieresAdmin.css";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { AutoComplete, Icon, InputGroup } from "rsuite";
import SyllabusFiliéreProgrammation from "../syllabusFiliéreProgrammation/index";
import SyllabusFiliéreDisign from "../syllabusFiliéreDisign/index";
import SyllabusFiliéreGestiondeprojet from "../syllabusFiliéreGestiondeprojet";
import SyllabusFiliéreMarketingDigital from "../syllabusFiliéreMarketingDigital";

import AddFiliére from "../addFiliére/index";
import { Link } from "react-router-dom";
const ListeFillieresAdminRefact = () => {
  const data = [
    "Developpement web",
    "Android developpeur",
    "UX/UI Design",
    "Gestion de projet",
    "Infographie",
  ];
  const styles = {
    marginBottom: 30,
  };

  return (
    <div className="p-b-200">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5">
                <InputGroup inside style={styles}>
                  <InputGroup.Addon>
                    <Icon icon="" />
                  </InputGroup.Addon>
                  <AutoComplete
                    data={data}
                    placeholder="Chercher un Domaine"
                    className="input-syllabus-search"
                  />
                </InputGroup>
              </div>
              <div className="col dis-flex m-b-15">
                <AddFiliére />
              </div>
            </div>

            <div className="row px-lg-0 px-md-2">
              <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                <SyllabusFiliéreProgrammation />
              </div>
              <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                <SyllabusFiliéreMarketingDigital />
              </div>
              <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                <SyllabusFiliéreGestiondeprojet />
              </div>
              <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                <SyllabusFiliéreDisign />
              </div>
            </div>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteLateItemForAdmin />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteMissingItemForAdmin />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeFillieresAdminRefact;
