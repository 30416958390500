import React, { Component } from "react";
import CoachAdmin from "../coachAdmin/CoachAdmin";
import CoachsAdminNew from "../coachAdmin/CoachsAdminNew";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import "./coachAdminView.css";

const CoachAdminView = () => {
  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <CoachAdmin />
            {/* <CoachsAdminNew /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachAdminView;
