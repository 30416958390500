import React, {Component} from 'react';
import './livrablesAdmin.css';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import ItemFilActualite from '../itemFilActualite/index';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

export default class livrablesAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="p-b-200">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/dashboard-admin" className="lien_nav">Dashboard /</Link></li>
                  <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current"> Livrables </li>
                </ol>
              </nav>                
              <div className="row">
                      <div className="col m-b-10">
                        <h6 className="h-theme ff-inter fw-600">Livrables</h6>
                      </div>
                      <div className="col dis-flex m-b-15">
                        
                      </div>
                    </div>
                    

                    
                    <div className="row m-b-30">
                      <div className="col-md-3 m-t-25 mb-md-0 mb-5">
                        <div className="search-livrables">
                          <span class="input-group-text" id="basic-addon1">
                            <SearchIcon className="" />
                            <input type="text" class="form-control input-livrable" placeholder="Entrer un nom" aria-label="Username" aria-describedby="basic-addon1" />
                          </span>
                        </div>
                        <div className="content-nav-fil-actualite-livraison">
                          <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link nav-fil-actualite-livraison active" id="v-pills-all-tab" data-toggle="pill" href="#v-pills-all" role="tab" aria-controls="v-pills-all" aria-selected="true">Tous les livrables</a>
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-md-9 px-xl-3 px-lg-2 m-b-10 pl-md-0 pr-md-3">
                        
                          <div className="tab-content" id="v-pills-tabContent">
                          <div className="dis-flex justify-content-center">
                                <p className="date-livraison-fil-d-actualite">Lun 20/12/2020</p>
                              </div>
                          <div className="card-dashboard p-4 ml-3 mr-3">
 
                              <ItemFilActualite />
                         
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">...</div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">...</div>
                            <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div>
                          </div>
                       
                      </div>
                    </div>
                    
                    </div>
            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteLateItemForAdmin />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteMissingItemForAdmin />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                        <ProgramReunionCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <SavedReunionListItem />
                      </div>
                    </div>
                  </div>        
                    
                  
                </div>
              </div>
            </div>;
    }
  }
