import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { API } from "../../../variablesGlobales";

const initialState = {
  isLoading: false,
  allStagiaires: [],
};

const API_URL = API;

export const stagiairesSlice = createSlice({
  name: "listAllstagiaires",
  initialState,

  reducers: {
    isFetchingStagiaire: (state) => {
      state.isLoading = true;
    },

    getAllStagiaires: (state, action) => {
      state.allStagiaires = action.payload;
    },

    isFetchingDoneStagiaire: (state) => {
      state.isLoading = false;
    },
  },
});

export const getAllStagiairesAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}liste-stagiaires`);
    if (res.data.success) {
      dispatch(isFetchingDoneStagiaire());
      dispatch(getAllStagiaires(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const stagiaireToBakelisteAsync = (FormData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${API_URL}stagiaire-to-bakeliste`,
      FormData
    );
    if (response.data.success) {
      toast.success(
        "Stagiaire en bakeliste avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getAllStagiairesAsync());
    } else {
      toast.error(
        "Erreur sur le changement de status!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};

//Editer les informations du stagiaire

export const updateInfoStagiaire = (formaData) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}edit-stagiaire`, formaData);
    if (response.data.success) {
      toast.success(
        "Informations modifiées  avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getAllStagiairesAsync());
    } else {
      toast.error(
        "Erreur sur la modification!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};

// export const archiveCoach = (id) => async (dispatch) => {
//   try {
//     const res = await axios.post(`${API_URL}archive-user/${id}`);
//     if (res.data.success) {
//       toast.success(
//         "coach archivé avec success",
//         { position: toast.POSITION.TOP_RIGHT },
//         { autoClose: 1000 }
//       );
//       dispatch(getAllCoachsAsync());
//     } else {
//       toast.error(
//         "erreur sur l'archivage",
//         { position: toast.POSITION.BOTTOM_LEFT },
//         { autoClose: 1000 }
//       );
//     }
//   } catch (err) {
//     throw new Error(err);
//   }
// };

export const {
  isFetchingStagiaire,
  getAllStagiaires,
  isFetchingDoneStagiaire,
} = stagiairesSlice.actions;
export const isLoading = (state) => state.listAllCoachs.isLoading;
export const showListStagiaires = (state) =>
  state.listAllstagiaires.allStagiaires;
export default stagiairesSlice.reducer;
