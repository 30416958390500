import React, {Component} from 'react';
import './dashboardAdminView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import DashboardAdmin from '../dashboardAdmin/index';

export default class dashboardAdminView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="body-theme">
                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <DashboardAdmin />
                    </div>
                  </div> 
                </div>

                
              </div>;
    }
  }