import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { timeFromX } from "../dayjsformat/dayJSDateFormat";

const TableBootstrap = ({
  userData,
  nameFormatter,
  actionFormatter,
  csvName,
  shouldExportCsv,
  shouldFormatName,
  shouldFormatAction,
  shouldShowPhoneNumber,
  shouldShowTrainingDuration,
  shouldSearch,
  shouldPaginate,
  shouldShowCoach,
}) => {
  // Function to format the domaine object
  const domaineFormatter = (cell, row) => {
    return `${row?.domaine?.name}`;
  };

  const timeFormatter = (cell, row) => {
    return timeFromX(row?.debut_formation, row?.fin_formation);
  };

  return (
    <BootstrapTable
      data={userData}
      striped={true}
      hover={true}
      search={shouldSearch ? true : false}
      exportCSV={shouldExportCsv ? true : false}
      csvFileName={`${csvName}.csv`}
      searchPlaceholder="Rechercher bakeliste..."
      multiColumnSearch={shouldSearch ? true : false}
      //   multiColumnSort={2}
      pagination={shouldPaginate ? true : false}
      bordered={true}
    >
      <TableHeaderColumn
        isKey="true"
        dataField="bakelisteFullname"
        dataFormat={shouldFormatName ? nameFormatter : false}
        // filter={{
        //   type: "TextFilter",
        //   delay: 1000,
        //   placeholder: "Recherche  ",
        // }}
        tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
        width="150"
      >
        <span className="color-nom-prenom-utilisateurs">Prenoms & Nom</span>
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="email"
        tdStyle={{
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
        // filter={{ type: "TextFilter", delay: 1000 }}
        width="150"
      >
        <span className="color-nom-prenom-utilisateurs">Email</span>
      </TableHeaderColumn>

      {shouldShowPhoneNumber && (
        <TableHeaderColumn
          dataField="phone"
          //   filter={{
          //     type: "TextFilter",
          //     delay: 1000,
          //     placeholder: "Recherche  ",
          //   }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
          <span className="color-nom-prenom-utilisateurs">Numéro</span>
        </TableHeaderColumn>
      )}

      <TableHeaderColumn
        dataField="domaine"
        dataFormat={domaineFormatter}
        tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
        // filter={{
        //   type: "TextFilter",
        //   delay: 1000,
        //   placeholder: "Recherche  ",
        // }}
        width="150"
      >
        <span className="color-nom-prenom-utilisateurs">Domaine</span>
      </TableHeaderColumn>

      {shouldShowTrainingDuration && (
        <TableHeaderColumn
          dataField="duree"
          dataFormat={timeFormatter}
          //   filter={{
          //     type: "TextFilter",
          //     delay: 1000,
          //     placeholder: "Recherche  ",
          //   }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
          <span className="color-nom-prenom-utilisateurs">Durée</span>
        </TableHeaderColumn>
      )}

      {shouldShowCoach && (
        <TableHeaderColumn
          dataField="coach"
          //   filter={{
          //     type: "TextFilter",
          //     delay: 1000,
          //     placeholder: "Recherche  ",
          //   }}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
          <span className="color-nom-prenom-utilisateurs">Coach</span>
        </TableHeaderColumn>
      )}

      {shouldFormatAction && (
        <TableHeaderColumn
          dataField="action"
          dataFormat={shouldFormatAction ? actionFormatter : false}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          // filter={{
          //   type: "TextFilter",
          //   delay: 1000,
          //   placeholder: "Recherche  ",
          // }}
          width="80"
        >
          <span className="color-nom-prenom-utilisateurs"> Actions</span>
        </TableHeaderColumn>
      )}
    </BootstrapTable>
  );
};

export default TableBootstrap;
