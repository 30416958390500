import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getBakelisteAbondonnessync,
  showListBakeAbondonnes,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import TableBootstrap from "./TableBootstrap";

const AllBakelisteAbondonnes = () => {
  const dispatch = useDispatch();
  const bakelisteAbondonnesData = useSelector(showListBakeAbondonnes);

  useEffect(() => {
    dispatch(getBakelisteAbondonnessync());
  }, []);

  // Bootstrap table name formatter
  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/bakelistes_new" className="lien_nav_current">
                Dashboard bakeliste
              </Link>
            </li>
            <li className="breadcrumb-item lien_nav_current">
              Liste des bakeliste abondonnés
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-11">
          <TableBootstrap
            userData={bakelisteAbondonnesData}
            csvName="Liste bakeliste abandonnés"
            shouldExportCsv
            shouldFormatName
            shouldPaginate={bakelisteAbondonnesData?.length > 10}
            shouldSearch
            shouldShowPhoneNumber
            shouldShowTrainingDuration
            nameFormatter={nameFormatter}
          />
        </div>
      </div>
    </>
  );
};

export default AllBakelisteAbondonnes;
