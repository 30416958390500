import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "./slices/adminSlices/adminSlice";
import bakelistetSlice from "./slices/bakelistesSlices/bakelistesSlice";
import coachAssistantSlice from "./slices/coachAssistantSlice/coachAssistantSlice";
import coachsSlice from "./slices/coachsSlices/coachsSlice";
import eventSlice from "./slices/eventsSlices/eventSlice";
import notificationSlice from "./slices/notifications/notificationSlice";
import parametreSlice from "./slices/parametreSlices/parametreSlice";
import prospectSlice from "./slices/prospectSlices/prospectSlice";
import stagiairesSlice from "./slices/stagiaires/stagiairesSlice";
import utilisateurSlice from "./slices/utilisateursSlices/utilisateurSlice";

export const store = configureStore({
  reducer: {
    listAllCoachs: coachsSlice,
    listAllBakeliste: bakelistetSlice,
    listCoachAssistant: coachAssistantSlice,
    listAdmin: adminSlice,
    listAllstagiaires: stagiairesSlice,
    listAllEvents: eventSlice,
    utilisateur: utilisateurSlice,
    prospects: prospectSlice,
    listSyllabus: parametreSlice,
    notifications: notificationSlice,
  },
});
