import FeatherIcon from "feather-icons-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  activerBakelistePaused,
  getBakelisteEnPauseAsync,
  showListBakeEnPause,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import TableBootstrap from "./TableBootstrap";

const AllBakelisteEnPause1 = () => {
  const dispatch = useDispatch();
  const bakelisteEnPauseData = useSelector(showListBakeEnPause);

  useEffect(() => {
    dispatch(getBakelisteEnPauseAsync());
  }, []);

  const actionFormatter = (cell, row) => {
    return (
      <span className="text-center" style={{ cursor: "pointer" }}>
        <span
          className="lien_nav_current"
          onClick={() => reintegrerBakliste(row.id)}
        >
          <FeatherIcon icon="corner-down-left" />
        </span>
      </span>
    );
  };
  const reintegrerBakliste = (bakeliste_id) => {
    let fd = new FormData();
    fd.append("bakeliste_id", bakeliste_id);
    dispatch(activerBakelistePaused(fd));
  };

  // Bootstrap table name formatter
  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/bakelistes_new" className="lien_nav_current">
                Dashboard bakeliste
              </Link>
            </li>
            <li className="breadcrumb-item lien_nav_current">
              Liste des bakelistes en pause
            </li>
          </ol>
        </nav>
      </div>
      <div className="row mt-3">
        <div className="col-md-11">
          <TableBootstrap
            userData={bakelisteEnPauseData}
            csvName="Liste bakeliste en pause"
            shouldExportCsv
            shouldFormatName
            shouldPaginate={bakelisteEnPauseData?.length > 10}
            shouldSearch
            shouldShowPhoneNumber
            shouldFormatAction
            nameFormatter={nameFormatter}
            actionFormatter={actionFormatter}
          />
        </div>
      </div>
    </>
  );
};

export default AllBakelisteEnPause1;
