import React, { Component } from "react";
import "./bakelisteSuiviDB.css";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CardUtilisateur from "../cardUtilisateur/cardUtilisateur";
import ListeBakelistesPause from "../listeBakelistesPause/listeBakelistesPause";
import ListeBakelistesAbandonnes from "../listeBakelistesAbandonnes/listeBakelistesAbandonnes";
import axios from "axios";
import API from "../../variablesGlobales";
import CardUtilisateurSkeleton from "../cardUtilisateurSkeleton/cardUtilisateurSkeleton";
import BakelisteEnPresentiel from "../bakelisteEnPresentiel";
import BakelisteEnLigne from "../bakelisteEnLigne";
import StatisticBakeliste from "../statisticBakeliste/statisticBakeliste";
import ListeComptesBloques from "../listeComptesBloques/listeComptesBloques";
import ListeBakelistesArchives from "../listeBakelistesArchives/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as bakelisteSuiviDBActions from "../../store/bakelisteSuiviDB/actions";
export default class bakelisteSuiviDB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      bakelistesGY2: [],
      bakelistesThies: [],
      bakelistesLabe: [],
      allBakelistes: 0,
      abandonnes: 0,
      pause: 0,
      online: 0,
      presentiel: 0,
      actifs: 0,
      bakelistes: [],
      is_loading: true,
    };
  }

  componentDidMount() {
    this.getListeAllBakelistes();
    this.getListeBakelistesLabe();
    this.getListeBakelistesThies();
    this.getListeBakelistesGY2();
    this.getStatisticBakeliste();
  }

  getListeBakelistesGY2 = () => {
    axios
      .get(this.state.api + "bakeliste-grand-yoff2")
      .then((res) => {
        this.setState({
          bakelistesGY2: res.data.data,
          is_loading: false,
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  getStatisticBakeliste = () => {
    axios
      .get(this.state.api + "count-bakelistes-statistics")
      .then((res) => {
        // console.log("count", res);
        this.setState({
          allBakelistes: res.data.allbakelistes,
          abandonnes: res.data.abandonnes,
          online: res.data.online,
          pause: res.data.paused,
          presentiel: res.data.presentiel,
          actifs: res.data.actifs,
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  getListeBakelistesThies = () => {
    axios
      .get(this.state.api + "list-bakelistes-thies")
      .then((res) => {
        this.setState({
          bakelistesThies: res.data.data,
        });
        // console.log(this.state.bakelistesThies, "bt")
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  getListeBakelistesLabe = () => {
    axios
      .get(this.state.api + "list-bakelistes-labe")
      .then((res) => {
        this.setState({
          bakelistesLabe: res.data.data,
        });
        // console.log(this.state.bakelistesLabe, "bl")
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
 
  getListeAllBakelistes = () => {
    axios
      .get(this.state.api + "bakelistes-in-training")
      .then((res) => {
        this.setState({
          bakelistes: res.data.data,
          is_loading: false,
        });
        // console.log(this.state.bakelistes)
      })
      .catch((error) => {
        this.setState({
          // loading: false,
          // getListeBakelisteRequestError: true,
        });
        // console.log(error.message);
      });
  };
  render() {
    return (
      <div className="component-bakeliste-suivi-d-b">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">
              <nav>
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active lien_nav"
                    aria-current="page"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="users"
                    />
                    BD Bakelistes
                  </li>
                </ol>
              </nav>
              <StatisticBakeliste
                allBakelistes={this.state.allBakelistes}
                countbakpause={this.state.pause}
                countbakabandon={this.state.abandonnes}
                countbakonline={this.state.online}
                countbakpresentiel={this.state.presentiel}
                countbakactifs={this.state.actifs}
              />
              <ul className="nav nav-tabs navTabsRefac" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="allbak-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="allbak"
                    aria-selected="true"
                  >
                    All Bakelistes
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="dakar-tab"
                    data-toggle="tab"
                    href="#dakar"
                    role="tab"
                    aria-controls="dakar"
                    aria-selected="false"
                  >
                    Dakar
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="thies-tab"
                    data-toggle="tab"
                    href="#thies"
                    role="tab"
                    aria-controls="thies"
                    aria-selected="false"
                  >
                    Thies
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="labe-tab"
                    data-toggle="tab"
                    href="#labe"
                    role="tab"
                    aria-controls="labe"
                    aria-selected="false"
                  >
                    Labe
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="presentiel-tab"
                    data-toggle="tab"
                    href="#presentiel"
                    role="tab"
                    aria-controls="presentiel"
                    aria-selected="false"
                  >
                    Presentiel
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="online-tab"
                    data-toggle="tab"
                    href="#online"
                    role="tab"
                    aria-controls="online"
                    aria-selected="false"
                  >
                    En Ligne
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="pause-tab"
                    data-toggle="tab"
                    href="#pause"
                    role="tab"
                    aria-controls="pause"
                    aria-selected="false"
                  >
                    En Pause
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="abandon-tab"
                    data-toggle="tab"
                    href="#abandon"
                    role="tab"
                    aria-controls="abandon"
                    aria-selected="false"
                  >
                    Abandonnes
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="blocked-tab"
                    data-toggle="tab"
                    href="#blocked"
                    role="tab"
                    aria-controls="blocked"
                    aria-selected="false"
                  >
                    Bloques
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    id="archives-tab"
                    data-toggle="tab"
                    href="#archives"
                    role="tab"
                    aria-controls="archives"
                    aria-selected="false"
                  >
                    Archivés
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-5" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="allbak"
                  role="tabpanel"
                  aria-labelledby="allbak-tab"
                >
                  <div className="row">
                    {this.state.is_loading && <CardUtilisateurSkeleton />}

                    {this.state.bakelistes.length === 0 &&
                    this.state.is_loading === false ? (
                      <p className="pComment">Pas de bakelistes</p>
                    ) : (
                      this.state.bakelistes.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-4 col-sm-4 mb-4 px-lg-3 px-md-2"
                        >
                      
                            <CardUtilisateur
                              data={item}
                              bakelisteFullname={item.bakelisteFullname}
                              domaine={item.domaine}
                              email={item.email}
                              coachFullname={item.coachFullname}
                              commentaire={item.commentaire}
                              phone={item.phone}
                              debut_formation={item.debut}
                              fin_formation={item.fin}
                              id={item.id}
                              getListeAllBakelistes={this.getListeAllBakelistes}
                              is_recruitable={item.is_recruitable}
                              is_online={item.is_online}
                              is_completed={item.is_completed}
                              is_blocked={item.is_account_blocked}
                              is_archived={item.is_archived}
                              syllabus={item.syllabus}
                            />
                        
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="dakar"
                  role="tabpanel"
                  aria-labelledby="dakar-tab"
                >
                  <div className="row">
                    {this.state.is_loading && <CardUtilisateurSkeleton />}

                    {this.state.bakelistes.length === 0 &&
                    this.state.is_loading === false ? (
                      <p className="pComment">
                        Il n'y a pas encore de bakeliste dans cette localité.
                      </p>
                    ) : (
                      this.state.bakelistesGY2.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-4 col-sm-4 mb-4 px-lg-3 px-md-2"
                        >
                          <CardUtilisateur
                            bakelisteFullname={item.bakelisteFullname}
                            domaine={item.domaine}
                            email={item.email}
                            coachFullname={item.coachFullname}
                            commentaire={item.commentaire}
                            phone={item.phone}
                            debut_formation={item.debut}
                            fin_formation={item.fin}
                            id={item.id}
                            getListeAllBakelistes={this.getListeAllBakelistes}
                            is_recruitable={item.is_recruitable}
                            is_online={item.is_online}
                            is_completed={item.is_completed}
                            is_blocked={item.is_account_blocked}
                            is_archived={item.is_archived}
                            syllabus={item.syllabus}

                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="thies"
                  role="tabpanel"
                  aria-labelledby="thies-tab"
                >
                  <div className="row">
                    {this.state.is_loading && <CardUtilisateurSkeleton />}

                    {this.state.bakelistesThies.length === 0 &&
                    this.state.is_loading === false ? (
                      <p className="pComment">
                        Il n'y a pas encore de bakeliste dans cette localité.
                      </p>
                    ) : (
                      this.state.bakelistes.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-4 col-sm-4 mb-4 px-lg-3 px-md-2"
                        >
                          <CardUtilisateur
                            bakelisteFullname={item.bakelisteFullname}
                            domaine={item.domaine}
                            email={item.email}
                            coachFullname={item.coachFullname}
                            commentaire={item.commentaire}
                            phone={item.phone}
                            debut_formation={item.debut}
                            fin_formation={item.fin}
                            id={item.id}
                            getListeAllBakelistes={this.getListeAllBakelistes}
                            is_recruitable={item.is_recruitable}
                            is_online={item.is_online}
                            is_completed={item.is_completed}
                            is_blocked={item.is_account_blocked}
                            is_archived={item.is_archived}
                            syllabus={item.syllabus}

                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="labe"
                  role="tabpanel"
                  aria-labelledby="labe-tab"
                >
                  <div className="row">
                    {this.state.is_loading && <CardUtilisateurSkeleton />}

                    {this.state.bakelistesLabe.length === 0 &&
                    this.state.is_loading === false ? (
                      <p className="pComment">
                        Il n'y a pas encore de bakeliste dans cette localité.
                      </p>
                    ) : (
                      this.state.bakelistes.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-md-4 col-sm-4 mb-4 px-lg-3 px-md-2"
                        >
                          <CardUtilisateur
                            bakelisteFullname={item.bakelisteFullname}
                            domaine={item.domaine}
                            email={item.email}
                            coachFullname={item.coachFullname}
                            commentaire={item.commentaire}
                            phone={item.phone}
                            debut_formation={item.debut}
                            fin_formation={item.fin}
                            id={item.id}
                            getListeAllBakelistes={this.getListeAllBakelistes}
                            is_recruitable={item.is_recruitable}
                            is_online={item.is_online}
                            is_completed={item.is_completed}
                            is_blocked={item.is_account_blocked}
                            is_archived={item.is_archived}
                            syllabus={item.syllabus}

                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="presentiel"
                  role="tabpanel"
                  aria-labelledby="presentiel-tab"
                >
                  <BakelisteEnPresentiel />
                </div>
                <div
                  className="tab-pane fade"
                  id="online"
                  role="tabpanel"
                  aria-labelledby="online-tab"
                >
                  <BakelisteEnLigne />
                </div>
                <div
                  className="tab-pane fade"
                  id="pause"
                  role="tabpanel"
                  aria-labelledby="pause-tab"
                >
                  <ListeBakelistesPause />
                </div>
                <div
                  className="tab-pane fade"
                  id="abandon"
                  role="tabpanel"
                  aria-labelledby="abandon-tab"
                >
                  <ListeBakelistesAbandonnes />
                </div>
                <div
                  className="tab-pane fade"
                  id="blocked"
                  role="tabpanel"
                  aria-labelledby="blocked-tab"
                >
                  <ListeComptesBloques />
                </div>
                <div
                  className="tab-pane fade"
                  id="archives"
                  role="tabpanel"
                  aria-labelledby="archives-tab"
                >
                  <ListeBakelistesArchives />
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ bakelisteSuiviDB }) => ({ ...bakelisteSuiviDB }),
//     dispatch => bindActionCreators({ ...bakelisteSuiviDBActions }, dispatch)
//   )( bakelisteSuiviDB );
