import React, { useEffect, useReducer } from "react";
import "./listeSousDomainesAdmin.css";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";

import AddSousDomaines from "../../components/addSousDomaines/index";
import { Link } from "react-router-dom";
import API from "../../variablesGlobales";
// import TableMyDomaine from "../../components/tableMyDomaine/index"
import axios from "axios";
import $ from "jquery";
import TableSousDomaines from "../tableSousDomaines/index";
import TableSousDomainesRefact from "../tableSousDomaines/TableSousDomainesRefact";
import AddSousDomainesRefact from "../addSousDomaines/AddSousDomainesRefact";
const ListeSousDomainesAdminRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      allSousDomaines: [],
      api: API.API,
    }
  );
  useEffect(() => {
    getListAllSousDomaines();
  }, []);
  const getListAllSousDomaines = () => {
    axios
      .get(state.api + "sous_domaines")
      .then((res) => {
        // console.log(res, "sous")
        if (res.data.success === true) {
          setState({
            allSousDomaines: res.data.data,
            // domaine_name: res.data.data.domaine.name,
            loading: false,
          });
        } else {
          setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        setState({
          loading: false,
        });
        console.log(error.message);
      });
  };
  return (
    <div className="p-b-200">
      <div className="row">
        <div className="col-lg-9">
          <div className="row m-b-15">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600 px-3">
                {" "}
                Gestion des Sous Domaines 
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5"></div>
            <div className="col dis-flex m-b-15">
              <AddSousDomainesRefact
                getAllSousDomaines={getListAllSousDomaines}
              />
            </div>
          </div>

          <div className="row px-lg-0 px-md-2">
            <TableSousDomainesRefact />
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeSousDomainesAdminRefact;
