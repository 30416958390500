import React from "react";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavTabsUtilisateursRefac from "../navTabsUtilisateurs/NavTabsUtilisateursRefac";
import NavVerticalAdmin from "../navVerticalAdmin/index";

const UsersAdminViewRefac = () => {
  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <NavTabsUtilisateursRefac />
            {/* <NavTabsUtilisateurs /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersAdminViewRefac;
