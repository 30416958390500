import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { history } from "../../../components/history";
import { API } from "../../../variablesGlobales";

const initialState = {
  isLoading: false,
  admins: [],
  usersExceptAdmins: [], 
  isAdding: false,
  usersDataStats: [],
  bakelistesDataStats: [],
  bakelistesActifsDataStats: [],
  allLivraisons: [],
  allCommentaires: [],
  fetchingUserData: false,
  dailyDataStats: "",
  weeklyDataStats: "",
  monthlyDataStats: "",
  isGettingAllStats: false,
};

const API_URL = API;

export const adminSlice = createSlice({
  name: "listAdmin",
  initialState,

  reducers: {
    isFetchingAdmins: (state) => {
      state.isLoading = true;
    },

    // addAdmin: (state) => {},

    getAdmins: (state, action) => {
      state.admins = action.payload;
    },
    getUsersExceptAdmins:(state, action) => {
      state.usersExceptAdmins = action.payload;
    },
    isFetchingAdminsDone: (state) => {
      state.isLoading = false;
    },

    isFetchingUsersExceptAdminsDone: (state) => {
      state.isLoading = false;
    }, 

    isAdding: (state) => {
      state.isAdding = true;
    },

    isAddingDone: (state) => {
      state.isAdding = false;
    },

    fetchingUserData: (state) => {
      state.fetchingUserData = true;
    },
    getUsersDataStats: (state, action) => {
      state.usersDataStats = action.payload;
    },
    getBakelistesDataStats: (state, action) => {
      state.bakelistesDataStats = action.payload;
    },

    getdailyDataStats: (state, action) => {
      state.dailyDataStats = action.payload;
    },

    getweeklyDataStats: (state, action) => {
      state.weeklyDataStats = action.payload;
    },

    getmonthlyDataStats: (state, action) => {
      state.monthlyDataStats = action.payload;
    },

    getBakelistesActifsStats: (state, action) => {
      state.bakelistesActifsDataStats = action.payload;
    },

    getAllLivraisons: (state, action) => {
      state.allLivraisons = action.payload;
    },

    getAllCommentaires: (state, action) => {
      state.allCommentaires = action.payload;
    },

    fetchingUserDataDone: (state) => {
      state.fetchingUserData = false;
    },

    fetchingAllStats: (state) => {
      state.isGettingAllStats = true;
    },
    fetchingAllStatsDone: (state) => {
      state.isGettingAllStats = false;
    },
  },
});

export const getAdminsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}list-admins`);
    if (res.data.success) {
      dispatch(isFetchingAdminsDone());
      dispatch(getAdmins(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getUsersExceptAdminsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}all-users-except-admin`);
    if (res.data.success) {
      dispatch(isFetchingUsersExceptAdminsDone());
      dispatch(getUsersExceptAdmins(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getUsersDataStatsAsync = () => async (dispatch) => {
  const requestOne = await axios.get(`${API_URL}count-users-by-status`);
  const requestTwo = await axios.get(`${API_URL}nbre-bakeliste`);
  const requestThree = await axios.get(`${API_URL}stats-bakelistes-actifs`);
  const requestFour = await axios.get(`${API_URL}get_all_livraisons`);
  const requestFive = await axios.get(`${API_URL}all-comments`);

  axios
    .all([requestOne, requestTwo, requestThree, requestFour, requestFive])
    .then(
      axios.spread((...responses) => {
        //   First request response
        const responseOne = responses[0];
        if (responseOne.data.success) {
          dispatch(getUsersDataStats(responseOne.data));
        }

        // Second request response
        const responseTwo = responses[1];
        if (requestTwo.data.success) {
          dispatch(getBakelistesDataStats(responseTwo.data));
        }

        // Third request response
        const responseThree = responses[2];
        if (requestThree.data.success) {
          dispatch(getBakelistesActifsStats(responseThree.data));
        }

        // Fourth request response
        const requestFour = responses[3];
        if (requestFour.data.success) {
          dispatch(getAllLivraisons(requestFour.data));
        }

        // Fourth request response
        const requestFive = responses[4];
        if (requestFive.data.success) {
          dispatch(getAllCommentaires(requestFive.data));
        }
        dispatch(fetchingUserDataDone());
      })
    )
    .catch((err) => {
      dispatch(fetchingUserDataDone());
      console.log(err);
    });
};

export const addAdminAsync = (fd) => async (dispatch) => {
  dispatch(isAdding());
  try {
    const res = await axios.post(`${API_URL}add-admin`, fd);
    if (res.data.success) {
      dispatch(isAddingDone());
      toast.success(
        "Admin ajouté avec success",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 500 }
      );
      dispatch(getAdminsAsync());
      history.push("/liste-admins");
    }
  } catch (err) {
    // throw new Error(err);
    dispatch(isAddingDone());
    toast.error(
      "Erreur sur l'ajout d'admin!!!",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
    if (err) {
      setTimeout(() => {
        history.push("/liste-admins");
      }, 2000);
    }
  }
};

export const archiveAdmin = (id) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}archive-user/${id}`);
    if (res.data.success) {
      toast.success(
        "Admin archivé avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getAdminsAsync());
    }
  } catch (err) {
    // throw new Error(err);
    toast.error(
      "erreur sur l'archivage",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
  }
};

export const getAllStatsAsync = () => async (dispatch) => {
  const dayFd = new FormData();
  dayFd.append("select", "day");
  const dailyStats = await axios.post(
    `${API_URL}statistiques-livraisons`,
    dayFd
  );

  const weekFd = new FormData();
  weekFd.append("select", "week");
  const weeklyStats = await axios.post(
    `${API_URL}statistiques-livraisons`,
    weekFd
  );

  const monthFd = new FormData();
  monthFd.append("select", "month");
  const monthlyStats = await axios.post(
    `${API_URL}statistiques-livraisons`,
    monthFd
  );

  axios
    .all([dailyStats, weeklyStats, monthlyStats])
    .then(
      axios.spread((...responses) => {
        // get responses
        const responseDaily = responses[0];
        if (responseDaily.data.success) {
          // console.log("di", responseDaily.data.bakelistesActifs);

          dispatch(getdailyDataStats(responseDaily?.data?.bakelistesActifs));
        }

        const responseWeekly = responses[1];
        if (responseWeekly.data.success) {
          dispatch(getweeklyDataStats(responseWeekly.data?.bakelistesActifs));
        }
        const responseMonthly = responses[2];
        if (responseMonthly.data.success) {
          dispatch(getmonthlyDataStats(responseMonthly.data?.bakelistesActifs));
        }
        dispatch(fetchingAllStatsDone());
      })
    )
    .catch((error) => {
      console.log(error);
    });
};


export const {
  isFetchingAdmins,
  getAdmins,
  getUsersExceptAdmins,
  isFetchingAdminsDone,
  isFetchingUsersExceptAdminsDone,
  isAdding,
  isAddingDone,
  getUsersDataStats,
  getBakelistesDataStats,
  getBakelistesActifsStats,
  fetchingUserData,
  fetchingUserDataDone,
  getdailyDataStats,
  getweeklyDataStats,
  getmonthlyDataStats,
  fetchingAllStats,
  fetchingAllStatsDone,
  getAllLivraisons,
  getAllCommentaires,
} = adminSlice.actions;
export const isLoadingAdmins = (state) => state.listAdmin?.isLoading;
export const userDataStats = (state) => state.listAdmin?.usersDataStats;
export const bakelisteDataStats = (state) =>
  state.listAdmin?.bakelistesDataStats;
export const bakelisteActifsDataStats = (state) =>
  state.listAdmin?.bakelistesActifsDataStats;
export const isAddingAdmins = (state) => state.listAdmin?.isAdding;
export const showListAdmins = (state) => state.listAdmin?.admins;
export const showListUsersExceptAdmins = (state) => state.listAdmin?.usersExceptAdmins; 

export const isFetchingUserData = (state) => state.listAdmin?.fetchingUserData;

// all stats 
export const day = (state) => state.listAdmin?.dailyDataStats;
export const week = (state) => state.listAdmin?.weeklyDataStats;
export const month = (state) => state.listAdmin?.monthlyDataStats;
export const loadingStats = (state) => state.listAdmin?.isGettingAllStats;

// livraisons stats
export const livraisonsByMonth = (state) => state.listAdmin?.allLivraisons;

// comments stats
export const commentsByMonth = (state) => state.listAdmin?.allCommentaires;

export default adminSlice.reducer;
