import React, {Component} from 'react';
import './recrutablesView.css'
// import { connect } from "react-redux";
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import Recrutables from "../../components/recrutables/index"
import API from "../../variablesGlobales";

import axios from "axios";
import * as Sentry from "@sentry/react";

// import { bindActionCreators } from "redux";
// import * as stagiaireViewActions from "../../store/stagiaireView/actions";
export default class recrutablesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Recrutables:[],
          api:API.API,
        };
    }
    componentDidMount() {
      this.getRecrutables();
    }

    
    getRecrutables() {
      const _this = this;
  
      axios
        .get(this.state.api + "liste-bakelistes-recrutables")
        .then((response) => {
          if (response.data.data !== undefined) {
            this.setState({
              Recrutables: response.data.data,
            });
            // console.log(this.state.Recrutables, 'recru')
          } else {
            this.setState({
              Recrutables: 0,
            });
          }
          // _this.hideLoader();
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
          // _this.hideLoader();
        });
    }
    render() { 
      return  <div className="body-theme">
                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <Recrutables
                      Recrutables={this.state.Recrutables} />
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ stagiaireView }) => ({ ...stagiaireView }),
//     dispatch => bindActionCreators({ ...stagiaireViewActions }, dispatch)
//   )( stagiaireView );