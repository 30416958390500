import React, {Component} from 'react';
import './pointageAdmin.css';
import {Link, NavLink} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';


const styles = {
  marginBottom: 30
};

const data = [

];

export default class pointageAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return (
      <div className="p-b-200">
             <div className="row px-3">
                  <div className="col-lg-12">
                  <div className="container-fluid px-0">
                        {/* <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/dashboard-admin" className="lien_nav">Dashboard / </Link></li>
                                <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current"> Paramètre </li>
                            </ol>
                        </nav> */}
                        <div className="row m-b-15">
                            <div className="col m-b-15 pt-3">
                                <h6 className="h-theme ff-inter fw-600 px-3"> Pointage</h6>
                            </div>
                        </div>
                        <div className="card-dashboard crd-pointage">
                          <div className="container">
                                <div className="row crd-stat">
                                    <div className="col-lg-3 p-l-0">
                                    <a href="listeAllPointage" className="desactive-decoration">

                                        <div className="card-dashboard lst-point">
                                            <p className="txt-pointage">Liste de tout les pointages</p>
                                            <p className="chiffre-item-pointage1">110</p>
                                            <p className="date-item-pointage">Presence du lundi 22/02/2021</p>
                                        </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-9 p-l-0">
                                        <div className="row">
                                            <div className="col-md-12 dis-flex m-b-15">
                                            <div className="card-dashboard st-point ">
                                        <p className="txt-pointage">Pointage Dakar</p>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Présence</p>
                                            <p className="chiffre-item-pointage">180</p>
                                        </div>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Retard</p>
                                            <p className="chiffre-item-pointage">50</p>
                                        </div>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Absence</p>
                                            <p className="chiffre-item-pointage">30</p>
                                        </div>
                                    </div>
                                    <div className="card-dashboard st-point">
                                        <p className="txt-pointage">Pointage Thiès</p>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Présence</p>
                                            <p className="chiffre-item-pointage">180</p>
                                        </div>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Retard</p>
                                            <p className="chiffre-item-pointage">50</p>
                                        </div>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Absence</p>
                                            <p className="chiffre-item-pointage">30</p>
                                        </div>
                                    </div>
                                    <div className="card-dashboard st-point">
                                        <p className="txt-pointage">Pointage Labè</p>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Présence</p>
                                            <p className="chiffre-item-pointage">180</p>
                                        </div>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Retard</p>
                                            <p className="chiffre-item-pointage">50</p>
                                        </div>
                                        <div className="item-pointage">
                                            <p className="titre-item-pointage">Absence</p>
                                            <p className="chiffre-item-pointage">30</p>
                                        </div>
                                    </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card-dashboard t-point">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="diagram">
                                                                <div className="diagram-v">60</div>
                                                                <div className="diagram-o">30</div>
                                                                <div className="diagram-r">10</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="legende-diagram">
                                                                <div className="legende-v"></div>
                                                                <p className="titre-diagram">Le vert représente les présents du jour</p>
                                                            </div>
                                                            <div className="legende-diagram">
                                                                <div className="legende-o"></div>
                                                                <p className="titre-diagram">L’orange représente les retard du jour</p>
                                                            </div>
                                                            <div className="legende-diagram">
                                                                <div className="legende-r"></div>
                                                                <p className="titre-diagram">Le rouge présente les absent du jour</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-b-15">
                            <div className="col m-b-15">
                                <h6 className="h-theme ff-inter fw-600 px-3"> Statistiques</h6>
                            </div>
                        </div>
                        <div className="card-statistque-retard">
                            <div className="header-card-statistique pt-2 d-flex">
                                <p className="titre-statistique-retard ml-3">Statistique du retard</p>
                                <div className="legende-statistique-retard pl-3">
                                    <div className="legende-v-statistique-retard"></div>
                                    <p className="titre-legende-statistique-retard ml-1">Retard MATIN</p>
                                </div>
                                <div className="legende-statistique-retard m-l-15">
                                    <div className="legende-o-statistique-retard m-l-15"></div>
                                    <p className="titre-legende-statistique-retard m-l-15">Retard Soir</p>
                                </div>
                            </div>
                        </div>
                        <div className="row m-t-40">
                            <div className="col-lg-6">
                                <div className="card-présence-semaine">
                                    <div className="header-card-présence-semaine dis-flex">
                                        <p className="titre-card-présence-semaine ml-3 pt-2">Présence de la semaine</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                <div className="card-absence-semaine">
                                    <div className="header-card-absence-semaine dis-flex">
                                        <p className="titre-card-absence-semaine ml-3 pt-2">Absence de la semaine</p>
                                        <div className="legende-card-absence-semaine ml-3 pt-2">
                                            <div className="legende-v-card-absence-semaine"></div>
                                            <p className="titre-legende-card-absence-semaine ml-3">Absent matin</p>
                                        </div>
                                        <div className="legende-card-absence-semaine ml-3 pt-2">
                                            <div className="legende-o-card-absence-semaine m-l-15"></div>
                                            <p className="titre-legende-card-absence-semaine">Absent Soir</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
      );
    }
  }