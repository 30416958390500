import React, { useEffect, useReducer } from "react";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    addSougroupeAsync,
  getGroupesAsync,
  showListGroupes,
} from "../../redux/slices/parametreSlices/parametreSlice";
const AjoutSousGroupe = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      description: "",
      lien_whatsapp: "",
      groupe_id: "",
      name: "",
      addGroupInProgress: false,
    }
  );

  const dispatch = useDispatch();
  const groupeData = useSelector(showListGroupes);
  const user_id = window.sessionStorage.getItem("user_id")
  useEffect(() => {
    dispatch(getGroupesAsync());
  }, []);

  const addSousgroupe = (e) => {
    e.preventDefault();
    setState({
      addGroupInProgress: true,
    });
    var fd = new FormData();
    fd.append("created_by", user_id);
    fd.append("groupe_id", state.groupe_id);
    fd.append("description", state.description);
    fd.append("name", state.name);
    fd.append("lien_whatsapp", state.lien_whatsapp);
    dispatch(addSougroupeAsync(fd));
  };
//   console.log('id',groupeData)
  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <div className="p-b-200">
              <div className="col-lg-9">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard-admin" className="lien_nav_current">
                        <FeatherIcon
                          className="icon-breadcrumb-custom"
                          icon="layout"
                        />
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/parametres-admin" className="lien_nav_current">
                        <FeatherIcon
                          className="icon-breadcrumb-custom"
                          icon="settings"
                        />
                        Paramètres
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active lien_nav"
                      aria-current="page"
                    >
                      <FeatherIcon
                        className="icon-side-breadcrumb-custom"
                        icon="users"
                      />
                      Ajout Sous Groupe
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="col-lg-9">
                  <div className="row m-b-15">
                    <div className="col m-b-15">
                      <h6 className="h-theme ff-inter fw-600">
                        Créer un sous groupe
                      </h6>
                    </div>
                    <div className="col dis-flex m-b-15"></div>
                  </div>
                  <div className="card-dashboard p-md-5 p-sm-5 m-b-20">
                    <form id="add_groupe_form" onSubmit={(e) => addSousgroupe(e)}>
                      <div className="edit-bakeliste-container">
                        <div className="col-md-6">
                          <div className="form-group ">
                            <label>Nom du sous groupe</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputName"
                              placeholder="Nom"
                              name="name"
                              required
                              value={state.name}
                              onChange={(e) =>
                                setState({ name: e.target.value })
                              }
                            />{" "}
                             <label>Description</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputName"
                              placeholder="Description"
                              name="description"
                              required
                              value={state.description}
                              onChange={(e) =>
                                setState({ description: e.target.value })
                              }
                            />{" "}
                            <label>Lien du groupe whatsapp</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputLink"
                              placeholder="Lien whatsapp"
                              name="lien_whatsapp"
                              value={state.lien_whatsapp}
                              onChange={(e) =>
                                setState({ lien_whatsapp: e.target.value })
                              }
                            />
                            <div className="form-group">
                              <label>Groupe :</label>
                              <select
                                className="form-control"
                                id="inputbakeli_domaine_id"
                                name="groupe_id"
                                onChange={(e) =>
                                  setState({ groupe_id: e.target.value })
                                }
                                required
                              >
                                <option> -- Choisir un groupe -- </option>
                                {groupeData.map((x, y) => (
                                  <option value={x.id} key={y}>
                                    {x.group_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        {state.addGroupInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            Création en cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            Créer
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                  <div className="row">
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <BakelisteLateItemForAdmin />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <BakelisteMissingItemForAdmin />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                      <ProgramReunionCoach />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <SavedReunionListItem />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjoutSousGroupe;
