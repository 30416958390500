import React, { useReducer } from "react";
import "./stagiaire.css";
// import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
import axios from "axios";
import API from "../../variablesGlobales";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { stagiaireToBakelisteAsync } from "../../redux/slices/stagiaires/stagiairesSlice";
const StagiaireRefact = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      Stagiaire: [],
      api: API.API,
    }
  );

  const dispatch = useDispatch();
  const actionsFormatter = (cell, row) => {
    let id = row.id;

    return (
      <span>
        <Tooltip title="Re-integrer comme bakeliste">
          <FeatherIcon
            icon="user-plus"
            className="stageToBak"
            cursor="pointer"
            onClick={(e) => stagiaireToBakeliste(row, e)}
          />
        </Tooltip>
        &nbsp;&nbsp;
        <Tooltip title="Modifier les détails de ce stagiaire">
          <Link
            to={{
              pathname: "/edit-stagiare",
              state: { id: row.id, data: row },
            }}
          >
            <FeatherIcon
              title="modifier les détails"
              icon="edit-2"
              style={{
                color: "#469789",
                height: "21px",
                width: "22px",
                cursor: "pointer",
              }}
              className="ajouter"
              cursor="pointer"
            />
          </Link>
        </Tooltip>
      </span>
    );
  };
  const stagiaireToBakeliste = (row) => {
    // console.log("okkk", row.id);
    var fm = new FormData();
    fm.append("bakeliste_id", row.id);
    dispatch(stagiaireToBakelisteAsync(fm));
  };
  return (
    <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon
                    className="icon-breadcrumb-custom"
                    icon="users"
                  />
                  Stagiares
                </li>
              </ol>
            </nav>
            <div className="col-xl-6 col-lg-6 col-md-6">
              <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                Liste des stagiaires
              </h6>
            </div>
            <BootstrapTable
              data={props.Stagiaire}
              striped={true}
              hover={true}
              // options={options}
              search
              searchPlaceholder="Rechercher bakeliste..."
              multiColumnSearch
              // exportCSV
              // csvFileName="mes-bakelistes.csv"
              multiColumnSort={2}
              pagination
              bordered={true}
            >
              <TableHeaderColumn
                isKey="true"
                dataField="first_name"
                // dataFormat={nameFormatter}
                // dataFormat={nameFormatter}
                filter={{ type: "TextFilter", delay: 1000 }}
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                width="150"
              >
                Prénoms
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="last_name"
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                filter={{ type: "TextFilter", delay: 1000 }}
                width="100"
              >
                Noms
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="email"
                dataSort
                filter={{ type: "TextFilter", delay: 1000 }}
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                width="170"
              >
                Email
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField=""
                dataFormat={actionsFormatter}
                dataSort
                // filter={{ type: "TextFilter", delay: 1000 }}
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                width="170"
              >
                Actions
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StagiaireRefact;
