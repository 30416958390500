import React, {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export default class TaskUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false,
        };
    }
    render() {
      return  <div>
                <button className="btn btn-warning w-full" onClick={() => this.setState({ show: true })}>Tache mise à jour</button>
                <SweetAlert
                    show={this.state.show}
                    onConfirm={() => this.setState({ show: false })}
                    success 
                    title="Tâche mis à jour!!!"
                    style={{ 'color':'#069A77', 'fontSize':'12px', 'fontFamily':'Work Sans, sans-serif', 'paddingTop': 60, 'paddingBottom': 40 }}
                    confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                />
              </div>;
    }
}