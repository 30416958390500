import React, { Component } from "react";
import "./bakelisteSuiviDBView.css";
import HeaderDashboardAdmin from "../headerDashboardAdmin";
import NavVerticalAdmin from "../navVerticalAdmin";
import NavTabsLocalite from "../navTabsLocalite/navTabsLocalite";
import BakelisteSuiviDB from "../bakelisteSuiviDB/bakelisteSuiviDB";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as bakelisteSuiviDBViewActions from "../../store/bakelisteSuiviDBView/actions";
export default class bakelisteSuiviDBView extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-bakeliste-suivi-d-b-view">
        {" "}
        <div className="component-card-suivi-bakeliste-view body-theme">
          <HeaderDashboardAdmin />
          <div className="container-fluid fixing-after-header">
            <div className="row">
              <div className="col-lg-2 p-0">
                <NavVerticalAdmin />
              </div>
              <div className="col-lg-10 pt-3">
                <BakelisteSuiviDB />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ bakelisteSuiviDBView }) => ({ ...bakelisteSuiviDBView }),
//     dispatch => bindActionCreators({ ...bakelisteSuiviDBViewActions }, dispatch)
//   )( bakelisteSuiviDBView );
