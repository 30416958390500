import React, {Component} from 'react';
import './editDomaine.css';
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import FeatherIcon from "feather-icons-react";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as editDomaineActions from "../../store/editDomaine/actions";
export default class editDomaine extends Component {
    constructor(props) {
        super(props);
        this.state = {
          editDomaineInProgress: false,
          api: API.API,
          domaines: [],
          domaineName: [],
          description: '',
          name: '',
          status: '',
          domaine_id: this.props.id,
        };
    }

    onChange = (e) => {
      this.setState({ domaine_id: e.value });
    };

    async componentDidMount(){
      if (this.props.match && this.props.match.params.id){
      const doms = this.props.match.params.id;
      // console.log(doms);
      const res = await axios.get(this.state.api + `domaines/${doms}`);
      if(res.data.status === 200){
        this.setState({
          name: res.data.domaine.name,
          description: res.data.domaine.description,
          status: res.data.domaine.status,
        });
      }
    }
  }

    editDomaine = async (e) =>{
        // console.log("edit domaine",this.state.domaines)
         e.preventDefault();
        // this.setState({
        //   editDomaineInProgress: true,
        // });

        // const fd = {
        //   description: this.state.description,
        //   name: this.state.name,
        //   status: this.state.status
        // } 
        

        // axios
        //   .put(this.state.api + "domaines/" + this.props.id, fd, {
        //     Headers: { "Content-Type": "multipart/formdata" },
        //   })
        //   .then((result) => {
        //     console.log("editer", result);
        //     this.setState({
        //       editDomaineInProgress: false,
        //     });
        //      console.log(result);
        //     if (result.data.success) {
        //       window.location = "/domaine-admin";
        //     }
        //   });
        };

        getDomaineById() {
          axios
            .get(this.state.api + "domaines/" + this.props.id)
            .then((res) => {
              this.setState({
                domaineName: res.data.data.name,
              });
              // console.log("ee",this.state.domaineName)
            });
        }
              
        annuler = (e) => {
          window.location="/domaine-admin"
        }

        handleInput = (e) =>{
          this.setSate({
            [e.target.value]: e.target.value
          }); 
        }

    render() {
      return (
      <div className="component-edit-domaine">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="" className="lien_nav">
                    Home{" "}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav">
                    Dashboard /{" "}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  className="lien_nav_current"
                >
                  {" "}
                  Modification d'un Domaine
                </li>
              </ol>
            </nav>
            
          <form className="form1" onSubmit={(e) => this.editDomaine(e)}>
          <div>
            <h4 className="h-theme titreCoach">
              Modification d'un Domaine 
            </h4>
            </div>

            <div className="form-row row">    
                  <div className="col-md-6">
                    <div className="form-group ">
                      <input
                        type="name"
                        className="form-control ab1 si-form-input"
                        id="inputName"
                        placeholder="Name"
                        name="name" 
                        value={this.state.name}
                        onChange={this.handleInput}
                        required
                      />               
                    </div>
                  </div>


                  <div className="col-md-6">
                    <div className="form-group"> 
                      <select
                        name="status"
                        className="form-control ab1 si-form-input"
                        id="statusInput"
                        value={this.state.status}
                        onChange={this.handleInput}
                        required
                      >
                        <option>
                          {" "}
                          *Choisir un statut --{" "}
                        </option>
                        <option value="Marié(e)"> Actifs </option>
                        <option value="Celibataire"> Non-actifs </option>
                      </select>
                     
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <textarea
                        className="form-control ab1 si-form-input"
                        id="inputDescription"
                        placeholder="description"
                        name="description"
                        value={this.state.description}
                        onChange={this.handleInput}
                      ></textarea>
                      
                    </div>
                  </div>
                 
               </div>

               <div className="row" >
                <div className="col-md-6">
                  {this.state.editDomaineInProgress ? (
                    <button
                      type="submit"
                      className="col-md-5 btn-theme text-up m-t-30 w-full p-t-15 p-b-15 offset-md-6"
                      disabled
                    >
                      Modification en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="col-md-5 btn-theme offset-md-6 text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      MODIFIER
                    </button>
                  )}
                </div>
                <div className="col-md-6">
                  <button
                      onClick={(e) => this.annuler(e)}
                      className="col-md-5 btn-warning text-up m-t-30 w-full p-t-15 p-b-15 offset-md-1"
                    >
                      ANNULER
                    </button>
                </div>
              </div>
          </form>
          </div>
          </div>
        </div>
        
       );
    }
  }
// export default connect(
//     ({ editDomaine }) => ({ ...editDomaine }),
//     dispatch => bindActionCreators({ ...editDomaineActions }, dispatch)
//   )( editDomaine );