import React from "react";
import "./bakelisteEnPresentiel.css";
import { Link, NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getBakelistePresentielsAsync,
  showListBakePresentiels,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";

const BakelisteEnPresentielRefact = () => {
  const bakelistePrensetiels = useSelector(showListBakePresentiels);
  const dispatch = useDispatch();

  const nameFormatter = (cell, row) => {
    return (
      <span>
        {/* <button> */}
        <Tooltip title="Voir la fiche">
          <Link
            className="linkName"
            to={{
              pathname: "/profil-utilisateur",
              state: { id: row.id, data: row },
            }}
          >
            {row.first_name} {row.last_name}{" "}
          </Link>
        </Tooltip>
        {/* </button> */}
      </span>
    );
  };
  useEffect(() => {
    dispatch(getBakelistePresentielsAsync());
  }, []);
  return (
    <div className="p-b-200">
      <div className="container px-0">
        <div className="col-lg-12">
          <div className="card-dashboard m-b-15 p-b-60">
            {bakelistePrensetiels.length > 0 ? (
              <BootstrapTable
                data={bakelistePrensetiels}
                striped={true}
                hover={true}
                // options={options}
                search
                searchPlaceholder="Rechercher bakeliste..."
                multiColumnSearch
                // exportCSV
                // csvFileName="mes-bakelistes.csv"
                multiColumnSort={2}
                pagination
                bordered={true}
              >
                <TableHeaderColumn
                  isKey="true"
                  dataField="bakelisteFullname"
                  // dataFormat={nameFormatter}
                  dataFormat={nameFormatter}
                  filter={{ type: "TextFilter", delay: 1000 }}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  width="150"
                >
                  Nom complet nnnn
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="domaine"
                  width="100"
                  filter={{ type: "TextFilter", delay: 1000 }}
                >
                  Formation
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  dataSort
                  filter={{ type: "TextFilter", delay: 1000 }}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  width="170"
                >
                  Email
                </TableHeaderColumn>
              </BootstrapTable>
            ) : (
              <p className="pComment">
                Il n'y a pas encore de bakeliste en présentiel.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BakelisteEnPresentielRefact;
