import React, { Component } from "react";
import "./usersAdmin.css";
import FeatherIcon from "feather-icons-react";

// import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
// import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
// import ProgramReunionCoach from '../programReunionCoach/index';
// import SavedReunionListItem from '../savedReunionListItem/index';
import TableMyCoachs from "../tableMyCoachs/index";
import { Link, NavLink } from "react-router-dom";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export default class usersAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="row">
          <div className="col-lg-12">
            <div className="container-fluid px-0">
            </div>

            <div className="row m-b-15">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                  Tous les utilisateurs
                </h6>
              </div>

              {/* <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
  

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Users<span class="sr-only">(current)</span></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ml-4" href="/utilisateurs-coach-admin">Coachs</a>
                    </li>
                    

                    <li class="nav-item">
                        <a class="nav-link ml-4" href="/all-bakelistes-admin">Bakelistes</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ml-4" href="/participants-admin">Participants</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ml-4" href="/bf-bakelistes-admin">BF.Terminés</a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link ml-4" href="/visiteurs-admin">Visiteur</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ml-4" href="/user-menager-admin">User Manager</a>
                    </li>
                    </ul>
    
                 </div>

            </nav> */}
            </div>

            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <TableMyCoachs />
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
