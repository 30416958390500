import React, { useEffect, useReducer } from "react";
import "./stagiaireView.css";
// import { connect } from "react-redux";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import Stagiaire from "../../components/stagiaire/index";
import API from "../../variablesGlobales";

import axios from "axios";
import * as Sentry from "@sentry/react";
import StagiaireRefact from "../stagiaire/StagiaireRefact";
import { useDispatch } from "react-redux";
import {
  getAllStagiairesAsync,
  showListStagiaires,
} from "../../redux/slices/stagiaires/stagiairesSlice";
import { useSelector } from "react-redux";
const StagiaireViewRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      Stagiaire: [],
      api: API.API,
    }
  );

  const dataStagiaire = useSelector(showListStagiaires);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllStagiairesAsync());
  }, [dispatch]);

  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <StagiaireRefact Stagiaire={dataStagiaire} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StagiaireViewRefact;
