import React, { Component } from "react";
import "./listeParticipants.css";
import { Link } from "react-router-dom";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import TableListeParticipants from "../tableListeParticipants/index";
import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeParticipantsActions from "../../store/listeParticipants/actions";
export default class listeParticipants extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // console.log("...", this.props.participants);
  }
  
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton style={{ "background-color": "#469789", "margin": "1rem" }} btnText="Export CSV" />
    );
  };
  render() {
   
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
    };
    return (
      <div className="component-liste-participants">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav">
                      Dashboard{" "}
                    </Link>
                  </li>
                  <li
                    className="lien_nav_current breadcrumb-item"
                    aria-current="page"
                  >
                    <Link to="/evenements" className="lien_nav">
                      liste des événements{" "}
                    </Link>
                  </li>
                  <li
                    className="lien_nav_current breadcrumb-item active"
                    aria-current="page"
                  >
                    Liste participants
                  </li>
                </ol>
              </nav>

              <div className="liste-bak pb-4">
                <span className="liste-bak-all ml-3">
                  Liste des participants
                </span>
              </div>
              {this.props.participants.length > 0 ? (
                <BootstrapTable
                  data={this.props.participants} 
                  striped  
                  version='4' 
                  exportCSV
                  csvFileName="Liste des participants.csv"
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                >
                  <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                    {" "}
                    ID{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='first_name' 
                    dataFormat={this.nameFormatter}
                   width="150"
                   thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                   tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                     <span className="color-nom-prenom-utilisateurs headerStyle">Prénoms</span>
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="last_name"
                    width="150"
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs headerStyle">Noms</span>
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="email"
                    width="150"
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs headerStyle">
                      Email
                    </span>
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="phone"

                   width="100"
                   thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                   tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                     <span className="color-nom-prenom-utilisateurs headerStyle">Téléphone</span>
                  </TableHeaderColumn> 
                 
                  
                </BootstrapTable>
              ) : (
                <p className="pComment">
                  Il n'y avait pas de partticipants pour cet événement.
                </p>
              )}
              
            </div>

            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeParticipants }) => ({ ...listeParticipants }),
//     dispatch => bindActionCreators({ ...listeParticipantsActions }, dispatch)
//   )( listeParticipants );
