import React, { Component } from 'react';
import './technologieAdminView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import TechnologieAdmin from '../technologieAdmin/index';

export default class technologieAdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <TechnologieAdmin />
          </div>
        </div>
      </div>
    </div>;
  }
}