import React, {Component} from 'react';
import './convertirProspectView.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import ConvertirProspect from '../convertirProspect/index';
import qs from "qs";
import API from "../../variablesGlobales";
// import * as convertirProspectViewActions from "../../store/convertirProspectView/actions";
export default class convertirProspectView extends Component {
     constructor(props) {
      super(props);
      let id = this.props.location?.state?.id;
      if (!id && this.props.location.search) {
        const obj = qs.parse(this.props.location.search.replace("?", ""));
        id = parseInt(obj.id);
      } 
      this.state = {
        id: id,
        api: API.API,
  
      };
    } 
    render() {
      // console.log(this.state.id ,"eeeee")
      return (
              <div className="component-table-my-participants-view body-theme">
                  <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <ConvertirProspect  id={this.state.id}/>
                    </div>
                  </div>
                </div>
              </div>
       );
    }
  }
// export default connect(
//     ({ convertirProspectView }) => ({ ...convertirProspectView }),
//     dispatch => bindActionCreators({ ...convertirProspectViewActions }, dispatch)
//   )( convertirProspectView );