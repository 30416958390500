import React, {Component} from 'react';
import './bakelisteGroupItemForCoach.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import IconGroup from '../../img/icons/people-icon.png';
import ModalBakelisteDetails from '../modalBakelisteDetails/index';


var bakelisteGroupeItemForCoach = [{
  id: 1
},{
  id: 2
},{
  id: 3
},{
  id: 4
},{
  id: 5
}];

class Status extends React.Component {
  render() {
    return (
      <ModalBakelisteDetails/>
    );
  }
}

function changeStatus(cell, row) {
  return (
    <Status active={ cell } />
  );
}
export default class bakelisteGroupItemForCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="card-dashboard m-b-15">
                <p className="h-card-dashboard-bakeliste"><img className="icon-h-card-dashboard-bakeliste" src={IconGroup} alt="" /> Groupe 10</p>
                <BootstrapTable data={bakelisteGroupeItemForCoach} version='4' bordered={ false } bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }}>
                    <TableHeaderColumn isKey dataField='id' thStyle={{ 'border': 'none' }} tdStyle={{ 'border': 'none', 'paddingLeft': '0', 'paddingRight': '0', 'width': '20px' }}></TableHeaderColumn>
                    <TableHeaderColumn dataField='name' dataFormat={changeStatus} thStyle={{ 'border': 'none' }} tdStyle={{ 'border': 'none', 'paddingLeft': 0 }}></TableHeaderColumn>
                </BootstrapTable>
              </div>;
    }
  }
// export default connect(
//     ({ bakelisteGroupItemForCoach }) => ({ ...bakelisteGroupItemForCoach }),
//     dispatch => bindActionCreators({ ...bakelisteGroupItemForCoachActions }, dispatch)
//   )( bakelisteGroupItemForCoach );