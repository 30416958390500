import React, {Component} from 'react';
import './syllabusItem.css';
import ImageSyllabus from '../../img/syllabus.jpg';
import {NavLink, Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
//import ShareTo from '../shareTo/index';
//import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

export default class syllabusItem extends Component {
    constructor(props) {
        super(props); 
        this.state = {};
    }
    render() {

      let id = this.props.syllabus_id;
  // console.log(id, "idddd")
      return  <div className="container-syllabus-item">
                <div className="syllabus-item">
                  {/* !!!!!!!!Important!!!!!!!!!!! */}
                  {/* etiquette pour le débutant  */}
                  
                  <p /* className="etiquette-syllabus-item " */
                    className={
                      "etiquette-syllabus-item " +
                      (this.props.niveau === "débutant"
                        ? " bg-debutant"
                        : this.props.niveau === "intermédiaire"
                        ? "bg-intermediaire"
                        : this.props.niveau === "avancé"
                        ? "bg-avance"
                        : "")
                    }
                  >   
                    {this.props.niveau}
                  </p>
                  {/* etiquette pour le confirmé  */}
                  {/* <p className="etiquette-syllabus-item confirme">confirmé</p> */}
                  <img src={ImageSyllabus} alt="Avatar" className="image-syllabus-item" style={{ width:'100%' }}  />
                  <div className="content-syllabus-item">
                    <p className="titre-content-syllabus-item">{this.props.name}</p>
                    <div className="row">
                      <div className="col-xl-4 col-lg-5 pr-lg-0 col-6 pr-0">
                        <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="list"/> {this.props.nbreTache} tâches</p>
                      </div>
                      {/* <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                        <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="clock"/> 3 semaines</p>
                      </div> */}
                      <div className="col-xl-4 pl-xl-0 col-6 pr-0">
                        <p className="info-content-syllabus-item"><FeatherIcon className="icon-content-syllabus-item" icon="user"/> {this.props.nbreBakeliste} bakelistes</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-syllabus-item-back">

                {/* <NavLink className="link-details-container-syllabus-item-back" to={'/syllabus-details-bakeliste/'+this.props.syllabus_id} > */}

                <Link className="link-details-container-syllabus-item-back" 
                  to={{
                    pathname: '/details-syllabus',
                    state: { id: id }, 
                  }}
                >

                  <div className="height-container-syllabus-item-back">
                    <p className="titre-container-syllabus-item-back">Description</p>
                    <p className="content-container-syllabus-item-back">{this.props.description}</p>
                    
                    <p className="titre-container-syllabus-item-back mt-xl-5 mt-lg-2 mt-md-2 mt-2">Outils et Technologies</p>
                    <p className="content-container-syllabus-item-back">
                    {this.props.outils.map((item,index) =>
                    <span>{item.name}&nbsp;- </span>
                    )}
                    </p>
                    <p className="content-container-syllabus-item-back">
                    {this.props.technologies.map((item,index) =>
                    <span key={index}>{item.name}&nbsp;</span>, 
                    )}</p>
                  </div>
                  </Link>

                  <div className="">
                    <div className="row justify-content-end position-action-container-syllabus-item-back">
                      <div className="col-xl-4 col-lg-5 col-md-5 pl-lg-3 pl-md-0 col-sm-6">
                       
                        <Link className="link-details-container-syllabus-item-back" 
                          to={{
                            pathname: "/details-syllabus",
                            state: { id: id },
                          }}>
                          <FeatherIcon className="icon-details-container-syllabus-item-back" icon="eye" />
                          Details
                        </Link>
                      </div>
                    
                    </div>
                  </div>
                  
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ syllabusItem }) => ({ ...syllabusItem }),
//     dispatch => bindActionCreators({ ...syllabusItemActions }, dispatch)
//   )( syllabusItem );