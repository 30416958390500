import FeatherIcon from "feather-icons-react";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { FiMapPin, FiPhone, FiUser } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import {
  abandonBakelisteTrainingAsync,
  archiverBakelisteAsync,
  bloquerUnBakelisteAsync,
  declareBakelisteRecrutable,
  getBakelisteDataAsync,
  isFetchingDetails,
  isLoadingBakelisteDetails,
  mettreBakelistePause,
  showBakelisteDetailsData,
  termineFormationAsync,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import { timeFromX } from "../dayjsformat/dayJSDateFormat";
import { frenchShortFormatDate } from "../frenchFormatDate";
import { history } from "../history";
import "./detailsBakelisteRefac.css";

const DetailsBakelisteRefact = () => {
  // HOC
  const location = useLocation();
  const dispatch = useDispatch();
  const bakelisteDetailsData = useSelector(showBakelisteDetailsData);
  const isLoading = useSelector(isLoadingBakelisteDetails);

  // initial states
  const [smShow, setSmShow] = React.useState(false);
  const [archiveShow, setArchivehow] = React.useState(false);
  const [terminerShow, setTerminerShow] = React.useState(false);
  const [bloquerShow, setBloquerShow] = React.useState(false);
  const [recrutableShow, setRecrutableShow] = React.useState(false);
  const [abondonShow, setAbondonShow] = React.useState(false);

  //state pour differents modals
  const { data, id } = location.state;

  useEffect(() => {
    dispatch(getBakelisteDataAsync(id)) && dispatch(isFetchingDetails());
  }, []);

  // Different functions for quick actions
  const blockUserAccount = (e) => {
    e.preventDefault();

    dispatch(bloquerUnBakelisteAsync(id));
    setTimeout(() => {
      setSmShow(false);
      history.push("/all-bakelistes-presentiel");
    }, 2000);
  };

  const archiverBakeliste = (e) => {
    e.preventDefault();
    dispatch(archiverBakelisteAsync(id));
    setTimeout(() => {
      history.push("/all-bakelistes-presentiel");
    }, 1000);
  };

  const pauseBakelisteTraining = (e) => {
    e.preventDefault();
    var fd = new FormData();

    fd.append("bakeliste_id", id);
    dispatch(mettreBakelistePause(fd));
    setTimeout(() => {
      // setSmShow(false);
      history.push("/all-bakelistes-presentiel");
    }, 1000);
  };

  const terminerFormation = (e) => {
    e.preventDefault();
    var fd = new FormData();
    fd.append("bakeliste_id", id);
    dispatch(termineFormationAsync(fd));
    setTimeout(() => {
      history.push("/all-bakelistes-presentiel");
    }, 1000);
  };

  const recrutable = (e) => {
    // console.log(id);
    e.preventDefault();
    var fd = new FormData();
    fd.append("bakeliste_nickname", "expert");
    fd.append("bakeliste_id", id);
    fd.append("label", "recruitable");
    dispatch(declareBakelisteRecrutable(fd));
    history.push("/all-bakelistes-presentiel");
  };

  const abandonBakelisteTraining = (e) => {
    e.preventDefault();
    var fd = new FormData();
    let date = new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date());
    // console.log(date);
    fd.append("bakeliste_date_abandon", date);
    fd.append("bakeliste_id", id);
    // fd.append("bakeliste_id", 58);
    dispatch(abandonBakelisteTrainingAsync(fd));
    history.push("/all-bakelistes-presentiel");
  };

  // Informations complementaires
  const InfosComplementaire = () => {
    return (
      <div className="row other-infos-container p-3">
        <div className="col d-flex flex-column">
          <h5>Civilité</h5>
          <p>{`${
            bakelisteDetailsData?.civility === null
              ? "Néant"
              : bakelisteDetailsData?.civility
          }`}</p>
        </div>
        <div className="col d-flex flex-column">
          <h5>Status</h5>
          <p>{`${
            bakelisteDetailsData?.marital_status === null
              ? "Néant"
              : bakelisteDetailsData?.marital_status
          }`}</p>
        </div>
        <div className="col d-flex flex-column">
          <h5>Domaine</h5>
          <p>{bakelisteDetailsData?.domaine}</p>
        </div>
      </div>
    );
  };

  // Formation Bakeliste
  const FormationBakeliste = () => {
    return (
      <div className="other-infos-container p-3">
        <div className="row">
          <div className="col d-flex flex-column">
            <h5>Domaine</h5>
            <p>{bakelisteDetailsData?.domaine}</p>
          </div>
          <div className="col d-flex flex-column">
            <h5>Durée</h5>
            <p>
              {timeFromX(
                bakelisteDetailsData?.debut_formation,
                bakelisteDetailsData?.fin_formation
              )}
            </p>
          </div>
          <div className="col d-flex flex-column">
            <h5>Coach</h5>
            <p>Programmation</p>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex flex-column mt-4">
            <h5>Date de début</h5>
            <p>
              {frenchShortFormatDate(bakelisteDetailsData?.debut_formation)}
            </p>
          </div>
          <div className="col d-flex flex-column mt-4">
            <h5>Date de fin</h5>
            <p>{frenchShortFormatDate(bakelisteDetailsData?.fin_formation)}</p>
          </div>
          <div className="col d-flex flex-column mt-4">
            <h5>Horaire</h5>
            <p>{`${bakelisteDetailsData?.nbreJours} fois par semaine`}</p>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex flex-column mt-4">
            <h5>Motivations</h5>
            <p>{bakelisteDetailsData?.objectifs}</p>
          </div>
        </div>
      </div>
    );
  };

  // Sylabus et groupes
  const SyllabusGroupes = () => {
    return (
      <div className="other-infos-container p-3 w-100 text-center">
        <div className="row">
          <div className="col d-flex flex-column">
            <h5>Syllabus</h5>
            <ul>
              <li>Syllabus 1</li>
              <li>Syllabus 2</li>
              <li>Syllabus 3</li>
            </ul>
          </div>
          <div className="col d-flex flex-column">
            <h5>Groupes</h5>
            <ul>
              <li>Groupe 1</li>
              <li>Groupe 2</li>
              <li>Groupe 3</li>
            </ul>
          </div>
          <div className="col d-flex flex-column">
            <h5>Sous groupes</h5>
            <ul>
              <li>Sous groupe 1</li>
              <li>Sous groupe 2</li>
              <li>Sous groupe 3</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  // Commentaires
  const Commentaires = () => {
    return <div>Commentaires</div>;
  };

  // Modal section
  const ValidationModal = () => {
    return (
      <>
        <Modal
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <form
            onSubmit={(e) => {
              pauseBakelisteTraining(e);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Mettre bakeliste en pause
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              Voulez-vous mettre en pause ce bakeliste{" "}
              {bakelisteDetailsData[0]?.first_name}{" "}
              {bakelisteDetailsData[0]?.last_name}?
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Confirmer
              </button>
              <button
                className="btn btn-info"
                type="reset"
                onClick={() => {
                  setSmShow(false);
                }}
              >
                Annuler
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          size="sm"
          show={archiveShow}
          onHide={() => setArchivehow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <form
            onSubmit={(e) => {
              archiverBakeliste(e);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Archiver bakeliste
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              Voulez-vous archiver ce bakeliste{" "}
              {bakelisteDetailsData[0]?.first_name}
              {bakelisteDetailsData[0]?.last_name}?
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Confirmer
              </button>
              <button
                className="btn btn-info"
                type="reset"
                onClick={() => {
                  setArchivehow(false);
                }}
              >
                Annuler
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          size="sm"
          show={terminerShow}
          onHide={() => setTerminerShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <form
            onSubmit={(e) => {
              terminerFormation(e);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Mettre fin à la formation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              Voulez-vous mettre fin à la formation de ce bakeliste{" "}
              {bakelisteDetailsData[0]?.first_name}
              {bakelisteDetailsData[0]?.last_name}?
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Confirmer
              </button>
              <button
                className="btn btn-info"
                type="reset"
                onClick={() => {
                  setTerminerShow(false);
                }}
              >
                Annuler
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          size="sm"
          show={bloquerShow}
          onHide={() => setBloquerShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <form
            onSubmit={(e) => {
              blockUserAccount(e);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Bloquer un Bakeliste
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              Voulez-vous bloquer ce bakeliste{" "}
              {bakelisteDetailsData[0]?.first_name}
              {bakelisteDetailsData[0]?.last_name}?
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Confirmer
              </button>
              <button
                className="btn btn-info"
                type="reset"
                onClick={() => {
                  setBloquerShow(false);
                }}
              >
                Annuler
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          size="sm"
          show={recrutableShow}
          onHide={() => setRecrutableShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <form
            onSubmit={(e) => {
              recrutable(e);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Declarer comme recrutable
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              Voulez-vous declarer recrutable ce bakeliste{" "}
              {bakelisteDetailsData[0]?.first_name}
              {bakelisteDetailsData[0]?.last_name}?
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Confirmer
              </button>
              <button
                className="btn btn-info"
                type="reset"
                onClick={() => {
                  setRecrutableShow(false);
                }}
              >
                Annuler
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          size="sm"
          show={abondonShow}
          onHide={() => setAbondonShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <form
            onSubmit={(e) => {
              abandonBakelisteTraining(e);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Declarer comme abandon
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {" "}
              Voulez-vous declarer ce bakeliste{" "}
              {bakelisteDetailsData[0]?.first_name}
              {bakelisteDetailsData[0]?.last_name} comme abandon?
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary">
                Confirmer
              </button>
              <button
                className="btn btn-info"
                type="reset"
                onClick={() => {
                  setAbondonShow(false);
                }}
              >
                Annuler
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  };

  return (
    <div className="component-my-participants">
    {/* {this.state.isLoaded && <ListeProspectSkeleton />} */}
    <div className="container-fluid px-0">
      {/* <nav aria-label="breadcrumb">
        <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
          <li className="breadcrumb-item">
            <Link to="/dashboard-admin" className="lien_nav_current">
              <FeatherIcon className="icon-breadcrumb-custom" icon="layout" />
              Dashboard
            </Link>
          </li>
          <li className="breadcrumb-item active lien_nav" aria-current="page">
            <FeatherIcon
              className="icon-breadcrumb-custom"
              icon="user-plus"
            />
            Propects
          </li>
        </ol>
      </nav> */}

      <nav aria-label="breadcrumb">
                   <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                     <li className="breadcrumb-item">
                       <Link to="/dashboard-admin" className="lien_nav_current">
                         Dashboard
                       </Link>
                     </li>
                     <li className="breadcrumb-item">
                       <Link
                         to="/all-bakelistes-presentiel"
                         className="lien_nav_current"
                       >
                         Bakelistes
                       </Link>
                     </li>
                     <li className="breadcrumb-item lien_nav_current">
                       Fiche de {bakelisteDetailsData[0]?.first_name} {bakelisteDetailsData[0]?.last_name}
                     </li>
                   </ol>
                 </nav>
    </div>

    <div className="row">
      <div className="col-lg-12">
        
        <div className="row">
          
        <div className="col-lg-9  details-bakeliste-container p-3 w-100">
          <div className="bg-white py-3">
            <div className="row m-0 p-0">
              <div className="col-lg-3 d-flex justify-content-center">
                {isLoading ? (
                  <Skeleton width={150} height={150} circle />
                ) : (
                  <img
                    src="https://picsum.photos/200"
                    alt="bakeliste_avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px", height: "150px" }}
                  />
                )}
              </div>
              <div className="col-lg-9 details-infos">
                {/* User avatar */}
                <div className="row">
                  <h4>
                    {isLoading ? (
                      <Skeleton width={200} height={20} />
                    ) : (
                      `${bakelisteDetailsData[0]?.first_name} ${bakelisteDetailsData[0]?.last_name}`
                    )}
                  </h4>
                </div>

                {/* User infos */}
                <div className="row d-flex flex-column sub-details">
                  <div className="d-flex align-items-center my-3">
                    <HiOutlineMail className="mr-2" size={20} />
                    <h6>
                      {isLoading ? (
                        <Skeleton width={250} height={20} />
                      ) : (
                        bakelisteDetailsData[0]?.email
                      )}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <FiMapPin className="mr-2" size={20} />
                    <h6>
                      {isLoading ? (
                        <Skeleton width={200} height={20} />
                      ) : (
                        bakelisteDetailsData[0]?.address
                      )}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <FiPhone className="mr-2" size={20} />
                    <h6>
                      {isLoading ? (
                        <Skeleton width={100} height={20} />
                      ) : (
                        bakelisteDetailsData[0]?.phone
                      )}
                    </h6>
                  </div>
                </div>

                {/* Actions rapide */}
                <div className="row mt-3">
                  <div>
                    <p className="h-theme mb-3 ff-inter fw-600" >Actions rapide</p>
                    {isLoading ? (
                      <Skeleton width={80} height={20} className="m-1" />
                    ) : (
                      <span
                        className="badge badge-pills badge-light m-1"
                        style={{
                          background: "#012F2A",
                          color: "#FFFFFF",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "10px",
                          cursor:
                            bakelisteDetailsData[0]?.is_training_paused === 0 &&
                            "pointer",
                          borderRadius: "10px",
                          boxShadow:
                            "0px 0.622024px 3.73215px rgba(0, 0, 0, 0.25)",
                        }}
                        disabled={
                          bakelisteDetailsData[0]?.is_training_paused !== 0 &&
                          true
                        }
                        onClick={() => {
                          setSmShow(true);
                        }}
                      >
                        Pause
                      </span>
                    )}

                    {isLoading ? (
                      <Skeleton width={80} height={20} className="m-1" />
                    ) : (
                      <span
                        className="badge badge-pills badge-light m-1"
                        style={{
                          background: "#FF9800",
                          color: "#FFFFFF",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "10px",
                          cursor:
                            bakelisteDetailsData[0]?.is_account_blocked === 0 &&
                            "pointer",
                          borderRadius: "10px",
                          boxShadow:
                            "0px 0.622024px 3.73215px rgba(0, 0, 0, 0.25)",
                        }}
                        disabled={
                          bakelisteDetailsData[0]?.is_account_blocked !== 0 &&
                          true
                        }
                        onClick={() => {
                          setBloquerShow(true);
                        }}
                      >
                        Blocker
                      </span>
                    )}
                    {isLoading ? (
                      <Skeleton width={80} height={20} className="m-1" />
                    ) : (
                      <span
                        className="badge badge-pills badge-light m-1"
                        style={{
                          background: "#573400",
                          color: "#FFFFFF",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "10px",
                          cursor:
                            bakelisteDetailsData?.is_archived === 0 &&
                            "pointer",
                          borderRadius: "10px",
                          boxShadow:
                            "0px 0.622024px 3.73215px rgba(0, 0, 0, 0.25)",
                        }}
                        disabled={
                          bakelisteDetailsData?.is_archived !== 0 && true
                        }
                        onClick={() => {
                          setArchivehow(true);
                        }}
                      >
                        Archiver
                      </span>
                    )}
                    {isLoading ? (
                      <Skeleton width={80} height={20} className="m-1" />
                    ) : (
                      <span
                        className="badge badge-pills badge-light m-1"
                        style={{
                          background: "#009688",
                          color: "#FFFFFF",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "10px",
                          cursor:
                            bakelisteDetailsData?.is_recruitable === 0 &&
                            "pointer",
                          borderRadius: "10px",
                          boxShadow:
                            "0px 0.622024px 3.73215px rgba(0, 0, 0, 0.25)",
                        }}
                        disabled={
                          bakelisteDetailsData?.is_recruitable !== 0 && true
                        }
                        onClick={() => {
                          setRecrutableShow(true);
                        }}
                      >
                        Recrutable
                      </span>
                    )}
                    {/* <span
                    className="badge badge-pills badge-light m-1"
                    style={{
                      background: "#3F85FB",
                      color: "#FFFFFF",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "10px",
                      cursor: "pointer",
                      borderRadius: "10px",
                      boxShadow: "0px 0.622024px 3.73215px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    Reintegrer
                  </span> */}
                    {isLoading ? (
                      <Skeleton width={80} height={20} className="m-1" />
                    ) : (
                      <span
                        className="badge badge-pills badge-light m-1"
                        style={{
                          background: "#F44336",
                          color: "#FFFFFF",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "10px",
                          cursor:
                            bakelisteDetailsData[0]?.is_completed === 0 &&
                            "pointer",
                          borderRadius: "10px",
                        }}
                        disabled={
                          bakelisteDetailsData[0]?.is_completed !== 0 && true
                        }
                        onClick={() => {
                          setTerminerShow(true);
                        }}
                      >
                        Terminer
                      </span>
                    )}
                    {/* <span
                    className="badge badge-pills badge-light m-1"
                    style={{
                      background: "#000000",
                      color: "#FFFFFF",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "10px",
                      cursor: bakelisteDetailsData?.status !== "stagiaire" && "pointer",
                      borderRadius: "10px",
                    }}
                    disabled={bakelisteDetailsData?.status === "stagiaire" && true}
                  >
                    Placer en stage
                  </span> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mt-5 p-0 w-100 ml-3">
              <div className="w-100">
                <h4 className="text-center h-theme ff-inter fw-600 px-3 mb-3">Informations Personnels</h4>
                <div className=" row other-infos-container p-3">
                  <div className="col-md-4">

                    <h5>Civilité</h5>
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        `${
                          bakelisteDetailsData[0]?.civility === null
                            ? "Néant"
                            : bakelisteDetailsData[0]?.civility
                        }`
                      )}
                    </p>
                    
                  </div>
                  <div className="col-md-4">
                    <h5>Status</h5>
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        `${
                          bakelisteDetailsData[0]?.marital_status === null
                            ? "Néant"
                            : bakelisteDetailsData[0]?.marital_status
                        }`
                      )}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h5>Domaine</h5>
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        bakelisteDetailsData[0]?.domaine?.name
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-5 p-0 w-100 ml-3">
            <div className="w-100">

              <h4 className="text-center h-theme ff-inter fw-600 px-3 mb-3">Formation suivie</h4>
                <div className="row other-infos-container p-3">
                  <div className="col-md-4">
                    <h5>Domaine</h5>
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        bakelisteDetailsData[0]?.domaine?.name
                      )}
                    </p>
                  </div>
                  <div className="col-md-4 ">
                    <h5>Durée</h5>
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        timeFromX(
                          bakelisteDetailsData[0]?.debut_formation,
                          bakelisteDetailsData[0]?.fin_formation
                        )
                      )}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h5>Coach</h5>
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        bakelisteDetailsData[0]?.coach
                      )}
                    </p>
                  </div>
                </div>

                <div className="row other-infos-container p-3">
                  <div className="col d-flex flex-column mt-4">
                    <h5>Date de début</h5>
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        frenchShortFormatDate(
                          bakelisteDetailsData[0]?.debut_formation
                        )
                      )}
                    </p>
                  </div>
                  <div className="col d-flex flex-column mt-4">
                    <h5>Date de fin</h5>
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        frenchShortFormatDate(
                          bakelisteDetailsData[0]?.fin_formation
                        )
                      )}
                    </p>
                  </div>
                  <div className="col d-flex flex-column mt-4">
                    <h5>Horaire</h5>
                    {/* <p>{`${bakelisteDetailsData[0]?.horaire?.nbre_jours} fois par semaine`}</p> */}
                    <p className="mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : (
                        `${
                          bakelisteDetailsData[0]?.horaire === null
                            ? "Néant"
                            : `${bakelisteDetailsData[0]?.horaire?.nbre_jours} fois par semaine`
                        }`
                      )}
                    </p>
                  </div>
                </div>

                <div className="row other-infos-container p-3">
                  <div className="col d-flex flex-column mt-4">
                    <h5>Motivations</h5>
                    <p className="mt-3 styleText">
                      {isLoading ? (
                        <Skeleton height={40} />
                      ) : bakelisteDetailsData[0]?.objectifs === null ? (
                        "Néant"
                      ) : (
                        bakelisteDetailsData[0]?.objectifs
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 p-0 w-100">
            <div className="p-3 w-100 ml-3">

              <h4 className="text-center h-theme ff-inter fw-600 px-3 mb-3">Syllabus suivis</h4>
                <div className="col-md-12 row other-infos-container">
                  <div className="col-md-6">
                    <h5 className="mb-2">Syllabus</h5>
                    <ul className="p-0 m-0 mt-3">
                      {isLoading ? (
                        <Skeleton width={150} height={20} />
                      ) : bakelisteDetailsData[0]?.syllabus?.length > 0 ? (
                        bakelisteDetailsData[0]?.syllabus.map((item) => (
                          <li className="list-unstyled mb-2">
                       <FeatherIcon icon="chevron-right" className="list-inline-item" />
                            {item?.syllabus_name}
                          </li>
                        ))
                      ) : (
                        <p>Aucun syllabus</p>
                      )}
                    </ul>
                  </div>
                  <div className="col-md-6 ">
                    <h5 className="mb-2">Sous groupes</h5>
                    {isLoading ? (
                      <Skeleton width={150} height={20} />
                    ) : bakelisteDetailsData[0]?.sous_groupes?.length > 0 ? (
                      <ul className="p-0 m-0 mt-3">
                        {bakelisteDetailsData[0]?.sous_groupes.map(
                          (sousGroupe) => (
                            <li className="list-unstyled mb-2">
                       <FeatherIcon icon="chevron-right" className="list-inline-item" />
                              {sousGroupe?.name}
                            </li>
                          )
                        )}
                      </ul>
                    ) : (
                      <p>Aucun sous groupes</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-5 p-0 w-100 text-center">
              <h4 className="text-center w-100 mb-2">Informations du coach</h4>
              <div className="row other-infos-container p-3 w-100">
                <div className="col d-flex flex-column">
                  <h5>Nom Complet</h5>
                  <p>
                    {`${
                      bakelisteDetailsData?.coach?.last_name === null
                        ? "Néant"
                        : bakelisteDetailsData?.coach?.last_name
                    }`}{" "}
                    {`${
                      bakelisteDetailsData?.coach?.first_name === null
                        ? "Néant"
                        : bakelisteDetailsData?.coach?.first_name
                    }`}
                  </p>
                </div>
                <div className="col d-flex flex-column">
                  <h5>Status</h5>
                  <p>{`${
                    bakelisteDetailsData?.status === null ? "Néant" : bakelisteDetailsData?.coach?.status
                  }`}</p>
                </div>
                <div className="col d-flex flex-column">
                  <h5>Domaine</h5>
                  <p>{bakelisteDetailsData?.domaine}</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-lg-3 activity-bakeliste-container p-3 w-100 wave">
          <div className="bg-white vh-100 p-3 w-100">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#009688"
                fill-opacity="1"
                d="M0,96L34.3,85.3C68.6,75,137,53,206,80C274.3,107,343,181,411,186.7C480,192,549,128,617,96C685.7,64,754,64,823,96C891.4,128,960,192,1029,229.3C1097.1,267,1166,277,1234,272C1302.9,267,1371,245,1406,234.7L1440,224L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
              ></path>
            </svg>
            <h6 className="text-center h-theme ff-inter fw-600 px-3 mb-2">Activités</h6>
            <div className="row w-100">
              <div className="mt-2 mb-2 d-flex align-items-center">
                <FeatherIcon
                  icon="check-square"
                  className="ml-2"
                  style={{ color: "#009688" }}
                />
                <p className="pl-2">
                  Nombre de livraison{" "}
                  <span
                    className="nbre"
                    style={{ fontWeight: "bold", fontSize: "18px" }}
                  >
                    {bakelisteDetailsData[0]?.livraisons?.length}
                  </span>
                </p>
              </div>
              <div className="mt-2 mb-2 d-flex align-items-center">
                <FeatherIcon
                  icon="award"
                  className="ml-2"
                  style={{ color: "#009688" }}
                />
                <p className="pl-2">
                  Nombre de tâches validées{" "}
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {bakelisteDetailsData[0]?.nbreTacheValidee}
                  </span>
                </p>
              </div>
              <div className="mt-2 mb-2 d-flex align-items-center">
                <FeatherIcon
                  icon="x-circle"
                  className="ml-2 "
                  style={{ color: "#F44336" }}
                />
                <p className="pl-2">
                  Nombre d'abscence{" "}
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {bakelisteDetailsData[0]?.absence}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default DetailsBakelisteRefact;
