import React, { useEffect, useReducer } from "react";
import "./bakelisteSuiviDB.css";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import CardUtilisateur from "../cardUtilisateur/cardUtilisateur";
import ListeBakelistesPause from "../listeBakelistesPause/listeBakelistesPause";
import ListeBakelistesAbandonnes from "../listeBakelistesAbandonnes/listeBakelistesAbandonnes";
import axios from "axios";
import API from "../../variablesGlobales";
import CardUtilisateurSkeleton from "../cardUtilisateurSkeleton/cardUtilisateurSkeleton";
import BakelisteEnPresentiel from "../bakelisteEnPresentiel";
import BakelisteEnLigne from "../bakelisteEnLigne";
import StatisticBakeliste from "../statisticBakeliste/statisticBakeliste";
import ListeComptesBloques from "../listeComptesBloques/listeComptesBloques";
import ListeBakelistesArchives from "../listeBakelistesArchives/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBakelisteAsync,
  getBakelisLabesAsync,
  getBakelisteGyAsync,
  getBakelisteThiesAsync,
  isFetchingBakeliste,
  isLoadingBakeliste,
  isLoadingBKT,
  showListBakelisLabes,
  showListBakeliste,
  showListBakelisteGy,
  showListBakelisteThies,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import CardUtilisateurRefact from "../cardUtilisateur/CardUtilisateurRefact";
import BakelisteEnPresentielRefact from "../bakelisteEnPresentiel/BakelisteEnPresentielRefact";
import ListeCompteBloquesRefact from "../listeComptesBloques/ListeCompteBloquesRefact";
import ListeBakelistesAbondonnesRefact from "../listeBakelistesAbandonnes/ListeBakelistesAbondonnesRefact";
import BakelisteEnligneRefact from "../bakelisteEnLigne/BakelisteEnligneRefact";
import ListeBakelistesArichivesRefact from "../listeBakelistesArchives/ListeBakelistesArichivesRefact";
import ListeBakelistesPauseRefact from "../listeBakelistesPause/ListeBakelistesPauseRefact";

const BakelisteSuiviDBRefact = () => {
  const dispatch = useDispatch();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      bakelistesGY2: [],
      bakelistesThies: [],
      bakelistesLabe: [],
      allBakelistes: 0,
      abandonnes: 0,
      pause: 0,
      online: 0,
      presentiel: 0,
      actifs: 0,
      bakelistes: [],
      is_loading: true,
    }
  );

  const allBakelisteRedux = useSelector(showListBakeliste);
  const bakelisteGyRedux = useSelector(showListBakelisteGy)
  const bakelisteThiesRedux = useSelector(showListBakelisteThies)
  const bakelisteLabesRedux = useSelector(showListBakelisLabes)
  const isLoading = useSelector(isLoadingBakeliste);

  useEffect(() => {
    allBakelisteRedux.length === 0 &&
      dispatch(getAllBakelisteAsync()) &&
      dispatch(isFetchingBakeliste());

      dispatch(getBakelisteGyAsync())
      dispatch(getBakelisteThiesAsync())
      dispatch(getBakelisLabesAsync())
    // getListeAllBakelistes();
    // getListeBakelistesLabe();
    // getListeBakelistesThies();
    // getListeBakelistesGY2();
    getStatisticBakeliste();
  }, [dispatch]);


  const getStatisticBakeliste = () => {
    axios
      .get(state.api + "count-bakelistes-statistics")
      .then((res) => {
        // console.log("count", res);
        setState({
          allBakelistes: res.data.allbakelistes,
          abandonnes: res.data.abandonnes,
          online: res.data.online,
          pause: res.data.paused,
          presentiel: res.data.presentiel,
          actifs: res.data.actifs,
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const getListeBakelistesThies = () => {
    axios
      .get(state.api + "list-bakelistes-thies")
      .then((res) => {
        setState({
          bakelistesThies: res.data.data,
        });
        // console.log(state.bakelistesThies, "bt")
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const getListeBakelistesLabe = () => {
    axios
      .get(state.api + "list-bakelistes-labe")
      .then((res) => {
        setState({
          bakelistesLabe: res.data.data,
        });
        // console.log(state.bakelistesLabe, "bl")
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const getListeAllBakelistes = () => {
    axios
      .get(state.api + "bakelistes-in-training")
      .then((res) => {
        setState({
          bakelistes: res.data.data,
          is_loading: false,
        });
        // console.log(state.bakelistes)
      })
      .catch((error) => {
        setState({
          // loading: false,
          // getListeBakelisteRequestError: true,
        });
        // console.log(error.message);
      });
  };


  return (
    <div className="component-bakeliste-suivi-d-b">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            <nav>
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon
                    className="icon-breadcrumb-custom"
                    icon="users"
                  />
                  BD Bakelistes
                </li>
              </ol>
            </nav>
            <StatisticBakeliste
              allBakelistes={state.allBakelistes}
              countbakpause={state.pause}
              countbakabandon={state.abandonnes}
              countbakonline={state.online}
              countbakpresentiel={state.presentiel}
              countbakactifs={state.actifs}
            />
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="allbak-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="allbak"
                  aria-selected="true"
                >
                  All Bakelistes
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="dakar-tab"
                  data-toggle="tab"
                  href="#dakar"
                  role="tab"
                  aria-controls="dakar"
                  aria-selected="false"
                >
                  Dakar
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="thies-tab"
                  data-toggle="tab"
                  href="#thies"
                  role="tab"
                  aria-controls="thies"
                  aria-selected="false"
                >
                  Thies
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="labe-tab"
                  data-toggle="tab"
                  href="#labe"
                  role="tab"
                  aria-controls="labe"
                  aria-selected="false"
                >
                  Labe
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="presentiel-tab"
                  data-toggle="tab"
                  href="#presentiel"
                  role="tab"
                  aria-controls="presentiel"
                  aria-selected="false"
                >
                  Presentiel
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="online-tab"
                  data-toggle="tab"
                  href="#online"
                  role="tab"
                  aria-controls="online"
                  aria-selected="false"
                >
                  En Ligne
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pause-tab"
                  data-toggle="tab"
                  href="#pause"
                  role="tab"
                  aria-controls="pause"
                  aria-selected="false"
                >
                  En Pause
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="abandon-tab"
                  data-toggle="tab"
                  href="#abandon"
                  role="tab"
                  aria-controls="abandon"
                  aria-selected="false"
                >
                  Abandonnes
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="blocked-tab"
                  data-toggle="tab"
                  href="#blocked"
                  role="tab"
                  aria-controls="blocked"
                  aria-selected="false"
                >
                  Bloques
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="archives-tab"
                  data-toggle="tab"
                  href="#archives"
                  role="tab"
                  aria-controls="archives"
                  aria-selected="false"
                >
                  Archivés
                </a>
              </li>
            </ul>
            <div class="tab-content mt-5" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="allbak"
                role="tabpanel"
                aria-labelledby="allbak-tab"
              >
                <div class="row">
                  {isLoading && <CardUtilisateurSkeleton />}

                  {allBakelisteRedux.length === 0 && isLoading === true ? (
                    <p className="pComment">Pas de bakelistes</p>
                  ) : (
                    allBakelisteRedux.map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-4 col-md-4 col-sm-4 mb-4 px-lg-3 px-md-2"
                      >
                        <CardUtilisateurRefact
                          data={item}
                          bakelisteFullname={item.bakelisteFullname}
                          domaine={item.domaine}
                          email={item.email}
                          coachFullname={item.coachFullname}
                          commentaire={item.commentaire}
                          phone={item.phone}
                          debut_formation={item.debut}
                          fin_formation={item.fin}
                          id={item.id}
                          getListeAllBakelistes={getListeAllBakelistes}
                          is_recruitable={item.is_recruitable}
                          is_online={item.is_online}
                          is_presentiel={item.is_presentiel}
                          is_completed={item.is_completed}
                          is_blocked={item.is_account_blocked}
                          is_archived={item.is_archived}
                          syllabus={item.syllabus}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="dakar"
                role="tabpanel"
                aria-labelledby="dakar-tab"
              >
                <div class="row">
                  {isLoading && <CardUtilisateurSkeleton />}

                  {bakelisteGyRedux.length === 0 &&
                  isLoading === false ? (
                    <p className="pComment">
                      Il n'y a pas encore de bakeliste dans cette localité.
                    </p>
                  ) : (
                    bakelisteGyRedux.map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-4 col-md-4 col-sm-4 mb-4 px-lg-3 px-md-2"
                      >
                        <CardUtilisateurRefact
                          bakelisteFullname={item.bakelisteFullname}
                          domaine={item.domaine}
                          email={item.email}
                          coachFullname={item.coachFullname}
                          commentaire={item.commentaire}
                          phone={item.phone}
                          debut_formation={item.debut}
                          fin_formation={item.fin}
                          id={item.id}
                          getListeAllBakelistes={getListeAllBakelistes}
                          is_recruitable={item.is_recruitable}
                          is_online={item.is_online}
                          is_completed={item.is_completed}
                          is_blocked={item.is_account_blocked}
                          is_archived={item.is_archived}
                          syllabus={item.syllabus}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="thies"
                role="tabpanel"
                aria-labelledby="thies-tab"
              >
                <div class="row">
                  {isLoading && <CardUtilisateurSkeleton />}

                  {bakelisteThiesRedux.length === 0 &&
                  isLoading === false ? (
                    <p className="pComment">
                      Il n'y a pas encore de bakeliste dans cette localité.
                    </p>
                  ) : (
                    bakelisteThiesRedux.map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-4 col-md-4 col-sm-4 mb-4 px-lg-3 px-md-2"
                      >
                        <CardUtilisateurRefact
                          bakelisteFullname={item.bakelisteFullname}
                          domaine={item.domaine}
                          email={item.email}
                          coachFullname={item.coachFullname}
                          commentaire={item.commentaire}
                          phone={item.phone}
                          debut_formation={item.debut}
                          fin_formation={item.fin}
                          id={item.id}
                          getListeAllBakelistes={getListeAllBakelistes}
                          is_recruitable={item.is_recruitable}
                          is_online={item.is_online}
                          is_completed={item.is_completed}
                          is_blocked={item.is_account_blocked}
                          is_archived={item.is_archived}
                          syllabus={item.syllabus}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="labe"
                role="tabpanel"
                aria-labelledby="labe-tab"
              >
                <div class="row">
                  {isLoading && <CardUtilisateurSkeleton />}

                  {bakelisteLabesRedux.length === 0 &&
                  isLoading === false ? (
                    <p className="pComment">
                      Il n'y a pas encore de bakeliste dans cette localité.
                    </p>
                  ) : (
                    bakelisteLabesRedux.map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-4 col-md-4 col-sm-4 mb-4 px-lg-3 px-md-2"
                      >
                        <CardUtilisateurRefact
                          bakelisteFullname={item.bakelisteFullname}
                          domaine={item.domaine}
                          email={item.email}
                          coachFullname={item.coachFullname}
                          commentaire={item.commentaire}
                          phone={item.phone}
                          debut_formation={item.debut}
                          fin_formation={item.fin}
                          id={item.id}
                          getListeAllBakelistes={getListeAllBakelistes}
                          is_recruitable={item.is_recruitable}
                          is_online={item.is_online}
                          is_completed={item.is_completed}
                          is_blocked={item.is_account_blocked}
                          is_archived={item.is_archived}
                          syllabus={item.syllabus}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="presentiel"
                role="tabpanel"
                aria-labelledby="presentiel-tab"
              >
                <BakelisteEnPresentielRefact />
              </div>
              <div
                class="tab-pane fade"
                id="online"
                role="tabpanel"
                aria-labelledby="online-tab"
              >
                <BakelisteEnligneRefact />
              </div>
              <div
                class="tab-pane fade"
                id="pause"
                role="tabpanel"
                aria-labelledby="pause-tab"
              >
                <ListeBakelistesPauseRefact />
              </div>
              <div
                class="tab-pane fade"
                id="abandon"
                role="tabpanel"
                aria-labelledby="abandon-tab"
              >
                <ListeBakelistesAbondonnesRefact />
              </div>
              <div
                class="tab-pane fade"
                id="blocked"
                role="tabpanel"
                aria-labelledby="blocked-tab"
              >
                <ListeCompteBloquesRefact />
              </div>
              <div
                class="tab-pane fade"
                id="archives"
                role="tabpanel"
                aria-labelledby="archives-tab"
              >
                <ListeBakelistesArichivesRefact />
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BakelisteSuiviDBRefact;
