import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { history } from "../../../components/history";
import { API } from "../../../variablesGlobales";

const initialState = {
  isLoading: false,
  allCoachs: [],
  archivedCoachs: [],
};

const API_URL = API;

export const coachsSlice = createSlice({
  name: "listAllCoachs",
  initialState,

  reducers: {
    isFetching: (state) => {
      state.isLoading = true;
    },

    getAllCoachs: (state, action) => {
      state.allCoachs = action.payload;
    },

    getArchivedCoachs: (state, action) => {
      state.archivedCoachs = action.payload;
    },

    isFetchingDone: (state) => {
      state.isLoading = false;
    },
  },
});

export const getAllCoachsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}liste-des-coachs`);
    if (res.data.success) {
      dispatch(isFetchingDone());
      dispatch(getAllCoachs(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getArchivedCoachsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${API_URL}liste-des-coachs-assistants-archives`
    );
    if (res.data.success) {
      //   dispatch(isFetchingDone());
      dispatch(getArchivedCoachs(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
}; 

export const archiveCoach = (id) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}archive-user/${id}`);
    if (res.data.success) {
      toast.success(
        "coach archivé avec success",
        { position: toast.POSITION.TOP_RIGHT }, 
        { autoClose: 1000 }
      );
      dispatch(getAllCoachsAsync());
    } else {
      toast.error(
        "erreur sur l'archivage",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};


export const updateCoachAsync = (user_id, data) => async (dispatch) => {
  try {
    const response = await axios.put(
      `${API_URL}update-coach-infos/${user_id}`,
      data
    );
    if (response.data.success) {
      toast.success(
        "coach modifié avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getAllCoachsAsync());
      history.push("/parametres-admin");
    } else {
      toast.error(
        "Erreur sur la modification!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};
export const addCoachAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}add-coach/`, data);
    if (response.data.success) {
      toast.success(
        "coach Ajouté avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getAllCoachsAsync());
      history.push("/parametres-admin");
    } else {
      toast.error(
        "Erreur sur l'ajout!!",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};
export const { isFetching, getAllCoachs, getArchivedCoachs, isFetchingDone } =
  coachsSlice.actions;
export const isLoading = (state) => state.listAllCoachs.isLoading;
export const showListCoachs = (state) => state.listAllCoachs.allCoachs;
export const showArchivedCoachs = (state) => state.listAllCoachs.archivedCoachs;
export default coachsSlice.reducer;
