import React from "react";
import "./parametresAdminView.css";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import NavTabsParametres from "../navTabsParametres/navTabsParametres";
import NavTabsParametresRefact from "../navTabsParametres/NavTabsParametresRefact";
const ParametresAdminViewRefact = () => {
  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-xl-2 col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <NavTabsParametresRefact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParametresAdminViewRefact;
