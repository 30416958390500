import React, { useState } from "react";
import { Link } from "react-router-dom";
import AutresInfos from "./AutresInfos";
import FormationBakeli from "./FormationBakeli";
import InformationPersonnelle from "./InformationPersonnelle";
import MultiStepProgress from "./MultiStepProgress";

const MultiStepForm = ({
  page,
  setPage,
  bakelisteInfos,
  setBakelisteInfos,
  domaines,
  coachs,
  days,
  apercu,
  setApercu,
  coachAssistants,
}) => {
  const [x, setX] = useState(0);
  const components = [
    <InformationPersonnelle
      x={x}
      setX={setX}
      page={page}
      setPage={setPage}
      bakelisteInfos={bakelisteInfos}
      setBakelisteInfos={setBakelisteInfos}
    />,
    <FormationBakeli
      x={x}
      setX={setX}
      days={days}
      domaines={domaines}
      page={page}
      setPage={setPage}
      bakelisteInfos={bakelisteInfos}
      setBakelisteInfos={setBakelisteInfos}
    />,
    <AutresInfos
      coachs={coachs}
      apercu={apercu}
      coachAssistants={coachAssistants}
      setApercu={setApercu}
      x={x}
      setX={setX}
      page={page}
      setPage={setPage}
      bakelisteInfos={bakelisteInfos}
      setBakelisteInfos={setBakelisteInfos}
    />,
  ];
  return (
    <section
      style={{
        backgroundColor: "white",
        padding: "2rem",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <MultiStepProgress page={page} />

      <form encType="multipart/form-data">{components[page]}</form>
    </section>
  );
};

export default MultiStepForm;
