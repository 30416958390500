import React, { useEffect } from "react";
import "./listCoachAssistants.css";
// import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import axios from "axios";
import API from "../../variablesGlobales";
import FeatherIcon from "feather-icons-react";
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from "rsuite";
import { Link } from "react-router-dom";

import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { archiveCoach, getCoachAssistantAsync, showListCoachsAssistant } from "../../redux/slices/coachAssistantSlice/coachAssistantSlice";
import { history } from "../history";
toast.configure();
const ListCoachAssistantsRefact = () => {
  const dispatch = useDispatch();
  const coachAssistantData = useSelector(showListCoachsAssistant);

  useEffect(() => {
    dispatch(getCoachAssistantAsync())
  }, []);
  const handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    console.log("This is my custom function for ExportCSVButton click event");
    onClick();
  };
 const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton style={{ "background": "#009688", "margin": "1rem" }}  btnText="Export CSV" />
    );
  };
  
 const archiverCoachAssistant = (id) => {
  dispatch(archiveCoach(id))
    // axios
    //   .post(state.api + "archive-user/" + id)
    //   .then((response) => {
    //     // console.log(response);
    //     if (response.data.success === true) {
    //       getListCoachAssistants();
    //       toast.success(
    //         "Coach assistant archivé avec success",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     } else {
    //       toast.error(
    //         "Erreur sur l'archivage de ce coach assistant!!!",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error.message);
    //   });
  };
  
 const actionformater = (cell, row) => {
    return (
      <span>
      <FeatherIcon
        icon="archive"
  
        id="delete_group"
        style={{
          color: "red",
          height: "21px",
          width: "22px",
          cursor: "pointer",
        }}
        onClick={() => archiverCoachAssistant(row.id)}
      />
      &nbsp;&nbsp;
      
      <Link
                to={{
                  pathname: "/edit-coach",
                  state: { id: row.id, data: row },
                }}
              >
        <FeatherIcon
          title="modifier les détails"
          icon="edit-2"
          style={{
            color: "#469789",
            height: "21px",
            width: "22px",
            cursor: "pointer",
          }}
          className="ajouter"
          cursor="pointer"
        />
        </Link>
        &nbsp;&nbsp;
     
     
    </span>
    );
  };
 const addCoachAssistant = () => {
   history.push("/ajout-coach-assistant")
  }
  const options = {
    exportCSVBtn: createCustomExportCSVButton,
  };
  return (
    <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
            <button
              className="btn-add-with-icon-plus-circle ml-md-auto"
              onClick={addCoachAssistant}
            >
              Ajouter
              <Icon className="icon-add ml-2" icon="plus-circle" />
            </button>
            {coachAssistantData.length > 0 ? (
              <BootstrapTable
                data={coachAssistantData}
                striped
                version="4"
                exportCSV
                csvFileName="Liste des coachs assistants.csv"
                bordered={false}
                bodyStyle={{
                  fontSize: 12,
                  color: "rgba(0, 0, 0, 0.7)",
                  fontFamily: "inherit",
                  fontWeight: 400,
                }}
                tdStyle={{ background: "red" }}
                tableHeaderclassName="header-table-groupe-view"
                wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                className="table-responsive table-responsive-sm"
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID{" "}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="first_name"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Prénoms
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="last_name"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Noms
                  </span>
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="email"
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Email
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataFormat={actionformater}
                  width="150"
                  thStyle={{ border: "none", fontWeight: "400" }}
                  tdStyle={{
                    border: "none",
                    padding: "1rem",
                    "font-size": "14px",
                    "font-family": "Montserrat",
                  }}
                >
                  <span className="color-nom-prenom-utilisateurs headerStyle">
                    Actions
                  </span>
                </TableHeaderColumn>
              </BootstrapTable>
            ) : (
              <p className="pComment">
                Il n'y a pas encore de coachs assistants actifs.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCoachAssistantsRefact;
