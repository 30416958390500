import React from "react";
import { FcCalendar } from "react-icons/fc";
import { HiUserGroup } from "react-icons/hi";
import { IoIosVideocam } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageJeemaCoder from "../../img/imageJeemaCoder.png";
import {
  getAllEventsAsync,
  getRecentRencontreAsync,
  showListEvents,
  showRecentEvents,
} from "../../redux/slices/eventsSlices/eventSlice";
import base_api_url from "../../variablesGlobales";
import "./event.css";

// Event incoming card item
const EventCardItem = (props) => {
  const {
    eventBanner,   
    eventName,
    eventDescription,
    eventSubDescription,
    eventFooterInfo,
    eventId,
  } = props;
  return (
    <Link to={`/details/event/${eventId}`}>
      <div className="card eventCardItem">
        <img
          src={
            eventBanner === null
              ? ImageJeemaCoder
              : base_api_url.base_api_url +
                "/uploads/rencontre_files/" +
                eventBanner
          }
          className="card-img-top"
          alt="..."
        />
        <div className="card-body eventCardItem__body">
          <h5 class="card-title">{eventName}</h5>
          <p className="card-text eventDescription text-break">
            {eventDescription}
          </p>

          <div className="eventSubDescription">
            <p>{eventSubDescription}</p>
          </div>
        </div>
        <div class="card-footer eventCardItem__footer">
          <span className="eventCardItem__footerIcon">
            <FcCalendar size={20} className="mx-1" /> En cours
          </span>

          <span className="eventCardItem__footerIcon">
            {eventFooterInfo} <HiUserGroup size={20} className="mx-1" />
          </span>
        </div>
      </div>
    </Link>
  );
};

//Event recent 
const EventRecentCardItem = (props) => {
  const {
    eventBanner,   
    eventName,
    eventDescription,
    eventSubDescription,
    eventFooterInfo,
    eventId,
  } = props;
  return (
    <Link to={`/details/event/${eventId}`}>
      <div className="card eventCardItem">
        <img
          src={
            eventBanner === null
              ? ImageJeemaCoder
              : base_api_url.base_api_url +
                "/uploads/rencontre_files/" +
                eventBanner
          }
          className="card-img-top"
          alt="..."
        />
        <div className="card-body eventCardItem__body">
          <h5 class="card-title">{eventName}</h5>
          <p className="card-text eventDescription text-break">
            {eventDescription}
          </p>

          <div className="eventSubDescription">
            <p>{eventSubDescription}</p>
          </div>
        </div>
        <div class="card-footer eventCardItem__footer">
          <span className="eventCardItem__footerIcon">
            Inscrits
          </span>

          <span className="eventCardItem__footerIcon">
            {eventFooterInfo} <HiUserGroup size={20} className="mx-1" />
          </span>
        </div>
      </div>
    </Link>
  );
};

// Left pane cards
const NombreEvents = (props) => {
  const { commingEvents, pastEvents } = props;
  return (
    <div className="eventsNumberCard">
      <span>
        <FcCalendar size={20} /> Événements
      </span>
      <div className="eventsNumberCard__count">
        <div className="commingCount">
          <span>{commingEvents}</span>A venir
        </div>

        <div className="commingCount">
          <span>{pastEvents}</span>Passé
        </div>

        {/* <span>{pastEvents}</span> */}
      </div>
    </div>
  );
};

const NextEvent = () => {
  return (
    <div className="nextEventCard">
      <span>
        <FcCalendar size={20} /> Prochain évenement
      </span>
      <Link to="/create-event">
        <div className="nextEventCard__btn mt-2">
          <button className="btn form-control">
            <span>
              <IoIosVideocam />
            </span>
            Créer
          </button>
        </div>
      </Link>
    </div>
  );
};

const EventsRefac = () => {
  const dispatch = useDispatch();
  const dataEvents = useSelector(showListEvents);
  const dataRecentEvents = useSelector(showRecentEvents);

  React.useEffect(() => {
    dispatch(getAllEventsAsync());
    dispatch(getRecentRencontreAsync());
  }, []);


  
  return (
    // console.log("recentd", dataRecentEvents),
    <div className="component-event">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                  <Link to="/dashboard-admin" className="lien_nav">
                    Dashboard{" "}
                  </Link>
                </li>
                <li
                  className="lien_nav_current breadcrumb-item"
                  aria-current="page"
                >
                  evenements
                </li>
              </ol> 
            </nav>
            <div className="mt-3">

            <div className="col-xl-6 col-lg-6 col-md-6">
                  <h6 className="h-theme ff-inter fw-600 px-3 mb-3">
                    Evenements à venir
                  </h6>
                </div>
            {/* events comming */}
            <div className="row"> 
             
              {dataEvents.map((event) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 my-2 event_wrapper"
                  key={event?.id}
                >
                  <EventCardItem
                    eventBanner={event?.rencontre_file}
                    eventName={event?.rencontre_name}
                    eventDescription={event?.rencontre_description}
                    // eventSubDescription="sub description here"
                    eventFooterInfo={event?.nbreParticipant}
                    eventId={event?.id}
                  />
                </div>
              ))}
            </div>
            </div>

            {/* old events */}
            <div className="mt-3">
            <div className="col-xl-6 col-lg-6 col-md-6">
                  <h6 className="h-theme ff-inter fw-600 px-3 mb-3">
                  Autres Evenements de Bakeli
                  </h6>
                </div>

                <div className="row"> 
             
             {dataRecentEvents.map((event) => (
               <div
                 className="col-xl-4 col-lg-4 col-md-6 my-2 event_wrapper"
                 key={event?.id}
               >
                 <EventRecentCardItem
                   eventBanner={event?.rencontre_file}
                   eventName={event?.rencontre_name}
                   eventDescription={event?.rencontre_description}
                   // eventSubDescription="sub description here"
                   eventFooterInfo={event?.nbreParticipant}
                   eventId={event?.id}
                 />
               </div>
             ))}
           </div>
           </div>
          </div>

          <div className="col-lg-3">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-sm-6 mt-lg-5 mt-sm-2">
                <NombreEvents
                  commingEvents={
                    dataEvents?.length === 0 ? 0 : dataEvents?.length
                  }
                  pastEvents={dataRecentEvents?.length === 0 ? 0 :dataRecentEvents?.length}
                  //   pastEvents={}
                />
              </div>

              <div className="col-xl-12 col-lg-12 col-sm-6 mt-lg-2 mt-sm-2">
                <NextEvent 
                recentEvents={dataRecentEvents?.length === 0 ? 0 :dataRecentEvents?.length}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsRefac;
