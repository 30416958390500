const frenchFormatDate = (datetoformat) => {
  const date = new Date(datetoformat);
  return date.toLocaleString("fr-FR", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};
const frenchShortFormatDate = (datetoformat) => {
  const date = new Date(datetoformat);
  return date.toLocaleString("fr-FR", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

module.exports = {
  frenchFormatDate: frenchFormatDate,
  frenchShortFormatDate: frenchShortFormatDate,
};
