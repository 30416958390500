import React, { useEffect, useReducer } from "react";

import "./listeDomainesAdmin.css";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { AutoComplete, Icon, InputGroup } from "rsuite";
import SyllabusDomaine from "../syllabusDomaine/index";
import SyllabusDomaineMarketingDigital from "../syllabusDomaineMarketingDigital/index";
import SyllabusDomaineDisign from "../syllabusDomaineDisign/index";
import SyllabusDomaineGestionProjet from "../syllabusDomaineGestionProjet/index";
import SyllabusDomaineBureautique from "../syllabusDomaineBureautique/index";
import SyllabusDomaineAnglais from "../syllabusDomaineAnglais/index";
import AddDomaine from "../addDomaine/index";
import { Link } from "react-router-dom";
import API from "../../variablesGlobales";
import TableMyDomaine from "../../components/tableMyDomaine/index";
import axios from "axios";
import $ from "jquery";
import { useDispatch } from "react-redux";
import { getSyllabusAsync, showListDomaines } from "../../redux/slices/parametreSlices/parametreSlice";
import { useSelector } from "react-redux";
import AddDomaineRefact from "../addDomaine/AddDomaineRefact";
import TableMyDomaineRefact from "../tableMyDomaine/TableMyDomaineRefact";
const ListeDomaineAdminRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      domaines: [],
      api: API.API,
    }
  );

  
  const dispatch = useDispatch()
  useEffect(() => {
    getAllDomaines();
    dispatch(getSyllabusAsync(state.userID))

  }, []);

  const getAllDomaines = () => {
    axios
      .get(state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        setState({
          domaines: domains.data,
        });
        // console.log(state.domaines)
      })
      .catch((error) => {
        //console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };
  return (
    <div className="p-b-200">
      <div className="row">
        <div className="col-lg-9">
          <div className="row m-b-15">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600 px-3">
                {" "}
                Gestion des Domaines 
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5">
            </div>
            <div className="col dis-flex m-b-15">
              <AddDomaineRefact  />
            </div>
          </div>

          <div className="row px-lg-0 px-md-2">
            <TableMyDomaineRefact />
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeDomaineAdminRefact;
