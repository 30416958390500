import React, { Component } from "react";
import "./listeBakelistesPause.css";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import API from "../../variablesGlobales";
import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeBakelistesPauseActions from "../../store/listeBakelistesPause/actions";
export default class listeBakelistesPause extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bakelistespause: [],
      api: API.API,
    };
  }
  componentDidMount() {
    this.getListesBakelistesPause();
  }

  getListesBakelistesPause = () => {
    axios
      .get(this.state.api + "bakelistes-training-pause-list")
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            bakelistespause: res.data.data,
            loading: true,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
        }
        // this.setState({ isLoaded: false });
        // $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };
  actionsFormatter = (cell, row) => {
    let id = row.id;

    console.log(row.id);
    return (
      <span>
        <Tooltip title="Activer la formation">
          <FeatherIcon
            icon="corner-down-left"
            className="btn-icon-table"
            cursor="pointer"
            onClick={(e) => this.activatePausedTraining(row, e)}
          />
        </Tooltip>
      </span>
    );
  };
  activatePausedTraining = (row) => {
    var fm = new FormData();
    fm.append("bakeliste_id", row.id);
    axios
      .post(this.state.api + "activate-paused-bakeliste-training", fm)
      .then((res) => {
        if (res.data.success === true) {
          toast.success(
            " Formation reactivee avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 500 }
          );
          this.getListesBakelistesPause();
        } else {
          toast.error(
            "Erreur sur la reactivation, réessayer!!!!'",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      });
  };

  nameFormatter(cell, row) {
    return `${row.first_name} ${row.last_name}`;
  }
  render() {
    const options = {
      exportCSVBtn: this.CustomExportCsvBtn,
    };
    return (
      <div className="component-liste-bakelistes-pause">
        {" "}
        <BootstrapTable
          pagination
          exportCSV
          options={options}
          csvFileName="liste-bakelistes-pause.csv"
          data={this.state.bakelistespause}
          striped
          version="4"
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn
            filter={{ type: "TextFilter", delay: 1000 }}
            dataField="first_name"
            dataFormat={this.nameFormatter}
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            isKey={true}
            dataField="email"
            width="200"
            filter={{ type: "TextFilter", delay: 1000 }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Email</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="phone"
            width="200"
            filter={{ type: "TextFilter", delay: 1000 }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Telephone</span>
          </TableHeaderColumn>

          {/* <TableHeaderColumn
            dataField="status"
            width="150"
            filter={{ type: "TextFilter", delay: 1000 }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Statut</span>
          </TableHeaderColumn> */}

          <TableHeaderColumn
            dataField=""
            dataFormat={this.actionsFormatter}
            dataSort
            // filter={{ type: "TextFilter", delay: 1000 }}
            // tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            // width="170"
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}
// export default connect(
//     ({ listeBakelistesPause }) => ({ ...listeBakelistesPause }),
//     dispatch => bindActionCreators({ ...listeBakelistesPauseActions }, dispatch)
//   )( listeBakelistesPause );
