import React, { useEffect, useReducer } from "react";
import "./listeSousGroupesAdmin.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";

import MultiSelect from "@khanacademy/react-multi-select";
import { Icon, Modal } from "rsuite";
import SweetAlert from "react-bootstrap-sweetalert";

import API from "../../variablesGlobales";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { archiveSousGroupesAsync, getSousGroupesAsync, showListSousGroupes, updateSousGroupeAsync } from "../../redux/slices/parametreSlices/parametreSlice";
import { getAllBakelisteAsync, showListBakeliste } from "../../redux/slices/bakelistesSlices/bakelistesSlice";
const ListeSousGroupesAdminRefact = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      coach_id: window.sessionStorage.getItem("user_id"),
      loading: false,
      //syllabus_id:props.match.params.id,
      groupes: [],
      datas: [],

      sous_groupes: [],
      groupeInfos: [],
      sgroupe: {},
      sgroupeInfos: [],
      liste: [],
      syllabus: [],
      noSavedData: [],
      selectOptions: [],
      selected: [],
      bakelistes: [],
      ajoutEnCours: false,
      ajoutSuccessAlert: false,
      isAjoutError: false,
      fields: {},
      onlySelect: false,
      groupySelect: true,
      showAlertSuccess: false,
      showAlertError: false,
      showAlertDeleteSuccess: false,
      showAlertDeleteError: false,
      name: "",
      lien_whatsapp: "",
      size: "md",
      showEditModal: false,
      editSousGroupeInProgress: false,
      voirListe: false,
      voirSyllabus: false,
      able: true,
      abled: true,
    }
  );
  const dispatch = useDispatch()
  const sousGroupeData = useSelector(showListSousGroupes)
  const bakelisteData = useSelector(showListBakeliste)

  useEffect(() => {
    dispatch(getSousGroupesAsync())
    dispatch(getAllBakelisteAsync())
   // getSousGroupes();
    getBakelistes();
  }, []);

  const hideLoader = () => {
    setState({ loading: false });
  };

  const showLoader = () => {
    setState({ loading: true });
  };

  const onSubmitEditSousGroupe = (e) => {
    e.preventDefault();
    setState({
      editSousGroupeInProgress: true,
    });
    var fd = new FormData();
    fd.append("created_by", state.coach_id);
    fd.append("groupe_id", state.groupeId);
    fd.append("name", state.name);
    fd.append("lien_whatsapp", state.lien_whatsapp);
    dispatch(updateSousGroupeAsync(state.sgId,fd))
    setTimeout(()=>{
      setState({
        editSousGroupeInProgress: false,
        showEditModal: false,
      });
      close();
    },2000)
    // axios
    //   .put(state.api + "sous__groupes/" + state.sgId, fd)
    //   .then((response) => {
    //     setState({
    //       editSousGroupeNInProgress: true,
    //     });
    //     if (response.data.success) {
    //       setState({
    //         editSousGroupeInProgress: false,
    //         showEditModal: false,
    //       });
    //       close();
    //       getSousGroupes();
    //     } else {
    //       setState({
    //         editSousGroupeInProgress: false,
    //         showEditModal: false,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error.message);
    //     setState({
    //       editSousGroupeNInProgress: false,
    //     });
    //     if (process.env.NODE_ENV !== "production") {
    //       return;
    //     }
    //     Sentry.captureException(error);
    //   });
  };

  // ajouter un bakeliste dans un sous-groupe
  const ajoutBakeliste = () => {
    setState({
      ajoutEnCours: true,
      onlySelect: false,
      groupySelect: true,
    });
    let data = state.fields;
    if (state.onlySelect) {
      data["sous_groupe_id"] = state.sgroupeInfos.id;
      data["is_only"] = 1;
      data["is_groupy"] = 0;
      data["bakeliste_ids"] = state.fields.bakeliste_ids;
    } else if (state.groupySelect) {
      data["sous_groupe_id"] = state.sgroupeInfos.id;
      data["is_groupy"] = 1;
      data["is_only"] = 0;
      data["bakeliste_ids"] = state.selected;
    }
    axios
      .post(state.api + "sous_groupes/add-bakeliste", data)
      .then((res) => {
        if (res.data.success) {
          $("#date_groupe").val("");
          setState({
            ajoutEnCours: false,
            showAlertSuccess: true,
            fields: {},
          });
          // window.location = "/sous-groupe/" + props.id;
        } else {
          setState({
            showAlertError: true,
          });
        }
        $(".close-modal-bakeliste-title").click();
      })
      .catch((error) => {
        setState({
          ajoutEnCours: false,
          isAjoutError: true,
        });
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  // const getSousGroupes = () => {
  //   showLoader();
  //   axios
  //     .get(state.api + "sous__groupes")
  //     .then((res) => {
  //       setState({
  //         loading: false,
  //       });
  //       if (res.data.data != undefined) {
  //         setState({
  //           // groupes: res.data.data,
  //           sous_groupes: res.data.data,
  //         });
  //         // console.log("response", res.data.data);
  //       }
  //       hideLoader();
  //     })
  //     .catch((error) => {
  //       if (process.env.NODE_ENV !== "production") {
  //         return;
  //       }
  //       Sentry.captureException(error);
  //       hideLoader();
  //     });
  // };

  const actionsFormatterSousGroup = (cell, row) => {
    return (
      <span>
        <FeatherIcon
          icon="archive"
          onClick={() => {
            deleteSousGroup(row);
          }}
          id="delete_group"
          style={{
            color: "red",
            height: "21px",
            width: "22px",
            cursor: "pointer",
          }}
        />
        &nbsp;&nbsp;
        <FeatherIcon
          icon="plus-circle"
          onClick={() => {
            onGetSousGroupeInfos(row);
          }}
          data-toggle="modal"
          data-target="#ajouterBakelisteModale"
          className="ajouter"
          cursor="pointer"
        />
        &nbsp;&nbsp;
        <span className="voir">{row.sous_groupes_bakelistes.length}</span>
        <FeatherIcon
          icon="users"
          onClick={() => {
            getBakelisteBySousGroupes(row.id);
            onGetSousGroupeInfos(row);
          }}
          cursor="pointer"
          className="voir"
          data-toggle="modal"
          data-target="#voirListeModale"
        />
        &nbsp;&nbsp;
        <FeatherIcon
          icon="edit"
          onClick={() => {
            onGetSousGroupeInfos(row);
            editSousGroupeModal();
          }}
          cursor="pointer"
          className="edit"
        />
      </span>
    );
  };

  const editSousGroupeModal = (id) => {
    // onGetGroupeById(id);
    setState({
      showEditModal: true,
    });
  };
  const close = () => {
    setState({
      showEditModal: false,
    });
  };
  const deleteSousGroup = (sgroup) => {

    dispatch(archiveSousGroupesAsync(sgroup.id))
  };

  const onGetSousGroupeInfos = (sgroup) => {
    setState({
      sgroupeInfos: sgroup,
      sgId: sgroup.id,
      name: sgroup.name,
      lien_whatsapp: sgroup.lien_whatsapp,
      groupeId: sgroup.groupe_id,
    });
    // console.log(state.sgroupeInfos)
  };

  const getBakelisteBySousGroupes = (id) => {
    const _this = this;
    // showLoader();
    axios
      .get(state.api + "sous__groupes/" + id)
      .then((response) => {
        setState({
          loading: false,
        });
        if (response.data.success) {
          setState({
            liste: response.data.liste[0].reverse(),
            syllabus: response.data.syllabus[0].reverse(),
          });
        }
        hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        hideLoader();
      });
  };

  // liste des bakelistes
  const getBakelistes = () => {
   
    showLoader();
    axios
      .get(state.api + "bakelistes-by-coach/" + state.coach_id)
      .then((response) => {
        var data = response.data.data;
        if (data !== undefined) {
          setState({
            bakelistes: data,
          });
        }
        data.forEach((item) => {
          let infos = {
            label:
              item.first_name +
              " " +
              item.last_name +
              " - " +
              item.domaine_name,
            value: item.id,
          };
          state.selectOptions.push(infos);
        });
        hideLoader();
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        hideLoader();
      });
  };

  const showSyllabus = () => {
    setState({
      voirListe: false,
      voirSyllabus: true,
      abled: false,
      able: true,
    });
  };

  const showListe = () => {
    setState({
      voirListe: true,
      voirSyllabus: false,
      able: false,
      abled: true,
    });
  };

  const voirModal = (cell, row) => {
    return (
      <>
        <button>
          <Link
            to={{
              pathname: "/information-sous-groupe",
              state: { id: row.id, name: row.groupe_name, donnees: row },
            }}
          >
            {row.name}{" "}
          </Link>
        </button>
      </>
    );
  };
//   const options = {
//     clearSearchBtn: createCustomClearButton,
//   };
// console.log("data",state.bakelistes)
  return (
    <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
      <BootstrapTable
        data={sousGroupeData}
        striped
        version="4"
        bordered={false}
        exportCSV
        csvFileName="Liste des sous-groupes.csv"
        bodyStyle={{
          fontSize: 12,
          color: "rgba(0, 0, 0, 0.7)",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        tdStyle={{ background: "red" }}
        tableHeaderclassName="header-table-groupe-view"
        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
        className="table-responsive table-responsive-sm"
      >
        <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
          {" "}
          ID{" "}
        </TableHeaderColumn>
        <TableHeaderColumn
          // filter={{type:"TextFilter", delay: 1000}}
          dataField="name"
          width="250"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs headerStyle">
            Sous groupes
          </span>
        </TableHeaderColumn>
        <TableHeaderColumn
          className="titre-tab"
          dataField="groupe_name"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{ border: "none", padding: "1rem" }}
        >
          <span className="color-nom-prenom-utilisateurs headerStyle">
            Groupes
          </span>
        </TableHeaderColumn>
        <TableHeaderColumn
          className="titre-tab"
          dataField="description"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{ border: "none", padding: "1rem" }}
        >
          <span className="color-nom-prenom-utilisateurs headerStyle">
            Description
          </span>
        </TableHeaderColumn>

        <TableHeaderColumn
          className="titre-tab"
          dataField="action"
          dataFormat={actionsFormatterSousGroup.bind(this)}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          <span className="color-nom-prenom-utilisateurs headerStyle">
            Actions
          </span>
        </TableHeaderColumn>
      </BootstrapTable>
      <div>
        <hr />
      </div>
      <SweetAlert
        show={state.showAlertSuccess}
        onConfirm={() => setState({ showAlertSuccess: false })}
        success
        title="Ajout effectué avec succes!!!"
        style={{
          color: "#069A77",
          fontSize: "12px",
          fontFamily: "Work Sans, sans-serif",
          paddingTop: 60,
          paddingBottom: 40,
        }}
        confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
      />

      <SweetAlert
        show={state.showAlertError}
        onConfirm={() => setState({ showAlertError: false })}
        error
        title="Erreur sur l'ajout, veuillez reessayer!!!"
        style={{
          color: "#d9534f",
          fontSize: "12px",
          fontFamily: "Work Sans, sans-serif",
          paddingTop: 60,
          paddingBottom: 40,
        }}
        confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
      />
      <div
        className="modal fade"
        id="ajouterBakelisteModale"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addLivraisonBakelisteTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-content-bakeliste">
            <h5
              className="modal-bakeliste-title m-b-50 marge"
              id="addLivraisonBakelisteTitle"
            >
              Ajouter des bakelistes dans {state.sgroupeInfos.name}
            </h5>
            <button
              type="button"
              className="close-modal-bakeliste-title"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FeatherIcon icon="x" aria-hidden="true" />
            </button>
            <form encType="multipart/form-data" id="livrable_form">
              <div className="form-group col-md-12 marge">
                <label>Choisissez un ou des Bakelistes</label>
                <MultiSelect
                  overrideStrings={{
                    selectSomeItems: "Choisissez les bakelistes",
                    allItemsAreSelected: "Tous vos bakelistes on été choisis",
                    selectAll: "Sélectionnez tout",
                    search: "Rechercher",
                  }}
                  options={bakelisteData}
                  selected={state.selected}
                  onSelectedChanged={(selected) => setState({ selected })}
                />
              </div>

              {state.ajoutEnCours ? (
                <button
                  type="submit"
                  className="btn-theme text-up  marge m-t-30 w-full p-t-15 p-b-15"
                  disabled
                >
                  Ajout en cours &nbsp;
                  <FeatherIcon icon="loader" aria-hidden="true" />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-theme text-up"
                  onClick={() => ajoutBakeliste()}
                >
                  Ajouter
                </button>
              )}
            </form>
          </div>
        </div>
      </div>

      <Modal
        className="modal-responsive-md"
        size={state.size}
        show={state.showEditModal}
        onHide={close}
      >
        <form
          encType="multipart/form-data"
          onSubmit={(e) => onSubmitEditSousGroupe(e)}
        >
          <Modal.Header>
            <Modal.Title className="modal-contact-us">
              Modifier le sous-groupe {state.sgroupeInfos.name}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="max-height-modal">
            <div className="form-group as_input_container">
              <input
                value={state.name}
                onChange={(e) =>
                  setState({
                    name: e.target.value,
                  })
                }
                name="name"
                className="form-control input_item"
                id="name"
              />
              <br />
              <input
                value={state.lien_whatsapp}
                onChange={(e) =>
                  setState({
                    lien_whatsapp: e.target.value,
                  })
                }
                name="lien_whatsapp"
                placeholder="Lien groupe whatsapp"
                className="form-control input_item"
                id="lien_whatsapp"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row dis-flex justify-content-end">
              <div className="col-md-5 col-sm-6">
                {state.editSousGroupeInProgress ? (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    Modification en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  >
                    MODIFIER
                  </button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <div
        className="modal fade"
        id="voirListeModale"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="addLivraisonBakelisteTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-content-bakeliste">
            <h5
              className="modal-bakeliste-title m-b-50"
              id="addLivraisonBakelisteTitle"
            >
              Liste des bakelistes dans {state.sgroupeInfos.name}
            </h5>
            <button
              type="button"
              className="close-modal-bakeliste-title"
              data-dismiss="modal"
              aria-label="Close"
            >
              <FeatherIcon icon="x" aria-hidden="true" />
            </button>

            <div className="modal-body">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Prenom</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {bakelisteData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.first_name}</td>
                      <td>{item.last_name}</td>
                      <td>{item.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <SweetAlert
              show={state.showAlertDeleteSuccess}
              onConfirm={() => setState({ showAlertDeleteSuccess: false })}
              success
              title="Sous-groupe archivé avec succes!!!"
              style={{
                color: "#069A77",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />

            <SweetAlert
              show={state.showAlertDeleteError}
              onConfirm={() => setState({ showAlertDeleteError: false })}
              error
              title="Erreur sur la suppression, veuillez reessayer!!!"
              style={{
                color: "#d9534f",
                fontSize: "12px",
                fontFamily: "Work Sans, sans-serif",
                paddingTop: 60,
                paddingBottom: 40,
              }}
              confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeSousGroupesAdminRefact;
