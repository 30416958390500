import React, {Component} from 'react';
import './profilVisiteur.css';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import Fatma from '../../img/fatma.png';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as profilVisiteurActions from "../../store/profilVisiteur/actions";
export default class profilVisiteur extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
            <div className="component-profil-visiteur">
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-lg-9"> 

                  <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to="/dashboard-admin" className="lien_nav">Dashboard / </Link></li>
                    <li className="breadcrumb-item" aria-current="page" className="lien_nav_current">Fatma Dieng</li>
                  </ol>
                  </nav>
                  <div className="container-fluid my-1 mt-2 demande-candidature">
                   
                      
                    <div class="card btom-card-demande-candidature">
                        <div class="card-body images-aita-card-center">
                          <div className="row">
                            <div className="col-sm-4 col-lg-4 col-xl-4 col-md-6">
                          <p><Link to="/liste-des-bakelistes-en-formation"><FeatherIcon icon="arrow-left" /></Link><span>fatma Dieng</span></p>

                            </div>
                            <div className="col-sm-4 col-lg-4 col-xl-4 col-md-6">
                                <img  src={Fatma}/> 
                              <h2>Fatma Dieng</h2>
                              <span>Designer</span>
                            </div>
                            <div className="col-sm-4 col-lg-4 col-xl-4 col-md-6">
                              <button>ass</button>
                              <button>ass</button>
                              <button>ass</button>
                            </div>
                          </div>
                            
                        </div>
                    </div>
                    <div className="card">
                    <div class="card-body">
                <div class="card btom-card-demande-candidature">
                    <div class="card-body">
                    <span className="experience-demande-candidature">Informations personnelles</span>
                        <div className="row">

                          <div className="col-sm-4 col-lg-4 col-xl-4 col-md-4">
                            <label for="inputPrenom" className="form-label ml-1">Date de naissance</label>
                            <input type="date" className="form-control form-texte-label-profil cache-bg-inp"  placeholder="23/03/2000" aria-label="prenom"/>
                              
                          </div>


                          <div className="col-sm-4 col-lg-4 col-xl-4 col-md-4">
                          <label for="inputPrenom" className="form-label ml-1">Telephone</label>
                            <input type="number" className="form-control  form-texte-label-profil cache-bg-inp" placeholder="770000000" aria-label="prenom"/>
                              
                          </div>
                          <div className="col-sm-4 col-lg-4 col-xl-4 col-md-4">
                            <label for="inputPrenom" className="form-label ml-1">Adresse mail</label>
                              <input type="text" className="form-control form-texte-label-profil cache-bg-inp" placeholder="Aita31@gmail.com" aria-label="prenom"/>
                              
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-4 col-lg-4 col-xl-4 col-md-4">
                            <label for="inputPrenom" className="form-label ml-1">Adresse :</label>
                            <input type="text" className="form-control form-texte-label-profil cache-bg-inp" placeholder="Liberte 6"  aria-label="prenom"/>
                              
                          </div>


                          <div className="col-sm-4 col-lg-4 col-xl-4 col-md-4">
                          <label for="inputPrenom" className="form-label ml-1">Whatsapp</label>
                            <input type="number" className="form-control form-texte-label-profil cache-bg-inp" placeholder="770000000"  aria-label="prenom"/>
                              
                          </div>
                          <div className="col-sm-4 col-lg-4 col-xl-4 col-md-4">
                            <label for="inputPrenom" className="form-label ml-1">N° carte d'identité *</label>
                              <input type="number" className="form-control form-texte-label-profil cache-bg-inp" placeholder="2769199203456" aria-label="prenom"/>
                              
                          </div>
                          </div>
                      
                    </div>
                </div>

                <div class="card btom-card-demande-candidature">
                    <div class="card-body">
                    <span className="experience-demande-candidature">Formations</span>
                    
                    <div className="row">
                        <div className="col-sm-4 col-xl-4 col-lg-4 col-md-4">
                          <label>statut</label><br />
                          <label className="form-texte-label-profil">Professionnel </label>
                        </div>
                        <div className="col-sm-4 col-xl-4 col-lg-4 col-md-4">
                          <label>Diplômes obtenus</label><br />
                          <label className="form-texte-label-profil">Licence
                           </label><br />
                          <label>Informatique</label>
                        </div>
                        <div className="col-sm-4 col-xl-4 col-lg-4 col-md-4">
                          <label>École</label><br />
                            <label className="form-texte-label-profil">ISM Dakar </label>
                        </div>
                      </div>
                   
                   
                    </div>
                </div>

                <div class="card btom-card-demande-candidature">
                    <div class="card-body">
                    <span className="experience-demande-candidature">Bakeli</span>
                        <div className="row">
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-md-3">
                              <label>Date d’inscription:</label><br />
                              <label className="form-texte-label-profil">02/10/2019</label>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-md-3">
                            <label>Durée de la formation:</label><br />
                              <label className="form-texte-label-profil">6 mois</label>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-md-3">
                            <label>Date de fin</label><br />
                              <label className="form-texte-label-profil">02/04/2020</label>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-md-3">
                            <label>Coach</label><br />
                              <label className="form-texte-label-profil">Makhtar</label>
                            </div>
                        </div>
                    
                        <div className="row pt-4">
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-md-3">
                              <label>Domaine</label><br />
                              <label className="form-texte-label-profil">Design</label>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-md-3">
                            <label>Mode de formation</label><br />
                              <label className="form-texte-label-profil">Présentiel</label>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-md-3">
                            <label>Lieu de formation</label><br />
                              <label className="form-texte-label-profil">Dakar</label>
                            </div>
                            <div className="col-sm-3 col-lg-3 col-xl-3 col-md-3">
                            <label>Horaire</label><br />
                              <label className="form-texte-label-profil">9h-17h</label>
                            </div>
                        </div>
                        
                    </div>
                </div>


                <div class="card btom-card-demande-candidature">
                    <div class="card-body">
                    <span className="experience-demande-candidature">Motivations</span>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                           Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                           when an unknown 
                          printer took a galley of type and scrambled it to make a type specimen book</p>
                    </div>
                </div>
                <div class="card btom-card-demande-candidature">
                    <div class="card-body">
                    <span className="experience-demande-candidature">Expérience</span>
                    
                    <div className="row">
                        <div className="col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <label className="form-texte-label-profil">UI designer</label><br />
                          <label className="form-texte-label-profil">Volkeno</label><br />
                          <label>2018</label>
                        </div>
                        <div className="col-sm-6 col-xl-6 col-lg-6 col-md-6">
                          <label className="form-texte-label-profil">UI designer</label><br />
                          <label className="form-texte-label-profil">Volkeno</label><br />
                          <label>2018</label>
                        </div>
                      </div>
                   </div>
                   </div>
                    </div>
                </div>
            
               
               </div>
             </div>


             <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                <div className="row">
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteLateItemForAdmin />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteMissingItemForAdmin />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                    <ProgramReunionCoach />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <SavedReunionListItem />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
// export default connect(
//     ({ profilVisiteur }) => ({ ...profilVisiteur }),
//     dispatch => bindActionCreators({ ...profilVisiteurActions }, dispatch)
//   )( profilVisiteur );