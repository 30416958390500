import React, {Component} from 'react';
import './tableSuiviBakelistes.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableSuiviBakelistesActions from "../../store/tableSuiviBakelistes/actions";

var gestionTacheForCoach = [{
  fullname: "Abdou ba",
  email: "gueyeazou@gmail.com",
  progression: "90",
  Coach: "Dieynaba",
  assiduite: "Regulier",
  NTache: "tache 1",
  NbrSemaine: "une semaine"
},{
  fullname: "Jean Diop",
  email: "gueyeazou@gmail.com",
  progression: "progression",
  Coach: "Aita",
  assiduite: "Regulier",
  NTache: "tache 1",
  NbrSemaine: "une semaine"
},{
  fullname: "Saliou Kane",
  email: "gueyeazou@gmail.com",
  progression: "90",
  Coach: "Fatma",
  assiduite: "Regulier",
  NTache: "tache 1",
  NbrSemaine: "une semaine"
},{
  fullname: "Dieynaba Ly",
  email: "gueyeazou@gmail.com",
  progression: "progression",
  Coach: "Ngoné",
  assiduite: "Regulier",
  NTache: "tache 1",
  NbrSemaine: "une semaine"
},{
  fullname: "Assane gueye",
  email: "gueyeazou@gmail.com",
  progression: "90",
  Coach: "Assane",
  assiduite: "Regulier",
  NTache: "tache 4",
  NbrSemaine: "une semaine"
},{
  fullname: "Fatma Dieng",
  email: "gueyeazou@gmail.com",
  progression: "progression",
  Coach: "Kalika",
  assiduite: "Regulier",
  NTache: "tache 3",
  NbrSemaine: "une semaine"
},
];

export default class tableSuiviBakelistes extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    Niveau = (Column, row) => {
      return(
        <div className="la-taille-div">
          {row.progression === "90" ?
            (
              <div className="progress-bare">
                <div className="progress-pourcentage">
                  <span>90%</span>
                </div>
              </div>
            )
                :
            (
              <div className="progress-bare">
                <div className="progress-pourcentage-minim">
                  <span>60%</span>
                </div>
              </div>
            )
          }
        </div>
      )
}
    CustomExportCsvBtn = (onClick) =>{
      return(
        <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
      );
    }
    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      };
      return (
            <div className="component-table-suivi-bakelistes">
                 <BootstrapTable 
                 
                  options={options}
                  exportCSV
                  csvFileName="utilisateurs.csv"
                  data={gestionTacheForCoach} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                    
                  <TableHeaderColumn 
                    
                    isKey filter={{type:"TextFilter", delay: 1000}} 
                    dataField='fullname' 
                    thStyle={{ 'border': 'none', 'fontWeight': '500' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '12px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Prénom & Nom</span> 
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                    dataField='email' width="200" 
                    filter={{type:"TextFilter", delay: 1000}}
                    thStyle={{ 'border': 'none', 'fontWeight': '500' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1.2rem','font-size': '12px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Email</span>
                    </TableHeaderColumn>
                   
      
                   
                  <TableHeaderColumn 
                      dataField='progression' width="200" 
                      dataFormat={this.Niveau}
                      filter={{type:"TextFilter", delay: 1000}}
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Progression</span>
                     </TableHeaderColumn>
                  <TableHeaderColumn 
                  dataField='Coach' 
                  // dataFormat={uploadFormatter} 
                  
                  thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                  tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs"> Coachs</span>
                    </TableHeaderColumn>

                    <TableHeaderColumn 
                      dataField='assiduite' 
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs"> Assiduité</span>
                    </TableHeaderColumn>

                    <TableHeaderColumn 
                      dataField='NTache' 
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs"> Nombres Taches</span>
                    </TableHeaderColumn>

                    <TableHeaderColumn 
                      dataField='NbrSemaine' 
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs"> Nombres Semaine</span>
                    </TableHeaderColumn>
                </BootstrapTable>
              </div>
        );
    }
  }
// export default connect(
//     ({ tableSuiviBakelistes }) => ({ ...tableSuiviBakelistes }),
//     dispatch => bindActionCreators({ ...tableSuiviBakelistesActions }, dispatch)
//   )( tableSuiviBakelistes );