import React, {Component} from 'react';
import './tableMyBakelistes.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import FeatherIcon from 'feather-icons-react';

var myBakeliste = [{
  id: 1,
  prenom: "Abdou ",
  nom: "ba",
  telephone: "773926912 ",
  email: "moumadjad.a@gmail.com",
  presence: "oui"
},{
  id: 2,
  prenom: "Moumadjad ",
  nom: "ahmed abdou",
  telephone: "773926912 ",
  email: "moumadjad.a@gmail.com",
  presence: "oui "
},{
  id: 3,
  prenom: "Mounir ",
  nom: "ismael moegne daho",
  telephone: "773926912 ",
  email: "moumadjad.a@gmail.com",
  presence: "oui "
},{
  id: 4,
  prenom: "malaoupé ",
  nom: "mbaba",
  telephone: "773926912 ",
  email: "moumadjad.a@gmail.com",
  presence: "non "
},{
  id: 5,
  prenom: "mwegne hamadi ",
  nom: "omari",
  telephone: "773926912 ",
  email: "moumadjad.a@gmail.com",
  presence: "oui "
},{
  id: 6,
  prenom: "Mouayad ",
  nom: "ahmed abdou",
  telephone: "773926912 ",
  email: "moumadjad.a@gmail.com",
  presence: "non "
}];

class PresenceCheck extends React.Component {
  render() {
    return (
      <div style={{color: '#22C580', textAlign: 'center'}}>
        <FeatherIcon icon="check" size={16} />
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <PresenceCheck active={ cell } />
  );
}

export default class tableMyBakelistes extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="component-table-my-bakelistes table-responsive-sm table-responsive-xl table-responsive-md">
                <BootstrapTable 
                  data={myBakeliste} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                  <TableHeaderColumn 
                    isKey 
                    dataField='id' 
                    thStyle={{ 'border': 'none', 'paddingLeft': '0', 'paddingRight': '0', 'width': '30px', 'textAlign': 'center', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'paddingLeft': '0', 'paddingRight': '0', 'width': '30px', 'textAlign': 'center' }}>N°</TableHeaderColumn>
                  <TableHeaderColumn dataField='prenom' thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none' }}>Prénom</TableHeaderColumn>
                  <TableHeaderColumn dataField='nom' thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none' }}>Nom</TableHeaderColumn>
                  <TableHeaderColumn dataField='telephone' thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none' }}>N° Téléphone</TableHeaderColumn>
                  <TableHeaderColumn dataField='email' dataSort thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none' }}>Adresse mail</TableHeaderColumn>
                  <TableHeaderColumn dataField='presence' dataSort dataFormat={uploadFormatter} thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none' }}>Présence</TableHeaderColumn>
                </BootstrapTable>
              </div>;
    }
  }
// export default connect(
//     ({ tableMyBakelistes }) => ({ ...tableMyBakelistes }),
//     dispatch => bindActionCreators({ ...tableMyBakelistesActions }, dispatch)
//   )( tableMyBakelistes );