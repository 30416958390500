import React, { useReducer } from "react";
import "./addDomaine.css";
import { Modal, Icon } from "rsuite";
import FeatherIcon from "feather-icons-react";
import API from "../../variablesGlobales";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { addDomaineAsync } from "../../redux/slices/parametreSlices/parametreSlice";
toast.configure();


const AddDomaineRefact = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      show: false,
      api: API.API,
      loading: false,
    }
  );
  const dispatch = useDispatch();

  const addDomaines = (e) => {
    e.preventDefault();
    // console.log('ddddddd');
    setState({
      loading: true,
    });
    var fd = new FormData();
    fd.append("description", state.description);
    fd.append("name", state.name);
    fd.append("status", state.status);
    dispatch(addDomaineAsync(fd));
    setTimeout(() => {
      setState({
        loading: false,
      });
      $(".close-modal-bakeliste-title").click();
    }, 1000);
  };
  const close = () => {
    setState({ show: false });
  };
  const open = () => {
    setState({ show: true });
  };
  return (
    <div className="modal-container ml-md-auto">
      <button className="btn-add-with-icon-plus-circle" onClick={open}>
        Ajouter
        <Icon className="icon-add ml-2" icon="plus-circle" />
      </button>
      <Modal
        className="modal-responsive-sm p-b-50"
        show={state.show}
        onHide={close}
      >
        <h5 className="modal-bakeliste-title m-b-50">
          Créer un nouveau domaine
        </h5>
        <button className="close-modal-bakeliste-title" onClick={close}>
          <FeatherIcon icon="x" aria-hidden="true" />
        </button>
        <form
          onSubmit={(e) => addDomaines(e)}
          id="domaine_form"
          className="mt-5"
        >
          <div className="form-row row">
            <div className="col-md-6">
              <div className="form-group ">
                <input
                  type="name"
                  className="form-control ab1 si-form-input"
                  id="inputName"
                  placeholder="Name"
                  name="name"
                  onChange={(e) => setState({ name: e.target.value })}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                  name="status"
                  className="form-control ab1 si-form-input"
                  id="statusInput"
                  onChange={(e) => setState({ status: e.target.value })}
                  required
                >
                  <option> *Choisir un statut -- </option>
                  <option value="Actif"> Actifs </option>
                  <option value="Non-actif"> Non-actif </option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <textarea
                  className="form-control ab1 si-form-input"
                  id="inputDescription"
                  placeholder="description"
                  name="description"
                  onChange={(e) => setState({ description: e.target.value })}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-md-6  offset-6 submit-btn-container">
            <div className="form-group ">
              {!state.loading ? (
                <button className="btn btn-theme">
                  <i className="fas fa-plus-circle"></i> &nbsp; Ajouter
                </button>
              ) : (
                <button className="btn btn-theme loading-btn">
                  Envoie en cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddDomaineRefact;
