import React from "react";
import EventRefact from "../event/EventRefact";
import EventsRefac from "../event/EventsRefac";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import "./eventView.css";
const EventViewRefact = () => {
  return (
    <div className="component-event-view body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            {/* <EventRefact /> */}
            <EventsRefac />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventViewRefact;
