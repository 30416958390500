import { Tooltip, withStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { forwardRef } from "react";

const EditTooltip = withStyles((theme) => ({
  arrow: {
    color: "#069A77",
  },
  tooltip: {
    backgroundColor: "#069A77",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

export const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <EditTooltip title="Options" placement="top" arrow>
      <MoreVertIcon style={{ color: "#069A77" }} />
    </EditTooltip>
  </span>
));
