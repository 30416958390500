import React, { Component } from "react";
import "./tableListeParticipants.css";
import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableListeParticipantsActions from "../../store/tableListeParticipants/actions";

var listeParticipants = [
  {
    fullname: "Assane Gueye",
    email: "assanegueye@gmail.com",
    telephone: "77 000 00 00",
    adresse: "Thies",
  },
  {
    fullname: "Demba Seck",
    email: "assanegueye@gmail.com",
    telephone: "77 000 00 00",
    adresse: "Dakar",
  },
];

export default class tableListeParticipants extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  CustomExportCsvBtn = (onClick) => {
    return (
      <ExportCSVButton
        style={{
          background: "#FF9800",
          border: "none",
          height: "42px",
          width: "150px",
          left: "20px",
        }}
        btnText="Export to csv"
      />
    );
  };
  render() {
    const options = {
      exportCSVBtn: this.CustomExportCsvBtn,
    };
    return (
      <div className="component-table-liste-participants">
        <BootstrapTable
          options={options}
          exportCSV
          csvFileName="utilisateurs.csv"
          data={listeParticipants}
          striped
          version="4"
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn
            isKey
            dataField="fullname"
            width="200"
            filter={{ type: "TextFilter", delay: 1000 }}
            thStyle={{ border: "none", fontWeight: "500" }}
            tdStyle={{
              border: "none",
              padding: "1.2rem",
              "font-size": "12px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Nom Prenom</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="email"
            width="200"
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Email</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="telephone"
            width="200"
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Telephone</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="adresse"
            width="200"
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Adresse</span>
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}
// export default connect(
//     ({ tableListeParticipants }) => ({ ...tableListeParticipants }),
//     dispatch => bindActionCreators({ ...tableListeParticipantsActions }, dispatch)
//   )( tableListeParticipants );
