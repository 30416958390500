import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Amico from "../../img/newImg/amico.png";
import Amico2 from "../../img/newImg/amico2.png";
import Amico3 from "../../img/newImg/amico3.png";
import AmicoR from "../../img/newImg/amicor.png";
import Amico4 from "../../img/newImg/amico_3.png";
import Pana from "../../img/newImg/pana.png";
import Rafiki from "../../img/newImg/rafiki.png";
import {
  getBakelisteAbondonnessync,
  getBakelisteArchivesAsync,
  getBakelisteBloquesAsync,
  getBakelisteEnligneeAsync,
  getBakelisteEnPauseAsync,
  getBakelistePresentielsAsync,
  getBakelisteStageAsync,
  getBakelisteTerminesAsync,
  showListBakeAbondonnes,
  showListBakeArchives,
  showListBakeEnligne,
  showListBakeEnPause,
  showListBakelisteBloques,
  showListBakelisteStages,
  showListBakelistTermines,
  showListBakePresentiels,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import "./bakelisteDash.css";
import NewBktCard from "./NewBktCard";
const DashboardBakelisteAll = () => {
  const dispatch = useDispatch();
  const bktEnligneData = useSelector(showListBakeEnligne);
  const bktPresentielData = useSelector(showListBakePresentiels);
  const bktArchivesData = useSelector(showListBakeArchives);
  const bktAbondonnesData = useSelector(showListBakeAbondonnes);
  const bakelisteTerminesData = useSelector(showListBakelistTermines);
  const bakelisteStageData = useSelector(showListBakelisteStages);
  const bakelisteBloqueData = useSelector(showListBakelisteBloques);
  const bakelisteEnpause = useSelector(showListBakeEnPause);

  useEffect(() => {
    // dispatch(getAllBakelisteAsync());
    dispatch(getBakelisteEnligneeAsync());
    dispatch(getBakelistePresentielsAsync());
    dispatch(getBakelisteAbondonnessync());
    dispatch(getBakelisteTerminesAsync());
    dispatch(getBakelisteStageAsync());
    dispatch(getBakelisteBloquesAsync());
    dispatch(getBakelisteEnPauseAsync());
    dispatch(getBakelisteArchivesAsync());
  }, []);

  return (
    <div className="row justify-content-start mt-3 dash">
      {/* Card bakeliste presentiel */}
      <NewBktCard
        cardName="Bakelistes en presentiel"
        cardLink="/all-bakelistes-presentiel"
        cardImage={Amico3}
        cardData={bktPresentielData?.length}
      />

      {/* Card bakeliste en ligne */}
      <NewBktCard
        cardName="Bakelistes en ligne"
        cardLink="/all-bakelistes-enligne"
        cardImage={Amico2}
        cardData={bktEnligneData?.length}
      />

      {/* Card bakeliste abandonnés */}
      <NewBktCard
        cardName="Bakelistes abondonnés"
        cardLink="/all-bakelistes-abondonnes"
        cardImage={Pana}
        cardData={bktAbondonnesData?.length}
      />

      {/* Card bakeliste en pause*/}
      <NewBktCard
        cardName="Bakelistes en pause"
        cardLink="/all-bakelistes-pause"
        cardImage={Pana}
        cardData={bakelisteEnpause?.length}
      />

      {/* Card bakeliste bloqués */}
      <NewBktCard
        cardName="Bakelistes bloqués"
        cardLink="/all-bakelistes-bloques"
        cardImage={Rafiki}
        cardData={bakelisteBloqueData?.length}
      />

      {/* Card bakeliste archivés */}
      <NewBktCard
        cardName="Bakelistes archivés"
        cardLink="/all-bakelistes-archives"
        cardImage={Amico4}
        cardData={bktArchivesData?.length}
      />

      {/* Card bakeliste en stage */}
      <NewBktCard
        cardName="Bakelistes en stage"
        cardLink="/all-bakelistes-stage"
        cardImage={Amico}
        cardData={bakelisteStageData?.length}
      />

      {/* Card bakeliste terminés */}
      <NewBktCard
        cardName="Bakelistes terminés"
        cardLink="/all-bakelistes-termines"
        cardImage={AmicoR}
        cardData={bakelisteTerminesData?.length}
      />
    </div>
  );
};

export default DashboardBakelisteAll;
