import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import successMsg from "../../../utils/successMsg";
import errorMsg from "../../../utils/errorMsg";
import existMsg from "../../../utils/existMsg";


import { API } from "../../../variablesGlobales";
import prev from "../multiStep/prev.svg";
import ApercuFormation from "./ApercuFormation";
import ApercuInformationsPersonnelles from "./ApercuInformationsPersonnelles";
import ApercuInfosSupplementaire from "./ApercuInfosSupplementaire";

const BakelisteInfosApercu = ({
  setPage,
  setApercu,
  bakelisteInfos,
  domaines,
  route,
  requestMethod,
}) => {
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let formData = new FormData();



  // const onSubmit = async (data) => {
    // setLoading(true);
    // for (var key in bakelisteInfos) {
    //   formData.append(key, bakelisteInfos[key]);
    // }
    // try {
    //   await axios({
    //     method: requestMethod,
    //     url: API + route,
    //     data: formData,
    //   });
    //   successMsg("Bakeliste ajoute avec succes");
    //   history.push("/all-bakelistes-presentiel");
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   setLoading(false);
    // }
  // };

 const onSubmit = async (data) => {
  setLoading(true);
  for (var key in bakelisteInfos) {
    formData.append(key, bakelisteInfos[key]);
  }
  try {
  const res = await axios({
      method: requestMethod,
      url: API + route,
      data: formData,
    });
    // console.log(res);
    if (res.status === 201 ) {
      successMsg("Bakeliste ajoute avec succes");
      history.push("/all-bakelistes-presentiel");
    }
    if (res.status === 200 ) {
      existMsg("Cette email exite deja dans le système");
    }
    } catch (err) {
      console.log(err);
      errorMsg("Erreur!! Veuillez reessayer");

    } finally {
      setLoading(false);
    }
  };

  return (
    <section
      style={{
        backgroundColor: "white",
        padding: "2rem",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <div className="d-flex justify-content-between mt-4">
        <button
          className="btn-switch btn-prev"
          onClick={() => {
            setApercu(false);
            setPage(2);
          }}
        >
          <img src={prev} alt="Prev" className="mr-3" /> Retour
        </button>
        <button
          onClick={onSubmit}
          className="btn-switch btn-submit"
          type="button"
        >
          {loading ? "Ajout en cours..." : "Ajouter"}
        </button>
      </div>

      <h1
        style={{ fontSize: "2rem", fontWeight: "bold" }}
        className="text-center apercu--title"
      >
        Résumé Bakeliste informations
      </h1>
      <main
        className="container--infos-supplementaire"
        style={{ border: "1px solid #bbbbbb", padding: "1rem 1rem 2rem 1rem" }}
      >
        <ApercuInformationsPersonnelles bakelisteInfos={bakelisteInfos} />
        <ApercuFormation domaines={domaines} bakelisteInfos={bakelisteInfos} />
        <ApercuInfosSupplementaire bakelisteInfos={bakelisteInfos} />
      </main>
    </section>
  );
};

export default BakelisteInfosApercu;
