import React, {Component} from 'react';
import './ajoutCoachAssistant.css';
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";

import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as ajoutCoachAssistantAssistantActions from "../../store/ajoutCoachAssistantAssistant/actions";
export default class ajoutCoachAssistantAssistant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ajoutCoachIAssistantnProgresse: false,
      domaines : [],
      api: API.API,
      fields: {},
      coach:[],

    };
  }
    componentDidMount = () => {
      this.getListCoachs();
      this.getAllDomaines();
     
    };

    addCoachHandleChange = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      //console.log("handle change", e.target.value)
      this.setState({
        fields,
      });
      //console.log("handle change", this.state.fields);
    }
    getAllDomaines = () => {
      axios
        .get(this.state.api + "domaines")
        .then((res) => {
          const domains = res.data;
          this.setState({
            domaines: domains.data,
          });
          // console.log(this.state.domaines)
        })
        .catch((error) => {
          //console.log(error.message);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          // Sentry.captureException(error);
        });
    };
    validateForm() {
      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;
      if (!fields["first_name"]) {
        formIsValid = false;
        this.setState({
          fn_empty: true,
        });
      }
  
      if (typeof fields["first_name"] !== "undefined") {
        if (
          !fields["first_name"].match(
            /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
          )
        ) {
          formIsValid = false;
          this.setState({
            fn_empty: true,
          });
        }
      }
  
      if (!fields["last_name"]) {
        formIsValid = false;
        this.setState({
          ln_empty: true,
        });
           }
  
      if (typeof fields["last_name"] !== "undefined") {
        if (
          !fields["last_name"].match(
            /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
          )
        ) {
          formIsValid = false;
          this.setState({
            ln_empty: true,
          });
         }
      }
  
      if (!fields["email"]) {
        formIsValid = false;
        this.setState({
          email_empty: true,
        });
        }
  
      if (typeof fields["email"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(fields["email"])) {
          formIsValid = false;
          this.setState({
            email_empty: true,
          });
            }
      }
  
      if (!fields["phone"]) {
        formIsValid = false;
        this.setState({
          phone_empty: true,
        });
        }
  
      if (!fields["coach_domaine_id"]) {
        formIsValid = false;
        this.setState({
          df_empty: true,
        });
           }
  
      
      this.setState({
        errors: errors,
      });
      return formIsValid;
    }

  getListCoachs = () => {
    axios
      .get(this.state.api + "liste-des-coachs")
      .then((res) => {
        if (res.data.success === true) {
          // console.log("coach", res.data.data);
          this.setState({
            coach: res.data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  addCoachAssistants = (e) => {
    e.preventDefault();

    if (this.validateForm()) {
      this.setState({
        ajoutCoachIAssistantnProgresse: true,
      });
      let fields = {};
      fields["first_name"] = "";
      fields["last_name"] = "";
      fields["email"] = "";
      fields["phone"] = "";
      fields["coach_domaine_id"] = "";
      fields["coach_id"]= "";
      this.setState({ fields: fields });
      const dataCoachAssistant = this.state.fields;
      // console.log(dataCoachAssistant);
      axios.post(this.state.api + "add-coach-assistant", dataCoachAssistant)
      .then((res) => {
        // console.log(res);
        if (res.status === 201 && res.statusText === "Created") {
          
          this.setState({
            ajoutCoachIAssistantnProgresse: false,
            // ajoutCoachAssistantInfoSuccess: true,
          });
          toast.success(
            "Coach assistant ajouté avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          window.location = '/liste-coach-assistants';
        } else {
          this.setState({
            ajoutCoachIAssistantnProgresse: false,
            // ajoutCoachAssistantInfoSuccess:true
          });
          toast.error(
            "Erreur sur l'ajout de coach assistant!!!",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      });
    }
  }

    render() {
      return  <div className="component-nav-tabs-parametres">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
                    <div className="col-lg-10 pt-3">
                    
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/liste-coach-assistants" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="users"
                      />
                      Liste coachs assistants
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="user"
                    />
                    Ajout Coach assistant
                  </li>
                </ol>
              </nav>

        <form>
                <div className="row edit-coach-container">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Prénom : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.addCoachHandleChange}
                        name="first_name"
                      />
                    </div>
                  </div>
                  {this.state.fn_empty && (
                    <div className="errorMsg">
                      {this.state.errors.first_name}
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Nom : </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={this.addCoachHandleChange}
                        name="last_name"
                        required
                      />
                    </div>
                  </div>
                  {this.state.ln_empty && (
                    <div className="errorMsg">
                      {this.state.errors.last_name}
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Email : </label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={this.addCoachHandleChange}
                        name="email"
                        required
                      />
                    </div>
                  </div>
                
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Téléphone : </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={this.addCoachHandleChange}
                        name="phone"
                        required
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Domaine :</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="coach_domaine_id"
                        onChange={this.addCoachHandleChange}
                        required
                      >
                        <option> -- Choisir un domaine -- </option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
               
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Coach :</label>
                      <select
                        className="form-control"
                        id="inputbakeli_domaine_id"
                        name="coach_id"
                        onChange={this.addCoachHandleChange}
                        required
                      >
                        <option> -- Choisir un coach -- </option>
                        {this.state.coach.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.coachFullname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
             
             
                {/* <button
                  type="button"
                  className="btn-theme-danger"
                >
                  <i class="fas fa-times-circle"></i> &nbsp; Quitter
                </button> */}

                {!this.state.ajoutCoachIAssistantnProgresse && (
                  <button
                    type="button"
                    className="col-md-4 offset-md-6 btn-theme"
                    onClick={(e) => this.addCoachAssistants(e)}
                  >
                     Ajouter coach
                  </button>
                )}
                {this.state.ajoutCoachIAssistantnProgresse && (
                  <button
                    type="button"
                    className=" col-md-4 btn-theme-orange"
                  >
                    Ajout en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
                </div>

         </form>
       
                    </div>
                  </div>
              </div>
              </div>
           </div>;
    }
  }
    
// export default connect(
//     ({ ajoutCoachAssistantAssistant }) => ({ ...ajoutCoachAssistantAssistant }),
//     dispatch => bindActionCreators({ ...ajoutCoachAssistantAssistantActions }, dispatch)
//   )( ajoutCoachAssistantAssistant );