import React, { Component } from "react";
import "./coachArchive.css";
import FeatherIcon from "feather-icons-react";
import {
  Modal,
  Icon,
  SelectPicker,
  Radio,
  RadioGroup,
  Uploader,
} from "rsuite";
import {Tabs, Tab, Row,  Col, Container} from "react-bootstrap";

import { Tooltip } from "@material-ui/core";
// import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
// import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
// import ProgramReunionCoach from '../programReunionCoach/index';
// import SavedReunionListItem from '../savedReunionListItem/index';
import TableMyListCoachs from "../tableMyListCoachs/index";
import ListeCoachs from "../listCoachs/index";
import { Link, NavLink } from "react-router-dom";

import axios from "axios";
import API from "../../variablesGlobales";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import $ from "jquery";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export default class coachArchive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coachs:[],
      coachsAssistants:[],
      api: API.API,
      status: false,
    };
  }
  componentDidMount() {
    this.getListAllCoachs();
    this.getListAllCoachsAssistants();
  }
  getListAllCoachs = () => {
    axios
      .get(this.state.api + "liste-des-coachs-archives")
      .then((res) => {
        if (res.data.success === true) {
          //  console.log('coachs', res.data.data)
          this.setState({
            coachs: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
        }
        this.setState({ isLoaded: false });
        $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };
  getListAllCoachsAssistants = () => {
    axios
      .get(this.state.api + "liste-des-coachs-assistants-archives")
      .then((res) => {
        if (res.data.success === true) {
          //  console.log('coachs', res.data.data)
          this.setState({
            coachsAssistants: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
        }
        this.setState({ isLoaded: false });
        $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };
  actionFormatter = (cell, row) => {
    return (
      <span>
        &nbsp;&nbsp;
        <button className="btn btn-theme-orange p-1" type="button" onClick={() => {
              this.desarchivedCoach(row.id);
            }}>
          Activer coach
        </button>
        &nbsp;&nbsp;
      </span>
    );
  };

  actionFormatterCA = (cell, row) => {
    return (
      <span>
        &nbsp;&nbsp;
        <button className="btn btn-theme-orange p-1" type="button" onClick={() => {
              this.desarchivedCoachAssistant(row.id);
            }}>
          Activer coach-assistant
        </button>
        &nbsp;&nbsp;
      </span>
    );
  };
  desarchivedCoach(id) {
    axios
      .post(this.state.api + "desarchive-user/" + id)
      .then((res) => {
        // console.log("archiver", res)
        if (res.data.success === true) {
          toast.success(
            "coach desarchivé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
            this.getListAllCoachs();
        } else {
          toast.error(
            "erreur sur le désarchivage",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  desarchivedCoachAssistant(id) {
    axios
      .post(this.state.api + "desarchive-user/" + id)
      .then((res) => {
        // console.log("archiver", res)
        if (res.data.success === true) {
          toast.success(
            "coach desarchivé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
            this.getListAllCoachsAssistants();
        } else {
          toast.error(
            "erreur sur le désarchivage",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getStatus = () => {
    this.setState({ statusCoach: true });
  };
  render() {
    return (
      <div className="p-b-200">
        <div className="container-fluid px-0">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard-admin" className="lien_nav">
                  Dashboard {" "}
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/liste-coachs" className="lien_nav">
                  Coachs {" "}
                </Link>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                className="lien_nav_current"
              >
                {" "}
                /Coachs archivés{" "}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="row m-b-15">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                  Listes des Coachs archivés
                </h6>
              </div>
             
            </div>
            <Container>
                  <Row>
                    <Col>
                      <Tabs defaultActiveKey="Coachs" 
                                      id="controlled-tab-example">
                        <Tab eventKey="Coachs" title="Coachs">                     
                        {/* <ListeAdmins /> */}
                        <div className="card-dashboard m-b-15 p-b-60">
       {this.state.coachs.length > 0 ? (
       
        <BootstrapTable
          data={this.state?.coachs}
          striped={true}
          hover={true}
          // options={options}
          search
          searchPlaceholder="Rechercher coach..."
          multiColumnSearch
          // exportCSV
          csvFileName="listes-coachs.csv"
          multiColumnSort={2}
          pagination
          bordered={true}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn
            className="titre-tab"
            isKey
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            dataField="coachFullname"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="domaine"
            className="titre-tab"
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Domaine</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="email"
            className="titre-tab"
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Email</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="nbreBakeliste"
            className="titre-tab"
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">
              Nombre Bakelistes
            </span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="phone"
            className="titre-tab"
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Numéro</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="action"
            dataFormat={this.actionFormatter}
            className="titre-tab"
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              display: "flex",
              justifyContent: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs"> Actions</span>
          </TableHeaderColumn>
        </BootstrapTable>
          
       ): (
        <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
        IL n'y a pas de coachs archivés
      </h6>
       )}
        </div>
                        </Tab>
                        <Tab eventKey="Coachs Assistants" title="Coachs Assistants">
                          {/* <CoachAdmin /> */}
                          {/* <ListeCoachs /> */}
                          <div className="card-dashboard m-b-15 p-b-60">
       {this.state.coachsAssistants.length > 0 ?(
       <BootstrapTable
         data={this.state?.coachsAssistants}
         striped={true}
         hover={true}
         // options={options}
         search
         searchPlaceholder="Rechercher coach..."
         multiColumnSearch
         // exportCSV
         csvFileName="listes-coachs.csv"
         multiColumnSort={2}
         pagination
         bordered={true}
         bodyStyle={{
           fontSize: 12,
           color: "rgba(0, 0, 0, 0.7)",
           fontFamily: "inherit",
           fontWeight: 400,
         }}
         tdStyle={{ background: "red" }}
         tableHeaderclassName="header-table-groupe-view"
         wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
         className="table-responsive table-responsive-sm"
       >
         <TableHeaderColumn
           className="titre-tab"
           isKey
           filter={{
             type: "TextFilter",
             delay: 1000,
             placeholder: "Recherche  ",
           }}
           dataField="coachFullname"
           thStyle={{ border: "none", fontWeight: "400" }}
           tdStyle={{
             border: "none",
             padding: "1rem",
             "font-size": "14px",
             "font-family": "Montserrat",
           }}
         >
           <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
         </TableHeaderColumn>
         <TableHeaderColumn
           dataField="domaine"
           className="titre-tab"
           filter={{
             type: "TextFilter",
             delay: 1000,
             placeholder: "Recherche  ",
           }}
           thStyle={{ border: "none", fontWeight: "400" }}
           tdStyle={{
             border: "none",
             padding: "1rem",
             "font-size": "14px",
             "font-family": "Montserrat",
           }}
         >
           <span className="color-nom-prenom-utilisateurs">Domaine</span>
         </TableHeaderColumn>
         <TableHeaderColumn
           dataField="email"
           className="titre-tab"
           filter={{
             type: "TextFilter",
             delay: 1000,
             placeholder: "Recherche  ",
           }}
           thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
           tdStyle={{
             border: "none",
             textAlign: "center",
             padding: "1rem",
             "font-size": "14px",
             "font-family": "Montserrat",
           }}
         >
           <span className="color-nom-prenom-utilisateurs">Email</span>
         </TableHeaderColumn>


         <TableHeaderColumn
           dataField="phone"
           className="titre-tab"
           filter={{
             type: "TextFilter",
             delay: 1000,
             placeholder: "Recherche  ",
           }}
           thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
           tdStyle={{
             border: "none",
             textAlign: "center",
             padding: "1rem",
             "font-size": "14px",
             "font-family": "Montserrat",
           }}
         >
           <span className="color-nom-prenom-utilisateurs">Numéro</span>
         </TableHeaderColumn>

         <TableHeaderColumn
           dataField="action"
           dataFormat={this.actionFormatterCA}
           className="titre-tab"
           thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
           tdStyle={{
             border: "none",
             display: "flex",
             justifyContent: "center",
             padding: "1.2rem",
             "font-size": "14px",
             "font-family": "Montserrat",
           }}
         >
           <span className="color-nom-prenom-utilisateurs"> Actions</span>
         </TableHeaderColumn>
       </BootstrapTable>
         
       ): (
        <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
        IL n'y a pas de coachs assistants archivés
      </h6>
       )}
       </div>
                       
                        </Tab>
                       </Tabs>
                      </Col>
                     </Row>
                   </Container>

            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                {/* <TableMyListCoachs /> */}
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    );
  }
}
