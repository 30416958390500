import React, {Component} from 'react';
import './syllabusAdmin.css';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import { AutoComplete, Icon, Modal, InputGroup } from 'rsuite';
import SyllabusItem from '../syllabusItem/index';
import AddSyllabus from '../addSyllabus/index';
import InfiniteScroll from "react-infinite-scroll-component";
import FeatherIcon from "feather-icons-react";
import MultiSelect from "@khanacademy/react-multi-select";
import API from "../../variablesGlobales";
import axios from 'axios';
import $ from "jquery"; 
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const styles = {
  marginBottom: 30
};




const data = [
  'Developpement web',
  'Android developpeur',
  'UX/UI Design',
  'Gestion de projet',
  'Infographie',
];

export default class syllabusAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
          userID: window.sessionStorage.getItem("user_id"),
          api: API.API,
          my_id: "",
          domaines: [],
          syllabusDomaineDatas: [],
          isPageLoading: false,
          datas: [],
          recherche: "",
          datasDomaine: [],
          tab_all_syllabus: true,
    
          // Add SYllabusss
    
          show: false,
          aisSuccessAssign: false,
          isErrorAssign: false,
          isAssignSyllabusInProgress: false,
          selectedOption: null,
          userID: window.sessionStorage.getItem("user_id"),
          showHide: false,
          isPageLoading: true,
          api: API.API,
          syllabusDatas: [],
          domaines: [],
          technologies: [],
          tools: [],
          fields: {},
          errors: {},
          sName: "",
          sDescription: "",
          sDomaine: 0,
          domainesSelect: [],
          sLevel: "",
          sTechnologie: [],
          sTool: [],
          sname: false,
          sdescription: false,
          stechnotool_empty: false,
          screatedby_empty: false,
          slevel_empty: false,
          sdomaineid_empty: false,
          addSyllabusInProgress: false,
          addSyllabusSuccessAlert: false,
          addSyllabusErrorAlert: false,
          remaining_char: 245,
          selectOptions: [],
          selectOptions1: [],
          selectOptions2: [],
          selectTechnologies: [],
          selectTools: [],
          syllabusID: 0,
          bakelisteID: 0,
          selected: [],
          utFields: {},
          selectedtools: [],
          onlySelect: false,
          groupySelect: false,
          bakelistesByCoach: [],
          bakeliste: [],
          assignationEncours: false,
          assignationSuccessAlert: false,
          isAssignationError: false,
          noSavedData: [],
          showAlertSuccess: false,
          showAlertError: false,
          utInProgress: false,
          syllabusByCoache: [],
          updateSyllabusRequestError: false,
          // updateSyllabusRequestError: false,
          updateSyllabusRequestSuccessAlert: false,
          bakelisteBySyllabus: [],
          sous_groupes: [],
          currentPage: 1,
          hasMore: true,
          loading: false,
          load: true,
          
        };
    this.addHandleChange = this.addHandleChange.bind(this);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }
    componentDidMount = () => {
      this.onGetSyllabus();
      this.onGetDomaines();
      this.getAllTechnologies();
      this.getAllTools();
      this.getAllDomaines();
      window.scrollTo(0, 0);
      this.onGetSyllabusByDomaine();
      setTimeout(() => {
        this.setState({
          load: false,
        });
      }, 4000);
    };
    getAllDomaines = () => {
      axios
        .get(this.state.api + "domaines")
        .then((res) => {
          const domains = res.data;
          this.setState({
            domaines: domains.data,
          });
        })
        .catch((error) => {
          //console.log(error.message);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          // Sentry.captureException(error);
        });
    };
    // Add Syllabus
    addHandleChange = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      fields["created_by"] = this.state.userID;
      fields["technologie_id"] = this.state.selected;
      fields["tool_id"] = this.state.selectedtools;
      // fields["syllabus_domaine_id"] = this.state.domainesSelect;
      if (e.target.name === "syllabus_description") {
        var chars = e.target.value.length;
        var remaining_char = 245 - chars;
        this.setState({
          remaining_char: remaining_char,
        });
      }
      this.setState({
        fields: fields,
      });
  
      // console.log(fields);
    };
    //get technologies
    getAllTechnologies() {
      axios
        .get(this.state.api + "technologies/")
        .then((response) => {
          var data = response.data.data;
  
          if (data !== undefined) {
            this.setState({
              technologies: data,
            });
            //console.log("les technologies :", this.state.technologies);
          }
  
          data.forEach((item) => {
            let infos = {
              label: item.name,
              value: item.id,
            };
            this.state.selectOptions1.push(infos);
          });
          //console.log("Technologies ", this.state.selectOptions1);
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          // Sentry.captureException(error);
        });
    }
  
    //get tools
    getAllTools() {
      axios
        .get(this.state.api + "tools/")
        .then((response) => {
          var data = response.data.data;
          if (data !== undefined) {
            this.setState({
              tools: data,
            });
          }
  
          data.forEach((item) => {
            let infos = {
              label: item.name,
              value: item.id,
            };
            this.state.selectOptions2.push(infos);
          });
          //console.log("Tools ", this.state.selectOptions2);
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          // Sentry.captureException(error);
        });
    }
  fetchMoreData = () => {
    setTimeout(() => {
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
      var baseApiUrl = this.state.api;
      axios
      .get(
        baseApiUrl +
          "syllabus-by-coach/" +
          this.state.userID +
          "/?page=" +
          this.state.currentPage
      )
        .then((response) => {
          // console.log(response)
          if (response.data.success === true) {
            Object.values(response.data.data.data).forEach((element) => {
              this.state.syllabusDatas.push(element);
            });

            if (
              response.data.data.current_page === response.data.data.last_page
            ) {
              this.setState({ hasMore: false, loading: false });
            } else {
              this.setState({ hasMore: true, loading: true });
            }
          }
        });
    }, 500);

    return this.state.syllabusDatas;
  };

  onValidateAddFormData = () => {
    let fields = this.state.fields;
    // console.log(fields);
    let errors = {};
    let isValidForm = true;

    if (!fields["syllabus_name"]) {
      isValidForm = false;
      this.setState({
        sname_empty: true,
      });
      this.onSwitchAlertStatus("sname_empty");
      errors["sname_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_description"]) {
      isValidForm = false;
      this.setState({
        sdescription_empty: true,
      });
      this.onSwitchAlertStatus("sdescription_empty");
      errors["sdescription_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["technologie_id"]) {
      isValidForm = false;
      this.setState({
        technologie_id_empty: true,
      });
      this.onSwitchAlertStatus("technologie_id_empty");
      errors["technologie_id_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["tool_id"]) {
      isValidForm = false;
      this.setState({
        tool_id_empty: true,
      });
      this.onSwitchAlertStatus("tool_id_empty");
      errors["tool_id_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["created_by"]) {
      isValidForm = false;
      this.setState({
        screatedby_empty: true,
      });
      this.onSwitchAlertStatus("screatedby_empty");
      errors["screatedby_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_level"]) {
      isValidForm = false;
      this.setState({
        slevel_empty: true,
      });
      this.onSwitchAlertStatus("slevel_empty");
      errors["slevel_empty"] = "*Ce champ est obligatoire";
    }
    if (!fields["syllabus_domaine_id"]) {
      isValidForm = false;
      this.setState({
        sdomaineid_empty: true,
      });
      this.onSwitchAlertStatus("sdomaineid_empty");
      errors["sdomaineid_empty"] = "*Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };
  onSwitchAlertStatus = (label) => {
    setTimeout(() => {
      if (label === "addSyllabusSuccessAlert") {
        this.setState({
          addSyllabusSuccessAlert: false,
        });
      }
      if (label === "addSyllabusErrorAlert") {
        this.setState({
          addSyllabusErrorAlert: false,
        });
      }
      if (label === "isGetSyllabusRequestError") {
        this.setState({
          isGetSyllabusRequestError: false,
        });
      }
      if (label === "sname_empty") {
        this.setState({
          sname_empty: false,
        });
      }
      if (label === "sdescription_empty") {
        this.setState({
          sdescription_empty: false,
        });
      }
      if (label === "screatedby_empty") {
        this.setState({
          screatedby_empty: false,
        });
      }
      if (label === "slevel_empty") {
        this.setState({
          slevel_empty: false,
        });
      }
      if (label === "sdomaineid_empty") {
        this.setState({
          sdomaineid_empty: false,
        });
      }
    }, 5000);
  };
  submitAddSyllabus = async (e) => {
    e.preventDefault();
    if (this.onValidateAddFormData()) {
      this.setState({
        addSyllabusInProgress: true,
      });
      //var baseApiUrl = this.state.api;
      var data = this.state.fields;
      // console.log("data", data);
      axios
        .post(this.state.api + "syllabus/create", this.state.fields)
        .then((response) => {
          // console.log(response);
          this.setState({
            addSyllabusInProgress: false,
          });
          // console.log(response.data.success);
          if (response.data.success) {
            this.onGetSyllabus();
            $(".close-modal-coach-title").click();
            toast.success(
              "Syllabus avec success",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 1000 }
            );
            //  $("#syllabus_form").reset();
            //this.onGetSyllabus();
            // setTimeout(() => {
            //   this.setState({
            //     showAlertSuccess: true,
            //   });
            // }, 1000);
          }
          // $(".close-modal-bakeliste-title").click();
        })
        .catch((error) => {
          if ((error.message = "Request failed with status code 422")) {
          }
          this.setState({
            addSyllabusInProgress: false,
            // showAlertError: true,
          });
          toast.error(
            "Erreur sur l'ajout de syllabus",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          // Sentry.captureException(error);
        });
    }
  };
  onGetSyllabus = () => {
    // const _this = this;
    //  this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;
    axios
    .get(
      baseApiUrl +
        "syllabus-by-coach/" +
        this.state.userID +
        "/?page=" +
        this.state.currentPage
    )
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          //console.log(response);
          // console.log("okk", response);
          this.setState({
            syllabusDatas: response.data.data.data,
          });
          //console.log(response);
        }
        this.state.syllabusDatas.forEach((item) => {
          let infos = {
            name: item.syllabus_name,
          };
          this.state.datas.push(infos);
        });
        // _this.hideLoader();
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        // _this.hideLoader();
      });
  };
  getSyllabusData() {
    const recherche = this.state.recherche.trim().toLocaleLowerCase();
    const syllabus = this.state.syllabusDatas;
    if (recherche.length < 1) {
      return syllabus;
    }
    return syllabus.filter((s) => {
      return s.syllabus_name.toLocaleLowerCase().includes(recherche);
    });
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  onGetDomaines = () => {
    const _this = this;
    // this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "liste-domaines-actifs")
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            domaines: response.data.data.reverse(),
          });
        }
        // _this.hideLoader();
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        // _this.hideLoader();
      });
  };

  setNavTabs = (event) => {
    // let navs = this.state.navs;
    // navs[event.target.name] = event.target.value;
    this.setState({
      tab_all_syllabus: false,
      // my_id: event.target.id,
    });
    const my_id = event.target.id;

    // this.onGetSyllabusByDomaine(event.target.value);
    this.onGetSyllabusByDomaine(my_id);
  };

  setNavall = (event) => {
    // let navs = this.state.navs;
    // navs[event.target.name] = event.target.value;
    // console.log(event.target.value);
    this.setState({
      tab_all_syllabus: true,
    });
  };

  onGetSyllabusByDomaine = (id) => {
    //  const _this = this;
    // console.log("yfhyf", id);
    // this.showLoader();
    this.setState({
      isPageLoading: true,
    });
    var baseApiUrl = this.state.api;

    axios
      .get(baseApiUrl + "syllabus-by-domaine/" + id)
      .then((response) => {
        //console.log("response",response)

        this.setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          this.setState({
            syllabusDomaineDatas: response.data.data.reverse(),
          });
        }
        this.state.syllabusDomaineDatas.forEach((item) => {
          let infos = {
            name: item.syllabus_name,
          };
          this.state.datasDomaine.push(infos);
        });
        //  _this.hideLoader();
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
        //   _this.hideLoader();
      });
  };
    render() {
      return  <div className="p-b-200">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col m-b-10">
                        <h6 className="h-theme ff-inter fw-600">Syllabus</h6>
                      </div>
                      <div className="col dis-flex m-b-15">
                        {/* <AddSyllabus/>
                      </div> */}
                      <button className="btn-add-with-icon-plus-circle" onClick={this.open}>
                        Ajouter
                        <Icon className="icon-add ml-2" icon="plus-circle" />
                      </button>
                      </div>
                      {/* <button
                        onClick={this.open}
                        className="btn-archive col-md-3 d-flex flex-row-reverse mr-3 offset-2 tope"
                      >
                        <NavLink className="add-group">
                          Ajouter{" "}
                          <Icon className="icon-add-groupe ml-2" icon="plus-circle" />
                        </NavLink>
                      </button> */}
                    </div>
                 
              <Modal
                className="modal-responsive-sm p-b-50"
                show={this.state.show}
                onHide={this.close}
              >
                <h5 className="modal-bakeliste-title m-b-50">
                  Créer un Syllabus
                </h5>
                <button
                  className="close-modal-coach-title"
                  onClick={this.close}
                >
                  <FeatherIcon icon="x" aria-hidden="true" />
                </button>
                <form
                  //encType="multipart/form-data"
                  id="syllabus_form"
                  onSubmit={(e) => this.submitAddSyllabus(e)}
                >
                  <div className="form-group form-group-add-syllabus">
                    <label for="nomSyllabus" className="label-add-syllabus">
                      Nom du syllabus
                    </label>
                    <input
                      type="text"
                      name="syllabus_name"
                      onChange={this.addHandleChange}
                      className="form-control input-add-syllabus"
                      id="nomSyllabus"
                      placeholder="Nom du syllabus"
                    />
                    {this.state.sname_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sname_empty}
                      </div>
                    )}
                  </div>
                  <div className="form-group form-group-add-syllabus">
                    <label
                      for="descriptionSyllabus"
                      className="label-add-syllabus"
                    >
                      Description du syllabus
                    </label>
                    <textarea
                      className="form-control input-add-syllabus"
                      rows="6"
                      id="descriptionSyllabus"
                      onChange={this.addHandleChange}
                      name="syllabus_description"
                      aria-describedby="descriptionHelp"
                      placeholder="Description du syllabus"
                    />{" "}
                    {this.state.remaining_char >= 31 && (
                      <span className="restant_container_sup_30">
                        {" "}
                        {this.state.remaining_char} caractéres restants
                      </span>
                    )}
                    {this.state.remaining_char <= 30 &&
                      this.state.remaining_char > 1 && (
                        <span className="restant_container_inf_30">
                          {" "}
                          {this.state.remaining_char} caractéres restants
                        </span>
                      )}
                    {this.state.remaining_char <= 1 && (
                      <span className="restant_container_inf_30">
                        {" "}
                        {this.state.remaining_char} caractére restant
                      </span>
                    )}
                    {this.state.sdescription_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sdescription_empty}
                      </div>
                    )}{" "}
                  </div>
                  <div className="form-group form-group-add-syllabus">
                    <label for="domaineSyllabus" className="label-add-syllabus">
                      Domaines
                    </label>{" "}
                    <select
                      name="syllabus_domaine_id"
                      onChange={this.addHandleChange}
                      className="form-control input_item"
                      id="syllabus_domaine_id"
                    >
                      <option> --Choisir un domaine-- </option>
                      {this.state.domaines.map((item, index) => (
                        <option key={index} value={item.id}>
                          {" "}
                          {item.name}{" "}
                        </option>
                      ))}
                    </select>
                    {this.state.sdomaineid_empty && (
                      <div className="errorMsg">
                        {this.state.errors.sdomaineid_empty}
                      </div>
                    )}
                  </div>

                  <div className="form-group form-group-add-syllabus">
                    <div className="form-group form-group-add-syllabus">
                      <label
                        for="outilsSyllabus"
                        className="label-add-syllabus"
                      >
                        Outils
                      </label>
                      <MultiSelect
                        overrideStrings={{
                          selectSomeItems: "Choisissez les outils",
                          allItemsAreSelected:
                            "Tous vos outils ont été choisis",
                          selectAll: "Sélectionnez tout",
                          search: "Rechercher",
                        }}
                        options={this.state.selectOptions2}
                        selected={this.state.selectedtools}
                        onSelectedChanged={(selectedtools) =>
                          this.setState({ selectedtools })
                        }
                      />
                    </div>
                    {this.state.stool_empty && (
                      <div className="errorMsg">
                        {this.state.errors.stool_empty}
                      </div>
                    )}
                  </div>

                  <div className="form-group form-group-add-syllabus">
                    <div className="form-group form-group-add-syllabus">
                      <label
                        htmlFor="syllabus_level"
                        className="label-add-syllabus"
                      >
                        Niveau ciblé
                      </label>{" "}
                      <br />
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="débutant"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label"> Débutant </span>
                      </span>
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="intermédiaire"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label">
                          {" "}
                          Intermédiaire{" "}
                        </span>
                      </span>
                      <span className="radio_button_container">
                        <input
                          type="radio"
                          value="avancé"
                          name="syllabus_level"
                          onChange={this.addHandleChange}
                          className="input_item"
                        />
                        &nbsp;{" "}
                        <span className="radio_button_label"> Avancé </span>
                      </span>
                      {this.state.slevel_empty && (
                        <div className="errorMsg">
                          {this.state.errors.slevel_empty}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group form-group-add-syllabus">
                    <label
                      for="LangagesFrameworkSyllabus"
                      className="label-add-syllabus"
                    >
                      Langages/framework
                    </label>{" "}
                    <MultiSelect
                      overrideStrings={{
                        selectSomeItems: "Choisissez les technologies",
                        allItemsAreSelected:
                          "Tous vos technologies ont été choisis",
                        selectAll: "Sélectionnez tout",
                        search: "Rechercher",
                      }}
                      options={this.state.selectOptions1}
                      selected={this.state.selected}
                      onSelectedChanged={(selected) =>
                        this.setState({ selected })
                      }
                    />
                  </div>
                  {this.state.technologie_id_empty && (
                    <div className="errorMsg">
                      {this.state.errors.technologie_id_empty}
                    </div>
                  )}

                  {this.state.addSyllabusErrorAlert && (
                    <div className="row assignation-error">
                      <div className="col-md-12 error-message-alert">
                        Un problème est survenu lors de l'ajout. Veuillez
                        réessayer plus tard.
                      </div>
                    </div>
                  )}
                  <div className="m-t-40 dis-flex justify-content-center">
                    <div className="col-sm-5">
                      {" "}
                      {this.state.addSyllabusInProgress ? (
                        <button
                          type="button"
                          className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          disabled
                        >
                          Ajout en cours &nbsp;
                          <FeatherIcon icon="loader" aria-hidden="true" />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn-theme w-full fs-13"
                        >
                          <span> Ajouter </span> &nbsp;
                          <img
                            className="submit_icon"
                            src="https://img.icons8.com/material-rounded/24/FFFFFF/filled-sent.png"
                            alt="Ajouter"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </Modal>
             
                    <div className="row">
                    {/* <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5 tope">
                <input
                  value={this.state.recherche}
                  type="search"
                  className="recherche"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                  onChange={(e) =>
                    this.setState({
                      recherche: e.target.value,
                    })
                  }
                />
              </div> */}
                    </div>
                    
                    <div className="row px-lg-0 px-md-2">
                    
            <div className="row px-lg-0 px-md-2">
              <div
                classname="col-md-2"
                onChange={(event) => this.setNavall(event)}
              >
                <div className="radiobtn">
                  <input
                    defaultChecked
                    type="radio"
                    value="all"
                    name="domaine"
                    id="all"
                    data-target="#all-syllabus"
                  />
                  <label for="all">All</label>
                </div>
              </div>
              {this.state.domaines.map((item, index) => (
                <div
                  classname="col"
                  onChange={(event) => this.setNavTabs(event)}
                >
                  <div className="radiobtn">
                    <input
                      key={index}
                      type="radio"
                      value={item.name}
                      name="domaine"
                      id={item.id}
                      data-target="#filter-syllabus"
                    />
                    <label for={item.id}>{item.name}</label>
                  </div>
                </div>
              ))}
            </div>
        
            <div className=" px-lg-0 px-md-2">
              <InfiniteScroll
                className="hello"
                dataLength={this.state.syllabusDatas.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={
                  this.state.loading &&
                  "Les donnees sont entrain de se charger...."
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Vous avez tous visualise!!!</b>
                  </p>
                }
              >
                {this.state.syllabusDatas.length > 0 &&
                this.state.tab_all_syllabus ? (
                  <div classname="row" id="all-syllabus">
                    {this.getSyllabusData().map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2"
                      >
                        <SyllabusItem
                          niveau={item.syllabus_level}
                          name={item.syllabus_name}
                          description={item.syllabus_description}
                          technologies={item.technologies}
                          syllabus_id={item.id}
                          outils={item.tools}
                          // groupes={item.assignationsgroupes.length}
                          nbreBakeliste={
                            item.assignations !== undefined &&
                            item.assignations.length
                          }
                          nbreTache={
                            item.details !== undefined && item.details.length
                          }
                          ongetSyllabus={this.onGetSyllabus}
                          created_by={item.created_by}
                          syllabus_domaine_id={item.syllabus_domaine_id}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="tab-pane active" id="filter-syllabus">
                    {this.state.syllabusDomaineDatas.map((item, index) => (
                      <div className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2">
                        <SyllabusItem
                          niveau={item.syllabus_level}
                          name={item.syllabus_name}
                          description={item.syllabus_description}
                          technologies={item.technologies}
                          syllabus_id={item.id}
                          outils={item.tools}
                          nbreBakeliste={item.assignations.length}
                          nbreTache={item.details.length}
                          // groupes={item.assignationsgroupes.length > 0 && item.assignationsgroupes.length }
                          ongetSyllabus={this.onGetSyllabus}
                          created_by={item.created_by}
                          syllabus_domaine_id={item.syllabus_domaine_id}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </InfiniteScroll>
            </div>
         
                      </div>
                    </div>
                  </div>
              </div>;
    }
  }
// export default connect(
//     ({ syllabusCoach }) => ({ ...syllabusCoach }),
//     dispatch => bindActionCreators({ ...syllabusCoachActions }, dispatch)
//   )( syllabusCoach );