import React, {Component} from 'react';
import './allListeRetard.css';
import {Link, NavLink} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import PointageItemForAdmin from '../pointageItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TableAllListePresence from '../tableAllListePresence/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as allListeRetardActions from "../../store/allListeRetard/actions";
export default class allListeRetard extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
            <div className="component-all-liste-retard">
              <div className="container px-0">
                <div className="row">
                    <div className="col-lg-9">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/dashboard-admin" className="lien_nav">Dashboard / </Link></li>
                                <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current"> All pointage </li>
                            </ol>
                        </nav>
                        
                       
                            <div className="row">
                                <div className="row m-b-5">
                                  
            <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
  

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link ml-4 lfb" href="/listeAllPointage">Liste de présences<span class="sr-only">(current)</span></a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link ml-4" href="/liste-absences">Liste d'absences</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link ml-4" href="/liste-retards">Liste de retards</a>
                    </li>
                    </ul>
    
                                      </div>
                                      </nav>
                                  

                                </div>
                               
                            </div>
                            <div className="row">
                                    <div className="col-lg-3 col-md-3 col-xl-3">
                                         <div className="dimention-cadres-pointages">
                                             <p className="placement-txt-pointe">
                                                 <span >
                                                     <input type="radio" className="rdio-btn-pointage"/>
                                                 </span>
                                               All
                                             </p> 
                                            </div>
                                        </div>
                                    <div className="col-lg-3 col-md-3 col-xl-3">
                                            <div className="dimention-cadres-pointages">
                                             <p className="placement-txt-pointe">
                                                 <span >
                                                     <input type="radio" className="rdio-btn-pointage"/>
                                                 </span>
                                                Dakar
                                             </p>     
                                            </div>
                                        </div>
                                    <div className="col-lg-3 col-md-3 col-xl-3">
                                    <div className="dimention-cadres-pointages">
                                        <p className="placement-txt-pointe">
                                            <span >
                                                <input type="radio" className="rdio-btn-pointage"/>
                                            </span>
                                            Thiès
                                        </p> 
                                    </div>
                                        </div>
                                    <div className="col-lg-3 col-md-3 col-xl-3">
                                    <div className="dimention-cadres-pointages">
                                        <p className="placement-txt-pointe">
                                            <span >
                                                <input type="radio" className="rdio-btn-pointage"/>
                                            </span>
                                            Labé
                                        </p> 
                                    </div>
                                    </div>
                                </div>
                            <div className="bande-gris-pointage">
                              <span className="center-txt-pointage">Liste  de retard </span>
                            </div>
                       <div className="card-dashboard m-b-15 p-b-60">
                    
            <div className="row m-b-5 m-t-5">
            <p className="h-theme ff-inter fw-600"></p>
              <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15">
              <div className="container-fluid px-0">
            <button className="btn btn-export-to"><ExitToAppIcon /> EXPORT TO CSV</button>
          </div>
              </div>
            </div>
                          
                <TableAllListePresence />                      
                      </div>

                    </div>
                    <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                <PointageItemForAdmin />
                            </div>
                           
                            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                                <ProgramReunionCoach />
                            </div>
                            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                <SavedReunionListItem />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              </div>
        );
    }
  }
// export default connect(
//     ({ allListeRetard }) => ({ ...allListeRetard }),
//     dispatch => bindActionCreators({ ...allListeRetardActions }, dispatch)
//   )( allListeRetard );