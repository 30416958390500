import React, {Component} from 'react';
import './programReunionCoach.css';
import IconProReunion from '../../img/icons/calendar.png';
import IconZoom from '../../img/icons/icon-zoom.png';
import { NavLink } from 'react-router-dom';

export default class programReunionCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="card-dashboard m-b-15 w-full dis-flex flex-col">
                <p className="h-card-dashboard-bakeliste m-b-30"><img className="icon-h-card-dashboard-bakeliste" src={IconProReunion} alt="" />Prochaine reunion</p>
                <p className="date-reunion-zoom-card-dashboard-bakeliste">Mar 12/10/2020 - 10H00</p>
                <NavLink className="btn-reunion-zoom-card-dashboard-bakeliste text-up mt-auto" to="/organiser-reunion">
                  <img className="icon-btn-reunion-zoom-card-dashboard-bakeliste" src={IconZoom} alt="" />
                  organiser
                </NavLink>
              </div>;
    }
  }
// export default connect(
//     ({ programReunionCoach }) => ({ ...programReunionCoach }),
//     dispatch => bindActionCreators({ ...programReunionCoachActions }, dispatch)
//   )( programReunionCoach );