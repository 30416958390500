import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  archiverBakelisteAsync,
  changerStatutStaigiareToBakeliste,
  getBakelisteStageAsync,
  mettreBakelistePause,
  showListBakelisteStages,
  termineFormationAsync,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import { timeFromX } from "../dayjsformat/dayJSDateFormat";
import "./bakelisteDash.css";
import TableBootstrap from "./TableBootstrap";
const AllBakelisteEnStages = () => {
  const dispatch = useDispatch();
  const bakelisteStageData = useSelector(showListBakelisteStages);

  useEffect(() => {
    dispatch(getBakelisteStageAsync());
  }, []);
  const actionFormatter = (cell, row) => {
    return (
      <span className="text-center">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Actions
          </Dropdown.Toggle>

          <Dropdown.Menu className="position-z">
            <Dropdown.Item>
              <span
                onClick={() => pauseBakelisteTraining(row.id)}
                style={{ textDecoration: "none" }}
              >
                Pause
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <span
                onClick={() => reintegreFormation(row.id)}
                style={{ textDecoration: "none" }}
              >
                Reintegrer
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <span
                onClick={() => terminerFormation(row.id)}
                style={{ textDecoration: "none" }}
              >
                Terminer
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <span
                onClick={() => archiverBakeliste(row.id)}
                style={{ textDecoration: "none" }}
              >
                Archiver
              </span>
            </Dropdown.Item>
            {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>{" "}
      </span>
    );
  };

  const terminerFormation = (id) => {
    var fd = new FormData();
    fd.append("bakeliste_id", id);
    dispatch(termineFormationAsync(fd));
  };

  const pauseBakelisteTraining = (id) => {
    var fd = new FormData();

    fd.append("bakeliste_id", id);
    dispatch(mettreBakelistePause(fd));
  };

  const archiverBakeliste = (id) => {
    dispatch(archiverBakelisteAsync(id));
  };

  const reintegreFormation = (id) => {
    var fd = new FormData();
    fd.append("bakeliste_id", id);
    dispatch(changerStatutStaigiareToBakeliste(fd));
  };

  // Bootstrap table name formatter
  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/bakelistes_new" className="lien_nav_current">
                Dashboard bakeliste
              </Link>
            </li>
            <li className="breadcrumb-item lien_nav_current">
              Liste des bakeliste en stage
            </li>
          </ol>
        </nav>
      </div>

      <div className="row">
        <div className="col-md-11">
          <TableBootstrap
            userData={bakelisteStageData}
            csvName="Liste bakeliste en stage"
            shouldExportCsv
            shouldFormatName
            shouldPaginate={bakelisteStageData?.length > 10}
            shouldSearch
            shouldShowPhoneNumber
            shouldShowTrainingDuration
            shouldFormatAction
            nameFormatter={nameFormatter}
            actionFormatter={actionFormatter}
          />
        </div>
      </div>
    </>
  );
};

export default AllBakelisteEnStages;
