import React, { Component } from "react";
import "./listeAdmins.css";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn,ExportCSVButton, } from "react-bootstrap-table";
import SkeletonAllBakeliste from "../tableMyAllbakelistes/skeletonAllBakeliste";

import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from "rsuite";
import { NavLink } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import * as Sentry from "@sentry/browser";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeAdminsActions from "../../store/listeAdmins/actions";
export default class listeAdmins extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      api: API.API,
      admin: [],
      isLoaded: true,

    };
  }

  componentDidMount() {
    this.getListAdmins();
  }


  archivedAdmin(id) {
    axios
      .post(this.state.api + "archive-user/" + id)
      .then((res) => {
        // console.log("archiver", res)
        if (res.data.success === true) {
          toast.success(
            "admin archivé avec success",
            { position: toast.POSITION.BOTTOM_LEFT }, 
            { autoClose: 1000 }
          );
          this.getListAdmins();
        } else {
          toast.error(
            "erreur sur l'archivage",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((err) => {
        console.log(err); 
      });
  }


  getListAdmins = () => {
    axios
      .get(this.state.api + "list-admins")
      .then((res) => {
        if (res.data.success === true) {
          //  console.log('domaines actifs', res.data.data)
          this.setState({
            admin: res.data.data,
            isLoaded: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };
  handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    console.log("This is my custom function for ExportCSVButton click event");
    onClick();
  };
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton style={{ "background-color": "#469789", "margin": "1rem" }} btnText="Export CSV" />
    );
  };

  actionFormatter = (cell, row) => {
    return (
      <span>
        &nbsp;&nbsp;
        <Tooltip title="Archivez admin">
          <FeatherIcon
            icon="archive"
            id="delete_group"
            style={{
              color: "red",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            onClick={() => {
              this.archivedAdmin(row.id);
            }}
            className="ajouter"
            cursor="pointer"
          />
        </Tooltip>
      </span> 
    );
  };


  render() {
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
    };
    return (
      <div className="component-nav-tabs-utilisateurs">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-12">
          {/* <button className="btn-add-with-icon-plus-circle ml-md-auto" onClick={this.addAdmin}>
              Ajouter
              <Icon className="icon-add ml-2" icon="plus-circle" />
            </button> */}
            
            <div className="col-md-2 btn-add-with-icon-plus-circle ml-auto mb-2">

          <NavLink
            className="ml-md-auto right-elements"
            to="/ajout-admin"
          >
            Ajouter
            <Icon className="ml-2" icon="plus-circle" />
          </NavLink>
          </div>
            {this.state.admin.length > 0 ? (
              <BootstrapTable
                data={this.state.admin} 
                striped  
                version='4' 
                exportCSV
                csvFileName="Liste des coachs.csv"
                bordered={ false } 
                bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                tdStyle={{ 'background': 'red' }}
                tableHeaderclassName="header-table-groupe-view"
                wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                className="table-responsive table-responsive-sm"
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  {" "}
                  ID{" "}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField='first_name' 
                 width="150"
                 thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                 tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs headerStyle">Prénom</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="last_name"
                  width="150"
                  thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                  tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                    <span className="color-nom-prenom-utilisateurs headerStyle">
                    Nom
                  </span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"

                 width="150"
                 thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                 tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs headerStyle">Email</span>
                </TableHeaderColumn>
               
                <TableHeaderColumn
                  dataFormat={this.actionFormatter}
                  width="150"
                  thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                  tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                    <span className="color-nom-prenom-utilisateurs headerStyle">Actions</span>
                </TableHeaderColumn>
                
              </BootstrapTable>
            ) : (
              <p className="pComment">
                Il n'y a pas encore d'admin actifs.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
      // <div className="component-table-my-list-coachs">
      //   <div className="col-md-2 ml-auto mb-2">
      //     <NavLink
      //       className="nav-link btn-theme right-elements"
      //       to="/ajout-admin"
      //     >
      //       Ajouter
      //       <Icon className="ml-2" icon="plus-circle" />
      //     </NavLink>
      //   </div>
      //   <BootstrapTable
      //     data={this.state.admin}
      //     striped
      //     version="4"
      //     bordered={false}
      //     exportCSV
      //     csvFileName="Liste des admins.csv"

      //     bodyStyle={{
      //       fontSize: 12,
      //       color: "rgba(0, 0, 0, 0.7)",
      //       fontFamily: "inherit",
      //       fontWeight: 400,
      //     }}
      //     tdStyle={{ background: "red" }}
      //     tableHeaderclassName="header-table-groupe-view"
      //     wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
      //     className="table-responsive table-responsive-sm"
      //   >
      //     <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
      //       {" "}
      //       ID{" "}
      //     </TableHeaderColumn>
      //     <TableHeaderColumn
      //       // filter={{type:"TextFilter", delay: 1000}}
      //       dataField="first_name"
      //       width="250"
      //       thStyle={{ border: "none", fontWeight: "400" }}
      //       tdStyle={{
      //         border: "none",
      //         padding: "1rem",
      //         "font-size": "14px",
      //         "font-family": "Montserrat",
      //       }}
      //     >
      //       <span className="color-nom-prenom-utilisateurs headerStyle">
      //         Prénoms
      //       </span>
      //     </TableHeaderColumn>
      //     <TableHeaderColumn
      //       className="titre-tab"
      //       dataField="first_namelast_name"
      //       thStyle={{ border: "none", fontWeight: "400" }}
      //       tdStyle={{ border: "none", padding: "1rem" }}
      //     >
      //       <span className="color-nom-prenom-utilisateurs headerStyle">

      //       Noms
      //       </span>
      //     </TableHeaderColumn>
      //     <TableHeaderColumn
      //       className="titre-tab"
      //       dataField="first_namelast_nameemail"
      //       thStyle={{ border: "none", fontWeight: "400" }}
      //       tdStyle={{ border: "none", padding: "1rem" }}
      //     >
      //       <span className="color-nom-prenom-utilisateurs headerStyle">

      //       Email
      //       </span>
      //     </TableHeaderColumn>

      //     <TableHeaderColumn
      //       dataField="action"
      //       dataFormat={this.actionFormatter}
      //       className="titre-tab"
      //       thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
      //       tdStyle={{
      //         border: "none",
      //         display: "flex",
      //         justifyContent: "center",
      //         padding: "1.2rem",
      //         "font-size": "14px",
      //         "font-family": "Montserrat",
      //       }}
      //     >
      //       <span className="color-nom-prenom-utilisateurs"> Actions</span>
      //     </TableHeaderColumn>
      //   </BootstrapTable>
      //   <div>
      //     <hr />
      //   </div>
      // </div>
    );
  }
}
// export default connect(
//     ({ listeAdmins }) => ({ ...listeAdmins }),
//     dispatch => bindActionCreators({ ...listeAdminsActions }, dispatch)
//   )( listeAdmins );
