import FeatherIcon from "feather-icons-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Appel from "../../img/appel.jpeg";
import ImageDossier from "../../img/folder_guess.png";
import {
  contactProspectAsync,
  getProspectsAsync,
  showListProspects,
} from "../../redux/slices/prospectSlices/prospectSlice";
import { history } from "../history";
import "./listProspects.css";

const ListeProspectRefac = () => {
  // dispatch
  const dispatch = useDispatch();

  const prospectsReduxData = useSelector(showListProspects);

  React.useEffect(() => {
    dispatch(getProspectsAsync());
  }, []);

  const onContactProspect = (prospectId) => {
    dispatch(contactProspectAsync(prospectId));
  };

  const voirDetail = (id) => {
    history.push(`/dossier-de-candidature-prospect/${id}`);
  };

  const transformer = (id) => {
    history.push(`/convertir-prospect-a-bakeliste?id=${id}`);
  };

  return (
    // console.log(prospectsReduxData),
    <div className="component-my-participants">
      {/* {this.state.isLoaded && <ListeProspectSkeleton />} */}
      <div className="container-fluid px-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                <FeatherIcon className="icon-breadcrumb-custom" icon="layout" />
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item active lien_nav" aria-current="page">
              <FeatherIcon
                className="icon-breadcrumb-custom"
                icon="user-plus"
              />
              Propects
            </li>
          </ol>
        </nav>
      </div>

      {/* <div className="row"> */}
        <div className="col-md-12">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <h6 className="h-theme ff-inter fw-600 px-3">
            </h6>
          </div>
          <div className="row">
          {prospectsReduxData.map((item, index) => (
              <>
                {item.is_called === true ? (
                  <div
                    key={index}
                    className="col-md-4 mb-3"
                  >
                    <img
                      className="place-folder"
                      src={ImageDossier}
                      alt=""
                      onClick={() => voirDetail(item.id)}
                    />
                    <span
                      onClick={() =>
                        window.confirm(
                          "Êtes-vous sûr de vouloir déclarer avoir appelé ce prospect ?"
                        ) && onContactProspect(item.id)
                      }
                    >
                      <FeatherIcon
                        className="icon"
                        icon="phone-outgoing"
                        type="button"
                        aria-hidden="true"
                      />
                    </span>
                    <div className="texte_centrer">
                      {`${item.firstName} ${item.lastName}`}
                      <br />
                      {item.email}
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    className="col-md-4 mb-3"
                  >
                    <img
                      className="place-folder"
                      src={Appel}
                      alt=""
                      onClick={() => voirDetail(item.id)}
                    />
                    <span>
                      <FeatherIcon
                        className="icon"
                        icon="phone-off"
                        type="button"
                        aria-hidden="true"
                      />
                    </span>
                    <span onClick={() => transformer(item.id)}>
                      <FeatherIcon
                        className="iconAppel"
                        icon="check-square"
                        type="button"
                        aria-hidden="true"
                      />
                    </span>
                    <div className="texte_centrer">
                      {item.firstName} &nbsp; {item.lastName}
                      <br />
                      {item.email}
                    </div>
                  </div>
                )}
                {/* <SweetAlert
                  show={this.state.pointDeparture}
                  onConfirm={() =>
                    this.confirm()
                  }
                  warning
                  title="Confirmez cette action!!!"
                  style={{
                    color: "rgb(240, 173, 78)",
                    fontSize: "12px",
                    fontFamily: "Work Sans, sans-serif",
                    paddingTop: 60,
                    paddingBottom: 40,
                  }}
                  confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                /> */}
              </>
            ))}
          </div>
          
        {/* </div> */}
      </div>
    </div>
  );
};

export default ListeProspectRefac;


