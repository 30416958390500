import React, {Component} from 'react';
import './tableMyAllbakelistes.css';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import API from "../../variablesGlobales";
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';
import SkeletonAllBakeliste from './skeletonAllBakeliste';
import $ from "jquery";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyAllbakelistesActions from "../../store/tableMyAllbakelistes/actions";




class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={18} />
        </button>
        
        
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}



export default class tableMyAllbakelistes extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          bakelistes: [],
          loading: true,
          bakelisteFullname:"",
          bakeliste_domaine_id: 0,
          isLoaded: true,
        };
    }
    componentDidMount() {
      this.getListAllBakelistes();
    }

    getListAllBakelistes = () => {
      axios
        .get(this.state.api + "liste-tous-les-bakelistes")
        .then((res) => {
          if (res.data.success === true) {
          // console.log('all bakelistes', res.data.data)
            this.setState({
              bakelistes: res.data.data,
              loading: true,
            });
          } else {
            this.setState({
              loading: false,
              isEmptyData: true,
            });
          }
          this.setState({ isLoaded: false });
          $("#start-hidden").removeClass("start-hidden");
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error.message);
        });
    };

    CustomExportCsvBtn = (onClick) =>{
      return(
        <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
      );
    }
    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      };
      return (
          <div className="component-table-my-allbakelistes">
            {this.state.isLoaded && <SkeletonAllBakeliste />}
<span className="color-listes-utilisateurs">Listes des Bakelistes</span>
                <BootstrapTable 
                  pagination
                  exportCSV
                  options={options}
                  csvFileName="utilisateurs.csv"
                  data={this.state.bakelistes} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                    
                  <TableHeaderColumn 
                    
                    isKey filter={{type:"TextFilter", delay: 1000}} 
                    dataField='bakelisteFullname'  
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Nom Complet</span> 
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                    dataField='domaine' 
                    filter={{type:"TextFilter", delay: 1000}}
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Domaine</span>
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                      dataField='type_formation' width="100"                   
                      filter={{type:"TextFilter", delay: 1000}}
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Type de Formation</span>
                     </TableHeaderColumn>
                     
                     <TableHeaderColumn 
                      dataField='type_stage' width="100" 
                      filter={{type:"TextFilter", delay: 1000}}                    
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Type de Stage</span>
                     </TableHeaderColumn>

                     <TableHeaderColumn 
                      dataField='phone' width="100" 
                      filter={{type:"TextFilter", delay: 1000}}
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Telephone</span>
                     </TableHeaderColumn>

                     <TableHeaderColumn 
                      dataField='ecole' width="100" 
                      filter={{type:"TextFilter", delay: 1000}}
                      thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                      tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Ecole</span>
                     </TableHeaderColumn>

                  <TableHeaderColumn dataField='action' 
                  dataFormat={uploadFormatter}                  
                  thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                  tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs"> Actions</span>
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
  }
// export default connect(
//     ({ tableMyAllbakelistes }) => ({ ...tableMyAllbakelistes }),
//     dispatch => bindActionCreators({ ...tableMyAllbakelistesActions }, dispatch)
//   )( tableMyAllbakelistes );