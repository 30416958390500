import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { history } from "../../../components/history";
import { API } from "../../../variablesGlobales";

const initialState = {
  isLoading: false,
  allBakeliste: [],
  bakelisteGy: [],
  bakelisteThies: [],
  bakelisteLabes: [],
  bakelistePresentiels: [],
  bakelisteEnPauses: [],
  bakelisteEnligne: [],
  bakelisteAbondonnes: [],
  bakelisteArchives: [],
  bakelisteTermines: [],
  bakelisteStages: [],
  bakelisteBloques: [],
  bakelisteDetailsData: [],
  fetchingDetails: false,
};

const API_URL = API;

export const bakelistetSlice = createSlice({
  name: "listAllBakeliste",
  initialState,

  reducers: {
    isFetchingBakeliste: (state) => {
      state.isLoading = true;
    },

    getAllBakeliste: (state, action) => {
      state.allBakeliste = action.payload;
    },

    isFetchingBKTDone: (state) => {
      state.isLoading = false;
    },

    getBakelisteGY: (state, action) => {
      state.bakelisteGy = action.payload;
    },
    getBakelisteThies: (state, action) => {
      state.bakelisteThies = action.payload;
    },
    getBakelisteLabes: (state, action) => {
      state.bakelisteLabes = action.payload;
    },
    getBakelistePresentiels: (state, action) => {
      state.bakelistePresentiels = action.payload;
    },

    isFetchingDetails: (state) => {
      state.fetchingDetails = true;
    },

    getBakelisteData: (state, action) => {
      state.bakelisteDetailsData = action.payload;
    },

    isFetchingDetailsDone: (state) => {
      state.fetchingDetails = false;
    },

    getBakelisteEnPauses: (state, action) => {
      state.bakelisteEnPauses = action.payload;
    },
    getBakelisteEnligne: (state, action) => {
      state.bakelisteEnligne = action.payload;
    },
    getBakelisteAbondonnes: (state, action) => {
      state.bakelisteAbondonnes = action.payload;
    },
    getBakelisteArchives: (state, action) => {
      state.bakelisteArchives = action.payload;
    },
    getBakeliseTermines: (state, action) => {
      state.bakelisteTermines = action.payload;
    },
    getBakeliseStages: (state, action) => {
      state.bakelisteStages = action.payload;
    },
    getBakeliseBqloques: (state, action) => {
      state.bakelisteBloques = action.payload;
    },
  },
});

// Recupère tous les bakelistes
export const getAllBakelisteAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}bakelistes-in-training`);
    if (res.data.success) {
      dispatch(isFetchingBKTDone());
      dispatch(getAllBakeliste(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

// Recupère les bakeliste de Grand Yoff
export const getBakelisteGyAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}bakeliste-grand-yoff2`);
    if (res.data.success) {
      //   dispatch(isFetchingDone());
      dispatch(getBakelisteGY(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};
//Bakeliste Thièes
export const getBakelisteThiesAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}list-bakelistes-thies`);
    if (response.data.success) {
      dispatch(getBakelisteThies(response.data.data));
    }
  } catch (error) {
    throw new Error(error);
  }
};
//Baklistes de Labés
export const getBakelisLabesAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}list-bakelistes-labe`);
    if (response.data.success) {
      dispatch(getBakelisteLabes(response.data.data));
    }
  } catch (error) {
    throw new Error(error);
  }
};

//Bakeliste en presentiel
export const getBakelistePresentielsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}bakelistes-training-presentiel-list`
    );
    if (response.data.success) {
      dispatch(getBakelistePresentiels(response.data.data.reverse()));
    }
  } catch (error) {}
};

// Details d'un bakeliste
export const getBakelisteDataAsync = (bakeliste_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}get-bakelistste-data/${bakeliste_id}`
    );
    if (response.data.success) {
      dispatch(isFetchingDetailsDone());
      dispatch(getBakelisteData(response?.data?.data));
    }
  } catch (error) {
    console.log(error);
  }
};

//Déclarer le bakeliste en tat que stagaire
export const changerStatutBakeliste = (formaData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${API_URL}bakeliste-to-stagiaire`,
      formaData
    );
    if (response.data.success) {
      toast.success(
        "Status changé avec success",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 500 }
      );
      dispatch(getAllBakelisteAsync());
      dispatch(getBakelisteTerminesAsync());
    }
  } catch (error) {
    toast.error(
      "Erreur sur le changement!!!",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
  }
};

//Staigiare to bakeliste
export const changerStatutStaigiareToBakeliste =
  (formaData) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_URL}stagiaire-to-bakeliste`,
        formaData
      );
      if (response.data.success) {
        toast.success(
          "Status changé avec success",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 500 }
        );
        dispatch(getAllBakelisteAsync());
        dispatch(getBakelisteStageAsync());
      }
    } catch (error) {
      toast.error(
        "Erreur sur le changement!!!",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  };
// Déclarer un bakeliste recrutable
export const declareBakelisteRecrutable = (formaData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${API_URL}change-bakeliste-to-recrutable`,
      formaData
    );
    if (response.data.success) {
      toast.success(
        "Ce bakeliste est désormais recrutable",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 500 }
      );
      dispatch(getAllBakelisteAsync());
      dispatch(getBakelisteEnligneeAsync());
      dispatch(getBakelistePresentielsAsync());
      dispatch(getBakelisteStageAsync());
      dispatch(getBakelisteTerminesAsync());
    } else {
      toast.error(
        "Erreur sur l'action, réessayer!!!!",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 500 }
      );
    }
  } catch (error) {
    toast.error(
      "Erreur sur l'action, réessayer!!!!",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 500 }
    );
  }
};

// Metrre un bakeliste en pause
export const mettreBakelistePause = (formaData) => async (dispatch) => {
  const response = await axios.post(
    `${API_URL}pause-bakeliste-training`,
    formaData
  );
  try {
    if (response.data.success) {
      toast.success(
        " La formation est mise en pause",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
      dispatch(getAllBakelisteAsync());
      dispatch(getBakelisteEnligneeAsync());
      dispatch(getBakelistePresentielsAsync());
    } else {
      toast.error(
        "Erreur sur l'action, réessayer!!!!'",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {
    toast.error(
      "Erreur sur l'action, réessayer!!!!'",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
  }
};

// debloquer le compte  d'un bakeliste
export const debloquerUnBakeliste = (bakeliste_id) => async (dispatch) => {
  const response = await axios.post(
    `${API_URL}unblock-user-account/${bakeliste_id}`
  );
  if (response.data.success) {
    toast.success(
      "Compte debloque avec succes",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
    dispatch(getAllBakelisteAsync());
    dispatch(getBakelisteBloquesAsync());
  } else {
    toast.error(
      "Erreur debloquage compte",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
  }
};

//bloquer le compte d'un bakeliste
export const bloquerUnBakelisteAsync = (bakeliste_id) => async (dispatch) => {
  const response = await axios.post(
    `${API_URL}block-user-account/${bakeliste_id}`
  );
  if (response.data.success) {
    toast.success(
      "Compte bloqué avec succes",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
    dispatch(getAllBakelisteAsync());
    dispatch(getBakelistePresentielsAsync());
  } else {
    toast.error(
      "Erreur le bloquage  du compte",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
  }
};
//Modifier un bakeliste

export const updateBakeliste = (id, formaData) => async (dispatch) => {
  const response = await axios.post(
    `${API_URL}update-bakeliste-infos/${id}`,
    formaData
  );
  if (response.data.success) {
    toast.success(
      "Bakeliste modifié avec succes",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
    dispatch(getAllBakelisteAsync());
    history.push("/bakelistes_new");
  } else {
    toast.error(
      "erreur sur la Mofication!!!",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
  }
};

export const archiverBakelisteAsync = (bakeliste_id) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}archive-user/${bakeliste_id}`);
    if (response.data.success) {
      toast.success(
        " Bakeliste archivé avec succes",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
      dispatch(getAllBakelisteAsync());
      dispatch(getBakelisteEnligneeAsync());
      dispatch(getBakelistePresentielsAsync());
      dispatch(getBakelisteStageAsync());
      dispatch(getBakelisteTerminesAsync());
    } else {
      toast.error(
        " Erreur sur l'archivage",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};
export const desarchiverBakelisteAsync = (bakeliste_id) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${API_URL}desarchive-user/${bakeliste_id}`
    );
    if (response.data.success) {
      toast.success(
        " Bakelisteb désarchivé avec succes",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
      dispatch(getBakelisteArchivesAsync());
    } else {
      toast.error(
        " Erreur sur ledésarchivage",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};
export const getBakelisteEnPauseAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}bakelistes-training-pause-list`
    );
    if (response.data.success) {
      dispatch(getBakelisteEnPauses(response.data.data));
    }
  } catch (error) {}
};

export const getBakelisteEnligneeAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}bakelistes-training-online-list`
    );
    if (response.data.success) {
      dispatch(getBakelisteEnligne(response.data.data));
    }
  } catch (error) {}
};

export const getBakelisteArchivesAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}liste-des-bakelistes-archives`);
    if (response.data.success) {
      dispatch(getBakelisteArchives(response.data.data));
    }
  } catch (error) {}
};
export const getBakelisteAbondonnessync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}bakelistes-training-abandon-list`
    );
    if (response.data.success) {
      dispatch(getBakelisteAbondonnes(response.data.data));
    }
  } catch (error) {}
};

export const activerBakelisteTrainingAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${API_URL}activate-paused-bakeliste-training`,
      data
    );
    if (response.data.success) {
      toast.success(
        " Formation réactivée",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
      dispatch(getBakelisteEnPauseAsync());
    } else {
      toast.error(
        " erreur sur la réintégration!!!",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};

export const termineFormationAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_URL}terminer_formation`, data);
    if (response.data.success) {
      toast.success(
        " Formation terminée",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
      dispatch(getAllBakelisteAsync());
      dispatch(getBakelisteTerminesAsync);
      dispatch(getBakelisteStageAsync());
      dispatch(getBakelisteEnligneeAsync());
      dispatch(getBakelistePresentielsAsync());
    } else {
      toast.error(
        " Erreur",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};

export const getBakelisteTerminesAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${API_URL}bakelistes-with-completed-training`
    );
    if (response.data.success) {
      dispatch(getBakeliseTermines(response.data.data));
    }
  } catch (error) {}
};
export const getBakelisteStageAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}liste-stagiaires`);
    if (response.data.success) {
      dispatch(getBakeliseStages(response.data.data));
    }
  } catch (error) {}
};

export const getBakelisteBloquesAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}blocked-user-list`);
    if (response.data.success) {
      dispatch(getBakeliseBqloques(response.data.data));
    }
  } catch (error) {}
};

export const activerBakelistePaused = (bakeliste_id) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${API}activate-paused-bakeliste-training`,
      bakeliste_id
    );
    if (response.data.success) {
      toast.success(
        " Formation réactivée",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
      dispatch(getBakelisteEnPauseAsync());
    } else {
      toast.success(
        "Impossible de réactiver",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};

export const abandonBakelisteTrainingAsync = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${API}declarer-bakeliste-abandon`, data);
    if (response.data.success) {
      toast.success(
        " Bakeliste declare comme abandonne avec succes",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    } else {
      toast.success(
        "Erreur sur l'action, réessayer!!!!",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (error) {}
};
export const {
  isFetchingBakeliste,
  getAllBakeliste,
  isFetchingBKTDone,
  getBakelisteGY,
  getBakelisteThies,
  getBakelisteLabes,
  getBakelistePresentiels,
  getBakelisteEnPauses,
  getBakelisteEnligne,
  getBakelisteAbondonnes,
  getBakelisteArchives,
  getBakeliseTermines,
  getBakeliseStages,
  getBakeliseBqloques,
  isFetchingDetails,
  getBakelisteData,
  isFetchingDetailsDone,
} = bakelistetSlice.actions;
export const isLoadingBakeliste = (state) => state.listAllBakeliste?.isLoading;
export const showListBakeliste = (state) =>
  state.listAllBakeliste?.allBakeliste;
export const isLoadingBakelisteDetails = (state) =>
  state.listAllBakeliste?.fetchingDetails;
export const showBakelisteDetailsData = (state) =>
  state.listAllBakeliste?.bakelisteDetailsData;
export const showListBakelisteGy = (state) =>
  state.listAllBakeliste.bakelisteGy;
export const showListBakelisteThies = (state) =>
  state.listAllBakeliste.bakelisteThies;
export const showListBakelisLabes = (state) =>
  state.listAllBakeliste.bakelisteLabes;
export const showListBakePresentiels = (state) =>
  state.listAllBakeliste.bakelistePresentiels;
export const showListBakeEnPause = (state) =>
  state.listAllBakeliste.bakelisteEnPauses;
export const showListBakeEnligne = (state) =>
  state.listAllBakeliste.bakelisteEnligne;
export const showListBakeAbondonnes = (state) =>
  state.listAllBakeliste.bakelisteAbondonnes;
export const showListBakeArchives = (state) =>
  state.listAllBakeliste.bakelisteArchives;
export const showListBakelistTermines = (state) =>
  state.listAllBakeliste.bakelisteTermines;
export const showListBakelisteStages = (state) =>
  state.listAllBakeliste.bakelisteStages;
export const showListBakelisteBloques = (state) =>
  state.listAllBakeliste.bakelisteBloques;
export default bakelistetSlice.reducer;
