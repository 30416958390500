import React, {Component} from 'react';
import './visiteursAdmin.css';
import {Link} from 'react-router-dom';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import ImageDossier from '../../img/folder_guess.png';


export default class visiteursAdmin extends Component {
    
    render() {
      return (
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-lg-9">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/dashboard-admin" className="lien_nav">Dashboard / </Link></li>
                      <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current"></li>
                    </ol>
                  </nav>
                  <div className="row">
                    <div className="col">
                      <h6 className="h-theme ff-inter fw-600 px-3">Dossier des visiteurs</h6>
                    </div>
                  </div>
                  
                  <div className="container"> 
                      <div className="dossier-visiteur mb-3">
                        <div className="row">
                          <div className="col-lg-4 col-xl-3 col-md-2 m-l-45">
                            <img className="place-folder" src={ImageDossier} alt="" />
                            <p className="">Khady Cissé</p>
                            <p className="">+221 77 800 00 00</p>
                             <p className="titre-dossiercandidature">Dossier de candidature</p>
                          </div>
                          <div className="col-lg-4 col-xl-3 col-md-2 m-l-45">
                            <img className="place-folder" src={ImageDossier} alt="" />
                            <p className="">Ngoné Diop</p>
                            <p className="">+221 77 800 00 00</p>
                            <p className="titre-dossiercandidature">Dossier de candidature</p>
                          </div>
                          <div className="col-lg-4 col-xl-3 col-md-2 m-l-45">
                            <img className="place-folder" src={ImageDossier} alt="" />
                            <p className="">Ngoné Diop</p>
                            <p className="">+221 77 800 00 00</p>
                          <p className="titre-dossiercandidature">Dossier de candidature</p>
                          </div>

                        </div>
                        
                      </div>
                   </div>
                   
                   <div className="row">
                    <div className="col">
                      <h6 className="h-theme ff-inter fw-600 px-3">Statistique</h6>
                    </div>
                  </div>
                  <div className="container"> 
                      <div className="dossier-visiteur mb-3">
                        <div className="row">
                          

                        </div>
                        
                      </div>
                   </div>
                  </div>


                  {/*<div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                <div className="row">
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteLateItemForAdmin />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteMissingItemForAdmin />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                    <ProgramReunionCoach />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <SavedReunionListItem />
                  </div>
                </div>
              </div>*/}
                </div>
              </div>
      );
    }
  }
// export default connect(
//     ({ visiteursAdmin }) => ({ ...visiteursAdmin }),
//     dispatch => bindActionCreators({ ...visiteursAdmin }, dispatch)
//   )( visiteursAdmin );