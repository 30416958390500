import React, { Component } from "react";
import "./navTabsProfilBakeliste.css";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfilBakeliste from "../profilBakeliste/index";
import BakelisteActifs from "../bakelisteActifs/index";
import BakelisteNonActifs from "../bakelisteNonActifs/index";
import BakelisteArretes from "../bakelisteArretes/index";
import BakelisteTermines from "../bakelisteTermines/index";
import FeatherIcon from "feather-icons-react";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navTabsProfilBakelisteActions from "../../store/navTabsProfilBakeliste/actions";
export default class navTabsProfilBakeliste extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-nav-tabs-profil-bakeliste">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="user-check"
                    />
                    Profil Bakeliste
                  </li>
                </ol>
              </nav>
              <Container className="container-fluid">
                <Row>
                  <Col>
                    <Tabs
                      defaultActiveKey="All Bakeliste"
                      id="controlled-tab-example"
                    >
                      <Tab eventKey="All Bakeliste" title="All Bakeliste">
                        <ProfilBakeliste />
                      </Tab>
                      <Tab eventKey="Bakeliste Actifs" title="Bakeliste Actifs">
                        <BakelisteActifs />
                      </Tab>
                      <Tab
                        eventKey="Bakeliste Non actifs"
                        title="Bakeliste Non actifs"
                      >
                        <BakelisteNonActifs />
                      </Tab>
                      <Tab
                        eventKey="Bakeliste Arretés"
                        title="Bakeliste Arretés"
                      >
                        <BakelisteArretes />
                      </Tab>
                      <Tab
                        eventKey="Bakeliste Terminés"
                        title="Bakeliste Terminés"
                      >
                        <BakelisteTermines />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ navTabsProfilBakeliste }) => ({ ...navTabsProfilBakeliste }),
//     dispatch => bindActionCreators({ ...navTabsProfilBakelisteActions }, dispatch)
//   )( navTabsProfilBakeliste );
