import React from 'react'

import './coachAssistantView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import CoachAssistantRefact from '../coachAssistant/CoachAssistantRefact';

const CoachAssistantViewRefact = () => {
  return (
    <div className="body-theme">
    <HeaderDashboardAdmin />
    <div className="container-fluid fixing-after-header">
      <div className="row">
        <div className="col-lg-2 p-0">
          <NavVerticalAdmin />
        </div>
        <div className="col-lg-10 pt-3">
          <CoachAssistantRefact />
        </div>
      </div>
    </div>
  </div>
  )
}

export default CoachAssistantViewRefact