import React, { Component } from "react";
import "./savedReunionListItem.css";
import IconEnrgReunion from "../../img/icons/youtube.png";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import API from "../../variablesGlobales";
import frenchShortFormatDate from "../frenchFormatDate";

export default class savedReunionListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      saved_meetings: [],
      formatDate: frenchShortFormatDate.frenchShortFormatDate,
    };
  }

  componentDidMount() {
    this.onGetSavedZoomMeetings();
  }
  onGetSavedZoomMeetings = () => {
    axios
      .get(this.state.api + "saved_meeting_zooms")
      .then((response) => {
        this.setState({
          saved_meetings: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    return (
      <div className="card-dashboard m-b-15 w-full dis-flex flex-col">
        <p className="h-card-dashboard-bakeliste m-b-30">
          <img
            className="icon-h-card-dashboard-bakeliste"
            src={IconEnrgReunion}
            alt=""
          />
          Reunions enregistrées
        </p>

        <ul className="list-none p-0">
          {this.state.saved_meetings.map((item, index) => (
            <li className="li-save-reunion-dashboard-bakeliste">
              Reunion du {this.state.formatDate(item.zoom_datetime)}{" "}
              <FeatherIcon
                className="icon-li-save-reunion-dashboard-bakeliste"
                icon="play-circle"
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
// export default connect(
//     ({ savedReunionListItem }) => ({ ...savedReunionListItem }),
//     dispatch => bindActionCreators({ ...savedReunionListItemActions }, dispatch)
//   )( savedReunionListItem );
