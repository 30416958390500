import React, { Component } from "react";
import "./myParticipants.css";
// import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
// import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
// import ProgramReunionCoach from "../programReunionCoach/index";
// import SavedReunionListItem from "../savedReunionListItem/index";
import TableMyParticipants from "../tableMyParticipants/index";
import { Link, NavLink } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as myParticipantsActions from "../../store/myParticipants/actions";
export default class myParticipants extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-my-participants">
        <div className="container-fluid px-0">
          {/* <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard-admin" className="lien_nav">
                  Dashboard /{" "}
                </Link>
              </li>
              <li
                className="breadcrumb-item active"
                aria-current="page"
                className="lien_nav_current"
              >
                {" "}
                participants{" "}
              </li>
            </ol>
          </nav> */}
        </div>
        <div className="row">
          <div className="col-lg-12">
            

            {/* <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item">
                    <a class="nav-link" href="/users-Admin">
                      Users<span class="sr-only">(current)</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link ml-4" href="/utilisateurs-coach-admin">
                      Coachs
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link ml-4" href="/all-bakelistes-admin">
                      Bakelistes
                    </a>
                  </li>
                  <li class="nav-item active">
                    <a class="nav-link ml-4" href="/participants-admin">
                      Participants
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link ml-4" href="/bf-bakelistes-admin">
                      BF.Terminés
                    </a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link ml-4" href="/visiteurs-admin">
                      Visiteur
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link ml-4" href="/user-menager-admin">
                      User Manager
                    </a>
                  </li>
                </ul>
              </div>
            </nav> */}

            <div className=" m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <TableMyParticipants />
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteLateItemForAdmin />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteMissingItemForAdmin />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                <ProgramReunionCoach />
              </div>
              <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <SavedReunionListItem />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ myParticipants }) => ({ ...myParticipants }),
//     dispatch => bindActionCreators({ ...myParticipantsActions }, dispatch)
//   )( myParticipants );
