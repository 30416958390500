import React from "react";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import ListeProspectRefac from "../listProspects/ListeProspectRefac";
import NavVerticalAdmin from "../navVerticalAdmin/index";

const ListeProspectsViewRefac = () => {
  return (
    <div className="component-table-my-participants-view body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3"> 
            {/* <ListProspects /> */}
            <ListeProspectRefac />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeProspectsViewRefac;
