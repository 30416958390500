import React, { useEffect, useReducer } from "react";
import "./addSousDomaines.css";
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from "rsuite";
import FeatherIcon from "feather-icons-react";
import data from "../dataTesting.json";
import API from "../../variablesGlobales";
import axios from "axios";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { addSousDomaineAsync, getDomainesAsync, showListDomaines } from "../../redux/slices/parametreSlices/parametreSlice";
import { useSelector } from "react-redux";
toast.configure();
const AddSousDomainesRefact = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      show: false,
      api: API.API,
      loading: false,
      domaines: [],
      domaine_id: "",
      nom: "",
      description: "",
    }
  );
  const sousDomaineData = useSelector(showListDomaines)
const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getDomainesAsync())
    getAllDomaines()
  },[])
  const getAllDomaines = () => {
    axios
      .get(state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        setState({
          domaines: domains.data,
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
  const addSousDomaines = (e) => {
    e.preventDefault();
    setState({
      loading: true,
    });
    var fd = new FormData();
    fd.append("description", state.description);
    fd.append("nom", state.nom);
    fd.append("domaine_id", state.domaine_id);
    dispatch(addSousDomaineAsync(fd))
    setTimeout(()=>{
      $(".close-modal-bakeliste-title").click();

    },1500)
    // axios
    //   .post(state.api + "sous_domaines", fd, {
    //     Headers: { "Content-Type": "multipart/formdata" },
    //   })
    //   .then((res) => {
    //     if (res.data.success) {
    //       setState({
    //         loading: false,
    //       });
    //       props.getAllSousDomaines();
    //       $(".close-modal-bakeliste-title").click();
    //       toast.success(
    //         "Sous Domaine ajouté avec success",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     } else {
    //       toast.error(
    //         "erreur sur l ajout",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 3000 }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error.message);
    //     setState({
    //       loading: false,
    //     });
    //   });
  };
  const close = () => {
    setState({ show: false });
  };
  const open = () => {
    setState({ show: true });
  };
  return (
    <div className="modal-container ml-md-auto">
      <button className="btn-add-with-icon-plus-circle" onClick={open}>
        Ajouter
        <Icon className="icon-add ml-2" icon="plus-circle" />
      </button>
      <Modal
        className="modal-responsive-sm p-b-50"
        show={state.show}
        onHide={close}
      >
        <h5 className="modal-bakeliste-title m-b-50">
          Ajouter un sous-domaine 
        </h5>
        <button className="close-modal-bakeliste-title" onClick={close}>
          <FeatherIcon icon="x" aria-hidden="true" />
        </button>
        <form
          onSubmit={(e) => addSousDomaines(e)}
          id="domaine_form"
          className="mt-5"
        >
          <div className="form-row row">
            <div className="col-md-6">
              <div className="form-group ">
                <input
                  type="nom"
                  className="form-control ab1 si-form-input"
                  id="inputName"
                  placeholder="Nom"
                  name="nom"
                  onChange={(e) => setState({ nom: e.target.value })}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                  className="form-control ab1 si-form-input"
                  name="domaine_id"
                  onChange={(e) =>
                    setState({ domaine_id: e.target.value })
                  }
                  required
                >
                  <option>--choisir un domaine--</option>
                  {sousDomaineData.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <textarea
                  className="form-control ab1 si-form-input"
                  placeholder="description"
                  name="description"
                  onChange={(e) =>
                    setState({ description: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-md-6  offset-6 submit-btn-container">
            <div className="form-group ">
              {!state.loading ? (
                <button className="btn btn-theme">
                  <i className="fas fa-plus-circle"></i> &nbsp; Ajouter
                </button>
              ) : (
                <button className="btn btn-theme loading-btn">
                  Envoie en cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default AddSousDomainesRefact;
