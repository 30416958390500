import React, {Component} from 'react';
import './ri.css';
import Logo from '../../img/logo.png';

export default class ri extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return <div className="ri-content dis-flex flex-col">
              <img className="ri-logo" src={Logo} alt="" />
              <h3 className="h-theme m-b-30 text-center lh-1">Réglement intérieur v1.0</h3>
              <h6 className="h-theme m-b-15">Objet</h6>
              <p className="text-dark ff-montserrat fw-300 m-b-25">Le présent règlement a pour  objet de : <br />
                Préciser l'application à l'entreprise de la réglementation en matière d'hygiène et de sécurité.<br />
                Déterminer les règles générales et permanentes relatives à la discipline ainsi que la nature et l'échelle des sanctions applicables.<br />
                Rappeler les garanties de procédure dont jouissent les étudiants en matière de sanctions disciplinaires. <br /><br />

                Il s'applique à tous les étudiants de l'entreprise où qu'ils se trouvent (lieu de travail, parking, salle de repos...).<br /><br />

                Toute autre prescription générale, portée à la connaissance de tous par note de service, sera considérée comme adjonction au présent règlement et aura même force d'application. <br /><br />

                Pour une meilleure information, ce document sera communiqué à chaque nouvel étudiant pour qu'il en prenne connaissance et sera affiché dans l'entreprise. 
                
              </p>
              <p>
                <button className="lire-suite-btn" type="button" data-toggle="collapse" data-target="#collapseRI" aria-expanded="false" aria-controls="collapseRI">
                   Integralité du RI
                </button>
              </p>
              <div className="collapse" id="collapseRI">
                <h6 className="h-theme m-b-15 fw-500">Article 1: Accès et horaires d'ouverture </h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">Les locaux sont ouverts du Lundi au Samedi de 8h00 à 18h minimum. Des horaires différents peuvent être appliqués en cas de nécessité.<br />
                  Les étudiants ont droit à une pause café  de 10h30 à 11h ainsi qu’une pause déjeuner de 13h30 à 15h.<br />
                  Les étudiants doivent respecter les horaires de travail affichés. Les étudiants ne sont aucunement autorisés à introduire dans l'entreprise des personnes étrangères à celle-ci, ainsi que tout animal.</p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 2:  Utilisation des ressources logistiques et informatiques</h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">L'utilisation d'Internet, de l'intranet, du téléphone, du fax et de la messagerie électronique ... de l'entreprise peut être réalisée pour un usage personnel, à condition d'être utilisé de bonne foi. Les étudiants ayant accès à Internet doivent dans la mesure du possible veiller à ne pas diffuser d'informations sensibles ou confidentielles sur les activités de l'entreprise. <br />
                  L'utilisation d'Internet doit être réalisée dans le respect des règles de sécurité et des dispositions légales relatives notamment au droit de propriété, à la diffamation, aux fausses nouvelles, aux injures et provocations. Les agissements contraires aux règles de fonctionnement établies dans l'entreprise pourront entraîner des sanctions disciplinaires.</p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 3: Retards et absences </h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">Tout retard doit être signalé et justifié auprès du Directeur des Ressources Humaines sous peine de sanctions. <br />
                  Les étudiants doivent également se  rapprocher de leurs coachs après chaque retard et se justifier. <br /> <br />

                  Aucun étudiant ne peut s'absenter de son poste de travail sans motif valable, ni quitter l'établissement sans autorisation préalable.</p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 4: Exécution du travail </h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">Les étudiants  doivent se conformer aux directives qui leurs sont données par leurs coachs et effectuer leurs tâches dans les meilleurs délais en vue de faciliter la formation.<br />
                  Tout manquement aux règles relatives à la discipline donnera lieu à l'application de l'une des sanctions prévues par le présent règlement.</p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 5: Discipline</h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">Tout comportement considéré comme fautif par l'employeur pourra, en fonction de sa nature et de sa gravité, faire l'objet d'une des sanctions énumérées ci-après par ordre d'importance. (Ex : absences non motivées, retards répétés, non-réalisation du travail à accomplir, comportement désinvolte, introduction de personnes étrangères dans le service, faute professionnelle etc.)</p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 6: Sanctions</h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">
                  Sont susceptibles d'être mises en oeuvre dans l'entreprise, les sanctions suivantes : <br/><br/>
                  <ul className="list-none m-b-20">
                    <li>
                      Paiement d’une amende de 50 FCFA pour chaque minute de retard
                    </li>
                    <li>
                      Pompe gymnastique pour chaque minute de retard
                    </li>
                    <li>
                      Avertissement oral 
                    </li>
                    <li>
                      Avertissement écrit
                    </li>
                    <li>
                      Renvoi de la formation sans préavis 
                    </li>
                  </ul>
                  L’étudiant sera convoqué par l'employeur à un entretien préalable lorsque celui-ci envisagera de prendre une des 3 dernières sanctions qui précède à son égard.
                </p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 7: Tabac</h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">Il est interdit de fumer dans tous les lieux fermés de Volkeno-SARL. <br/>
                  Il est donc formellement interdit de fumer dans : les bureaux, la salle de détente et de repas, la salle de réunion, les couloirs.<br/>
                  Sauf dans le lieu spécialement réservé aux fumeurs.</p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 8: Boissons et restaurations</h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">Les boissons et tout autre aliment devront être consommés dans la cuisine.<br/>
                  La salle de détente et de repas est un lieu convivial, commun qui doit rester propre et bien tenu à tout instant.</p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 9: Règles d’hygiène</h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">
                  Les étudiants veilleront à ce que:<br/>
                  Les ustensiles de cuisine soient nettoyés après chaque utilisation.<br/>
                  De passer la serpillère après les ablutions. <br/>
                  Maintenir les toilettes propres.<br/>
                  De veiller à la propreté de son espace de travail.
                </p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 10: Autres dispositions</h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">
                  Toute personne ayant accès aux locaux ne peut :<br/>
                  Causer du désordre en quelque lieu que ce soit<br/>
                  Faire du bruit dans les couloirs, salle de travail, salle de détente de manière à nuire au bon déroulement des activités de la société.
                </p>
                
                <h6 className="h-theme m-b-15 fw-500">Article 11: Entrée en vigueur et modification dudit règlement </h6>
                <p className="text-dark ff-montserrat fw-300 m-b-25">
                  Ce règlement entrera en vigueur le 28-10-2019 Toute modification ultérieure du règlement interne sera soumise à la procédure définie dans le  code du travail.<br/><br/>

                  Fait à Dakar , le 28-10-2019
                </p>
                
                <h6 className="text-dark m-b-15 fw-500 text-right">Par Binetou Rassoul Samb</h6>
              </div>

              
            </div>;
    }
}
