import React, {Component} from 'react';
import './stagiaireView.css'
// import { connect } from "react-redux";
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import Stagiaire from "../../components/stagiaire/index"
import API from "../../variablesGlobales";

import axios from "axios";
import * as Sentry from "@sentry/react";

// import { bindActionCreators } from "redux";
// import * as stagiaireViewActions from "../../store/stagiaireView/actions";
export default class stagiaireView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Stagiaire:[],
          api:API.API,
        };
    }
    componentDidMount() {
      this.getStagiaires();
    }
    getStagiaires() {
      const _this = this;
  
      axios
        .get(this.state.api + "liste-stagiaires")
        .then((response) => {
          if (response.data.data !== undefined) {
            this.setState({
              Stagiaire: response.data.data,
            });
            // console.log(this.state.Stagiaire)
          } else {
            this.setState({
              Stagiaire: 0,
            });
          }
          // _this.hideLoader();
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
          // _this.hideLoader();
        });
    }
    render() { 
      return  <div className="body-theme">
                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <Stagiaire
                      Stagiaire={this.state.Stagiaire} />
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ stagiaireView }) => ({ ...stagiaireView }),
//     dispatch => bindActionCreators({ ...stagiaireViewActions }, dispatch)
//   )( stagiaireView );