import React, {Component} from 'react';
import './editBakelisteView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import EditBakeliste from '../editBakeliste/index';
import qs from "qs";
 
export default class editBakelisteView extends Component {
    constructor(props) {
        super(props);
          let id = this.props.location?.state?.id;
          let data = this.props.location?.state.data;
            if (!id && this.props.location.search) {
            const obj = qs.parse(this.props.location.search.replace("?", ""));
            id = parseInt(obj.id);
          }
              this.state = { 
                id: id,
                data:data,
              };
              console.log(this.state.data, this.state.id, "kaaay")
        }
    
    render() {
      // console.log("data",this.state.data)
      return  <div className="body-theme">
                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <EditBakeliste
                      id={this.state.id}
                      domaine_id={this.state.data.bakeliste_domaine_id}
                      first_name={this.state.data.first_name}
                      last_name={this.state.data.last_name}
                      full_name={this.state.data.user_fullname}
                      domaine={this.state.data.domaine}
                      lieu_formation={this.state.data.lieu_formation}
                      debut_formation={this.state.data.debut_formation}
                      fin_formation={this.state.data.fin_formation}
                      adresse={this.state.data.address}
                      ecole={this.state.data.ecole}
                      phone={this.state.data.phone}
                      email={this.state.data.email}
                      // horaire={this.state.data.horaire}
                      paiement_inscription={this.paiement_inscription}

                        />
                    </div>
                  </div> 
                </div> 

                
              </div>;
    }
  }