import React, {Component} from 'react';
import './tableMyReporting.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';


var gestionTacheForCoach = [{
  fullname: "Abdou ba",
  tache: "Developpement Web",
  duree: "6h",
  statut: "To Do",
  action: "oui"
},{
  fullname: "Moumadjad ahmed abdou",
  tache: "Developpement Web",
  duree: "6h",
  statut: "To Do",
  action: "oui "
},{
  fullname: "Mounir ismael moegne daho",
  tache: "Developpement Web",
  duree: "6h",
  statut: "To Do",
  action: "oui "
},{
  fullname: "malaoupé mbaba",
  tache: "Developpement Web",
  duree: "6h",
  statut: "To Do",
  action: "non "
},{
  fullname: "mwegne hamadi omar",
  tache: "Developpement Web",
  duree: "6h",
  statut: "To Do",
  action: "oui "
},{
  fullname: "Mouayad ahmed abdou",
  tache: "Developpement Web",
  duree: "6h",
  statut: "To Do",
  action: "non "
}];

class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col cadre">
        <p className="badge done-badge">Bakeliste</p>

        {/* !!!!important!!!! */}
        {/* <p className="badge doing-badge">Doing</p> */}
        {/* <p className="badge done-badge">Done</p> */}
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return (
    <Status active={ cell } />
  );
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={16} />
        </button>
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="share-2" size={16} />
        </button>
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="edit-2" size={16} />
        </button>
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}

export default class tableMyCoachs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    CustomExportCsvBtn = (onClick) =>{
      return(
        <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
      );
    }
    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      };
      return  <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
                <BootstrapTable
                  pagination
                  options={options}
                  exportCSV
                  csvFileName="reporting.csv"
                  data={gestionTacheForCoach} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                  <TableHeaderColumn className="titre-tab" isKey dataField='fullname' thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none', 'padding': '1rem' }}>Prénom & Nom </TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='tache' thStyle={{ 'border': 'none', 'fontWeight': '400' }} tdStyle={{ 'border': 'none', 'padding': '1rem' }}>Date</TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='duree' width="100" dataSort thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1rem' }}>Tâche</TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='statut' dataFormat={changeStatus} width="100" dataSort thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1rem' }}>Statut</TableHeaderColumn>
                  <TableHeaderColumn className="titre-tab" dataField='action' dataFormat={uploadFormatter} thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1rem' }}>Actions</TableHeaderColumn>
                </BootstrapTable>
              </div>;
    }
  }
