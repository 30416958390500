import React, {Component} from 'react';
import './listProspectsView.css'
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import ListProspects from '../listProspects/index';
export default class listProspectsView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return (
              <div className="component-table-my-participants-view body-theme">
                  <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <ListProspects />
                    </div>
                  </div>
                </div>
              </div>
       );
    }
  }
