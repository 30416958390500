import React, { useEffect } from "react";
import "./editEvent.css";
import API from "../../variablesGlobales";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import { useReducer } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getEventsAsync,
  showtEvent,
  updateEventAsync,
} from "../../redux/slices/eventsSlices/eventSlice";
const EditEventRefact = (props) => {
  const eventData = useSelector(showtEvent);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      details: [],
      id: props.location?.state?.id,
      rencontre_description: props.location?.state?.rencontre_description,
      rencontre_end_date: props.location?.state?.rencontre_end_date,
      rencontre_end_time: props.location?.state?.rencontre_end_time,
      rencontre_name: props.location?.state?.rencontre_name,
      rencontre_start_date: props.location?.state?.rencontre_start_date,
      rencontre_start_time: props.location?.state?.rencontre_start_time,
      rencontre_type: props.location?.state?.rencontre_type,
      is_payant: props.location?.state?.is_payant,
      prix_participation:props.location?.state?.prix_participation
    }
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEventsAsync(state.id));
    getDetailsEvent();
  }, []);

  const getDetailsEvent = () => {
    axios
      .get(state.api + "rencontres/" + state.id)
      .then((res) => {
        // console.log("hmm", res.data.data);
        setState({
          details: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editEvent = (e) => {
    e.preventDefault()
    let fd = new FormData();

    fd.append("rencontre_name", state.rencontre_name);
    fd.append("rencontre_description", state.rencontre_description);
    fd.append("rencontre_type", state.rencontre_type);
    fd.append("rencontre_end_date", state.rencontre_end_date);
    fd.append("rencontre_end_time", state.rencontre_end_time);
    fd.append("rencontre_start_date", state.rencontre_start_date);
    fd.append("rencontre_start_time", state.rencontre_start_time);
    fd.append("is_payant", state.is_payant);
    fd.append("rencontre_start_date", state.rencontre_start_date);
    fd.append("prix_participation",state.prix_participation)
    dispatch(updateEventAsync(state.id,fd))
  };
  console.log("id", state.id);
  console.log("data", eventData);

  return (
    <div className="component-profil-visiteur-view body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <div className="p-b-200">
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-lg-9">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                        <li className="breadcrumb-item">
                          <Link
                            to="/dashboard-admin"
                            className="lien_nav_current"
                          >
                            <FeatherIcon
                              className="icon-breadcrumb-custom"
                              icon="layout"
                            />
                            Dashboard
                          </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/evenements" className="lien_nav_current">
                            <FeatherIcon
                              className="icon-breadcrumb-custom"
                              icon="airplay"
                            />
                            Evenements
                          </Link>
                        </li>
                        <li
                          className="breadcrumb-item active lien_nav"
                          aria-current="page"
                        >
                          <FeatherIcon
                            className="icon-breadcrumb-custom"
                            icon="edit"
                          />
                          Modifier evenement
                        </li>
                      </ol>
                    </nav>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6">
                        <h6 className="h-theme ff-inter fw-600 px-3">
                          {" "}
                          {/* <FeatherIcon icon="arrow-left" />
              Créer Evenements */}
                        </h6>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="card mb-3">
                        <div className="card-body">
                          <form onSubmit={(e)=>editEvent(e)}>
                            <div className="row mb-3 pt-3">
                              <label
                                htmlfor="inputEmail3"
                                className="col-form-label pb-3 pl-3"
                              >
                                Nom de l'événement
                              </label>
                              <div className="col-sm-12">
                                <input
                                  onChange={(e) =>
                                    setState({
                                      rencontre_name: e.target.value,
                                    })
                                  }
                                  value={state.rencontre_name}
                                  type="text"
                                  name="rencontre_name"
                                  className="form-control taille-des-input-event"
                                  id="inputEmail3"
                                />
                                {/* <span className="dep-crt-caractere offset-11">0/75</span> */}
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlfor="inputPassword3"
                                className="col-form-label pb-3 pl-3"
                              >
                                Type de la rencontre
                              </label>
                              <div className="col-sm-12">
                                <select
                                  onChange={(e) =>
                                    setState({
                                      rencontre_type: e.target.value,
                                    })
                                  }
                                  className="form-control form-select taille-des-input-event"
                                  name="rencontre_type"
                                >
                                  <option selected>
                                    {" "}
                                    -- choisir une option--{" "}
                                  </option>
                                  <option value="evenement">Evenement</option>
                                  <option value="meetuo">Meetup</option>
                                  <option value="entretien">Entretien</option>
                                </select>
                              </div>
                            </div>
                            <label
                              htmlfor="inputPassword3"
                              className="col-form-label"
                            >
                              Rencontre payant
                            </label>
                            <div className="form-check-inline ali">
                              <input
                                type="radio"
                                id="huey"
                                name="is_payant"
                                value={state.is_payant}
                                onChange={(e) =>
                                  setState({ is_payant: e.target.value })
                                }
                              />
                              <label className="col-md-3 taille-option-non-oui">
                                Oui
                              </label>
                              <input
                                onChange={(e) =>
                                  setState({ is_payant: e.target.value })
                                }
                                type="radio"
                                id="dewey"
                                name="is_payant"
                                value={state.is_payant}
                                checked
                               
                              />
                              <label className="col-md-3 taille-option-non-oui">
                                Non
                              </label>
                            </div>

                            {state.is_payant  && (
                              <div className="row mb-3 pt-3">
                                <label
                                  htmlfor="inputEmail3"
                                  className="col-form-label pb-3 pl-3"
                                >
                                  Ajouter un prix pour l'accès
                                </label>
                                <div className="col-sm-12">
                                  <input
                                    onChange={(e) =>
                                      setState({
                                        prix_participation: e.target.value,
                                      })
                                    }
                                    type="text"
                                    name="prix_participation"
                                    className="form-control taille-des-input-event"
                                    id="inputEmail3"
                                    value={state.prix_participation}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="row mb-3">
                              <div className="col-lg-6 col-xl-6 col-md-6">
                                <div className="row">
                                  <div className="col-lg-6 col-xl-6 col-md-6">
                                    <label className="col-form-label pb-3">
                                      Date de début
                                    </label>
                                    <input
                                      onChange={(e) =>
                                        setState({
                                          rencontre_start_date: e.target.value,
                                        })
                                      }
                                      type="date"
                                      id="start"
                                      name="rencontre_start_date"
                                      className="supp-taille-input"
                                      value={state.rencontre_start_date}
                                    />
                                  </div>
                                  <div className="col-lg-6 col-xl-6 col-md-6">
                                    <label className="col-form-label pb-3">
                                      Heure de début
                                    </label>
                                    <input
                                      onChange={(e) =>
                                        setState({
                                          rencontre_start_time: e.target.value,
                                        })
                                      }
                                      type="time"
                                      id="appt"
                                      name="rencontre_start_time"
                                      value={state.rencontre_start_time}
                                      className="supp-taille-input"
                                      placeholder="09:00"
                                      min="09:00"
                                      max="18:00"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-xl-6 col-md-6">
                                <div className="row">
                                  <div className="col-lg-6 col-xl-6 col-md-6">
                                    <label className="col-form-label pb-3">
                                      Date de fin
                                    </label>
                                    <input
                                      onChange={(e) =>
                                        setState({
                                          rencontre_end_date: e.target.value,
                                        })
                                      }
                                      type="date"
                                      id="start"
                                      name="rencontre_end_date"
                                      value={state.rencontre_end_date}
                                      className="supp-taille-input"
                                      placeholder="2021-01-05"
                                      // min="2021-01-05"
                                      // max="2021-06-01"
                                    />
                                  </div>
                                  <div className="col-lg-6 col-xl-6 col-md-6">
                                    <label className="col-form-label pb-3">
                                      Heure de fin
                                    </label>
                                    <input
                                      onChange={(e) =>
                                        setState({
                                          rencontre_end_time: e.target.value,
                                        })
                                      }
                                      type="time"
                                      id="appt"
                                      value={state.rencontre_end_time}
                                      name="rencontre_end_time"
                                      className="supp-taille-input"
                                      placeholder="17:00"
                                      min="09:00"
                                      max="18:00"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-3 pt-3">
                              <label
                                htmlfor="inputEmail3"
                                className="col-form-label pb-3 pl-3"
                              >
                                Description
                              </label>
                              <br />
                              <div className="col-sm-12">
                                <div className="la-couleur-p mb-2">
                                  Ajouter des détails tels que des sujets,
                                  programmes ...
                                </div>
                                <textarea
                                  type="text"
                                  rows="1"
                                  cols="5"
                                  name="rencontre_description"
                                  className="form-control taille-des-input-event"
                                  id="inputEmail3"
                                  value={state.rencontre_description}
                                  onChange={(e) =>
                                    setState({
                                      rencontre_description: e.target.value,
                                    })
                                  }
                                ></textarea>
                              </div>
                            </div>

                            <div>
                              {!state.loading ? (
                                <button className="col-md-4 btn btn-theme">
                                  <i className="fas fa-plus-circle"></i> &nbsp;
                                  Modifier
                                </button>
                              ) : (
                                <button className="col-md-4 offset-2 btn btn-theme loading-btn">
                                  Modification en cours &nbsp;
                                  <i className="fas fa-spin fa-spinner"></i>
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEventRefact;
