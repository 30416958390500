import { toast } from "react-toastify";
toast.configure();


const existMsg = (message) => {
  toast.warning(
      message,
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 500 }
    );
}


export default existMsg