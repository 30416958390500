import React from "react";
import "./navTabsProfilBakeliste.css";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfilBakeliste from "../profilBakeliste/index";
import BakelisteActifs from "../bakelisteActifs/index";
import BakelisteNonActifs from "../bakelisteNonActifs/index";
import BakelisteArretes from "../bakelisteArretes/index";
import BakelisteTermines from "../bakelisteTermines/index";
import FeatherIcon from "feather-icons-react";
import ProfilBakelisteRefact from "../profilBakeliste/ProfilBakelisteRefact";
import BakelisteActifsRefact from "../bakelisteActifs/BakelisteActifsRefact";
import BakelisteNonActifsRefact from "../bakelisteNonActifs/BakelisteNonActifsRefact";
import BakelisteTerminesRefact from "../bakelisteTermines/BakelisteTerminesRefact";
const NavTabsProfilBakelisteRefact = () => {
  return (
    <div className="component-nav-tabs-profil-bakeliste">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon
                    className="icon-breadcrumb-custom"
                    icon="user-check"
                  />
                  Profil Bakeliste
                </li>
              </ol>
            </nav>
            <Container className="container-fluid">
              <Row>
                <Col>
                  <Tabs
                    defaultActiveKey="All Bakeliste"
                    id="controlled-tab-example"
                  >
                    <Tab eventKey="All Bakeliste" title="All Bakeliste">
                      <ProfilBakelisteRefact />
                    </Tab>
                    <Tab eventKey="Bakeliste Actifs" title="Bakeliste Actifs">
                      <BakelisteActifsRefact />
                    </Tab>
                    <Tab
                      eventKey="Bakeliste Non actifs"
                      title="Bakeliste Non actifs"
                    >
                      <BakelisteNonActifsRefact />
                    </Tab>
                    <Tab eventKey="Bakeliste Arretés" title="Bakeliste Arretés">
                      <BakelisteArretes />
                    </Tab>
                    <Tab
                      eventKey="Bakeliste Terminés"
                      title="Bakeliste Terminés"
                    >
                      <BakelisteTerminesRefact />
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavTabsProfilBakelisteRefact;
