import React, { Component } from "react";
import "./tableSousDomaines.css";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from "react-bootstrap-table";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import * as Sentry from "@sentry/browser";
import { Modal } from "rsuite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";

toast.configure();

class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col cadre">
        <p className="badge done-badge">Actif</p>

        {/* !!!!important!!!! */}
        {/* <p className="badge doing-badge">Doing</p> */}
        {/* <p className="badge done-badge">Done</p> */}
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return <Status active={cell} />;
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon
            className="icon-btn-icon-table"
            icon="edit-2"
            size={16}
          />
        </button>
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return <Actions active={cell} />;
}

export default class tableSousDomaines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      domaines: [],
      allSousDomaines: [],
      id: 0,
      showEditModal: false, 
      size: "md",
      archiver: false,
      syllabus: {},
      // api : API.API,
      sousDoamaine: {},
      nom: "",
     description: "",
      domaine_id: "",
      fields: {},
      sousDomaineID: "",
      domaine_name: "",
      sous_domaine_id: "",
      id_Sous_Domaine: ""
    };
  }

  componentDidMount() {
    this.getListDomaines();
    this.getListAllSousDomaines();
  }

  getListDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        if (res.data.success === true) {
          //  console.log('domaines actifs', res.data.data)
          this.setState({
            domaines: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  editDomaineModal = (id) => {
    this.onGetSousDomaine(id);
    this.setState({
      showEditModal: true,
    });
  };

  onGetSousDomaine = (id) => {
    this.setState({
      isPageLoading: true,
    });
    axios
      .get(this.state.api + "sous_domaines/" + id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        // console.log(response, "res")
        if (response.data.success) {
          this.setState({
            sousDoamaine: response.data.data,
            nom: response.data.data.nom,
            domaine_id: response.data.data.domaine_id,
            description: response.data.data.description,
            sous_domaine_id: response.data.data.id
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };
  close = () => {
    this.setState({
      showEditModal: false,
    });
  };

  onEditSousDomaine = (e) => {
    e.preventDefault();
    this.setState({
      editDomaineInProgress: true,
    });
    var fd = new FormData();
    fd.append("nom", this.state.nom);
    fd.append("domaine_id", this.state.domaine_id);
    fd.append("description", this.state.description);

    axios
      .put(this.state.api + "sous_domaines/" + this.state.sous_domaine_id, fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        // console.log("start")
        // console.log("response", response)
        this.setState({
          editDomaineInProgress: true,
        });
        if (response.data.success === true) {
          // console.log("i m here")
          this.setState({
            showEditModal: false,
            editDomaineInProgress: false,
          });
          this.getListAllSousDomaines();
          toast.success(
            "Domaine modifiée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          this.setState({
            showEditModal: false,
          });
          toast.error(
            "erreur sur la modification",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 3000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          editSyllabusContentInProgress: false,
        });
        toast.error(
          "erreur sur la modification",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 3000 }
        );
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };

  actionsFormatter = (cell, row) => {
    let id = row.id;
    return (
      <span>
        <span className="modifier" onClick={() => this.editDomaineModal(id)}>
          <FeatherIcon
            className="icon-details-container-syllabus-item-back"
            icon="edit-3"
          />
          Modifier
        </span>
        <span className="archiver" onClick={() => this.setState({ archiver: true,
        id_Sous_Domaine: row.id })}

        >
          <FeatherIcon
            className="icon-details-container-syllabus-item-back mgleft"
            icon="trash"
          />
          Archiver
        </span>
        
      </span>
    );
  };

  archiverSousDomaine = () => {
    // // console.log(id);
    // var fd = new FormData()
    // fd.append("domaine_id", this.state.id_Sous_Domaine)
    axios
      .post(this.state.api + "archive_sous_domaine/" + this.state.id_Sous_Domaine )
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.getListAllSousDomaines();
          toast.success(
            "Sous domaine archivé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'archivage!!!",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  }
  handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    console.log("This is my custom function for ExportCSVButton click event");
    onClick();
  };
  createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton style={{ "background-color": "#469789", "margin": "1rem" }} btnText="Export CSV" />
    );
  };

  
  getListAllSousDomaines = () => {
    axios
      .get(this.state.api + "sous_domaines")
      .then((res) => {
        // console.log(res, "sous")
        if (res.data.success === true) {

          this.setState({
            allSousDomaines: res.data.data,
            // domaine_name: res.data.data.domaine.name,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  render() {
    const options = {
      exportCSVBtn: this.createCustomExportCSVButton,
    };
    return (
      <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
        <BootstrapTable
          data={this.state.allSousDomaines}
          striped
          version="4"
          bordered={false}
          exportCSV
          csvFileName="Liste des admins.csv"

          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
            {" "}
            ID{" "}
          </TableHeaderColumn>
          <TableHeaderColumn
            // filter={{type:"TextFilter", delay: 1000}}
            dataField="nom"
            width="250"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">
              Nom
            </span>
          </TableHeaderColumn>
          {/* <TableHeaderColumn
            className="titre-tab"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none", padding: "1rem" }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">

            Domaine 
            </span>
          </TableHeaderColumn> */}
          <TableHeaderColumn
            className="titre-tab"
            dataField="description"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none", padding: "1rem" }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">

            Description
            </span>
          </TableHeaderColumn>
          <TableHeaderColumn
            className="titre-tab"
            dataFormat={this.actionsFormatter}
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none", padding: "1rem" }}
          >
            <span className="color-nom-prenom-utilisateurs headerStyle">

            Actions
            </span>
          </TableHeaderColumn>

        </BootstrapTable>
       
        <div>
                  <SweetAlert
                    warning
                    show={this.state.archiver}
                    title="Etes vous sur de voulouir archiver ce sous domaine?"
                    // text="SweetAlert in React"
                    confirmBtnText="Oui"
                    cancelBtnText="Non"
                    onConfirm={() => {
                      // console.log("confirm");
                      this.setState({ archiver: false });
                      // this.pauseBakelisteTraining();
                      this.archiverSousDomaine();
                    }}
                    showConfirm={true}
                    showCloseButton={true}
                    onCancel={() => {
                      // console.log("cancel");
                      this.setState({ archiver: false });
                    }}
                    showCancel={true}
                    focusCancelBtn={true}
                    cancelBtnBsStyle="danger"
                  />
                </div>
        <div>
          <hr />
        </div>

        <Modal
          className="modal-responsive-md"
          size={this.state.size}
          show={this.state.showEditModal}
          onHide={this.close}
        >
          <form onSubmit={(e) => this.onEditSousDomaine(e)}>
            <Modal.Header>
              <Modal.Title className="modal-contact-us">
              
              <h5 className="modal-bakeliste-title m-b-50">Modifier sous-domaine</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-height-modal">
              <div className="form-group as_input_container">
                <label>Nom du domaine</label>
                <input
                  value={this.state.nom}
                  onChange={(e) =>
                    this.setState({ nom: e.target.value })
                  }
                  name="nom"
                  // placeholder="Nom du syllabus"
                  className="form-control input_item"
                  id="name"
                />
              </div>
              <div className="form-group as_input_container">
                <label>Description du domaine</label>
                <textarea
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  name="description"
                  placeholder="Nom du syllabus"
                  className="form-control input_item"
                  id="description"
                />
              </div>
              <div className="form-group as_input_container">
              
                <select
                        className="form-control input_item"
                        name="domaine_id"
                        onChange={(e) => this.setState({ domaine_id: e.target.value })}
                        required
                  value={this.state.domaine_id}

                        

                      >
                        <option>-{this.state.domaine_name}</option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="row dis-flex justify-content-end">
                <div className="col-md-5 col-sm-6">
                  {this.state.editDomaineInProgress ? (
                    <button
                      type="submit"
                      className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                      disabled
                    >
                      Modification en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      MODIFIER
                    </button>
                  )}
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
