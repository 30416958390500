import React, {Component} from 'react';
import './listeFillieresAdminView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import ListeFillieresAdmin from '../listeFillieresAdmin/index';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/bakelisteMissingItemForAdmin';
import ProgramReunionCoach from '../programReunionCoach/programReunionCoach';
import SavedReunionListItem from '../savedReunionListItem/savedReunionListItem';

export default class listeFillieresAdminView  extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="body-theme">
                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                    <ListeFillieresAdmin />
                    </div>
                  </div>
              </div>
                      
                



    
              </div>;
    }
  }