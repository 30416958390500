import React from "react";
import EventParticipate from "./EventParticipate";

const EventParticipateView = () => {
  return (
    <div>
      <EventParticipate />
      {/* <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo ullam ea
        ratione molestias voluptatum earum unde possimus id non molestiae sed
        laudantium tenetur voluptatem, suscipit numquam dolore veritatis quam.
        Quisquam!
      </p> */}
    </div>
  );
};

export default EventParticipateView;
