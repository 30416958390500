import React, {Component} from 'react';
import './editDomaineView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import EditDomaine from '../editDomaine/editDomaine';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as editDomaineViewActions from "../../store/editDomaineView/actions";
export default class editDomaineView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
      <div className="component-edit-domaine-view body-theme">
         <HeaderDashboardAdmin />
            <div className="container-fluid fixing-after-header">
              <div className="row">
                <div className="col-lg-2 p-0">
                  <NavVerticalAdmin />
                </div>
                <div className="col-lg-10 pt-3">
                  <EditDomaine />
                </div>
              </div> 
          </div> 
       </div>
      );
    }
  }
// export default connect(
//     ({ editDomaineView }) => ({ ...editDomaineView }),
//     dispatch => bindActionCreators({ ...editDomaineViewActions }, dispatch)
//   )( editDomaineView );