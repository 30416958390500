import React, { Component } from "react";
import "./listeParticipantsView.css";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import ListeParticipants from "../listeParticipants/index";
import qs from "qs";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeParticipantsViewActions from "../../store/listeParticipantsView/actions";
export default class listeParticipantsView extends Component {
  constructor(props) {
    super(props);
    let participants = this.props.location?.state?.participants;

    this.state = { 
      participants: participants 
    };
    //  console.log("ok", this.state.participants);
  }
  render() {
    return (
      <div className="component-liste-participants-view body-theme">
        <HeaderDashboardAdmin /> 
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalAdmin />
            </div>
            <div className="col-lg-10 pt-3">
              <ListeParticipants participants={this.state.participants} />
            </div>
          </div> 
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeParticipantsView }) => ({ ...listeParticipantsView }),
//     dispatch => bindActionCreators({ ...listeParticipantsViewActions }, dispatch)
//   )( listeParticipantsView );
