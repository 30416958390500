import React, { useEffect, useReducer } from "react";
import "./editBakeliste.css";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import TableDebutant from "../tableDebutant/index";
import $ from "jquery";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import { useDispatch } from "react-redux";
import { updateBakeliste } from "../../redux/slices/bakelistesSlices/bakelistesSlice";
const EditBakelisteRefact = (props) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      domaines: [],
      bakeliste: [],
      domaineName: [],
      // bakeliste_domaine_id: "",
      editBakelisteInProgress: false,
      bakeliste_domaine_id: props.domaine_id,
      first_name: props.first_name,
      last_name: props.last_name,
      domaine: props.domaine,
      address: props.adresse,
      email: props.email,
      phone: props.phone,
      debut_formation: props.debut_formation,
      fin_formation: props.fin_formation,
      ecole: props.ecole,
      lieu_formation: props.lieu_formation,
      paiement_inscription:props.paiement_inscription,
      horaire: "9h-17h",
      boursier:0,
      demi_boursier:0,
      is_isa_contract: 0,
      is_stagede_formation: 0,
      // horaire: "",
    }
  );

  const dispatch = useDispatch()

 const getDomaine =() => {
    axios.get(state.api + "domaines").then((res) => {
      const domains = res.data;
      setState({
        domaines: domains.data,
      });
    });
  }
  const handleChange = (e) => {
    setState(() => ({
      bakeliste: { [e.target.name]: e.target.value },
    }));
  };
const getDomaineById =()=> {
    axios
      .get(state.api + "domaines/" + props.domaine_id)
      .then((res) => {
        setState({
          domaineName: res.data.data.name,
        });
        // console.log("ee",state.domaineName)
      });
  }
 const getHoraireById =()=> {
    axios
      .get(state.api + "programmation_horaires/" + props.id)
      .then((res) => {
        setState({
          horaire: res.data.data.horaire,
        });
        //  console.log("ee",state.horaire)
      });
  }
  
 useEffect(()=>{
    getDomaine();
    getDetailsBakelistes();
    getDomaineById();
    getHoraireById()
 },[])

 const getDetailsBakelistes = () => {
    axios
      .get(state.api + "get-profile/" + props.id)
      .then((res) => {
        if (res.data.success === true) {
          // console.log("Details Bakeliste", res.data.data);
          setState({
            bakeliste: res.data.data,
            bakeliste_domaine_id: res.data.data.bakeliste_domaine_id,
            loading: false,
          });
        } else {
          setState({
            loading: false,
            isEmptyData: true,
          });
        }
      })
      .catch((error) => {
        setState({
          loading: false,
          getBakelisteRequestError: true,
        });
        console.log(error.message);
      });
  };

 const editBakeliste = (e) => {
    // console.log("ddddddddd",state.bakeliste)
    e.preventDefault();
    setState({
      editBakelisteInProgress: true,
    });
    var fd = new FormData();
    fd.append("first_name", state.first_name);
    fd.append("boursier", 0);
    fd.append("demi_boursier", 0);
    fd.append("is_formation_payante", 1);
    // fd.append("admin_id", 0);
    // fd.append("horaire", state.horaire);
    fd.append("is_isa_contract", state.is_isa_contract);
    fd.append("is_stagede_formation", state.is_stagede_formation);
    fd.append("paiement_inscription", 1);
    fd.append("bakeliste_domaine_id", state.bakeliste_domaine_id);
    fd.append("last_name", state.last_name);
    fd.append("ecole", state.ecole);
    fd.append("phone", state.phone);
    fd.append("address", state.address);
    fd.append("debut_formation", state.debut_formation);
    fd.append("fin_formation", state.fin_formation);
    fd.append("lieu_formation", state.lieu_formation);
    // fd.append("domaine", state.domaine);
    fd.append("email", state.email); 
    dispatch(updateBakeliste(props.id,fd))
    // axios
    //   .put(state.api + "update-bakeliste-infos/" + props.id, fd, {
    //     Headers: { "Content-Type": "multipart/formdata" },
    //   })
    //   .then((result) => {
    //     // console.log("editer", result);
    //     setState({
    //       editBakelisteInProgress: false,
    //     });
    //     // console.log(result);
    //     if (result.data.success) {
    //       window.location = "/bakelistes-db";
    //     }
    //   });
  };
const annuler = (e) => {
  window.location="/bakelistes-db"
}
 const onChange = (e) => {
    setState({ bakeliste_domaine_id: e.value });
  };

  return   <div className="p-b-200">
  <div className="row">
    <div className="col-lg-9">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard-admin" className="lien_nav">
            Dashboard{" "}
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/bakelistes-db" className="lien_nav">
              Base de données bakelistes  /{" "}
            </Link>
          </li>
          <li
            className="breadcrumb-item active lien_nav_current"
            aria-current="page"
           
          >
            {" "}
            Modification données bakeliste 
          </li>
        </ol>
      </nav>
      <form className="form1" onSubmit={(e) => editBakeliste(e)}>
        <div>
          <h4 className="h-theme titreCoach">
            Modification des details d'un bakeliste 
          </h4>
        </div>
        <div className="form-row row">
          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Prénom:</label>
              <input
                type="text"
                className="form-control ab1"
                name="first_name"
                value={state.first_name}
                onChange={(e) =>
                  setState({ first_name: e.target.value })
                }
              />

              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Nom de famille:</label>
              <input
                type="last_name"
                className="form-control ab1"
                name="last_name"
                value={state.last_name}
                onChange={(e) =>
                  setState({ last_name: e.target.value })
                }
              />
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Email:</label>
              <input
                type="Email"
                className="form-control ab1"
                name="email"
                value={state.email}
                onChange={(e) => setState({ email: e.target.value })}
              />
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Téléphone:</label>
              <input
                type="text"
                className="form-control ab1"
                name="phone"
                value={state.phone}
                onChange={(e) => setState({ phone: e.target.value })}
              />
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Adresse:</label>
              <input
                type="text"
                className="form-control ab1"
                name="address"
                value={state.address}
                onChange={(e) =>
                  setState({ address: e.target.value })
                }
              />
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">École:</label>
              <input
                type="text"
                className="form-control ab1"
                name="ecole"
                value={state.ecole}
                onChange={(e) => setState({ ecole: e.target.value })}
              />
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Lieu de formation:</label>
              <select
                className="form-control ab1"
                id="inputlieu_formation"
                name="lieu_formation"
                value={state.lieu_formation}
                onChange={(e) =>
                  setState({ lieu_formation: e.target.value })
                }
              >
                <option> --Choisir un lieu-- </option>
                <option value="Sicap Foire">Sicap Foire</option>
                <option value="Grand Yoff 1">Grand Yoff 1</option>
                <option value="Grand Yoff 2">Grand Yoff 2</option>
                <option value="Thiés">Thiés</option>
                <option value="Labé">Labé (Guinée)</option>
              </select>
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Domaine de formation:</label>
              <select
                className="form-control ab1"
                id="bakeliste_domaine_id"
                name="bakeliste_domaine_id"
                value={state.bakeliste_domaine_id}
                onChange={(e) =>
                  setState({ bakeliste_domaine_id: e.target.value })
                }
              >
                <option> --choisir une domaine </option>
                {state.domaines.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
              </select>
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Date de début:</label>
              <input
                type="date"
                className="form-control ab1"
                name="debut_formation"
                value={state.debut_formation}
                onChange={(e) =>
                  setState({ debut_formation: e.target.value })
                }
              />
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group ">
              <label className="taille-txt-elements">Date de fin:</label>
              <input
                type="date"
                className="form-control ab1"
                // id="inputfin_formation"
                name="fin_formation"
                value={state.fin_formation}
                //  onChange={handleChange}
                onChange={(e) =>
                  setState({ fin_formation: e.target.value })
                }
              />
              <div class="line-box">
                <div class="line"></div>
              </div>
            </div>
          </div>
          
        </div>
        <div className="row" >
          <div className="col-md-6">
            {state.editBakelisteInProgress ? (
              <button
                type="submit"
                className="col-md-5 btn-theme text-up m-t-30 w-full p-t-15 p-b-15 offset-md-6"
                disabled
              >
                Modification en cours &nbsp;
                <FeatherIcon icon="loader" aria-hidden="true" />
              </button>
            ) : (
              <button
                type="submit"
                className="col-md-5 btn-theme offset-md-6 text-up m-t-30 w-full p-t-15 p-b-15"
              >
                MODIFIER
              </button>
            )}
          </div>
          <div className="col-md-6">
            <button
                 onClick={(e) => annuler(e)}
                className="col-md-5 btn-warning text-up m-t-30 w-full p-t-15 p-b-15 offset-md-1"
              >
                ANNULER
              </button>
          </div>
        </div>
      </form>
    </div>

    <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
      <div className="row">
        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
          <BakelisteLateItemForAdmin />
        </div>
        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
          <BakelisteMissingItemForAdmin />
        </div>
        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
          <ProgramReunionCoach />
        </div>
        <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
          <SavedReunionListItem />
        </div>
      </div>
    </div>
  </div>
</div>;
};

export default EditBakelisteRefact;
