import React from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addingEvent,
  createEventAsync,
} from "../../redux/slices/eventsSlices/eventSlice";
import "./createEvent.css";

const CreateEventRefac = () => {
  // dispatch
  const dispatch = useDispatch();
  const isAdding = useSelector(addingEvent);

  // Initial States
  const [file, setFile] = React.useState(null);
  const [uploadedFile, setUploadedFile] = React.useState("");
  const [rencontreName, setRencontreName] = React.useState("");
  const [rencontreType, setRencontreType] = React.useState("");
  const [regime, setRegime] = React.useState("");

  const [rencontreDateDebut, setRencontreDateDebut] = React.useState("");
  const [rencontreDateFin, setRencontreDateFin] = React.useState("");
  const [rencontreHeureDebut, setRencontreHeureDebut] = React.useState("");
  const [rencontreHeureFin, setRencontreHeureFin] = React.useState("");
  const [rencontreDescription, setRencontreDescription] = React.useState("");
  const [rencontreOnline, setRencontreOnline] = React.useState(false);
  const [rencontreVisibility, setRencontreVisibility] = React.useState("");
  const [rencontrePlace, setRencontrePlace] = React.useState("");
  const [lienWhatsapp, setLienWhatsapp] = React.useState("");

  const today = new Date(),
  date = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();


//   const today = new Date();
// const month = today.getMonth();
// const date = "";
// if (month <= "9") {
//   date =
//     today.getFullYear() +
//     "-" +
//     "0" +
//     (today.getMonth() + 1) +
//     "-" +
//     today.getDate() +
//     " " +
//     today.getHours() +
//     ":" +
//     today.getMinutes() +
//     ":" +
//     today.getSeconds();
// } else {
//   date =
//     today.getFullYear() +
//     "-" +
//     (today.getMonth() + 1) +
//     "-" +
//     today.getDate() +
//     " " +
//     today.getHours() +
//     ":" +
//     today.getMinutes() +
//     ":" +
//     today.getSeconds();
// }



  // create events
  const addEvent = (e) => {
    e.preventDefault();

    //   Form data
    const fd = new FormData();
    fd.append("rencontre_file", uploadedFile);
    fd.append("rencontre_name", rencontreName);
    fd.append("rencontre_type", rencontreType);
    fd.append("regime", regime);
    fd.append("rencontre_start_date", rencontreDateDebut);
    fd.append("rencontre_end_date", rencontreDateFin);
    fd.append("rencontre_start_time", rencontreHeureDebut);
    fd.append("rencontre_end_time", rencontreHeureFin);
    fd.append("rencontre_description", rencontreDescription);
    fd.append("rencontre_online", rencontreOnline ? 1 : 0);
    fd.append("rencontre_visibility", rencontreVisibility);
    fd.append("rencontre_place", rencontrePlace);
    fd.append("lien_groupe_whatsapp", lienWhatsapp);

    dispatch(createEventAsync(fd));
  };

  // Image files validation
  const fileValidation = (elementID) => {
    var fileInput = document.getElementById(elementID);

    var filePath = fileInput.value;

    // Allowing file type
    var allowedExtensions = /(\.png|\.jpg|\.jpeg|)$/i;

    if (!allowedExtensions.exec(filePath)) {
      fileInput.value = "";
      return false;
    } else {
      return true;
    }
  };

  // handle file image change
  const handlefileChange = (e) => {
    // console.log(e.target.files[0]);
    if (e.target.name === "rencontre_file") {
      if (fileValidation("rencontre_file")) {
        setUploadedFile(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
        // fields["rencontre_file"] = e.target.files[0];
      }
      //   else {
      //     setState({
      //       isInvalidFileExtension: true,
      //     });
      //     setTimeout(() => {
      //       this.setState({
      //         isInvalidFileExtension: false,
      //       });
      //     }, 6000);
      //   }
    }
    // console.log(this.state.rencontre_file);
  };

  return (
    // console.log("date", date, rencontreDateDebut),
    <div className="">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <form
              encType="multipart/form-data"
              className="event-form"
              onSubmit={(e) => addEvent(e)}
            >
              <div className="wrapper_events">
                {/* event header banner */}
                <div
                  className={`event_bannerImg ${
                    file === null ? "event_bannerImgBorder" : ""
                  }`}
                >
                  {file ? (
                    <>
                      <img src={file} alt="" />
                      <span
                        class="imgPreviewSection__Icon"
                        onClick={() => setFile(null)}
                      >
                        <MdEdit size={20} />
                      </span>
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        onChange={(e) => handlefileChange(e)}
                        name="rencontre_file"
                        className="event__bannerFile"
                        id="rencontre_file"
                        accept=".png, .jpg, .jpeg"
                      />
                      <label htmlFor="rencontre_file">Choisir une image</label>
                      {/* <button className="col-md-3 btn-theme"
                      type="file"
                      onClick={(e) => handlefileChange(e)}
                      name="rencontre_file"
                      id="rencontre_file"
                      accept=".png, .jpg, .jpeg">Choisir une image</button> */}

                    </>
                  )}
                </div>
                {/* event body */}
                <div className="event_form_body">
                  <div className="d-flex flex-column mt-5 mb-3">
                    <label htmlFor="rencontre_name">Thème de l'événement</label>
                    <input
                      type="text"
                      onChange={(e) => setRencontreName(e.target.value)}
                      name="rencontre_name"
                      id="rencontre_name"
                      className=""
                    />
                  </div>

                  <div className="d-flex flex-column mb-3">
                    <label htmlFor="rencontre_place">Lieu de l'évenement</label>
                    <input
                      type="text"
                      onChange={(e) => setRencontrePlace(e.target.value)}
                      name="rencontre_place"
                      id="rencontre_place"
                    />
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label htmlFor="lien_groupe_whatsapp">Lien du groupe whatsapp</label>
                    <input
                      type="text"
                      onChange={(e) => setLienWhatsapp(e.target.value)}
                      name="lien_groupe_whatsapp"
                      id="lien_groupe_whatsapp"
                      required
                    />
                  </div>

                  {/* <div className="d-flex align-items-center mb-3">
                    <label htmlFor="">
                      S’agit t-il d’un évènement en ligne ?
                    </label>
                    <label className="switch mx-2">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setRencontreOnline((prevState) => !rencontreOnline)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div> */}

                  <div className="d-flex flex-column mb-3">
                    <label className="" htmlFor="regime">
                      L'évènement sera en ligne ou en présentiel?
                    </label>
                      <select
                        name="regime"
                        className="sselect"
                        id="regime"
                        value={regime}
                        onChange={(e) => setRegime(e.target.value)}
                        required
                      >
                        <option>
                          {" "}
                          *Type --Choisir un type --{" "}
                        </option>
                        <option value="en_ligne"> En ligne </option>
                        <option value="en_présentiel"> En présentiel </option>
                        <option value="en_ligne_presentiel"> En présentiel et en ligne </option>
                      </select>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-3 my-sm-2">
                      <label className="" htmlFor="rencontre_start_date">
                        Date début <span className="requiredElement">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setRencontreDateDebut(e.target.value)}
                        name="rencontre_start_date"
                        id="rencontre_start_date"
                        min={date}
                      />
                    </div> 

                    <div className="col-md-3 my-sm-2">
                      <label className="" htmlFor="rencontre_start_time">
                        Heure début <span className="requiredElement">*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        onChange={(e) => setRencontreHeureDebut(e.target.value)}
                        name="rencontre_start_time"
                        id="rencontre_start_time"
                        min="09:00"
                        max="18:00"
                      />
                    </div>

                    <div className="col-md-3 my-sm-2">
                      <label className="" htmlFor="rencontre_end_date">
                        Date fin <span className="requiredElement">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        onChange={(e) => setRencontreDateFin(e.target.value)}
                        name="rencontre_end_date"
                        id="rencontre_end_date"
                        min="09:00"
                        max="18:00"
                      />
                    </div>

                    <div className="col-md-3 my-sm-2">
                      <label className="" htmlFor="rencontre_end_time">
                        Heure fin <span className="requiredElement">*</span>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        onChange={(e) => setRencontreHeureFin(e.target.value)}
                        name="rencontre_end_time"
                        id="rencontre_end_time"
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-column mb-3">
                    <label className="" htmlFor="">
                      Description <span className="requiredElement">*</span>
                    </label>
                    <span>
                      Ajouter des détails tels que des sujets, programmes ...
                    </span>
                    <textarea
                      name="rencontre_description"
                      id="rencontre_description"
                      onChange={(e) => setRencontreDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="d-flex flex-column mb-3">
                    <label className="" htmlFor="rencontre_type">
                      Type d'événement
                    </label>
                      <select
                        name="rencontre_type"
                        className="sselect"
                        id="rencontre_type"
                        value={rencontreType}
                        onChange={(e) => setRencontreType(e.target.value)}
                        required
                      >
                        <option>
                          {" "}
                          *Type --Choisir un type --{" "}
                        </option>
                        <option value="workshop"> Workshop </option>
                        <option value="jeemaCoder"> JeemaCoder </option>
                        <option value="hackaton"> Hackaton </option>
                      </select>
                  </div>

                  <div className="d-flex flex-column mb-3">
                    <label className="" htmlFor="">
                      Visibilité de l’évènement
                    </label>
                    <span>
                      La visibilité de l’évènement ne peut plus être modifiée
                      une fois l’évènement crée. En savoir plus
                    </span>

                    <div className="d-flex flex-column mb-2">
                      <span className="d-flex">
                        <input
                          type="radio"
                          id="evenement_public"
                          name="rencontre_visibility"
                          value="public"
                          onChange={(e) =>
                            setRencontreVisibility(e.target.value)
                          }
                        />
                        <label htmlFor="evenement_public" className="mx-2">
                          Événement public
                        </label>
                      </span>
                      <span>
                        Visible de tout le monde sur facebook. N’importe qui
                        peut décidé de participer.
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <span className="d-flex">
                        <input
                          type="radio"
                          id="evenement_privé"
                          name="rencontre_visibility"
                          value="privé"
                          onChange={(e) =>
                            setRencontreVisibility(e.target.value)
                          }
                        />
                        <label htmlFor="evenement_privé" className="mx-2">
                          Événement privée
                        </label>
                      </span>
                      <span>
                        Visible des invités et de toute autre personne ayant le
                        lien de l’événement. Les personnes qui ne sont pas
                        invitées doivent obtenir votre approbation pour
                        participer.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row events_footerSection">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 my-1">
                  <Link to="/evenements">
                    <button
                      className="btn event_btn event_btn_cancel form-control"
                      type="button"
                    >
                      Annuler
                    </button>
                  </Link>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 my-1">
                  <button
                    className="btn event_btn event_btn_sent form-control"
                    type="submit"
                    disabled={isAdding ? true : false}
                  >
                    {isAdding ? "Création en cours..." : "Créer "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEventRefac;
