import React, {Component} from 'react';
import './groupeCoachView.css';
import HeaderDashboardCoach from '../headerDashboardCoach/index';
import NavVerticalCoach from '../navVerticalCoach/index';
import GroupeCoach from '../groupeCoach/index';

export default class groupeCoachView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="body-theme">
                <HeaderDashboardCoach />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalCoach />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <GroupeCoach />
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ groupeCoachView }) => ({ ...groupeCoachView }),
//     dispatch => bindActionCreators({ ...groupeCoachViewActions }, dispatch)
//   )( groupeCoachView );