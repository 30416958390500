import React, {Component} from 'react';
import './groupeCoach.css';
// import { NavLink } from 'react-router-dom';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
// import IconGroupWhatsapp from '../../img/icons/group-whatsapp.png';
// import TableGroupeBakeliste from '../tableGroupeBakeliste/index';
import IconGroup from '../../img/icons/people.png';

export default class groupeCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row m-b-15">
                      <div className="col m-b-15">
                        <h6 className="h-theme ff-inter fw-600">Groupe</h6>
                      </div>
                      <div className="col dis-flex m-b-15">
                        
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4 col-sm-4 pr-sm-2 mb-3 dis-flex">
                        <div className="item-statistics-dashboard">
                          <p className="groupe-titre-on-coach-dashboard">Groupe 1</p>
                          <p className="designation-item-statistics-dashboard">24 membres</p>
                          <img className="img-item-statistics-dashboard" src={IconGroup} alt="" />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 pr-sm-2 pl-sm-2 mb-3 dis-flex">
                        <div className="item-statistics-dashboard">
                          <p className="groupe-titre-on-coach-dashboard">Groupe 2</p>
                          <p className="designation-item-statistics-dashboard">7 membres</p>
                          <img className="img-item-statistics-dashboard" src={IconGroup} alt="" />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4 pl-sm-2 mb-3 dis-flex">
                        <div className="item-statistics-dashboard">
                          <p className="groupe-titre-on-coach-dashboard">Groupe 3</p>
                          <p className="designation-item-statistics-dashboard">19 membres</p>
                          <img className="img-item-statistics-dashboard" src={IconGroup} alt="" />
                        </div>
                      </div>
                    </div>
                    {/* <div className="card-dashboard m-b-15 p-b-60 p-sm-4">
                      <div className="row m-b-30">
                        <div className="col-xl-7 col-lg-6 col-md-7 col-sm-6 col-6 m-b-15 dis-flex itm-center">
                          <h5 className="h-theme ff-inter fw-500">Groupe 12</h5>
                        </div>
                        <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15">
                          <NavLink className="link-groupe-whatsapp no-view-mobile" to="">
                            <img className="icon-link-groupe-whatsapp" src={IconGroupWhatsapp} alt="Groupe whatsapp" />
                            Groupe de discussion
                          </NavLink>
                          <NavLink className="link-groupe-whatsapp-mobile no-display-tablette ml-auto" to="">
                            <img className="icon-link-groupe-whatsapp" src={IconGroupWhatsapp} alt="Groupe whatsapp" />
                          </NavLink>
                        </div>
                      </div>

                      <TableGroupeBakeliste />
                    </div> */}
                  </div>

                  <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteGroupItemForCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                        <ProgramReunionCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <SavedReunionListItem />
                      </div>
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ groupeCoach }) => ({ ...groupeCoach }),
//     dispatch => bindActionCreators({ ...groupeCoachActions }, dispatch)
//   )( groupeCoach );