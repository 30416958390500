import React, {Component} from 'react';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import AjouterBakelisteRefac from '../ajouterBakeliste/AjouterBakelisteRefac'
import AjouterBakeliste from '../ajouterBakeliste/index';

export default class ajouterbakelisteView extends Component {
    constructor(props) {
        super(props);
      
            this.state = { 
            
            };
    }
    render() {
      return (
          <div className="component-profil-visiteur-view body-theme">
              <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      {/* <AjouterBakeliste /> */}
                      <AjouterBakelisteRefac />
                    </div>
                  </div>
                </div>
          </div>
      );
    }
  }