import React, {Component} from 'react';
import './tableAllListePresence.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Bureautique from '../../img/bureautique.png';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableAllListePresenceActions from "../../store/tableAllListePresence/actions";


var gestionTacheForCoach = [{
  image: "../../img/bureautique.png",
  fullname: "Ngone Diop",
  telephone: "+221 77 000 00 00",
  date: "22/02/2020"
},{
  image: "../../img/bureautique.png",
  fullname: "Aita Mbaye",
  telephone: "+221 77 000 00 00",
  date: "22/02/2020"
},{
  image: "../img/bureautique.png",
  fullname: "Dieyanaba Ly",
  telephone: "+221 77 000 00 00",
  date: "22/02/2020"
},{
  image: "../../img/bureautique.png",
  fullname: "Adama Diakhaté",
  telephone: "+221 77 000 00 00",
  date: "22/02/2020"
},{
  image: "../../img/bureautique.png",
  fullname: "Fatma Dieng",
  telephone: "+221 77 000 00 00",
  date: "22/02/2020"
},{
  image: "../../img/bureautique.png",
  fullname: "Aisha Nd Keita",
  telephone: "+221 77 000 00 00",
  date: "22/02/2020"
},{
  image: "../../img/bureautique.png",
  fullname: "Lucresse",
  telephone: "+221 77 000 00 00",
  date: "22/02/2020"
},
];



export default class tableAllListePresence extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    ImageFormatter = (cell, row) =>{
      return (
        <div>
          <img style={{width:44, height: 42, borderRadius:50}} src={Bureautique}/>
        </div>
        );
    }

    render() {
      return (
          <div className="component-table-all-liste-presence">
                <BootstrapTable 
                  pagination
                  data={gestionTacheForCoach} 
                  striped 
                  version='4' 
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                  >
                    <TableHeaderColumn 
                      dataFormat={this.ImageFormatter}
                      thStyle={{ 'border': 'none' }} 
                      tdStyle={{ 'border': 'none'}} 

                      dataField='image'
                      width="80" 
                     >

                    </TableHeaderColumn >
                  <TableHeaderColumn 
                    isKey 
                    
                    dataField='fullname' 
                    filter={{type:"TextFilter", delay: 1000}} 
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs">Nom Complet</span> 
                    </TableHeaderColumn>
                  <TableHeaderColumn 
                    dataField='telephone' width="200"
                    filter={{type:"TextFilter", delay: 1000}} 
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                        <span className="color-nom-prenom-utilisateurs">Telephone</span>
                    </TableHeaderColumn>   
                  <TableHeaderColumn 
                  dataField='date' 
                  filter={{type:"TextFilter", delay: 1000}}        
                  thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} 
                  tdStyle={{ 'border': 'none', 'display': 'flex', 'justifyContent': 'center', 'padding': '1.2rem','font-size': '14px','font-family': 'Montserrat' }}>
                   <span className="color-nom-prenom-utilisateurs">Date</span>
                    </TableHeaderColumn>
                </BootstrapTable>

            </div>
        );
    }
  }
// export default connect(
//     ({ tableAllListePresence }) => ({ ...tableAllListePresence }),
//     dispatch => bindActionCreators({ ...tableAllListePresenceActions }, dispatch)
//   )( tableAllListePresence );