import React, {Component} from 'react';
import './visiteursAdminView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import VisiteursAdmin from '../visiteursAdmin/index';
export default class visiteursAdminView extends Component {
   
    render() {
      return (
          <div className="component-create-event-view body-theme">
            <HeaderDashboardAdmin />
            <div className="container-fluid fixing-after-header">
              <div className="row">
                <div className="col-lg-2 p-0">
                  <NavVerticalAdmin />
                </div>
                <div className="col-lg-10 pt-3">
                  <VisiteursAdmin />
                </div>
              </div>
            </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ visiteursAdminView }) => ({ ...visiteursAdminView }),
//     dispatch => bindActionCreators({ ...visiteursAdminViewActions }, dispatch)
//   )( visiteursAdminView );