import React, { Component } from "react";
import "./ressetPassword.css";
import axios from "axios";
import API from "../../variablesGlobales";
import Logo from "../../img/logo.png";
import FeatherIcon from "feather-icons-react";
import amplitude from "amplitude-js";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as ressetPasswordActions from "../../store/ressetPassword/actions";
export default class ressetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.sendingEmailForResetPassword = this.sendingEmailForResetPassword.bind(
      this
    );
    this.state = {
      api: API.API,
      showForm: true,
      fields: {},
      isPending: false,
      requestError: false,
    };
  }

  handleChange = (e) => {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });

    // console.log(fields);
  };

  sendingEmailForResetPassword = (e) => {
    e.preventDefault();

    this.setState({
      isPending: true,
    });

    let emailData = this.state.fields;

    axios
      .post(this.state.api + "send-reset-password", emailData)
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            isPending: false,
            showForm: false,
          });
        } else {
          this.setState({
            isPending: false,
            requestError: true,
          });
          // console.log(response);
        }
      });
  };
  render() {
    return (
      <Amplitude>
        <div className="container-fluid">
          <LogOnMount eventType="visite page d'accueil" />
          <div className="row">
            <div className="col-md-6 banner-connexion-page dis-flex justify-content-center itm-center py-5">
              <div className="col-xl-9 col-lg-10 col-md-11 col-sm-8 text-center">
                <h1 className="titre-banner-connexion">Bakeli SI</h1>
                <p className="slogan-banner-connexion">
                  Training the best tech talent in Africa.
                </p>
              </div>
            </div>
            <div className="col-md-6 bg-white dis-flex justify-content-center itm-center py-md-0 py-5">
              <div className="col-xl-7 col-lg-9 col-md-11 col-sm-8 dis-flex flex-col">
                <img className="logo-connexion m-l-r-auto" src={Logo} alt="" />
                <p className="h-connexion-form">MODIFIER VOTRE MOT DE PASSE</p>
                {this.state.showForm ? (
                  <div className="reset-form-container">
                    <form
                      className="positionForm"
                      onSubmit={this.sendingEmailForResetPassword}
                    >
                       <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                      >
                        <FeatherIcon
                          className="icon-form-connexion"
                          icon="mail"
                        />
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control input-form-connexion"
                      id="email"
                      placeholder="Entre votre email"
                      name="email"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  {this.state.email_empty && (
                    <div className="errorMsg">{this.state.errors.email}</div>
                  )}
                      <div className="form-group">
                        {!this.state.isPending && (
                          <button className="btn-connexion">
                             Envoyer
                          </button>
                           
                        )}
                        {this.state.isPending && (
                          <button className="btn-connexion">
                            Envoie en cours &nbsp;
                            <i class="fas fa-spinner fa-spin"></i>
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="success-reset-form-container">
                    <div className="alert alert-success success-message">
                      <p>
                        Nous venons de vous envoyer un email dans lequel vous
                        trouverez le lien qui vous permettra de modifier votre
                        mot de passe.
                      </p>
                      <p>
                        Si vous ne voyez pas le message dans votre boîte de
                        réception, nous vous suggérons de consulter vos spams
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
