import React, { Component } from 'react';
import './syllabusFiliéreDisign.css';
import ImageSyllabus from '../../img/syllabus-Disign.png';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ShareTo from '../shareTo/index';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

export default class syllabusFiliéreDisign extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="container container-syllabus-item-filiéredisign">
    <div className="card-design">
      <div className="card-img-overlay-design">
      <p className="titre-card-filiéredisign">Disign</p>
      <ul>
        <li>Design d’interface utilisateur</li>
        <li>Design d’expérience utilisateurs</li>
        <li>Design graqhique</li>
        <li>Montage vidéo</li>
      </ul>
    </div>
    </div>
  </div>

  }
}
