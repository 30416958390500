import React, {Component} from 'react';
import './accountCoachView.css';
import HeaderDashboardCoach from '../headerDashboardCoach/index';
import NavVerticalCoach from '../navVerticalCoach/index';
import AccountCoach from '../accountCoach/index';

export default class accountCoachView extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    return  <div className="body-theme">
              <HeaderDashboardCoach />
              <div className="container-fluid fixing-after-header">
                <div className="row">
                  <div className="col-lg-2 p-0">
                    <NavVerticalCoach />
                  </div>
                  <div className="col-lg-10 pt-3">
                    <AccountCoach/>
                  </div>
                </div>
              </div>
            </div>;
  }
}
