import React, {Component} from 'react';
import './addChiffres.css';
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from 'rsuite';
import FeatherIcon from 'feather-icons-react';
import data from '../dataTesting.json';


export default class addChiffres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  render() {
    return  <div className="modal-container ml-md-auto">
              <button className="btn-add-with-icon-plus-circle" 
              onClick={this.open}>
                Ajouter
                <Icon className="icon-add ml-2" icon="plus-circle" />
              </button>

              <Modal className="modal-responsive-sm p-b-50" show={this.state.show} onHide={this.close}>
                <form>
                  <h5 className="modal-bakeliste-title m-b-50">Créer un chiffre</h5>
                  <button className="close-modal-bakeliste-title" onClick={this.close}>
                    <FeatherIcon icon="x" aria-hidden="true" />
                  </button>
                  <Modal.Body className="max-height-modal pb-0">
                    <div className="form-group form-group-add-syllabus">
                      <label for="nomSyllabus" className="label-add-syllabus">Nom du chiffre</label>
                      <input type="text" className="form-control input-add-syllabus" id="nomSyllabus" placeholder="Nom du chiffre" />
                    </div>
                    <div className="form-group form-group-add-syllabus">
                      <label for="niveauSyllabus" className="label-add-syllabus">Type de chiffre</label><br/>
                      <RadioGroup name="radioList" inline>
                        <Radio value="A">Utilisateurs</Radio>
                        <Radio value="B">Lieux</Radio>
                        <Radio value="C">Diplôme</Radio>
                        <Radio value="C">Evenement</Radio>
                      </RadioGroup>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="m-t-40 dis-flex justify-content-center">
                      <div className="col-sm-5">
                        <button onClick={this.close} className="btn-theme w-full fs-13">Créer</button>
                      </div>
                    </div>
                  </Modal.Footer>
                </form>
              </Modal>
            </div>;
  }
}
// export default connect(
//     ({ addSyllabus }) => ({ ...addSyllabus }),
//     dispatch => bindActionCreators({ ...addSyllabusActions }, dispatch)
//   )( addSyllabus );