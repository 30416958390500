import React, { Component } from 'react';
import './syllabusFiliéreGestiondeprojet.css';
import ImageSyllabus from '../../img/syllabus-Gestiondeprojet.png';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ShareTo from '../shareTo/index';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

export default class syllabusFiliéreGestiondeprojet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="container container-syllabus-item-filiéregestionprojet">
    <div className="card-gestion-projet">
      <div className="card-img-overlay-gestion-projet">
        <p className="titre-card-filiéregestionprojet">Gestion de projet</p>
        <ul>
          <li>Scrum Master</li>
          <li>Product Owner</li>
          <li>Tester Logiciel</li>
          <li>Chef de projet</li>
        </ul>
      </div>
    </div>
  </div>

  }
}
