import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  eventDetails,
  getEventDetailsAsync,
} from "../../redux/slices/eventsSlices/eventSlice";
import { base_api_url } from "../../variablesGlobales";
import "./detailsEvent.css";

const EventParticipantsTable = (props) => {
  const { participantsData, eventName , type, regime} = props;

  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <div className="mt-3">
      {type === "jeemaCoder" ? (
          <BootstrapTable
          data={participantsData}
          striped={true}
          hover={true}
          search
          exportCSV
          csvFileName="listes-participants-jeemacoder.csv"
          searchPlaceholder="Rechercher participant..."
          multiColumnSearch
          multiColumnSort={2}
          pagination
          bordered={true}
        >
          <TableHeaderColumn isKey dataField="id" hidden>
            id
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="first_name"
            filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="150"
            dataFormat={nameFormatter}
          >
                <span className="color-title-table">Noms</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="email"
            filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="150"
          >
                <span className="color-title-table">Email</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="phone"
            filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="100"
          >
                <span className="color-title-table">Téléphone</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="address"
            filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="100"
          >
                <span className="color-title-table">Adresse</span>
          </TableHeaderColumn>
          
          <TableHeaderColumn
            dataField="rencontre_user_tools"
            filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="110"
          >
                <span className="color-title-table">Outils</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="rencontre_place"
            filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="100"
          >
                <span className="color-title-table">Région</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="rencontre_online"
            filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="100"
          >
                <span className="color-title-table">Régime</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="rencontre_user_disponibility"
            filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="50"
          >
                <span className="color-title-table">Disponibilité</span>
          </TableHeaderColumn>
        </BootstrapTable>

      ) : ( regime === "en_ligne_presentiel" ? (





      <BootstrapTable
        data={participantsData}
        striped={true}
        hover={true}
        search
        exportCSV
        csvFileName="listes-participants-rencontre.csv"
        searchPlaceholder="Rechercher participant..."
        multiColumnSearch
        multiColumnSort={2}
        pagination
        bordered={true}
      >
        <TableHeaderColumn isKey dataField="id" hidden>
          id
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="first_name"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
          dataFormat={nameFormatter}
        >
              <span className="color-title-table">Noms</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="email"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Email</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="regime"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Présence</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="phone"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Téléphone</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="address"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Adresse</span>
        </TableHeaderColumn>
      </BootstrapTable>

      ):(
        <BootstrapTable
        data={participantsData}
        striped={true}
        hover={true}
        search
        exportCSV
        csvFileName="listes-participants-rencontre.csv"
        searchPlaceholder="Rechercher participant..."
        multiColumnSearch
        multiColumnSort={2}
        pagination
        bordered={true}
      >
        <TableHeaderColumn isKey dataField="id" hidden>
          id
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="first_name"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
          dataFormat={nameFormatter}
        >
              <span className="color-title-table">Noms</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="email"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="200"
        >
              <span className="color-title-table">Email</span>
        </TableHeaderColumn>
        

        <TableHeaderColumn
          dataField="phone"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Téléphone</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="address"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Adresse</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="domaine"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Domaine</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="rencontre_user_disponibility"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Disponibilité</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="canal_infos"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="100"
        >
              <span className="color-title-table">Canal Infos</span>
        </TableHeaderColumn>
      </BootstrapTable>
      )
     
)

}

{/* {regime === "en_ligne_presentiel" ? (
    <>

        <BootstrapTable
        data={participantsData}
        striped={true}
        hover={true}
        search
        exportCSV
        csvFileName="listes-participants-rencontre.csv"
        searchPlaceholder="Rechercher participant..."
        multiColumnSearch
        multiColumnSort={2}
        pagination
        bordered={true}
      >
        <TableHeaderColumn isKey dataField="id" hidden>
          id
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="first_name"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
          dataFormat={nameFormatter}
        >
              <span className="color-title-table">Noms</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="email"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Email</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="regime"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Présence</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="phone"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="150"
        >
              <span className="color-title-table">Téléphone</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="address"
          filter={{ type: "TextFilter", delay: 1000 , placeholder: "Recherche  "}}
          tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
          width="100"
        >
              <span className="color-title-table">Adresse</span>
        </TableHeaderColumn>
      </BootstrapTable>
    </>
    ):(
      <>
       </>
    )} */}

    </div>
  );
};

const DetailsEvent = () => {
  // dispatch
  const dispatch = useDispatch();
  const eventDetailsData = useSelector(eventDetails);

  let { id } = useParams();

  React.useEffect(() => {
    dispatch(getEventDetailsAsync(id));
  }, []);

  return (
    <div className="eventDetailsWrapper">
      <div className="containe-fluid px-0">
        <div className="col-lg-12 col-md-12">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to="/dashboard-admin" className="lien_nav">
                  Dashboard{" "}
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/evenements" className="lien_nav">
                  Événements
                </Link>
              </li>
              <li
                className="lien_nav_current breadcrumb-item"
                aria-current="page"
              >
                Détails événement
              </li>
            </ol>
          </nav>

          <div className="row eventDetailsHead">
            <h6>Détails de l'évenement</h6>
            <button className="btn ">Modifier cet évenement</button>
          </div>

          <div className="eventDetailsContainer">
            <div className="eventDetailsBanner">
              <img
                src={`${base_api_url}/uploads/rencontre_files/${eventDetailsData?.rencontre_file}`}
                alt=""
              />
            </div>

            <div className="eventDetailsHeader">
              <p className="eventdetailsType">
                <span>{eventDetailsData?.rencontre_type}</span>
                {` sur ${eventDetailsData?.rencontre_name}`}
              </p>

              {/* <p className="eventDetailsInfos infosCount">
                {`Nombre de places : `} &nbsp;
                <span>{eventDetailsData?.participants?.length}</span>
              </p> */}

              {/* <p className="eventDetailsInfos">
                {`Intervenants : `} &nbsp;
                <span>{eventDetailsData?.participants?.length}</span>
              </p> */}
            </div>

            <div className="eventDetailsBody mt-3">
              {/* <h5>{eventDetailsData?.rencontre_name}</h5> */}

              <p className="eventDetailsInfos infosLien mt-4"> {`Description : `} &nbsp;<span>{eventDetailsData?.rencontre_description}</span></p>

              {/* <p className="eventDetailsInfos">
                {`Intervenants : `} &nbsp;
                <span>{`https://si-admin-recette.bakeli.tech/join-event/${eventDetailsData?.id}`}</span>
              </p> */}
              <p className="eventDetailsInfos infosLien mt-4">
                {`Lien de participation : `} &nbsp; 
                <span>{`https://network.bakeli.tech/inscription-rencontre/${eventDetailsData?.id}`}</span>
              </p>

              <h5 className="eventdetailsProgramme mt-4">Liste des participants</h5>
               {/* {eventDetailsData?.participants.length > 0 ? (  */}
                  <EventParticipantsTable
                  participantsData={eventDetailsData?.participants}
                  eventName={eventDetailsData?.rencontre_name}
                  type={eventDetailsData?.rencontre_type}
                  regime={eventDetailsData?.regime}


                />
               {/* ):(
<>

            <p className="listeP mt-3">
              Il n y a pas encore de participants pour cette évenement
            </p>
</>
              )} */}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsEvent;
