import React, {Component} from 'react';
import './livraisonCoach.css';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import ItemFilActualite from '../itemFilActualite/index';

export default class livraisonCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="p-b-200">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col m-b-10">
                        <h6 className="h-theme ff-inter fw-600">Livrables</h6>
                      </div>
                      <div className="col dis-flex m-b-15">
                        
                      </div>
                    </div>
                    

                    <div className="row">
                      <div className="col-md-3 m-t-35 mb-md-0 mb-5">
                        <div className="content-nav-fil-actualite-livraison">
                          <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link nav-fil-actualite-livraison active" id="v-pills-all-tab" data-toggle="pill" href="#v-pills-all" role="tab" aria-controls="v-pills-all" aria-selected="true">Tous les livrables</a>
                            <a className="nav-link nav-fil-actualite-livraison" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</a>
                            <a className="nav-link nav-fil-actualite-livraison" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Messages</a>
                            <a className="nav-link nav-fil-actualite-livraison" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 px-xl-3 px-lg-2 m-b-50 pl-md-0 pr-md-3">
                        <div className="content-fil-actualite">
                          <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-all" role="tabpanel" aria-labelledby="v-pills-all-tab">
                              
                              <div className="dis-flex justify-content-center">
                                <p className="date-livraison-fil-d-actualite">Lun 20/12/2020</p>
                              </div>
                              <ItemFilActualite />
                              <ItemFilActualite />
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">...</div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">...</div>
                            <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteGroupItemForCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                        <ProgramReunionCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <SavedReunionListItem />
                      </div>
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ livraisonCoach }) => ({ ...livraisonCoach }),
//     dispatch => bindActionCreators({ ...livraisonCoachActions }, dispatch)
//   )( livraisonCoach );