import React from "react";
import DashboardAdmin from "../dashboardAdmin/index";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import DashboardAdminRefac from "./DashboardAdminRefac";

import "./dashboardAdminView.css";
import DashboardNewAdmin from "./DashboardNewAdmin";

const DashboardAdminViewRefac = () => {
  return (
    <div className="body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            {/* <DashboardAdmin /> */}
            {/* <DashboardAdminRefac /> */}
            <DashboardNewAdmin />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdminViewRefac;
