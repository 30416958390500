import * as React from "react";
import "./tableMyListCoachs.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import API from "../../variablesGlobales";
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from "rsuite";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import FeatherIcon from "feather-icons-react";

import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import $ from "jquery";
import SkeletonAllBakeliste from "../tableMyAllbakelistes/skeletonAllBakeliste";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  archiveCoach,
  getAllCoachsAsync,
  isFetching,
  isLoading,
  showListCoachs,
} from "../../redux/slices/coachsSlices/coachsSlice";
toast.configure();

const TableMyListCoachsRefac = () => {
  // Redux
  const dispatch = useDispatch();

  const isFetchingReduxData = useSelector(isLoading);
  const coachReduxData = useSelector(showListCoachs);

  const [state, setState] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      coachs: [], 
      domaines: [],
      fields: {},
      upFields: {},
      coachsID: 0,
      coachFullname: "",
      coachEmail: "",
      coachDomaine: "",
      coachEcole: "",
      coachPhone: "",
      coachDebutFormation: "",
      coachFinFormation: "",
      coachcoachFullname: "",
      emailValue: "",
      phoneValue: "",
      boursierValue: false,
      demiBoursierValue: false,
      adresseValue: "",
      ecoleValue: "",
      stageFormationValue: false,
      isISAContractValue: false,
      lieuFormationValue: "",
      horaireValue: "",
      paiementInscriptionValue: false,
      debutFormationValue: "",
      finFormationValue: "",
      firstNameValue: "",
      lastNameValue: "",
      loading: true,
      isLoaded: true,
      updatedCoachStatusInProgress: false,
      updatedError: false,
      updateFields: {},
      updateCoach: [],
      is_archived: 0,
      updateCoachInfosInProgress: false,
      updateCoachInfosSuccessAlert: false,
      updateCoachInfosRequestError: false,
      show: false,
    }
  );

  React.useEffect(() => {
    coachReduxData.length === 0 &&
      dispatch(getAllCoachsAsync()) &&
      dispatch(isFetching());
    // getListAllCoachs();
    getAllDomaines();
  }, []);

  const getCoachInfos = (coach) => {
    setState({
      // show: true,
      coachID: coach.id,
      // coachFullname: coach.coachFullname,
      // coachEmail: coach.email,
      // coachDomaine: coach.domaine,
      // coachDomaineID: coach.coach_domaine_id,
      // coachPhone: coach.phone,
      // coachStatus: coach.coach_is_actif,
      // coachNbreBakelistes: coach.nbreBakeliste,
      // coachFirstname: coach.first_name,
      // coachLastname: coach.last_name,
      // updateCoach: coach,
    });
  };

  const archiveCoachRedux = (id) => {
    dispatch(archiveCoach(id));
  };

  const actionFormatter = (cell, row) => {
    return (
      <span>
        &nbsp;&nbsp;
        <Tooltip title="Modifier">
          <FeatherIcon
            icon="edit-2"
            style={{
              color: "#469789",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            onClick={() => {
              getCoachInfos(row);
              open();
            }}
          />
        </Tooltip>
        &nbsp;&nbsp;
        <Tooltip title="Archivez coach">
          <FeatherIcon
            icon="archive"
            id="delete_group"
            style={{
              color: "red",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            onClick={() => {
              archiveCoachRedux(row.id);
            }}
            className="ajouter"
            cursor="pointer"
          />
        </Tooltip>
      </span>
    );
  };

  const nbrBakeliste = (cell, row) => {
    return (
      <span className="coach-color">{row.nbreBakeliste.length}</span>
    );
  };
  const coachAd = (Column, row) => {
    return (
      <div className="la-taille-div">
        {row.status === "admin" ? (
          <span className="admin-color">Admin</span>
        ) : (
          <span className="coach-color">Coach</span>
        )}
      </div>
    );
  };

  const numeroCoach = (Column, row) => {
    return (
      <div className="la-taille-div">
        {row.statut === "coachs" ? (
          <span className="admin-color">5</span>
        ) : (
          <span className="coach-color">2</span>
        )}
      </div>
    );
  };
  const customExportCsvBtn = (onClick) => {
    return (
      <ExportCSVButton
        style={{
          background: "#FF9800",
          border: "none",
          height: "42px",
          width: "150px",
          left: "20px",
        }}
        btnText="Export to csv"
      />
    );
  };

  const handleShowAndHideAlert = (label) => {
    setTimeout(() => {
      if (label === "coachUpdateSuccess") {
        setState({
          updateCoachInfosSuccessAlert: false,
        });
      }
      if (label === "coachUpdateError") {
        setState({
          updateCoachInfosRequestError: false,
        });
      }
    }, 5000);
  };
  const updatedCoachData = (e) => {
    e.preventDefault();
    // console.log(state.updateFields)
    setState({
      updateCoachInfosInProgress: true,
    });
    let idCoach = state.coachID;
    let data = state.updateFields;
    axios
      .put(API.API + "update-coach-infos/" + idCoach, data)
      .then((response) => {
        // console.log(response)
        if (response.data.success === true) {
          //   getListAllCoachs();
          handleShowAndHideAlert("coachUpdateSuccess");
          close();
          // $(".close").click();
          setState({
            updateCoachInfosInProgress: false,
            updateCoachInfosSuccessAlert: true,
            updateFields: {},
          });
        } else {
          // console.log(response)
          setState({
            updateCoachInfosInProgress: false,
            updateCoachInfosRequestError: true,
          });
          handleShowAndHideAlert("coachUpdateError");
        }
      })
      .catch((error) => {
        console.log(error);
        setState({
          updateCoachInfosInProgress: false,
          updateCoachInfosRequestError: true,
        });
        handleShowAndHideAlert("coachUpdateError");
      });
  };
  const close = () => {
    setState({ show: false });
  };
  const open = () => {
    setState({ show: true });
  };
  const updateHandleChange = (e) => {
    let fields = state.updateFields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === "first_name") {
      setState({
        coachFirstname: e.target.value,
      });
    }
    if (e.target.name === "last_name") {
      setState({
        coachLastname: e.target.value,
      });
    }
    if (e.target.name === "email") {
      setState({
        coachEmail: e.target.value,
      });
    }
    if (e.target.name === "phone") {
      setState({
        coachPhone: e.target.value,
      });
    }
    if (e.target.name === "coach_domaine_id") {
      setState({
        coachDomaineID: e.target.value,
      });
    }

    if (!fields["first_name"]) {
      fields["first_name"] = state.updateCoach.first_name;
    }
    if (!fields["last_name"]) {
      fields["last_name"] = state.updateCoach.last_name;
    }
    if (!fields["email"]) {
      fields["email"] = state.updateCoach.email;
    }
    if (!fields["phone"]) {
      fields["phone"] = state.updateCoach.phone;
    }
    if (!fields["coach_domaine_id"]) {
      fields["coach_domaine_id"] = state.updateCoach.coach_domaine_id;
    }

    setState({
      updateFields: fields,
    });

    // console.log(fields)
  };

  const getAllDomaines = () => {
    axios
      .get(API.API + "domaines")
      .then((res) => {
        const domains = res.data;
        setState({
          domaines: domains.data,
        });
        // console.log(state.domaines)
      })
      .catch((error) => {
        //console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };

  const options = {
    exportCSVBtn: customExportCsvBtn,
  };

  return (
    console.log(coachReduxData),
    <div className="component-table-my-list-coachs">
      {isFetchingReduxData && <SkeletonAllBakeliste />}
      <div className="col-md-2 ml-auto mb-2">
        <NavLink
          className="nav-link btn-theme right-elements"
          to="/ajout-coach"
        >
          Ajouter
          <Icon className="ml-2" icon="plus-circle" />
        </NavLink>
      </div>
      <BootstrapTable
        data={coachReduxData}
        striped={true}
        hover={true}
        options={options}
        search
        searchPlaceholder="Rechercher coach..."
        multiColumnSearch
        exportCSV
        csvFileName="listes-coachs.csv"
        multiColumnSort={2}
        pagination
        bordered={true}
        bodyStyle={{
          fontSize: 12,
          color: "rgba(0, 0, 0, 0.7)",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        tdStyle={{ background: "red" }}
        tableHeaderclassName="header-table-groupe-view"
        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
        className="table-responsive table-responsive-sm"
      >
        <TableHeaderColumn
          className="titre-tab"
          isKey
          filter={{
            type: "TextFilter", 
            delay: 1000,
            placeholder: "Recherche  ",
          }}
          dataField="coachFullname"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
          width="250"
        >
          <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="domaine"
          className="titre-tab"
          filter={{
            type: "TextFilter",
            delay: 1000,
            placeholder: "Recherche  ",
          }}
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Domaine</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="email"
          className="titre-tab"
          filter={{
            type: "TextFilter",
            delay: 1000,
            placeholder: "Recherche  ",
          }}
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Email</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          // dataField="nbreBakeliste"
          dataFormat={nbrBakeliste}
          className="titre-tab"
          filter={{
            type: "TextFilter",
            delay: 1000,
            placeholder: "Recherche  ",
          }}
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">
            Nbr Bakelistes
          </span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="phone"
          className="titre-tab"
          filter={{
            type: "TextFilter",
            delay: 1000,
            placeholder: "Recherche  ",
          }}
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Numéro</span>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="action"
          dataFormat={actionFormatter}
          className="titre-tab"
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            display: "flex",
            justifyContent: "center",
            padding: "1.2rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs"> Actions</span>
        </TableHeaderColumn>
      </BootstrapTable>

      <div
        className="modal fade"
        id="showInfosModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="showInfosModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id="showInfosModalLabel">
                Detail sur {state.coachFullname}
              </h4>
            </div>
            <div className="modal-body">
              <div className="coachInfos-container">
                <div className="fullname_container">
                  <span className="item_label">Prénom et Nom : </span>{" "}
                  <span className="label_content">{state.coachFullname}</span>
                </div>{" "}
                <hr />
                <div className="email_container">
                  <span className="item_label">Email : </span>{" "}
                  <span className="label_content">{state.coachEmail}</span>
                </div>{" "}
                <hr />
                <div className="phone_container">
                  <span className="item_label">Téléphone : </span>{" "}
                  <span className="label_content">{state.coachPhone}</span>
                </div>{" "}
                <hr />
                <div className="domaine_container">
                  <span className="item_label">Domaine de compétence : </span>{" "}
                  <span className="label_content">{state.coachDomaine}</span>
                </div>{" "}
                <hr />
                <div className="count_container">
                  <span className="item_label nbakelistes">
                    Nombre de bakelistes :{" "}
                  </span>{" "}
                  <br />
                  <span className="label_content cbakelistes">
                    {state.coachNbreBakelistes}
                  </span>
                </div>{" "}
                <hr />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Editer les infos du coach */}

      <Modal
        className="modal-responsive-sm p-b-50"
        show={state.show}
        onHide={close}
      >
        <h5 className="modal-bakeliste-title m-b-50">
          Éditer {state.coachFullname}
        </h5>
        <button className="close-modal-coach-title" onClick={close}>
          <FeatherIcon icon="x" aria-hidden="true" />
        </button>
        <div className="modal-body">
          <div className="row edit-coach-container">
            <div className="col-md-6">
              <div className="form-group ">
                <label>Prénom : </label>
                <input
                  type="text"
                  className="form-control"
                  value={state.coachFirstname}
                  onChange={updateHandleChange}
                  name="first_name"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group ">
                <label>Nom : </label>
                <input
                  type="text"
                  className="form-control"
                  value={state.coachLastname}
                  onChange={updateHandleChange}
                  name="last_name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label>Email : </label>
                <input
                  type="email"
                  className="form-control"
                  value={state.coachEmail}
                  onChange={updateHandleChange}
                  name="email"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group ">
                <label>Téléphone : </label>
                <input
                  type="number"
                  className="form-control"
                  value={state.coachPhone}
                  onChange={updateHandleChange}
                  name="phone"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Domaine :</label>
                <select
                  className="form-control"
                  id="inputbakeli_domaine_id"
                  name="coach_domaine_id"
                  value={state.coachDomaineID}
                  onChange={updateHandleChange}
                >
                  <option> -- Choisir un domaine -- </option>
                  {state.domaines.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {state.updateCoachInfosRequestError && (
              <div className="alerty-danger-container col-md-12 alert alert-danger form-group">
                <div className="alerty-danger">
                  Un probléme est survenu lors de la modification. Veuillez
                  réessayer plus tard.
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <div className="row">
            <div className="col-md-5">
              <button
                type="button"
                className="btn btn-theme-danger"
                onClick={close}
              >
                Quitter
              </button>
            </div>
            <div className="col-md-7">
              {!state.updateCoachInfosInProgress && (
                <button
                  type="button"
                  className="btn btn-theme"
                  onClick={(e) => updatedCoachData(e)}
                >
                  Modifier
                </button>
              )}
              {state.updateCoachInfosInProgress && (
                <button
                  type="button"
                  className="btn btn-warning button addBtn btn_for_wait"
                >
                  Modification en cours &nbsp;
                  <i className="fas fa-spinner fa-spin"></i>
                </button>
              )}
            </div>
          </div>
          {state.updatedError && (
            <div className="row update-error-container">
              <div className="col-md-12 error-content alert alert-danger">
                Oups...Un problème est survenu lors de la modification. Veuillez
                réessayer plus tard.
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TableMyListCoachsRefac;
