import React, {Component} from 'react';
import './pointageItemForAdmin.css';
import IconPoint from '../../img/icons/touch.svg';
import ModalBakelisteDetails from '../modalBakelisteDetails/index';

class Status extends React.Component {
  render() {
    return (
      <ModalBakelisteDetails/>
    );
  }
}

function changeStatus(cell, row) {
  return (
    <Status active={ cell } />
  );
}
export default class pointageItemForAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="card-dashboard m-b-15 w-full crd-item-pointage">
        <p className="txt-pointage"><img className="icon-h-card-dashboard-bakeliste" src={IconPoint} alt="" /> Pointage </p>
        <br />
        <br />
               <div className="diagram">
                                                                <div className="diagram-v">60</div>
                                                                <div className="diagram-o">30</div>
                                                                <div className="diagram-r">10</div>
                                                            </div>
              </div>;
    }
  }
