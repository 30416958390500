import React, {Component} from 'react';
import './navTabsLocalite.css';
import FeatherIcon from "feather-icons-react";
import {Tabs, Tab, Row,  Col, Container} from "react-bootstrap";
import { Link} from 'react-router-dom';
import AllCardSuiviBakeliste from '../allCardSuiviBakeliste/index';
import ListSuiviBakelisteDakar from '../listSuiviBakelisteDakar/index';
import ListSuiviBakelisteThies from '../listSuiviBakelisteThies/index';
import ListSuiviBakelisteLabe from '../listSuiviBakelisteLabe/index';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navTabsLocaliteActions from "../../store/navTabsLocalite/actions";
export default class navTabsLocalite extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
        <div className="component-nav-tabs-localite">
          <div className="container-fluid px-0">
             <div className="row">
              <div className="col-lg-9">
              <nav>
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="award"
                    />
                    Formation
                  </li>
                </ol>
              </nav>
                <Container>
                  <Row>
                    <Col>
                      <Tabs defaultActiveKey="Bakelistes" 
                            id="controlled-tab-example">
                        <Tab eventKey="Bakelistes" title="Bakelistes">                     
                          <AllCardSuiviBakeliste/>
                        </Tab>
                        <Tab eventKey="dakar" title="dakar">
                          <ListSuiviBakelisteDakar/>
                        </Tab>
                        <Tab eventKey="thies" title="thies">
                          <ListSuiviBakelisteThies/>
                        </Tab>
                        <Tab eventKey="labe" title="labe">
                          <ListSuiviBakelisteLabe/>
                        </Tab>
                       </Tabs>
                      </Col>
                     </Row>
                   </Container>
                  </div>
                </div>
              </div>
        </div>
      );
    }
  }
// export default connect(
//     ({ navTabsLocalite }) => ({ ...navTabsLocalite }),
//     dispatch => bindActionCreators({ ...navTabsLocaliteActions }, dispatch)
//   )( navTabsLocalite );