import React, { Component } from 'react';
import './listeGroupe.css';
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from 'rsuite';

import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import ListeGroupesAdmin from "../listeGroupesAdmin/index";
import AddSousDomaines from '../../components/addSousDomaines/index';
import { Link } from 'react-router-dom';
import API from "../../variablesGlobales";
// import TableMyDomaine from "../../components/tableMyDomaine/index"
import axios from 'axios';
import $ from "jquery"; 
import TableSousDomaines from '../tableSousDomaines/index';
// import Icon from '@ant-design/icons/lib/components/Icon';
const styles = {
  marginBottom: 30
};

const data = [
  'Developpement web',
  'Android developpeur',
  'UX/UI Design', 
  'Gestion de projet',
  'Infographie',
];

export default class listeGroupe extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  addGroupe = () => {
    window.location="/ajout-groupe"
  }
  render() {
    return <div className="p-b-200">
      <div className="row">
        <div className="col-lg-9">
    
      <div className="row m-b-15">
        <div className="col m-b-15">
          <h6 className="h-theme ff-inter fw-600 px-3"> Gestion des Groupes</h6>
        </div>
      </div>
     
             <div className="row">
             <div className="col-md-10">
</div>

               <div className="col-md-2">
                    <button className="btn-add-with-icon-plus-circle offset" onClick={this.addGroupe}>
                Ajouter
                <Icon className="icon-add ml-2" icon="plus-circle" /> 
              </button>
           
         </div>
</div>
          <div className="row px-lg-0 px-md-2"> 
            <ListeGroupesAdmin  />
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          {/* <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div> */}
        </div>
      </div>
    </div>;
  }
}
