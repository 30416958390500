import React, {Component} from 'react';
import './tableMyVisiteurs.css';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import API from "../../variablesGlobales";
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyVisiteursActions from "../../store/tableMyVisiteurs/actions";


class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col">
        <p className="badge todo-badge">To do</p>

        {/* !!!!important!!!! */}
        {/* <p className="badge doing-badge">Doing</p> */}
        {/* <p className="badge done-badge">Done</p> */}
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return (
    <Status active={ cell } />
  );
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="refresh-cw" size={18} />
        </button>
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="send" size={18} />
        </button>
        
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return (
    <Actions active={ cell } />
  );
}


export default class tableMyVisiteurs extends Component {
    constructor(props) {
        super(props);
        this.state = {
          api: API.API,
          visiteurs: [],
          loading: true,
        };
    }

    componentDidMount() {
      this.getListVisiteurs();
    }

    getListVisiteurs = () => {
      axios
        .get(this.state.api + "list-visiteurs")
        .then((res) => {
          if (res.data.success === true) {
          //  console.log('visiteurs', res.data.data)
            this.setState({
              visiteurs: res.data.data,
              loading: true,
            });
          } else {
            this.setState({
              loading: false,
              isEmptyData: true,
            });
          }
          // this.setState({ isLoaded: false });
          // $("#start-hidden").removeClass("start-hidden");
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error.message);
        });
    };

        //recuperation du nom et prenom dans un meme colonnne
        nameFormatter(cell, row) {
          return `${row.first_name} ${row.last_name}`
      }

    CustomExportCsvBtn = (onClick) =>{
      return(
        <ExportCSVButton style={{background: "#FF9800", border: "none", height: "42px", width: "150px", left: "20px"}} btnText="Export to csv" />
      );
    }
    render() {
      const options = {
        exportCSVBtn : this.CustomExportCsvBtn,
      };
      return (
        <div className="component-nav-tabs-utilisateurs">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">
                <BootstrapTable
                  data={this.state.visiteurs} 
                  striped 
                  version='4' 
                  csvFileName="Liste des visiteurs.csv"

                  exportCSV
                  bordered={ false } 
                  bodyStyle={{ 'fontSize': 12, 'color': 'rgba(0, 0, 0, 0.7)', 'fontFamily': 'inherit', 'fontWeight': 400 }} 
                  tdStyle={{ 'background': 'red' }}
                  tableHeaderclassName="header-table-groupe-view"
                  wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
                  className="table-responsive table-responsive-sm"
                >
                  <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                    {" "}
                    ID{" "}
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField='first_name' 

                   width="150"
                   thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                   tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                     <span className="color-nom-prenom-utilisateurs headerStyle">Noms complets</span>
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="email"

                   width="150"
                   thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                   tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                     <span className="color-nom-prenom-utilisateurs headerStyle">Email</span>
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="phone"

                   width="150"
                   thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                   tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                     <span className="color-nom-prenom-utilisateurs headerStyle">Telephone</span>
                  </TableHeaderColumn>
                
                  <TableHeaderColumn dataField='action' 
                  dataFormat={uploadFormatter}                  
                  width="150"
                    thStyle={{ 'border': 'none', 'fontWeight': '400' }} 
                    tdStyle={{ 'border': 'none', 'padding': '1rem','font-size': '14px','font-family': 'Montserrat' }}>
                      <span className="color-nom-prenom-utilisateurs headerStyle">Actions</span>
                    </TableHeaderColumn>
                </BootstrapTable>
               
                </div>
                </div>
                </div>
                </div>
        );
    }
  }
// export default connect(
//     ({ tableMyVisiteurs }) => ({ ...tableMyVisiteurs }),
//     dispatch => bindActionCreators({ ...tableMyVisiteursActions }, dispatch)
//   )( tableMyVisiteurs );