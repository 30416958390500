import React, { Component } from "react";
import "./navTabsAllBakelisteAdmin.css";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Tabs, Tab, Row, Col, Container } from "react-bootstrap";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import BakelistesAdmin from "../bakelistesAdmin/index";
import ListeBakelisteDakar from "../listeBakelisteDakar/index";
import ListeBakelisteThies from "../listeBakelisteThies/index";
import ListeBakelisteLabe from "../listeBakelisteLabe/index";
import { Fade } from "@material-ui/core";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navTabsAllBakelisteAdminActions from "../../store/navTabsAllBakelisteAdmin/actions";
export default class navTabsAllBakelisteAdmin extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-nav-tabs-all-bakeliste-admin">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="user"
                    />
                    Bakeliste
                  </li>
                </ol>
              </nav>

              <Container>
                <Row>
                  <Col>
                    <Tabs
                      defaultActiveKey="Bakelistes"
                      id="controlled-tab-example"
                      transition={Fade}
                    >
                      <Tab eventKey="Bakelistes" title="Bakelistes">
                        <BakelistesAdmin />
                      </Tab>
                      <Tab eventKey="Dakar" title="Dakar">
                        <ListeBakelisteDakar />
                      </Tab>
                      <Tab eventKey="Thies" title="Thies">
                        <ListeBakelisteThies />
                      </Tab>
                      <Tab eventKey="Labe" title="Labe">
                        <ListeBakelisteLabe />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </Container>
            </div>

            {/*<div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ navTabsAllBakelisteAdmin }) => ({ ...navTabsAllBakelisteAdmin }),
//     dispatch => bindActionCreators({ ...navTabsAllBakelisteAdminActions }, dispatch)
//   )( navTabsAllBakelisteAdmin );
