import React, { Component } from "react";
import "./navTabsParametres.css";
import { Tabs, Tab, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ParametresAdmin from "../parametresAdmin/index";
import ListeDomainesAdmin from "../listeDomainesAdmin/index";
import ListeFillieresAdmin from "../listeFillieresAdmin/index";
import CreneauxHorairesAdmin from "../creneauxHorairesAdmin/index";
import RiAdmin from "../riAdmin/index";
import SyllabusAdmin from "../syllabusAdmin/index";
import ListeAdmins from "../../components/listeAdmins/index";
import ListeCoachs from "../listCoachs/index";
import ListeCoachAssistants from "../../components/listCoachAssistants/index";
import ListeSousDomainesAdmin from "../../components/listeSousDomainesAdmin/index";
import ListeGroupe from "../../components/listeGroupe/index";
import ListeSousGroupes from "../../components/listeSousGroupe/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navTabsParametresActions from "../../store/navTabsParametres/actions";
export default class navTabsParametres extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-nav-tabs-parametres">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-side-breadcrumb-custom"
                      icon="users"
                    />
                    Paramétres
                  </li>
                </ol>
              </nav>
              
              <div className="row m-b-15">
                <div className="col m-b-15">
                  <h6 className="h-theme ff-inter fw-600 px-3">
                    {" "}
                    Gestion de paramètre
                  </h6>
                </div>
              </div>

              <Container>
                <Row>
                  <Col>
                    <Tabs
                      defaultActiveKey="parametres-admin"
                      id="controlled-tab-example"
                    >
                      <Tab
                        eventKey="parametres-admin"
                        title="Liste des syllabus"
                      >
                        <SyllabusAdmin />
                      </Tab>
                      <Tab
                        eventKey="Liste des domaines"
                        title="Liste des domaines"
                      >
                        <ListeDomainesAdmin />
                      </Tab>
                      <Tab
                        eventKey="Liste des sous domaines"
                        title="Liste des sous domaines"
                      >
                        <ListeSousDomainesAdmin />
                      </Tab>
                      <Tab
                        eventKey="Liste des filières"
                        title="Liste des filières"
                      >
                        <ListeFillieresAdmin />
                      </Tab>
                      <Tab eventKey="Liste des admins" title="Liste des admins">
                        <ListeAdmins />
                      </Tab>
                      <Tab eventKey="Liste des coachs" title="Liste des coachs">
                        <ListeCoachs /> 
                      </Tab>
                      <Tab eventKey="Liste des coach-assistants" title="Liste des coach-assistants">
                        <ListeCoachAssistants />
                      </Tab>
                      <Tab eventKey="Créneaux horaire" title="Créneaux horaire">
                        <CreneauxHorairesAdmin />
                      </Tab>
                    
                      <Tab
                        eventKey="Liste des groupes"
                        title="Liste des groupes"
                      >
                        <ListeGroupe />
                      </Tab>
                      <Tab
                        eventKey="Liste des sous groupes"
                        title="Liste des sous groupes"
                      >
                        <ListeSousGroupes />
                      </Tab>
                        <Tab eventKey="RI" title="RI">
                        <RiAdmin />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ navTabsParametres }) => ({ ...navTabsParametres }),
//     dispatch => bindActionCreators({ ...navTabsParametresActions }, dispatch)
//   )( navTabsParametres );
