import qs from "qs";
import React from "react";
import { useParams } from "react-router-dom";
import ConvertirProspectRefac from "../convertirProspect/ConvertirProspectRefac";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import "./convertirProspectView.css";

const ConvertirProspectViewRefac = (props) => {
  let id = props.location?.state?.id;
  if (!id && props.location.search) {
    const obj = qs.parse(props.location.search.replace("?", ""));
    id = parseInt(obj.id);
  }
  return (
    <div className="component-table-my-participants-view body-theme">
      {console.log("prospect id", id)}
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <ConvertirProspectRefac prospectId={id} />
            {/* <ConvertirProspect id={this.state.id} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConvertirProspectViewRefac;
