import React, { useReducer } from "react";
import FeatherIcon from "feather-icons-react";
import qs from "qs";
import { Link } from "react-router-dom";
import API from "../../variablesGlobales";
import "./editerCoach.css";

import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import { useDispatch } from "react-redux";
import { updateCoachAsync } from "../../redux/slices/coachsSlices/coachsSlice";
import { history } from "../history";
toast.configure();
const EditerCoachRefact = (props) => {
  let user_id = props.location?.state?.id;
  let data = props.location?.state?.data;
  if (!user_id && props.location.search) {
    const obj = qs.parse(props.location.search.replace("?", ""));
    user_id = parseInt(obj.id);
  }
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      user_id: user_id,
      api: API.API,
      data: data,
      userID: window.sessionStorage.getItem("user_id"),
      user: [],
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      phone: data.phone,
      email: data.email,
      ecole: data.ecole,
      domaine: data.domaine,
      domaine_id: data.coach_domaine_id,
      formation_suivie: data.formation_suivie,
      domaines: [],
      id: data.id,
      status: data.status,
    }
  );
  const dispatch = useDispatch()
  const editDetailsCoach = (e) => {
    // console.log("ddddddddd",state.bakeliste)
    e.preventDefault();
    setState({
      editCoachInProgress: true,
    });
    var fd = new FormData();
    fd.append("first_name", state.first_name);
    fd.append("last_name", state.last_name);
    fd.append("coach_domaine_id", state.domaine_id);
    // fd.append("ecole", state.ecole);
    // fd.append("formation_suivie", state.formation_suivie);
    fd.append("address", state.address);
    fd.append("phone", state.phone);
    fd.append("email", state.email);
    dispatch(updateCoachAsync(state.id,fd))
    // axios
    //   .put(state.api + "update-coach-infos/" + state.id, fd, {
    //     Headers: { "Content-Type": "multipart/formdata" },
    //   })
    //   .then((result) => {
    //     if (result.data.success) {
    //       toast.success(
    //         "Détails coach modiifié avec success",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //       setState({
    //         editCoachInProgress: false,
    //       });

    //       window.location = "/parametres-admin";
    //     } else {
    //       toast.error(
    //         "Erreur sur la modification",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     }
    //   });
  };
  const annuler = () => {
    history.push("/parametres-admin")
  };
  return (
    <div className="component-edit-bakeliste-suivi-view body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="users"
                    />
                    Utilisataeurs
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon
                    className="icon-side-breadcrumb-custom"
                    icon="edit"
                  />
                  Editer détails coach
                </li>
              </ol>
            </nav>
            <form className="form1" onSubmit={(e) => editDetailsCoach(e)}>
              <div>
                {state.status === "coach" && (
                  <h4 className="h-theme titreCoach">
                    Modification des details d'un coach
                  </h4>
                )}
                {state.status === "coach-assistant" && (
                  <h4 className="h-theme titreCoach">
                    Modification des details d'un coach assistant
                  </h4>
                )}
              </div>
              <div className="form-row row">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Prénom:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="first_name"
                      value={state.first_name}
                      onChange={(e) =>
                        setState({ first_name: e.target.value })
                      }
                    />

                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">
                      Nom de famille:
                    </label>
                    <input
                      type="last_name"
                      className="form-control ab1"
                      name="last_name"
                      value={state.last_name}
                      onChange={(e) =>
                        setState({ last_name: e.target.value })
                      }
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Email:</label>
                    <input
                      type="Email"
                      className="form-control ab1"
                      name="email"
                      value={state.email}
                      onChange={(e) => setState({ email: e.target.value })}
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Téléphone:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="phone"
                      value={state.phone}
                      onChange={(e) => setState({ phone: e.target.value })}
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Adresse:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="address"
                      value={state.address}
                      onChange={(e) =>
                        setState({ address: e.target.value })
                      }
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">
                      Domaine de coaching:
                    </label>

                    <select
                      className="form-control ab1"
                      name="coach_domaine"
                      onChange={(e) =>
                        setState({ domaine_id: e.target.value })
                      }
                      required
                      value={state.domaine_id}
                    >
                      <option>--choisir un domaine--</option>
                      {state.domaines.map((x, y) => (
                        <option value={x.id} key={y}>
                          {x.name}
                        </option>
                      ))}
                    </select>
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {state.editCoachInProgress ? (
                    <button
                      type="submit"
                      className="col-md-5 btn-theme text-up m-t-30 w-full p-t-15 p-b-15 offset-md-6"
                      disabled
                    >
                      Modification en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="col-md-5 btn-theme offset-md-6 text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      MODIFIER
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditerCoachRefact;
