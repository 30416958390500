import React, { Component } from "react";
import "./navTabsUtilisateurs.css";
import { Link } from "react-router-dom";
import { Tabs, Tab, Row, Col, Container } from "react-bootstrap";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import MyUserManager from "../myUserManager/index";
import MyVisiteurs from "../myVisiteurs/index";
import MyBfTerminer from "../myBfTerminer/index";
import MyParticipants from "../myParticipants/index";
import AllBakelisteAdmin from "../allBakelisteAdmin/index";
import UsersAdmin from "../usersAdmin/index";
import FeatherIcon from "feather-icons-react";

import ListeAdmins from "../../components/listeAdmins/index";
import ListeCoachs from "../listCoachs/index";
import ListeCoachAssistants from "../../components/listCoachAssistants/index";
import TableMyVisiteurs from "../tableMyVisiteurs/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navTabsUtilisateursActions from "../../store/navTabsUtilisateurs/actions";
export default class navTabsUtilisateurs extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-nav-tabs-utilisateurs">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active lien_nav"
                    aria-current="page"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="users"
                    />
                    Utilisateurs
                  </li>
                </ol>
              </nav>

              <Container>
                <Row>
                  <Col>
                    <Tabs defaultActiveKey="Admins" id="controlled-tab-example">
                      <Tab eventKey="Admins" title="Admins">
                        <ListeAdmins />
                      </Tab>
                      <Tab eventKey="Coachs" title="Coachs">
                        {/* <CoachAdmin /> */}
                        <ListeCoachs />
                      </Tab>
                      <Tab eventKey="Coach-Assistants" title="Coach-Assistants">
                        <ListeCoachAssistants />
                      </Tab>
                      <Tab eventKey="Participants" title="Participants">
                        <MyParticipants />
                      </Tab>
                      <Tab eventKey="BF.Terminés" title="BF.Terminés">
                        <MyBfTerminer />
                      </Tab>
                      <Tab eventKey="Visiteurs" title="Visiteurs">
                        <TableMyVisiteurs />
                      </Tab>
                      <Tab eventKey="User Manager" title="User Manager">
                        <MyUserManager />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </Container>
            </div>
            {/*<div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ navTabsUtilisateurs }) => ({ ...navTabsUtilisateurs }),
//     dispatch => bindActionCreators({ ...navTabsUtilisateursActions }, dispatch)
//   )( navTabsUtilisateurs );
