import React, {Component} from 'react';
import './allListeRetardView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import AllListeRetard from '../allListeRetard/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as allListeRetardViewActions from "../../store/allListeRetardView/actions";
export default class allListeRetardView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
          <div className="component-all-liste-retard-view body-theme">
              <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <AllListeRetard />
                    </div>
                  </div>
                </div>
              </div>
          );
    }
  }
// export default connect(
//     ({ allListeRetardView }) => ({ ...allListeRetardView }),
//     dispatch => bindActionCreators({ ...allListeRetardViewActions }, dispatch)
//   )( allListeRetardView );