import { toast } from "react-toastify";
toast.configure();


const errorMsg = (message) => {
  toast.error(
      message,
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 500 }
    );
}


export default errorMsg