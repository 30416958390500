import React, { Component } from 'react';
import './syllabusFiliéreProgrammation.css';
import ImageSyllabus from '../../img/syllabus-Programmation.png';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ShareTo from '../shareTo/index';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

export default class syllabusFiliéreProgrammation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="container container-syllabus-item-programmation">
      <div className="card-programmation">
        <div className="card-img-overlay-programmation">
          <p className="titre-card-filiéreprogrammation">Programmation</p>
          <ul className="">
            <li>Développement web et frondtend</li>
            <li>Développement web backend</li>
            <li>Développement mobile</li>
            <li>Développement logiciel</li>
          </ul>
        </div>
      </div>
    </div>

  }
}
