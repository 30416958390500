import React, {Component} from 'react';
import './syllabusDetailsView.css';
// import HeaderDashboardCoach from '../headerDashboardCoach/index';
import SyllabusDetailsAdmin from '../syllabusDetailsAdmin/index';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import API from "../../variablesGlobales";
import qs from "qs";

export default class syllabusDetailsView extends Component {
  constructor(props) {
    super(props); 
    let syllabus_id = this.props.location?.state?.id;
    if (!syllabus_id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      syllabus_id = parseInt(obj.id);
    } 
    this.state = {
      syllabus_id: syllabus_id, 
      api: API.API, 
      userID: window.sessionStorage.getItem("user_id"),
      syllabusDatas: [],
      taches: [],
      loading: true,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 4000);
  }
    render() {
      return  <div className="body-theme">
                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div> 
                    <div className="col-lg-10 pt-3">
                      <SyllabusDetailsAdmin
                      name={this.state.syllabusDatas.syllabus_name}
                      niveau={this.state.syllabusDatas.syllabus_level}
                      id={this.state.syllabus_id}
                      description={this.state.syllabusDatas.syllabus_description}
                      nbreTaches={this.state.nombreTaches}
                      Taches={this.state.syllabusDatas.taches}
                      lien={this.state.syllabusDatas.tache_url}
                   />
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ syllabusDetailsView }) => ({ ...syllabusDetailsView }),
//     dispatch => bindActionCreators({ ...syllabusDetailsViewActions }, dispatch)
//   )( syllabusDetailsView );