import React, { Component } from "react";
import "./shareTo.css";
import {
  InputGroup,
  Checkbox,
  CheckboxGroup,
  Modal,
  ButtonToolbar,
  Button,
  Icon,
  AutoComplete,
} from "rsuite";

import FeatherIcon from "feather-icons-react";

const options = ["A", "B", "C", "D"];

const data = [
  "Developpement web",
  "Android developpeur",
  "UX/UI Design",
  "Gestion de projet",
  "Infographie",
];

export default class shareTo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkAll: false,
      show: false,
      value: [],
    };
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  handleCheckAll(value, checked) {
    const nextValue = checked ? options : [];
    // console.log(nextValue, "handleCheckAll");
    this.setState({
      value: nextValue,
      indeterminate: false,
      checkAll: checked,
    });
  }
  handleChange(value) {
    // console.log(value, "handleChange");
    this.setState({
      value,
      indeterminate: value.length > 0 && value.length < options.length,
      checkAll: value.length === options.length,
    });
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  render() {
    return (
      <div className="component-share-to">
        <div className="modal-container">
          <ButtonToolbar>
            <Button className="btn-share-to" onClick={this.open}>
              <FeatherIcon
                className="icon-details-container-syllabus-item-back"
                icon="share-2"
              />
              Assigner
            </Button>
          </ButtonToolbar>

          <Modal
            className="modal-responsive-sm"
            show={this.state.show}
            onHide={this.close}
          >
            <h5
              className="modal-bakeliste-title m-b-50"
              id="modalAddTacheTitle"
            >
              Assigner à
            </h5>
            <button
              type="button"
              className="close-modal-bakeliste-title"
              onClick={this.close}
            >
              <FeatherIcon icon="x" aria-hidden="true" />
            </button>
            <Modal.Body className="max-height-modal pb-0">
              <InputGroup inside>
                <AutoComplete data={data} placeholder="Entrer un nom" />
                <InputGroup.Addon>
                  <Icon icon="search" />
                </InputGroup.Addon>
              </InputGroup>

              <div className="ligne"></div>

              <div className="m-b-40">
                <Checkbox
                  indeterminate={this.state.indeterminate}
                  checked={this.state.checkAll}
                  onChange={this.handleCheckAll}
                >
                  <span className="select-all-label-send-task-to">
                    Tout selectionner
                  </span>
                </Checkbox>

                <CheckboxGroup
                  inline
                  name="checkboxList"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <Checkbox className="dis-flex" value="A">
                    Ngone Diop{" "}
                  </Checkbox>
                  <Checkbox value="B">Seykel Sylla</Checkbox>
                  <Checkbox value="C">Fatma DIENG</Checkbox>
                  <Checkbox value="D">Aissatou Keita</Checkbox>
                </CheckboxGroup>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="row m-t-40 dis-flex justify-content-end">
                <div className="col-sm-5">
                  <button
                    onClick={this.close}
                    className="btn-theme w-full fs-13"
                  >
                    Envoyer
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ shareTo }) => ({ ...shareTo }),
//     dispatch => bindActionCreators({ ...shareToActions }, dispatch)
//   )( shareTo );
