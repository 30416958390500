import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { reducer } from "react-redux-toastr";
import { toast } from "react-toastify";
import { history } from "../../../components/history";
import { API } from "../../../variablesGlobales";

const initialState = {
  isLoading: false,
  prospects: [],
  prospectItem: [],
  domaineData: [],
  prospectComments: [],
  isContacting: false,
  gettingCandidatureById: false,
  loadingComments: false,
};

const API_URL = API;

export const prospectSlice = createSlice({
  name: "prospect",
  initialState,

  reducers: {
    gettingProspect: (state) => {
      state.isLoading = true;
    },

    getProspects: (state, action) => {
      state.prospects = action.payload;
    },

    isGettingCandidatureById: (state) => {
      state.gettingCandidatureById = true;
    },

    getCandidatureById: (state, action) => {
      state.prospectItem = action.payload;
    },

    isGettingCandidatureByIdDone: (state) => {
      state.gettingCandidatureById = false;
    },

    gettingProspectDone: (state) => {
      state.isLoading = false;
    },

    getDomaineById: (state, action) => {
      state.domaineData = action.payload;
    },

    contactingProspect: (state) => {
      state.isContacting = true;
    },

    contactingProspectDone: (state) => {
      state.isContacting = false;
    },

    getProspectsComments: (state, action) => {
      state.prospectComments = action.payload;
    },

    isLoadingComments: (state) => {
      state.loadingComments = true;
    },
    isLoadingCommentsDone: (state) => {
      state.loadingComments = false;
    },
  },
});

export const getProspectsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}prospects`);
    if (res.data.success) {
      dispatch(getProspects(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getProspectsCommentsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}admin-do-comment-prospects`);
    if (res.data.success) {
      dispatch(getProspectsComments(res.data.data));
      dispatch(isLoadingCommentsDone());
    }
  } catch (error) {
    console.log(error);
  }
};

export const contactProspectAsync = (prospectId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}contacted-prospect/${prospectId}`);
    if (res.data.success) {
      dispatch(getProspectsAsync());
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDomaineByIdAsync = (domaineId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}domaines/${domaineId}`);
    if (res.data.success) {
      dispatch(getDomaineById(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCandidatureByIdAsync = (prospectId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}prospects/${prospectId}`);
    if (res.data.success) {
      dispatch(getCandidatureById(res.data.data));
      dispatch(isGettingCandidatureByIdDone());
    }
  } catch (error) {
    console.log(error);
    dispatch(isGettingCandidatureByIdDone());
  }
};

export const addAdminCommentAsync = (fd) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}admin-do-comment-prospects`, fd);
    if (res.data.success) {
      toast.success(
        "Commentaire ajouté",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
      dispatch(getProspectsAsync());
    }
  } catch (error) {
    console.log(error);
    toast.error(
      "Erreur !!!",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
  }
};

export const {
  gettingProspect,
  getProspects,
  gettingProspectDone,
  contactingProspect,
  contactingProspectDone,
  getDomaineById,
  getCandidatureById,
  isGettingCandidatureById,
  isGettingCandidatureByIdDone,
  getProspectsComments,
  isLoadingCommentsDone,
  isLoadingComments,
} = prospectSlice.actions;
export const showListProspects = (state) => state.prospects?.prospects;
export const prospectByIdItem = (state) => state.prospects?.prospectItem;
export const prospectComments = (state) => state.prospects?.prospectComments;
export const domaineInfos = (state) => state.prospects?.domaineData;
export const isLoadingDomaines = (state) =>
  state.prospects?.gettingCandidatureById;
export const loadingComments = (state) => state.prospects?.loadingComments;
export default prospectSlice.reducer;
