import React, {Component} from 'react';
import './parametresAdmin.css';
import {Link, NavLink} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import TableFormationCerfiante from '../tableFormationCertifiante/index';
import { AutoComplete, Icon, InputGroup } from 'rsuite';
import AddSyllabus from '../addSyllabus/index';
import SearchIcon from '@material-ui/icons/Search';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TableFormationDiplomante from '../tableFormationDiplomante/index';


const styles = {
  marginBottom: 30
};

const data = [

];

export default class parametresAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="p-b-200">
            <div className="container-fluid px-0">
                <div className="row">
                    <div className="col-lg-9">
                        {/* <nav aria-label="breadcrumb">
            <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
              <li className="breadcrumb-item"><Link to="/dashboard-admin" className="lien_nav_current"><FeatherIcon className="icon-breadcrumb-custom" icon="layout"/>Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page" className="lien_nav"><FeatherIcon className="icon-side-breadcrumb-custom" icon="users"/>Paramétres</li>
            </ol>
          </nav>
                        <div className="row m-b-15">
                            <div className="col m-b-15">
                                <h6 className="h-theme ff-inter fw-600 px-3"> Gestion de paramètre</h6>
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <div className="row m-b-15">
                                <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
                                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="navbar-nav mr-auto">
                                            <li class="nav-item active">
                                                <a class="nav-link ml-4 lfb" href="parametres-admin">Liste de formation de Bakeli<span class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link ml-4" href="listeDomainesAdmin">Liste des domaines</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link ml-4" href="listeFillieresAdmin">Liste des filières</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link ml-4" href="creneauxHorairesAdmin">Créneaux horaire</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link ml-4" href="riAdmin">RI</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div> 
                        </div>*/}
                        <div className="row pt-3">
                            <div className="col-xl-4 col-lg-5 col-md-4 col-sm-5">
                                <InputGroup inside style={styles}>
                                    <InputGroup.Addon></InputGroup.Addon>
                                    <AutoComplete data={data} placeholder="Chercher" className="input-syllabus-search" />
                                    <Icon icon="search" />
                                </InputGroup>
                            </div>
                            <div className="col m-b-15">
                                <button className="btn-add-with-icon-plus-circle btn-vert-add" 
                                onClick={this.ajouterBakeliste} 
                                >Ajouter un bakeliste
                                <Icon className="icon-add-chiffres ml-2" icon="plus-circle" />
                                </button>
                            </div>
                        </div>
                       <div className="card-dashboard m-b-15 p-b-60">
                           <div className="row m-b-30 m-t-20">
                               <p className="h-theme ff-inter fw-600 m-l-25">Formation Certifiante</p>
                               <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15">
                            
                               </div>
                            </div>
                            <div className="row cp">

                            </div>
                            <TableFormationCerfiante  />
                            </div>
                            <div className="card-dashboard m-b-15 p-b-60">
                                <div className="row m-b-30 m-t-20">
                                    <p className="h-theme ff-inter fw-600">Formation Diplômante</p>
                                    <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15">

                                    </div>
                                </div>
                                <div className="row cp">

                                </div>
                                <TableFormationDiplomante />
                            </div>
                        </div>
                        {/*<div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                            <div className="row">
                                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                    <BakelisteLateItemForAdmin />
                                </div>
                                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                    <BakelisteMissingItemForAdmin />
                                </div>
                                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                                    <ProgramReunionCoach />
                                </div>
                                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                                    <SavedReunionListItem />
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>;
    }
  }