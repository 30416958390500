import React, {Component} from 'react';
import './tacheCoach.css';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import { Uploader, DatePicker } from 'rsuite';
import SendTaskTo from '../sendTaskTo/index';
import TableGestionTacheForCoach from '../tableGestionTacheForCoach/index';

export default class tacheCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="p-b-200">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row m-b-15">
                      <div className="col m-b-15">
                        <h6 className="h-theme ff-inter fw-600">Ajouter une tâche</h6>
                      </div>
                      <div className="col dis-flex m-b-15">

                      </div>
                    </div>
                    
                    <div className="card-dashboard p-md-5 p-sm-5 m-b-20">
                      <form>
                        <div className="form-group">
                          <input type="texte" className="form-control form-input-dashboard-coach" placeholder="Nom de la tâche" />
                        </div>
                        <div className="form-group">
                          <textarea className="form-control form-input-dashboard-coach p-t-15 p-b-15" rows="10" placeholder="Description de la tâche"></textarea>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <Uploader 
                              className="w-full" 
                              listType="text" 
                              action="//jsonplaceholder.typicode.com/posts/" 
                              multiple="true" 
                              name=""
                              icon="google-plus-circle"
                            />
                          </div>
                          <div className="col-md-3 pl-md-0">
                            <DatePicker
                              className="datapicker-custom-bakeli"
                              format="YYYY-MM-DD HH:mm:ss"
                              ranges={[
                                {
                                  label: 'Now',
                                  value: new Date()
                                }
                              ]}
                              placeholder="Choisir un délai"
                            />
                          </div>
                        </div>

                        <div className="row m-t-40 dis-flex justify-content-end">
                          <div className="col-lg-4 px-lg-3 col-md-3 pl-md-0 col-sm-4 pl-sm-0">
                            <SendTaskTo />
                          </div>
                        </div>
                      </form>
                    </div>


                    <h6 className="h-theme m-b-20 m-t-25">Gestion des tâches</h6>
                    <div className="card-dashboard p-sm-4 mb-3">
                      <TableGestionTacheForCoach />
                    </div>

                  </div>
                  <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteGroupItemForCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                        <ProgramReunionCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <SavedReunionListItem />
                      </div>
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ tacheCoach }) => ({ ...tacheCoach }),
//     dispatch => bindActionCreators({ ...tacheCoachActions }, dispatch)
//   )( tacheCoach );