import FeatherIcon from "feather-icons-react";
import React from "react";
import { Link } from "react-router-dom";

const NewBktCard = ({ cardName, cardLink, cardImage, cardData }) => {
  return (
    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-10 mx-auto mb-3 ">
      <Link to={cardLink} style={{ textDecoration: "none" }}>
        <div className="card card-all">
          <div className="card-body cardCenter">
            <img src={cardImage} alt="logo" className="img-card" />
            <p className="text-white mt-2 fw-bold">{cardName}</p>
          </div>
          <div className="card-footer">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6 d-flex justify-content-start mr-auto">
                <FeatherIcon icon="users" />
              </div>
              <div className="col-md-6 text-start d-flex justify-content-end ml-auto">
                <p className="titre-nbr">{cardData}</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NewBktCard;
