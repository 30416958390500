import React, {Component} from 'react';
import './statisticsCoach.css';
import IconStat1 from '../../img/icons/person_add.png';
import IconStat2 from '../../img/icons/content_paste.png';
import IconStat3 from '../../img/icons/people.png';

export default class statisticsCoach extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="row">
                <div className="col-md-4 pr-md-2 mb-3 dis-flex">
                  <div className="item-statistics-dashboard">
                    <p className='chiffre-item-statistics-dashboard'>234</p>
                    <p className="designation-item-statistics-dashboard">bakelistes</p>
                    <img className="img-item-statistics-dashboard" src={IconStat1} alt="" />
                  </div>
                </div>
                <div className="col-md-4 pr-md-2 pl-md-2 mb-3 dis-flex">
                  <div className="item-statistics-dashboard">
                    <p className='chiffre-item-statistics-dashboard'>04</p>
                    <p className="designation-item-statistics-dashboard">Nombre de tâches envoyéés</p>
                    <img className="img-item-statistics-dashboard icon2" src={IconStat2} alt="" />
                  </div>
                </div>
                <div className="col-md-4 pl-md-2 mb-3 dis-flex">
                  <div className="item-statistics-dashboard">
                    <p className='chiffre-item-statistics-dashboard'>20</p>
                    <p className="designation-item-statistics-dashboard">Groupes</p>
                    <img className="img-item-statistics-dashboard" src={IconStat3} alt="" />
                  </div>
                </div>
              </div>;
    }
  }
// export default connect(
//     ({ statisticsCoach }) => ({ ...statisticsCoach }),
//     dispatch => bindActionCreators({ ...statisticsCoachActions }, dispatch)
//   )( statisticsCoach );