import React, { useEffect, useReducer } from "react";
import "./tableSousDomaines.css";
import FeatherIcon from "feather-icons-react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import * as Sentry from "@sentry/browser";
import { Modal } from "rsuite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  archiveSousDomaineAsync,
  getSousDomainesAsync,
  showListSousDomaines,
  updateSousDomaineAsync,
} from "../../redux/slices/parametreSlices/parametreSlice";

toast.configure();
const TableSousDomainesRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      domaines: [],
      allSousDomaines: [],
      id: 0,
      showEditModal: false,
      size: "md",
      archiver: false,
      syllabus: {},
      // api : API.API,
      sousDoamaine: {},
      nom: "",
      description: "",
      domaine_id: "",
      fields: {},
      sousDomaineID: "",
      domaine_name: "",
      sous_domaine_id: "",
      id_Sous_Domaine: "",
    }
  );

  const dispatch = useDispatch();
  const sousDomainesData = useSelector(showListSousDomaines);

  useEffect(() => {
    dispatch(getSousDomainesAsync());
    getListDomaines();
    //getListAllSousDomaines();
  }, []);

  const getListDomaines = () => {
    axios
      .get(state.api + "domaines")
      .then((res) => {
        if (res.data.success === true) {
          //  console.log('domaines actifs', res.data.data)
          setState({
            domaines: res.data.data,
            loading: false,
          });
        } else {
          setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  const editDomaineModal = (id) => {
    onGetSousDomaine(id);
    setState({
      showEditModal: true,
    });
  };

  const onGetSousDomaine = (id) => {
    setState({
      isPageLoading: true,
    });
    axios
      .get(state.api + "sous_domaines/" + id)
      .then((response) => {
        setState({
          isPageLoading: false,
        });
        // console.log(response, "res")
        if (response.data.success) {
          setState({
            sousDoamaine: response.data.data,
            nom: response.data.data.nom,
            domaine_id: response.data.data.domaine_id,
            description: response.data.data.description,
            sous_domaine_id: response.data.data.id,
          });
        }
      })
      .catch((error) => {
        setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };
  const close = () => {
    setState({
      showEditModal: false,
    });
  };

  const onEditSousDomaine = (e) => {
    e.preventDefault();
    setState({
      editDomaineInProgress: true,
    });
    var fd = new FormData();
    fd.append("nom", state.nom);
    fd.append("domaine_id", state.domaine_id);
    fd.append("description", state.description);
    dispatch(updateSousDomaineAsync(state.sous_domaine_id, fd));
    setTimeout(() => {
      setState({
        showEditModal: false,
        editDomaineInProgress: false,
      });
    }, 1500);
  };

  const actionsFormatter = (cell, row) => {
    let id = row.id;
    return (
      <span>
        <span className="modifier" onClick={() => editDomaineModal(id)}>
          <FeatherIcon
            className="icon-details-container-syllabus-item-back"
            icon="edit-3"
          />
          Modifier
        </span>
        <span
          className="archiver"
          onClick={() => setState({ archiver: true, id_Sous_Domaine: row.id })}
        >
          <FeatherIcon
            className="icon-details-container-syllabus-item-back mgleft"
            icon="trash"
          />
          Archiver
        </span>
      </span>
    );
  };

  const archiverSousDomaine = () => {
    dispatch(archiveSousDomaineAsync(state.id_Sous_Domaine));
  };
  const handleExportCSVButtonClick = (onClick) => {
    // Custom your onClick event here,
    // it's not necessary to implement this function if you have no any process before onClick
    console.log("This is my custom function for ExportCSVButton click event");
    onClick();
  };
  const createCustomExportCSVButton = (onClick) => {
    return (
      <ExportCSVButton
        style={{ "background-color": "#469789", margin: "1rem" }}
        btnText="Export CSV"
      />
    );
  };


  const options = {
    exportCSVBtn: createCustomExportCSVButton,
  };
  return (
    <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
      <BootstrapTable
        data={sousDomainesData}
        striped
        version="4"
        bordered={false}
        exportCSV
        csvFileName="Liste des admins.csv"
        bodyStyle={{
          fontSize: 12,
          color: "rgba(0, 0, 0, 0.7)",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        tdStyle={{ background: "red" }}
        tableHeaderclassName="header-table-groupe-view"
        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
        className="table-responsive table-responsive-sm"
      >
        <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
          {" "}
          ID{" "}
        </TableHeaderColumn>
        <TableHeaderColumn
          // filter={{type:"TextFilter", delay: 1000}}
          dataField="nom"
          width="250"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs headerStyle">Nom</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          className="titre-tab"
          dataField="description"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{ border: "none", padding: "1rem" }}
        >
          <span className="color-nom-prenom-utilisateurs headerStyle">
            Description
          </span>
        </TableHeaderColumn>
        <TableHeaderColumn
          className="titre-tab"
          dataFormat={actionsFormatter}
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{ border: "none", padding: "1rem" }}
        >
          <span className="color-nom-prenom-utilisateurs headerStyle">
            Actions
          </span>
        </TableHeaderColumn>
      </BootstrapTable>

      <div>
        <SweetAlert
          warning
          show={state.archiver}
          title="Etes vous sur de voulouir archiver ce sous domaine?"
          // text="SweetAlert in React"
          confirmBtnText="Oui"
          cancelBtnText="Non"
          onConfirm={() => {
            // console.log("confirm");
            setState({ archiver: false });
            // pauseBakelisteTraining();
            archiverSousDomaine();
          }}
          showConfirm={true}
          showCloseButton={true}
          onCancel={() => {
            // console.log("cancel");
            setState({ archiver: false });
          }}
          showCancel={true}
          focusCancelBtn={true}
          cancelBtnBsStyle="danger"
        />
      </div>
      <div>
        <hr />
      </div>

      <Modal
        className="modal-responsive-md"
        size={state.size}
        show={state.showEditModal}
        onHide={close}
      >
        <form onSubmit={(e) => onEditSousDomaine(e)}>
          <Modal.Header>
            <Modal.Title className="modal-contact-us">
              <h5 className="modal-bakeliste-title m-b-50">
                Modifier sous-domaine
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-height-modal">
            <div className="form-group as_input_container">
              <label>Nom du domaine</label>
              <input
                value={state.nom}
                onChange={(e) => setState({ nom: e.target.value })}
                name="nom"
                // placeholder="Nom du syllabus"
                className="form-control input_item"
                id="name"
              />
            </div>
            <div className="form-group as_input_container">
              <label>Description du domaine</label>
              <textarea
                value={state.description}
                onChange={(e) => setState({ description: e.target.value })}
                name="description"
                placeholder="Nom du syllabus"
                className="form-control input_item"
                id="description"
              />
            </div>
            <div className="form-group as_input_container">
              <select
                className="form-control input_item"
                name="domaine_id"
                onChange={(e) => setState({ domaine_id: e.target.value })}
                required
                value={state.domaine_id}
              >
                <option>-{state.domaine_name}</option>
                {state.domaines.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row dis-flex justify-content-end">
              <div className="col-md-5 col-sm-6">
                {state.editDomaineInProgress ? (
                  <button
                    type="submit"
                    className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    Modification en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  >
                    MODIFIER
                  </button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default TableSousDomainesRefact;
