import React, { useEffect, useReducer } from "react";
import "./listeGroupesAdmin.css";
import { Link } from "react-router-dom";
import BakelisteGroupItemForCoach from "../bakelisteGroupItemForCoach/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import { Icon } from "rsuite";
import { NavLink } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import API from "../../variablesGlobales";
import IconGroup from "../../img/icons/people.png";
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "rsuite";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getGroupesAsync,
  showListGroupes,
  updateGroupeAsync,
} from "../../redux/slices/parametreSlices/parametreSlice";
const ListeGroupesAdminRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      groupes: [],
      loading: false,
      showAlertError: false,
      showAlertSuccess: false,
      userID: window.sessionStorage.getItem("user_id"),
      list: [],
      group_name: "",
      lien_whatsapp: "",
      id: "",
      showEditModal: false,
      fields: {},
      size: "md",
      value: " ",
      groupe: {},
      editGroupeInProgress: false,
    }
  );
  const dispatch = useDispatch();
  const groupeData = useSelector(showListGroupes);
  useEffect(() => {
    dispatch(getGroupesAsync());
    // getGroupes()
  }, []);

  const onSubmitEditDetailsGroupe = (e) => {
    e.preventDefault();
    setState({
      editGroupeInProgress: true,
    });
    var fd = new FormData();
    fd.append("group_name", state.group_name);
    fd.append("lien_whatsapp", state.lien_whatsapp);

    // data["group_name"] = state.group_name;
    dispatch(updateGroupeAsync(state.id, fd));

    setTimeout(() => {
      setState({
        showAlertSuccess: true,
        editGroupeInProgress: false,

        showEditModal: false,
      });
      close();
    }, 2000);
  };

  const getGroupes = () => {
    // const _this = this;
    // showLoader();
    axios
      .get(state.api + "groupes")
      .then((res) => {
        setState({
          loading: false,
        });
        if (res.data.success) {
          setState({
            groupes: res.data.data.reverse(),
            name: res.data.data.group_name,
          });
        }
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
        // _hideLoader();
      });
  };



  const deleteGroup = (id) => {
    axios
      .delete(state.api + "groupes/" + id)
      .then((res) => {
        if (res.data.success) {
          getGroupes();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const editGroupeModal = (id) => {
    onGetGroupeById(id);
    setState({
      showEditModal: true,
    });
  };

  const onGetGroupeById = (id) => {
    const _this = this;
    setState({
      isPageLoading: true,
    });
    var baseApiUrl = state.api;
    axios
      .get(baseApiUrl + "groupes/" + id)
      .then((response) => {
        setState({
          isPageLoading: false,
        });
        if (response.data.success) {
          setState({
            groupe: response.data.data,
            group_name: response.data.data.group_name,
            id: response.data.data.id,
          });
        }
      })
      .catch((error) => {
        setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };

  const close = () => {
    setState({
      showEditModal: false,
    });
  };
  return (
    <div className="component-livrable-bakeliste-view container-fluid px-md-3 p-0">
      <div className="row">
        {groupeData.map((item, index) => (
          <div className="col-md-6 col-sm-4 pr-sm-2 mb-3 dis-flex" key={index}>
            <div className="item-statistics-dashboard">
              <Link
                to={{
                  pathname: "/sous-groupe",
                  state: { id: item.id },
                }}
              >
                <p className="groupe-titre-on-coach-dashboard">
                  {item.group_name}
                </p>

                <p className="designation-item-statistics-dashboard"> </p>
                <img
                  className="img-item-statistics-dashboard1"
                  src={IconGroup}
                  alt=""
                />
              </Link>
              {item.sous_groupes !== undefined && (
                <span>{item.sous_groupes.length} sous-groupes</span>
              )}{" "}
              <br />
              <a
                // className="lien-whatsapp"
                href={item.lien_whatsapp}
                target="blank"
              >
                {item.lien_whatsapp}
              </a>
              <div className="row">
                <div className="col-md-6">
                  <span onClick={() => editGroupeModal(item.id)}>
                    <FeatherIcon
                      icon="edit"
                      className="btn-modifier "
                      type="button"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="col-md-6">
                  <FeatherIcon
                    icon="archive"
                    className="btn-archiver-groupe "
                    type="button"
                    onClick={() => deleteGroup(item.id)}
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div
                className="modal fade"
                id={`editGroupModal${item.id}`}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="editDetailsSyllabusModalLabel"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    {/* Alert Livrable envoye avec succes */}
                    <SweetAlert
                      show={state.showAlertSuccess}
                      onConfirm={() => setState({ showAlertSuccess: false })}
                      success
                      title="têche modifié avec succes!!!"
                      style={{
                        color: "#069A77",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />
                    <SweetAlert
                      show={state.showAlertError}
                      onConfirm={() => setState({ showAlertError: false })}
                      error
                      title="Erreur sur modification du groupe, veuillez reessayer!!!"
                      style={{
                        color: "#d9534f",
                        fontSize: "12px",
                        fontFamily: "Work Sans, sans-serif",
                        paddingTop: 60,
                        paddingBottom: 40,
                      }}
                      confirmBtnCssclassName="btn-theme border-theme fs-17 no-decoration px-4"
                    />
                    <Modal
                      className="modal-responsive-md"
                      size={state.size}
                      show={state.showEditModal}
                      onHide={close}
                    >
                      <form
                        encType="multipart/form-data"
                        onSubmit={(e) => onSubmitEditDetailsGroupe(e)}
                      >
                        <Modal.Header>
                          <Modal.Title className="modal-contact-us">
                            Modifier Groupe{" "}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="max-height-modal">
                          <div className="form-group as_input_container">
                            <input
                              value={state.group_name}
                              onChange={(e) =>
                                setState({
                                  group_name: e.target.value,
                                })
                              }
                              name="group_name"
                              placeholder="Nom du groupe"
                              className="form-control input_item"
                              id="group_name"
                            />
                            <br />
                            <input
                              value={state.lien_whatsapp}
                              onChange={(e) =>
                                setState({
                                  lien_whatsapp: e.target.value,
                                })
                              }
                              name="lien_whatsapp"
                              placeholder="Lien groupe whatsapp"
                              className="form-control input_item"
                              id="lien_whatsapp"
                            />
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="row dis-flex justify-content-end">
                            <div className="col-md-5 col-sm-6">
                              {state.editGroupeInProgress ? (
                                <button
                                  type="submit"
                                  className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                  disabled
                                >
                                  Modification en cours &nbsp;
                                  <FeatherIcon
                                    icon="loader"
                                    aria-hidden="true"
                                  />
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                                >
                                  MODIFIER
                                </button>
                              )}
                            </div>
                          </div>
                        </Modal.Footer>
                      </form>
                    </Modal>
                    ENd ALert Error
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListeGroupesAdminRefact;
