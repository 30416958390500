import React, {Component} from 'react';
import './listSuiviBakelisteDakar.css';
import CardSkeleton from '../allCardSuiviBakeliste/cardSkeleton';
import { toast } from "react-toastify";
import $ from "jquery";
import { Link } from "react-router-dom";
import axios from "axios";
import API from "../../variablesGlobales";
import { CSVLink } from 'react-csv';
import Hom from '../../img/hom.png';
import csvData from 'react-csv';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listSuiviBakelisteDakarActions from "../../store/listSuiviBakelisteDakar/actions";


const data = [
  { bakelisteFullname: "Fatou Lô", bakeliste_domaine_id: "", email: "mai@gmail.com" }
];
const headers = [
  { label: "BakelisteFullname", key: "bakelisteFullname" },
  { label: "Bakelite Domaine", key: "bakelistedomaine" },
  { label: "Email", key: "email" }
];
const csvReport = {
  filename: 'Report.csv',
  headers: headers,
  data: data,
};


export default class listSuiviBakelisteDakar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          api: API.API,          
          bakeliste_domaine_id: 0,        
          Tache : [],        
          bakelistes: [],       
          nbrSemaine: 0,
          Assiduite: "",
          Performance : "",
          Autonomie :"",
          Satisfactions : "",
          avisCoach : "",
          loading: true,
          getListeBakelisteRequestError: false,
          isLoaded: true,
        }

        this.csvLinkEl = React.createRef();
        this.headers = [
          {label: "BakelisteFullname", key: "bakelisteFullname" },
          {label: "Bakelite Domaine", key: "bakeliste_domaine_id" },
          {label: "Email", key: "email" },
          {label: "Tâche", key: "tâche" },
          {label: "Coach", key: "coach" },
          {label: "Nombres de semaines", key: "nbrSemaine" },
          {label: "Assiduité", key: "assiduité" },
          {label: "Performance", key: "performance" },
          {label: "Autonomie", key: "autonomie" },
          {label: "Satisfaction", key: "satisfaction" },
          {label: "Avis Coach", key: "avisCoach" }
        ]
      }
    

   
    

    componentDidMount() { 
      this.getListeBakelistes();
    }

    getListeBakelistes = () => {
      axios
        .get(this.state.api + "bakeliste-grand-yoff2")
        .then((res) => {  
        //  console.log('All Bakelistes', res.data.data)
            this.setState({
              bakelistes: res.data.data,
              loading: false,
            });          
            this.state.bakelistes.map((bakeliste, index) => {
              this.setState({
                 loading: false
              }) 
              }
            );
            this.setState({ isLoaded: false });
            $("#start-hidden").removeClass("start-hidden");
            // console.log("Bakeliste liste", this.state.bakelistes);      
        })
        .catch((error) => {
          this.setState({
            loading: false,
            getListeBakelisteRequestError: true,
          });
          console.log(error.message);
        });
        
    };

    bakelisteToStage = () =>{
      // e.preventDefault();
      this.setState({
        loading: true,
      });
      const dataBakeliste = this.state.bakeliste;
      axios
        .post(this.state.api + "bakeliste_to_stagiaire", dataBakeliste)
        .then((res) => {
          if (res.data.success) {
            this.setState({
              loading: false,
            });
            toast.success(
              "Bakeliste converti en stagiaire avec succes",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 500 }
            );
            
          } else {
            // console.log(res);
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            getListeBakelisteRequestError: true,
          });
          console.log(error.message);
        });
    };

    render() {
      return (
        <div className="component-list-suivi-bakeliste-dakar">
             <CSVLink {...csvReport} 
                 className="btn btn-export-to mt-3"> EXPORT TO CSV</CSVLink>
                 {this.state.isLoaded && <CardSkeleton />}
                <div className="row mt-3">
                

                {this.state.bakelistes.map((bakeliste, index) => (
                  <div className="col-md-5  first-card-info">       
                    <div className="align-inf-bak">
                      <div className="image-cercle">
                        
                          <img src={Hom} key={index} alt=""/>
                        </div>
                        <div className="ml-2">
                          <p className="fat-ma"><span className="d-flex">{bakeliste.bakelisteFullname}</span></p>
                          <p><span className="pro-mail">{bakeliste.domaine} / </span><span className="d-flex mail-color">{bakeliste.email}</span></p>
                        </div>
                        <div className="ml-2">
                        <Link 
                          to={{
                            pathname: "/edit-suivi-bakeliste",
                            // state: { id: row.id, data: row},
                          }}
                          >
                          <button className="btn-modif-info-bakeliste">Modifier les informations</button>
                          </Link>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-md-4 col-lg-4">
                            <p><span className="txt-suivi-coach">Tache : </span>
                            <span className="comp-fonct">{}</span></p>
                          </div>
                          <div className="col-md-4 col-lg-4 ">
                            <p><span className="txt-suivi-coach">Coach : </span>
                            <span className="comp-fonct"> {bakeliste.coachFullname} </span></p>
                          </div>
                          <div className="col-md-4 col-lg-4">
                            <p><span className="txt-suivi-coach">Nbr Semaine : </span>
                            <span className="comp-fonct"> {""} </span></p>
                          </div>
                      </div>
                      <div className="row mt-2">
                          <div className="col-md-4 col-lg-4">
                            <p><span className="txt-suivi-coach">Assiduité : </span>
                            <span className="comp-fonct"> {""} </span></p>
                          </div>
                          <div className="col-md-4 col-lg-4 ">
                            <p><span className="txt-suivi-coach">Performance : </span>
                            <div className="progress-bare-niv-bakeliste"> 
                            <div className="progress-effet-bare">
                              <span>{""}</span>
                                </div> 
                              </div>
                            </p>
                          </div>
                          <div className="col-md-4 col-lg-4">
                          <p><span className="txt-suivi-coach">Autonomie: </span><div className="progress-bare-niv-bakeliste"> 
                            <div className="progress-effet-bare">
                              <span>{""}</span>
                                </div> 
                              </div>
                            </p>
                          </div>
                      </div>
                      <div className="row mt-3">
                       <div className="col-md-4 col-lg-4 ">
                            <p><span className="txt-suivi-coach">Satisfaction: </span><div className="progress-bare-niv-bakeliste"> 
                            <div className="progress-effet-bare">
                              <span>{""}</span>
                                </div> 
                              </div>
                            </p>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-md-4 col-lg-4">
                            <span className="txt-suivi-coach">Avis du coach</span>
                            </div>
                          <div className="col-md-8 col-lg-8 ">
                            <p className="txt-coach">
                             {bakeliste.commentaire}
                              </p>
                          </div>
                          
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-xl-6 col-lg-6 col-sm-4">
                            <button 
                            className="btn-v-convertir"
                            onClick={this.bakelisteToStage}
                            >
                              Convertir
                            </button>
                          </div>
                          <div className="col-md-6 col-xl-6 col-lg-6 col-sm-4">
                            <Link 
                                to={{
                                  pathname: "/profil-bakeliste",
                                  // state: { id: row.id, data: row},
                                }}
                               >
                              <button className="btn-v-plus">Voir plus</button>
                              </Link>
                          </div>
                          </div>
                      </div>
                    )) }
                   </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ listSuiviBakelisteDakar }) => ({ ...listSuiviBakelisteDakar }),
//     dispatch => bindActionCreators({ ...listSuiviBakelisteDakarActions }, dispatch)
//   )( listSuiviBakelisteDakar );