import React, {Component} from 'react';
import './editBakelisteSuiviView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import qs from "qs";
import EditBakelisteSuivi from '../editBakelisteSuivi/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as editBakelisteSuiviViewActions from "../../store/editBakelisteSuiviView/actions";
export default class editBakelisteSuiviView extends Component {
    // constructor(props) {
    //     super(props);
    //     let id = this.props.location?.state?.id;
    //     // let data = this.props.location?.state.data;  
    //       if (!id && this.props.location.search) {
    //       const obj = qs.parse(this.props.location.search.replace("?", ""));
    //       id = parseInt(obj.id);
    //       }
    //     this.state = {
    //       id: id,
    //       // data:data,
    //     };
    // }
    render() {
      return (
      <div className="component-edit-bakeliste-suivi-view body-theme">
        <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <EditBakelisteSuivi
                      // id={this.state.id}
                      // domaine_id={this.state.bakeliste_domaine_id}
                      // tache={this.state.tache}
                      // nbrSemaine={this.state.nbrSemaine}
                      // coachFullname={this.state.coachFullname}
                      // assiduite={this.state.assiduite}
                      // performance={this.state.performance}
                      // autonomie={this.state.autonomie}
                      // satisfaction={this.state.satisfaction}
                      // first_name={this.state.first_name}
                      // last_name={this.state.last_name}
                      // full_name={this.state.user_fullname}
                      // domaine={this.state.domaine}
                      // lieu_formation={this.state.lieu_formation}
                      // debut_formation={this.state.debut_formation}
                      // fin_formation={this.state.fin_formation}
                      // adresse={this.state.address}
                      // ecole={this.state.ecole}
                      // phone={this.state.phone}
                      // email={this.state.email}
                      // horaire={this.state.horaire}
                      // paiement_inscription={this.paiement_inscription}

                        />
                    </div>
                  </div> 
                </div> 
        </div>
      );
    }
  }
// export default connect(
//     ({ editBakelisteSuiviView }) => ({ ...editBakelisteSuiviView }),
//     dispatch => bindActionCreators({ ...editBakelisteSuiviViewActions }, dispatch)
//   )( editBakelisteSuiviView );