import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { reducer } from "react-redux-toastr";
import { toast } from "react-toastify";
import { history } from "../../../components/history";
import { API } from "../../../variablesGlobales";

const initialState = {
  isLoading: false,
  participants: [],
  visiteurs: [],
  isAdding: false,
};

const API_URL = API;

export const utilisateurSlice = createSlice({
  name: "utilisateur",
  initialState,

  reducers: {
    getParticipants: (state, action) => {
      state.participants = action.payload;
    },
    getVisiteurs: (state, action) => {
      state.visiteurs = action.payload;
    },
  },
});

export const getParticipantsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}partcipants`);
    if (res.data.success) {
      dispatch(getParticipants(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getVisiteursAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}list-visiteurs`);
    if (res.data.success) {
      dispatch(getVisiteurs(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const { getParticipants, getVisiteurs } = utilisateurSlice.actions;
export const showListParticipants = (state) => state.utlisateurs?.participants;
export const showListVisiteurs = (state) => state.utlisateurs?.visiteurs;

export default utilisateurSlice.reducer;
