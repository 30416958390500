import React from "react";
import { Col, Dropdown, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";

import "./bakelisteDash.css";

import BakelisteEnStages from "./BakelisteEnStages";
import DashboardBakelisteAll from "./DashboardBakelisteAll";

import AllBakeliste1 from "./AllBakeliste1";
import AllBakelistePresentiel1 from "./AllBakelistePresentiel1";
import AllBakelisteEnligne1 from "./AllBakelisteEnligne1";
import AllBakelisteAbondonnes1 from "./AllBakelisteAbondonnes1";
import AllBakelisteArchives1 from "./AllBakelisteArchives1";
import BakelisteTermines from "./BakelisteTermines";

import AllBakelisteBloques1 from "./AllBakelisteBloques1";
import AllBakelisteEnPause from "./AllBakelisteEnPause";


const BakelisteNew = () => {



  return (
    <div className="body-theme bakelisteDash">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <div className="p-b-200">
              <div className="col-lg-10 mt-2">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard-admin" className="lien_nav_current">
                        Dashboard
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#" className="lien_nav_current">
                        Dashboard bakeliste
                      </Link>
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row mx-2">
              <DashboardBakelisteAll />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BakelisteNew;
