import React, {Component} from 'react';
import './convertirProspect.css';
import { Link } from "react-router-dom";

import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
// import TableMyParticipants from "../tableMyParticipants/index";
// import rectFolder from "../../img/rectFolder.png";
// import Ellipse1 from "../../img/Ellipse1.png";
import API from "../../variablesGlobales";
import axios from "axios";
// import FeatherIcon from "feather-icons-react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as convertirProspectActions from "../../store/convertirProspect/actions";
export default class convertirProspect extends Component {
    constructor(props) {
        super(props);
        this.state = {
          id : this.props.id,
          api: API.API,
          details:[],
          fields: {},
          _phFields: {},
          errors: {},
          value: "",
          // firstName:"",
          domaines: [],
          loading: false,
          isPro: false,
          isEtudiant: false,
          isFormationCourte: false,
          isFormationDiplomante: false,
          openStatutProfessionnelFC: false,
          openStatutFD: false,
          isSemaine: false,
          requestIsDone: false,
          fn_empty: false,
          ln_empty: false,
          email_empty: false,
          phone_empty: false,
          tf_empty: false,
          boursier_empty: false,
          lf_empty: false,
          df_empty: false,
          paiement_empty: false,
          fs_empty: false,
          ecole_empty: false,
          obj_empty: false,
          sp_empty: false,
          pe_empty: false,
          j1_empty: false,
          j2_empty: false,
          j3_empty: false,
          civility_empty: false,
          ms_empty: false,
          address_empty: false,
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false,
          isExistUser: false,
          isSuccessAddedBakelisteAlert: false,
          level_empty: false,
          domaines:[],
        };
        this.handleChange = this.handleChange.bind(this);
        this.submituserConversionForm = this.submituserConversionForm.bind(
          this
        );
        
    }

    componentDidMount  ()  {
      axios.get(this.state.api + "domaines").then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
      });
      this.getDetailsProspect();
      
    }
    getDetailsProspect = () => {
      axios
        .get(this.state.api + "prospects/" + this.props.id)
        .then((response) => {
          this.setState({
            details: response.data.data,
            // firstName: response.data.data.firstName,
          });
          // console.log("prospects", this.state.details);
        })
        .catch((error) => {
          // console.log(error.message);
          if (process.env.NODE_ENV !== "production") {
            return;
          }
        });
    };

    submituserConversionForm(e) {
      e.preventDefault();
      // console.log("gooooo");
      // if (this.validateForm()) {
        // console.log("hello");
        this.setState({
          loading: true,
        });
      
        // this.setState({ fields: fields, _phFields: _phFields });
        // const dataBakeliste = this.state.fields;
        const dataPHBakeliste = this.state._phFields;
        // console.log(dataBakeliste);
        // console.log(dataPHBakeliste);
        axios
          .put(this.state.api + "validate-bakeliste-self-subscribe/" + this.state.id, dataPHBakeliste)
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              // this.createNewBakelistePH(dataPHBakeliste);
  
              this.setState({
                isSuccessAddedBakelisteAlert: true,
              });
              setTimeout(() => {
                 window.location = "dashboard-admin";
              }, 5000);
              this.setState({
                loading: false,
              });
            } else if (res.data.success === false && res.data.exist === true) {
              this.setState({
                loading: false,
                isExistUser: true,
              });
              this.handleShowAndHideAlert("isExistUser");
            } else {
              // console.log(res);
              this.setState({
                loading: false,
              });
            }
          })
          .catch((error) => {
            // console.log(error.message);
            this.setState({
              loading: false,
            });
          });
      
    }

    handleChange(e) {
      let fields = this.state.fields;
      let _phFields = this.state._phFields;
  
      fields[e.target.name] = e.target.value;
      _phFields[e.target.name] = e.target.value;
      _phFields["boursier"] = 0;
      _phFields["demi_boursier"] = 0;
      _phFields["objectifs"] = this.state.details.motivations; 


      // console.log("handle change", e.target.value);
      if (e.target.name === "bakeliste_domaine_id") {
        this.state.domaines.forEach((x) => {
          if (x.name === e.target.value) {
            this.setState({
              idSelect: x.id,
            });
          }
        });
        fields[e.target.name] = this.state.idSelect;
      }
      if (e.target.name === "type_formation") {
        fields[e.target.name] = e.target.value;
        if (e.target.value === "courte") {
          _phFields["jour_fd"] = "";
          this.setState({
            openStatutProfessionnelFC: true,
            openStatutFD: false,
          });
        } else if (e.target.value === "diplomante") {
          _phFields["nbre_jours"] = 5;
          _phFields["is_etudiant"] = 1;
          _phFields["is_week"] = 1;
          _phFields["is_weekend"] = 0;
          _phFields["jour_fd"] = "lundi au vendredi";
          _phFields["jour_1"] = "Lundi";
          _phFields["jour_2"] = "Mardi";
          _phFields["jour_3"] = "Mercredi";
          _phFields["jour_4"] = "Jeudi";
          _phFields["jour_5"] = "Vendredi";
          _phFields["jour_6"] = "";
          this.setState({
            openStatutProfessionnelFC: false,
            openStatutFD: true,
            isSemaine: false,
            isPro: false,
            isEtudiant: false,
          });
        }
      }
      if (e.target.name === "isproetudiant") {
        if (e.target.value === "professionnel") {
          _phFields["is_pro"] = 1;
          _phFields["is_etudiant"] = 0;
          this.setState({
            isPro: true,
            isEtudiant: false,
            isEtudiantSemaineChoice: false,
            isEtudiantSemaineLSChoice: false,
          });
        } else if (e.target.value === "etudiant") {
          _phFields["is_etudiant_a_tester"] = 0;
          _phFields["is_etudiant"] = 1;
          _phFields["is_pro"] = 0;
          _phFields["nbre_jours"] = 3;
          _phFields["jour_4"] = "";
          _phFields["jour_5"] = "";
          _phFields["jour_6"] = "";
          this.setState({
            isPro: false,
            isEtudiant: true,
            isSemaine: false,
          });
        } else if (e.target.value === "a_tester") {
          _phFields["is_etudiant_a_tester"] = 1;
          _phFields["is_etudiant"] = 1;
          _phFields["is_pro"] = 0;
          _phFields["nbre_jours"] = 3;
          _phFields["jour_4"] = "";
          _phFields["jour_5"] = "";
          _phFields["jour_6"] = "";
          this.setState({
            isPro: false,
            isEtudiant: true,
            isSemaine: false,
          });
        }
      }
      if (e.target.name === "isWE") {
        if (e.target.value === "semaine") {
          _phFields["is_etudiant"] = 0;
          _phFields["is_week"] = 1;
          _phFields["is_weekend"] = 0;
          _phFields["nbre_jours"] = 2;
          _phFields["horaire"] = "17h30-19h30";
          _phFields["jour_3"] = "";
          _phFields["jour_4"] = "";
          _phFields["jour_5"] = "";
          _phFields["jour_6"] = "";
          this.setState({
            isSemaine: true,
          });
        } else if (e.target.value === "weekend") {
          _phFields["is_weekend"] = 1;
          _phFields["is_week"] = 0;
          _phFields["horaire"] = "9h-13h";
          _phFields["nbre_jours"] = 1;
          _phFields["jour_1"] = "Samedi";
          _phFields["jour_2"] = "";
          _phFields["jour_3"] = "";
          _phFields["jour_4"] = "";
          _phFields["jour_5"] = "";
          _phFields["jour_6"] = "";
          this.setState({
            isSemaine: false,
          });
        }
        if (e.target.name === "email") {
          _phFields["email"] = e.target.value;
        }
      }
      if (e.target.name === "isWEE") {
        if (e.target.value === "semaine") {
          _phFields["is_etudiant"] = 1;
          _phFields["is_week"] = 1;
          _phFields["is_weekend"] = 0;
          _phFields["nbre_jours"] = 3;
          _phFields["jour_4"] = "";
          _phFields["jour_5"] = "";
          _phFields["jour_6"] = "";
          this.setState({
            isEtudiantSemaineChoice: true,
            isEtudiantSemaineLSChoice: false,
          });
        } else if (e.target.value === "weekend") {
          _phFields["is_weekend"] = 1;
  
          _phFields["is_week"] = 0;
          _phFields["horaire"] = "9h-17h";
          _phFields["nbre_jours"] = 2;
          _phFields["jour_1"] = "Samedi";
          _phFields["jour_2"] = "Dimanche";
          _phFields["jour_3"] = "";
          _phFields["jour_4"] = "";
          _phFields["jour_5"] = "";
          _phFields["jour_6"] = "";
          this.setState({
            isEtudiantSemaineChoice: false,
            isEtudiantSemaineLSChoice: false,
          });
        } else if (e.target.value === "semainels") {
          _phFields["is_etudiant"] = 1;
          _phFields["is_week"] = 1;
          _phFields["is_weekend"] = 0;
          _phFields["nbre_jours"] = 5;
          _phFields["jour_1"] = "Lundi";
          _phFields["jour_2"] = "Mardi";
          _phFields["jour_3"] = "Mercredi";
          _phFields["jour_4"] = "Jeudi";
          _phFields["jour_5"] = "Vendredi";
          _phFields["jour_6"] = "";
          // console.log(_phFields);
  
          this.setState({
            isEtudiantSemaineChoice: false,
            isEtudiantSemaineLSChoice: true,
          });
        }
      }
      this.setState({
        fields,
        _phFields,
      });
      // console.log("handle change", this.state.fields)
    }
    render() {
      
      // console.log(this.state.id, "iddddd")
      return (
        <div className="component-my-participants">
          <div className="container-fluid px-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav">
                    Dashboard {" "}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/liste-prospect" className="lien_nav">
                    Prospects /{" "}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  className="lien_nav_current"
                >
                  {" "}
                  conversion{" "}
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-lg-9">
              {/* <div className="col-xl-6 col-lg-6 col-md-6">
                <h6 className="h-theme ff-inter fw-600 px-3">
                  Convertir ce prospect à un bakeliste
                </h6>
              </div> */}
           
                



              <form className="form1" onSubmit={(e) => this.submituserConversionForm(e)}> 
                <div className="form-row row">
                  <div>
                    {/* <h1 id="titreCoach">Ajouter un Bakeliste</h1> */}
                    <h1 className="h-theme titreCoach">Convertir ce prospect à un bakeliste</h1>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Civilité</label>
                      <select
                        name="civility"
                        className="form-control ab1 "
                        id="civilityInput"
                        value={this.state.fields.civility}
                        onChange={this.handleChange}
                      >
                        <option> --Choisir un statut -- </option>
                        <option value="Monsieur"> Monsieur </option>
                        <option value="Madame"> Madame </option>
                        <option value="Mademoiselle"> Mademoiselle </option>
                      </select>
                      {this.state.civility_empty && (
                        <div className="errorMsg">
                          {this.state.errors.civility}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Situation matrimoniale</label>
                      <select
                        name="marital_status"
                        className="form-control ab1"
                        id="marital_statusInput"
                        value={this.state.fields.marital_status}
                        onChange={this.handleChange}
                      >
                        <option> --Choisir un statut -- </option>
                        <option value="Marié(e)"> Marié(e) </option>
                        <option value="Celibataire"> Célibataire </option>
                      </select>
                      {this.state.ms_empty && (
                        <div className="errorMsg">
                          {this.state.errors.marital_status}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Prénom:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        id="inputpFirstName"
                        // placeholder="Prénom"
                        name="firstName"
                        value={this.state.details.firstName}
                        onChange={this.handleChange}
                      />
                      {/* {this.state.fn_empty && (
                        <div className="errorMsg">
                          {this.state.errors.firstName}
                        </div>
                      )} */}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Nom:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        id="inputLastName"
                        placeholder="Nom"
                        name="lastName"
                        value={this.state.details.lastName}
                        onChange={this.handleChange}
                      />

                      {this.state.ln_empty && (
                        <div className="errorMsg">
                          {this.state.errors.lastName}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Email:</label>
                      <input
                        type="Email"
                        className="form-control ab1"
                        id="inputEmail"
                        placeholder="Email"
                        name="email"
                        value={this.state.details.email}
                        onChange={this.handleChange}
                      />

                      {this.state.email_empty && (
                        <div className="errorMsg">
                          {this.state.errors.email}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6"> 
                    <div className="form-group ">
                      <label>Téléphone:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        id="inputNumero"
                        placeholder="Téléphone"
                        name="phone"
                        onChange={this.handleChange}
                        value={this.state.details.phone}

                      />

                      {this.state.phone_empty && (
                        <div className="errorMsg">
                          {this.state.errors.phone}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Adresse:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        id="inputAdresse"
                        placeholder="Adresse du bakeliste"
                        name="address"
                        
                        onChange={this.handleChange}
                      />

                      {this.state.address_empty && (
                        <div className="errorMsg">
                          {this.state.errors.address}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Formation suivie:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        id="inputformationsuivie"
                        placeholder="Formation suivie"
                        name="formation_suivie"
                        
                        onChange={this.handleChange}
                      />

                      {this.state.fs_empty && (
                        <div className="errorMsg">
                          {this.state.errors.formation_suivie}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Ecole:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        id="inputEcole"
                        placeholder="Ecole"
                        name="ecole"
                        onChange={this.handleChange}
                      />

                      {this.state.ecole_empty && (
                        <div className="errorMsg">
                          {this.state.errors.ecole}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Diplôme obtenu</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        id="inputEcole"
                        placeholder="Diplôme"
                        name="ecole"
                        onChange={this.handleChange}
                      />

                      {this.state.ecole_empty && (
                        <div className="errorMsg">
                          {this.state.errors.ecole}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group conditionnal-input">
                      <label>Formation:</label> <br />
                      {this.state.details.type_formation === "courte" ? (
                        <>
                      <input
                        type="radio"
                        className="radio-bsi"
                        name="type_formation"
                        value={this.state.details.type_formation}
                        // onChange={this.handleChange}
                        checked
                      />
                      <span>Formation courte</span> <br />
                      <input
                        type="radio"
                        className="radio-bsi"
                        name="type_formation"
                        value={this.state.details.type_formation}
                        onChange={this.handleChange}
                      />
                      <span>Formation diplômante</span>*{" "}
                      
                     </> ):( 
                       <>
                       <input
                        type="radio"
                        className="radio-bsi"
                        name="type_formation"
                        value={this.state.details.type_formation}
                        // onChange={this.handleChange}
                        
                      />
                      <span>Formation courte</span> <br />
                      <input
                        type="radio"
                        className="radio-bsi"
                        name="type_formation"
                        value={this.state.details.type_formation}
                        onChange={this.handleChange}
                        checked
                      />
                      <span>Formation diplômante</span>*{" "}
                      
                     </> )}
                     {this.state.tf_empty && (
                        <div className="errorMsg">
                          {this.state.errors.type_formation}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <label>Objectifs:</label>
                      <textarea
                        className="form-control ab1"
                        id="inputObjectifs"
                        placeholder="Objectifs"
                        name="objectifs"
                        value={this.state.details.motivations}
                        
                        onChange={this.handleChange}
                      ></textarea>
                      {this.state.obj_empty && (
                        <div className="errorMsg">
                          {this.state.errors.objectifs}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  {/* {this.state.details.ispro === true ? (
                    <>
                  {this.state.openStatutProfessionnelFC && ( */}
                    <div className="pro_etudiant_container col-md-12">
                      <div className="form-group">
                         {this.state.details.ispro === true ? (
                           <>
                        <label>Statut professionnel</label> <br />
                        
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="professionnel"
                          checked
                          onChange={this.handleChange}
                        />
                        <span>Professionnel</span> <br />

                          <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="etudiant"
                          onChange={this.handleChange}
                        />
                        <span>Etudiant</span> <br />
                      
                        </>):(
                          <>
                        <label>Statut professionnel</label> <br />

                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="professionnel"
                          onChange={this.handleChange}
                        />
                        <span>Professionnel</span> <br />
                        
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isproetudiant"
                          value="etudiant"
                          onChange={this.handleChange}
                          checked
                        />
                        <span>Etudiant</span> <br />
                        
                        </>
                        )}
                        

                       
                        {this.state.sp_empty && (
                          <div className="errorMsg">
                            {this.state.errors.statut_professionnel}
                          </div>
                        )}
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
 
                  {/* )}
                  </>
                  ):( */}
                    {/* <> */}

                    {this.state.details.ispro === true ? (
                      <>
                        {/*  {this.state.isEtudiant && ( */}
                    <div className="etudiant_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Type de présence : </label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semaine"
                          onChange={this.handleChange}
                        />
                        <span>Semaine (3 jours de présence )</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="weekend"
                          onChange={this.handleChange}
                        />
                        <span>Week-End (Samedi et Dimanche de 9h à 17h)</span>{" "}
                        <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semainels"
                          onChange={this.handleChange}
                        />
                        <span>Semaine (Du Lundi au Vendredi )</span>
                        {this.state.pe_empty && (
                          <div className="errorMsg">
                            {this.state.errors.pro_etudiant}
                          </div>
                        )}
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                   {/* )} */}
                      </>
                    ):(
<>
{/* {this.state.isEtudiant && ( */}
                    <div className="etudiant_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Type de présence : </label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semaine"
                          onChange={this.handleChange}
                        />
                        <span>Semaine (3 jours de présence )</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="weekend"
                          onChange={this.handleChange}
                        />
                        <span>Week-End (Samedi et Dimanche de 9h à 17h)</span>{" "}
                        <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semainels"
                          onChange={this.handleChange}
                        />
                        <span>Semaine (Du Lundi au Vendredi )</span>
                        {this.state.pe_empty && (
                          <div className="errorMsg">
                            {this.state.errors.pro_etudiant}
                          </div>
                        )}
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                  {/* )} */}
</>
                    )}


                  {this.state.isEtudiant && (
                    <div className="etudiant_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Type de présence : </label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semaine"
                          onChange={this.handleChange}
                        />
                        <span>Semaine (3 jours de présence )</span> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="weekend"
                          onChange={this.handleChange}
                        />
                        <span>Week-End (Samedi et Dimanche de 9h à 17h)</span>{" "}
                        <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWEE"
                          value="semainels"
                          onChange={this.handleChange}
                        />
                        <span>Semaine (Du Lundi au Vendredi )</span>
                        {this.state.pe_empty && (
                          <div className="errorMsg">
                            {this.state.errors.pro_etudiant}
                          </div>
                        )}
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                  )}
                {/* </>
                )} */}
                  {this.state.isEtudiantSemaineLSChoice && (
                    <div className="etudiant_jhp_container col-md-12">
                      <div className="form-group col-md-6">
                        <label>Choix horaire :</label>
                        <select
                          className="form-control"
                          id="horaire"
                          name="horaire"
                          onChange={this.handleChange}
                        >
                          <option> --Choisir un horaire-- </option>
                          <option value="8h-17h">8h - 17h</option>
                          <option value="8h-12h30">8h - 12h30</option>
                          <option value="12h30-17h">12h30 - 17h</option>
                        </select>

                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.isEtudiantSemaineChoice && (
                    <div className="etudiant_jhp_container">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix horaire :</label>
                          <select
                            className="form-control ab1"
                            id="horaire"
                            name="horaire"
                            onChange={this.handleChange} 
                          >
                            <option> --Choisir un horaire-- </option>
                            <option value="8h-17h">8h - 17h</option>
                            <option value="8h-12h30">8h - 12h30</option>
                            <option value="12h30-17h">12h30 - 17h</option>
                          </select>
                          <div class="line-box">
                            <div class="line"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 1:</label>
                          <select
                            className="form-control"
                            id="jour_1"
                            name="jour_1"
                            onChange={this.handleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>

                          {this.state.j1_empty && (
                            <div className="errorMsg">
                              {this.state.errors.jour_1}
                            </div>
                          )}
                          <div class="line-box">
                            <div class="line"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 2:</label>
                          <select
                            className="form-control ab1"
                            id="jour_2"
                            name="jour_2"
                            onChange={this.handleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>

                          {this.state.j2_empty && (
                            <div className="errorMsg">
                              {this.state.errors.jour_2}
                            </div>
                          )}
                          <div class="line-box">
                            <div class="line"></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 3:</label>
                          <select
                            className="form-control ab1"
                            id="jour_3"
                            name="jour_3"
                            onChange={this.handleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                            <option value="Samedi">Samedi</option>
                          </select>

                          {this.state.j3_empty && (
                            <div className="errorMsg">
                              {this.state.errors.jour_3}
                            </div>
                          )}
                          <div class="line-box">
                            <div class="line"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.isPro && (
                    <div className="pro_jhp_container col-md-12">
                      <div className="form-group">
                        <label>Type de présence : </label> <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWE"
                          value="semaine"
                          onChange={this.handleChange}
                        />
                        <span>
                          Semaine (2 jours de présence de 17h30 à 19h30)
                        </span>{" "}
                        <br />
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="isWE"
                          value="weekend"
                          onChange={this.handleChange}
                        />
                        <span>Week-End (Samedi de 9h à 13h)</span>
                        {this.state.pe_empty && (
                          <div className="errorMsg">
                            {this.state.errors.pro_etudiant}
                          </div>
                        )}
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.isSemaine && (
                    <div className="jour_presence_container">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 1:</label>
                          <select
                            className="form-control ab1"
                            id="jour_1"
                            name="jour_1"
                            onChange={this.handleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                          </select>

                          {this.state.j1_empty && (
                            <div className="errorMsg">
                              {this.state.errors.jour_1}
                            </div>
                          )}
                          <div class="line-box">
                            <div class="line"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Choix Jour 2:</label>
                          <select
                            className="form-control ab1"
                            id="jour_1"
                            name="jour_2"
                            onChange={this.handleChange}
                          >
                            <option> --Choisir un jour-- </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                          </select>
                          {this.state.j2_empty && (
                            <div className="errorMsg">
                              {this.state.errors.jour_2}
                            </div>
                          )}
                          <div class="line-box">
                            <div class="line"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.openStatutFD && (
                    <div className="fd_choice_container col-md-12">
                      <div className="form-group">
                        <label>Choix horaire : (Du Lundi au Vendredi) </label>
                        <select
                          className="form-control ab1"
                          id="horaire"
                          name="horaire"
                          onChange={this.handleChange}
                        >
                          <option> --Choisir un horaire-- </option>
                          <option value="8h-12h30">8h - 12h30</option>
                          <option value="12h30-17h">12h30 - 17h</option>
                        </select>
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Formation payante :</label>
                      <select
                        className="form-control ab1"
                        id="inputFormationP"
                        name="type_formation"
                        onChange={this.handleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Stage de formation :</label>
                      <select
                        className="form-control ab1"
                        id="inputSFormation"
                        name="is_stagede_formation"
                        onChange={this.handleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Lieu de formation:</label>
                      <select
                        className="form-control ab1"
                        id="inputlieu_formation"
                        name="lieu_formation"
                        onChange={this.handleChange}
                      >
                        <option> --Choisir un lieu-- </option>
                        <option value="Sicap Foire">Sicap Foire</option>
                        <option value="Grand Yoff 1">Grand Yoff 1</option>
                        <option value="Grand Yoff 2">Grand Yoff 2</option>
                        <option value="Thiés">Thiés</option>
                        <option value="Guinée">Labé (Guinée)</option>
                      </select>
                      {this.state.lf_empty && (
                        <div className="errorMsg">
                          {this.state.errors.lieu_formation}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Domaine de formation:</label>
                      <select
                        className="form-control ab1"
                        id="inputbakeli_domaine_id"
                        name="bakeliste_domaine_id"
                        onChange={this.handleChange}
                      >
                        <option> --Choisir un domaine-- </option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>

                      {this.state.df_empty && (
                        <div className="errorMsg">
                          {this.state.errors.domaine_formation}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Niveau par rapport au domaine choisi :</label>
                      <select
                        className="form-control ab1"
                        id="inputpaiement_inscriptionvg"
                        name="bakeliste_level"
                        onChange={this.handleChange}
                      >
                        <option> --Choisir un niveau-- </option>
                        <option value={"débutant"}>Débutant</option>
                        <option value={"intermédiaire"}>Intermédiaire</option>
                        <option value={"avancé"}>Avancé</option>
                      </select>

                      {this.state.level_empty && (
                        <div className="errorMsg">
                          {this.state.errors.bakeliste_level}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Paiement inscription :</label>
                      <select
                        className="form-control ab1"
                        id="inputSFormationvg"
                        name="paiement_inscription"
                        onChange={this.handleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>OUI</option>
                        <option value={0}>NON</option>
                      </select>

                      {this.state.paiement_empty && (
                        <div className="errorMsg">
                          {this.state.errors.paiement_inscription}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Durée de formation:</label>
                      <select
                        className="form-control ab1"
                        id="inputSFormationvg"
                        name="Durée de formation"
                        onChange={this.handleChange}
                      >
                        <option> --Choisir une option-- </option>
                        <option value={1}>3 mois</option>
                        <option value={0}>6 mois</option>
                        <option value={0}>12 mois</option>
                        <option value={0}>24 mois</option>
                      </select>

                      {this.state.paiement_empty && (
                        <div className="errorMsg">
                          {this.state.errors.paiement_inscription}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Date de début:</label>
                      <input
                        type="date"
                        className="form-control ab1"
                        id="inputdebut_formation"
                        name="debut_formation"
                        onChange={this.handleChange}
                      />
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label>Date de fin:</label>
                      <input
                        type="date"
                        className="form-control ab1"
                        id="inputfin_formation"
                        name="fin_formation"
                        onChange={this.handleChange}
                      />
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group ">
                      <label>Commentaire:</label>
                      <textarea
                        className="form-control ab1"
                        id="inputCommentaire"
                        placeholder="Ajouter un commentaire"
                        name="commentaire"
                        onChange={this.handleChange}
                      ></textarea>

                      {this.state.obj_empty && (
                        <div className="errorMsg">
                          {this.state.errors.commentaire}
                        </div>
                      )}
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  {/* {this.state.isSuccessAddedBakelisteAlert && (
                    <div className="col-md-12 add_success_alerty_container">
                      <div className="form-group alerty-group">
                        <div className="alert alert-success add_success_alerty text-center">
                          Le bakeliste a été bien enregistré. Vous allez être
                          redirigé dans 5 secondes ...
                        </div>
                      </div>
                    </div>
                  )} */}
                  <div className="col-md-4 col-md-offset-2 submit-btn-container">
                    <div className="form-group ">
                      {!this.state.loading ? (
                        <button className="btn btn-theme">
                          <i className="fas fa-plus-circle"></i> &nbsp; Transformer
                        </button>
                      ) : (
                        <button className="btn btn-theme loading-btn">
                          Transformation en cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
           
             
            </div>
  
            <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
// export default connect(
//     ({ convertirProspect }) => ({ ...convertirProspect }),
//     dispatch => bindActionCreators({ ...convertirProspectActions }, dispatch)
//   )( convertirProspect );