import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { history } from "../../../components/history";
import { API } from "../../../variablesGlobales";

const initialState = { 
  isLoading: false,
  allEvents: [],
  recentEvents: [],
  event: [],
  addingEvent: false,
  eventDetails: [],
  joiningEvent: false,
};

const API_URL = API;

export const eventSlice = createSlice({
  name: "listAllEvents",
  initialState,

  reducers: {
    isFetchingEvent: (state) => {
      state.isLoading = true;
    },

    getAllEvents: (state, action) => {
      state.allEvents = action.payload;
    },
    getAllRecentEvents: (state, action) => {
      state.recentEvents = action.payload;
    },
    isFetchingDoneEvent: (state) => {
      state.isLoading = false;
    },
    isFetchingRecentEvent: (state) => {
      state.isLoading = false;
    },
    getEvent: (state, action) => {
      state.event = action.payload;
    },

    isAddingEvent: (state) => {
      state.addingEvent = true;
    },

    isAddingEventDone: (state) => {
      state.addingEvent = false;
    },

    isJoiningEvent: (state) => {
      state.joiningEvent = true;
    },

    isJoiningEventDone: (state) => {
      state.joiningEvent = false;
    },

    getEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
  },
});

export const getAllEventsAsync = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}get-coming-rencontres`);
    if (res.data.success) {
      dispatch(isFetchingDoneEvent());
      dispatch(getAllEvents(res.data.data));
    }
  } catch (err) { 
    throw new Error(err);
  }
};
export const getRecentRencontreAsync = () => async (dispatch) => {
  
  try {
    const res = await axios.get(`${API_URL}get-recent-rencontres`);
    // console.log(res);
    if (res.data.success) {
      dispatch(isFetchingRecentEvent());
      dispatch(getAllRecentEvents(res.data.data));
    }
  } catch (err) { 
    throw new Error(err);
  }
};
export const getEventsAsync = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}rencontres/${id}`);
    if (res.data.success) {
      // dispatch(isFetchingDoneEvent());
      dispatch(getEvent(res.data.data));
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getEventDetailsAsync = (eventId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}rencontres/${eventId}`);
    if (res.data.success) {
      dispatch(getEventDetails(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const archiveEvent = (id) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}archived_rencontre/${id}`);
    if (res.data.success) {
      toast.success(
        "rencontre archivée avec success",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(getAllEventsAsync());
    } else {
      toast.error(
        "erreur sur l'archivage",
        { position: toast.POSITION.BOTTOM_LEFT },
        { autoClose: 1000 }
      );
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const updateEventAsync = (id, formaData) => async (dispatch) => {
  const response = await axios.post(`${API_URL}updated_event/${id}`, formaData);
  if (response.data.success) {
    toast.success(
      "rencontre modifiée avec success",
      { position: toast.POSITION.TOP_RIGHT },
      { autoClose: 1000 }
    );
    dispatch(getAllEventsAsync());
    history.push("/evenements");
  } else {
    toast.error(
      "erreur sur la modification!!!",
      { position: toast.POSITION.TOP_RIGHT },
      { autoClose: 1000 }
    );
  }
};

// Create events
export const createEventAsync = (fd) => async (dispatch) => {
  dispatch(isAddingEvent());
  setTimeout(() => {
    try {
      axios.post(`${API_URL}add-rencontre`, fd).then((res) => {
        if (res.data.success) {
          dispatch(isAddingEventDone());
          history.push("/evenements");
        }
      });
    } catch (error) {
      dispatch(isAddingEventDone());
      console.log(error);
    }
  }, 3000);
};

export const joinEvent = (fd, resetStates) => async (dispatch) => {
  //   const id = toast.loading("Envoi en cours...");
  dispatch(isJoiningEvent());
  setTimeout(() => {
    try {
      axios.post(`${API_URL}joinRencontreForm`, fd).then((res) => {
        if (res.data.success) {
          dispatch(isJoiningEventDone());
          resetStates();
          toast.success(
            "Inscription effectuée avec success",
            { position: toast.POSITION.TOP_RIGHT },
            { autoClose: 1000 }
          );
          setTimeout(() => {
            window.location.replace("https://network.bakeli.tech/");
          }, 1300);
        }
      });
    } catch (error) {
      toast.error(
        "Erreur lors du l'inscription",
        { position: toast.POSITION.TOP_RIGHT },
        { autoClose: 1000 }
      );
      dispatch(isJoiningEventDone());
      console.log(error);
    }
  }, 1000);
};

export const {
  isFetchingEvent,
  getAllEvents,
  getAllRecentEvents,
  isFetchingDoneEvent,
  isFetchingRecentEvent,
  getEvent,
  getEventDetails,
  isAddingEvent,
  isAddingEventDone,
  isJoiningEvent,
  isJoiningEventDone,
} = eventSlice.actions;
export const isLoadingEvent = (state) => state.listAllEvents.isLoading;
export const showListEvents = (state) => state.listAllEvents.allEvents;
export const showRecentEvents = (state) => state.listAllEvents.recentEvents;
export const eventDetails = (state) => state.listAllEvents?.eventDetails;
export const showtEvent = (state) => state.listAllEvents.event;
export const addingEvent = (state) => state.listAllEvents?.addingEvent;
export const joiningEvent = (state) => state.listAllEvents?.joiningEvent;

export default eventSlice.reducer;
