import React from "react";
import { Route, Router } from "react-router-dom";
import AccountCoachView from "./components/accountCoachView/index";
import AnnoncesAdminView from "./components/annoncesAdminView/index";
import ArticlesAdminView from "./components/articlesAdminView/index";
import BakelistesAdminView from "./components/bakelistesAdminView/index";
import Connexion from "./components/connexion/index";
import ControleAdminView from "./components/controleAdminView/index";
import DashboardAdminView from "./components/dashboardAdminView/index";
import DashboardCoachView from "./components/dashboardCoachView/index";
import FormationAdminView from "./components/formationAdminView/index";
import GroupeCoachView from "./components/groupeCoachView/index";
import ListProspectsView from "./components/listProspectsView/index";
import LivrableCoachView from "./components/livrableCoachView";
import LivrablesAdminView from "./components/livrablesAdminView/index";
import NotificationExemples from "./components/notificationPage/index";
import ParametresAdminView from "./components/parametresAdminView/index";
import PointageAdminView from "./components/pointageAdminView/index";
import SyllabusCoachView from "./components/syllabusCoachView/index";
import TacheCoachView from "./components/tacheCoachView/index";
import UsersAdminView from "./components/usersAdminView/index";
// import ListeDomainesAdminView from "./components/listeDomainesAdminView/index";
import CreneauxHorairesAdminView from "./components/creneauxHorairesAdminView/index";
import DiplomeAdminView from "./components/diplomeAdminView/index";
import IntermediaireAdminView from "./components/intermediaireAdminView/index";
import ListeFillieresAdminView from "./components/listeFillieresAdminView/index";
import ReportingAdminView from "./components/reportingAdminView/index";
import RiAdminView from "./components/riAdminView/index";
// import DomaineAdminView from "./components/domaineAdminView/index";
import TechnologieAdminView from "./components/technologieAdminView/index";
// import SyllabusAdminView from "./components/syllabusAdminView/index";
import AllListeAbsencesView from "./components/allListeAbsencesView/index";
import AllListeRetardView from "./components/allListeRetardView/index";
import CoachAdminView from "./components/coachAdminView/index";
import ListeAllPointageView from "./components/listeAllPointageView/index";
import ListeBakelisteDakarView from "./components/listeBakelisteDakarView/index";
import ListeBakelisteLabeView from "./components/listeBakelisteLabeView/index";
import ListeBakelisteThiesView from "./components/listeBakelisteThiesView/index";
import MyUserManagerView from "./components/myUserManagerView/index";
import RessetPassword from "./components/ressetPassword/index";
import TableMyAllbakelistesView from "./components/tableMyAllbakelistesView/index";
import TableMyBfTerminerView from "./components/tableMyBfTerminerView/index";
import TableMyParticipantsView from "./components/tableMyParticipantsView/index";
import TableMyVisiteursView from "./components/tableMyVisiteursView/index";

import AjouterbakelisteView from "./components/ajouterBakelisteView/index";
import CourtesView from "./components/courtesView/index";
import CreateEventView from "./components/createEventView/index";
import DiplomanteView from "./components/diplomanteView/index";
import EditBakelisteView from "./components/editBakelisteView/index";
import EventView from "./components/eventView/index";
import ListeEventView from "./components/listeEventView/index";
import NavTabsProfilBakelisteView from "./components/navTabsProfilBakelisteView/index";
import OrganiserReunionView from "./components/organiserReunionView/index";
import {
  default as passwordReset,
  default as PasswordReset,
} from "./components/passwordReset/index";
import ProfilAdminView from "./components/profilAdminView/index";
import ProfilVisiteurView from "./components/profilVisiteurView/index";
import ProposEventView from "./components/proposEventView/index";
import StatistiqueAdminView from "./components/statistiqueAdminView/index";
import TableSuiviBakelistes from "./components/tableSuiviBakelistes/index";
import VisiteursAdminView from "./components/visiteursAdminView/index";

//import PasswordReset from "./components/passwordReset/index";
// import AjouterbakelisteView from "./components/ajouterBakelisteView/index";
// import TableSuiviBakelistes from "./components/tableSuiviBakelistes/index";
// import EditBakelisteView from "./components/editBakelisteView/index";
import AjoutCoach from "./components/ajoutCoach/index";
import AjoutCoachAssistantView from "./components/ajoutCoachAssistantView/index";
import AjoutGroupe from "./components/ajoutGroupe/index";
import AjoutTacheSyllabus from "./components/ajoutTacheSyllabus/index";
import BakelisteSuiviDBView from "./components/bakelisteSuiviDBView/bakelisteSuiviDBView";
import CardSuiviBakelisteView from "./components/cardSuiviBakelisteView/index";
import CoachArchiveView from "./components/coachArchiveView/index";
import CoachAssistantView from "./components/coachAssistantView/index";
import ConvertirProspectView from "./components/convertirProspectView/index";
import CreateDomaineView from "./components/createDomaineView/index";
import CvBakelistesView from "./components/cvBakelistesView";
import DakarView from "./components/dakarView/index";
import DetailsCoachView from "./components/detailsCoachView/index";
import DetailsStagiaireView from "./components/detailsStagiaireView/index";
import DetailsUsersView from "./components/detailsUsersView/index";
import DossierCandidatureView from "./components/dossierCandidatureView/index";
import EditBakelisteSuiviView from "./components/editBakelisteSuiviView/index";
import EditDomaineView from "./components/editDomaineView/index";
import editerCoach from "./components/editerCoach/index";
import editerStagiaire from "./components/editerStagiaire/index";
import EditEvent from "./components/editEvent/index";
import FicheBakelisteAdmin from "./components/ficheBakelisteAdmin/index";
import FormeView from "./components/formeView/index";
import LabeView from "./components/labeView/index";
import ListeParticipantsView from "./components/listeParticipantsView/index";
import ProfilBakeliste from "./components/profilBakeliste";
import RecrutablesView from "./components/recrutablesView/index";
import StageView from "./components/stageView/index";
import StagiaireView from "./components/stagiaireView/index";
import SyllabusDetailsView from "./components/syllabusDetailsView/index";
import ThiesView from "./components/thiesView/index";

import AdminArchiveView from "./components/adminArchiveView/index";
import AjoutAdmin from "./components/ajoutAdmin/AjoutAdminRefac";
import AjoutCoachRefact from "./components/ajoutCoach/AjoutCoachRefact";
import AjoutGroupeRefact from "./components/ajoutGroupe/AjoutGroupeRefact";
import AjoutSousGroupe from "./components/ajoutSousGroupe/AjoutSousGroupe";
import BakelisteNew from "./components/bakelisteNew/BakelisteNew";
import DashboardBakelisteAll from "./components/bakelisteNew/DashboardBakelisteAll";
import BakelisteSuivziDBViewRefact from "./components/bakelisteSuiviDBView/BakelisteSuivziDBViewRefact";
import CoachAssistantViewRefact from "./components/coachAssistantView/CoachAssistantViewRefact";
import CreateEventViewRefac from "./components/createEventView/CreateEventViewRefac";
import DashboardAdminViewRefac from "./components/dashboardAdminView/DashboardAdminViewRefac";
import DetailsEventView from "./components/detailsEvents/DetailsEventView";
import DossierCandidatureViewRefac from "./components/dossierCandidatureView/DossierCandidatureViewRefac";
import EditBakelisteViewRefact from "./components/editBakelisteView/EditBakelisteViewRefact";
// import EditerCoachRefact from "./components/editerCoach/EditerCoachRefact";
import EditerCoachRefact from "./components/editerCoach/EditerCoachRefact";
import EditerStagiaireRefact from "./components/editerStagiaire/EditerStagiaireRefact";
import EditEventRefact from "./components/editEvent/EditEventRefact";
import EventViewRefact from "./components/eventView/EventViewRefact";
import { history } from "./components/history";
import ListeAdminView from "./components/listeAdminView/ListeAdminView";
import ListeProspectsViewRefac from "./components/listProspectsView/ListeProspectsViewRefac";
import NavTabsProfilBakelisteViewRefact from "./components/navTabsProfilBakelisteView/NavTabsProfilBakelisteViewRefact";
import ParametresAdminViewRefact from "./components/parametresAdminView/ParametresAdminViewRefact";
// import AjoutCoachRefact from "./components/ajoutCoach/AjoutCoachRefact";
// import AjoutGroupeRefact from "./components/ajoutGroupe/AjoutGroupeRefact";
// import AjoutSousGroupe from "./components/ajoutSousGroupe/AjoutSousGroupe";
// import BakelisteNew from "./components/bakelisteNew/BakelisteNew";
// import DashboardBakelisteAll from "./components/bakelisteNew/DashboardBakelisteAll";
import AjoutBakelisteViewRefact from "./components/ajouterBakelisteView/AjoutBakelisteViewRefact";
import AllBakeliste from "./components/bakelisteNew/AllBakeliste";
import AllBakelisteAbondonnesView from "./components/bakelisteNew/AllBakelisteAbondonnesView";
import AllBakelisteArchivesView from "./components/bakelisteNew/AllBakelisteArchivesView";
import AllBakelisteBloquesView from "./components/bakelisteNew/AllBakelisteBloquesView";
import AllBakelisteEnligneView from "./components/bakelisteNew/AllBakelisteEnligneView";
import AllBakelisteEnlPauseView from "./components/bakelisteNew/AllBakelisteEnlPauseView ";
import AllBakelisteNewView from "./components/bakelisteNew/AllBakelisteNewView";
import AllBakelistePresentielView from "./components/bakelisteNew/AllBakelistePresentielView";
import AllBakelisteStagesView from "./components/bakelisteNew/AllBakelisteStagesView";
import AllBakelisteTerminesView from "./components/bakelisteNew/AllBakelisteTerminesView";
import ConvertirProspectViewRefac from "./components/convertirProspectView/ConvertirProspectViewRefac";
import DetailsBakelisteViewRefac from "./components/detailsBakelisteRefac/DetailsBakelisteViewRefac";
import DetailsBakelisteEnligneView from "./components/DetailsDuBakeliste/DetailsBakelisteEnligneView";
import DetailsBakelistePresentielView from "./components/DetailsDuBakeliste/DetailsBakelistePresentielView";
import EventParticipateView from "./components/eventsParticpate/EventParticipateView";
import RecrutableViewRefact from "./components/recrutablesView/RecrutableViewRefact";
import StagiaireViewRefact from "./components/stagiaireView/StagiaireViewRefact";
import UsersAdminViewRefac from "./components/usersAdminView/UsersAdminViewRefac";
// import Connexion from './components/connexion/index';
// import DashboardBakelisteView from './components/dashboardBakelisteView/index';
// import TacheBakelisteView from './components/tacheBakelisteView/index';
// import LivrableBakelisteView from './components/livrableBakelisteView';
// import GroupeBakelisteView from './components/groupeBakelisteView/index';
// import DashboardCoachView from './components/dashboardCoachView/index';
// import TacheCoachView from './components/tacheCoachView/index';
// import LivrableCoachView from './components/livrableCoachView';
// import GroupeCoachView from './components/groupeCoachView/index';
// import SyllabusCoachView from './components/syllabusCoachView/index';
// import SyllabusDetailsCoachView from './components/syllabusDetailsCoachView/index';
// import SyllabusBakelisteView from './components/syllabusBakelisteView/index';
// import NotificationExemples from './components/notificationPage/index';
// import AccountBakelisteView from './components/accountBakelisteView/index';
// import AccountCoachView from './components/accountCoachView/index';
// import SyllabusDetailsBakelisteView from './components/syllabusDetailsBakelisteView/index';

// import DashboardAdminView from './components/dashboardAdminView/index';
// import ControleAdminView from './components/controleAdminView/index';
// import FormationAdminView from './components/formationAdminView/index';
// import BakelistesAdminView from './components/bakelistesAdminView/index';
// import UsersAdminView from './components/usersAdminView/index';
// import LivrablesAdminView from './components/livrablesAdminView/index';
// import ArticlesAdminView from './components/articlesAdminView/index';
// import AnnoncesAdminView from './components/annoncesAdminView/index';
// import PointageAdminView from './components/pointageAdminView/index';
// import ParametresAdminView from './components/parametresAdminView/index';
// import ListeDomainesAdminView from './components/listeDomainesAdminView/index';
// import ListeFillieresAdminView from './components/listeFillieresAdminView/index';
// import CreneauxHorairesAdminView from './components/creneauxHorairesAdminView/index';
// import RiAdminView from './components/riAdminView/index';
// import ReportingAdminView from './components/reportingAdminView/index';
// import IntermediaireAdminView from './components/intermediaireAdminView/index';
// import DiplomeAdminView from './components/diplomeAdminView/index';
// import DomaineAdminView from './components/domaineAdminView/index';
// import TechnologieAdminView from './components/technologieAdminView/index';
// import SyllabusAdminView from './components/syllabusAdminView/index';
// import CoachAdminView from './components/coachAdminView/index';
// import TableMyAllbakelistesView from './components/tableMyAllbakelistesView/index';
// import TableMyParticipantsView from './components/tableMyParticipantsView/index';
// import TableMyBfTerminerView from './components/tableMyBfTerminerView/index';
// import TableMyVisiteursView from './components/tableMyVisiteursView/index';
// import MyUserManagerView from "./components/myUserManagerView/index";
// import ListeBakelisteThiesView from "./components/listeBakelisteThiesView/index";
// import ListeBakelisteDakarView from "./components/listeBakelisteDakarView/index";
// import ListeBakelisteLabeView from "./components/listeBakelisteLabeView/index";
// import ListeAllPointageView from "./components/listeAllPointageView/index";
// import EventView from "./components/eventView/index";
// import StatistiqueAdminView from "./components/statistiqueAdminView/index";
// import RessetPassword from './components/ressetPassword/index';
// // import ViewEditBakelist from './components/viewEditBakelist/index';
// import PasswordReset from './components/passwordReset/index';
// import VisiteursAdminView from "./components/visiteursAdminView/index";

function App() {
  return (
    <Router history={history}>
      <div>
        <Route exact path="/" component={Connexion} />

        {/* <Route path="/syllabus-bakeliste" component={SyllabusBakelisteView} /> */}
        <Route path="/reinitialiser-mot-de-passe" component={RessetPassword} />
        <Route path="/modifier-mot-de-passe" component={passwordReset} />
        <Route exact path="/suivi-bakeliste" component={TableSuiviBakelistes} />
        <Route path="/bakelistes-à-dakar" component={ListeBakelisteDakarView} />
        <Route path="/edit-event" component={EditEventRefact} />
        <Route path="/dashboard-coach" component={DashboardCoachView} />
        <Route path="/tache-coach" component={TacheCoachView} />
        <Route path="/livraison-coach" component={LivrableCoachView} />
        <Route path="/groupe-coach" component={GroupeCoachView} />
        <Route path="/syllabus-coach" component={SyllabusCoachView} />
        <Route path="/details-syllabus" component={SyllabusDetailsView} />
        {/* <Route
          path="/details-syllabus"
          component={SyllabusDetailsView}
        /> */}
        <Route path="/ajout-tache-syllabus" component={AjoutTacheSyllabus} />
        <Route path="/details-syllabus" component={SyllabusDetailsView} />
        <Route path="/ajout-tache-syllabus" component={AjoutTacheSyllabus} />
        {/* <Route path="/edit-bakeliste" component={EditBakelisteView} /> */}
        <Route path="/notification-exemples" component={NotificationExemples} />
        <Route path="/ajouter-bakeliste" component={AjoutBakelisteViewRefact} />
        <Route path="/ajouter-domaines" component={CreateDomaineView} />

        <Route path="/formations-courtes" component={CourtesView} />
        {/* <Route path="/dashboard-admin" component={DashboardAdminView} /> */}
        <Route path="/dashboard-admin" component={DashboardAdminViewRefac} />
        <Route path="/statistique-admin" component={StatistiqueAdminView} />
        {/* <Route path="/syllabus-admin" component={SyllabusAdminView}/> */}
        <Route path="/bakelistes-admin" component={BakelistesAdminView} />
        {/* <Route path="/users-admin" component={UsersAdminView} /> */}
        <Route path="/users-admin" component={UsersAdminViewRefac} />

        <Route path="/stagiaires" component={StagiaireViewRefact} />
        <Route
          path="/liste-bakelistes-recrutables"
          component={RecrutableViewRefact}
        />
        <Route path="/all-bakelistes-new" component={AllBakelisteNewView} />
        <Route
          path="/all-bakelistes-enligne"
          component={AllBakelisteEnligneView}
        />
        <Route
          path="/all-bakelistes-bloques"
          component={AllBakelisteBloquesView}
        />
        <Route
          path="/all-bakelistes-stage"
          component={AllBakelisteStagesView}
        />
        <Route
          path="/all-bakelistes-termines"
          component={AllBakelisteTerminesView}
        />
        <Route
          path="/all-bakelistes-pause"
          component={AllBakelisteEnlPauseView}
        />
        <Route
          path="/all-bakelistes-presentiel"
          component={AllBakelistePresentielView}
        />
        <Route
          path="/all-bakelistes-abondonnes"
          component={AllBakelisteAbondonnesView}
        />
        <Route
          path="/all-bakelistes-archives"
          component={AllBakelisteArchivesView}
        />
        {/* <Route path="/show-presentiel-bakeliste" component={DetailsBakelistePresentielView} /> */}
        <Route
          path="/show-presentiel-bakeliste"
          component={DetailsBakelisteViewRefac}
        />

        {/* <Route
          path="/show-enligne-bakeliste"
          component={DetailsBakelisteEnligneView}
        /> */}
        <Route
          path="/show-enligne-bakeliste"
          component={DetailsBakelisteViewRefac}
        />

        <Route path="/livrables-admin" component={LivrablesAdminView} />
        <Route path="/articles-admin" component={ArticlesAdminView} />
        <Route path="/annonces-admin" component={AnnoncesAdminView} />
        <Route path="/pointage-admin" component={PointageAdminView} />
        {/* <Route path="/listeDomainesAdmin" component={ListeDomainesAdminView} /> */}
        <Route path="/visiteurs-folder" component={VisiteursAdminView} />
        {/* <Route
          path="/listeFillieresAdmin"
          component={ListeFillieresAdminView}
        />{" "} */}
        {/* <Route
          path="/creneauxHorairesAdmin"
          component={CreneauxHorairesAdminView}
        /> */}
        {/* <Route path="/riAdmin" component={RiAdminView} /> */}
        <Route path="/intermediaire-admin" component={IntermediaireAdminView} />
        {/* <Route
          path="/controle_admin/reporting-admin"
          component={ReportingAdminView}
        /> */}
        <Route path="/diplome-admin" component={DiplomeAdminView} />
        {/* <Route path="/domaine-admin" component={DomaineAdminView} /> */}
        {/* <Route path="/technologie-admin" component={TechnologieAdminView} /> */}
        {/* <Route path="/syllabus-admin" component={SyllabusAdminView} /> */}
        <Route path="/liste-coachs" component={CoachAdminView} />
        <Route path="/liste-admins" component={ListeAdminView} />
        <Route path="/liste-admins-archives" component={AdminArchiveView} />

        <Route
          path="/liste-coach-assistants"
          component={CoachAssistantViewRefact}
        />
        <Route path="/liste-coachs-archives" component={CoachArchiveView} />

        {/* <Route path="/all-bakelistes-admin" component={TableMyAllbakelistesView} /> */}
        <Route path="/participants-admin" component={TableMyParticipantsView} />
        {/* <Route path="/liste-prospect" component={ListProspectsView} /> */}
        <Route path="/liste-prospect" component={ListeProspectsViewRefac} />
        {/* <Route
          path="/dossier-de-candidature-prospect"
          component={DossierCandidatureView} 
        /> */}

        <Route
          path="/dossier-de-candidature-prospect/:id"
          component={DossierCandidatureViewRefac}
        />

        <Route path="/show-bakeliste-cv" component={CvBakelistesView} />
        <Route path="/bf-bakelistes-admin" component={TableMyBfTerminerView} />
        <Route path="/liste-visiteur" component={TableMyVisiteursView} />
        <Route path="/user-menager-admin" component={MyUserManagerView} />
        {/* <Route path="/bakelistes-admin-dakar" component={ListeBakelisteDakarView} /> */}
        <Route path="/bakelistes-à-thies" component={ListeBakelisteThiesView} />
        <Route path="/bakelistes-à-labe" component={ListeBakelisteLabeView} />
        <Route path="/listeAllPointage" component={ListeAllPointageView} />
        <Route path="/liste-absences" component={AllListeAbsencesView} />
        <Route path="/liste-retards" component={AllListeRetardView} />
        <Route path="/formation-diplomante" component={DiplomanteView} />
        <Route path="/edit-bakeliste" component={EditBakelisteViewRefact} />
        <Route path="/edit-domaine/:id" component={EditDomaineView} />

        <Route
          path="/edit-suivi-bakeliste"
          component={EditBakelisteSuiviView}
        />
        <Route path="/compte-coach" component={AccountCoachView} />
        {/* <Route path="/ajouter-bakeliste" component={AjouterbakelisteView} /> */}
        {/* <Route path="/dashboard-admin" component={DashboardAdminView} /> */}
        <Route path="/controle-admin" component={ControleAdminView} />
        <Route path="/formation-admin" component={FormationAdminView} />
        <Route path="/parametres-admin" component={ParametresAdminViewRefact} />
        <Route path="/fiche-bakeliste" component={FicheBakelisteAdmin} />
        <Route path="/ajout-coach" component={AjoutCoachRefact} />
        <Route path="/ajout-admin" component={AjoutAdmin} />
        <Route
          path="/ajout-coach-assistant"
          component={AjoutCoachAssistantView}
        />
        <Route path="/ajout-groupe" component={AjoutGroupeRefact} />
        <Route path="/ajout-sous-groupe" component={AjoutSousGroupe} />
        <Route
          path="/all-bakelistes-admin"
          component={TableMyAllbakelistesView}
        />
        <Route path="/visiteurs-admin" component={TableMyVisiteursView} />
        <Route path="/bakelistes-db" component={BakelisteSuivziDBViewRefact} />
        <Route path="/bakelistes_new" component={BakelisteNew} />
        {/* <Route patth="/bakeliste_dash" component={DashboardBakelisteAll} /> */}
        <Route
          path="/bakelistes-admin-dakar"
          component={ListeBakelisteDakarView}
        />
        <Route
          path="/bakelistes-admin-thies"
          component={ListeBakelisteThiesView}
        />
        <Route
          path="/bakelistes-admin-labe"
          component={ListeBakelisteLabeView}
        />
        <Route
          path="/liste-des-bakelistes-en-formation"
          component={NavTabsProfilBakelisteViewRefact}
        />
        <Route path="/evenements" component={EventViewRefact} />
        <Route path="/details/event/:id" component={DetailsEventView} />

        {/* Participate to event */}
        <Route path="/join-event/:id" component={EventParticipateView} />

        {/* <Route path="/create-event" component={CreateEventView} /> */}
        <Route path="/create-event" component={CreateEventViewRefac} />

        {/* <Route path="/liste-des-evenements" component={ListeEventView} /> */}
        <Route path="/a-propos-des-evenements" component={ProposEventView} />
        <Route path="/profil-admin" component={ProfilAdminView} />
        <Route path="/organiser-reunion" component={OrganiserReunionView} />
        <Route path="/profil-visiteur" component={ProfilVisiteurView} />
        <Route path="/profil-utilisateur" component={DetailsUsersView} />
        <Route path="/profil-coach" component={DetailsCoachView} />
        <Route path="/profil-stagiaire" component={DetailsStagiaireView} />
        <Route path="/edit-coach" component={EditerCoachRefact} />
        <Route path="/edit-stagiare" component={EditerStagiaireRefact} />

        <Route path="/profil-bakeliste" component={ProfilBakeliste} />
        {/* <Route
          path="/convertir-prospect-a-bakeliste"
          component={ConvertirProspectView}
        /> */}

        <Route
          path="/convertir-prospect-a-bakeliste"
          component={ConvertirProspectViewRefac}
        />

        {/* <Route path="/visiteurs-folder" component={VisiteursAdminView} /> */}
        {/* <Route
          path="/liste-des-bakelistes-en-formation"
          component={NavTabsProfilBakelisteView}
        /> */}
        <Route
          path="/suivi-bakeliste-version-deux"
          component={CardSuiviBakelisteView}
        />
        <Route
          path="/liste-des-participants-evenements"
          component={ListeParticipantsView}
        />
        <Route path="/stage" component={StageView} />
        <Route path="/thies" component={ThiesView} />
        <Route path="/dakar" component={DakarView} />
        <Route path="/labe" component={LabeView} />
        <Route path="formé-G" component={FormeView} />
      </div>
    </Router>
  );
}

export default App;
