import axios from "axios";
import React from "react";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/plugin/weekOfYear";
import { AiOutlineUserAdd } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import AdminDashboardChart from '../adminDashboardChart/AdminDashboardChart';
import { Link } from "react-router-dom";
import {
  bakelisteActifsDataStats,
  bakelisteDataStats,
  commentsByMonth,
  day,
  fetchingAllStats,
  fetchingUserData,
  getAllStatsAsync,
  getUsersDataStatsAsync,
  isFetchingUserData,
  livraisonsByMonth,
  loadingStats,
  month,
  userDataStats,
  week,
} from "../../redux/slices/adminSlices/adminSlice";

import {
  AllCoachAssistantFeedback,
  coachAssistantFeedback,
  dailyIdentifier,
  getAllCoachCommentStatsAsync,
  getCoachsCommentStatsAsync,
  monthlyIdentifier,
  setCommentIdentifier,
  weeklyIdentifier,
} from "../../redux/slices/coachAssistantSlice/coachAssistantSlice";
import { API } from "../../variablesGlobales";
import "./dashboardNewAdmin.css";

/*
****************************************************************
================= Statistiques Components ======================
****************************************************************
*/

const AdminUserStats = (props) => {
  const {
    users,
    bakelistes,
    visiteurs,
    coachs,
    coachAssistant,
    admins,
    commentaires,
    livraisons,
    validations,
  } = props;

  return (
    <SkeletonTheme baseColor="#e9e9e9" highlightColor="rgb(210, 210, 210)">
      <div className="row bannerSection mb-2 mx-3">
        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card total-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{users}</h3>
                    <span>Total</span>
                  </div>
                  <div className="align-self-center">
                    <HiUserGroup className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card bakelistes-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{bakelistes}</h3>
                    <span>Bakelistes</span>
                  </div>
                  <div className="align-self-center">
                    <IoIosPeople className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card visiteurs-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{visiteurs}</h3>
                    <span>Visiteurs</span>
                  </div>
                  <div className="align-self-center">
                    <HiUserGroup className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card coachs-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{coachs}</h3>
                    <span>Coachs</span>
                  </div>
                  <div className="align-self-center">
                    <HiUserGroup className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card coach-assistants-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{coachAssistant}</h3>
                    <span>Coachs-Assistants</span>
                  </div>
                  <div className="align-self-center">
                    <HiUserGroup className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card admins-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{admins}</h3>
                    <span>Admins</span>
                  </div>
                  <div className="align-self-center">
                    <HiUserGroup className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card coach-assistants-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{commentaires}</h3>
                    <span>Commentaires</span>
                  </div>
                  <div className="align-self-center">
                    <HiUserGroup className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card bakelistes-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{livraisons}</h3>
                    <span>Livraisons</span>
                  </div>
                  <div className="align-self-center">
                    <HiUserGroup className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-4 col-sm-6 col-12 px-1 my-1">
          <div className="card admins-card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h3 className="warning">{validations}</h3>
                    <span>Validations</span>
                  </div>
                  <div className="align-self-center">
                    <HiUserGroup className="card-body__Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

// AdminLocationStats
var weekOfYear = require("dayjs/plugin/weekOfYear");
var toObject = require("dayjs/plugin/toObject");

// Components here

const Dashboard = () => {
  // Days js Config
  dayjs.locale("fr");
  dayjs.extend(weekOfYear);
  dayjs.extend(toObject);

  const dispatch = useDispatch();

  //   const coachAssistantRedux = useSelector(showListCoachsAssistant);
  const coachAssistantFeedbackStats = useSelector(coachAssistantFeedback);
  const AllCoachAssistantFeedbackStats = useSelector(AllCoachAssistantFeedback);
  const commentIdentifier = useSelector(setCommentIdentifier);

  React.useEffect(() => {
    // coachAssistantRedux.length === 0 && dispatch(getCoachAssistantAsync());
    dispatch(getAllCoachCommentStatsAsync());
  }, []);

  // Initial States
  const [selectedTime, setSelectedTime] = React.useState("day");

  React.useEffect(() => {
    //   For the comments
    const fd = new FormData();
    fd.append("commentTime", selectedTime);

    if (selectedTime) {
      dispatch(getCoachsCommentStatsAsync(fd, handleCommentIdentifier));
    }
  }, [selectedTime]);

  const handleCommentIdentifier = () => {
    return selectedTime === "day"
      ? dispatch(dailyIdentifier())
      : selectedTime === "week"
      ? dispatch(weeklyIdentifier())
      : selectedTime === "month"
      ? dispatch(monthlyIdentifier())
      : null;
  };

  // COMMENTS CHARTS CONFIG
  const globalColors = ["#009688", "#F44336"];
  var globalSeries = [
    {
      name: "Commentaires",
      data: AllCoachAssistantFeedbackStats.map((item) =>
        item?.comments === undefined ? 0 : item?.comments
      ),
    },
    {
      name: "Validations",
      data: AllCoachAssistantFeedbackStats.map((item) =>
        item?.validations === undefined ? 0 : item?.validations
      ),
    },
  ];

  var globalOptions = {
    chart: {
      type: "bar",
       height: 430,
    },
    colors: globalColors,
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    yaxis: {
      categories: AllCoachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
    },
    xaxis: {
      categories: AllCoachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      display: true,
      text: "Vue d'ensemble des commentaires et validations des coachs",
      align: "center",
    },
    noData: {
      text: "Chargement...",
    },
  };

  // Weekly data

  const weeklyColors = ["#FF9800", "#009688"];

  var weeklySeries = [
    {
      name: "Commentaires",
      data: coachAssistantFeedbackStats.map((item) =>
        item?.comments === undefined ? 0 : item?.comments
      ),
    },
    {
      name: "Validations",
      data: coachAssistantFeedbackStats.map((item) =>
        item?.validations === undefined ? 0 : item?.validations
      ),
    },
  ];

  var weeklyOptions = {
    chart: {
      type: "bar",
      height: 430,
    },
    colors: weeklyColors,
    plotOptions: {
      bar: {
        horizontal: true,
        // distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    yaxis: {
      categories: coachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
    },
    xaxis: {
      categories: coachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      display: true,
      text: "Statistiques hebdomadaire des commentaires et validations des coachs",
      align: "center",
    },
    noData: {
      text: "Chargement...",
    },
  };

  // Monhly data

  const monthlyColors = ["#F44336", "#FF9800"];

  var monthlySeries = [
    {
      name: "Commentaires",
      data: coachAssistantFeedbackStats.map((item) =>
        item?.comments === undefined ? 0 : item?.comments
      ),
      goals: [
        {
          name: "Expected",
          value: 52,
          strokeColor: "#775DD0",
        },
      ],
    },
    {
      name: "Validations",
      data: coachAssistantFeedbackStats.map((item) =>
        item?.validations === undefined ? 0 : item?.validations
      ),
    },
  ];

  var monthlyOptions = {
    chart: {
      type: "bar",
      height: 430,
    },

    colors: monthlyColors,
    plotOptions: {
      bar: {
        horizontal: true,
        // distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    yaxis: {
      categories: coachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
    },
    xaxis: {
      categories: coachAssistantFeedbackStats?.map(
        (item) => `${item?.first_name} ${item?.last_name}`
      ),
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      display: true,
      text: "Statistiques mensuel des commentaires et validations des coachs",
      align: "center",
    },
    noData: {
      text: "Chargement...",
    },
  };

  return (
    <div className="mt-3">
      <div className="container-fluid px-0">
        {/* <div className="row"> */} 
        {/* <div className="mx-3 mb-2 allStats"> */}

          <div className="col-md-12 left-pane mx-2">
            <div className="col d-flex align-items-center justify-content-between p-3 customSelectSection">
              <select
                name="period"
                id="period"
                onChange={(e) => setSelectedTime(e.target.value)}
              >
                {/* <option>Periode</option> */}
                <option value="day">Aujourd'hui</option>
                <option value="week">Semaine</option>
                <option value="month">Mois</option>
              </select>
            </div>
            <>
              {commentIdentifier === "global" ||
                (commentIdentifier === "day" && (
                  <Chart
                    options={globalOptions}
                    series={globalSeries}
                    type="bar"
                  />
                ))}
              {/* {commentIdentifier === "day" && (
                <Chart options={dailyOptions} series={dailySeries} type="bar" />
              )} */}
              {commentIdentifier === "week" && (
                <Chart
                  options={weeklyOptions}
                  series={weeklySeries}
                  type="bar"
                />
              )}
              {commentIdentifier === "month" && (
                <Chart
                  options={monthlyOptions}
                  series={monthlySeries}
                  type="bar"
                />
              )}
            </>
          </div>

        {/* </div> */}


         </div> 
       {/* </div> */}
    </div>
  );
};
const AdminLocationStats = (props) => {
  // Props
  const { grandYoff1, grandYoff2, thies, labe, mbour, actifs, inactifs } =
    props;

  // Charts data & config
  const locationLabels = ["Dakar", "Thies", "Labé", "Mbour"];
  const locationColors = ["#009688", "#FF9800", "#F44336", "#3F85FB"];

  const locationOptions = {
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val;
      },
      //   dropShadow: {
      //     ...
      //   }
    },
    colors: locationColors,
    labels: locationLabels,
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: 600,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: "75%",
          background: "transparent",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: false,
              label: "Total",
              fontSize: "22px",
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "#373d3f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {},
      },
    ],
  };

  const locationSeries = [
    grandYoff1 === undefined
      ? 0
      : grandYoff1 + grandYoff2 === undefined
      ? 0
      : grandYoff2,
    thies === undefined ? 0 : thies,
    labe === undefined ? 0 : labe,
    mbour === undefined ? 0 : mbour,
  ];

  // bakelistes stats config

  const bakelistesLabels = ["Bakelistes actifs", "Bakelistes inactifs"];
  const bakelistesColors = ["#009688", "#FF9800"];

  const bakelistesOptions = {
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.ceil(val) + "%";
      },
      //   dropShadow: {
      //     ...
      //   }
    },
    colors: bakelistesColors,
    labels: bakelistesLabels,
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: 600,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    plotOptions: {
      pie: {
        size: 600,
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
      },
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {},
      },
    ],
  };

  const bakelistesSeries = [
    actifs === undefined ? 0 : actifs,
    inactifs === undefined ? 0 : inactifs,
  ];

  return (
    <>
      <div className="row">{/* <h3>Nos écoles de formation</h3> */}</div>
      <div className="row statsSection mb-2 mx-3">
        <div className="col-xl-6 col-md-6 statsSection__left">
          <Chart
            options={locationOptions}
            series={locationSeries}
            type="donut"
            width="100%"
          />
        </div>

        <div className="col-xl-6 col-md-6 statsSection__right">
          <Chart
            options={bakelistesOptions}
            series={bakelistesSeries}
            type="pie"
            width="100%"
          />
        </div>

        {/* <div className="col-xl-6 col-md-6  statsSection__right">
          <div className="row">
            <div className="col">
              <span className="dkr">Dakar</span>
            </div>
            <div className="col">
              <span>
                {grandYoff1 + grandYoff2 === 0 ||
                grandYoff1 + grandYoff2 === undefined
                  ? "0"
                  : grandYoff1 + grandYoff2}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <span className="thies">Thies</span>
            </div>
            <div className="col">
              <span>{thies === 0 || thies === undefined ? "0" : thies}</span>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <span className="labe">labe</span>
            </div>
            <div className="col">
              <span>{labe === 0 || labe === undefined ? "0" : labe}</span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="mbour">Mbour</span>
            </div>
            <div className="col">
              <span>{mbour === 0 || mbour === undefined ? "0" : mbour}</span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

// Stats
const Stats = (props) => {
  const API_URL = API;

  //   const [daily, setDaily] = React.useState();
  //   const [weekly, setWeekly] = React.useState();
  //   const [monthly, setMonthly] = React.useState();
  //   const [yearly, setYearly] = React.useState();

  React.useEffect(() => {
    dispatch(getAllStatsAsync()) && dispatch(fetchingAllStats());
  }, []);

  const dispatch = useDispatch();
  const daily = useSelector(day);
  const weekly = useSelector(week);
  const monthly = useSelector(month);
  const loading = useSelector(loadingStats);

  // Props
  //   const { dailyStats, weeklyStats, monthlyStats, yearlyStats } = props;

  return (
    <div className="mx-3 mb-2 allStats">
      <div className="row bkt_actifs_name_row">
        <div
          style={{ overflowX: "auto", fontSize: "0.9rem !important" }}
          className="col d-flex align-items-center justify-content-between"
        >
          <span style={{ fontSize: "1.5rem" }} className="bkt-actifs">
            Bakelistes actifs
          </span>
          <span style={{ fontSize: "1.5rem" }} className="nbr-actifs">
            Nombres
          </span>
        </div>
      </div>
      <div className="row bkt_actifs_row">
        <div className="col d-flex align-items-center justify-content-between">
          <span className="bkt-actifs_key">Jour</span>
          <span className="bkt-actifs_value">{daily}</span>
        </div>
      </div>

      <div className="row bkt_actifs_row">
        <div className="col d-flex align-items-center justify-content-between">
          <span className="bkt-actifs_key">Semaine</span>
          <span className="bkt-actifs_value">{weekly}</span>
        </div>
      </div>

      <div className="row bkt_actifs_row border-0">
        <div className="col d-flex align-items-center justify-content-between">
          <span className="bkt-actifs_key">Mois</span>
          <span className="bkt-actifs_value">{monthly}</span>
        </div>
      </div>

      {/* <div className="row bkt_actifs_row border-0">
        <div className="col d-flex align-items-center justify-content-between">
          <span className="bkt-actifs_key">Année</span>
          <span className="bkt-actifs_value">not yet</span>
        </div>
      </div> */}
    </div>
  );
};

const DashboardNewAdmin = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userDataStats);
  const livraisonsData = useSelector(livraisonsByMonth);
  const commentsData = useSelector(commentsByMonth);
  const bakelisteData = useSelector(bakelisteDataStats);
  const bakelisteActifsData = useSelector(bakelisteActifsDataStats);
  const isFetching = useSelector(isFetchingUserData);

  // useEffect
  React.useEffect(() => {
    userData.length === 0 &&
      dispatch(getUsersDataStatsAsync()) &&
      dispatch(fetchingUserData());
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <nav>
          <ol className="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
          </ol>
        </nav>


        <div className="row d-flex align-items-center justify-content-end newBkt mb-2 mx-3">
          <Link to="/ajouter-bakeliste">
            <button>
              <AiOutlineUserAdd /> Nouveau Bakeliste
            </button>
          </Link>
        </div>

        {/* Admin users stats */}
 <div className="col-xl-6 col-lg-6 col-md-6">
                  <h3 className="h-theme ff-inter fw-600 px-3 mb-3">
                   KPI
                  </h3>
                </div>

        <AdminUserStats
          users={userData?.allUser}
          bakelistes={userData?.nbreBakeliste}
          visiteurs={userData?.nbreVisiteur}
          coachs={userData?.nbreCoach}
          coachAssistant={userData?.nbreCoachAss}
          admins={userData?.nbreAdmin}
          livraisons={livraisonsData?.total_count}
          commentaires={commentsData?.nbres_commentaires}
          validations={commentsData?.nbres_validations}
        />

        {/* Admin location stats */}
        {/* <AdminLocationStats
          grandYoff1={bakelisteData?.data5}
          grandYoff2={bakelisteData?.data6}
          thies={bakelisteData?.data7}
          actifs={bakelisteActifsData?.bakelistesActifs}
          inactifs={
            userData?.nbreBakelisteChauds -
            bakelisteActifsData?.bakelistesActifs
          }
        /> */}

        {/* All stats */}
        <div className="col-xl-6 col-lg-6 col-md-6">
                  <h3 className="h-theme ff-inter fw-600 px-3 mb-3">
                   Évolution des prospects
                  </h3>
                </div>
        {/* <Dashboard />  */}
        <AdminDashboardChart />
        <div className="col-xl-6 col-lg-6 col-md-6">
                  <h3 className="h-theme ff-inter fw-600 px-3 mb-3">
                   Commentaires et validations des tâches
                  </h3>
                </div>
        <Dashboard /> 
        <div className="col-xl-6 col-lg-6 col-md-6">
                  <h3 className="h-theme ff-inter fw-600 px-3 mb-3">
                   L'activité des bakelistes
                  </h3>
                </div>
        <Stats />
      </div>
    </div>
  );
};

export default DashboardNewAdmin;
