import React, { Component } from "react";
import "./cardSuiviBakeliste.css";
import { NavLink, Link } from "react-router-dom";

import AllCardSuiviBakeliste from "../allCardSuiviBakeliste/index";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import API from "../../variablesGlobales";
import BakelisteEnLigne from "../bakelisteEnLigne/index";
import BakelisteEnPresentiel from "../bakelisteEnPresentiel/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cardSuiviBakelisteActions from "../../store/cardSuiviBakeliste/actions";
export default class cardSuiviBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      api: API.API,
      bakeliste_domaine_id: 0,
      Tache: [],
      bakelistes: [],
      nbrSemaine: 0,
      Assiduite: "",
      Performance: "",
      Autonomie: "",
      Satisfactions: "",
      avisCoach: "",
      loading: true,
      getListeBakelisteRequestError: false,
      bakelistesLabe: [],
      bakelistesThies: [],
      bakelistesDakar: [],
      bakelistesGY2: [],
      // isLoaded: true,
    };
  }

  componentDidMount() {
    this.getListeAllBakelistes();
    this.getListeBakelistesLabe();
    this.getListeBakelistesThies();
    this.getListeBakelistesGY2();
  }

  getListeBakelistesGY2 = () => {
    axios
      .get(this.state.api + "bakeliste-grand-yoff2")
      .then((res) => {
        this.setState({
          bakelistesGY2: res.data.data,
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  getListeBakelistesThies = () => {
    axios
      .get(this.state.api + "list-bakelistes-thies")
      .then((res) => {
        this.setState({
          bakelistesThies: res.data.data,
        });
        // console.log(this.state.bakelistesThies, "bt")
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  getListeBakelistesLabe = () => {
    axios
      .get(this.state.api + "list-bakelistes-labe")
      .then((res) => {
        // console.log(res)
        this.setState({
          bakelistesLabe: res.data.data,
        });
        // console.log(this.state.bakelistesLabe, "bl")
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  getListeAllBakelistes = () => {
    axios
      .get(this.state.api + "liste-tous-les-bakelistes")
      .then((res) => {
        // console.log("All Bakelistes", res.data.data);
        // console.log("res",res);

        this.setState({
          bakelistes: res.data.data,
          // loading: false,
        });
        // console.log(this.state.bakelistes, "gnagna")
        // this.state.bakelistes.map((bakeliste, index) => {
        //   this.setState({
        //     loading: false,
        //   });
        // });
        // this.setState({ isLoaded: false });
        // $("#start-hidden").removeClass("start-hidden");
        // console.log("Bakeliste liste", this.state.bakelistes);
      })
      .catch((error) => {
        this.setState({
          // loading: false,
          // getListeBakelisteRequestError: true,
        });
        // console.log(error.message);
      });
  };

  render() {
    
    return (
      <div className="component-card-suivi-bakeliste">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>

                  <li
                    className="breadcrumb-item active lien_nav"
                    aria-current="page"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="user"
                    />
                    Suivi Bakeliste
                  </li>
                </ol>
              </nav>

              <div className="liste-bak pb-4">
                <span className="liste-bak-all ml-3">
                  Liste de tous les bakelistes
                </span>
              </div>

              <div className="row">
                <ul className="nav nav-pills1" role="tablist">
                  <li className="nav-item1  col-md-3">
                    <a
                      className="nav-link suivi-link-bakelistes active"
                      data-toggle="pill"
                      href="#all"
                    >
                      Bakelistes
                    </a>
                  </li>
                  <li className="nav-item1 col-md-3">
                    <a
                      className="nav-link suivi-link-bakelistes"
                      data-toggle="pill"
                      href="#dakar"
                    >
                      Dakar
                    </a>
                  </li>
                  <li className="nav-item1 col-md-3">
                    <a
                      className="nav-link suivi-link-bakelistes"
                      data-toggle="pill"
                      href="#thies"
                    >
                      Thiès
                    </a>
                  </li>
                  <li className="nav-item1 col-md-3">
                    <a
                      className="nav-link suivi-link-bakelistes"
                      data-toggle="pill"
                      href="#labe"
                    >
                      Labé
                    </a>
                  </li>
                  <li className="nav-item1 col-md-3">
                    <a
                      className="nav-link suivi-link-bakelistes"
                      data-toggle="pill"
                      href="#ligne"
                    >
                      Bakelistes en ligne
                    </a>
                  </li>
                  <li className="nav-item1 col-md-3">
                    <a
                      className="nav-link suivi-link-bakelistes"
                      data-toggle="pill"
                      href="#presentiel"
                    >
                      Bakelistes en présentiel
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div className="row px-lg-0 px-md-2"> */}
              <div className="tab-content">
                {/* <div  className=""> */}
                <div className="row container tab-pane active" id="all">
                  {this.state.bakelistes.length === 0 ? (
                    <p className="pComment">
                      Il n'y a pas encore de bakeliste dans cette localité.
                    </p>
                  ) : (
                    this.state.bakelistes.map((item, index) => (
                      <div
                        key={index}
                        className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 px-md-2"
                      >
                        <AllCardSuiviBakeliste
                          bakelisteFullname={item.bakelisteFullname}
                          domaine={item.domaine}
                          email={item.email}
                          coachFullname={item.coachFullname}
                          commentaire={item.commentaire}
                          id={item.id}
                          getListeAllBakelistes={this.getListeAllBakelistes}
                          is_recruitable={item.is_recruitable}
                          is_online={item.is_online}
                          is_completed={item.is_completed}
                        />
                      </div>
                    ))
                  )}
                </div>
                {/* </div> */}

                <div id="dakar" className="container tab-pane ">
                  <div className="row px-lg-0 px-md-2">
                    {this.state.bakelistesGY2.length > 0 ? (
                      this.state.bakelistesGY2.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 mt-4"
                        >
                          <AllCardSuiviBakeliste
                            bakelisteFullname={item.bakelisteFullname}
                            domaine={item.domaine}
                            email={item.email}
                            coachFullname={item.coachFullname}
                            commentaire={item.commentaire}
                            id={item.id}
                            getListeAllBakelistes={this.getListeBakelistesGY2}
                            is_recruitable={item.is_recruitable}
                            is_online={item.is_online}
                            is_completed={item.is_completed}
                          />
                        </div>
                      ))
                    ) : (
                      <p className="pComment">
                        Il n'y a pas encore de bakeliste dans cette localité.
                      </p>
                    )}
                  </div>
                </div>

                <div id="thies" className="container tab-pane ">
                  <div className="row px-lg-0 px-md-2">
                    {this.state.bakelistesThies.length > 0 ? (
                      this.state.bakelistesThies.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 mt-4"
                        >
                          <AllCardSuiviBakeliste
                            bakelisteFullname={item.bakelisteFullname}
                            domaine={item.domaine}
                            email={item.email}
                            coachFullname={item.coachFullname}
                            commentaire={item.commentaire}
                            id={item.id}
                            getListeAllBakelistes={this.getListeBakelistesThies}
                            is_recruitable={item.is_recruitable}
                            is_online={item.is_online}
                            is_completed={item.is_completed}
                          />
                        </div>
                      ))
                    ) : (
                      <p className="pComment">
                        Il n'y a pas encore de bakeliste dans cette localité.
                      </p>
                    )}
                  </div>
                </div>

                <div id="labe" className="container tab-pane ">
                  <div className="row px-lg-0 px-md-2">
                    {this.state.bakelistesLabe.length > 0 ? (
                      this.state.bakelistesLabe.map((item, index) => (
                        <div
                          key={index}
                          className="col-lg-6 col-md-4 col-sm-6 mb-4 px-lg-3 mt-4"
                        >
                          <AllCardSuiviBakeliste
                            bakelisteFullname={item.bakelisteFullname}
                            domaine={item.domaine}
                            email={item.email}
                            coachFullname={item.coachFullname}
                            commentaire={item.commentaire}
                            id={item.id}
                            getListeAllBakelistes={this.getListeBakelistesLabe}
                            is_recruitable={item.is_recruitable}
                            is_online={item.is_online}
                            is_completed={item.is_completed}
                          />
                        </div>
                      ))
                    ) : (
                      <p className="pComment">
                        Il n'y a pas encore de bakeliste dans cette localité.
                      </p>
                    )}
                  </div>
                </div>
                {/* </div> */}
                <div id="ligne" className="container tab-pane ">
                  <BakelisteEnLigne />
                </div>
                <div id="presentiel" className="container tab-pane ">
                  <BakelisteEnPresentiel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ cardSuiviBakeliste }) => ({ ...cardSuiviBakeliste }),
//     dispatch => bindActionCreators({ ...cardSuiviBakelisteActions }, dispatch)
//   )( cardSuiviBakeliste );
