import React, { Component } from 'react';
import './syllabusFiliéreMarketingDigital.css';
import ImageSyllabus from '../../img/syllabus-Marketing-Digital.png';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ShareTo from '../shareTo/index';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

export default class syllabusFiliéreMarketingDigital extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="container container-syllabus-item-marketingdigital">
    <div className="card-marketing-digital">
      <div className="card-img-overlay-marketing-digital">
        <p className="titre-card-filiéremarketingdigital">Marketing Digital</p>
        <ul>
          <li>Community manager</li>
          <li>Rédacteur de contenus</li>
          <li>Référent Digital</li>
          <li>Chef de projet Digital</li>
        </ul>
      </div>
    </div>
  </div>

  }
}
