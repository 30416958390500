import React, { Component } from "react";
import "./allCardSuiviBakeliste.css";
import Skeleton from "react-loading-skeleton";

export default class cardSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="p-b-200">
        {/* <div className="row mb-3"> */}
        <div className="col-xl-5 col-lg-5 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="style-du-skeleton">
            <Skeleton width={`400%`} height={`40vh`} />
          </div>
        </div>
        {/* <div className="col-xl-5 col-lg-5 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
            <div className="style-du-skeleton">
              <Skeleton width={`100%`} height={`40vh`} />
            </div>
          </div> */}
        {/* </div> */}
      </div>
    );
  }
}
