import React, { Component } from "react";
import "./statisticsEvents.css";
import EventIcon from "@material-ui/icons/Event";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

export default class statisticsEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row m-t-60 m-b-30">
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard ">
            <Link to="/evenements" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.allEvents}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Evènement
                </p>
                <div className="eclipse2">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="home"
                  />{" "}
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
          <div className="item-statistics-dashboard ">
            <Link to="/evenements" className="desactive-decoration">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.participants}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Participants
                </p>
                <div className="eclipse2">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="users"
                  />{" "}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
