import qs from "qs";
import React from "react";
import AjouterBakelisteRefac from "../ajouterBakeliste/AjouterBakelisteRefac";
import AjouterBakelisteRefact from "../ajouterBakeliste/AjouterBakelisteRefact";
import AjouterBakeliste from "../ajouterBakeliste/index";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";

const AjoutBakelisteViewRefact = () => {
  return (
    <div className="component-profil-visiteur-view body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            {/* <AjouterBakelisteRefact /> */}
            <AjouterBakelisteRefac />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjoutBakelisteViewRefact;
