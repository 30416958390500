import React, {Component} from 'react';
import './accountCoach.css';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import MyAccount from '../myAccount/index';

export default class accountCoach extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    return  <div className="p-b-200">
              <div className="row">
                <div className="col-lg-9">
                  <MyAccount/>
                </div>
                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                  <div className="row">
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <BakelisteGroupItemForCoach />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                      <ProgramReunionCoach />
                    </div>
                    <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                      <SavedReunionListItem />
                    </div>
                  </div>
                </div>
              </div>
            </div>;
  }
}
