import React, {Component} from 'react';
import './intermediaireAdminView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import IntermediaireAdmin from '../intermediaireAdmin/index';

export default class intermediaireAdminView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="body-theme">
                <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <IntermediaireAdmin />
                    </div>
                  </div>
                </div>
              </div>;
    }
  }