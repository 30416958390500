import FeatherIcon from "feather-icons-react";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Modal, Uploader } from "rsuite";
import Wagaan from "../../img/wagaan_head.png";
import {
  addAdminCommentAsync,
  domaineInfos,
  getDomaineByIdAsync,
  getProspectsCommentsAsync,
  isGettingCandidatureById,
  isLoadingDomaines,
  loadingComments,
  prospectComments,
} from "../../redux/slices/prospectSlices/prospectSlice";

const DossierCandidatureRefac = ({ dataProps }) => {
  const dispatch = useDispatch();

  const domaineReduxData = useSelector(domaineInfos);
  const isLoading = useSelector(isLoadingDomaines);
  const commentsLoading = useSelector(loadingComments);
  const prospectCommentsData = useSelector(prospectComments);

  const [state, setState] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      show: false,
      showBloc: false,
      fields: {},
    }
  );

  const handleClose = () => setState({ show: false });
  const handleShow = () => setState({ show: true });

  React.useEffect(() => {
    dispatch(getDomaineByIdAsync(dataProps?.study_choice)) &&
      dispatch(isGettingCandidatureById());
  }, []);

  const showComment = (e) => {
    e.preventDefault();
    if (state.showBloc) {
      setState({
        showBloc: false,
      });
    } else {
      setState({
        showBloc: true,
      });
    }
    dispatch(getProspectsCommentsAsync());
  };

  const adminId = sessionStorage.getItem("user_id");

  const handleAdminCommentChange = (e) => {
    let fields = state.fields;
    fields["admin_id"] = adminId;
    fields["prospect_id"] = dataProps?.id;

    fields[e.target.name] = e.target.value;

    setState({
      fields: fields,
    });

    // console.log("fields Data", fields);
  };

  const handleAddAdminComment = (e) => {
    e.preventDefault();
    //   this.setState({
    //     addCommentInProgress: true,
    //   });

    let addData = state.fields;
    dispatch(addAdminCommentAsync(addData));
  };

  const DossierCandidatureSkeleton = () => {
    return (
      <SkeletonTheme baseColor="#e9e9e9" highlightColor="rgb(210, 210, 210)">
        <div className="card-body">
          <div class="card btom-card-dossier-demande-candidature">
            <div class="card-body images-aita-card-center">
              <img src={Wagaan} alt="" />
              <h2>
                <Skeleton width={200} height={15} />
              </h2>
              <span>
                <Skeleton width={150} height={15} />
              </span>
            </div>
          </div>

          <div class="card btom-card-dossier-demande-candidature">
            <div class="card-body">
              <span className="experience-demande-candidature">
                Informations personnelles
              </span>
              <table class="table table-borderless">
                <thead>
                  <tr>
                    {/* <td scope="col">Date de naissance</td> */}
                    {/* <td scope="col">Date de naissance</td> */}
                    <td>Telephone</td>
                    <td>Adresse mail</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <th>{state.formatDate(props.date)}
                                </th> */}
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                  </tr>
                  <tr>
                    <td>Adresse</td>
                    <td>WhatsApp</td>
                    <td>Domaine choisie</td>
                    {/* <td>N° carte d'identité</td> */}
                  </tr>
                  <tr>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card btom-card-dossier-demande-candidature">
            <div class="card-body">
              <span className="experience-demande-candidature">
                Motivations
              </span>
              <p className="text-experience-demande-candidature">
                {/* Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book */}
                {dataProps?.motivations}
              </p>
            </div>
          </div>
          <div class="card btom-card-dossier-demande-candidature">
            <div class="card-body">
              <span className="experience-demande-candidature">Formations</span>
              <br />
              <table class="table table-borderless">
                <thead>
                  <tr>
                    <td>Année</td>
                    <td>Diplôme obtenu</td>
                    <td>Ecole</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <Skeleton />
                    </th>
                    <th>
                      <Skeleton />
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card btom-card-dossier-demande-candidature">
            <div class="card-body"></div>
          </div>
          <div className="row">
            {/*<div className="col-md-6">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            id="annuller-buton-demande"
                          >
                            Supprimer
                          </button>
                        </div>
                        <div className="col-md-6">
                          <Link to="/demande-candidature">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-primary btn-lg"
                              id="annuller-buton-demande"
                            >
                              Modifier
                            </button>
                          </Link>
                        </div> */}
          </div>
        </div>
      </SkeletonTheme>
    );
  };

  return (
    // console.log(dataProps), 
    <div className="p-b-200">
      <div className="row">
        <div className="col-lg-9">
          <div className="row m-b-15">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600">
                Dossier de candidature
              </h6>{" "}
            </div>
            <div className="col dis-flex m-b-15">
              <div className="ml-md-auto btn-comment">
                <button
                  className="btn-add-with-icon-plus-circle"
                  onClick={handleShow}
                >
                  Commentaire
                  <Icon className="icon-add ml-2" icon="send" />
                </button>

                <Modal
                  className="modal-responsive-sm p-b-50"
                  show={state.show}
                  onHide={handleClose}
                >
                  <button
                    className="close-modal-bakeliste-title"
                    onClick={handleClose}
                  >
                    <FeatherIcon icon="x" aria-hidden="true" />
                  </button>
                  <form
                    style={{
                      overflow: "scroll",
                      height: "500px",
                      padding: "30px",
                    }}
                  >
                    <h5 className="modal-bakeliste-title m-b-50">
                      Poster un commentaire
                    </h5>
                    <div className="comments-prospects-container">
                      <div className="toggle-btn-container">
                        <button
                          className="btn-theme w-full fs-13 voir"
                          onClick={(e) => showComment(e)}
                          onHide={handleShow}
                        >
                          Voir les commentaires
                        </button>
                      </div>
                      {state.showBloc ? (
                        <div
                          className="comment-items"
                          style={{ overflow: "scroll", height: "400px" }}
                        >
                          {commentsLoading ? (
                            <div className="loader-container">
                              <div id="loader2"></div>
                              <i>chargement en cours ...</i>
                            </div>
                          ) : prospectCommentsData?.length !== 0 ? (
                            prospectCommentsData?.map((comment, index) => (
                              <div key={index} className="comment-item">
                                <div className="comment-title">
                                  Commentaire {comment.commenterFullname}{" "}
                                  {comment.frenchDateFormat}
                                </div>
                                <div className="comment-content-container">
                                  <p> {comment.comment_content} </p>
                                </div>
                                <hr />
                              </div>
                            ))
                          ) : (
                            <div className="alert alert-warning empty-comment-alert">
                              Aucun commentaire sur ce bakeliste.
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      className="close-modal-bakeliste-title"
                      onClick={handleClose}
                    ></button>
                    <Modal.Body className="max-height-modal pb-0">
                      <div className="form-group form-group-add-syllabus">
                        <textarea
                          name="comment_content"
                          type="text"
                          className="form-control input-add-syllabus"
                          id="nomSyllabus"
                          placeholder="commentaire"
                          onChange={handleAdminCommentChange}
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="m-t-40 dis-flex justify-content-center">
                        <div className="col-sm-5">
                          <button
                            type="button"
                            className="btn-theme w-full fs-13"
                            onClick={(e) => handleAddAdminComment(e)}
                          >
                            {" "}
                            Ajouter un commentaire
                          </button>
                          {/* {!state.addCommentInProgress ? (
                            <button
                              type="button"
                              className="btn-theme w-full fs-13"
                              onClick={(e) => handleAddAdminComment(e)}
                            >
                              {" "}
                              Ajouter un commentaire
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn button addBtn btn_for_wait"
                            >
                              ajout en cours &nbsp;
                              <i className="fas fa-spinner fa-spin"></i>
                            </button>
                          )} */}
                        </div>
                      </div>
                      {state.successCalledAlert === true && (
                        <div className="alert alert-success text-center upsuccess-alert">
                          <i class="fas fa-check-double"></i> &nbsp;&nbsp; Appel
                          déclaré avec succès.
                        </div>
                      )}
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>
            </div>
          </div>

          <div className=" m-b-15 p-b-60">
            <div className="component-dossier-demande-Candidature">
              <div className="card my-1 mt-2 dossier-demande-candidature">
                {isLoading ? (
                  <DossierCandidatureSkeleton />
                ) : (
                  <div className="card-body">
                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body images-aita-card-center">
                        <img src={Wagaan} alt="" />

                        <h2>{`${dataProps?.firstName} ${dataProps?.lastName}`}</h2>
                        {dataProps?.ispro === false ? (
                          <span>Etudiant</span>
                        ) : (
                          <span>Professionnel</span>
                        )}
                      </div>
                    </div>

                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Informations personnelles
                        </span>
                        <table class="table table-borderless">
                          <thead>
                            <tr>
                              {/* <td scope="col">Date de naissance</td> */}
                              
                              <td>Telephone</td>
                              <td>Adresse mail</td>
                              <td>Lieu de formation</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {/* <th>{state.formatDate(props.date)}
                                </th> */}
                              <th>{dataProps?.phone}</th>
                              <th>{dataProps?.email}</th>
                              <th>{dataProps?.session}</th>

                            </tr>
                            <tr>
                              <td>Adresse</td>
                              <td>WhatsApp</td>
                              <td>Domaine choisie</td>
                              <td>TYpe de formation</td>

                              {/* <td>N° carte d'identité</td> */}
                            </tr>
                            <tr>
                              <th>{dataProps?.ville_residence}</th>
                              <th>{dataProps?.phone}</th>
                              <th>{dataProps?.study_choice}</th>
                              <th>{dataProps?.type_formation}</th>

                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Motivations
                        </span>
                        <p className="text-experience-demande-candidature">
                          {/* Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book */}
                          {dataProps?.motivations}
                        </p>
                      </div>
                    </div>
                    {/* <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body">
                        <span className="experience-demande-candidature">
                          Formations
                        </span>
                        <br />
                        <table class="table table-borderless">
                          <thead>
                            <tr>
                               <td>Année</td>
                              <td>Diplôme obtenu</td>
                              <td>Ecole</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th> {domaineReduxData?.name}</th>
                              <th>{dataProps?.type_formation} </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div> */}

                    <div class="card btom-card-dossier-demande-candidature">
                      <div class="card-body"></div>
                    </div>
                    <div className="row">
                      {/*<div className="col-md-6">
                          <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            id="annuller-buton-demande"
                          >
                            Supprimer
                          </button>
                        </div>
                        <div className="col-md-6">
                          <Link to="/demande-candidature">
                            {" "}
                            <button
                              type="button"
                              class="btn btn-primary btn-lg"
                              id="annuller-buton-demande"
                            >
                              Modifier
                            </button>
                          </Link>
                        </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*<div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
                      </div>*/}
      </div>
    </div>
  );
};

export default DossierCandidatureRefac;
