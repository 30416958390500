import FeatherIcon from "feather-icons-react";
import qs from "qs";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import API from "../../variablesGlobales";
import "./editerCoach.css";

import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as editerCoachViewActions from "../../store/editerCoachView/actions";
export default class editerCoachView extends Component {
  constructor(props) {
    super(props);
    let user_id = this.props.location?.state?.id;
    let data = this.props.location?.state?.data;
    if (!user_id && this.props.location.search) {
      const obj = qs.parse(this.props.location.search.replace("?", ""));
      user_id = parseInt(obj.id);
    }
    this.state = {
      user_id: user_id,
      api: API.API,
      data: data,
      userID: window.sessionStorage.getItem("user_id"),
      user: [],
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      phone: data.phone,
      email: data.email,
      ecole: data.ecole,
      domaine: data.domaine,
      domaine_id: data.coach_domaine_id,
      formation_suivie: data.formation_suivie,
      domaines: [],
      id: data.id,
      status: data.status,
    };
    // console.log(this.state.data)
  }

  componentDidMount = () => {
    this.getAllDomaines();
  };
  getAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
        // console.log(this.state.domaines)
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };

  editDetailsCoach = (e) => {
    // console.log("ddddddddd",this.state.bakeliste)
    e.preventDefault();
    this.setState({
      editCoachInProgress: true,
    });
    var fd = new FormData();
    fd.append("first_name", this.state.first_name);
    fd.append("last_name", this.state.last_name);
    fd.append("coach_domaine_id", this.state.domaine_id);
    // fd.append("ecole", this.state.ecole);
    // fd.append("formation_suivie", this.state.formation_suivie);
    fd.append("address", this.state.address);
    fd.append("phone", this.state.phone);
    fd.append("email", this.state.email);

    axios
      .put(this.state.api + "update-coach-infos/" + this.state.id, fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((result) => {
        if (result.data.success) {
          toast.success(
            "Détails coach modiifié avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          this.setState({
            editCoachInProgress: false,
          });

          window.location = "/parametres-admin";
        } else {
          toast.error(
            "Erreur sur la modification",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      });
  };
  annuler = () => {
    window.location = "/parametres-admin";
  };
  render() {
    // console.log(this.state.data)
    return (
      <div className="component-edit-bakeliste-suivi-view body-theme">
        <HeaderDashboardAdmin />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-lg-2 p-0">
              <NavVerticalAdmin />
            </div>
            <div className="col-lg-10 pt-3">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/users-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="users"
                      />
                      Utilisataeurs
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active lien_nav"
                    aria-current="page"
                  >
                    <FeatherIcon
                      className="icon-side-breadcrumb-custom"
                      icon="edit"
                    />
                    Editer détails coach
                  </li>
                </ol>
              </nav>
              <form
                className="form1"
                onSubmit={(e) => this.editDetailsCoach(e)}
              >
                <div>
                  {this.state.status === "coach" && (
                    <h4 className="h-theme titreCoach">
                      Modification des details d'un coach
                    </h4>
                  )}
                  {this.state.status === "coach-assistant" && (
                    <h4 className="h-theme titreCoach">
                      Modification des details d'un coach assistant
                    </h4>
                  )}
                </div>
                <div className="form-row row">
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="taille-txt-elements">Prénom:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        name="first_name"
                        value={this.state.first_name}
                        onChange={(e) =>
                          this.setState({ first_name: e.target.value })
                        }
                      />

                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="taille-txt-elements">
                        Nom de famille:
                      </label>
                      <input
                        type="last_name"
                        className="form-control ab1"
                        name="last_name"
                        value={this.state.last_name}
                        onChange={(e) =>
                          this.setState({ last_name: e.target.value })
                        }
                      />
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="taille-txt-elements">Email:</label>
                      <input
                        type="Email"
                        className="form-control ab1"
                        name="email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="taille-txt-elements">Téléphone:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        name="phone"
                        value={this.state.phone}
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                      />
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="taille-txt-elements">Adresse:</label>
                      <input
                        type="text"
                        className="form-control ab1"
                        name="address"
                        value={this.state.address}
                        onChange={(e) =>
                          this.setState({ address: e.target.value })
                        }
                      />
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                  {/*
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">École:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="ecole"
                      value={this.state.ecole}
                      onChange={(e) => this.setState({ ecole: e.target.value })}
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
                  */}
                  {/* <div className="col-md-6">
                  <div className="form-group ">
                    <label className="taille-txt-elements">Formation suivie:</label>
                    <input
                      type="text"
                      className="form-control ab1"
                      name="ecole"
                      value={this.state.formation_suivie}
                      onChange={(e) => this.setState({ formation_suivie: e.target.value })}
                    />
                    <div class="line-box">
                      <div class="line"></div>
                    </div>
                  </div>
                </div> */}

                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="taille-txt-elements">
                        Domaine de coaching:
                      </label>

                      <select
                        className="form-control ab1"
                        name="coach_domaine"
                        onChange={(e) =>
                          this.setState({ domaine_id: e.target.value })
                        }
                        required
                        value={this.state.domaine_id}
                      >
                        <option>--choisir un domaine--</option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                      <div class="line-box">
                        <div class="line"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    {this.state.editCoachInProgress ? (
                      <button
                        type="submit"
                        className="col-md-5 btn-theme text-up m-t-30 w-full p-t-15 p-b-15 offset-md-6"
                        disabled
                      >
                        Modification en cours &nbsp;
                        <FeatherIcon icon="loader" aria-hidden="true" />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="col-md-5 btn-theme offset-md-6 text-up m-t-30 w-full p-t-15 p-b-15"
                      >
                        MODIFIER
                      </button>
                    )}
                  </div>
                  {/* <div className="col-md-6">
                  <button
                       onClick={(e) => this.annuler(e)}
                      className="col-md-5 btn-warning text-up m-t-30 w-full p-t-15 p-b-15 offset-md-1"
                    >
                      ANNULER
                    </button>
                </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ editerCoachView }) => ({ ...editerCoachView }),
//     dispatch => bindActionCreators({ ...editerCoachViewActions }, dispatch)
//   )( editerCoachView );
