import React, {Component} from 'react';
import './addSousDomaines.css';
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from 'rsuite';
import FeatherIcon from 'feather-icons-react';
import data from '../dataTesting.json';
import API from "../../variablesGlobales";
import axios from 'axios';
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export default class addSousDomaines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      api: API.API,
      loading: false,
      domaines:[],
      domaine_id: "",
      nom: "",
      description: ""
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  componentDidMount = () => {
    this.getAllDomaines();
  }


  getAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
addSousDomaines = (e) => {
  e.preventDefault();
  this.setState({
    loading: true,
  });
  var fd = new FormData();
    fd.append("description", this.state.description);
    fd.append("nom", this.state.nom);
    fd.append("domaine_id", this.state.domaine_id);

axios
.post(this.state.api + "sous_domaines", fd ,{
  Headers: { "Content-Type": "multipart/formdata" },
})
.then((res) => {
  if (res.data.success) {
    this.setState({
      loading: false,
    });
    this.props.getAllSousDomaines();
    $(".close-modal-bakeliste-title").click();
    toast.success(
      "Sous Domaine ajouté avec success",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 1000 }
    );
  } else {
    toast.error(
      "erreur sur l ajout",
      { position: toast.POSITION.BOTTOM_LEFT },
      { autoClose: 3000 }
    );
  }
})
.catch((error) => {
  // console.log(error.message);
  this.setState({
    loading: false,
  });
});
};
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }
  render() {
    return  <div className="modal-container ml-md-auto">
              <button className="btn-add-with-icon-plus-circle" onClick={this.open}>
                Ajouter
                <Icon className="icon-add ml-2" icon="plus-circle" /> 
              </button>
              <Modal className="modal-responsive-sm p-b-50" show={this.state.show} onHide={this.close}>
              <h5 className="modal-bakeliste-title m-b-50">Ajouter un sous-domaine</h5>
              <button className="close-modal-bakeliste-title" onClick={this.close}>
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              <form 
                onSubmit={(e) => this.addSousDomaines(e)}
                id="domaine_form"
                className="mt-5"
                >
                <div className="form-row row">    
                  <div className="col-md-6">
                    <div className="form-group ">
                      <input
                        type="nom"
                        className="form-control ab1 si-form-input"
                        id="inputName"
                        placeholder="Nom"
                        name="nom"                       
                        onChange={(e) =>
                          this.setState({ nom: e.target.value })
                        }
                        required
                      />               
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group"> 
                    <select
                        className="form-control ab1 si-form-input"
                        name="domaine_id"
                        onChange={(e) => this.setState({ domaine_id: e.target.value })}
                        required
                        

                      >
                        <option>--choisir un domaine--</option>
                        {this.state.domaines.map((x, y) => (
                          <option value={x.id} key={y}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                     
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group ">
                      <textarea
                        className="form-control ab1 si-form-input"
                        placeholder="description"
                        name="description"
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
               </div>
               <div className="col-md-6  offset-6 submit-btn-container">
                    <div className="form-group ">
                      {!this.state.loading ? (
                        <button className="btn btn-theme">
                          <i className="fas fa-plus-circle"></i> &nbsp; Ajouter
                        </button>
                      ) : (
                        <button className="btn btn-theme loading-btn">
                          Envoie en cours &nbsp;
                          <i className="fas fa-spin fa-spinner"></i>
                        </button>
                      )}
                    </div>
                  </div>
                  </form>
                
              </Modal>
            </div>;
  }
}
// export default connect(
//     ({ addSyllabus }) => ({ ...addSyllabus }),
//     dispatch => bindActionCreators({ ...addSyllabusActions }, dispatch)
//   )( addSyllabus );