import React, {Component} from 'react';
import './tableMyVisiteursView.css';
import FeatherIcon from 'feather-icons-react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
// import MyVisiteurs from '../myVisiteurs/index';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyVisiteursViewActions from "../../store/tableMyVisiteursView/actions";
export default class tableMyVisiteursView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
            <div className="component-table-my-visiteurs-view body-theme">
               <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      {/* <MyVisiteurs /> */}
                    </div>
                  </div>
                </div>
              </div>
        );
    }
  }
// export default connect(
//     ({ tableMyVisiteursView }) => ({ ...tableMyVisiteursView }),
//     dispatch => bindActionCreators({ ...tableMyVisiteursViewActions }, dispatch)
//   )( tableMyVisiteursView );