import React, { Component } from "react";
import "./cardUtilisateur.css";
import { Link } from "react-router-dom";
import { Tooltip, colors } from "@material-ui/core";
import Hom from "../../img/wagaan_head.png";
import axios from "axios";
import API from "../../variablesGlobales";
import BaseApi from "../../variablesGlobales";

import SweetAlert from "react-bootstrap-sweetalert";
import { Dropdown, Icon, IconButton } from "rsuite";
import { Modal } from "rsuite";
import FeatherIcon from "feather-icons-react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cardUtilisateurActions from "../../store/cardUtilisateur/actions";
export default class cardUtilisateur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      api: API.API,
      base_api: BaseApi.base_api,
      bakeliste_domaine_id: 0,
      Tache: [],
      bakelistes: [],
      nbrSemaine: 0,
      Assiduite: "",
      Performance: "",
      Autonomie: "",
      Satisfactions: "",
      avisCoach: "",
      loading: true,
      getListeBakelisteRequestError: false,
      isLoaded: true,
      bakToSta: [],
      showPause: false,
      showAbandon: false,
      toBlock: false,
      toUnblock: false,
      showEditModal: false,
      size: "md",
      recruiter_raison_sociale: "",
      recruiter_domaine_pro: "",
      toStagiaire: false,
      recruteur_raison: "",
      recruteur_domaine: "",
      setOpen:false,
    };
    // console.log("ccccc",this.props.syllabus)
  }

  componentDidMount() {}

  formatDate = (date) => {
    var d = new Date(date);
    let dated = new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(d);
    return dated;
  };

  recrutable = (id) => {
    // console.log(id);

    var fd = new FormData();
    fd.append("bakeliste_nickname", "expert");
    fd.append("bakeliste_id", id);
    fd.append("label", "recruitable");
    axios
      .post(this.state.api + "change-bakeliste-to-recrutable", fd)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(
            " Ce bakeliste est désormais recrutable",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'action, réessayer!!!!'",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {});
  };
  close = () => {
    this.setState({
      showEditModal: false,
    });
  };
  changeStatusModal = (id) => {
    // this.onGetDomaine(id);
    this.setState({
      showEditModal: true,
    });
  };
  blockUserAccount = () => {
    axios
      .post(this.state.api + "block-user-account/" + this.props.id)
      .then((response) => {
        // console.log(response.data.success);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Compte bloque avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur bloquage compte",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  unblockUserAccount = () => {
    axios
      .post(this.state.api + "unblock-user-account/" + this.props.id)
      .then((response) => {
        // console.log(response.data.success);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Compte debloque avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur debloquage compte",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  changeBakelisteToPresentiel = (id) => {
    axios
      .post(this.state.api + "change-bakeliste-to-presentiel/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Format de formation changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du format de formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  pauseBakelisteTraining = () => {
    var fd = new FormData();
    // console.log(new Date());
    // let date = new Intl.DateTimeFormat("fr-FR", {
    //   year: "numeric",
    //   month: "2-digit",
    //   day: "2-digit",
    // }).format(new Date());

    // fd.append("bakeliste_date_pause", date);
    // fd.append("bakeliste_id", this.props.id);
    fd.append("bakeliste_id", this.props.id);
    // console.log("date", new Date(), this.props.id);

    axios
      .post(this.state.api + "pause-bakeliste-training", fd)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(
            " La formation est mise en pause",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'action, réessayer!!!!'",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {});
  };

  abandonBakelisteTraining = () => {
    var fd = new FormData();
    let date = new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date());
    // console.log(date);
    fd.append("bakeliste_date_abandon", date);
    // fd.append("bakeliste_id", this.props.id);
    fd.append("bakeliste_id", 58);

    axios
      .post(this.state.api + "declarer-bakeliste-abandon", fd)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(
            " Bakeliste declare comme abandonne avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'action, réessayer!!!!'",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {});
  };
  changeBakelisteToPresentiel = (id) => {
    // console.log(id);
    axios
      .post(this.state.api + "change-bakeliste-to-presentiel/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Format de formation changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du format de formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  changeBakelisteToOnline = (id) => {
    // console.log(id);
    axios
      .post(this.state.api + "change-bakeliste-to-online/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Format de formation changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du format de formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  changeStatus = (e) => {
    e.preventDefault();

    this.setState({
      bakToStagiaireInProgress: true,
    });
    var fd = new FormData();
    fd.append("bakeliste_id", this.props.id);
    fd.append("recruteur_raison", this.state.recruteur_raison);
    fd.append("recruteur_domaine", this.state.recruter_domaine);

    // console.log(id);
    axios
      .post(this.state.api + "bakeliste-to-stagiaire", fd)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.setState({
            bakToSta: response.data.data,
            bakToStagiaireInProgress: false,
            showEditModal: false,
          });
          this.props.getListeAllBakelistes();
          toast.success(
            "Status changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du status",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  arreterFormation = (id) => {
    // console.log(id);
    axios
      .post(this.state.api + "completed-bakelistes-formation/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Formation arrêtée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'arrêt du formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  archiverBakeliste = (id) => {
    axios
      .post(this.state.api + "archive-user/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          this.props.getListeAllBakelistes();
          toast.success(
            "Bakeliste archivé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'archivage de ce bakeliste!!!",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  getSyllabusBakeliste = () => {
    return (
      <>
        <div data-toggle="modal" data-target="#syllabusModal">
          <label
            className="cursor"
            data-toggle="tooltip">
          </label>
        </div>
        <div
          className="modal fade"
          id="syllabusModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="syllabusModalListe"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-bakeliste">
              <h5
                className="modal-bakeliste-title m-b-50"
                id="addLivraisonBakelisteTitle"
              >
                Liste des syllabus de 
                 {this.props.bakelisteFullname} 
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              <div className="modal-body row">
                <ul className="character-bullets-3 md-3 0ffset-4">
                  {this.props.syllabus.map((item, index) => {
                    return <li>{item.syllabus.syllabus_name}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div> 
      </>
    );
  };
  editerBakeliste = (id) => {
    window.location="/edit-bakeliste"
  }
  handleOpen = () =>{
this.setState({
 setOpen:true,
})
  };
  closeSyllabus = () =>{
    this.setState({
     setOpen:false,
    })
      }
  render() {
    const id = this.props.id
    var pp = this.state.base_api + this.props.photo;
    return (
      <div className="component-card-utilisateur">
        <div className="card-item first-card-info">
          
          <div className="align-inf-bak">
            {}
            {this.props.photo !== null ? (
              <div className="image-cercle">
                <img src={pp} alt="" />
              </div>
            ) : (
              <div className="image-cercle">
                <img src={Hom} alt="" />
              </div>
            )}

            <div className="pt-2 pl-3">
              <Link className="link-details-container-syllabus-item-back" 
                to={{
                  pathname: '/profil-utilisateur',
                  state: { id: id }, 
                }}
              >
                <p className="fat-ma">
                  <span className="d-flex">{this.props.bakelisteFullname}</span>
                </p>
              <p>
                <span className="pro-mail">{this.props.domaine}</span>
                <span className="d-flex mail-color">{this.props.email}</span>
                <span className="pro-mail">{this.props.phone}</span>
              </p>
              </Link>
            </div>
            <div className="ml-auto ">
              <div class="dropdown ">
                <Icon
                  icon="ellipsis-v"
                  className="icon-dropdown-syllabus-item-tache curseurDrop mr-1 mt-2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Dropdown.Item>
                      <button 
                        className="btn col "  
                        // data-toggle="modal" 
                        // data-target="#syllabusModal"
                        // dataFormat={this.getSyllabusBakeliste}
                        onClick={this.handleOpen}
                      
                      >
                       Voir les syllabus
                      </button>

                      <Modal
                className="modal-responsive-md"
                size={this.state.size}
                show={this.state.setOpen}
                onHide={this.closeSyllabus}
              >
                  <Modal.Header>
                    <Modal.Title className="modal-contact-us">
                <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                Liste des syllabus de 
                 {this.props.bakelisteFullname} 
                      </h6>
                    </Modal.Title>
                  </Modal.Header>
                 
                  <div className="modal-body row">
                <ul className="character-bullets-3 md-3 0ffset-4">
                  {this.props.syllabus.map((item, index) => {
                    return <li>{item.syllabus.syllabus_name}</li>;
                  })}
                </ul>
              </div>
             
              </Modal>
                    </Dropdown.Item>
                  {this.props.is_completed === 1 ? (
                    <Dropdown.Item>
                      <button className="btn col " disabled>
                        formation en arrêt
                      </button>
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item>
                      <button
                        className="btn "
                        onClick={() => this.arreterFormation(this.props.id)}
                      >
                        Arrêter la formation
                      </button>
                    </Dropdown.Item>
                  )}

                  {this.props.is_online === 1 ? (
                    <Dropdown.Item>
                      <button
                        className="btn "
                        onClick={() =>
                          this.changeBakelisteToOnline(this.props.id)
                        }
                      >
                        Mettre en ligne
                      </button>
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item>
                      <button
                        className="btn "
                        onClick={() =>
                          this.changeBakelisteToPresentiel(this.props.id)
                        }
                      >
                        Mettre en présentiel
                      </button>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item>
                    <button
                      className="btn "
                      onClick={() => this.setState({ showAbandon: true })}
                    >
                      Déclarer comme abandonné{" "}
                    </button>
                  </Dropdown.Item>
                  {this.props.is_archived === 0 ? (
                    <Dropdown.Item>
                      <button
                        className="btn "
                        onClick={() => this.archiverBakeliste(this.props.id)}
                      >
                        Archiver
                      </button>
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item>
                      <button className="btn ">Bakeliste Archivé</button>
                    </Dropdown.Item>
                  )}
                </div>
              </div>
            </div>
         
          </div>

          <div className="row">
            {/* <div className="col-md-4 col-lg-4">
              <p>
                <span className="txt-suivi-coach">Tache : </span>
                <span className="comp-fonct">{}</span>
              </p>
            </div> */}
            <div className="col-md-12 col-lg-12 mb-2 ml-2">
              <p>
                <span className="green-color">Coach : </span>
                <span className="comp-fonct"> {this.props.coachFullname} </span>
              </p>
            </div>
            <div className="col-md-6 col-lg-6 mb-3">
              <p>
                <span className="green-color">Debut : </span>
                <span className="comp-fonct">
                  {" "}
                  {this.formatDate(this.props.debut_formation)}
                </span>
              </p>
            </div>
            <div className="col-md-6 col-lg-6 mb-3">
              <p>
                <span className=" green-color">Fin : </span>
                <span className="comp-fonct">
                  {" "}
                  {this.formatDate(this.props.fin_formation)}{" "}
                </span>
              </p>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="row m-2"> 
                <div className="col-md-2">
                  <Tooltip title="Déclarer comme stagiaire">
                    <div class="icon-wrapper">
                      <i
                        className="fas fa-user-graduate custom-icon"
                        onClick={() => this.setState({ toStagiaire: true })}
                      >
                        {" "}
                      </i>
                    </div>
                  </Tooltip>
                </div>

                <Modal
                  className="modal-responsive-md"
                  size={this.state.size}
                  show={this.state.showEditModal}
                  onHide={this.close}
                >
                  <form onSubmit={(e) => this.changeStatus(e)}>
                    <Modal.Header>
                      <Modal.Title className="modal-contact-us">
                        <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                          Changer bakeliste en stagiaire{" "}
                        </h6>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="max-height-modal">
                      <div className="form-group as_input_container">
                        <label>Domaine</label>
                        <input
                          // value={this.state.recruteur_raison}
                          onChange={(e) =>
                            this.setState({ recruteur_domaine: e.target.value })
                          }
                          name="recruteur_domaine"
                          placeholder="Domaine du recruteur"
                          className="form-control input_item"
                          id="name"
                        />
                      </div>
                      <div>
                        <label>Raison social</label>
                        <input
                          // value={this.state.recruteur_raison}
                          onChange={(e) =>
                            this.setState({ recruteur_raison: e.target.value })
                          }
                          name="recruteur_raison"
                          placeholder="Domaine du recruteur"
                          className="form-control input_item"
                          id="name"
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="row dis-flex justify-content-end">
                        <div className="col-md-5 col-sm-6">
                          {this.state.bakToStagiaireInProgress ? (
                            <button
                              type="submit"
                              className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                              disabled
                            >
                              En cours &nbsp;
                              <FeatherIcon icon="loader" aria-hidden="true" />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                            >
                              Valider
                            </button>
                          )}
                        </div>
                      </div>
                    </Modal.Footer>
                  </form>
                </Modal>

                <div className="col-md-2">
                  {this.props.is_recruitable === 1 ? (
                    <Tooltip title="Déja recrutable">
                      <div class="icon-wrapper">
                        <i className="fas fa-user-check custom-icon"> </i>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Déclarer comme recrutable">
                      <div class="icon-wrapper">
                        <i
                          className="fas fa-file-certificate custom-icon-orange"
                          onClick={() => this.recrutable(this.props.id)}
                        >
                          {" "}
                        </i>
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className="col-md-2">
                  <Tooltip title="Mettre la formation en pause">
                    <div class="icon-wrapper">
                      <i
                        className="fa fa-pause custom-icon-red"
                        onClick={() => this.setState({ showPause: true })}

                        // onClick={(e) =>
                        //   window.confirm(
                        //     "Êtes-vous sûr de vouloir changer le status de ce bakeliste en stagiaire ?"
                        //   ) && this.changeStatus(this.props.id)
                        // }
                      >
                        {" "}
                      </i>
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <SweetAlert
                    warning
                    show={this.state.toStagiaire}
                    title="Voulez vous transformez ce bakeliste en stagiaire?"
                    // text="SweetAlert in React"
                    confirmBtnText="Oui"
                    cancelBtnText="Non"
                    onConfirm={() => {
                      // console.log("confirm");
                      this.setState({ toStagiaire: false });
                      // this.pauseBakelisteTraining();
                      this.changeStatusModal();
                    }}
                    showConfirm={true}
                    showCloseButton={true}
                    onCancel={() => {
                      // console.log("cancel");
                      this.setState({ toStagiaire: false });
                    }}
                    showCancel={true}
                    focusCancelBtn={true}
                    cancelBtnBsStyle="danger"
                  />
                </div>
                <div className="col-md-2">
                  <Tooltip title="Editer">
                    <div class="icon-wrapper">
                    <Link
            to={{
              pathname: "/edit-bakeliste",
              state: { id: this.props.id, data:this.props.data },
            }}
          >
                      <i className="fas fa-user-edit custom-icon" > </i>

          </Link>
                    </div>
                  </Tooltip>
                </div>
                <div className="col-md-2">
                  <Tooltip title="Voir CV">
                    <Link to="/show-bakeliste-cv">
                      <div class="icon-wrapper">
                        <i className="fas fa-file custom-icon-orange"> </i>
                      </div>
                    </Link>
                  </Tooltip>
                </div>

                <div>
                  <SweetAlert
                    warning
                    show={this.state.showPause}
                    title="Voulez vous mettre la formation en pause?"
                    // text="SweetAlert in React"
                    confirmBtnText="Oui"
                    cancelBtnText="Non"
                    onConfirm={() => {
                      // console.log("confirm");
                      this.setState({ showPause: false });
                      this.pauseBakelisteTraining();
                    }}
                    showConfirm={true}
                    showCloseButton={true}
                    onCancel={() => {
                      // console.log("cancel");
                      this.setState({ showPause: false });
                    }}
                    showCancel={true}
                    focusCancelBtn={true}
                    cancelBtnBsStyle="danger"
                  />
                </div>
                <div className="col-md-2">
                  {this.props.is_blocked === 1 ? (
                    <Tooltip title="Deblloquer le compte">
                      <div class="icon-wrapper">
                        <i
                          className="fas fa-unlock custom-icon-red"
                          onClick={() => this.setState({ toUnblock: true })}
                        >
                          {" "}
                        </i>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Bloquer le compte">
                      <div class="icon-wrapper">
                        <i
                          className="fas fa-lock custom-icon-red"
                          onClick={() => this.setState({ toBlock: true })}
                        >
                          {" "}
                        </i>
                      </div>
                    </Tooltip>
                  )}
                </div>
                <SweetAlert
                  warning
                  show={this.state.showAbandon}
                  title="Voulez vous declarer ce bakeliste comme abandonne?"
                  // text="SweetAlert in React"
                  confirmBtnText="Oui"
                  cancelBtnText="Non"
                  onConfirm={() => {
                    // console.log("confirm");
                    this.setState({ showAbandon: false });
                    this.abandonBakelisteTraining();
                  }}
                  showConfirm={true}
                  showCloseButton={true}
                  onCancel={() => {
                    // console.log("cancel");
                    this.setState({ showAbandon: false });
                  }}
                  showCancel={true}
                  focusCancelBtn={true}
                  cancelBtnBsStyle="danger"
                />
              </div>
              <div>
                <SweetAlert
                  warning
                  show={this.state.toBlock}
                  title="Voulez vous bloquer ce compte?"
                  // text="SweetAlert in React"
                  confirmBtnText="Oui"
                  cancelBtnText="Non"
                  onConfirm={() => {
                    this.setState({ toBlock: false });
                    this.blockUserAccount();
                  }}
                  showConfirm={true}
                  showCloseButton={true}
                  onCancel={() => {
                    // console.log("cancel");
                    this.setState({ toBlock: false });
                  }}
                  showCancel={true}
                  focusCancelBtn={true}
                  cancelBtnBsStyle="danger"
                />
              </div>
              <div>
                <SweetAlert
                  warning
                  show={this.state.toUnblock}
                  title="Voulez vous debloquer ce compte?"
                  // text="SweetAlert in React"
                  confirmBtnText="Oui"
                  cancelBtnText="Non"
                  onConfirm={() => {
                    this.setState({ toUnblock: false });
                    this.unblockUserAccount();
                  }}
                  showConfirm={true}
                  showCloseButton={true}
                  onCancel={() => {
                    // console.log("cancel");
                    this.setState({ toUnblock: false });
                  }}
                  showCancel={true}
                  focusCancelBtn={true}
                  cancelBtnBsStyle="danger"
                />
              </div>

              {/* <p>
                <span className="txt-suivi-coach">Nbr Semaine : </span>
                <span className="comp-fonct"> {""} </span>
              </p> */}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4 col-lg-4">
              {/* <p>
                <span className="txt-suivi-coach">Assiduité : </span>
                <span className="comp-fonct"> {""} </span>
              </p> */}
            </div>
            <div className="col-md-4 col-lg-4 ">
              {/* <p>
                <span className="txt-suivi-coach">Performance : </span>
                <div className="progress-bare-niv-bakeliste">
                  <div className="progress-effet-bare">
                    <span>{""}</span>
                  </div>
                </div>
              </p> */}
            </div>
            <div className="col-md-4 col-lg-4">
              {/* <p>
                <span className="txt-suivi-coach">Autonomie: </span>
                <div className="progress-bare-niv-bakeliste">
                  <div className="progress-effet-bare">
                    <span>{""}</span>
                  </div>
                </div>
              </p> */}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4 col-lg-4 ">
              {/* <p>
                <span className="txt-suivi-coach">Satisfaction: </span>
                <div className="progress-bare-niv-bakeliste">
                  <div className="progress-effet-bare">
                    <span>{""}</span>
                  </div>
                </div>
              </p> */}
            </div>
          </div>

        
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ cardUtilisateur }) => ({ ...cardUtilisateur }),
//     dispatch => bindActionCreators({ ...cardUtilisateurActions }, dispatch)
//   )( cardUtilisateur );
