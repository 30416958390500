import React, { useEffect } from "react";
import "./ajouterBakeliste.css";
// import { connect } from "react-redux";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import qs from "qs";
import API from "../../variablesGlobales";
import API2 from "../../variablesGlobales";

import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import { useReducer } from "react";
toast.configure();
const AjouterBakelisteRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      api2: API2.API2,

      fields: {},
      _phFields: {},
      errors: {},
      value: "",
      domaines: [],
      loading: false,
      isPro: false,
      isEtudiant: false,
      isFormationCourte: false,
      isFormationDiplomante: false,
      openStatutProfessionnelFC: false,
      openStatutFD: false,
      isSemaine: false,
      requestIsDone: false,
      fn_empty: false,
      ln_empty: false,
      email_empty: false,
      phone_empty: false,
      tf_empty: false,
      boursier_empty: false,
      lf_empty: false,
      df_empty: false,
      paiement_empty: false,
      fs_empty: false,
      ecole_empty: false,
      obj_empty: false,
      sp_empty: false,
      pe_empty: false,
      j1_empty: false,
      j2_empty: false,
      j3_empty: false,
      civility_empty: false,
      ms_empty: false,
      fin_formation: "",
      address_empty: false,
      isEtudiantSemaineChoice: false,
      isEtudiantSemaineLSChoice: false,
      isExistUser: false,
      isSuccessAddedBakelisteAlert: false,
      level_empty: false,
      plus_date: 0,
      allCoach : [],
      coach_id : ""
    }
  );

  useEffect(() => {
    getAllDomaine();
    getAllCoach()
  }, []);

  const getAllDomaine = () => {
    axios.get(state.api + "domaines").then((res) => {
      const domains = res.data;
      setState({
        domaines: domains.data,
      });
    });
  };

  const getAllCoach = () =>{
  axios.get(state.api + "liste-des-coachs").then((response)=>{
      setState({
        allCoach : response.data.data
      })
  })
}
// console.log("coach_id",state.coach_id)
  const handleShowAndHideAlert = (input) => {
    setTimeout(() => {
      if (input === "firstName") {
        setState({
          fn_empty: false,
        });
      }
      if (input === "lastName") {
        setState({
          ln_empty: false,
        });
      }
      if (input === "email") {
        setState({
          email_empty: false,
        });
      }
      if (input === "phone") {
        setState({
          phone_empty: false,
        });
      }
      if (input === "type_formation") {
        setState({
          tf_empty: false,
        });
      }
      if (input === "ispro") {
        setState({
          ispro_empty: false,
        });
      }
      if (input === "domaine_formation") {
        setState({
          df_empty: false,
        });
      }
      if (input === "formation_diplomante") {
        setState({
          fd_empty: false,
        });
      }
      if (input === "paiement_inscription") {
        setState({
          paiement_empty: false,
        });
      }
      if (input === "formation_suivie") {
        setState({
          fs_empty: false,
        });
      }

      if (input === "objectifs") {
        setState({
          obj_empty: false,
        });
      }
      if (input === "lieu_formation") {
        setState({
          lf_empty: false,
        });
      }
      if (input === "boursier") {
        setState({
          boursier_empty: false,
        });
      }
      if (input === "statut_professionnel") {
        setState({
          sp_empty: false,
        });
      }
      if (input === "pro_etudiant") {
        setState({
          pe_empty: false,
        });
      }
      if (input === "jour_1") {
        setState({
          j1_empty: false,
        });
      }
      if (input === "jour_2") {
        setState({
          j2_empty: false,
        });
      }
      if (input === "jour_3") {
        setState({
          j3_empty: false,
        });
      }
      if (input === "civility") {
        setState({
          civility_empty: false,
        });
      }
      if (input === "marital_status") {
        setState({
          ms_empty: false,
        });
      }
      if (input === "address") {
        setState({
          address_empty: false,
        });
      }
      if (input === "bakeliste_level") {
        setState({
          level_empty: false,
        });
      }
      if (input === "isExistUser") {
        setState({
          isExistUser: false,
        });
      }
    }, 5000);
  };

  const validateForm = () => {
    let fields = state.fields;
    let _phFields = state._phFields;
    let errors = {};
    let formIsValid = true;
    if (!fields["civility"]) {
      // console.log("civility");
      formIsValid = false;
      setState({
        civility_empty: true,
      });
      handleShowAndHideAlert("civility");
      errors["civility"] = "*Ce champ est obligatoire";
    }
    if (!fields["marital_status"]) {
      // console.log("marital");
      formIsValid = false;
      setState({
        ms_empty: true,
      });
      handleShowAndHideAlert("marital_status");
      errors["marital_status"] = "*Ce champ est obligatoire";
    }
    if (!fields["first_name"]) {
      // console.log("first_name");
      formIsValid = false;
      setState({
        fn_empty: true,
      });
      handleShowAndHideAlert("firstName");
      errors["first_name"] = "*Ce champ est obligatoire";
    }

    if (typeof fields["first_name"] !== "undefined") {
      // console.log("first_name2");
      if (
        !fields["first_name"].match(
          /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
        )
      ) {
        formIsValid = false;
        setState({
          fn_empty: true,
        });
        handleShowAndHideAlert("firstName");
        errors["first_name"] = "*SVP que des lettres.";
      }
    }

    if (!fields["last_name"]) {
      // console.log("last_name");
      formIsValid = false;
      setState({
        ln_empty: true,
      });
      handleShowAndHideAlert("lastName");
      errors["last_name"] = "*Ce champ est obligatoire";
    }

    if (typeof fields["last_name"] !== "undefined") {
      // console.log("last_name2");
      if (
        !fields["last_name"].match(
          /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s- ]*$/
        )
      ) {
        formIsValid = false;
        setState({
          ln_empty: true,
        });
        handleShowAndHideAlert("lastName");
        errors["last_name"] = "*SVP que des lettres.";
      }
    }

    if (!fields["email"]) {
      // console.log("email");
      formIsValid = false;
      setState({
        email_empty: true,
      });
      handleShowAndHideAlert("email");
      errors["email"] = "*Ce champ est obligatoire.";
    }

    if (typeof fields["email"] !== "undefined") {
      // console.log("rmail2");
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        setState({
          email_empty: true,
        });
        handleShowAndHideAlert("email");
        errors["email"] = "*Adresse email invalide.";
      }
    }

    if (!fields["phone"]) {
      // console.log("phone");
      formIsValid = false;
      setState({
        phone_empty: true,
      });
      handleShowAndHideAlert("phone");
      errors["phone"] = "*Ce champ est obligatoire.";
    }

    if (!fields["bakeliste_level"]) {
      // console.log("bakeliste_level");
      formIsValid = false;
      setState({
        level_empty: true,
      });
      handleShowAndHideAlert("bakeliste_level");
      errors["bakeliste_level"] = "*Ce champ est obligatoire.";
    }

    // if (typeof fields["phone"] !== "undefined") {
    //     if (!fields["phone"].match(/^[0-9]{9}$/)) {
    //         formIsValid = false;
    //         errors["phone"] = "*Numéro de telephone invalide.";
    //     }
    // }

    if (!fields["address"]) {
      // console.log("address");
      formIsValid = false;
      setState({
        address_empty: true,
      });
      handleShowAndHideAlert("address");
      errors["address"] = "*Ce champ est obligatoire";
    }
    // if (!fields["boursier"]) {
    //     console.log('boursier')
    //     formIsValid = false;
    //     setState({
    //         boursier_empty: true
    //     })
    //     handleShowAndHideAlert('boursier');
    //     errors["boursier"] = "*Ce champ est obligatoire.";
    // }
    if (!fields["type_formation"]) {
      // console.log("type_formation");
      formIsValid = false;
      setState({
        tf_empty: true,
      });
      handleShowAndHideAlert("type_formation");
      errors["type_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["lieu_formation"]) {
      // console.log("lieu_dormation");
      formIsValid = false;
      setState({
        lf_empty: true,
      });
      handleShowAndHideAlert("lieu_formation");
      errors["lieu_formation"] = "*Ce champ est obligatoire.";
    }
    if (!_phFields["domaines_id"]) {
      // console.log("domaine_id");
      formIsValid = false;
      setState({
        df_empty: true,
      });
      handleShowAndHideAlert("domaine_formation");
      errors["domaine_formation"] = "*Ce champ est obligatoire.";
    }
    if (!fields["paiement_inscription"]) {
      // console.log("paiement inscription");
      formIsValid = false;
      setState({
        paiement_empty: true,
      });
      handleShowAndHideAlert("paiement_inscription");
      errors["paiement_inscription"] = "*Ce champ est obligatoire.";
    }
    if (!fields["formation_suivie"]) {
      // console.log("formation suivie");
      formIsValid = false;
      setState({
        fs_empty: true,
      });
      handleShowAndHideAlert("formation_suivie");
      errors["formation_suivie"] = "*Ce champ est obligatoire.";
    }

    if (!fields["objectifs"]) {
      // console.log("objectifs");
      formIsValid = false;
      setState({
        obj_empty: true,
      });
      handleShowAndHideAlert("objectifs");
      errors["objectifs"] = "*Ce champ est obligatoire.";
    }
    if (
      fields["type_formation"] &&
      fields["type_formation"] === "courte" &&
      !fields["isproetudiant"]
    ) {
      // console.log("jour is pro etudiant");
      formIsValid = false;
      setState({
        sp_empty: true,
      });
      handleShowAndHideAlert("statut_professionnel");
      errors["statut_professionnel"] =
        "*Vous devez choisir un statut professionnel.";
    }
    if (
      fields["type_formation"] &&
      fields["isproetudiant"] &&
      fields["isproetudiant"] === "professionnel" &&
      !fields["isWE"]
    ) {
      // console.log("jour is week end");
      formIsValid = false;
      setState({
        pe_empty: true,
      });
      handleShowAndHideAlert("pro_etudiant");

      errors["pro_etudiant"] = "*Vous devez choisir un type de présence.";
    }
    if (
      fields["type_formation"] &&
      fields["isproetudiant"] &&
      fields["isWE"] &&
      fields["isWE"] === "semaine" &&
      !fields["jour_1"]
    ) {
      // console.log("jour 1");
      formIsValid = false;
      setState({
        j1_empty: true,
      });
      handleShowAndHideAlert("jour_1");
      errors["jour_1"] = "*Ce champ ne doit pas être vide.";
    }
    if (
      fields["type_formation"] &&
      fields["isproetudiant"] &&
      fields["isWE"] &&
      fields["isWE"] === "semaine" &&
      !fields["jour_2"]
    ) {
      // console.log("jour 2");
      formIsValid = false;
      setState({
        j2_empty: true,
      });
      handleShowAndHideAlert("jour_2");
      errors["jour_2"] = "*Ce champ ne doit pas être vide.";
    }
    if (
      fields["type_formation"] &&
      fields["isproetudiant"] &&
      fields["isWE"] &&
      fields["isproetudiant"] === "etudiant" &&
      !fields["jour_3"]
    ) {
      // console.log("jour 3");
      formIsValid = false;
      setState({
        j3_empty: true,
      });
      handleShowAndHideAlert("jour_3");
      errors["jour_3"] = "*Ce champ ne doit pas être vide.";
    }
    // if (fields["type_formation"] && fields["isproetudiant"] && fields["isWE"] && fields["isproetudiant"] == 'etudiant' && !fields["jour_2"]) {
    //     console.log('jour 2')
    //     formIsValid = false;
    //     state.j2_empty = true;
    //     handleShowAndHideAlert('jour_2');
    //     errors["jour_2"] = "*Ce champ ne doit pas être vide.";
    // }
    setState({
      errors: errors,
    });
    // console.log(formIsValid);
    return formIsValid;
  };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    // console.log("gooooo");
    if (validateForm()) {
      // console.log("hello");
      setState({
        loading: true,
      });

      const dataBakeliste = state._phFields;
      axios
        // .post(state.api + "add-bakeliste", dataBakeliste)
        .post(state.api + "add-bakeliste", dataBakeliste)
        .then((res) => {
          // console.log("res",res)
          if (res.data.success) {
            // createNewBakelistePH(dataPHBakeliste);
            setState({
              loading: false,
            });
            toast.success(
              "Bakeliste ajoute avec succes",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 500 }
            );
            window.location = "/dashboard-admin";
          } else if (res.data.success === false && res.data.exist === true) {
            setState({
              loading: false,
              isExistUser: true,
            });
            toast.warning(
              "Bakeliste exixtant",
              { position: toast.POSITION.BOTTOM_LEFT },
              { autoClose: 500 }
            );
            handleShowAndHideAlert("isExistUser");
          } else {
            // console.log(res);
            setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          toast.error(
            "Erreur Ajout Bakeliste",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 500 }
          );
          // console.log(error.message);
          setState({
            loading: false,
          });
        });
    }
  };

  const addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    // console.log("date", date);
    return date;
  };
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleChange = (e) => {
    let fields = state.fields;
    let _phFields = state._phFields;

    fields[e.target.name] = e.target.value;
    _phFields[e.target.name] = e.target.value;
    // console.log("handle change", e.target.value);
    if (e.target.name === "domaines_id") {
      state.domaines.forEach((x) => {
        if (x.name === e.target.value) {
          setState({
            idSelect: x.id,
          });
        }
      });
      fields[e.target.name] = state.idSelect;
    }

    if(e.target.name === "coach_id"){
      setState({
        coach_id : e.target.value
      })
    }
    if (e.target.name === "training_duration") {
      setState({ plus_date: e.target.value });
    }
    if (e.target.name === "debut_formation") {
      var date = e.target.value;
      var plusdate = state.plus_date;

      // var newDate = new Date(date.setMonth(date.getMonth() + 8));

      var datel = new Date(date);
      var day = datel.getDate();
      var month = datel.getMonth();
      var year = datel.getFullYear();
      const date_fin = addMonths(new Date(year, month, day), plusdate);

      _phFields["fin_formation"] = formatDate(new Date(date_fin));
      setState({ fin_formation: formatDate(new Date(date_fin)) });
      // const farm = moment(new Date(date_fin), "yyyy-mm-dd");
      // console.log("aadd", new Date(date_fin));
    }
    if (e.target.name === "type_formation") {
      fields[e.target.name] = e.target.value;
      if (e.target.value === "courte") {
        _phFields["jour_fd"] = "";
        setState({
          openStatutProfessionnelFC: true,
          openStatutFD: false,
        });
      } else if (e.target.value === "diplomante") {
        _phFields["nbre_jours"] = 5;
        _phFields["is_etudiant"] = 1;
        _phFields["is_week"] = 1;
        _phFields["is_weekend"] = 0;
        _phFields["jour_fd"] = "lundi au vendredi";
        _phFields["jour_1"] = "Lundi";
        _phFields["jour_2"] = "Mardi";
        _phFields["jour_3"] = "Mercredi";
        _phFields["jour_4"] = "Jeudi";
        _phFields["jour_5"] = "Vendredi";
        _phFields["jour_6"] = "";
        setState({
          openStatutProfessionnelFC: false,
          openStatutFD: true,
          isSemaine: false,
          isPro: false,
          isEtudiant: false,
        });
      }
    }
    if (e.target.name === "isproetudiant") {
      if (e.target.value === "professionnel") {
        _phFields["is_pro"] = 1;
        _phFields["is_etudiant"] = 0;
        setState({
          isPro: true,
          isEtudiant: false,
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "etudiant") {
        _phFields["is_etudiant_a_tester"] = 0;
        _phFields["is_etudiant"] = 1;
        _phFields["is_pro"] = 0;
        _phFields["nbre_jours"] = 3;
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        setState({
          isPro: false,
          isEtudiant: true,
          isSemaine: false,
        });
      } else if (e.target.value === "a_tester") {
        _phFields["is_etudiant_a_tester"] = 1;
        _phFields["is_etudiant"] = 1;
        _phFields["is_pro"] = 0;
        _phFields["nbre_jours"] = 3;
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        setState({
          isPro: false,
          isEtudiant: true,
          isSemaine: false,
        });
      }
    }
    if (e.target.name === "isWE") {
      if (e.target.value === "semaine") {
        _phFields["is_etudiant"] = 0;
        _phFields["is_week"] = 1;
        _phFields["is_weekend"] = 0;
        _phFields["nbre_jours"] = 2;
        _phFields["horaire"] = "17h30-19h30";
        _phFields["jour_3"] = "";
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        setState({
          isSemaine: true,
        });
      } else if (e.target.value === "weekend") {
        _phFields["is_weekend"] = 1;
        _phFields["is_week"] = 0;
        _phFields["horaire"] = "9h-13h";
        _phFields["nbre_jours"] = 1;
        _phFields["jour_1"] = "Samedi";
        _phFields["jour_2"] = "";
        _phFields["jour_3"] = "";
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        setState({
          isSemaine: false,
        });
      }
      if (e.target.name === "email") {
        _phFields["email"] = e.target.value;
      }
    }
    if (e.target.name === "isWEE") {
      if (e.target.value === "semaine") {
        _phFields["is_etudiant"] = 1;
        _phFields["is_week"] = 1;
        _phFields["is_weekend"] = 0;
        _phFields["nbre_jours"] = 3;
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        setState({
          isEtudiantSemaineChoice: true,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "weekend") {
        _phFields["is_weekend"] = 1;

        _phFields["is_week"] = 0;
        _phFields["horaire"] = "9h-17h";
        _phFields["nbre_jours"] = 2;
        _phFields["jour_1"] = "Samedi";
        _phFields["jour_2"] = "Dimanche";
        _phFields["jour_3"] = "";
        _phFields["jour_4"] = "";
        _phFields["jour_5"] = "";
        _phFields["jour_6"] = "";
        setState({
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: false,
        });
      } else if (e.target.value === "semainels") {
        _phFields["is_etudiant"] = 1;
        _phFields["is_week"] = 1;
        _phFields["is_weekend"] = 0;
        _phFields["nbre_jours"] = 5;
        _phFields["jour_1"] = "Lundi";
        _phFields["jour_2"] = "Mardi";
        _phFields["jour_3"] = "Mercredi";
        _phFields["jour_4"] = "Jeudi";
        _phFields["jour_5"] = "Vendredi";
        _phFields["jour_6"] = "";
        console.log(_phFields);

        setState({
          isEtudiantSemaineChoice: false,
          isEtudiantSemaineLSChoice: true,
        });
      }
    }
    _phFields["demi_boursier"] = 0;
    _phFields["boursier"] = 0;
    _phFields["is_isa_contract"] = 0;

    setState({
      fields,
      _phFields,
    });
    // console.log("handle change", state.fields);
    // console.log("phFields", state._phFields);
  };
  return (
    <div className="p-b-200">
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-lg-9">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon
                    className="icon-breadcrumb-custom"
                    icon="user-plus"
                  />
                  Ajouter bakeliste
                </li>
              </ol>
            </nav>

            <form
              className="form1"
              onSubmit={(e) => submituserRegistrationForm(e)}
            >
              <div className="form-row row">
                <div>
                  {/* <h1 id="titreCoach">Ajouter un Bakeliste</h1> */}
                  {/* <h1 className="h-theme titreCoach">Ajouter un Bakeliste</h1> */}
                </div>
                <div className="col-md-12 mb-3">
                  {" "}
                  <span className="experience-demande-candidature">
                    Informations personnelles{" "}
                    <span style={{ color: "red" }}> * </span>
                  </span>
                  <br />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {/* <label class="label-form">Civilité</label> */}
                    <select
                      name="civility"
                      className="form-control ab1 si-form-input "
                      id="civilityInput"
                      value={state.fields.civility}
                      onChange={handleChange}
                      required
                    >
                      <option> *Civilité -- choisir un statut -- </option>
                      <option value="Monsieur"> Monsieur </option>
                      <option value="Madame"> Madame </option>
                      <option value="Mademoiselle"> Mademoiselle </option>
                    </select>
                    {state.civility_empty && (
                      <div className="errorMsg">
                        {state.errors.civility}
                      </div>
                    )}
                    {/* <div class="line-box">
                  <div class="line"></div>
                </div> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {/* <label class="label-form">Situation matrimoniale</label> */}
                    <select
                      name="marital_status"
                      className="form-control ab1 si-form-input"
                      id="marital_statusInput"
                      value={state.fields.marital_status}
                      onChange={handleChange}
                      required
                    >
                      <option>
                        {" "}
                        *Situation matrimoniale --Choisir un statut --{" "}
                      </option>
                      <option value="Marié(e)"> Marié(e) </option>
                      <option value="Celibataire"> Célibataire </option>
                    </select>
                    {state.ms_empty && (
                      <div className="errorMsg">
                        {state.errors.marital_status}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Prénom:</label> */}
                    <input
                      type="text"
                      className="form-control ab1 si-form-input"
                      id="inputpFirstName"
                      placeholder="Prénom"
                      name="first_name"
                      value={state.fields.first_name}
                      onChange={handleChange}
                      required
                    />
                    {state.fn_empty && (
                      <div className="errorMsg">
                        {state.errors.first_name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Nom:</label> */}
                    <input
                      type="text"
                      className="form-control ab1 si-form-input"
                      id="inputLastName"
                      placeholder="Nom"
                      name="last_name"
                      value={state.fields.last_name}
                      onChange={handleChange}
                    />

                    {state.ln_empty && (
                      <div className="errorMsg">
                        {state.errors.last_name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Email:</label> */}
                    <input
                      type="Email"
                      className="form-control ab1 si-form-input"
                      id="inputEmail"
                      placeholder="Email"
                      name="email"
                      value={state.fields.email}
                      onChange={handleChange}
                      required
                    />

                    {state.email_empty && (
                      <div className="errorMsg">{state.errors.email}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control ab1 si-form-input"
                      id="inputNumero"
                      placeholder="Téléphone"
                      name="phone"
                      onChange={handleChange}
                      required
                    />

                    {state.phone_empty && (
                      <div className="errorMsg">{state.errors.phone}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control ab1 si-form-input"
                      id="inputNumero"
                      placeholder="Téléphone Whatsapp"
                      name="telephone"
                      onChange={handleChange}
                      required
                    />

                    {state.phone_empty && (
                      <div className="errorMsg">{state.errors.phone}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <input
                      type="date"
                      className="form-control ab1 si-form-input"
                      id="inputNumero"
                      placeholder="Date de Naissance"
                      name="date_naissance"
                      onChange={handleChange}
                      required
                    />

                    {state.phone_empty && (
                      <div className="errorMsg">
                        {state.errors.date_naissance}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Adresse:</label> */}
                    <input
                      type="text"
                      className="form-control ab1 si-form-input"
                      id="inputAdresse"
                      placeholder="Adresse du bakeliste"
                      name="address"
                      onChange={handleChange}
                      required
                    />

                    {state.address_empty && (
                      <div className="errorMsg">
                        {state.errors.address}
                      </div>
                    )}
                    {/* <div class="line-box">
                  <div class="line"></div>
                </div> */}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Formation suivie:</label> */}
                    <input
                      type="text"
                      className="form-control ab1 si-form-input"
                      id="inputformationsuivie"
                      placeholder="Formation suivie"
                      name="formation_suivie"
                      onChange={handleChange}
                      required
                    />

                    {state.fs_empty && (
                      <div className="errorMsg">
                        {state.errors.formation_suivie}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Ecole:</label> */}
                    <input
                      type="text"
                      className="form-control ab1 si-form-input"
                      id="inputEcole"
                      placeholder="Ecole"
                      name="ecole"
                      onChange={handleChange}
                      required
                    />

                    {state.ecole_empty && (
                      <div className="errorMsg">{state.errors.ecole}</div>
                    )}
                  </div>
                </div>

                {/* <div className="col-md-6">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control ab1 si-form-input"
                  id="inputEcole"
                  placeholder="Diplôme"
                  name="ecole"
                  onChange={handleChange}
                  required
                />

                {state.ecole_empty && (
                  <div className="errorMsg">
                    {state.errors.ecole}
                  </div>
                )}
              </div>
           
           
           
            </div> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  {" "}
                  <span className="experience-demande-candidature">
                    Formations à Bakeli{" "}
                    <span style={{ color: "red" }}> * </span>
                  </span>
                  <br />
                </div>
                <div className="col-md-12">
                  <div className="form-group conditionnal-input">
                    {/* <label class="label-form">Formation:</label> <br /> */}
                    <div class="checkbox c-checkbox">
                      <label>
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="type_formation"
                          value="courte"
                          onChange={handleChange}
                          required
                        />
                        <span class="fa fa-bars"></span> Formation courte
                        <br />
                      </label>
                    </div>
                    <div class="checkbox c-checkbox">
                      <label>
                        <input
                          type="radio"
                          className="radio-bsi"
                          name="type_formation"
                          value="diplomante"
                          onChange={handleChange}
                        />
                        <span class="fa fa-bars"></span> Formation diplômante
                      </label>
                    </div>
                    {state.tf_empty && (
                      <div className="errorMsg">
                        {state.errors.type_formation}
                      </div>
                    )}
                  </div>
                </div>

                {state.openStatutProfessionnelFC && (
                  <div className="pro_etudiant_container col-md-12">
                    <div className="form-group">
                      <label class="label-form">Statut professionnel</label>{" "}
                      <div class="checkbox c-checkbox">
                        <label>
                          <input
                            type="radio"
                            className="radio-bsi"
                            name="isproetudiant"
                            value="professionnel"
                            onChange={handleChange}
                          />
                          <span class="fa fa-bars"></span>Professionnel
                        </label>
                      </div>
                      <div class="checkbox c-checkbox">
                        <label>
                          <input
                            type="radio"
                            className="radio-bsi"
                            name="isproetudiant"
                            value="etudiant"
                            onChange={handleChange}
                          />
                          <span class="fa fa-bars"></span>Etudiant
                        </label>
                      </div>
                      <div class="checkbox c-checkbox">
                        <label>
                          <input
                            type="radio"
                            className="radio-bsi"
                            name="isproetudiant"
                            value="a_tester"
                            onChange={handleChange}
                          />
                          <span class="fa fa-bars"></span>Etudiant à a_tester
                        </label>
                      </div>
                      {state.sp_empty && (
                        <div className="errorMsg">
                          {state.errors.statut_professionnel}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {state.isEtudiant && (
                  <div className="etudiant_jhp_container col-md-12">
                    <div className="form-group">
                      <label class="label-form">Type de présence : </label>{" "}
                      <br />
                      <div class="checkbox c-checkbox">
                        <label>
                          <input
                            type="radio"
                            className="radio-bsi"
                            name="isWEE"
                            value="semaine"
                            onChange={handleChange}
                          />
                          <span class="fa fa-bars"></span>Semaine (3 jours de
                          présence ) <br />
                        </label>
                      </div>
                      <div class="checkbox c-checkbox">
                        <label>
                          <input
                            type="radio"
                            className="radio-bsi"
                            name="isWEE"
                            value="weekend"
                            onChange={handleChange}
                          />
                          <span class="fa fa-bars"></span>Week-End (Samedi et
                          Dimanche de 9h à 17h){" "}
                        </label>
                      </div>
                      <br />
                      <div class="checkbox c-checkbox">
                        <label>
                          <input
                            type="radio"
                            className="radio-bsi"
                            name="isWEE"
                            value="semainels"
                            onChange={handleChange}
                          />
                          <span class="fa fa-bars"></span>Semaine (Du Lundi au
                          Vendredi )
                        </label>
                      </div>
                      {state.pe_empty && (
                        <div className="errorMsg">
                          {state.errors.pro_etudiant}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {state.isEtudiantSemaineLSChoice && (
                  <div className="etudiant_jhp_container col-md-12">
                    <div className="form-group">
                      {/* <label class="label-form">Choix horaire :</label> */}
                      <select
                        className="form-control si-form-input"
                        id="horaire"
                        name="horaire"
                        onChange={handleChange}
                        required
                      >
                        <option> --Choisir une horaire-- </option>
                        <option value="8h-17h">8h - 17h</option>
                        <option value="8h-12h30">8h - 12h30</option>
                        <option value="12h30-17h">12h30 - 17h</option>
                      </select>
                    </div>
                  </div>
                )}

                {state.isEtudiantSemaineChoice && (
                  <div className="etudiant_jhp_container">
                    <div className="col-md-6">
                      <div className="form-group">
                        {/* <label class="label-form">Choix horaire :</label> */}
                        <select
                          className="form-control ab1 si-form-input"
                          id="horaire"
                          name="horaire"
                          onChange={handleChange}
                        >
                          <option> --Choisir une horaire-- </option>
                          <option value="8h-17h">8h - 17h</option>
                          <option value="8h-12h30">8h - 12h30</option>
                          <option value="12h30-17h">12h30 - 17h</option>
                        </select>
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Choix Jour 1:</label>
                        <select
                          className="form-control si-form-input"
                          id="jour_1"
                          name="jour_1"
                          onChange={handleChange}
                        >
                          <option> --Choisir un jour-- </option>
                          <option value="Lundi">Lundi</option>
                          <option value="Mardi">Mardi</option>
                          <option value="Mercredi">Mercredi</option>
                          <option value="Jeudi">Jeudi</option>
                          <option value="Vendredi">Vendredi</option>
                          <option value="Samedi">Samedi</option>
                        </select>

                        {state.j1_empty && (
                          <div className="errorMsg">
                            {state.errors.jour_1}
                          </div>
                        )}
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Choix Jour 2:</label>
                        <select
                          className="form-control ab1"
                          id="jour_2"
                          name="jour_2"
                          onChange={handleChange}
                        >
                          <option> --Choisir un jour-- </option>
                          <option value="Lundi">Lundi</option>
                          <option value="Mardi">Mardi</option>
                          <option value="Mercredi">Mercredi</option>
                          <option value="Jeudi">Jeudi</option>
                          <option value="Vendredi">Vendredi</option>
                          <option value="Samedi">Samedi</option>
                        </select>

                        {state.j2_empty && (
                          <div className="errorMsg">
                            {state.errors.jour_2}
                          </div>
                        )}
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Choix Jour 3:</label>
                        <select
                          className="form-control ab1"
                          id="jour_3"
                          name="jour_3"
                          onChange={handleChange}
                        >
                          <option> --Choisir un jour-- </option>
                          <option value="Lundi">Lundi</option>
                          <option value="Mardi">Mardi</option>
                          <option value="Mercredi">Mercredi</option>
                          <option value="Jeudi">Jeudi</option>
                          <option value="Vendredi">Vendredi</option>
                          <option value="Samedi">Samedi</option>
                        </select>

                        {state.j3_empty && (
                          <div className="errorMsg">
                            {state.errors.jour_3}
                          </div>
                        )}
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {state.isPro && (
                  <div className="pro_jhp_container col-md-12">
                    <div className="form-group">
                      <label class="label-form">Type de présence : </label>{" "}
                      <div class="checkbox c-checkbox">
                        <label>
                          <input
                            type="radio"
                            className="radio-bsi si-form-input"
                            name="isWE"
                            value="semaine"
                            onChange={handleChange}
                            required
                          />
                          <span class="fa fa-bars"></span>Semaine (2 jours de
                          présence de 17h30 à 19h30){" "}
                        </label>
                      </div>
                      <div class="checkbox c-checkbox">
                        <label>
                          <input
                            type="radio"
                            className="radio-bsi"
                            name="isWE"
                            value="weekend"
                            onChange={handleChange}
                          />
                          <span class="fa fa-bars"></span>Week-End (Samedi de 9h
                          à 13h){" "}
                        </label>
                      </div>
                      {state.pe_empty && (
                        <div className="errorMsg">
                          {state.errors.pro_etudiant}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {state.isSemaine && (
                  <div className="jour_presence_container col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          {/* <label class="label-form">Choix Jour 1:</label> */}
                          <select
                            className="form-control ab1 si-form-input"
                            id="jour_1"
                            name="jour_1"
                            onChange={handleChange}
                          >
                            <option> Choix Jour 1 </option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                          </select>

                          {state.j1_empty && (
                            <div className="errorMsg">
                              {state.errors.jour_1}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          {/* <label class="label-form">Choix Jour 2:</label> */}
                          <select
                            className="form-control ab1 si-form-input"
                            id="jour_1"
                            name="jour_2"
                            onChange={handleChange}
                          >
                            <option> Choix Jour 2</option>
                            <option value="Lundi">Lundi</option>
                            <option value="Mardi">Mardi</option>
                            <option value="Mercredi">Mercredi</option>
                            <option value="Jeudi">Jeudi</option>
                            <option value="Vendredi">Vendredi</option>
                          </select>
                          {state.j2_empty && (
                            <div className="errorMsg">
                              {state.errors.jour_2}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.openStatutFD && (
                  <div className="fd_choice_container col-md-6">
                    <div className="form-group">
                      <label class="label-form">
                        {/* Choix horaire : (Du Lundi au Vendredi){" "} */}
                      </label>
                      <select
                        className="form-control ab1 si-form-input"
                        id="horaire"
                        name="horaire"
                        onChange={handleChange}
                      >
                        <option>
                          {" "}
                          Choisir une horaire: (Du Lundi au Vendredi){" "}
                        </option>
                        <option value="8h-12h30">8h - 12h30</option>
                        <option value="12h30-17h">12h30 - 17h</option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Domaine de formation:</label> */}
                    <select
                      className="form-control ab1 si-form-input"
                      id="inputbakeli_domaine_id"
                      name="domaines_id"
                      onChange={handleChange}
                    >
                      <option> Choisir un domaine </option>
                      {state.domaines.map((x, y) => (
                        <option value={x.id} key={y}>
                          {x.name}
                        </option>
                      ))}
                    </select>

                    {state.df_empty && (
                      <div className="errorMsg">
                        {state.errors.domaine_formation}
                      </div>
                    )}
                    {/* <div class="line-box">
                  <div class="line"></div>
                </div> */}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Formation payante :</label> */}
                    <select
                      className="form-control ab1  si-form-input"
                      id="inputFormationP"
                      name="is_formation_payante"
                      onChange={handleChange}
                    >
                      <option> Formation payante </option>
                      <option value={1}>OUI</option>
                      <option value={0}>NON</option>
                    </select>
                  </div>{" "}
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Stage de formation :</label> */}
                    <select
                      className="form-control ab1 si-form-input"
                      id="inputSFormation"
                      name="is_stagede_formation"
                      onChange={handleChange}
                    >
                      <option> Stage de Formation</option>
                      <option value={1}>OUI</option>
                      <option value={0}>NON</option>
                    </select>
                  </div>{" "}
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Lieu de formation:</label> */}
                    <select
                      className="form-control ab1 si-form-input"
                      id="inputlieu_formation"
                      name="lieu_formation"
                      onChange={handleChange}
                      required
                    >
                      <option> Choisir un lieu </option>
                      <option value="Grand Yoff 1">Grand Yoff 1</option>
                      <option value="Grand Yoff 2">Grand Yoff 2</option>
                      <option value="Thiés">Thiés</option>
                      <option value="Labé">Labé (Guinée)</option>
                      <option value="Conakry">Conakry(Guinée)</option>
                    </select>
                    {state.lf_empty && (
                      <div className="errorMsg">
                        {state.errors.lieu_formation}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label class="label-form">
                      {/* Niveau par rapport au domaine choisi : */}
                    </label>
                    <select
                      className="form-control ab1 si-form-input"
                      id="inputpaiement_inscriptionvg"
                      name="bakeliste_level"
                      onChange={handleChange}
                      required
                    >
                      <option> --Choisir un niveau-- </option>
                      <option value={"débutant"}>Débutant</option>
                      <option value={"intermédiaire"}>Intermédiaire</option>
                      <option value={"avancé"}>Avancé</option>
                    </select>

                    {state.level_empty && (
                      <div className="errorMsg">
                        {state.errors.bakeliste_level}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Paiement inscription :</label> */}
                    <select
                      className="form-control ab1 si-form-input"
                      id="inputSFormationvg"
                      name="paiement_inscription"
                      onChange={handleChange}
                      required
                    >
                      <option> --Paiement Inscription-- </option>
                      <option value={1}>OUI</option>
                      <option value={0}>NON</option>
                    </select>

                    {state.paiement_empty && (
                      <div className="errorMsg">
                        {state.errors.paiement_inscription}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    {/* <label class="label-form">Durée de formation:</label> */}
                    <select
                      className="form-control ab1 si-form-input"
                      id="inputSFormationvg"
                      name="training_duration"
                      onChange={handleChange}
                      required
                    >
                      <option> --Choisir une duree-- </option>
                      <option value="3">3 mois</option>
                      <option value="6">6 mois</option>
                      <option value="12">12 mois</option>
                      <option value="24">24 mois</option>
                    </select>

                    {state.paiement_empty && (
                      <div className="errorMsg">
                        {state.errors.paiement_inscription}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label class="label-form">Date de début:</label>
                    <input
                      type="date"
                      className="form-control ab1 si-form-input"
                      id="inputdebut_formation"
                      name="debut_formation"
                      format="yyyy/mm/dd"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label class="label-form">Date de fin:</label>
                    <input
                      type="date"
                      className="form-control ab1 si-form-input"
                      id="inputfin_formation"
                      name="fin_formation"
                      value={state.fin_formation}
                      onChange={handleChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group ">
                    {/* <label class="label-form">Objectifs:</label> */}
                    <textarea
                      className="form-control ab1 si-form-input"
                      id="inputObjectifs"
                      placeholder="Objectifs"
                      name="objectifs"
                      onChange={handleChange}
                    ></textarea>
                    {state.obj_empty && (
                      <div className="errorMsg">
                        {state.errors.objectifs}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                      <select 
                      // onChange={(e)=>setState({
                      //     coach_id : e.target.value
                      // })}
                      onChange={handleChange}
                      name="coach_id"
                      className="form-control ab1 si-form-input"
                      >
                        <option>Choisir le coach</option>
                        {state.allCoach.map((item,index)=>(
                          <option index={index} value={item.id}>{item.last_name} {item.first_name}</option>
                        ))}
                      </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group ">
                    {/* <label class="label-form">Commentaire:</label> */}
                    <textarea
                      className="form-control ab1 si-form-input"
                      id="inputCommentaire"
                      placeholder="Ajouter un commentaire"
                      name="commentaire"
                      onChange={handleChange}
                    ></textarea>

                    {state.obj_empty && (
                      <div className="errorMsg">
                        {state.errors.commentaire}
                      </div>
                    )}
                  </div>
                </div>
                {/* {state.isSuccessAddedBakelisteAlert && (
              <div className="col-md-12 add_success_alerty_container">
                <div className="form-group alerty-group">
                  <div className="alert alert-success add_success_alerty text-center">
                    Le bakeliste a été bien enregistré. Vous allez être
                    redirigé dans 5 secondes ...
                  </div>
                </div>
              </div>
            )} */}
                <div className="col-md-6  offset-6 submit-btn-container">
                  <div className="form-group ">
                    {!state.loading ? (
                      <button className="btn btn-theme">
                        <i className="fas fa-plus-circle"></i> &nbsp; Ajouter
                      </button>
                    ) : (
                      <button className="btn btn-theme loading-btn">
                        Envoie en cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
            {/* <div className="row">
          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteLateItemForAdmin />
          </div>
          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteMissingItemForAdmin />
          </div>
          <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
            <ProgramReunionCoach />
          </div>
          <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <SavedReunionListItem />
          </div>
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AjouterBakelisteRefact;
