import React, { Component } from "react";
import "./bakelistesAdmin.css";
// import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
// import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
// import ProgramReunionCoach from '../programReunionCoach/index';
// import SavedReunionListItem from '../savedReunionListItem/index';
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TableBakelisteNiveau from "../tableBakelisteNiveau/index";

export default class bakelistesAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="row px-3">
          <div className="col-lg-12">
            <div className="container-fluid px-0">
              {/* <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="user"
                    />
                    Bakeliste
                  </li>
                </ol>
              </nav> */}
            </div>

            <div className="row m-b-15">
              <div className="col m-b-15">
                <h6 className="h-theme ff-inter fw-600 pt-3">
                  Liste de tous les Bakelistes{" "}
                </h6>
              </div>
              <div className="col dis-flex m-b-15"></div>
            </div>

            <div className="col-xl-10 col-lg-10 col-md-8">
              {/* <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item active">
                    <a class="nav-link" href="/bakelistes-admin">Bakelistes<span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link ml-5" href="/bakelistes-admin-dakar">Dakar</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link ml-5" href="/bakelistes-admin-thies">Thiès</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link ml-5" href="/bakelistes-admin-labe">Labé</a>
                  </li>
                </ul>
              </div>
            </nav> */}
            </div>

            <div className="card-dashboard m-b-15 p-b-60">
              <div className="row m-b-30 m-t-20">
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>

              <TableBakelisteNiveau />
            </div>
          </div>
          {/* <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div> */}
        </div>
      </div>
    );
  }
}
