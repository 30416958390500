import React, {Component} from 'react';
import './annoncesAdmin.css';
import BakelisteGroupItemForCoach from '../bakelisteGroupItemForCoach/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import ItemFilActualite from '../itemFilActualite/index';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import icons_add_image from '../../img/icons/icons_add_image.png'
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';

export default class annoncesAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="p-b-200">
          <div className="container-fluid px-0">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard-admin" className="lien_nav">Dashboard / </Link></li>
                        <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current"> Annonces </li>
                    </ol>
                </nav>
                <h5 className="h-theme fw-700 ff-inter px-3 pb-5">Formulaire de L'annonce </h5>
            </div>
                <div className="row">
                  <div className="col-lg-9">
                    
                    <div className="container-fluid bg-white">
                      
                      <div className="col-md-12 px-xl-3 px-lg-2 m-b-50 pl-md-0 pr-md-3 pt-3">
                        <div className="card hauteur-card">                   
                          <div className="card-body">
                            <div className="deplacement-btn-ajouter">
                               <p><img src={icons_add_image} /> <span className="txt-ajouter-photo">Ajouter des photos</span></p>
                            </div>
                            
                          </div>
                        </div>
                        <div className="pt-3">
                        <span className="format-txt-photos pt-5">Photos° 0/10 Choisissez d’abord la photo principale de votre  annonce.</span>
                          </div>

                        <form>
                            <div className="row mb-3 pt-5">
                              <label for="inputEmail3" className="col-sm-3 col-form-label">Nom de l'entreprise</label>
                              <div className="col-sm-9">
                                <input type="email" className="form-control taille-des-input" id="inputEmail3" />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label for="inputPassword3" className="col-sm-3 col-form-label">Adresse </label>
                              <div className="col-sm-9">
                                <input type="password" className="form-control taille-des-input" id="inputPassword3" />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label for="inputPassword3" className="col-sm-3 col-form-label">Numéro de téléphone</label>
                                <div className="col-lg-3 col-sm-4"> 
                                  <select className="form-control form-select taille-des-input">
                                    <option selected></option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                  <span className="col-form-label">indicatif </span>
                                </div>
                                <div className="col-lg-6 col-sm-4"> 
                              
                                  <select className="form-control form-select taille-des-input">
                                    <option selected></option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                  <span className="col-form-label">N° de Telephone </span>
                                </div>
                            </div>

                            <div className="row mb-3">
                              <label for="inputPassword3" className="col-sm-3 col-form-label">Secteur de l’entreprise</label>
                                <div className="col-sm-9"> 
                                  <select className="form-control form-select taille-des-input">
                                    <option selected></option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                            </div>
                            
                            <div className="row mb-3">
                              <label for="inputEmail3" className="col-sm-3 col-form-label">Intitulé </label>
                              <div className="col-sm-9">
                                <input type="email" className="form-control taille-des-input" id="inputEmail3" />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label for="inputEmail3" className="col-sm-3 col-form-label">Nature </label>
                              <div className="col-sm-9">
                                <input type="email" className="form-control taille-des-input" id="inputEmail3" />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label for="inputEmail3" className="col-sm-3 col-form-label">Lieu </label>
                              <div className="col-sm-9">
                                <input type="email" className="form-control taille-des-input" id="inputEmail3" />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label for="inputEmail3" className="col-sm-3 col-form-label">Date de début </label>
                              <div className="col-sm-9">
                                <input type="email" className="form-control taille-des-input" id="inputEmail3" />
                              </div>
                            </div>
                            
                            <div className="row mb-3">
                              <label for="inputEmail3" className="col-sm-3 col-form-label">Compétences requise </label>
                              <div className="col-sm-9">
                                <input type="email" className="form-control taille-des-input" id="inputEmail3" />
                              </div>
                            </div>
                            <div className="row mb-3">
                              <label for="inputPassword3" className="col-sm-3 col-form-label">Niveau d'étude </label>
                                <div className="col-lg-3 col-sm-4"> 
                                  <select className="form-control form-select taille-des-input">
                                    <option selected></option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                              <label for="inputPassword3" className="col-sm-3 col-form-label">Niveau d'experience </label>

                                <div className="col-lg-3 col-sm-4"> 
                              
                                  <select className="form-control form-select taille-des-input">
                                    <option selected></option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                              <label for="inputPassword3" className="col-sm-3 col-form-label"> Langue </label>
                                <div className="col-sm-9"> 
                                  <select className="form-control form-select taille-des-input">
                                    <option selected></option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                              <label for="inputPassword3" className="col-sm-3 col-form-label">Nombre de Place </label>
                                <div className="col-sm-9"> 
                                  <select className="form-control form-select taille-des-input">
                                    <option selected></option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                  </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                            <label for="exampleFormControlTextarea1" className="col-sm-3 form-label sgm-msg">Missions </label>
                            
                            <div className="col-sm-9 mb-0">
                              <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            </div>

                          </form>
                      <button type="button" className="btn butons-pub mb-5 offset-10">PUBLIER</button>
                          

                      </div>
                    </div>
                    
                  </div>
                  <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteLateItemForAdmin />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <BakelisteMissingItemForAdmin />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                        <ProgramReunionCoach />
                      </div>
                      <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                        <SavedReunionListItem />
                      </div>
                    </div>
                  </div>
                </div>
              </div>;
    }
  }
