import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import FeatherIcon from "feather-icons-react";
import {
  archiverBakelisteAsync,
  bloquerUnBakelisteAsync,
  declareBakelisteRecrutable,
  getAllBakelisteAsync,
  getBakelisteAbondonnessync,
  getBakelisteEnligneeAsync,
  getBakelistePresentielsAsync,
  mettreBakelistePause,
  showListBakeAbondonnes,
  showListBakeEnligne,
  showListBakeliste,
  showListBakePresentiels,
  termineFormationAsync,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import AllBakelisteEnligne from "./AllBakelisteEnligne";
import AllBakelistePresentiel from "./AllBakelistePresentiel";
import AllBakelisteAbondonnes from "./AllBakelisteAbondonnes";
import { timeFromX } from "../dayjsformat/dayJSDateFormat";

const AllBakeliste = () => {
  const dispatch = useDispatch();
  const allBakelisteData = useSelector(showListBakeliste);
  const bakelisteEnligneData = useSelector(showListBakeEnligne);
  const bakelistePresentielsData = useSelector(showListBakePresentiels);
  const bakelisteAbondoneesData = useSelector(showListBakeAbondonnes);

  useEffect(() => {
    dispatch(getAllBakelisteAsync());
    dispatch(getBakelisteEnligneeAsync());
    dispatch(getBakelistePresentielsAsync());
    dispatch(getBakelisteAbondonnessync());
  }, []);

  const blockUserAccount = (bakeliste_id) => {
    dispatch(bloquerUnBakelisteAsync(bakeliste_id));
  };
  const actionFormatter = (cell, row) => {
    return (
      <div className="text-left">
      <span className="text-center text-warning">
        <Tooltip title="Modifier" placement="top">
          <Link
          to={{
            pathname: "/edit-bakeliste",
            state: { id: row.id, data: row },
          }}
          // onClick={() => {
          //   console.log(row.id, row);
          // }}
          style={{ textDecoration: "none", color: "black" }}
          >
          <FeatherIcon icon="edit" className="ml-2" />
          
          </Link>
        </Tooltip>
        &nbsp;&nbsp;
      </span>
       <span className="text-center text-success">
       <Tooltip title="details" placement="top">
        <Link
         to={{
          pathname: "/show-bakeliste",
          state: { id: row.id, data: row },
        }}
        style={{ textDecoration: "none", color: "black" }}
        >
        <FeatherIcon icon="eye" className="ml-2" />
        </Link>
       </Tooltip>
       &nbsp;&nbsp;
     </span>
     </div>
    );
  };
  const timeFormater = (cell, row) => {
    return timeFromX(row.debut_formation, row.fin_formation);
  };
  const archiverBakeliste = (id) => {
    dispatch(archiverBakelisteAsync(id));
  };
  const pauseBakelisteTraining = (id) => {
    var fd = new FormData();

    fd.append("bakeliste_id", id);
    dispatch(mettreBakelistePause(fd));
  };
  const terminerFormation = (id) => {
    var fd = new FormData();
    fd.append("bakeliste_id", id);
    dispatch(termineFormationAsync(fd));
  };
  const recrutable = (id) => {
    // console.log(id);

    var fd = new FormData();
    fd.append("bakeliste_nickname", "expert");
    fd.append("bakeliste_id", id);
    fd.append("label", "recruitable");
    dispatch(declareBakelisteRecrutable(fd));
  };
  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/bakelistes_new" className="lien_nav_current">
                Dashboard bakeliste
              </Link>
            </li>
            <li>
              <Link to="#" className="lien_nav_current">
                All Bakelistes
              </Link>
            </li>
          </ol>
        </nav>
      </div>

      <div className="row d-flex ">
        <div className="col-md-3 ml-auto">
          <Link
            style={{
              background: "#009688",
              border: "1px solid #009688",
              padding: "10px 25px",
              color: "white",
              fontWeight: "bold",
            }}
            to="/ajouter-bakeliste"
          >
            Ajouter
            <FeatherIcon icon="plus-circle" className="ml-2" />
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11">
          <BootstrapTable
            data={allBakelisteData}
            hover={true}
            multiColumnSearch
            exportCSV
            csvFileName="listes-coachs.csv"
            multiColumnSort={2}
            pagination
            tableStyle={{ background: "#ffff" }}
            bordered={true}
            bodyStyle={{
              fontSize: 12,
              color: "rgba(0, 0, 0, 0.7)",
              fontFamily: "inherit",
              fontWeight: 400,
            }}
            tdStyle={{ background: "red" }}
            tableHeaderclassName="header-table-groupe-view"
            wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
            className="table-responsive table-responsive-sm mt-3"
          >
            <TableHeaderColumn
              className="titre-tab"
              isKey
              filter={{
                type: "TextFilter",
                delay: 1000,
                placeholder: "Recherche  ",
              }}
              dataField="bakelisteFullname"
              thStyle={{ border: "none", fontWeight: "400" }}
              tdStyle={{
                border: "none",
                padding: "1rem",
                "font-size": "14px",
                "font-family": "Montserrat",
              }}
              width="250"
            >
              <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="email"
              className="titre-tab"
              // filter={{
              //   type: "TextFilter",
              //   delay: 1000,
              //   placeholder: "Recherche  ",
              // }}
              thStyle={{ border: "none", fontWeight: "400" }}
              tdStyle={{
                border: "none",
                padding: "1rem",
                "font-size": "14px",
                "font-family": "Montserrat",
              }}
            >
              <span className="color-nom-prenom-utilisateurs">Email</span>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="domaine"
              className="titre-tab"
              // filter={{
              //   type: "TextFilter",
              //   delay: 1000,
              //   placeholder: "Recherche  ",
              // }}
              thStyle={{
                border: "none",
                fontWeight: "400",
                textAlign: "center",
              }}
              tdStyle={{
                border: "none",
                // textAlign: "center",
                padding: "1rem",
                "font-size": "14px",
                "font-family": "Montserrat",
              }}
            >
              <span className="color-nom-prenom-utilisateurs">Domaine</span>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="action"
              dataFormat={actionFormatter}
              className="titre-tab"
              thStyle={{
                border: "none",
                fontWeight: "400",
                textAlign: "center",
              }}
              tdStyle={{
                border: "none",
                display: "flex",
                textAlign: "center",
                padding: "1.2rem",
                "font-size": "14px",
                "font-family": "Montserrat",
              }}
            >
              <span className="color-nom-prenom-utilisateurs"> Actions</span>
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </>
  );
};

export default AllBakeliste;
