import React, { Component } from "react";
import "./tableMyAllbakelistes.css";
import Skeleton from "react-loading-skeleton";

export default class skeletonAllBakeliste extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
 
  render() {
    return (
      <div className="p-b-200"> 
       <div className="col-lg-12">
           <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`2vh`} />
                </div>
              </div>
            </div>   
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`5vh`} />
                </div>
              </div>
            </div>

        <div className="row mb-3 mt-3">          
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
            </div>
            
            <div className="row mb-3 mt-3">          
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
            </div>

            <div className="row mb-3 mt-3">          
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
            </div>

            <div className="row mb-3 mt-3">          
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
            </div>

            <div className="row mb-3 mt-3">          
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
            </div>

            <div className="row mb-3 mt-3">          
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-2 col-xl-2 col-lg-2">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
                <div className="col-md-1 col-xl-1 col-lg-1">
                    <div className="style-du-skeleton">
                        <Skeleton width={`100%`} height={`4vh`} />
                    </div>
                </div>
            </div>
        </div>
    </div>
      
    );
  }
}
