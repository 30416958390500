import React, { Component } from "react";
import "./tableMyDomaine.css";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import axios from "axios";
import API from "../../variablesGlobales";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import * as Sentry from "@sentry/browser";
import { Modal } from "rsuite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col cadre">
        <p className="badge done-badge">Actif</p>

        {/* !!!!important!!!! */}
        {/* <p className="badge doing-badge">Doing</p> */}
        {/* <p className="badge done-badge">Done</p> */}
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return <Status active={cell} />;
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon
            className="icon-btn-icon-table"
            icon="edit-2"
            size={16}
          />
        </button>
      </div>
    );
  }
}

function uploadFormatter(cell, row) {
  return <Actions active={cell} />;
}

export default class tableMyDomaine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      domaines: [],
      allDomaines: [],
      id: 0,
      showEditModal: false, 
      size: "md",

      syllabus: {},
      // api : API.API,
      doamaine: {},
      domaine_name: "",
      description: "",
      status: "",
      fields: {},
      domaineID: "",
    };
  }

  componentDidMount() {
    this.getListDomaines();
    this.getListAllDomaines();
  }

  getListDomaines = () => {
    axios
      .get(this.state.api + "liste-domaines-actifs")
      .then((res) => {
        if (res.data.success === true) {
          //  console.log('domaines actifs', res.data.data)
          this.setState({
            domaines: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };
  editDomaineModal = (id) => {
    this.onGetDomaine(id);
    this.setState({
      showEditModal: true,
    });
  };
  onGetDomaine = (id) => {
    this.setState({
      isPageLoading: true,
    });
    axios
      .get(this.state.api + "domaines/" + id)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        // console.log(response, "res")
        if (response.data.success) {
          this.setState({
            doamaine: response.data.data,
            domaine_name: response.data.data.name,
            status: response.data.data.status,
            description: response.data.data.description,
            domaineID: response.data.data.id,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };
  close = () => {
    this.setState({
      showEditModal: false,
    });
  };

  onSubmitEditDetailsDomaine = (e) => {
    e.preventDefault();
    this.setState({
      editDomaineInProgress: true,
    });
    // var data = this.state.syllabus;
    var fd = new FormData();
    fd.append("name", this.state.domaine_name);
    fd.append("status", this.state.status);
    fd.append("description", this.state.description);

    axios
      .put(this.state.api + "domaines/" + this.state.domaineID, fd, {
        Headers: { "Content-Type": "multipart/formdata" },
      })
      .then((response) => {
        // console.log("start")
        // console.log("response", response)
        this.setState({
          editDomaineInProgress: true,
        });
        if (response.data.success === true) {
          // console.log("i m here")
          this.setState({
            showEditModal: false,
            editDomaineInProgress: false,
          });
          this.getListAllDomaines();
          toast.success(
            "Domaine modifiée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          this.setState({
            showEditModal: false,
          });
          toast.error(
            "erreur sur la modification",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 3000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
        this.setState({
          editSyllabusContentInProgress: false,
        });
        toast.error(
          "erreur sur la modification",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 3000 }
        );
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        Sentry.captureException(error);
      });
  };
  getListAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            allDomaines: res.data.data,

            loading: false,
          });
          //  console.log('listes tous les domaines', this.state.domaine_name);
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  actionsFormatter = (cell, row) => {
    let id = row.id;
    return (
      <span>
        {/* <Link 
              to={`/edit-domaine/${row.id}`}
            >
              <EditIcon className="btn-icon-table" />
            </Link>  */}
        <span className="modifier" onClick={() => this.editDomaineModal(id)}>
          <FeatherIcon
            className="icon-details-container-syllabus-item-back"
            icon="edit-3"
          />
          Modifier
        </span>
        {/* 
          <div
              className="modal fade"
              id={`showInfosModal${row.id}`}
              role="dialog"
              aria-labelledby="showInfosModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="bakelisteInfos-container">
                      <div className="fullname_container">
                        <span className="item_label">Nom Domaine: </span>{" "}
                        <span className="label_content">
                          {row.name}
                        </span>
                      </div>{" "}
                      <hr />
                      <div className="email_container">
                        <span className="item_label">statut : </span>{" "}
                        <span className="label_content">
                          {row.status}
                        </span>
                      </div>{" "}
                      <hr />
                      <div className="phone_container">
                        <span className="item_label">Description : </span>{" "}
                        <span className="label_content">
                          {row.description}
                        </span>
                      </div>{" "}
                      <hr />
                    
                      
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
            </div>
             */}
      </span>
    );
  };

  render() {
    return (
      <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
        <BootstrapTable
          data={this.state.allDomaines}
          striped
          version="4"
          bordered={false}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
            {" "}
            ID{" "}
          </TableHeaderColumn>
          <TableHeaderColumn
            // filter={{type:"TextFilter", delay: 1000}}
            dataField="name"
            width="250"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Domaines</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            className="titre-tab"
            width="250"
            dataField="description"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{ border: "none", padding: "1rem" }}
          >
            Descriptions
          </TableHeaderColumn>
          {/* <TableHeaderColumn className="titre-tab" dataField='statut' dataFormat={changeStatus} width="100" dataSort thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1rem' }}>Statuts</TableHeaderColumn> */}
          <TableHeaderColumn
            className="titre-tab"
            width="250" 
            dataField="action"
            dataFormat={this.actionsFormatter}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              display: "flex",
              justifyContent: "center",
              padding: "1rem",
            }}
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>
        <div>
          <hr />
        </div>

        <Modal
          className="modal-responsive-md"
          size={this.state.size}
          show={this.state.showEditModal}
          onHide={this.close}
        >
          <form onSubmit={(e) => this.onSubmitEditDetailsDomaine(e)}>
            <Modal.Header>
              <Modal.Title className="modal-contact-us">
                Modifier Domaine{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-height-modal">
              <div className="form-group as_input_container">
                <label>Nom du domaine</label>
                <input
                  value={this.state.domaine_name}
                  onChange={(e) =>
                    this.setState({ domaine_name: e.target.value })
                  }
                  name="syllabus_name"
                  // placeholder="Nom du syllabus"
                  className="form-control input_item"
                  id="name"
                />
              </div>
              <div className="form-group as_input_container">
                <label>Description du domaine</label>
                <textarea
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  name="description"
                  placeholder="Nom du syllabus"
                  className="form-control input_item"
                  id="description"
                />
              </div>
              <div className="form-group as_input_container">
                <select
                  name="status"
                  className="form-control input_item"
                  id="syllabus_level"
                  value={this.state.fields["domaine_id"]}
                  onChange={(e) => this.setState({ status: e.target.value })}
                >
                  <option value="actif"> Actif </option>
                  <option value="non-actif"> Non-actif </option>
                </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="row dis-flex justify-content-end">
                <div className="col-md-5 col-sm-6">
                  {this.state.editDomaineInProgress ? (
                    <button
                      type="submit"
                      className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                      disabled
                    >
                      Modification en cours &nbsp;
                      <FeatherIcon icon="loader" aria-hidden="true" />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                    >
                      MODIFIER
                    </button>
                  )}
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
