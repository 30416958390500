import React, { useEffect, useReducer } from "react";
import "./bakelisteEnLigne.css";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Tooltip } from "@material-ui/core";

import API from "../../variablesGlobales";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getBakelisteEnligneeAsync, showListBakeEnligne } from "../../redux/slices/bakelistesSlices/bakelistesSlice";
const BakelisteEnligneRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      bakelistesEnLigne: [],
      isLoaded: true,
    }
  );

  const dispatch = useDispatch()
  const bakelisteEnligneData = useSelector(showListBakeEnligne)
  useEffect(() => {
    dispatch(getBakelisteEnligneeAsync())
   // getBakelisteEnLigne();
  }, []);

//   const getBakelisteEnLigne = () => {
//     axios
//       .get(state.api + "bakelistes-training-online-list")
//       .then((response) => {
//         setState({
//           isLoaded: false,
//           bakelistesEnLigne: response.data.data,
//         });
//         // console.log(state.bakelistesEnLigne);
//       })
//       .catch((error) => {
//         // console.log(error.message);
//         if (process.env.NODE_ENV !== "production") {
//           return;
//         }
//       });
//   };
  const nameFormatter = (cell, row) => {
    return (
      <span>
        {/* <button> */}
        <Tooltip title="Voir la fiche">
          <Link
            className="linkName"
            to={{
              pathname: "/profil-utilisateur",
              state: { id: row.id, data: row },
            }}
          >
            {row.first_name} {row.last_name}{" "}
          </Link>
        </Tooltip>
        {/* </button> */}
      </span>
    );
  };
  return (
    <div className="p-b-200">
      <div className="container px-0">
        <div className="col-lg-12">
          <div className="card-dashboard m-b-15 p-b-60">
            {state.bakelistesEnLigne.length > 0 ? (
              <BootstrapTable
                data={bakelisteEnligneData}
                striped={true}
                hover={true}
                // options={options}
                search
                searchPlaceholder="Rechercher bakeliste..."
                multiColumnSearch
                // exportCSV
                // csvFileName="mes-bakelistes.csv"
                multiColumnSort={2}
                pagination
                bordered={true}
              >
                <TableHeaderColumn
                  isKey="true"
                  dataField="bakelisteFullname"
                  // dataFormat={nameFormatter}
                  dataFormat={nameFormatter}
                  filter={{ type: "TextFilter", delay: 1000 }}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  width="150"
                >
                  Nom complet
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="domaine"
                  width="100"
                  filter={{ type: "TextFilter", delay: 1000 }}
                >
                  Formation
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  dataSort
                  filter={{ type: "TextFilter", delay: 1000 }}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  width="170"
                >
                  Email
                </TableHeaderColumn>
              </BootstrapTable>
            ) : (
              <p className="pComment">
                Il n'y a pas encore de bakeliste en ligne.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BakelisteEnligneRefact;
