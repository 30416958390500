import React, { Component } from "react";
import "./event.css";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import API from "../../variablesGlobales";
import { Icon } from "rsuite";
import Skeleton from "react-loading-skeleton";

export default class dashboardAdminSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="p-b-200">        
        <div className="row mb-3">
            <div className="col-lg-9">
           <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`4vh`} />
                </div>
              </div>
            </div>
            <div className="card mt-3">
            <div className="row ml-3 mt-3">
              <div className="col-xl-10 col-lg-10 col-md-10">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`2vh`} />
                </div>
                <div className="style-du-skeleton">
                    <Skeleton width={`10%`} height={`2vh`} />
                </div>
                <div className="style-du-skeleton mt-4">
                    <Skeleton width={`80%`} height={`10vh`} />
                </div>
                 <div className="row mt-3">
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-2">
                        <div className="style-du-skeleton-event">
                            <Skeleton width={`40%`} height={`40%`} />
                        </div>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-2">
                        <div className="style-du-skeleton-event">
                            <Skeleton width={`40%`} height={`40%`} />
                        </div>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-2">
                     <div className="style-du-skeleton-event">
                            <Skeleton width={`40%`} height={`40%`} />
                        </div>
                    </div>
                 </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2">
                <div className="style-du-skeleton">
                    <Skeleton width={`7%`} height={`2vh`} />
                </div>
                </div>
            </div>
            </div>

            <div className="row mt-4 mb-2">
              <div className="col-xl-9 col-lg-9 col-md-9">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`5vh`} />
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="row ml-3 mt-3">
                <div className="col-xl-10 col-lg-10 col-md-10">
                 <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`2vh`} />
                </div>
                <div className="style-du-skeleton">
                    <Skeleton width={`10%`} height={`2vh`} />
                </div>
                <div className="style-du-skeleton mt-4">
                    <Skeleton width={`80%`} height={`10vh`} />
                </div>
                 <div className="row mt-3">
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-2">
                        <div className="style-du-skeleton-event">
                            <Skeleton width={`40%`} height={`40%`} />
                        </div>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-2">
                        <div className="style-du-skeleton-event">
                            <Skeleton width={`40%`} height={`40%`} />
                        </div>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-2">
                     <div className="style-du-skeleton-event">
                            <Skeleton width={`40%`} height={`40%`} />
                        </div>
                    </div>
                 </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2">
                <div className="style-du-skeleton">
                    <Skeleton width={`7%`} height={`2vh`} />
                </div>
                </div>
            </div>
            </div>

            <div className="row mt-4 mb-2">
              <div className="col-xl-9 col-lg-9 col-md-9">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`5vh`} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`4vh`} />
                </div>
              </div>
            </div>

            <div className="col-xl-5 col-lg-5 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`20vh`} />
                </div>   
            </div>
            <div className="row ml-3">
                <div className="style-du-skeleton">
                    <Skeleton width={`60%`} height={`7vh`} />
                </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
                <div className="style-du-skeleton">
                    <Skeleton width={`100%`} height={`20vh`} />
                </div>
            </div>
            <div className="row ml-3">
                <div className="style-du-skeleton">
                    <Skeleton width={`60%`} height={`7vh`} />
                </div>
            </div>
        </div>
    </div>  
    </div>
    );
  }
}
