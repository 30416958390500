import React from "react";
import "./coachAdmin.css";
// import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
// import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
// import ProgramReunionCoach from '../programReunionCoach/index';
// import SavedReunionListItem from '../savedReunionListItem/index';
import TableMyListCoachs from "../tableMyListCoachs/index";
import { Link, NavLink } from "react-router-dom";
import TableMyListCoachsRefac from "../tableMyListCoachs/TableMyListCoachsRefac";

const CoachAdmin = () => {
  return (
    <div className="p-b-200">
      <div className="container-fluid px-0">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav">
                Dashboard /
              </Link>
            </li>
            <li
              className="breadcrumb-item active lien_nav_current"
              aria-current="page"
            >
              Coachs
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="row m-b-15">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                Listes des Coachs
              </h6>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 ml-auto vla">
              <NavLink className="nav-link" to="/liste-coachs-archives">
                <button className="btn btn-theme-orange right-elements ">
                  Voir liste coachs archivés
                </button>
              </NavLink>
            </div>
          </div>
          <div className="card-dashboard m-b-15 p-b-60">
            <div className="row m-b-30 m-t-20">
              {/* <TableMyListCoachs /> */}
              <TableMyListCoachsRefac />
              {/* <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div> */}
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CoachAdmin;
