import React, { Component } from "react";
import "./stagiaire.css";
// import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
import axios from "axios";
import API from "../../variablesGlobales";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
// import { bindActionCreators } from "redux";
// import * as stagiaireActions from "../../store/stagiaire/actions";
export default class stagiaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
    };
  }
  // actionsFormatter = (cell, row) => {
  //   return (
  //     <span>
  //     <FeatherIcon
  //       icon="archive"
  
  //       id="delete_group"
  //       style={{
  //         color: "red",
  //         height: "21px",
  //         width: "22px",
  //         cursor: "pointer",
  //       }}
  //       onClick={() => this.archiverCoach(row.id)}
  //     />
  //     &nbsp;&nbsp;
  //     <Link
  //             to={{
  //               pathname: "/edit-coach",
  //               state: { id: row.id, data: row },
  //             }}
  //           >
  //     <FeatherIcon
  //       title="modifier les détails"
  //       icon="edit-2"
  //       style={{
  //         color: "#469789",
  //         height: "21px",
  //         width: "22px",
  //         cursor: "pointer",
  //       }}
  //       className="ajouter"
  //       cursor="pointer"
  //     />
  //     </Link>
  //     &nbsp;&nbsp;
     
     
  //   </span>
  //   );
  // };
  actionsFormatter = (cell, row) => {
    let id = row.id;

    return (
      <span>
        <Tooltip title="Re-integrer comme bakeliste">
          <FeatherIcon
            icon="user-plus"
            className="stageToBak"
            cursor="pointer"
            onClick={(e) => this.stagiaireToBakeliste(row, e)}
          />
        </Tooltip>
        &nbsp;&nbsp;
        <Tooltip title="Modifier les détails de ce stagiaire">
        <Link
              to={{
                pathname: "/edit-stagiare",
                state: { id: row.id, data: row },
              }}
            >
      <FeatherIcon
        title="modifier les détails"
        icon="edit-2"
        style={{
          color: "#469789",
          height: "21px",
          width: "22px",
          cursor: "pointer",
        }}
        className="ajouter"
        cursor="pointer"
      />
      </Link>
      </Tooltip>

      </span>
    );
  };
  stagiaireToBakeliste = (row) => {
    // console.log("okkk", row.id);
    var fm = new FormData();
    fm.append("bakeliste_id", row.id);
    axios.post(this.state.api + "stagiaire-to-bakeliste", fm).then((res) => {
      if (res.data.success === true) {
        toast.success(
          " Stagiaire reintegre avec succes",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 500 }
        );
      } else {
        toast.error(
          "Erreur sur la reintegration, réessayer!!!!'",
          { position: toast.POSITION.BOTTOM_LEFT },
          { autoClose: 1000 }
        );
      }
    });
  };
  render() {
    return (
      <div className="component-nav-tabs-utilisateurs">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="users"
                    />
                    Stagiares
                  </li>
                </ol>
              </nav>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                  Liste des stagiaires
                </h6>
              </div>
              <BootstrapTable
                data={this.props.Stagiaire}
                striped={true}
                hover={true}
                // options={options}
                search
                searchPlaceholder="Rechercher bakeliste..."
                multiColumnSearch
                // exportCSV
                // csvFileName="mes-bakelistes.csv"
                multiColumnSort={2}
                pagination
                bordered={true}
              >
                <TableHeaderColumn
                  isKey="true"
                  dataField="first_name"
                  // dataFormat={this.nameFormatter}
                  dataFormat={this.nameFormatter}
                  filter={{ type: "TextFilter", delay: 1000 }}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  width="150"
                >
                  Prénoms
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="last_name"
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  filter={{ type: "TextFilter", delay: 1000 }}
                  width="100"
                >
                  Noms
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="email"
                  dataSort
                  filter={{ type: "TextFilter", delay: 1000 }}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  width="170"
                >
                  Email
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField=""
                  dataFormat={this.actionsFormatter}
                  dataSort
                  // filter={{ type: "TextFilter", delay: 1000 }}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  width="170"
                >
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ stagiaire }) => ({ ...stagiaire }),
//     dispatch => bindActionCreators({ ...stagiaireActions }, dispatch)
//   )( stagiaire );
