import { colors, Tooltip } from "@material-ui/core";
import axios from "axios";
import React, { useReducer } from "react";
import { Link } from "react-router-dom";
import Hom from "../../img/wagaan_head.png";
import { default as API, default as BaseApi } from "../../variablesGlobales";
import "./cardUtilisateur.css";

import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Dropdown, Icon, IconButton, Modal } from "rsuite";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  archiverBakelisteAsync,
  bloquerUnBakeliste,
  changerStatutBakeliste,
  debloquerUnBakeliste,
  declareBakelisteRecrutable,
  mettreBakelistePause,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
toast.configure();
const CardUtilisateurRefact = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: [],
      api: API.API,
      base_api: BaseApi.base_api,
      bakeliste_domaine_id: 0,
      Tache: [],
      bakelistes: [],
      nbrSemaine: 0,
      Assiduite: "",
      Performance: "",
      Autonomie: "",
      Satisfactions: "",
      avisCoach: "",
      loading: true,
      getListeBakelisteRequestError: false,
      isLoaded: true,
      bakToSta: [],
      showPause: false,
      showAbandon: false,
      toBlock: false,
      toUnblock: false,
      showEditModal: false,
      size: "md",
      recruiter_raison_sociale: "",
      recruiter_domaine_pro: "",
      toStagiaire: false,
      recruteur_raison: "",
      recruteur_domaine: "",
      setOpen: false,
    }
  );

  const formatDate = (date) => {
    var d = new Date(date);
    let dated = new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(d);
    return dated;
  };

  const recrutable = (id) => {
    // console.log(id);

    var fd = new FormData();
    fd.append("bakeliste_nickname", "expert");
    fd.append("bakeliste_id", id);
    fd.append("label", "recruitable");
    // dispatch(declareBakelisteRecrutable(fd))
    axios
      .post(state.api + "change-bakeliste-to-recrutable", fd)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(
            " Ce bakeliste est désormais recrutable",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'action, réessayer!!!!'",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {});
  };
  const close = () => {
    setState({
      showEditModal: false,
    });
  };
  const changeStatusModal = (id) => {
    // onGetDomaine(id);
    setState({
      showEditModal: true,
    });
  };
  const blockUserAccount = () => {
    axios
      .post(state.api + "block-user-account/" + props.id)
      .then((response) => {
        // console.log(response.data.success);
        if (response.data.success === true) {
          props.getListeAllBakelistes();
          toast.success(
            "Compte bloque avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur bloquage compte",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const unblockUserAccount = () => {
    dispatch(debloquerUnBakeliste(props.id));
    // axios
    //   .post(state.api + "unblock-user-account/" + props.id)
    //   .then((response) => {
    //     // console.log(response.data.success);
    //     if (response.data.success === true) {
    //       props.getListeAllBakelistes();
    //       toast.success(
    //         "Compte debloque avec succes",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     } else {
    //       toast.error(
    //         "Erreur debloquage compte",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error.message);
    //   });
  };
  const changeBakelisteToPresentiel = (id) => {
    axios
      .post(state.api + "change-bakeliste-to-presentiel/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          props.getListeAllBakelistes();
          toast.success(
            "Format de formation changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du format de formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  const pauseBakelisteTraining = () => {
    var fd = new FormData();

    fd.append("bakeliste_id", props.id);
    dispatch(mettreBakelistePause(fd));

    // axios
    //   .post(state.api + "pause-bakeliste-training", fd)
    //   .then((response) => {
    //     if (response.data.success === true) {
    //       toast.success(
    //         " La formation est mise en pause",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     } else {
    //       toast.error(
    //         "Erreur sur l'action, réessayer!!!!'",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     }
    //   })
    //   .catch((error) => {});
  };

  const abandonBakelisteTraining = () => {
    var fd = new FormData();
    let date = new Intl.DateTimeFormat("fr-FR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date());
    // console.log(date);
    fd.append("bakeliste_date_abandon", date);
    fd.append("bakeliste_id", props.id);
    // fd.append("bakeliste_id", 58);

    axios
      .post(state.api + "declarer-bakeliste-abandon", fd)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(
            " Bakeliste declare comme abandonne avec succes",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'action, réessayer!!!!'",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {});
  };
  //  const changeBakelisteToPresentiel = (id) => {
  //     // console.log(id);
  //     axios
  //       .post(state.api + "change-bakeliste-to-presentiel/" + id)
  //       .then((response) => {
  //         // console.log(response);
  //         if (response.data.success === true) {
  //           props.getListeAllBakelistes();
  //           toast.success(
  //             "Format de formation changé avec success",
  //             { position: toast.POSITION.BOTTOM_LEFT },
  //             { autoClose: 1000 }
  //           );
  //         } else {
  //           toast.error(
  //             "Erreur sur le changement du format de formation",
  //             { position: toast.POSITION.BOTTOM_LEFT },
  //             { autoClose: 1000 }
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         // console.log(error.message);
  //       });
  //   };
  const changeBakelisteToOnline = (id) => {
    // console.log(id);
    axios
      .post(state.api + "change-bakeliste-to-online/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          props.getListeAllBakelistes();
          toast.success(
            "Format de formation changé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur le changement du format de formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const changeStatus = (e) => {
    e.preventDefault();

    setState({
      bakToStagiaireInProgress: true,
    });
    var fd = new FormData();
    fd.append("bakeliste_id", props.id);
    fd.append("recruteur_raison", state.recruteur_raison);
    fd.append("recruteur_domaine", state.recruter_domaine);

    dispatch(changerStatutBakeliste(fd));

    setTimeout(() => {
      setState({
        bakToStagiaireInProgress: false,
        showEditModal: false,
      });
    }, 2000);
    // axios
    //   .post(state.api + "bakeliste-to-stagiaire", fd)
    //   .then((response) => {
    //     // console.log(response);
    //     if (response.data.success === true) {
    //       setState({
    //         bakToSta: response.data.data,
    //         bakToStagiaireInProgress: false,
    //         showEditModal: false,
    //       });
    //       props.getListeAllBakelistes();
    //       toast.success(
    //         "Status changé avec success",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     } else {
    //       toast.error(
    //         "Erreur sur le changement du status",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error.message);
    //   });
  };

  const arreterFormation = (id) => {
    // console.log(id);
    axios
      .post(state.api + "completed-bakelistes-formation/" + id)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          props.getListeAllBakelistes();
          toast.success(
            "Formation arrêtée avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        } else {
          toast.error(
            "Erreur sur l'arrêt du formation",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  const archiverBakeliste = (id) => {
    dispatch(archiverBakelisteAsync(id));
    // axios
    //   .post(state.api + "archive-user/" + id)
    //   .then((response) => {
    //     // console.log(response);
    //     if (response.data.success === true) {
    //       props.getListeAllBakelistes();
    //       toast.success(
    //         "Bakeliste archivé avec success",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     } else {
    //       toast.error(
    //         "Erreur sur l'archivage de ce bakeliste!!!",
    //         { position: toast.POSITION.BOTTOM_LEFT },
    //         { autoClose: 1000 }
    //       );
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log(error.message);
    //   });
  };
  const getSyllabusBakeliste = () => {
    return (
      <>
        <div data-toggle="modal" data-target="#syllabusModal">
          <label className="cursor" data-toggle="tooltip"></label>
        </div>
        <div
          className="modal fade"
          id="syllabusModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="syllabusModalListe"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-content-bakeliste">
              <h5
                className="modal-bakeliste-title m-b-50"
                id="addLivraisonBakelisteTitle"
              >
                Liste des syllabus de
                {props.bakelisteFullname}
              </h5>
              <button
                type="button"
                className="close-modal-bakeliste-title"
                data-dismiss="modal"
                aria-label="Close"
              >
                <FeatherIcon icon="x" aria-hidden="true" />
              </button>
              <div className="modal-body row">
                <ul className="character-bullets-3 md-3 0ffset-4">
                  {props.syllabus?.map((item, index) => {
                    return <li>{item?.syllabus?.syllabus_name}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const editerBakeliste = (id) => {
    window.location = "/edit-bakeliste";
  };
  const handleOpen = () => {
    setState({
      setOpen: true,
    });
  };
  const closeSyllabus = () => {
    setState({
      setOpen: false,
    });
  };

  const id = props.id; 
  var pp = state.base_api + props.photo;
  return (
    <div className="component-card-utilisateur">
      <div className="card-item first-card-info">
        <div className="align-inf-bak">
          {}
          {props.photo !== null ? (
            <div className="image-cercle">
              <img src={pp} alt="" />
            </div>
          ) : (
            <div className="image-cercle">
              <img src={Hom} alt="" />
            </div>
          )}

          <div className="pt-2 pl-3">
            <Link
              className="link-details-container-syllabus-item-back"
              to={{
                pathname: "/profil-utilisateur",
                state: { id: id },
              }}
            >
              <p className="fat-ma">
                <span className="d-flex">{props.bakelisteFullname}</span>
              </p>
              <p>
                <span className="pro-mail">{props.domaine}</span>
                <span className="d-flex mail-color">{props.email}</span>
                <span className="pro-mail">{props.phone}</span>
              </p>
            </Link>
          </div>
          <div className="ml-auto ">
            <div class="dropdown ">
              <Icon
                icon="ellipsis-v"
                className="icon-dropdown-syllabus-item-tache curseurDrop mr-1 mt-2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              />
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <Dropdown.Item>
                  <button
                    className="btn col "
                    // data-toggle="modal"
                    // data-target="#syllabusModal"
                    // dataFormat={getSyllabusBakeliste}
                    onClick={handleOpen}
                  >
                    Voir les syllabus
                  </button>

                  <Modal
                    className="modal-responsive-md"
                    size={state.size}
                    show={state.setOpen}
                    onHide={closeSyllabus}
                  >
                    <Modal.Header>
                      <Modal.Title className="modal-contact-us">
                        <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                          Liste des syllabus de
                          {props.bakelisteFullname}
                        </h6>
                      </Modal.Title>
                    </Modal.Header>

                    <div className="modal-body row">
                      <ul className="character-bullets-3 md-3 0ffset-4">
                        {props.syllabus?.map((item, index) => {
                          return <li>{item?.syllabus?.syllabus_name}</li>;
                        })}
                      </ul>
                    </div>
                  </Modal>
                </Dropdown.Item>
                {props.is_completed === 1 ? (
                  <Dropdown.Item>
                    <button className="btn col " disabled>
                      formation en arrêt
                    </button>
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item>
                    <button
                      className="btn "
                      onClick={() => arreterFormation(props.id)}
                    >
                      Arrêter la formation
                    </button>
                  </Dropdown.Item>
                )}

                {props.is_presentiel === 1 ? (
                  <Dropdown.Item>
                    <button
                      className="btn "
                      onClick={() => changeBakelisteToOnline(props.id)}
                    >
                      Mettre en ligne
                    </button>
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item>
                    <button
                      className="btn "
                      onClick={() => changeBakelisteToPresentiel(props.id)}
                    >
                      Mettre en présentiel
                    </button>
                  </Dropdown.Item>
                )}
                <Dropdown.Item>
                  <button
                    className="btn "
                    onClick={() => setState({ showAbandon: true })}
                  >
                    Déclarer comme abandonné{" "}
                  </button>
                </Dropdown.Item>
                {props.is_archived === 0 ? (
                  <Dropdown.Item>
                    <button
                      className="btn "
                      onClick={() => archiverBakeliste(props.id)}
                    >
                      Archiver
                    </button>
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item>
                    <button className="btn ">Bakeliste Archivé</button>
                  </Dropdown.Item>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-12 mb-2 ml-2">
            <p>
              <span className="green-color">Coach : </span>
              <span className="comp-fonct"> {props.coachFullname} </span>
            </p>
          </div>
          <div className="col-md-6 col-lg-6 mb-3">
            <p>
              <span className="green-color">Debut : </span>
              <span className="comp-fonct">
                {" "}
                {formatDate(props.debut_formation)}
              </span>
            </p>
          </div>
          <div className="col-md-6 col-lg-6 mb-3">
            <p>
              <span className=" green-color">Fin : </span>
              <span className="comp-fonct">
                {" "}
                {formatDate(props.fin_formation)}{" "}
              </span>
            </p>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="row m-2">
              <div className="col-md-2">
                <Tooltip title="Déclarer comme stagiaire">
                  <div class="icon-wrapper">
                    <i
                      className="fas fa-user-graduate custom-icon"
                      onClick={() => setState({ toStagiaire: true })}
                    >
                      {" "}
                    </i>
                  </div>
                </Tooltip>
              </div>

              <Modal
                className="modal-responsive-md"
                size={state.size}
                show={state.showEditModal}
                onHide={close}
              >
                <form onSubmit={(e) => changeStatus(e)}>
                  <Modal.Header>
                    <Modal.Title className="modal-contact-us">
                      <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                        Changer bakeliste en stagiaire{" "}
                      </h6>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="max-height-modal">
                    <div className="form-group as_input_container">
                      <label>Domaine</label>
                      <input
                        // value={state.recruteur_raison}
                        onChange={(e) =>
                          setState({ recruteur_domaine: e.target.value })
                        }
                        name="recruteur_domaine"
                        placeholder="Domaine du recruteur"
                        className="form-control input_item"
                        id="name"
                      />
                    </div>
                    <div>
                      <label>Raison social</label>
                      <input
                        // value={state.recruteur_raison}
                        onChange={(e) =>
                          setState({ recruteur_raison: e.target.value })
                        }
                        name="recruteur_raison"
                        placeholder="Domaine du recruteur"
                        className="form-control input_item"
                        id="name"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="row dis-flex justify-content-end">
                      <div className="col-md-5 col-sm-6">
                        {state.bakToStagiaireInProgress ? (
                          <button
                            type="submit"
                            className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                            disabled
                          >
                            En cours &nbsp;
                            <FeatherIcon icon="loader" aria-hidden="true" />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                          >
                            Valider
                          </button>
                        )}
                      </div>
                    </div>
                  </Modal.Footer>
                </form>
              </Modal>

              <div className="col-md-2">
                {props.is_recruitable === 1 ? (
                  <Tooltip title="Déja recrutable">
                    <div class="icon-wrapper">
                      <i className="fas fa-user-check custom-icon"> </i>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title="Déclarer comme recrutable">
                    <div class="icon-wrapper">
                      <i
                        className="fas fa-file-certificate custom-icon-orange"
                        onClick={() => recrutable(props.id)}
                      >
                        {" "}
                      </i>
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="col-md-2">
                <Tooltip title="Mettre la formation en pause">
                  <div class="icon-wrapper">
                    <i
                      className="fa fa-pause custom-icon-red"
                      onClick={() => setState({ showPause: true })}
                    >
                      {" "}
                    </i>
                  </div>
                </Tooltip>
              </div>
              <div>
                <SweetAlert
                  warning
                  show={state.toStagiaire}
                  title="Voulez vous transformez ce bakeliste en stagiaire?"
                  // text="SweetAlert in React"
                  confirmBtnText="Oui"
                  cancelBtnText="Non"
                  onConfirm={() => {
                    // console.log("confirm");
                    setState({ toStagiaire: false });
                    // pauseBakelisteTraining();
                    changeStatusModal();
                  }}
                  showConfirm={true}
                  showCloseButton={true}
                  onCancel={() => {
                    // console.log("cancel");
                    setState({ toStagiaire: false });
                  }}
                  showCancel={true}
                  focusCancelBtn={true}
                  cancelBtnBsStyle="danger"
                />
              </div>
              <div className="col-md-2">
                <Tooltip title="Editer">
                  <div class="icon-wrapper">
                    {/* ************************************************************************************* */}
                    <Link
                      to={{
                        pathname: "/edit-bakeliste",
                        state: { id: props.id, data: props.data },
                      }}
                    >
                      <i className="fas fa-user-edit custom-icon"> </i>
                    </Link>
                  </div>
                </Tooltip>
              </div>
              <div className="col-md-2">
                <Tooltip title="Voir CV">
                  <Link to="/show-bakeliste-cv">
                    <div class="icon-wrapper">
                      <i className="fas fa-file custom-icon-orange"> </i>
                    </div>
                  </Link>
                </Tooltip>
              </div>

              <div>
                <SweetAlert
                  warning
                  show={state.showPause}
                  title="Voulez vous mettre la formation en pause?"
                  // text="SweetAlert in React"
                  confirmBtnText="Oui"
                  cancelBtnText="Non"
                  onConfirm={() => {
                    // console.log("confirm");
                    setState({ showPause: false });
                    pauseBakelisteTraining();
                  }}
                  showConfirm={true}
                  showCloseButton={true}
                  onCancel={() => {
                    // console.log("cancel");
                    setState({ showPause: false });
                  }}
                  showCancel={true}
                  focusCancelBtn={true}
                  cancelBtnBsStyle="danger"
                />
              </div>
              <div className="col-md-2">
                {props.is_blocked === 1 ? (
                  <Tooltip title="Deblloquer le compte">
                    <div class="icon-wrapper">
                      <i
                        className="fas fa-unlock custom-icon-red"
                        onClick={() => setState({ toUnblock: true })}
                      >
                        {" "}
                      </i>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title="Bloquer le compte">
                    <div class="icon-wrapper">
                      <i
                        className="fas fa-lock custom-icon-red"
                        onClick={() => setState({ toBlock: true })}
                      >
                        {" "}
                      </i>
                    </div>
                  </Tooltip>
                )}
              </div>
              <SweetAlert
                warning
                show={state.showAbandon}
                title="Voulez vous declarer ce bakeliste comme abandonne?"
                // text="SweetAlert in React"
                confirmBtnText="Oui"
                cancelBtnText="Non"
                onConfirm={() => {
                  // console.log("confirm");
                  setState({ showAbandon: false });
                  abandonBakelisteTraining();
                }}
                showConfirm={true}
                showCloseButton={true}
                onCancel={() => {
                  // console.log("cancel");
                  setState({ showAbandon: false });
                }}
                showCancel={true}
                focusCancelBtn={true}
                cancelBtnBsStyle="danger"
              />
            </div>
            <div>
              <SweetAlert
                warning
                show={state.toBlock}
                title="Voulez vous bloquer ce compte?"
                // text="SweetAlert in React"
                confirmBtnText="Oui"
                cancelBtnText="Non"
                onConfirm={() => {
                  setState({ toBlock: false });
                  blockUserAccount();
                }}
                showConfirm={true}
                showCloseButton={true}
                onCancel={() => {
                  // console.log("cancel");
                  setState({ toBlock: false });
                }}
                showCancel={true}
                focusCancelBtn={true}
                cancelBtnBsStyle="danger"
              />
            </div>
            <div>
              <SweetAlert
                warning
                show={state.toUnblock}
                title="Voulez vous debloquer ce compte?"
                // text="SweetAlert in React"
                confirmBtnText="Oui"
                cancelBtnText="Non"
                onConfirm={() => {
                  setState({ toUnblock: false });
                  unblockUserAccount();
                }}
                showConfirm={true}
                showCloseButton={true}
                onCancel={() => {
                  // console.log("cancel");
                  setState({ toUnblock: false });
                }}
                showCancel={true}
                focusCancelBtn={true}
                cancelBtnBsStyle="danger"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardUtilisateurRefact;
