import FeatherIcon from "feather-icons-react";
import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  desarchiverBakelisteAsync,
  getBakelisteArchivesAsync,
  showListBakeArchives,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import TableBootstrap from "./TableBootstrap";

const AllBakelisteArchives = () => {
  const dispatch = useDispatch();
  const bakelisteArchivesData = useSelector(showListBakeArchives);

  useEffect(() => {
    dispatch(getBakelisteArchivesAsync());
  }, []);

  const actionFormatter = (cell, row) => {
    return (
      <span className="text-center" style={{ cursor: "pointer" }}>
        <span
          className="lien_nav_current"
          onClick={() => dearchiverBakliste(row.id)}
        >
          <FeatherIcon icon="corner-down-left" />
        </span>
      </span>
    );
  };

  const dearchiverBakliste = (bakeliste_id) => {
    dispatch(desarchiverBakelisteAsync(bakeliste_id));
  };

  // Bootstrap table name formatter
  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/bakelistes_new" className="lien_nav_current">
                Dashboard bakeliste
              </Link>
            </li>
            <li className="breadcrumb-item lien_nav_current">
              Liste des bakelistes archivés
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-11">
          <TableBootstrap
            userData={bakelisteArchivesData}
            csvName="Liste bakeliste archivés"
            shouldExportCsv
            shouldFormatName
            shouldPaginate={bakelisteArchivesData?.length > 10}
            shouldSearch
            shouldShowPhoneNumber
            shouldFormatAction
            nameFormatter={nameFormatter}
            actionFormatter={actionFormatter}
          />
        </div>
      </div>
    </>
  );
};

export default AllBakelisteArchives;
