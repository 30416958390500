import React, {Component} from 'react';
import './listeBakelisteThiesView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
// import ListeBakelisteThies from '../listeBakelisteThies/index';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeBakelisteThiesViewActions from "../../store/listeBakelisteThiesView/actions";
export default class listeBakelisteThiesView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
              <div className="component-liste-bakeliste-thies-view body-theme">
                 <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      {/* <ListeBakelisteThies /> */}
                    </div>
                  </div>
                </div>
                </div>
        );
    }
  }
// export default connect(
//     ({ listeBakelisteThiesView }) => ({ ...listeBakelisteThiesView }),
//     dispatch => bindActionCreators({ ...listeBakelisteThiesViewActions }, dispatch)
//   )( listeBakelisteThiesView );