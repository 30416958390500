import React, { useReducer } from "react";
import "./editBakelisteView.css";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import EditBakeliste from "../editBakeliste/index";
import qs from "qs";
import EditBakelisteRefact from "../editBakeliste/EditBakelisteRefact";
const EditBakelisteViewRefact = (props) => {
  let id = props.location?.state?.id;
  let data = props.location?.state.data;
  if (!id && props.location.search) {
    const obj = qs.parse(props.location.search.replace("?", ""));
    id = parseInt(obj.id);
  }
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: id,
      data: data,
    }
    );
    console.log("datan", data)
  return <div className="body-theme">
    <HeaderDashboardAdmin />
    <div className="container-fluid fixing-after-header">
      <div className="row">
        <div className="col-lg-2 p-0">
          <NavVerticalAdmin />
        </div>
        <div className="col-lg-10 pt-3">
          <EditBakelisteRefact
            id={state.id}
            domaine_id={state.data?.bakeliste_domaine_id}
            first_name={state.data.first_name}
            last_name={state.data.last_name}
            full_name={state.data.user_fullname}
            domaine={state.data.domaine}
            lieu_formation={state.data.lieu_formation}
            debut_formation={state.data.debut_formation}
            fin_formation={state.data.fin_formation}
            adresse={state.data.address}
            ecole={state.data.ecole}
            phone={state.data.phone}
            email={state.data.email}
            horaire={state.data.horaire}
            paiement_inscription={state.data.paiement_inscription}

          />
        </div>
      </div>
    </div>


  </div>;
};

export default EditBakelisteViewRefact;
