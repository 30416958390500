import React, { Component } from "react";
import "./bakelisteEnPresentiel.css";
import { Link, NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import TableFormationCerfiante from "../tableFormationCertifiante/index";
import { AutoComplete, Icon, InputGroup } from "rsuite";
import AddCréneauxHoraire from "../addCréneauxHoraire/index";
import { Tooltip } from "@material-ui/core";

import API from "../../variablesGlobales";
import axios from "axios";

export default class bakelisteEnPresentiel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      bakelistesEnPresentiel: [],
      isLoaded: true,

    };
  }


  componentDidMount() {
    this.getBakelisteEnPresentiel();
  }

  getBakelisteEnPresentiel = () => {
    axios
      .get(this.state.api + "bakelistes-training-presentiel-list")
      .then((response) => {
        this.setState({ isLoaded: false, bakelistesEnPresentiel: response.data.data });
      // console.log(this.state.bakelistesEnPresentiel);

      })
      .catch((error) => {
        // console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
      });
  };
  nameFormatter = (cell, row) => {
    return (
      <span>
        {/* <button> */}
        <Tooltip title="Voir la fiche">
          <Link className="linkName"
            to={{
              pathname: "/profil-utilisateur",
              state: { id: row.id, data: row, },
            }}
          >
            {row.first_name} {row.last_name}{" "}
          </Link>
          </Tooltip>
        {/* </button> */}

      </span>
    );
  };
  render() {
    return (
      <div className="p-b-200">
        <div className="container px-0">
            <div className="col-lg-12">
              <div className="card-dashboard m-b-15 p-b-60">
              {this.state.bakelistesEnPresentiel.length > 0 ? (
                <BootstrapTable
                  data={this.state.bakelistesEnPresentiel}
                  striped={true}
                  hover={true}
                  // options={options}
                  search
                  searchPlaceholder="Rechercher bakeliste..."
                  multiColumnSearch
                  // exportCSV
                  // csvFileName="mes-bakelistes.csv"
                  multiColumnSort={2}
                  pagination
                  bordered={true}
                >
                  <TableHeaderColumn
                    isKey="true"
                    dataField="bakelisteFullname"
                    // dataFormat={this.nameFormatter}
                    dataFormat={this.nameFormatter}
                    filter={{ type: "TextFilter", delay: 1000 }}
                    tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    width="150"
                  >
                    Nom complet
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="domaine"
                    width="100"
                    filter={{ type: "TextFilter", delay: 1000 }}
                  >
                    Formation
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="email"
                    dataSort
                    filter={{ type: "TextFilter", delay: 1000 }}
                    tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    width="170"
                  >
                    Email
                  </TableHeaderColumn>
                </BootstrapTable>
              ):(

                <p className="pComment">
                Il n'y a pas encore de bakeliste en présentiel.
              </p>
              )}
              </div>
            </div>
        </div>
      </div>
    );
  }
}
