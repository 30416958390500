import React, { Component } from "react";
import "./listeEventView.css";
import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import ListeEvent from "../listeEvent/index";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Carbon_event from '../../img/carbon_event.png';
import axios from "axios";
import API from "../../variablesGlobales";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as listeEventViewActions from "../../store/listeEventView/actions";
export default class listeEventView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      evenements: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.getEvenement();
  }
  getEvenement = () => {
    this.setState({
      loading: true,
    });
    axios
      .get(this.state.api + "evenements")
      .then((res) => {
        if (res.data.success === true) {
          // console.log("evenement", res);
          this.setState({
            evenements: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
        }
        
      })
      .catch((error) => {
        this.setState({
          loading: false,
          getBakelisteRequestError: true,
        });
        console.log(error.message);
      });
  };

  render() {
//     return  <div className="body-theme">
//     <HeaderDashboardAdmin />
//     <div className="container-fluid fixing-after-header">
//       <div className="row">
//         <div className="col-lg-2 p-0">
//           <NavVerticalAdmin />
//         </div>
//         <div className="row">
//           <div className="col-lg-7 pt-3">
//           {this.state.evenements.map((item, index) => (
//                 <ListeEvent
//                   name={item.rencontre_name}
//                   date={item.rencontre_start_date}
//                   index={index}
//                 />
//               ))}
//           </div>
//           <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
 
//           <div className="row">
//               <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

//                   <BakelisteLateItemForAdmin />
//                 </div>
//               <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

//                   <BakelisteMissingItemForAdmin />
//                 </div>
//               <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

//                   <ProgramReunionCoach />
//                 </div>
//               <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

//                   <SavedReunionListItem />
//                 </div>
//           </div>

//           </div>
        
//         </div>
       
//       </div> 
//     </div>

    
//   </div>;
// }

//     return  <div className="body-theme">
//     <HeaderDashboardAdmin />
//     <div className="container-fluid fixing-after-header">
//       <div className="row">
//         <div className="col-lg-2 p-0">
//           <NavVerticalAdmin />
//         </div>
        
//         <div className="row">
//         <div className="col-lg-7 pt-3">
//         {this.state.evenements.map((item, index) => (
//                 <ListeEvent
//                   name={item.rencontre_name}
//                   date={item.rencontre_start_date}
//                   index={index}
//                 />
//               ))}
//         </div>
        
        // <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
 
        //   <div className="row">
        //       <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

        //           <BakelisteLateItemForAdmin />
        //         </div>
        //       <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

        //           <BakelisteMissingItemForAdmin />
        //         </div>
        //       <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

        //           <ProgramReunionCoach />
        //         </div>
        //       <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">

        //           <SavedReunionListItem />
        //         </div>
        //   </div>

        // </div>
         
        
//         </div> 
      
//       </div> 
//     </div>

    
//   </div>;
// }
// }
    return (
      <div className="component-liste-event-view body-theme">
        <HeaderDashboardAdmin />
        <div className="container-fluid fixing-after-header">
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-2 p-0">
              <NavVerticalAdmin />
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7 pt-3">
              {/* <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <h6 className="h-theme ff-inter fw-600 px-3">
                    {" "}
                    
                    Liste des Evenements
                  </h6>
                </div>
              </div> */}
              <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
              <li class="breadcrumb-item ">
                <Link to="/dashboard-admin" className="lien_nav">Dashboard</Link>
              </li>
              {/* <li class="breadcrumb-item " aria-current="page">
                <Link to="/evenements" className="lien_nav">Evènements</Link>
              </li> */}
              
              <li class="breadcrumb-item active" aria-current="page"> 
             Liste des événements
              </li>
            </ol>

              </nav>

              <div className="col-md-4 mb-3">
                 <Link to="/create-event" className="link"> 
                 <div className="creation-event-btn">
                  <div className="dep-event">
                      <span className="event-creer ml-3">
                      Creer un evenement 
                        
                        <img src={Carbon_event} className="color-icon-img ml-4" alt=""/>
                      </span>
                      </div>
                  </div>
                  </Link>
                </div>
                
              <div className="df">
              {this.state.evenements.map((item, index) => (
                <ListeEvent
                  name={item.rencontre_name}
                  date={item.rencontre_start_date}
                  index={index}
                />
              ))}
            </div>
            </div>


            <div className="col-xl-3 col-lg-3 col-md-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ listeEventView }) => ({ ...listeEventView }),
//     dispatch => bindActionCreators({ ...listeEventViewActions }, dispatch)
//   )( listeEventView );
