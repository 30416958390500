import React, {Component} from 'react';
import './cardSuiviBakelisteView.css';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import CardSuiviBakeliste from '../cardSuiviBakeliste/index';
import NavTabsLocalite from '../navTabsLocalite/navTabsLocalite';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as cardSuiviBakelisteViewActions from "../../store/cardSuiviBakelisteView/actions";
export default class cardSuiviBakelisteView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return( 
            <div className="component-card-suivi-bakeliste-view body-theme">
                 <HeaderDashboardAdmin />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalAdmin />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <CardSuiviBakeliste/>
                    </div>
                  </div>
                </div>
              </div>
        );
    }
  }
// export default connect(
//     ({ cardSuiviBakelisteView }) => ({ ...cardSuiviBakelisteView }),
//     dispatch => bindActionCreators({ ...cardSuiviBakelisteViewActions }, dispatch)
//   )( cardSuiviBakelisteView );