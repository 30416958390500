import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../../variablesGlobales";

const initialState = {
  allNotifications: [],
  unreadNotifications: [],
};

const API_URL = API;

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,

  reducers: {
    getNotifications: (state, action) => {
      state.allNotifications = action.payload;
    },

    getUnreadNotifications: (state, action) => {
      state.unreadNotifications = action.payload;
    },
  },
});

export const getNotificationsAsync = (user_id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}get-user-notification/${user_id}`);
    if (res.data.success) {
      dispatch(getNotifications(res.data.data));
      dispatch(getUnreadNotifications(res.data?.unread));
    }
  } catch (error) {
    console.log(error);
  }
};

export const markAllAsRead = (user_id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${API_URL}mark-notification-as-read/${user_id}`
    );
    if (res.data.success) {
      dispatch(getNotificationsAsync(user_id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const { getNotifications, getUnreadNotifications } =
  notificationSlice.actions;

export const getAllNotifications = (state) =>
  state?.notifications?.allNotifications;

export const getAllUnreadNotifications = (state) =>
  state?.notifications?.unreadNotifications;

export default notificationSlice.reducer;
