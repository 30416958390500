import React, { Component } from "react";
import "./statisticBakeliste.css";
import { Link } from "react-router-dom";
import { Icon } from "rsuite";
import FeatherIcon from "feather-icons-react";

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as statisticBakelisteActions from "../../store/statisticBakeliste/actions";
export default class statisticBakeliste extends Component {
  // constructor(props) {
  //     super(props);
  //     this.state = {};
  // }
  render() {
    return (
      <div className="component-statistic-bakeliste">
        {/* <h6 className="titre_utilisateurs m-t-10">Statistiques Bakeliste</h6> */}

        <div className="row m-t-60 m-b-30">
          <div className="col-xl-2 col-lg-2 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
            <div className="item-statistics-dashboard ">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.allBakelistes}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Total
                </p>
                <div className="eclipse">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="users"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
            <div className="item-statistics-dashboard ">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.countbakactifs}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Actifs
                </p>
                <div className="eclipse">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="user-check"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-2 col-lg-2 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
            <div className="item-statistics-dashboard ">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {" "}
                  {this.props.countbakpresentiel}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  En Presentiel
                </p>
                <div className="eclipse1">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="book-open"
                  />
                  {/* <img
                    className="img-item-statistics-dashboard styleicon"
                    src={IconStat3}
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-2 col-lg-2 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
            <div className="item-statistics-dashboard ">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.countbakabandon}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Abandonnes
                </p>
                <div className="eclipse2">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="x-circle"
                  />{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
            <div className="item-statistics-dashboard ">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.countbakonline}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  En Ligne
                </p>
                <div className="eclipse">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="wifi"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-6 pr-md-2 pl-md-2 mb-3 dis-flex">
            <div className="item-statistics-dashboard ">
              <div>
                <p className="chiffre-item-statistics-dashboard nbr">
                  {this.props.countbakpause}
                </p>
                <p className="designation-item-statistics-dashboard txt">
                  Pause
                </p>
                <div className="eclipse2">
                  <FeatherIcon
                    className="img-item-statistics-dashboard styleicon"
                    icon="pause-circle"
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ statisticBakeliste }) => ({ ...statisticBakeliste }),
//     dispatch => bindActionCreators({ ...statisticBakelisteActions }, dispatch)
//   )( statisticBakeliste );
