import React, { Component } from "react";
import "./statistiqueAdmin.css";
import FeatherIcon from "feather-icons-react";
import { Tabs, Tab, Modal, Row, Col, Form, Container } from "react-bootstrap";
import BakelisteLateItemForAdmin from "../bakelisteLateItemForAdmin/index";
import BakelisteMissingItemForAdmin from "../bakelisteMissingItemForAdmin/index";
import ProgramReunionCoach from "../programReunionCoach/index";
import SavedReunionListItem from "../savedReunionListItem/index";
import TableMyCoachs from "../tableMyCoachs/index";
import { Link } from "react-router-dom";

import Chart from "react-apexcharts";
import API from "../../variablesGlobales";
import GrapheLivraisons from "../grapheLivraisons/grapheLivraisons";
import GrapheCommentaires from "../grapheCommentaires/grapheCommentaires";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import axios from "axios";

export default class statistiqueAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: API.API,
      categories: [],
      bakelistes: [],
      prospects: [],
      visiteurs: [],
      participants: [],
      participants_last: [],
    };
  }


  componentDidMount() {
    this.getUsersGrapheData();
    this.getParticipantsGrapheData();
  }

  getUsersGrapheData() {
    axios
      .get(this.state.api + "bakelistes-counter")
      .then((response) => {
        this.setState({
          categories: response.data.dataMonths,
          bakelistes: response.data.dataBakelistes,
          prospects: response.data.dataProspects,
          visiteurs: response.data.dataVisiteurs,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getParticipantsGrapheData() {
    axios
      .get(this.state.api + "count-participants")
      .then((response) => {
        this.setState({
          participants: response.data.participants,
          participants_last: response.data.participantslast,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  makeGraphe(categories, bakeliste, prospect, visiteur) {
    const options = {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: this.state.categories,
        //  [
        //   "Jan",
        //   "Fev",
        //   "Mars",
        //   "Avril",
        //   "Mai",
        //   "Juin",
        //   "Juil",
        //   "Août",
        //   "Sep",
        //   "oct",
        //   "Nov",
        //   "Dec",
        // ],
      },
      colors: ["#069A77", "#FF9800", "#F44336"],
    };
    const series = [
      {
        name: "Bakeliste",
        data: [32, 54, 60, 20, 70, 92, 113, 113, 32, 116, 113, 120],
      },
      {
        name: "Prospect",
        data: [11, 20, 81, 20, 11, 58, 32, 32, 32, 40, 69, 30],
      },
      {
        name: "Visiteur",
        data: [32, 11, 20, 20, 11, 20, 11, 32, 50, 25, 30, 30],
      },
    ];
  }
  render() {
    let bakelisteCounter = [];
    this.state.bakelistes.forEach((item) => {
      bakelisteCounter.push(item);
    });
    // console.log(this.state.bakelistes);

    return (
      <div className="p-b-200">
        <div className="container-fluid px-0">
          <div className="row px-3">
            <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="bar-chart-2"
                    />
                    Statistique
                  </li>
                </ol>
              </nav>
              <div className="row">
                <div className="col-md-12">
                  <h6 className="h-theme ff-inter fw-600">
                    Représentation de Bakeli en statistique
                  </h6>
                  <div className="section-content">
                    <Chart
                      options={{
                        chart: {
                          id: "apexchart-example",
                        },
                        xaxis: {
                          categories: this.state.categories,
                        },
                        colors: ["#069A77", "#FF9800", "#F44336"],
                      }}
                      series={[
                        {
                          name: "Bakeliste",
                          data: this.state.bakelistes,
                        },
                        {
                          name: "Prospect",
                          data: this.state.prospects,
                        },
                        {
                          name: "Visiteur",
                          data: this.state.visiteurs,
                        },
                      ]}
                      type="bar"
                    />
                  </div>
                </div>
              </div>


              <div className="row m-b-15">
                <div className="col m-b-15">
                  <GrapheLivraisons /> <GrapheCommentaires />
                 
                </div>
              </div>
              <div className="row m-b-15">
                <div className="col m-b-15">
                  <h6 className="h-theme ff-inter fw-600 m-t-20">
                    Représentation des participants aux evenements
                  </h6>
                  <div className="section-content">
                    <Chart
                      options={{
                        chart: {
                          id: "apexchart1-example",
                        },
                        xaxis: {
                          categories: [
                            "Jan",
                            "Fev",
                            "Mars",
                            "Avril",
                            "Mai",
                            "Juin",
                            "Juil",
                            "Août",
                            "Sep",
                            "oct",
                            "Nov",
                            "Dec",
                          ],
                        },
                        colors: ["#069A77", "#FF9800", "#F44336"],
                      }}
                      series={[
                        {
                          name: "Cette annee",
                          data: this.state.participants,
                        },
                        {
                          name: "Annee passee",
                          data: this.state.participants_last,
                        },
                        // {
                        //   name: "Visiteur",
                        //   data: this.state.visiteurs,
                        // },
                      ]}
                      type="bar"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
              <div className="row">
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteLateItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <BakelisteMissingItemForAdmin />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                  <ProgramReunionCoach />
                </div>
                <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                  <SavedReunionListItem />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
