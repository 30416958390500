import React, {Component} from 'react';
import './navTabsFormationAdmin.css';
import FeatherIcon from "feather-icons-react";
import { Link} from 'react-router-dom';
import {Tabs, Tab, Modal, Row,  Col, Form, Container} from "react-bootstrap";
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import FormationAdmin from '../formationAdmin/index';
// import DomaineAdmin from '../domaineAdmin/index';
import TechnologieAdmin from '../technologieAdmin/index';
// import SyllabusAdmin from '../syllabusAdmin/index';
import ListeDomainesAdmin from "../listeDomainesAdmin/index";


// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as navTabsFormationAdminActions from "../../store/navTabsFormationAdmin/actions";
export default class navTabsFormationAdmin extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
          <div className="component-nav-tabs-formation-admin">
            <div className="container-fluid px-0">
             <div className="row">
              <div className="col-lg-9">
              <nav>
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="award"
                    />
                    Formation
                  </li>
                </ol>
              </nav>
                <Container>
                  <Row>
                    <Col>
                      <Tabs defaultActiveKey="Outils" 
                                      id="controlled-tab-example">
                        <Tab eventKey="Outils" title="Outils">                     
                            <FormationAdmin/>
                        </Tab>
                        <Tab eventKey="Domaine" title="Domaine">
                        <ListeDomainesAdmin />

                        </Tab>
                        <Tab eventKey="Technologie" title="Technologie">
                          <TechnologieAdmin />
                        </Tab>
                        <Tab eventKey="Syllabus" title="Syllabus">
                          {/* <SyllabusAdmin /> */}
                        </Tab>
                       </Tabs>
                      </Col>
                     </Row>
                   </Container>
                  </div>

                  {/*<div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
          <div className="row">
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteLateItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
            <BakelisteMissingItemForAdmin />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
              <ProgramReunionCoach />
            </div>
            <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
              <SavedReunionListItem />
            </div>
          </div>
        </div>*/}
                </div>
              </div>
            </div>
        );
    }
  }
// export default connect(
//     ({ navTabsFormationAdmin }) => ({ ...navTabsFormationAdmin }),
//     dispatch => bindActionCreators({ ...navTabsFormationAdminActions }, dispatch)
//   )( navTabsFormationAdmin );