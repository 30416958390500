import React, {Component} from 'react';
import './myAccount.css';
import {NavLink} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import AvatartMyAccount from '../../img/mouum.jpg';
import { Uploader } from 'rsuite';

export default class myAccount extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    return  <div className="card-dashboard p-lg-5 mb-3 pb-5">
              <div className="col-12 mb-md-0 mb-5">
                <NavLink className="navigation-previous-account" to="/">
                  <FeatherIcon className="icon-navigation-previous" icon="arrow-left" />
                </NavLink>
                <p className="titre-navigation-previous-account pl-lg-0 pl-3">Mon profil</p>
              </div>

              <form>
                <div className="row mt-5 mb-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="inputPrenom" className="label-account">Prénom</label>
                      <input type="text" className="form-control input-account" id="inputPrenom" placeholder="Prénom" />
                    </div>
                    <div className="form-group">
                      <label for="inputNom" className="label-account">Nom</label>
                      <input type="text" className="form-control input-account" id="inputNom" placeholder="Nom" />
                    </div>
                    <div className="form-group">
                      <label for="inputAdresse" className="label-account">Adresse</label>
                      <input type="text" className="form-control input-account" id="inputAdresse" placeholder="Adresse" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="inputemail" className="label-account">Adresse mail</label>
                      <input type="text" className="form-control input-account" id="inputemail" placeholder="Adresse mail" />
                    </div>
                    <div className="form-group">
                      <label for="inputTel" className="label-account">N° téléphone</label>
                      <input type="text" className="form-control input-account" id="inputTel" placeholder="N° téléphone" />
                    </div>
                    <div className="form-group">
                      <label for="inputPassword" className="label-account">Mot de passe</label>
                      <input type="text" className="form-control input-account" id="inputPassword" placeholder="Mot de passe" />
                    </div>
                  </div>
                </div>
                <div className="row dis-flex justify-content-end mb-5">
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 pr-5">
                    <img className="avatar-account mb-2" src={AvatartMyAccount} alt="" />
                    <div className="pos-relative">
                      <Uploader className="upload-new-avatar-account" action="//jsonplaceholder.typicode.com/posts/" />
                      <button className="delete-icon-avatar-account">
                        <FeatherIcon className="icon-avatar-account" icon="trash-2"/>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row dis-flex justify-content-center">
                  <div className="col-md-6">
                    <button className="btn-theme w-full text-up">ENREGISTRER LES MODIFICaTIONS</button>
                  </div>
                </div>
              </form>
            </div>;
  }
}
