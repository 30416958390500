import React, { Component } from "react";
import "./reportingAdmin.css";
import FeatherIcon from "feather-icons-react";
import TableMyReporting from "../tableMyReporting/index";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export default class reportingAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-b-200">
        <div className="row px-3">
          <div className="col-lg-12">
            <div className="container-fluid px-0">
              {/* <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard-admin" className="lien_nav">Dashboard / </Link></li>
                        <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current">
                          <Link to="/controle-admin" className="lien_nav"> Contrôle / </Link></li>
                        <li className="breadcrumb-item active" aria-current="page" className="lien_nav_current">
                          <Link to="/controle_admin/reporting-admin" className="lien_nav"> Reporting </Link></li>
                    </ol>
                </nav> */}
            </div>

            {/* <nav class="navbar navbar-expand-lg navbar-light nav-color py-3">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav mr-auto">
                  <li class="nav-item">
                      <a class="nav-link" href="controle-admin">Tache<span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link ml-5" href="#">Pointage</a>
                  </li>
                  <li class="nav-item active">
                      <a class="nav-link ml-5" href="controle-admin">Reporting</a>
                  </li>
                  
                  </ul>

              </div>
            </nav> */}

            <div className="container-fluid px-0 pt-4 pb-4">
              <button className="btn btn-export-to">
                <ExitToAppIcon /> EXPORT TO CSV
              </button>
            </div>

            {/* <div className="row m-b-15">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600">
                
              </h6>
            </div>
            <div className="col dis-flex m-b-15">
           
            </div>
            
          </div> */}

            <div className="card-dashboard m-b-15 p-b-60">
              <h4 className="h-theme ff-inter fw-600">Liste de Reporting</h4>
              <div className="row m-b-30 m-t-20">
                <div className="col-xl-5 pl-xl-5 col-lg-6 pl-lg-3 col-md-5 pl-md-5 col-sm-6 col-6 dis-flex m-b-15"></div>
              </div>

              <TableMyReporting />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
