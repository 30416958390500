import FeatherIcon from "feather-icons-react";
import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  archiverBakelisteAsync,
  debloquerUnBakeliste,
  getBakelisteBloquesAsync,
  getBakelisteEnligneeAsync,
  mettreBakelistePause,
  showListBakeEnligne,
  showListBakelisteBloques,
  termineFormationAsync,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import TableBootstrap from "./TableBootstrap";

const AllBakelisteBloques = () => {
  const dispatch = useDispatch();
  const bakelisteBloqueData = useSelector(showListBakelisteBloques);

  useEffect(() => {
    dispatch(getBakelisteBloquesAsync());
  }, []);

  const unblockUserAccount = (id) => {
    dispatch(debloquerUnBakeliste(id));
  };

  const actionFormatter = (cell, row) => {
    return (
      <span className="text-center" style={{ cursor: "pointer" }}>
        <span
          className="lien_nav_current"
          onClick={() => unblockUserAccount(row.id)}
        >
          <FeatherIcon icon="corner-down-left" />
        </span>
      </span>
    );
  };

  // Bootstrap table name formatter
  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/bakelistes_new" className="lien_nav_current">
                Dashboard bakeliste
              </Link>
            </li>
            <li>
              <Link to="#" className="lien_nav_current">
                Bakelistes en ligne
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-11">
          <TableBootstrap
            userData={bakelisteBloqueData}
            csvName="Liste bakeliste bloqués"
            shouldExportCsv
            shouldFormatName
            shouldPaginate={bakelisteBloqueData?.length > 10}
            shouldSearch
            shouldShowCoach
            shouldShowPhoneNumber
            shouldFormatAction
            nameFormatter={nameFormatter}
            actionFormatter={actionFormatter}
          />
        </div>
      </div>
    </>
  );
};

export default AllBakelisteBloques;
