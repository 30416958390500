import React, {Component} from 'react';
import './recrutables.css'
// import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';
import {
  BootstrapTable,
  TableHeaderColumn,
  ExportCSVButton,
} from "react-bootstrap-table";
// import { bindActionCreators } from "redux";
// import * as stagiaireActions from "../../store/stagiaire/actions";
export default class recrutables extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {}; 
    // }

    nameFormatter = (cell, row) => {
      return (
        <span>
          <button className="btn-show" title="Voir les détails">
            {/* <Tooltip title="Voir les détails"> */}
            <Link className="nameFormat" 
                to={{
                  pathname: '/profil-utilisateur',
                  state: { id: row.id },  
                }}
              >
              {row.first_name} {row.last_name}{" "}
            </Link>
            {/* </Tooltip> */}
          </button>
  
        </span> 
      );
    };
    render() {
      return (
        <div className="component-nav-tabs-utilisateurs">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-9">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard-admin" className="lien_nav_current">
                      <FeatherIcon
                        className="icon-breadcrumb-custom"
                        icon="layout"
                      />
                      Dashboard
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    className="lien_nav"
                  >
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="users"
                    />
                   Les bakelistes recrutables
                  </li>
                </ol>
              </nav>
              <div className="col-xl-6 col-lg-6 col-md-6">
                <h6 className="h-theme ff-inter fw-600 px-3 pt-3">
                 Liste des bakelistes recrutables
                </h6>
              </div>
              <BootstrapTable
          data={this.props.Recrutables}
          striped={true}
          hover={true}
          // options={options}
          search
          searchPlaceholder="Rechercher bakeliste..."
          multiColumnSearch
          // exportCSV
          // csvFileName="mes-bakelistes.csv"
          multiColumnSort={2}
          pagination
          bordered={true}
        >
          <TableHeaderColumn
            isKey="true"
            dataField="first_name"
            // dataFormat={this.nameFormatter}
            dataFormat={this.nameFormatter}
            filter={{ type: "TextFilter", delay: 1000 }}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="150"
          >
            Prénoms
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="last_name"
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            filter={{ type: "TextFilter", delay: 1000 }}
            width="100"
          >
            Noms
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="email"
            dataSort
            filter={{ type: "TextFilter", delay: 1000 }}
            tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            width="170"
          >
            Email
          </TableHeaderColumn>
         </BootstrapTable>
     
               
              </div>
            
            </div>
          </div>
        </div>
       );
    }
  }
// export default connect(
//     ({ stagiaire }) => ({ ...stagiaire }),
//     dispatch => bindActionCreators({ ...stagiaireActions }, dispatch)
//   )( stagiaire );