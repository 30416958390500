import React from "react";
import "./listeSousGroupe.css";
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from "rsuite";
import ListeSousGroupesAdmin from "../../components/listeSousGroupesAdmin/index";
import { history } from "../history";
import ListeSousGroupesAdminRefact from "../listeSousGroupesAdmin/ListeSousGroupesAdminRefact";
const ListeSousGroupesRefact = () => {
  const addSousGroupe = () => {
    history.push("/ajout-sous-groupe");
  };
  return (
    <div className="p-b-200">
      <div className="row">
        <div className="col-lg-9">
          <div className="row m-b-15">
            <div className="col m-b-15">
              <h6 className="h-theme ff-inter fw-600 px-3">
                {" "}
                Gestion des sous Groupes
              </h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10"></div>

            <div className="col-md-2">
              <button
                className="btn-add-with-icon-plus-circle offset"
                onClick={addSousGroupe}
              >
                Ajouter
                <Icon className="icon-add ml-2" icon="plus-circle" />
              </button>
            </div>
          </div>
          <div className="row px-lg-0 px-md-2">
            <ListeSousGroupesAdminRefact />
          </div>
        </div>
        <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
        </div>
      </div>
    </div>
  );
};

export default ListeSousGroupesRefact;
