import React, {Component} from 'react';
import './bakelisteTermines.css';
import {Link} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Coach from '../../img/coach.png';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as bakelisteTerminesActions from "../../store/bakelisteTermines/actions";
export default class bakelisteTermines extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
            <div className="component-bakeliste-termines">
                <div className="container-fluid px-0">
                 <div className="row">
                  <div className="col-lg-12">
     
                   <div className="container">
                    <div className="row pt-5">
                        <div className="col-lg-4">
                            <div className="text-center card-box">
                                <div className="member-card pt-2 pb-2">
                                    <div className="thumb-lg member-thumb mx-auto">
                                    <img src={Coach} className="rounded-circle img-thumbnail" alt="profile-image" /></div>
                                    <div className="">
                                        <h5>Assane Gueye</h5>
                                        <p className="text-muted">Marketer</p>
                                    </div>
                                    <ul className="social-links list-inline">
                                        <FeatherIcon icon="facebook" className="list-inline-item" />
                                        <FeatherIcon icon="twitter" className="list-inline-item" />
                                        <FeatherIcon icon="instagram" className="list-inline-item" />
                                    </ul>
                                    <Link to="/profil-visiteur"><button type="button" 
                                    className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="mt-3">
                                                
                                                    <p className="mb-0 text-muted">Livrables</p>
                                                    <p>121</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mt-3">
                                                    
                                                    <p className="mb-0 text-muted">Pointage</p>
                                                    <p>Absent</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mt-3">
                                                    
                                                    <p className="mb-0 text-muted">Avis du Coach</p>
                                                    <p> il vient souvent en retard</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end col --> */}
                        <div className="col-lg-4">
                            <div className="text-center card-box">
                                <div className="member-card pt-2 pb-2">
                                    <div className="thumb-lg member-thumb mx-auto">
                                    <img src={Coach} className="rounded-circle img-thumbnail" alt="profile-image" /></div>
                                    <div className="">
                                        <h5>Aita Mbaye</h5>
                                        <p className="text-muted">Programmer</p>
                                    </div>
                                    <ul className="social-links list-inline">
                                        <FeatherIcon icon="facebook" className="list-inline-item" />
                                        <FeatherIcon icon="twitter" className="list-inline-item" />
                                        <FeatherIcon icon="instagram" className="list-inline-item" />
                                    </ul>
                                    <Link to="/profil-visiteur"><button type="button" 
                                    className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="mt-3">
                                                
                                                    <p className="mb-0 text-muted">Livrables</p>
                                                    <p>121</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mt-3">
                                                    
                                                    <p className="mb-0 text-muted">Pointage</p>
                                                    <p>Absent</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="mt-3">
                                                    
                                                    <p className="mb-0 text-muted">Avis du Coach</p>
                                                    <p> il vient souvent en retard</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end col --> */}
                        <div className="col-lg-4">
                            <div className="text-center card-box">
                                <div className="member-card pt-2 pb-2">
                                    <div className="thumb-lg member-thumb mx-auto">
                                    <img src={Coach} 
                                    className="rounded-circle img-thumbnail" 
                                    alt="profile-image"/>
                                    </div>
                                    <div className="">
                                        <h5>Kalika</h5>
                                        <p className="text-muted">Webdesigner</p>
                                    </div>
                                    <ul className="social-links list-inline">
                                        <FeatherIcon icon="facebook" className="list-inline-item" />
                                        <FeatherIcon icon="twitter" className="list-inline-item" />
                                        <FeatherIcon icon="instagram" className="list-inline-item" />
                                    </ul>
                                    <Link to="/profil-visiteur"><button type="button" 
                                    className="btn btn-primary mt-3 btn-rounded waves-effect w-md waves-light color-btn-profil-bakeliste">Voir plus</button></Link>
                                    <div className="mt-4">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4">
                                                <div className="mt-3">
                                                
                                                    <p className="mb-0 text-muted">Livrables</p>
                                                    <p>121</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <div className="mt-3">
                                                    
                                                    <p className="mb-0 text-muted">Pointage</p>
                                                    <p>Absent</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <div className="mt-3">
                                                    
                                                    <p className="mb-0 text-muted">Avis du Coach</p>
                                                    <p> il vient souvent en retard</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end col --> */}
                    </div>
                    {/* <!-- end row --> */}
                   

                </div>
                

              </div>
              </div>
              </div>
              </div>
        );
    }
  }
// export default connect(
//     ({ bakelisteTermines }) => ({ ...bakelisteTermines }),
//     dispatch => bindActionCreators({ ...bakelisteTerminesActions }, dispatch)
//   )( bakelisteTermines );