import React, {Component} from 'react';
import './profilAdmin.css';
import {Link} from 'react-router-dom';
import BakelisteLateItemForAdmin from '../bakelisteLateItemForAdmin/index';
import BakelisteMissingItemForAdmin from '../bakelisteMissingItemForAdmin/index';
import ProgramReunionCoach from '../programReunionCoach/index';
import SavedReunionListItem from '../savedReunionListItem/index';
import FeatherIcon from 'feather-icons-react';
import ProfilAdmin from '../../img/profilAdmin.png';

// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as profilAdminActions from "../../store/profilAdmin/actions";
export default class profilAdmin extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return (
          <div className="component-profil-admin">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-lg-9">
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active"><Link to="/dashboard-admin" className="lien_nav">Dashboard / </Link></li>
                  <li className="breadcrumb-item" aria-current="page" className="lien_nav_current">Profil Admin</li>
                </ol>
              </nav>

              <div className="container-fluid bg-white pt-2">
                <div className="row pt-1">
                  <div className="col-md-12 col-lg-12 col-xl-12">
                      <p className="taille-txt-profil"><FeatherIcon icon="arrow-left" /><span className="ml-4">Mon profil</span></p>
                    </div>
                    </div>

                    
                      <form className="pt-5 distance-form">
                      <div className="row">
                        <div className="col-md-6 col-xl-6 col-lg-6">
                        <label for="inputPrenom" className="form-label format-txt-lab ml-1">Prénom</label>
                          <input type="text" className="form-control enlevement-bodure mb-5" placeholder="Modou" aria-label="prenom"/>
                            <label for="inputName" class="form-label format-txt-lab ml-1">Nom</label>
                          <input type="text" className="form-control enlevement-bodure mb-5" placeholder="Seck" aria-label="name"/>
                          <label for="inputAdress" class="form-label format-txt-lab ml-1">Adresse</label>
                          <input type="text" className="form-control enlevement-bodure mb-5" placeholder="Dakar" aria-label="adresse"/>                    
                        </div>
                        <div class="col-md-6 col-xl-6 col-lg-6"> 
                        <label for="inputAdress" class="form-label format-txt-lab ml-1">Adresse Email</label>                   
                          <input type="text" className="form-control enlevement-bodure mb-5" placeholder="modou@gmail.com" aria-label="email"/>
                          <label for="inputTel" class="form-label format-txt-lab ml-1">Téléphone</label>
                          <input type="number" className="form-control enlevement-bodure mb-5" placeholder="77 000 00 00" aria-label="tel"/>
                          <label for="inputPassword4" class="form-label format-txt-lab ml-1">Mot de passe</label>
                          <input type="password" className="form-control enlevement-bodure mb-5" placeholder="*******" aria-label="password"/>
                        </div>
                      </div>
                      </form>
                    <div className="row">
                      <div className="col-md-12 col-xl-12 col-lg-12 alignement-profil-infos">
                        <img src={ProfilAdmin} />
                        <div className="mt-2 ml-4"><button className="change-profil-tof">Charger une photo</button> <FeatherIcon icon="trash-2" className="format-trash"/></div>
                        <div>
                          <button className="format-btn-enregistert-modif mb-5 mt-5">ENREGISTRER LES MODIFICATIONS</button>
                      </div>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 ml-lg-auto pl-xl-3 pl-lg-0">
                <div className="row">
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteLateItemForAdmin />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-3 pr-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <BakelisteMissingItemForAdmin />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pr-lg-3 pl-md-2 pr-md-2  col-sm-6 pr-sm-3 pl-sm-2 dis-flex">
                    <ProgramReunionCoach />
                  </div>
                  <div className="col-lg-12 col-md-4 pl-lg-3 pl-md-2 col-sm-6 pl-sm-3 pr-sm-2 dis-flex">
                    <SavedReunionListItem />
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ profilAdmin }) => ({ ...profilAdmin }),
//     dispatch => bindActionCreators({ ...profilAdminActions }, dispatch)
//   )( profilAdmin );