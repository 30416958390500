import React, {Component} from 'react';
import Event from '../event/index';
import HeaderDashboardAdmin from '../headerDashboardAdmin/index';
import NavVerticalAdmin from '../navVerticalAdmin/index';
import './eventView.css'
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as eventViewActions from "../../store/eventView/actions";
export default class eventView extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {}; 
    // }
    render() {
      return (
          <div className="component-event-view body-theme">
                <HeaderDashboardAdmin />
              <div className="container-fluid fixing-after-header">
                <div className="row">
                  <div className="col-lg-2 p-0">
                    <NavVerticalAdmin />
                  </div>
                  <div className="col-lg-10 pt-3">
                    <Event />
                  </div>
                </div>
              </div>
          </div>
      );
    }
  }
// export default connect(
//     ({ eventView }) => ({ ...eventView }),
//     dispatch => bindActionCreators({ ...eventViewActions }, dispatch)
//   )( eventView );