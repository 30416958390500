import React from "react";
import InfoItem from "./InfoItem";

const ApercuInformationsPersonnelles = ({ bakelisteInfos }) => {
  return (
    <div>
      <h4 >Informations personnelles</h4>
      <div className="container-fluid">
        <InfoItem
          label1="Prénom"
          label2="Nom"
          data1={bakelisteInfos.first_name}
          data2={bakelisteInfos.last_name}
        />

        <InfoItem
          label1="Civilité"
          label2="Situation matrimoniale"
          data1={bakelisteInfos.civility}
          data2={bakelisteInfos.marital_status}
        />

        <InfoItem
          label1="Email"
          label2="Téléphone"
          data1={bakelisteInfos.email}
          data2={bakelisteInfos.telephone}
        />

        <InfoItem
          label1="Téléphone whatsapp"
          label2="Date de naissance"
          data1={bakelisteInfos.phone}
          data2={bakelisteInfos.date_naissance}
        />

        <InfoItem
          label1="Adresse"
          label2="Formation suivies"
          data1={bakelisteInfos.address}
          data2={bakelisteInfos.formation_suivie}
        />

        <div className="row apercu--alone border-bottom py-2">
          <div className="col-6">
            <span>Ecole : {bakelisteInfos.ecole}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApercuInformationsPersonnelles;
