import React, { Component } from 'react';
import './syllabusDomaineDisign.css';
import ImageSyllabus from '../../img/Domaine (4).png';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ShareTo from '../shareTo/index';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

export default class syllabusDomaineDisign extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="container-syllabus-item">
      <div className="syllabus-domaine">
        {/* !!!!!!!!Important!!!!!!!!!!! */}
        {/* etiquette pour le débutant  */}
        <p className="etiquette-syllabus-domaine "
          className={
            "etiquette-syllabus-domaine " +
            (this.props.niveau === "débutant"
              ? " bg-debutant"
              : this.props.niveau === "intermédiaire"
                ? "bg-intermediaire"
                : this.props.niveau === "avancé"
                  ? "bg-avance"
                  : "")
          }
        >
        {this.props.niveau}
        </p>
        {/* etiquette pour le confirmé  */}
        {/* <p className="etiquette-syllabus-item confirme">confirmé</p> */}
        <a className="survolimage a_survolimage" href="#">
          <img src={ImageSyllabus} alt="Avatar" className="image-syllabus-domaine" style={{ width: '100%' }}/>
          <span className="a-txt c1">
            <ul>Disign
              <li>Design d’interface utilisateur</li>
              <li>Design d’expérience utilisateurs</li>
              <li>Design graqhique</li>
              <li>Montage vidéo</li>
            </ul>
          </span>
          </a>        <div className="card-img-overlay">
          <p className="titre-content-syllabus-item">{this.props.name}</p>
          <div className="row">
            <div className="col-xl-10 col-lg-2 pr-lg-0 col-10 pr-0">
              <p className="info-content-syllabus-item">{this.props.nbreTache} Disign</p>
            </div>
            <div className="col-xl-2 pl-xl-0 col-2 pr-0">
                <div className="dropdown">
                  <a  type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FeatherIcon className="icon-domaine" icon="more-vertical" /></a>
                  <div className="dropdown-menu dropdown-primary">
                    <a className="dropdown-item" href="#"><FeatherIcon className="icon-content-syllabus-item" icon="plus-circle" />&nbsp;&nbsp;Ajouter</a>
                    <a className="dropdown-item" href="#"><FeatherIcon className="icon-content-syllabus-item" icon="edit-3" />&nbsp;&nbsp;Modifier</a>
                    <a className="dropdown-item" href="#"><FeatherIcon className="icon-content-syllabus-item" icon="archive" />&nbsp;&nbsp;Archive</a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}
// export default connect(
//     ({ syllabusDomaineDisign }) => ({ ...syllabusDomaineDisign }),
//     dispatch => bindActionCreators({ ...syllabusDomaineDisignActions }, dispatch)
//   )( syllabusDomaineDisign );