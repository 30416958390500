import React, { Component } from "react";
import "./addTacheSyllabus.css";
import { Icon } from "rsuite";
import FeatherIcon from "feather-icons-react";
import { DatePicker, Modal, Uploader } from "rsuite";

export default class addTacheSyllabus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open(size) {
    this.setState({
      size,
      show: true,
    });
  }

  render() {
    return (
      <div className="ml-md-auto">
        <div className="modal-container">
          <button
            className="btn-add-with-icon-plus-circle"
            onClick={() => this.open("sm")}
          >
            Ajouter une tâche
            <Icon className="icon-add ml-2" icon="plus-circle" />
          </button>

          <Modal
            className="modal-responsive-sm p-b-30"
            size={this.state.size}
            show={this.state.show}
            onHide={this.close}
          >
            <form>
              <Modal.Body className="modal-content-coach max-height-modal pb-0 mt-0">
                <h5 className="modal-bakeliste-title m-b-50">
                  Ajouter une tâche
                </h5>
                <button
                  className="close-modal-bakeliste-title"
                  onClick={this.close}
                >
                  <FeatherIcon icon="x" aria-hidden="true" />
                </button>

                <div className="form-group">
                  <input
                    type="texte"
                    className="form-control form-input-dashboard-coach text-dark border-theme-dark"
                    placeholder="Nom de la tâche"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control form-input-dashboard-coach p-t-15 p-b-15 border-theme-dark"
                    rows="10"
                    placeholder="Description de la tâche"
                  ></textarea>
                </div>

                <div className="row">
                  <div className="col-sm-5 ">
                    <DatePicker
                      className="w-full datepicker-light-custom"
                      format="HH:mm"
                      ranges={[]}
                    />
                  </div>
                  <div className="col-sm-5 pl-sm-0">
                    <div>
                      <FeatherIcon className="icon-btn-upload" icon="link-2" />
                      <Uploader
                        className="upload-white"
                        action="//jsonplaceholder.typicode.com/posts/"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="m-t-40 dis-flex justify-content-end">
                  <div className="col-sm-5">
                    <button
                      onClick={this.close}
                      className="btn-theme w-full fs-13"
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    );
  }
}
// export default connect(
//     ({ addTacheSyllabus }) => ({ ...addTacheSyllabus }),
//     dispatch => bindActionCreators({ ...addTacheSyllabusActions }, dispatch)
//   )( addTacheSyllabus );
