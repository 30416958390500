import { Tooltip } from "@material-ui/core";
import FeatherIcon from "feather-icons-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getBakelisteEnligneeAsync,
  showListBakeEnligne,
} from "../../redux/slices/bakelistesSlices/bakelistesSlice";
import TableBootstrap from "./TableBootstrap";

const AllBakelisteEnligne = () => {
  const dispatch = useDispatch();
  const bakelisteEnligneData = useSelector(showListBakeEnligne);

  useEffect(() => {
    dispatch(getBakelisteEnligneeAsync());
  }, []);

  const actionFormatter = (cell, row) => {
    return (
      <div className="text-left">
        <span className="text-center text-success">
          <Tooltip title="details" placement="top">
            <Link
              to={{
                pathname: "/show-enligne-bakeliste",
                state: { id: row.id, data: row },
              }}
              style={{ textDecoration: "none", color: "#009688" }}
            >
              <FeatherIcon icon="eye" className="ml-2" />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;
        </span>
        <span className="text-center text-warning">
          <Tooltip title="Modifier" placement="top">
            <Link
              to={{
                pathname: "/edit-bakeliste",
                state: { id: row.id, data: row },
              }}
              // onClick={() => {
              //   console.log(row.id, row);
              // }}
              style={{ textDecoration: "none", color: "#FF9800" }}
            >
              <FeatherIcon icon="edit" className="ml-2" />
            </Link>
          </Tooltip>
          &nbsp;&nbsp;
        </span>
      </div>
    );
  };

  // Bootstrap table name formatter
  const nameFormatter = (cell, row) => {
    return `${row?.first_name} ${row?.last_name}`;
  };

  return (
    <>
      <div className="row">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
            <li className="breadcrumb-item">
              <Link to="/dashboard-admin" className="lien_nav_current">
                Dashboard
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/bakelistes_new" className="lien_nav_current">
                Dashboard bakeliste
              </Link>
            </li>
            <li>
              <Link to="#" className="lien_nav_current">
                Bakelistes en ligne
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-11">
          <TableBootstrap
            userData={bakelisteEnligneData}
            csvName="Liste bakelistes en ligne"
            shouldExportCsv
            shouldFormatName
            shouldFormatAction
            shouldSearch
            shouldPaginate={bakelisteEnligneData?.length > 10}
            nameFormatter={nameFormatter}
            actionFormatter={actionFormatter}
          />
        </div>
      </div>
    </>
  );
};

export default AllBakelisteEnligne;
