import React, { Component } from "react";
import "./connexion.css";
import Logo from "../../img/logo.png";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import amplitude from "amplitude-js";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import API from "../../variablesGlobales";
import { Amplitude, LogOnMount } from "@amplitude/react-amplitude";


Sentry.init({
  dsn:
    "https://cfd5fe9f51b049ad94ea20a6bd7ab883@o253692.ingest.sentry.io/1442714",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  tracesSampleRate: 1.0,
});
class Connexion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginPage: true,
      email: "",
      password: "",
      errors: {},
      isAuthenticated: false,
      token: null,
      isValidUser: true,
      authAlertError: false,
      userFullName: "",
      assistant_domaine_id:"",
      isPending: false,
      api: API.API,
      email_empty: false,
      password_empty: false,
      isLoaded: true,
      isRequestError: false,
      notUser: false,
      trainingIsCompleted: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ isLoaded: false });
      }.bind(this),
      6000
    );
    this.amplitudeScript("0168d5bcb0d33437d15f2a6220fb054a");
    amplitude.getInstance().logEvent("visitedHomepageAdmin_V2");
  }

  componentDidCatch(err, info) {
    Sentry.captureException(err);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  

 
  amplitudeScript = (api_key) => {
    (function (e, t) {
      var n = e.amplitude || { _q: [], _iq: {} };
      var r = t.createElement("script");
      r.type = "text/javascript";
      r.integrity =
        "sha384-vYYnQ3LPdp/RkQjoKBTGSq0X5F73gXU3G2QopHaIfna0Ct1JRWzwrmEz115NzOta";
      r.crossOrigin = "anonymous";
      r.async = true;
      r.src = "https://cdn.amplitude.com/libs/amplitude-5.8.0-min.gz.js";
      r.onload = function () {
        if (!e.amplitude.runQueuedFunctions) {
        }
      };
      var i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
      function s(e, t) {
        e.prototype[t] = function () {
          this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          return this;
        };
      }
      var o = function () {
        this._q = [];
        return this;
      };
      var a = [
        "add",
        "append",
        "clearAll",
        "prepend",
        "set",
        "setOnce",
        "unset",
      ];
      for (var u = 0; u < a.length; u++) {
        s(o, a[u]);
      }
      n.Identify = o;
      var c = function () {
        this._q = [];
        return this;
      };
      var l = [
        "setProductId",
        "setQuantity",
        "setPrice",
        "setRevenueType",
        "setEventProperties",
      ];
      for (var p = 0; p < l.length; p++) {
        s(c, l[p]);
      }
      n.Revenue = c;
      var d = [
        "init",
        "logEvent",
        "logRevenue",
        "setUserId",
        "setUserProperties",
        "setOptOut",
        "setVersionName",
        "setDomain",
        "setDeviceId",
        "enableTracking",
        "setGlobalUserProperties",
        "identify",
        "clearUserProperties",
        "setGroup",
        "logRevenueV2",
        "regenerateDeviceId",
        "groupIdentify",
        "onInit",
        "logEventWithTimestamp",
        "logEventWithGroups",
        "setSessionId",
        "resetSessionId",
      ];
      function v(e) {
        function t(t) {
          e[t] = function () {
            e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        for (var n = 0; n < d.length; n++) {
          t(d[n]);
        }
      }
      v(n);
      n.getInstance = function (e) {
        e = (!e || e.length === 0 ? "$default_instance" : e).toLowerCase();
        if (!n._iq.hasOwnProperty(e)) {
          n._iq[e] = { _q: [] };
          v(n._iq[e]);
        }
        return n._iq[e];
      };
      e.amplitude = n;
    })(window, document);
    amplitude.getInstance().init(api_key);
  };

  validateLoginForm() {
    let email = this.state.email;
    let password = this.state.password;
    let isValidForm = true;
    let errors = {};
    if (!email) {
      this.setState({
        isPending: false,
      });
      isValidForm = false;
      this.setState({
        email_empty: true,
      });
      this.handleShowAndHideAlert("email");
      errors["email"] = "Le champ email est obligatoire";
    }
    if (!password) {
      this.setState({
        isPending: false,
      });
      isValidForm = false;
      this.setState({
        password_empty: true,
      });
      this.handleShowAndHideAlert("password");
      errors["password"] = "Le champ mot de passe est obligatoire";
    }
    this.setState({
      errors: errors,
    });
    return isValidForm;
  }

  handleShowAndHideAlert(input) {
    setTimeout(() => {
      if (input === "credential_error") {
        this.setState({
          isValidUser: true,
        });
      }
      if (input === "email") {
        this.setState({
          email_empty: false,
        });
      }
      if (input === "password") {
        this.setState({
          password_empty: false,
        });
      }
      if (input === "requestError") {
        this.setState({
          isRequestError: false,
        });
      }
    }, 5000);
  }

  doLogin = (e) => {
    e.preventDefault();

    this.setState({
      isPending: true,
    });
    if (this.validateLoginForm()) {
      const fields = {
        email: this.state.email,
        password: this.state.password,
      };
      var eventProperties = {
        user_email: this.state.email,
      };
      axios
        .post(this.state.api + "user/login", fields, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          let errors = {};
          const token = response.data.data.auth_token;
          const sous_groupe_id = response.data.data.sous_groupe_id;
          const user_first_name = response.data.data.first_name;
          const user_last_name = response.data.data.last_name;
          const userFullName =
            response.data.data.first_name + " " + response.data.data.last_name;
          const userStatus = response.data.data.status;
          const connectedUserID = response.data.data.id;
          const user_phone = response.data.data.phone;
          this.setState({
            userFullName: userFullName,
            token: token,
          });
          if (token) {
            window.sessionStorage.setItem("token", token);
            localStorage.setItem("user", response.data.data);
            window.sessionStorage.setItem("userFullName", userFullName);
            window.sessionStorage.setItem("user_status", userStatus);
            window.sessionStorage.setItem("user_id", connectedUserID);
            window.sessionStorage.setItem("user_first_name", user_first_name);
            window.sessionStorage.setItem("user_last_name", user_last_name);
            window.sessionStorage.setItem("user_phone", user_phone);

           
            localStorage.setItem("app_data", JSON.stringify(token));
           
            this.setState({
              isPending: false,
            });
           
            if (response.data.data.status === "admin") {
              window.location = "/dashboard-admin";
           
              amplitude
                .getInstance()
                .logEvent("ADMIN_CONNEXION_TYPE_V2", eventProperties);
            }
          } else if (!token) {
            errors["auth_error"] = "Vos identifiants ne sont pas corrects.";
            amplitude.getInstance().logEvent("NO_CORRECT_ID_CONNEXION_TYPE_V2");
            this.setState({
              errors: errors,
              isValidUser: false,
              isPending: false,
            });
            this.handleShowAndHideAlert("credential_error");
          }
        })
        .catch((error) => {
          this.setState({
            isPending: false,
            isRequestError: true,
          });
          amplitude.getInstance().logEvent("REQUEST_ERROR_CONNEXION_TYPE_V2");

          this.handleShowAndHideAlert("requestError");
          if (process.env.NODE_ENV !== "production") {
            return;
          }
          Sentry.captureException(error);
        });
    }
  };

  testAmplitude = () => {
    amplitude.logEvent("test Amplitude v2", {
      color: "red;",
      shape: "triangle",
      sides: 3,
    });
  };

  render() {
    return (
      <Amplitude>
        <div className="container-fluid">
          <LogOnMount eventType="visite page d'accueil" />
          <div className="row">
            <div className="col-md-6 banner-connexion-page dis-flex justify-content-center itm-center py-5">
              <div className="col-xl-9 col-lg-10 col-md-11 col-sm-8 text-center">
                <h1 className="titre-banner-connexion">Bakeli SI</h1>
                <p className="slogan-banner-connexion">
                  Training the best tech talent in Africa.
                </p>
              </div>
            </div>
            <div className="col-md-6 bg-white dis-flex justify-content-center itm-center py-md-0 py-5">
              <div className="col-xl-7 col-lg-9 col-md-11 col-sm-8 dis-flex flex-col">
                <img className="logo-connexion m-l-r-auto" src={Logo} alt="" />
                
                <form onSubmit={(e) => this.doLogin(e)}>
                  <p className="h-connexion-form">CONNECTEZ-VOUS</p>
                  {this.state.notUser && (
                    <div className="auth-error text-center">
                      Cet utilisateur n'existe pas dans la base de donnee.
                    </div>
                  )}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                      >
                        <FeatherIcon
                          className="icon-form-connexion"
                          icon="mail"
                        />
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control input-form-connexion"
                      placeholder="Adresse Mail"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  {this.state.email_empty && (
                    <div className="errorMsg">{this.state.errors.email}</div>
                  )}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-text-connexion"
                        id="basic-addon1"
                      >
                        <FeatherIcon
                          className="icon-form-connexion"
                          icon="lock"
                        />
                      </span>
                    </div>
                    <input
                      type="password"
                      className="form-control input-form-connexion"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder="Mot de passe"
                    />
                  </div>
                  {this.state.password_empty && (
                    <div className="errorMsg">{this.state.errors.password}</div>
                  )}
                  <NavLink className="mdp-forget-link" to="/reinitialiser-mot-de-passe">
                    Mot de passe oublié ?
                  </NavLink> 
                  <button className="btn-connexion">
                    CONNEXION &nbsp;
                    {this.state.isPending && (
                      <FeatherIcon
                        icon="loader"
                        aria-hidden="true"
                        style={{ color: "#069A77" }}
                      />
                    )}
                  </button>
                  {!this.state.isValidUser && (
                    <div className="auth-error text-center">
                      {this.state.errors.auth_error}
                    </div>
                  )}
                  {this.state.isRequestError && (
                    <div className="auth-error text-center" >
                      La connexion a échoué. Veuillez réessayer plus tard.
                    </div>
                  )}
                </form>
              
              </div>
            </div>
          </div>
        </div>
      </Amplitude>
    );
  }
}
export default Connexion;
