import React, { useReducer } from "react";
import "./editerStagiaire.css";
import API from "../../variablesGlobales";
import qs from "qs";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import HeaderDashboardAdmin from "../headerDashboardAdmin/index";
import NavVerticalAdmin from "../navVerticalAdmin/index";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { updateInfoStagiaire } from "../../redux/slices/stagiaires/stagiairesSlice";
toast.configure();
const EditerStagiaireRefact = (props) => {
  let user_id = props.location?.state?.id;
  let data = props.location?.state?.data;
  if (!user_id && props.location.search) {
    const obj = qs.parse(props.location.search.replace("?", ""));
    user_id = parseInt(obj.id);
  }
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      user_id: user_id,
      api: API.API,
      data: data,
      userID: window.sessionStorage.getItem("user_id"),
      user: [],
      first_name: data.first_name,
      last_name: data.last_name,
      address: data.address,
      phone: data.phone,
      email: data.email,
      ecole: data.ecole,
      domaine: data.domaine,
      domaine_id: data.coach_domaine_id,
      formation_suivie: data.formation_suivie,
      domaines: [],
      id: data.id,
      status: data.status,
      recruteur_raison: data.recruiter_raison_sociale,
      recruteur_domaine: data.recruiter_domaine_pro,
      objectifs: data.bio,
    }
  );

  const dispatch = useDispatch();

  const editDetailsStagiaire = (e) => {
    // console.log("ddddddddd",state.bakeliste)
    e.preventDefault();
    setState({
      editStagiaireInProgress: true,
    });
    var fd = new FormData();
    fd.append("recruteur_raison", state.recruteur_raison);
    fd.append("recruteur_domaine", state.recruteur_domaine);
    fd.append("bakeliste_id", state.id);
    dispatch(updateInfoStagiaire(fd));

    setTimeout(() => {
      setState({
        editStagiaireInProgress: false,
      });

      window.location = "/stagiaires";
    }, 2000);
  };
  return (
    <div className="component-edit-bakeliste-suivi-view body-theme">
      <HeaderDashboardAdmin />
      <div className="container-fluid fixing-after-header">
        <div className="row">
          <div className="col-lg-2 p-0">
            <NavVerticalAdmin />
          </div>
          <div className="col-lg-10 pt-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb cd-breadcrumb custom-separator custom-icons">
                <li className="breadcrumb-item">
                  <Link to="/dashboard-admin" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="layout"
                    />
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/stagiaires" className="lien_nav_current">
                    <FeatherIcon
                      className="icon-breadcrumb-custom"
                      icon="users"
                    />
                    Stagiaires
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active lien_nav"
                  aria-current="page"
                >
                  <FeatherIcon
                    className="icon-side-breadcrumb-custom"
                    icon="edit"
                  />
                  Editer détails statgiaire
                </li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-md-6">
                <div className="card-dashboard m-b-15 p-b-60 mto-3">
                  <h4 className="h-theme titreCoach">
                    Fiche de {state.first_name} {state.last_name}
                  </h4>
                  <div className="row mt-5 mb-3">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label-account">Nom Complet:</label>
                        <label className="form-control input-account">
                          {" "}
                          {state.first_name} {state.last_name}
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="label-account">Email:</label>
                        <label className="form-control input-account">
                          {state.email}
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="label-account">Adresse:</label>
                        <label className="form-control input-account">
                          {state.address}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="label-account">Telephone:</label>
                        <label className="form-control input-account">
                          {state.phone}
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="label-account">Domaine:</label>
                        <label className="form-control input-account">
                          {state.formation_suivie}
                        </label>
                      </div>
                      <div className="form-group">
                        <label className="label-account">Objectifs:</label>
                        <textarea className="form-control input-account taille">
                          {state.objectifs}
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <form
                  className="form1"
                  onSubmit={(e) => editDetailsStagiaire(e)}
                >
                  <div>
                    <h4 className="h-theme titreCoach">
                      Modifier détails de stage
                    </h4>
                  </div>
                  <div className="form-row row pdt_table">
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="taille-txt-elements">
                          Domaine du recruteur:
                        </label>
                        <input
                          type="text"
                          className="form-control ab1"
                          name="recruteur_domaine"
                          value={state.recruteur_domaine}
                          onChange={(e) =>
                            setState({ recruteur_domaine: e.target.value })
                          }
                        />

                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="taille-txt-elements">
                          Raison sociale:
                        </label>
                        <input
                          type="last_name"
                          className="form-control ab1"
                          name="recruteur_raison"
                          value={state.recruteur_raison}
                          onChange={(e) =>
                            setState({ recruteur_raison: e.target.value })
                          }
                        />
                        <div class="line-box">
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {state.editStagiaireInProgress ? (
                      <button
                        type="submit"
                        className="col-md-6 btn-theme text-up m-t-30 w-full p-t-15 p-b-15 offset-md-6"
                        disabled
                      >
                        Modification en cours &nbsp;
                        <FeatherIcon icon="loader" aria-hidden="true" />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="col-md-5 btn-theme offset-md-6 text-up m-t-30 w-full p-t-15 p-b-15"
                      >
                        MODIFIER
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditerStagiaireRefact;
