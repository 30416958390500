import { motion } from "framer-motion/dist/framer-motion";
import React from "react";
import { useForm } from "react-hook-form";
import next from "./next.svg";

const InformationPersonnelle = ({
  page,
  setPage,
  bakelisteInfos,
  setBakelisteInfos,
  x,
  setX,
}) => {
  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const nextPage = (e) => {
    e.preventDefault();
    setX(10);

    if (!bakelisteInfos.civility) {
      setError("civility");
      setTimeout(() => {
        clearErrors("civility");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.marital_status) {
      setError("marital_status");
      setTimeout(() => {
        clearErrors("marital_status");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.first_name) {
      setError("first_name");
      setTimeout(() => {
        clearErrors("first_name");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.last_name) {
      setError("last_name");
      setTimeout(() => {
        clearErrors("last_name");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.email) {
      setError("email");
      setTimeout(() => {
        clearErrors("email");
      }, 1500);
      return;
    }
    // check for gmail account only
    // const pattern = "^[a-zA-Z0-9]+@gmail.com$";
    const pattern = "gmail.com";

    if (!bakelisteInfos.email.toLowerCase().includes(pattern)) {
      setError("gmailError");
      setTimeout(() => {
        clearErrors("gmailError");
      }, 2000);
      return;
    }
    if (!bakelisteInfos.phone) {
      setError("phone");
      setTimeout(() => {
        clearErrors("phone");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.telephone) {
      setError("telephone");
      setTimeout(() => {
        clearErrors("telephone");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.date_naissance) {
      setError("date_naissance");
      setTimeout(() => {
        clearErrors("date_naissance");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.address) {
      setError("address");
      setTimeout(() => {
        clearErrors("address");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.formation_suivie) {
      setError("formation_suivie");
      setTimeout(() => {
        clearErrors("formation_suivie");
      }, 1500);
      return;
    }
    if (!bakelisteInfos.ecole) {
      setError("ecole");
      setTimeout(() => {
        clearErrors("ecole");
      }, 1500);
      return;
    }

    setPage(page + 1);
  };

  return (
    <motion.div
      initial={{ x: x }}
      transition={{ duration: 0.5 }}
      animate={{ x: 0 }}
      className="py-1 multi-form"
    >
      <h2>Informations personnelles</h2>
      {/* select ******************** */}
      <div className="row-form">
        <div>
          <select
            defaultValue={bakelisteInfos.civility}
            onChange={(e) =>
              setBakelisteInfos({ ...bakelisteInfos, civility: e.target.value })
            }
            className=" p-2"
          >
            <option className="first_select-option" value="">
              *Civilite -- choisissez votre statut --
            </option>
            <option value="Monsieur">Monsieur</option>
            <option value="Madame">Madame</option>
            <option value="Mademoiselle">Mademoiselle</option>
          </select>
          {errors.civility && <span role="alert">Civilité obligatoire</span>}
        </div>
        <div>
          <select
            defaultValue={bakelisteInfos.marital_status}
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                marital_status: e.target.value,
              })
            }
            className=" p-2"
          >
            <option value="">
              *Situation matrimoniale -- choisissez votre statut --
            </option>
            <option value="Marié(e)">Marié(e)</option>
            <option value="Celibataire">Célibataire</option>
          </select>
          {errors.marital_status && (
            <span role="alert">Situation matrimoniale obligatoire</span>
          )}
        </div>
      </div>
      {/* -------------- Prenom - nom */}
      <div className="row-form">
        <div>
          <input
            defaultValue={bakelisteInfos.first_name}
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                first_name: e.target.value,
              })
            }
            type="text"
            placeholder="Prenom"
            className=" p-2"
            id="prenom"
            aria-describedby="prenomHelp"
          />
          {errors.first_name && <span role="alert">Prénom obligatoire</span>}
        </div>
        <div>
          <input
            defaultValue={bakelisteInfos.last_name}
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                last_name: e.target.value,
              })
            }
            type="text"
            placeholder="Nom"
            className=" p-2"
            id="nom"
            aria-describedby="nomHelp"
          />
          {errors.last_name && <span role="alert">Nom obligatoire</span>}
        </div>
      </div>
      {/* -------------- Email - Telephone */}
      <div className="row-form">
        <div>
          <input
            defaultValue={bakelisteInfos.email}
            onChange={(e) =>
              setBakelisteInfos({ ...bakelisteInfos, email: e.target.value })
            }
            type="email"
            placeholder="Email"
            className=" p-2"
            id="email"
            aria-describedby="emailHelp"
            pattern="^[a-zA-Z0-9]+@gmail\.com$"
          />
          {errors.email ? (
            <span role="alert">Email obligatoire</span>
          ) : errors.gmailError ? (
            <span role="alert">Seul les comptes Gmail sont autorisés</span>
          ) : (
            ""
          )}
        </div>
        <div>
          <input
            defaultValue={bakelisteInfos.phone}
            onChange={(e) =>
              setBakelisteInfos({ ...bakelisteInfos, phone: e.target.value })
            }
            type="tel"
            placeholder="Telephone"
            className=" p-2"
            id="phone"
            aria-describedby="phoneHelp"
          />
          {errors.phone && <span role="alert">Télephone obligatoire</span>}
        </div>
      </div>
      {/* -------------- */}
      <div className="row-form">
        <div>
          <input
            defaultValue={bakelisteInfos.telephone}
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                telephone: e.target.value,
              })
            }
            type="tel"
            placeholder="Telephone whatsapp"
            className=" p-2"
            id="phone"
            aria-describedby="phoneHelp"
          />
          {errors.telephone && <span role="alert">Télephone obligatoire</span>}
        </div>
        <div>
          <input
            defaultValue={bakelisteInfos.date_naissance}
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                date_naissance: formatDate(new Date(e.target.value)),
              })
            }
            type="date"
            placeholder="Date"
            className=" p-2"
            id="date"
            aria-describedby="dateHelp"
          />
          {errors.date_naissance && (
            <span role="alert">Date de naissance obligatoire</span>
          )}
        </div>
      </div>
      {/* -------------- */}
      <div className="row-form">
        <div>
          <input
            defaultValue={bakelisteInfos.address}
            onChange={(e) =>
              setBakelisteInfos({ ...bakelisteInfos, address: e.target.value })
            }
            type="text"
            placeholder="Adresse du Bakeliste"
            className=" p-2"
            id="adress"
            aria-describedby="adressHelp"
          />
          {errors.address && <span role="alert">Adresse obligatoire</span>}
        </div>
        <div>
          <input
            defaultValue={bakelisteInfos.formation_suivie}
            onChange={(e) =>
              setBakelisteInfos({
                ...bakelisteInfos,
                formation_suivie: e.target.value,
              })
            }
            type="text"
            placeholder="Formation suivi"
            className=" p-2"
            id="formation"
            aria-describedby="formationHelp"
          />
          {errors.formation_suivie && (
            <span role="alert">Formation suivie obligatoire</span>
          )}
        </div>
      </div>
      {/* -------------- */}
      <div className="row-form">
        <div>
          <input
            defaultValue={bakelisteInfos.ecole}
            onChange={(e) =>
              setBakelisteInfos({ ...bakelisteInfos, ecole: e.target.value })
            }
            type="text"
            placeholder="Ecole"
            className=" p-2 last-input"
            id="adress"
            aria-describedby="adressHelp"
          />
          {errors.ecole && (
            <span style={{ display: "block" }} role="alert">
              Ecole obligatoire
            </span>
          )}
        </div>
      </div>
      <div className="mt-4">
        <button className="btn-switch btn-next" onClick={nextPage}>
          {/* Next */}
          {/* <GrNext fontSize={10} className="text-danger" /> */}
          suivant <img className="ml-3" src={next} alt="Next" />
        </button>
      </div>
    </motion.div>
  );
};

export default InformationPersonnelle;
