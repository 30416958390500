import React, { Component } from "react";
import "./tableCoachAssistants.css";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import API from "../../variablesGlobales";
import { Modal, Icon, SelectPicker, Radio, RadioGroup, Uploader } from "rsuite";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import {
  BootstrapTable,
  ExportCSVButton,
  TableHeaderColumn,
} from "react-bootstrap-table";
import $ from "jquery";
import SkeletonAllBakeliste from "../tableMyAllbakelistes/skeletonAllBakeliste";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import * as tableMyListcoachAssistantActions from "../../store/tableMyListcoachAssistant/actions";

class Status extends React.Component {
  render() {
    return (
      <div className="dis-flex justify-content-center itm-center flex-col">
        <p className="badge todo-badge">To do</p>

        {/* !!!!important!!!! */}
        {/* <p className="badge doing-badge">Doing</p> */}
        {/* <p className="badge done-badge">Done</p> */}
      </div>
    );
  }
}

function changeStatus(cell, row) {
  return <Status active={cell} />;
}

class Actions extends React.Component {
  render() {
    return (
      <div className="dis-flex">
        <button className="btn-icon-table">
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={18} />
        </button>
      </div>
    );
  }
}

export default class tableCoachAssistants extends Component {
  constructor(props) {
    super(props);
    // this.updatedCoachData = this.updatedCoachData.bind(this);
    // this.updateHandleChange = this.updateHandleChange.bind(this);
    this.state = {
      api: API.API,
      coachAssistant: [],
      domaines: [],
      fields: {},
      upFields: {},
      coachAssistantID: 0,
      coachFullname: "",
      coachEmail: "",
      coachDomaine: "",
      coachEcole: "",
      coachPhone: "",
      coachDebutFormation: "",
      coachFinFormation: "",
      coachcoachFullname: "",
      emailValue: "",
      phoneValue: "",
      boursierValue: false,
      demiBoursierValue: false,
      adresseValue: "",
      ecoleValue: "",
      stageFormationValue: false,
      isISAContractValue: false,
      lieuFormationValue: "",
      horaireValue: "",
      paiementInscriptionValue: false,
      debutFormationValue: "",
      finFormationValue: "",
      firstNameValue: "",
      lastNameValue: "",
      loading: true,
      isLoaded: true,
      updatedcoachAssistanttatusInProgress: false,
      updatedError: false,
      updateFields: {},
      updateCoach: [],
      is_archived: 0,
      updateCoachInfosInProgress: false,
      updateCoachInfosSuccessAlert: false,
      updateCoachInfosRequestError: false,
      show: false,
    };
  }

  componentDidMount() {
    this.getListCoachAssistant();
    this.getAllDomaines();
  }
  getCoachInfos(coach) {
    this.setState({
      // show: true,
      coachID: coach.id,
      coachFullname: coach.coachFullname,
      coachEmail: coach.email,
      coachDomaine: coach.domaine,
      coachDomaineID: coach.coach_domaine_id,
      coachPhone: coach.phone,
      coachAssistanttatus: coach.coach_is_actif,
      coachNbreBakelistes: coach.nbreBakeliste,
      coachFirstname: coach.first_name,
      coachLastname: coach.last_name,
      updateCoach: coach,
    });
  }

  archivedCoach(id) {
    axios
      .post(this.state.api + "archive-user/" + id)
      .then((res) => {
        // console.log("archiver", res)
        if (res.data.success === true) {
          toast.success(
            "coach assistant archivé avec success",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
          this.getListCoachAssistant();
        } else {
          toast.error(
            "erreur sur l'archivage",
            { position: toast.POSITION.BOTTOM_LEFT },
            { autoClose: 1000 }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  actionFormatter = (cell, row) => {
    return (
      // return <Actions active={cell} />;
      <span>
        {/* <button title="Voir" onClick={() => { this.getCoachInfos(row) }} id="show_item" className="update_item" className="btn btn-default edit-btn volkeno-green-bcolor" data-toggle="modal" data-target="#showInfosModal">
                      <i class="fas fa-eye"></i>
                  </button> */}
        {/* <div className="dis-flex">
        <button className="btn-icon-table" onClick={() => { this.getCoachInfos(row) }} data-toggle="modal" data-target="#showInfosModal">
          <FeatherIcon className="icon-btn-icon-table" icon="eye" size={18} />
        </button>
      </div> */}
        &nbsp;&nbsp;
        <Tooltip title="Modifier">
          <FeatherIcon
            icon="edit-2"
            style={{
              color: "#469789",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            onClick={() => {
              this.getCoachInfos(row);
              this.open();
            }}
          />
        </Tooltip>
        &nbsp;&nbsp;
        <Tooltip title="Archivez coach">
          <FeatherIcon
            icon="archive"
            id="delete_group"
            style={{
              color: "red",
              height: "21px",
              width: "22px",
              cursor: "pointer",
            }}
            onClick={() => {
              this.archivedCoach(row.id);
            }}
            className="ajouter"
            cursor="pointer"
          />
        </Tooltip>
      </span>
    );
  };
  getListCoachAssistant = () => {
    axios
      .get(this.state.api + "liste-des-coachs-assistants")
      .then((res) => {
        if (res.data.success === true) {
           console.log('coachAssistant', res)
          this.setState({
            coachAssistant: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            isEmptyData: true,
          });
        }
        this.setState({ isLoaded: false });
        $("#start-hidden").removeClass("start-hidden");
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error.message);
      });
  };

  CoachAd = (Column, row) => {
    return (
      <div className="la-taille-div">
        {row.status === "admin" ? (
          <span className="admin-color">Admin</span>
        ) : (
          <span className="coach-color">Coach</span>
        )}
      </div>
    );
  };

  NumeroCoach = (Column, row) => {
    return (
      <div className="la-taille-div">
        {row.statut === "coachAssistant" ? (
          <span className="admin-color">5</span>
        ) : (
          <span className="coach-color">2</span>
        )}
      </div>
    );
  };
  CustomExportCsvBtn = (onClick) => {
    return (
      <ExportCSVButton
        style={{
          background: "#FF9800",
          border: "none",
          height: "42px",
          width: "150px",
          left: "20px",
        }}
        btnText="Export to csv"
      />
    );
  };

  handleShowAndHideAlert(label) {
    setTimeout(() => {
      if (label === "coachUpdateSuccess") {
        this.setState({
          updateCoachInfosSuccessAlert: false,
        });
      }
      if (label === "coachUpdateError") {
        this.setState({
          updateCoachInfosRequestError: false,
        });
      }
    }, 5000);
  }
  updatedCoachData = (e) => {
    e.preventDefault();
    // console.log(this.state.updateFields)
    this.setState({
      updateCoachInfosInProgress: true,
    });
    let idCoach = this.state.coachID;
    let data = this.state.updateFields;
    axios
      .put(this.state.api + "update-coach-infos/" + idCoach, data)
      .then((response) => {
        // console.log(response)
        if (response.data.success === true) {
          this.getListCoachAssistant();
          this.handleShowAndHideAlert("coachUpdateSuccess");
          this.close();
          // $(".close").click();
          this.setState({
            updateCoachInfosInProgress: false,
            updateCoachInfosSuccessAlert: true,
            updateFields: {},
          });
        } else {
          // console.log(response)
          this.setState({
            updateCoachInfosInProgress: false,
            updateCoachInfosRequestError: true,
          });
          this.handleShowAndHideAlert("coachUpdateError");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          updateCoachInfosInProgress: false,
          updateCoachInfosRequestError: true,
        });
        this.handleShowAndHideAlert("coachUpdateError");
      });
  };
  close = () => {
    this.setState({ show: false });
  };
  open = () => {
    this.setState({ show: true });
  };
  updateHandleChange = (e) => {
    let fields = this.state.updateFields;
    fields[e.target.name] = e.target.value;

    if (e.target.name === "first_name") {
      this.setState({
        coachFirstname: e.target.value,
      });
    }
    if (e.target.name === "last_name") {
      this.setState({
        coachLastname: e.target.value,
      });
    }
    if (e.target.name === "email") {
      this.setState({
        coachEmail: e.target.value,
      });
    }
    if (e.target.name === "phone") {
      this.setState({
        coachPhone: e.target.value,
      });
    }
    if (e.target.name === "coach_domaine_id") {
      this.setState({
        coachDomaineID: e.target.value,
      });
    }

    if (!fields["first_name"]) {
      fields["first_name"] = this.state.updateCoach.first_name;
    }
    if (!fields["last_name"]) {
      fields["last_name"] = this.state.updateCoach.last_name;
    }
    if (!fields["email"]) {
      fields["email"] = this.state.updateCoach.email;
    }
    if (!fields["phone"]) {
      fields["phone"] = this.state.updateCoach.phone;
    }
    if (!fields["coach_domaine_id"]) {
      fields["coach_domaine_id"] = this.state.updateCoach.coach_domaine_id;
    }

    this.setState({
      updateFields: fields,
    });

    // console.log(fields)
  };

  getAllDomaines = () => {
    axios
      .get(this.state.api + "domaines")
      .then((res) => {
        const domains = res.data;
        this.setState({
          domaines: domains.data,
        });
        // console.log(this.state.domaines)
      })
      .catch((error) => {
        //console.log(error.message);
        if (process.env.NODE_ENV !== "production") {
          return;
        }
        // Sentry.captureException(error);
      });
  };
  render() {
    const options = {
      exportCSVBtn: this.CustomExportCsvBtn,
    };
    return (
      <div className="component-table-my-list-coachAssistant">
        {this.state.isLoaded && <SkeletonAllBakeliste />}
        <div className="col-md-2 ml-auto mb-2">
          <NavLink
            className="nav-link btn-theme right-elements"
            to="/ajout-coach-assistant"
          >
            Ajouter
            <Icon className="ml-2" icon="plus-circle" />
          </NavLink>
        </div>
         <BootstrapTable
          data={this.state?.coachAssistant}
          striped={true}
          hover={true}
          options={options}
          search
          searchPlaceholder="Rechercher coach..."
          multiColumnSearch
          exportCSV
          csvFileName="listes-coachAssistant.csv"
          multiColumnSort={2}
          pagination
          bordered={true}
          bodyStyle={{
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.7)",
            fontFamily: "inherit",
            fontWeight: 400,
          }}
          tdStyle={{ background: "red" }}
          tableHeaderclassName="header-table-groupe-view"
          wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
          className="table-responsive table-responsive-sm"
        >
          <TableHeaderColumn
            className="titre-tab"
            isKey
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            dataField="coachFullname"
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Nom Complet</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="domaine"
            className="titre-tab"
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            thStyle={{ border: "none", fontWeight: "400" }}
            tdStyle={{
              border: "none",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Domaine</span>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="email"
            className="titre-tab"
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Email</span>
          </TableHeaderColumn>

          {/* <TableHeaderColumn
            dataField="nbreBakeliste"
            className="titre-tab"
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">
              Nombre Bakelistes
            </span>
          </TableHeaderColumn> */}

          <TableHeaderColumn
            dataField="phone"
            className="titre-tab"
            filter={{
              type: "TextFilter",
              delay: 1000,
              placeholder: "Recherche  ",
            }}
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              textAlign: "center",
              padding: "1rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs">Numéro</span>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="action"
            dataFormat={this.actionFormatter}
            className="titre-tab"
            thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
            tdStyle={{
              border: "none",
              display: "flex",
              justifyContent: "center",
              padding: "1.2rem",
              "font-size": "14px",
              "font-family": "Montserrat",
            }}
          >
            <span className="color-nom-prenom-utilisateurs"> Actions</span>
          </TableHeaderColumn>
        </BootstrapTable> 

        <div
          className="modal fade"
          id="showInfosModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="showInfosModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="showInfosModalLabel">
                  Detail sur {this.state.coachFullname}
                </h4>
              </div>
              <div className="modal-body">
                <div className="coachInfos-container">
                  <div className="fullname_container">
                    <span className="item_label">Prénom et Nom : </span>{" "}
                    <span className="label_content">
                      {this.state.coachFullname}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="email_container">
                    <span className="item_label">Email : </span>{" "}
                    <span className="label_content">
                      {this.state.coachEmail}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="phone_container">
                    <span className="item_label">Téléphone : </span>{" "}
                    <span className="label_content">
                      {this.state.coachPhone}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="domaine_container">
                    <span className="item_label">Domaine de compétence : </span>{" "}
                    <span className="label_content">
                      {this.state.coachDomaine}
                    </span>
                  </div>{" "}
                  <hr />
                  <div className="count_container">
                    <span className="item_label nbakelistes">
                      Nombre de bakelistes :{" "}
                    </span>{" "}
                    <br />
                    <span className="label_content cbakelistes">
                      {this.state.coachNbreBakelistes}
                    </span>
                  </div>{" "}
                  <hr />
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Editer les infos du coach */}

        <Modal
          className="modal-responsive-sm p-b-50"
          show={this.state.show}
          onHide={this.close}
        >
          <h5 className="modal-bakeliste-title m-b-50">
            Éditer {this.state.coachFullname}
          </h5>
          <button className="close-modal-coach-title" onClick={this.close}>
            <FeatherIcon icon="x" aria-hidden="true" />
          </button>
          <div className="modal-body">
            <div className="row edit-coach-container">
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Prénom : </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.coachFirstname}
                    onChange={this.updateHandleChange}
                    name="first_name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group ">
                  <label>Nom : </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.coachLastname}
                    onChange={this.updateHandleChange}
                    name="last_name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label>Email : </label>
                  <input
                    type="email"
                    className="form-control"
                    value={this.state.coachEmail}
                    onChange={this.updateHandleChange}
                    name="email"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group ">
                  <label>Téléphone : </label>
                  <input
                    type="number"
                    className="form-control"
                    value={this.state.coachPhone}
                    onChange={this.updateHandleChange}
                    name="phone"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Domaine :</label>
                  <select
                    className="form-control"
                    id="inputbakeli_domaine_id"
                    name="coach_domaine_id"
                    value={this.state.coachDomaineID}
                    onChange={this.updateHandleChange}
                  >
                    <option> -- Choisir un domaine -- </option>
                    {this.state.domaines.map((x, y) => (
                      <option value={x.id} key={y}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {this.state.updateCoachInfosRequestError && (
                <div className="alerty-danger-container col-md-12 alert alert-danger form-group">
                  <div className="alerty-danger">
                    Un probléme est survenu lors de la modification. Veuillez
                    réessayer plus tard.
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-5">
                <button
                  type="button"
                  className="btn btn-theme-danger"
                  onClick={this.close}
                >
                  Quitter
                </button>
              </div>
              <div className="col-md-7">
                {!this.state.updateCoachInfosInProgress && (
                  <button
                    type="button"
                    className="btn btn-theme"
                    onClick={(e) => this.updatedCoachData(e)}
                  >
                    Modifier
                  </button>
                )}
                {this.state.updateCoachInfosInProgress && (
                  <button
                    type="button"
                    className="btn btn-warning button addBtn btn_for_wait"
                  >
                    Modification en cours &nbsp;
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                )}
              </div>
            </div>
            {this.state.updatedError && (
              <div className="row update-error-container">
                <div className="col-md-12 error-content alert alert-danger">
                  Oups...Un problème est survenu lors de la modification.
                  Veuillez réessayer plus tard.
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
// export default connect(
//     ({ tableMyListcoachAssistant }) => ({ ...tableMyListcoachAssistant }),
//     dispatch => bindActionCreators({ ...tableMyListcoachAssistantActions }, dispatch)
//   )( tableMyListcoachAssistant );
