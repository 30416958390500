import React, {Component} from 'react';
import './livrableCoachView.css';
import HeaderDashboardCoach from '../headerDashboardCoach/index';
import NavVerticalCoach from '../navVerticalCoach/index';
import LivraisonCoach from '../livraisonCoach/index';

export default class livrableCoachView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
      return  <div className="body-theme">
                <HeaderDashboardCoach />
                <div className="container-fluid fixing-after-header">
                  <div className="row">
                    <div className="col-lg-2 p-0">
                      <NavVerticalCoach />
                    </div>
                    <div className="col-lg-10 pt-3">
                      <LivraisonCoach />
                    </div>
                  </div>
                </div>
              </div>;
    }
}
// export default connect(
//     ({ livrableCoachView }) => ({ ...livrableCoachView }),
//     dispatch => bindActionCreators({ ...livrableCoachViewActions }, dispatch)
//   )( livrableCoachView );