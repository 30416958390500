import React, { useEffect, useReducer } from "react";
import "./tableMyDomaine.css";
import FeatherIcon from "feather-icons-react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import axios from "axios";
import API from "../../variablesGlobales";
import * as Sentry from "@sentry/browser";
import { Modal } from "rsuite";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import {
  getDomainesAsync,
  showListDomaines,
  updateDomaineAsync,
} from "../../redux/slices/parametreSlices/parametreSlice";
import { useSelector } from "react-redux";
toast.configure();
const TableMyDomaineRefact = () => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      api: API.API,
      domaines: [],
      allDomaines: [],
      id: 0,
      showEditModal: false,
      size: "md",

      syllabus: {},
      // api : API.API,
      doamaine: {},
      domaine_name: "",
      description: "",
      status: "",
      fields: {},
      domaineID: "",
    }
  );

  const domainesDataRedux = useSelector(showListDomaines);

  const dispatch = useDispatch();
  useEffect(() => {
    // getListDomaines();
    //getListAllDomaines();
    dispatch(getDomainesAsync());
  }, []);


  const editDomaineModal = (id) => {
    onGetDomaine(id);
    setState({
      showEditModal: true,
    });
  };
  const onGetDomaine = (id) => {
    setState({
      isPageLoading: true,
    });
    axios
      .get(state.api + "domaines/" + id)
      .then((response) => {
        setState({
          isPageLoading: false,
        });
        // console.log(response, "res")
        if (response.data.success) {
          setState({
            doamaine: response.data.data,
            domaine_name: response.data.data.name,
            status: response.data.data.status,
            description: response.data.data.description,
            domaineID: response.data.data.id,
          });
        }
      })
      .catch((error) => {
        setState({
          isPageLoading: false,
          isGetSyllabusRequestError: true,
        });
      });
  };
  const close = () => {
    setState({
      showEditModal: false,
    });
  };

  const onSubmitEditDetailsDomaine = (e) => {
    e.preventDefault();
    setState({
      editDomaineInProgress: true,
    });
    // var data = state.syllabus;
    var fd = new FormData();
    fd.append("name", state.domaine_name);
    fd.append("status", state.status);
    fd.append("description", state.description);
    dispatch(updateDomaineAsync(state.domaineID, fd));
    setTimeout(() => {
      setState({
        editDomaineInProgress: true,
      });
      setState({
        showEditModal: false,
        editDomaineInProgress: false,
      });
    }, 1000);
  };

  const actionsFormatter = (cell, row) => {
    let id = row.id;
    return (
      <span>
        <span className="modifier" onClick={() => editDomaineModal(id)}>
          <FeatherIcon
            className="icon-details-container-syllabus-item-back"
            icon="edit-3"
          />
          Modifier
        </span>
      </span>
    );
  };
  return (
    <div className="component-table-gestion-tache-for-coach table-responsive-sm table-responsive-xl table-responsive-md">
      <BootstrapTable
        data={domainesDataRedux}
        striped
        version="4"
        bordered={false}
        bodyStyle={{
          fontSize: 12,
          color: "rgba(0, 0, 0, 0.7)",
          fontFamily: "inherit",
          fontWeight: 400,
        }}
        tdStyle={{ background: "red" }}
        tableHeaderclassName="header-table-groupe-view"
        wrapperClasses="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm"
        className="table-responsive table-responsive-sm"
      >
        <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
          {" "}
          ID{" "}
        </TableHeaderColumn>
        <TableHeaderColumn
          // filter={{type:"TextFilter", delay: 1000}}
          dataField="name"
          width="250"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{
            border: "none",
            padding: "1rem",
            "font-size": "14px",
            "font-family": "Montserrat",
          }}
        >
          <span className="color-nom-prenom-utilisateurs">Domaines</span>
        </TableHeaderColumn>
        <TableHeaderColumn
          className="titre-tab"
          width="250"
          dataField="description"
          thStyle={{ border: "none", fontWeight: "400" }}
          tdStyle={{ border: "none", padding: "1rem" }}
        >
          Descriptions
        </TableHeaderColumn>
        {/* <TableHeaderColumn className="titre-tab" dataField='statut' dataFormat={changeStatus} width="100" dataSort thStyle={{ 'border': 'none', 'fontWeight': '400', 'textAlign': 'center' }} tdStyle={{ 'border': 'none', 'textAlign': 'center', 'padding': '1rem' }}>Statuts</TableHeaderColumn> */}
        <TableHeaderColumn
          className="titre-tab"
          width="250"
          dataField="action"
          dataFormat={actionsFormatter}
          thStyle={{ border: "none", fontWeight: "400", textAlign: "center" }}
          tdStyle={{
            border: "none",
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
          }}
        >
          Actions
        </TableHeaderColumn>
      </BootstrapTable>
      <div>
        <hr />
      </div>

      <Modal
        className="modal-responsive-md"
        size={state.size}
        show={state.showEditModal}
        onHide={close}
      >
        <form onSubmit={(e) => onSubmitEditDetailsDomaine(e)}>
          <Modal.Header>
            <Modal.Title className="modal-contact-us">
              Modifier Domaine{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-height-modal">
            <div className="form-group as_input_container">
              <label>Nom du domaine</label>
              <input
                value={state.domaine_name}
                onChange={(e) => setState({ domaine_name: e.target.value })}
                name="syllabus_name"
                // placeholder="Nom du syllabus"
                className="form-control input_item"
                id="name"
              />
            </div>
            <div className="form-group as_input_container">
              <label>Description du domaine</label>
              <textarea
                value={state.description}
                onChange={(e) => setState({ description: e.target.value })}
                name="description"
                placeholder="Nom du syllabus"
                className="form-control input_item"
                id="description"
              />
            </div>
            <div className="form-group as_input_container">
              <select
                name="status"
                className="form-control input_item"
                id="syllabus_level"
                value={state.fields["domaine_id"]}
                onChange={(e) => setState({ status: e.target.value })}
              >
                <option value="actif"> Actif </option>
                <option value="non-actif"> Non-actif </option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row dis-flex justify-content-end">
              <div className="col-md-5 col-sm-6">
                {state.editDomaineInProgress ? (
                  <button
                    type="submit"
                    className="btn-theme-orange m-t-30 w-full p-t-15 p-b-15"
                    disabled
                  >
                    Modification en cours &nbsp;
                    <FeatherIcon icon="loader" aria-hidden="true" />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme text-up m-t-30 w-full p-t-15 p-b-15"
                  >
                    MODIFIER
                  </button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default TableMyDomaineRefact;
